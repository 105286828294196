import {
    SET_DISCOUNTS,
    GET_DISCOUNTS,
    GET_AVAILABLE_DISCOUNTS,
    DISCOUNT_ERROR,
    DELETE_DISCOUNT,
    ADD_DISCOUNT,
    GET_DISCOUNT,
    EDIT_DISCOUNT,
    CLEAR_DISCOUNTS,
    CLEAR_DISCOUNT,
    DISCOUNTS_LOADING,
    MODAL_DISCOUNT_LOADING,
    SET_MODAL_DISCOUNT,
    CLEAR_MODAL_DISCOUNT,
    APPLY_DISCOUNT,
  } from '../actions/types';
  
  const initialState = {
    discounts: [],
    available_discounts: [],
    detailDiscount: null,
    modal_discount: null,
    loading: true,
    modal_loading: true,
    recently_applied_discount: false, // Show the 'continue' btn on the add promo code page after applying discount
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
        case DISCOUNTS_LOADING:

            // Set the loading state to true
            return {
                ...state,
                loading: true
            };
        case MODAL_DISCOUNT_LOADING:

            // Set the loading state to true
            return {
                ...state,
                modal_loading: true
            };
        case GET_DISCOUNTS:
            return {
            ...state,
            discounts: payload,
            loading: false
            };
        case GET_DISCOUNT:
            return {
                ...state,
                detailDiscount: payload,
                loading: false
            };
        case SET_DISCOUNTS: {

            // Merge the existing discounts with the payload discounts, ensuring no duplicates based on _id
            const newDiscounts = action.payload;
            
            // Create a Set to store unique _id values
            const idSet = new Set(state.discounts.map(discount => discount._id));
            
            // Filter out new discounts that have _id already in idSet
            const filteredNewDiscounts = newDiscounts.filter(discount => !idSet.has(discount._id));
            
            // Merge unique discounts with existing discounts
            const updatedDiscounts = [...state.discounts, ...filteredNewDiscounts];

            return {
                ...state,
                discounts: updatedDiscounts,
                loading: false
            };
        }
        case SET_MODAL_DISCOUNT: {

            // Merge the existing discounts with the payload discounts, and update the discounts state

            return {
                ...state,
                modal_discount: payload,
                modal_loading: false
            };
        }
        case CLEAR_MODAL_DISCOUNT: {

            // Merge the existing discounts with the payload discounts, and update the discounts state

            return {
                ...state,
                modal_discount: null,
                modal_loading: false
            };
        }
        case ADD_DISCOUNT:
            return {
            ...state,
            discounts: [payload, ...state.discounts],
            detailDiscount: payload,
            loading: false
            };
        case EDIT_DISCOUNT:
            return {
                ...state,
                detailDiscount: payload,
                loading: false
            };
        case DELETE_DISCOUNT:
            return {
            ...state,
            discounts: state.discounts.filter(discount => discount._id !== payload),
            loading: false
            };
        case APPLY_DISCOUNT: {

                return {
                    ...state,
                    recently_applied_discount: true
                }
            }
        case DISCOUNT_ERROR:
            return {
            ...state,
            error: payload,
            loading: false
            };
        case CLEAR_DISCOUNTS:
            return {
                ...state,
                discounts: [],
                loading: false
            }
        case CLEAR_DISCOUNT:
            return {
                ...state,
                detailDiscount: null,
                recently_applied_discount: false,
                loading: false
            }
        case GET_AVAILABLE_DISCOUNTS:
            return {
                ...state,
                available_discounts: payload,
                loading: false
            }
        default:
            return state;
    }
  }