import React from 'react'

import logo from '../../utils/imgs/miitoken_logo.png';

// A functional component representing a logo
const Logo = props => {

     // Render an image element with the logo
    return <img className="modal__twitterIcon" src={logo} style={{maxHeight: '40px'}} alt="logo" />;
}

Logo.propTypes = {

}

// Export component as the default export
export default Logo
