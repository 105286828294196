import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, add_To_Recent_History } from '../actions/navActions';
import { getLikedPosts } from '../actions/postActions';

// Layout Wrapper
import Layout from '../components/layout/Layout';

// components
import Spinner from '../components/common/Spinner';
import Post from '../components/Post/Post';

const LikePage = ({ 
    getLikedPosts, 
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    auth, 
    post: { 
        posts, 
        no_more_posts,
        loading 
    }, 
    nav: { 
        sideNav 
    }
}) => {

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // State variable to track if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Inifite scroll
    const [lastPageDoc, setLastPageDoc] = useState(null);
    const [showBottomSpinner, setShowBottomSpinner] = useState(false)

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

        // Set the current page to 'saved'
        setPage('saved');
        add_To_Recent_History(url.pathname);  // current url
        
        // Fetch liked posts when the authenticated user changes
        if(auth.user) {
            getLikedPosts(auth.user._id);
        }
    }, [auth.user]);

    useEffect(() => {

        if(posts.length > 0) {
            handleLastLoadedDoc();
        }
    }, [posts]);

    const handleLastLoadedDoc = async () => {
        let lastDocument = posts[posts.length - 1];

        // Create a reference to the specified post document in the "posts" collection
        const docRef = doc(db, 'posts', lastDocument?._id)

        // Retrieve the document with the specified ID from the "posts" collection
        const postDoc = await getDoc(docRef);

        // Get the last visible document for the next load
        setLastPageDoc(postDoc);

        // Hide the loading spinner
        setShowBottomSpinner(false);
    }

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };


    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more posts are met
        if (newScrollY >= scrollHeight - 1 && !showBottomSpinner && !no_more_posts && posts.length > 0) {
            console.log('scrolling');
            loadMore();
        }
    }

    // Function to load more paginated posts
    const loadMore = () => {
        console.log("last", lastPageDoc);

        // Show spinner
        setShowBottomSpinner(true);

        getLikedPosts(auth.user._id, lastPageDoc);

    }
    
    let postList;

    if(posts === null || loading) {

        // Display a loading spinner if posts are still loading
        postList = <Spinner />;
    }
    else {
        if(posts.length > 0) {

            // Map over the posts array and render a Post component for each post
            postList = posts.map(post => {
                if(post) {
                    return (
                        <Post key={post._id} postDoc={post} parentPost={post.post_commented_on && true} />

                        // Placeholder props for the Post component
                        // displayName="Tunde Adepitan"
                        // username="foxytunde" 
                        // verified={true}
                        // text="YOOO its working"
                        // avatar="https://pbs.twimg.com/profile_images/1257120322899398659/Jxe3zf98_x96.jpg"
                    )
                }
            })
        }
        else {

            // Display a message when there are no saved posts
            postList = (
                <div className="no-posts-container">
                    <div className="no-chats">
                        <h1>You haven’t liked any posts yet</h1>
                        <p>When you like a post, it’ll show up here.</p>
                    </div>
                </div>
            );
        }
    }

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.identify(auth.user._id);
        mixpanel.track("Saved Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && auth.user !== null) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (
        <Layout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="saved">

            {/* List of generated posts */}
            {postList}

            {/* Render a spinner at the bottom if there are more posts to load */}
            {showBottomSpinner && !no_more_posts && <Spinner />}
            
            {/* Display a message when there are no more posts */}
            <div className="no-rides">{(no_more_posts && posts.length !== 0) && "You've reached the end."}</div>
        </Layout>
    )
}

LikePage.propTypes = {
    getLikedPosts: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired, // Object prop representing the authentication state of the user
    nav: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    post: state.post,
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    getLikedPosts, 
    toggleSideNav, 
    setPage,
    add_To_Recent_History
})(LikePage);
