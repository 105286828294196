import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { getMenuPage, getMenuItems } from '../../actions/menuActions';
import { setPage, add_To_Recent_History } from '../../actions/navActions';

// Layout Wrapper
import ShopLayout from '../../components/layout/ShopLayout';

// Components 
import SlideMenu from '../components/SlideMenu/SlideMenu';
import Category_List from '../components/CategoryList/Category_List';
import Mobile_CartBtn from '../components/Cart/Mobile_CartBtn';

const ExplorePage = ({
    getMenuPage,
    getMenuItems,
    setPage,
    add_To_Recent_History,
    menu,
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Get URL params
    const { menuID } = useParams();
    // Put "menu" data into forms
    const detailMenu = menu.detailMenu;
    const loadingMenu = menu.loading;
    const menus = menu.menus;

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Set nav page
        setPage('marketplace');
        add_To_Recent_History(url.pathname);  // current url

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    useEffect(() => {

            // -- Get Product Data
            if(!detailMenu) getMenuPage("FuuNWfB2pTIbzPEdDIsW");
    }, []);

    useEffect(() => {

        if(detailMenu) {

            getMenuItems(detailMenu._id);
        }

    }, [detailMenu]);

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more products are met
        // if (newScrollY >= scrollHeight - 1 && !showBottomSpinner && !noMoreProducts && filter !== null && products.length > 0) {
        //     console.log('scrolling');
        //     loadMore();
        // }
    }

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Browse Grocery Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (
        <ShopLayout handleScroll={handleScroll} page="home">

            <div style={{paddingTop: '15px', paddingLeft: '15px', position: 'relative', boxSizing: 'border-box'}}>
                {/* Breadcrumbs */}
                {/* <ul className="breadcrumbs">
                    <li className="breadcrumbs__item">
                        <a href="#" className="breadcrumbs__link">Marketplace</a>
                    </li>
                    <li className="breadcrumbs__item">
                        <a href="#" className="breadcrumbs__link breadcrumbs__link--active">Trending</a>
                    </li>
                </ul> */}

                {/* Sub-Category Slide Menu */}
                <SlideMenu />

                {/* Product Category Overview */}
                <Category_List />
            </div>

            <div style={{height: '50px'}}></div>

            <Mobile_CartBtn />

        </ShopLayout>
    )
}

ExplorePage.propTypes = {
    getMenuPage: PropTypes.func.isRequired,
    getMenuItems: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    menu: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    menu: state.menu,
    cart: state.cart
})

export default connect(mapStateToProps, { 
    getMenuPage, 
    getMenuItems, 
    setPage,
    add_To_Recent_History
})(ExplorePage);
