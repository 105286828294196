import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

import mixpanel from 'mixpanel-browser';

// Actions
import { getUnreadChats } from '../../actions/chatActions';
import { getUnreadCount } from '../../actions/notificationActions';

// Components - imported
import { Avatar } from '@material-ui/core';

// Icons - material UI
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import MoneyOffOutlinedIcon from '@material-ui/icons/MoneyOffOutlined';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';

const Nav_Settings = ({ 
    sideNav, 
    handleSlideMenu, 
    auth, 
    chat,
    page, 
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [authDropdown, setAuthDropdown] = useState(false);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, [window.location]);
    
    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Update the header animation based on the sideNav state
        if(sideNav) {
            document.getElementById('feed-header').classList.remove("active");
            return "feed__header";
        } else {
            setTimeout(() => {
                document.getElementById('feed-header').classList.add("active");
            }, 700)
        }
    }, [sideNav])

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user, page]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Extract the current path from the window location
    let previousURL = window.location.pathname;
    // Split the path into an array based on '/'
    var pathArray = previousURL.split( '/' );

    console.log('HELLO PATH ARRAY');
    console.log(pathArray);
    console.log('FORTH PATH');

    // Check if the third path element exists
    if(pathArray[2]) {
        console.log(pathArray[2]);
    }

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;
    const notFullScreen = windowWidth <= 1170;

     // Initialize Mixpanel and track settings Nav page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Nav Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    let user_name;

    if(!auth.loading && auth.isAuthenticated) {
        user_name = `${auth.user.first_name} ${auth.user.last_name && auth.user.last_name}`
    }

    return (
        <div className="feed">

            {/* Nav Header */}
            <div className="feed__header" id="feed-header">
                
                {/* Icon & Text */}
                <h2 style={{display:'flex'}}>

                    <div style={{display:'flex', alignItems: 'center'}}>
                        
                        {isMobile && (
                            <Fragment>
                                <div onClick={goBack} style={{marginRight: '10px'}} className="shopFeed__backBtn noMargin">
                                    <div>
                                        <ArrowBackIcon />
                                    </div>
                                </div>

                                {badgeValue > 0 && (
                                    <span className="feed-header-badge home">
                                        {badgeValue}
                                    </span>
                                )}
                            </Fragment>
                        )}

                        Settings
                    </div>
                        
                </h2>

            </div>
            

            {/* Account Edit  */}
            {auth.user ? (
                <Fragment>
                    <div style={{background: '#f5f8fa', color: 'grey', fontSize: '13px', fontWeight: 'bold', padding: '5px 10px'}}>
                        <span>Account Info</span>
                    </div>

                    <a style={{textDecoration:'none'}} href="/messages">
                        <div className={`settings__item pictogram ${pathArray[2] && pathArray[2].toLowerCase().includes("help") ? 'active' : null}`}>

                            <span style={{display: 'flex'}}>
                                <span style={{marginRight: '5px'}}>
                                    <MailOutlineIcon />
                                </span>
                                Inbox
                            </span>
                            <ChevronRightIcon />
                        </div>
                    </a>

                    <a style={{textDecoration:'none'}} href="/notifications">
                        <div className={`settings__item pictogram ${pathArray[2] && pathArray[2].toLowerCase().includes("help") ? 'active' : null}`}>

                            <span style={{display: 'flex'}}>
                                <span style={{marginRight: '5px'}}>
                                    <NotificationsNoneIcon />
                                </span>
                                Alerts
                            </span>
                            <ChevronRightIcon />
                        </div>
                    </a>

                    <a style={{textDecoration:'none'}} href="/settings/coupons">
                        <div className={`settings__item pictogram ${pathArray[2] && pathArray[2].toLowerCase().includes("help") ? 'active' : null}`}>
                            <span style={{display: 'flex'}}>
                                <span style={{marginRight: '5px'}}>
                                    <ConfirmationNumberOutlinedIcon />
                                </span>
                                My Coupons
                            </span>
                            <ChevronRightIcon />
                        </div>
                    </a>

                    <a style={{textDecoration:'none'}} href="/settings/addresses">
                        <div className={`settings__item pictogram ${pathArray[2] && pathArray[2].toLowerCase().includes("help") ? 'active' : null}`}>
                            <span style={{display: 'flex'}}>
                                <span style={{marginRight: '5px'}}>
                                    <LocationOnOutlinedIcon />
                                </span>
                                My Addresses
                            </span>
                            <ChevronRightIcon />
                        </div>
                    </a>

                    <a style={{textDecoration:'none'}} href="/orders">
                        <div className={`settings__item pictogram ${pathArray[2] && pathArray[2].toLowerCase().includes("help") ? 'active' : null}`}>
                            <span style={{display: 'flex'}}>
                                <span style={{marginRight: '5px'}}>
                                    <ShoppingBasketOutlinedIcon />
                                </span>
                                My Orders
                            </span>
                            <ChevronRightIcon />
                        </div>
                    </a>

                    <a style={{textDecoration:'none'}} href="/settings/refunds">
                        <div className={`settings__item pictogram ${pathArray[2] && pathArray[2].toLowerCase().includes("help") ? 'active' : null}`}>
                            <span style={{display: 'flex'}}>
                                <span style={{marginRight: '5px'}}>
                                    <MoneyOffOutlinedIcon />
                                </span>
                                Refunds
                            </span>
                            <ChevronRightIcon />
                        </div>
                    </a>
                    <a style={{textDecoration:'none'}} href="/settings/account">
                        <div className={`settings__item pictogram ${pathArray[2] && pathArray[2].toLowerCase().includes("account") ? 'active' : null}`}>
                            <span style={{display: 'flex'}}>
                                <span style={{marginRight: '5px'}}>
                                    <AccountCircleOutlinedIcon />
                                </span>
                                Account Settings
                            </span>
                            <ChevronRightIcon />
                        </div>
                    </a>
                </Fragment>
            ) : (
                <Fragment>
                    <a style={{textDecoration:'none'}} href="/user_registration">
                        <div className={`settings__item ${pathArray[2] && pathArray[2].toLowerCase().includes("help") ? 'active' : null}`}>
                            <p>Sign Up</p>
                            <ChevronRightIcon />
                        </div>
                    </a>

                    {/* Help */}
                    <a style={{textDecoration:'none'}} href="/user_registration">
                        <div className={`settings__item ${pathArray[2] && pathArray[2].toLowerCase().includes("help") ? 'active' : null}`}>
                            <p>Log In</p>
                            <ChevronRightIcon />
                        </div>
                    </a>
                </Fragment>
            )}

            <div style={{background: '#f5f8fa', color: 'grey', fontSize: '13px', fontWeight: 'bold', padding: '5px 10px'}}>
                <span>Help & Feedback</span>
            </div>

            {/* Help */}
            <a style={{textDecoration:'none'}} href="/settings/help">
                <div className={`settings__item ${pathArray[2] && pathArray[2].toLowerCase().includes("help") ? 'active' : null}`}>
                    <p>Customer Support</p>
                    <ChevronRightIcon />
                </div>
            </a>

            {/* Help */}
            <a style={{textDecoration:'none'}} href="/settings/feedback">
                <div className={`settings__item ${pathArray[2] && pathArray[2].toLowerCase().includes("feedback") ? 'active' : null}`}>
                    <p>Give Us Feedback</p>
                    <ChevronRightIcon />
                </div>
            </a>

            <div style={{background: '#f5f8fa', color: 'grey', fontSize: '13px', fontWeight: 'bold', padding: '5px 10px'}}>
                <span>General Info</span>
            </div>

            {/* About */}
            {/* <a style={{textDecoration:'none'}} href="/settings/about">
                <div className={`settings__item ${pathArray[2] && pathArray[2].toLowerCase().includes("about") ? 'active' : null}`}>
                    <p>About</p>
                    <ChevronRightIcon />
                </div>
            </a> */}

            {/* How It Works */}
            <a style={{textDecoration:'none'}} href="/settings/howitworks">
                <div className={`settings__item ${pathArray[2] && pathArray[2].toLowerCase().includes("howitworks") ? 'active' : null}`}>
                    <p>How It Works</p>
                    <ChevronRightIcon />
                </div>
            </a>

            {/* Terms of Service */}
            <a style={{textDecoration:'none'}} href="/settings/terms">
                <div className={`settings__item ${pathArray[2] && pathArray[2].toLowerCase().includes("terms") ? 'active' : null}`}>
                    <p>Terms of Service</p>
                    <ChevronRightIcon />
                </div>
            </a>

            {/* Privacy Policy */}
            <a style={{textDecoration:'none'}} href="/settings/privacy">
                <div className={`settings__item ${pathArray[2] && pathArray[2].toLowerCase().includes("privacy") ? 'active' : null}`}>
                    <p>Privacy Policy</p>
                    <ChevronRightIcon />
                </div>
            </a>

            {/* Log Out */}
            {auth.user && (
                <a style={{textDecoration:'none'}} href="/logout">
                    <div className="settings__item logout">
                        <p>Log Out</p>
                    </div>
                </a>
            )}

            {/* Add space to the bottom of the page, due to the bottom nav */}
            <div style={{height: '100px'}}></div>
        </div>
    )
}

export default Nav_Settings;
