import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Firebase
import { db, auth, storage } from "../../../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { decrement, addToCart, removeItem } from '../../../actions/cartActions';
import { getProductCollections_helper } from '../../../actions/collectionActions';
import { get_All_Discounts_From_CollectionList_helper } from '../../../actions/discountActions';

// Components
import Discount_Disclaimer from '../Discount_Components/Discount_Disclaimer';

// Icons
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const Cart_List_Item = ({ 
    cart: {
        cart
    },
    item: {
        item: {
            name,
            _id
        },
        price,
        qty
    },
    decrement, 
    addToCart,
    removeItem
}) => {

    // Product Imgs
    const [productImgs, setProductImgs] = useState(null);
    const [gotProductImgs, setGotProductImgs] = useState(false);

    const { items } = cart;

    useEffect(() => {

        getProductImgs()
    }, [items]);

    // Function to get post images
    async function getProductImgs() {
        const docRef = doc(db, 'products', _id)
        const colRef = collection(docRef, "img_gallery")
        // console.log('FETCHING IMGS')
        try {
            // console.log('GALLERY DATA')
            const galleryData = await getDocs(colRef);
            let galleryList = galleryData.docs.map((doc) => {

                // --- ImageKit (img optimization service) ---
   
                // Replace the firebase URL with ImageKit.io URL-endpoint
                const optimized_img_path = doc.data().img_path.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
                
                return ({...doc.data(), img_path: optimized_img_path, id: doc.id})
            });
            
            if(galleryList.length > 0) {
                galleryList = galleryList.sort((a, b) => a.img_order - b.img_order);
            }

            setProductImgs(galleryList)
            setGotProductImgs(true);
        } catch (err) {
            console.log('ERROR:');
            console.log(err);
        }
    }

    // Check if post images are not fetched yet and detailProduct exists
    if(!gotProductImgs) {

        // Fetch post images
        getProductImgs()

        // Don't fetch imgs again after this
        setGotProductImgs(true);
    }

    const handleDecrement = () => {
        if(qty) {
            if(qty >= 2) {
                decrement(_id)
            } else {
                removeItem(_id)
            }
        }
    }

    return (
        <div key={_id} style={{display:'flex', justifyContent:'center', boxSizing: 'border-box', flexDirection:'column', minHeight:'70px', borderBottom: '1px solid #e6ecf0'}}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', flex: 1}}>
                <div style={{display:'flex', alignItems:'center', flex: 1}}>
                    <a href={`/details/${_id}`} style={{cursor:'pointer', textDecoration:'none', wordWrap:'break-word'}}>
                        <div style={{marginRight:'24px', padding:'0 4px', display:'flex', alignItems:'center'}}>
                            <div style={{height:'50px', width:'50px', position:'relative'}}>
                             
                                {productImgs && <img style={{height:'100%'}} src={productImgs[0].img_path} />}
                            </div>
                            <div style={{paddingRight:'32px', display:'flex', flexDirection:'column', justifyContent:'space-between', paddingLeft:'8px'}}>
                                <span className='line-clamp' style={{lineHeight:'15.8px', overflow:'hidden' , fontSize:'14px', fontWeight:'bold', color:'#000', maxWidth:'100%'}}>
                                    {name}
                                </span>
                                <span style={{marginTop:'4px', fontSize:'12px', color:'rgb(92, 92, 92)', lineHeight:'15.8px', display: 'flex'}}>
                                    <span style={{color: 'green', fontSize:'13px', fontWeight: 'bold', marginRight: '5px'}}>${price}</span>
                                </span>
                                {/* <span style={{marginTop:'4px', fontSize:'12px', color:'rgb(92, 92, 92)', lineHeight:'15.8px', display: 'flex'}}>
                                    <span style={{color: 'green', fontSize:'13px', fontWeight: 'bold', marginRight: '5px'}}>$4.97</span>
                                    <s>${price}</s>
                                </span> */}
                            </div>
                        </div>
                    </a>
                </div>
                <div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'64px', height:'36px', overflow:'hidden', position:'relative', width:'120px'}}>
                        <div style={{display:'flex', cursor:'pointer', alignItems:'center', padding:'2px', boxSizing:'border-box', background:'rgb(236, 238, 233)', borderRadius:'64px', height:'100%', position:'absolute', width:'120px'}}>
                            <div onClick={handleDecrement} style={{borderRadius:'16px', height:'32px', width:'32px', background:'#fff', cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <RemoveIcon />
                            </div>
                            <div style={{alignItems:'center', justifyContent:'center', flex:1, display:'flex'}}>
                                <div style={{color:'rgb(0, 0, 0)', lineHeight:'23.8px', fontSize: '18px', }}>
                                    {qty}
                                </div>
                            </div>
                            <div onClick={() => addToCart(_id)} style={{borderRadius:'16px', height:'32px', width:'32px', color: '#fff', background:'rgba(0,164,255,1.00)', cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <AddIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Discount_Disclaimer productID={_id} />

            {/* <div className='cart_item_alert'>
                <div>
                    <CheckCircleIcon />
                    Buy 2  Save $3.00 ~ <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>Details</span>
                </div>
            </div> */}
        </div>
    )
}

Cart_List_Item.propTypes = {
    cart: PropTypes.object.isRequired,
    decrement: PropTypes.func.isRequired, 
    addToCart: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    cart: state.cart
})

export default connect(mapStateToProps, { decrement, addToCart, removeItem })(Cart_List_Item);