
// Firebase
import { db, auth, storage } from "../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

// import { setAlert } from './alertActions';

import {
  GET_COLLECTIONS,
  SET_COLLECTIONS,
  COLLECTION_ERROR,
  GET_COLLECTION,
  COLLECTIONS_LOADING,
  CLEAR_COLLECTIONS,
  GET_ITEM_COLLECTIONS
} from './types';

// Reference to the "collections" collection in Firestore
const categoryCollectionRef = collection(db, "categories");
const productsCollectionRef = collection(db, "products");

// Get projects
export const getCollections = (lastPageDoc) => async dispatch => {
  
  console.log('ENTER GET COLLECTIONS');

  try {

    console.log('GETTING COLLECTIONS')

    // Construct a new query starting at the last visible document and fetch the next 3 posts.
    const getQuery = query(categoryCollectionRef, orderBy('lastModified', 'desc'), limit(15));

    // Retrieve all documents from the "categories" collection
    const data = await getDocs(getQuery);

    // Map the document data to an array of categories with the document ID included
    const categoryList = data.docs.map((doc) => ({...doc.data(), _id: doc.id}));

    console.log('SHOW CATEGORY LIST')
    console.log(categoryList);

    // Dispatch an action of type GET_CATEGORIES with the categoryList as the payload
    dispatch({
        type: GET_COLLECTIONS,
        payload: categoryList
    });

  } catch (err) {
    
    console.log('ERROR!!!')
    console.log(err);

    // dispatch({
    //   type: COLLECTION_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status }
    // });
  }
};

// Get Locations by product id
export const getCollectionsByIdList = (menu_items) => async dispatch => {
  
  console.log('GETTING COLLECTIONS BY ID LIST');
  console.log(menu_items);

  const collectionArray = [];

  try {

    for (const menu_item of menu_items) {

      let collectionID = menu_item.collection_id;

      console.log('COLLECTION ID');
      console.log(collectionID);
      
      // Create a reference to the specified category document in the "categories" collection
      const docRef = doc(db, 'categories', collectionID)

      // Retrieve the document with the specified ID from the "categories" collection
      const categoryDoc = await getDoc(docRef);
      
      console.log('GOT COLLECTION BY ID');
      console.log(categoryDoc.data())

      let temp_CategoryData = {...categoryDoc.data(), _id: categoryDoc.id}

      if(collectionArray.length > 0) {
        if(collectionArray.filter(collectionItem => collectionItem._id.toString() === temp_CategoryData._id).length > 0) {
          console.log('Already added')
        } else {
          collectionArray.push(temp_CategoryData);
        }
      } else {
        collectionArray.push(temp_CategoryData);
      }
      
      console.log('COLLECTIONS ARRAY');
      console.log(collectionArray);
    }
    
    console.log('EXIT FOR LOOP')
    console.log(collectionArray)

    dispatch({
      type: GET_COLLECTIONS,
      payload: collectionArray
    });
  } catch (err) {
    console.log('ERROR!! ')
    console.log(err);

    dispatch({
      type: GET_COLLECTIONS,
      payload: []
    })
  }
};

// Get single collection by id
export const getCollectionById = id => async dispatch => {
  dispatch(setCollectionsLoading());

    try {

        // Create a reference to the specified category document in the "categories" collection
        const docRef = doc(db, 'categories', id)

        // Retrieve the document with the specified ID from the "categories" collection
        const categoryDoc = await getDoc(docRef);

        console.log('GOT COLLECTION BY ID');
        console.log(categoryDoc.data())
  
        if(categoryDoc.data()) {
            // Dispatch an action of type GET_COLLECTION with the category data and ID as the payload
            dispatch({
                type: GET_COLLECTION,
                payload: {
                    ...categoryDoc.data()
                }
            });
        } else {
            dispatch({
                type: GET_COLLECTION,
                payload: null
            });

            // dispatch(setAlert("Sorry, that collection doesn't exist.", "okay"));
        }
    } catch (err) {

        // Dispatch an action of type COLLECTION_ERROR with an error message and status code as the payload
        dispatch({
            type: COLLECTION_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        
        // Log the error to the console
        console.log('ERROR!!!')
        console.log(err);
    }
}

// Products loading
export const setCollectionsLoading = () => {
  return {
      type: COLLECTIONS_LOADING
  }
} 

// Remove all collections
export const clearCollections = () => dispatch => {
  dispatch({
      type: CLEAR_COLLECTIONS
  });
}

// Get Collections In Product
export const getProductCollections = productID => async dispatch => {

  console.log('GETTING COLLECTIONS IN PRODUCT')
  console.log(productID)

  dispatch(setCollectionsLoading());

  try {

    const collection_list = await getProductCollections_helper(productID);

    // Dispatch an action of type GET_ITEM_COLLECTIONS with the collection_obj_list as the payload
    dispatch({
        type: GET_ITEM_COLLECTIONS,
        payload: collection_list
    });

  } catch (err) {

      // Dispatch an action of type COLLECTION_ERROR with an error message and status code as the payload

      dispatch({
        type: COLLECTION_ERROR,
        payload: { msg:  "Something went wrong", status: err.status || 500 },
      });
      
      // Log the error to the console
      console.log('ERROR GETTING COLLECTIONS IN PRODUCT!!!')
      console.log(err);
  }
};

// --- Functions for Global Use ---

// Get Collections In Product
export const getProductCollections_helper = async productID => { // returns a promise

  console.log('HELPING GET COLLECTIONS IN PRODUCT')
  console.log(productID)

  try {

      const productRef = doc(db, 'products', productID)

      // Retrieve the document with the specified ID from the "products" collection
      const productDoc = await getDoc(productRef);

      const product_obj = {
          ...productDoc.data(),
          _id: productDoc.id
      }

      // Check if the collections field exists
      if(!product_obj.collections) {
        console.log('Item has no collections field');

        return [];
      } else {
        const collectionList = [...product_obj.collections];

        console.log('GOT COLLECTION IDS');
        console.log(collectionList)

        let collection_obj_list = [];
        // START loop
        for (const collectionID of collectionList) {

            // Create a reference to the specified collection document in the "categories" collection
            const collectionRef = doc(db, 'categories', collectionID);

            // Retrieve the document with the specified ID from the "categories" collection
            const collectionDoc = await getDoc(collectionRef);

            collection_obj_list.push({
                ...collectionDoc.data(),
                _id: collectionDoc.id
            });
        }

        console.log('GOT PRODUCT COLLECTIONS');
        console.log(collection_obj_list)

        return collection_obj_list;
      }

  } catch (err) {
      // Log the error to the console
      console.log('ERROR IN HELPING GET COLLECTIONS IN PRODUCT!!!')
      console.log(err);

      return [];
  }
};