import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { login, loginWithEmailAndPassword, addSocialHandle } from '../../actions/authActions';
import { setAlert } from '../../actions/alertActions';

// Layout Wrapper
import ModalPage from '../../components/layout/ModalPage';

// Components - imported
import { Button } from '@material-ui/core';
import Alert from '../../components/common/Alert';

// Icons -imported
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import miitoken_logo from '../../utils/imgs/miitoken_logo.png';



// Initial State
const initialState = {
    email: '',
    password: ''
}

const Flyer_1 = ({
    auth: { 
        isAuthenticated, 
        user 
    }, 
    nav: {
        campus_name,
        campus_ext
    },
    loginWithEmailAndPassword,
    addSocialHandle,
    setAlert,
    history
}) => {

    // State for tracking if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);
    
    // State for tracking the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // State for form data
    const [formData, setFormData] = useState(initialState);

    // State to toggle login methods
    const [loginWithPassword, setLoginWithPassword] = useState(true);

    // State to toggle email code inputs
    const [showEmailCode, setShowEmailCode] = useState(false);

    // INPUT FIELD
    const [socialHandle, setSocialHandle] = useState('');

    // Get the URL filter and check if there is a "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("redirect");
    const authMethod = url.searchParams.get("method");

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Handle the "redirect" parameter in the URL
    useEffect(() => {
        if(filter && filter === 'true') {
            setAlert('Failed: Wrong Email.', 'danger')
        } 

    }, [filter]);

    // Handle the Authentication Method via URL parameter
    useEffect(() => {
        if(authMethod && authMethod === 'password') {
            setLoginWithPassword(true)
        } 

        if(authMethod && authMethod === 'noPassword') {
            setLoginWithPassword(false)
        } 
    }, [authMethod]);

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Function to go back in history
    const goBack = (e) => {
        history.goBack();
    }

    // Check if the device is mobile or tablet
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Destructure the email value from form data
    const { email, password } = formData;

    // Function to handle input change
    const onChange = e => setSocialHandle(e.target.value);
    
    const handleSubmit = (e) => {
        e.preventDefault();

        if(socialHandle === '') {
            setAlert('Enter your Instagram username!', 'danger');
        } else {

            console.log('START ADD SOCIAL');
            addSocialHandle(socialHandle);
            
        }

    };

    // Function to handle Mixpanel event tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Flyer 1 Page View");
    }

    // Send Mixpanel event only in production and if not already sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (

        <div className='register_container login' style={{background: '#ECE1D5', display:'flex', flexDirection: 'column', padding: '20px'}}>
            <div className='register_content' style={{flex: 1}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '50px'}}>
                    <h3>What's your Instagram?</h3>
                    <div onClick={() => {window.location = '/register'}} className='transparent_btn'>
                        
                        <div style={{textAlign: 'center', flex: 1}}>
                            <div style={{color: '#000', fontWeight: 'bold', lineHeight: '23.8px', fontSize: '16px'}}>
                                Skip
                            </div>
                        </div>

                        <div style={{fontSize: '24px', color: '#000', marginTop: '5px'}}>
                            <ChevronRightIcon />
                        </div>
                    </div>
                </div>
                <div style={{boxSizing:'border-box', textAlign:'center'}}>
                    
                    {/* Title */}
                    <div style={{textAlign: 'center', fontWeight: 700, fontSize: '38px'}}>
                    10-MIN <br/> DELIVERY
                    </div>
                    

                        {/* Logo */}
                        <div onClick={() => window.location = '/'} className='register_logo' style={{flex: '3', height: '200px'}}>
                            <img src={miitoken_logo} />
                        </div>

                
                </div>

                            
                {/* Input & Label */}
                <div className='register_input_container login'>

                    <input 
                        // className='register_input_element login' 
                        type="text"
                        placeholder="Enter Your Instagram Handle"
                        className="input"
                        name="socialHandle"
                        value={socialHandle}
                        // maxlength="30"
                        onChange={e => onChange(e)}
                        
                    />
                </div>

                {/* Form Actions */}
                <div onClick={handleSubmit} className='register_btns' style={{display: 'flex', alignItems:'center', flexDirection:'column'}}>
                    <button 
                        style={{width: '100%'}}
                        className={`register_btn login active`}
                    >
                        Continue
                    </button>
                </div>
                
            </div>

            {/* Page footer - fixed to bottom of screen */}
    
            <div style={{textAlign: 'center', width: '100%', fontSize: '14px', lineHeight: '23px'}}>
                <span>
                    By continuing you agree to our <br/>
                    <a style={{textDecoration: 'none'}} href='/settings/terms'>
                        Terms of Service
                    </a>
                    {' '}& {' '}
                    <a style={{textDecoration: 'none'}} href='/settings/privacy'>
                        Privacy policy
                    </a>
                </span>
            </div>

        </div>
    )
}

// Prop types for the Login component
Flyer_1.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    addSocialHandle: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    loginWithEmailAndPassword,
    setAlert,
    addSocialHandle
})(Flyer_1);
