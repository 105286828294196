import React from 'react';

const DiscountModal = ({ children, shareGroup }) => {
    return (
        <div className={`discount-modal ${shareGroup ? 'shareModal' : ''}`}>
            {children}
        </div>
    )
}

export default DiscountModal;
