import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Actions
import { get_All_Discounts_From_CollectionList_helper } from '../../../actions/discountActions';
import { getProductCollections_helper } from '../../../actions/collectionActions';

const Discount_Disclaimer = ({
    productID
}) => {

    // --- Collection List & Available Discounts ---

    const [collectionList, setCollectionList] = useState([]);
    const [availableDiscounts, setAvailableDiscounts] = useState([]);
    const [discount_BuyXGetY, setDiscount_BuyXGetY] = useState(null); // State to hold the first "buy x get y" discount found
    const [discount_Amount_Off_Product, setDiscount_AmountOffProduct] = useState(null); // State to hold the first "amount off product" discount found

    // --- END: Collection List & Available Discounts ---

    useEffect(() => {

        if(productID) {
            handle_getProductCollections(productID);
        }
    }, [productID]);

    useEffect(() => {
        console.log('PROD COLLECTION LIST CHANGED')

        console.log('CALLING GET DISCOUNTS FROM PRODUCT CARD')
        handle_Get_All_Discounts_From_CollectionList(collectionList, availableDiscounts);

    }, [collectionList]);

    useEffect(() => {
        console.log('AVAILABLE DISCOUNTS LIST CHANGED')

        if(availableDiscounts?.length > 0) {
            console.log('CHECKING PROD CARD DISCOUNTS FOR TYPES');
            handle_Check_Discount_List(availableDiscounts);
        }

    }, [availableDiscounts]);

    const handle_getProductCollections = async (prodID) => {
        console.log('HANDLE GET PROD COL:');
        const collection_list = await getProductCollections_helper(prodID);

        console.log('COLLECTION BEST LIST:');
        console.log(collection_list);
        
        setCollectionList(collection_list);
    }

    const handle_Get_All_Discounts_From_CollectionList = async (collectionList, availableDiscounts) => {
        console.log('HANDLE GET ALL AVAILABLE PROD DISCOUNTS:');
        const discount_list = await get_All_Discounts_From_CollectionList_helper(collectionList, availableDiscounts);

        console.log('DISCOUNT BEST LIST:');
        console.log(discount_list);

        setAvailableDiscounts(discount_list);
    }

    const handle_Check_Discount_List = async (availableDiscounts) => {
        console.log('HANDLE GET ALL AVAILABLE PROD DISCOUNTS:');
    
        // Check if the available discounts array is empty or not provided
        if (!availableDiscounts || availableDiscounts.length === 0) {
            console.log('No available discounts for this product_card');
            return;
        }
    
        // Iterate through the available discounts array
        for (const discount of availableDiscounts) {
            // Check if the discount type is "buy x get y" and if it has not been set yet
            if (discount.discount_type === "buy x get y" && !discount_BuyXGetY) {
                setDiscount_BuyXGetY(discount); // Update the state with the discount object
                console.log('FOUND Discount Buy X Get Y:', discount);
            }
    
            // Check if the discount type is "amount off product" and if it has not been set yet
            if (discount.discount_type === "amount off product" && !discount_Amount_Off_Product) {
                setDiscount_AmountOffProduct(discount); // Update the state with the discount object
                console.log('FOUND Discount Amount Off:', discount);
            }
    
            // If both discounts have been found, break the loop early for efficiency
            if (discount_BuyXGetY && discount_Amount_Off_Product) {
                break;
            }
        }
    };

  return (
    <Fragment>
        {(discount_BuyXGetY && discount_BuyXGetY.discount_type === 'buy x get y' && discount_BuyXGetY.percentage_off_each && discount_BuyXGetY.min_quantity) && (
            <div className='cart_item_alert'>
                <div>
                    Buy {discount_BuyXGetY.min_quantity} Get {discount_BuyXGetY.getY_quantity} for {discount_BuyXGetY.percentage_off_each !== '100' ? `${discount_BuyXGetY.percentage_off_each}% OFF` : 'FREE'}
                </div>
            </div>
        )}

        {(discount_Amount_Off_Product && discount_Amount_Off_Product.discount_type === 'amount off products' && discount_Amount_Off_Product.amount_off_each && discount_Amount_Off_Product.min_quantity) && (
            <div className='cart_item_alert'>
                <div>
                    Buy {discount_Amount_Off_Product.min_quantity} Get {discount_Amount_Off_Product.getY_quantity} ${discount_Amount_Off_Product.amount_off_each} OFF
                </div>
            </div>
        )}
    </Fragment>
  )
}

Discount_Disclaimer.propTypes = {}

export default Discount_Disclaimer