import {
    PRODUCTS_LOADING,
    GET_PRODUCTS,
    SET_PRODUCTS,
    SET_LAST_PRODUCTS,
    GET_PRODUCT,
    CLEAR_PRODUCTS,
    CLEAR_PRODUCT,
    PRODUCT_ERROR,
    UPDATE_LAST_PRODUCT_ADDED,
    SET_LAST_PAGE_DOC,
    SET_SHOW_BOTTOM_SPINNER,
    SET_NO_MORE_PRODUCTS
} from '../actions/types';

const initialState = {
    products: [],
    detailProduct: null,
    no_more_products: false,
    loading: true,
    show_bottom_spinner: false,
    last_product_added: null,
    last_page_doc: 0,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case PRODUCTS_LOADING:

            // Set the loading state to true
            return {
                ...state,
                loading: true
            };
        case CLEAR_PRODUCTS:

            // Clear the products state
            return {
                ...state,
                products: []
            };
        case CLEAR_PRODUCT:

            // Clear the detailProduct state
            return {
                ...state,
                detailProduct: null
            };
        case GET_PRODUCTS:

            // Set the productS state to the payload value and set loading to false
            return {
                ...state,
                products: payload,
                loading: false
            };
        case SET_PRODUCTS: {

            // Merge the existing productS with the payload products, and update the products state
            const products = action.payload;
            let tempProducts = products; 
            
            if(state.products.length > 0) {
                tempProducts= [...state.products, ...tempProducts ];
            }

            return {
                ...state,
                products: tempProducts,
                loading: false
            };
        }
        case SET_LAST_PRODUCTS: {

            // Merge the existing products with the payload products, and update the products state
            const products = action.payload;
            let tempProducts = products; 
            
            if(state.products.length > 0) {
                tempProducts= [...state.products, ...tempProducts ];
            }

            return {
                ...state,
                products: tempProducts,
                no_more_products: true,
                loading: false
            };
        }
        case GET_PRODUCT:

            // Set the product state to the payload value and set loading to false
            return {
                ...state,
                detailProduct: payload,
                loading: false
            }
        case UPDATE_LAST_PRODUCT_ADDED:

            // Add the new product to the beginning of the products state and set loading to false
            return {
                ...state,
                last_product_added: payload,
            };
        case SET_LAST_PAGE_DOC:

            // Add the new product to the beginning of the products state and set loading to false
            return {
                ...state,
                last_page_doc: payload
            };
        case PRODUCT_ERROR:

            // Update the error state with the payload value and set loading to false
            return {
                ...state,
                error: payload,
                loading: false
            };
        case SET_SHOW_BOTTOM_SPINNER:
            return {
                ...state,
                show_bottom_spinner: payload
            };
        case SET_NO_MORE_PRODUCTS:
            return {
                ...state,
                no_more_products: payload
            };
        default:
            return state;
    }
}