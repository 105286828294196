import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Routing
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';

// Components - imported
import { Button } from '@material-ui/core';
import { toggleAuthModal, toggleProfileModal } from '../../actions/navActions';


// Displays if the user is logged in AND has set up profile
const HowToDeliver = ({ 
    auth: {
        isAuthenticated,
        user
    },
    toggleAuthModal,
    toggleProfileModal,
    page
}) => {
    
    // Redirect the user to the previous page or a specific URL when the "goBack" function is called
    const history = useHistory();

    const handleCreateDriverProfile = () => {
        if(isAuthenticated) {
            toggleProfileModal();
        } else {
            toggleAuthModal('driver register')
        }
    }

    // Render the component
    return (
        <div style={{padding: '16px', borderBottom: '1px solid #e6ecf0'}}>
            <div style={{color:'rgb(15, 20, 25)', marginBottom: '1.5rem', textAlign:'center', fontWeight:'700', fontSize:'24px', lineHeight:'24px', overflowWrap:'break-word'}}>
                    <span style={{fontSize: '1.125rem'}}>How to Deliver</span>
                </div>
                <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                    <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                        <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://cdn-icons-png.flaticon.com/512/2725/2725783.png" />
                    </div>
                    <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                            First, click 'Do This Delivery!'
                        </h5>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                            Do you have enough money to purchase the assigned items?
                        </h5>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                    <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                        <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://cdn2.iconfinder.com/data/icons/university-set-3/512/13-512.png" />
                    </div>
                    <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                            Next, go to the pick up spot
                        </h5>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                            We'll give you directions
                        </h5>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                    <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                        <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://cdn-icons-png.flaticon.com/512/4537/4537225.png" />
                    </div>
                    <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                            Scan & purchase the assigned items
                        </h5>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                            Don't worry, we'll pay you back the full amount + tips!
                        </h5>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                    <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                        <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://www.svgrepo.com/show/60859/home-delivery-service.svg" />
                    </div>
                    <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                            Lastly, complete the delivery
                        </h5>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                            Go to the customer, and hand-over the items
                        </h5>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                    <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                        <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://cdn-icons-png.flaticon.com/512/5372/5372515.png" />
                    </div>
                    <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                            DON'T FORGET!
                        </h5>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                            Collect the customer's code or else we won't be able to pay you
                        </h5>
                    </div>
                </div>

                {page === 'how to deliver' && (
                    <div>
                        <Button 
                            variant="outlined" 
                            className="sidebar__tweet" 
                            fullWidth
                            onClick={() => history.goBack()}
                        >
                            Go Back
                        </Button>
                    </div>
                )}
        </div>
    )
}

// Prop types for the component
HowToDeliver.propTypes = {

    // Prop type validation for function to toggle the Authentication modal
    auth: PropTypes.object.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    toggleProfileModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { 

    // Connecting actions from Redux to the component
    toggleAuthModal,
    toggleProfileModal
})(HowToDeliver);
