import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { toggle_QuitDelivery_Modal } from '../../../actions/navActions';
import { cancel_And_Refund_Order, remove_Driver_From_Order } from '../../../actions/orderActions';

// Modal
import ModalContainer from '../../../components/modal/ModalContainer';
import Modal from '../../../components/modal/Modal';

// Icons - material UI
import CheckIcon from '@material-ui/icons/Check';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

// Components - imported
import { Button, Avatar } from '@material-ui/core';
import DeliveryItem from './DeliveryItem';
import Spinner from '../../../components/common/Spinner';

const DeliveryDetail = ({
    detailOrder,
    updateOrderStatus,
    transferPayout,
    setAlert,
    auth: {
        user
    },
    nav: {
        driver_quitDeliveryModal,
        campus_name,
    },
    toggle_QuitDelivery_Modal,
    cancel_And_Refund_Order, 
    remove_Driver_From_Order
}) => {

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Modal toggles
    const [customerCodeModal, setCustomerCodeModal] = useState(false);
    const [confirmPickupModal, setConfirmPickupModal] = useState(false);
    const [quitDeliveryModal, setQuitDeliveryModal] = useState(false);

    // Orders List
    const [cartList, setCartList] = useState([]);
    const [gotCartList, setGotCartList] = useState(false);

    // Delivery Status
    const [pickupConfirmed, setPickupConfirmed] = useState(false);
    const [hasArrived, setHasArrived] = useState(false);
    const [hasFinished, setHasFinished] = useState(false);
    const [customerCode, setCustomerCode] = useState('');

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const status = url.searchParams.get("status");

    // - Timer ----

    const [timer, setTimer] = useState('00:00:00');

    const getTimeRemaining = (e) => {

        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);

        return {
            total, hours, minutes, seconds
        };
    }
 
    const startTimer = (e) => {
        
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
                    
        if (total >= 0) {
 
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable

            if(hours == 0) {
                setTimer(
                    (minutes > 9 ? minutes : '0' + minutes) + ':'
                    + (seconds > 9 ? seconds : '0' + seconds)
                )
            } else {
                setTimer(
                    (hours > 9 ? hours : '0' + hours) + ':' +
                    (minutes > 9 ? minutes : '0' + minutes) + ':'
                    + (seconds > 9 ? seconds : '0' + seconds)
                )
            }
        }
    }
 
    const clearTimer = (e) => {
 
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next   
        setTimer('00:00');
 
        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
 
    const getDeadTime = (remainingSeconds) => {
        let deadline = new Date();
 
        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(remainingSeconds);
        return deadline;
    }

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
 
    // Check URL 'status' param to update page content
    useEffect(() => {

        if(status) {
            if(detailOrder?.items_picked?.length === detailOrder?.cart.items.length) {
                if(status === 'transit') {
                    setPickupConfirmed(true);
                } else if(status === 'arrived') {
                    setPickupConfirmed(true);
                    setHasArrived(true);
                } else if(status === 'delivered') {
                    setPickupConfirmed(true);
                    setHasArrived(true);
                    setHasFinished(true);
                } else {
                    window.location = `/delivery/${detailOrder._id}`
                }
            }  else {
                window.location = `/delivery/${detailOrder._id}`
            }
        } 

        function formatTimestamp(timestamp) {
            const seconds = timestamp.seconds;
            const nanoseconds = timestamp.nanoseconds;
          
            const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1e6); // Convert nanoseconds to milliseconds
            const isoDate = new Date(milliseconds).toISOString();
          
            return isoDate;
        }


        if(detailOrder?.est_complete_time_ISO) {
            
            const isoFormattedDate = formatTimestamp(detailOrder.est_complete_time_ISO);

            const targetDateTime = isoFormattedDate; // Replace with your desired target date and time in ISO format
            const remainingSeconds = secondsUntilDateTime(targetDateTime);

            console.log('SETTING TIMER');
            console.log(detailOrder.est_complete_time_ISO)
            console.log(detailOrder.est_complete_time_ISO.seconds)
            console.log(isoFormattedDate)

            clearTimer(getDeadTime(remainingSeconds));
        }
    }, []);

    // Redirect to 'Delivered' page if order status is finished
    useEffect(() => {

        if(status && status !== "delivered" && detailOrder?.status === "delivered" && detailOrder._id) {
            window.location = `/delivery/${detailOrder._id}?status=delivered`
        } 
    }, [detailOrder]);

    useEffect(() => {

        if(hasFinished && user.stripe_onboarding_complete && detailOrder.driver_paid !== true) {
            transferPayout(detailOrder, user.stripe_account_id)
        }

    }, [hasFinished, detailOrder]);

    const secondsUntilDateTime = (targetDateTime) => {
        const currentDate = new Date();
        const targetDate = new Date(targetDateTime);
        
        if (targetDate <= currentDate) {
            return 0; // The target date and time has already passed
        }
        
        const timeDifference = targetDate - currentDate;
        const secondsRemaining = timeDifference / 1000; // Convert milliseconds to seconds
        
        return Math.floor(secondsRemaining);
    }

    // - END: Timer ----

    const {
        cart: {
            items,
            total,
            subtotal,
            tax,
            qty,
            tip
        }
    } = detailOrder

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    useEffect(() => {

        renderCartList();
    }, [pickupConfirmed]);

    // Handle Customer Code input
    const onChange = e => setCustomerCode(e.target.value);

    const renderCartList = () => {
        setCartList([]);

        if(items === null) {
            setCartList([(
                <div className="no-rides">
                    <h1>Empty</h1>
                </div>
            )])
        }
        else { 
            if(items.length > 0) {

                items.map(order_item => {
                    setCartList(cartList => [...cartList, (
                        <DeliveryItem
                            detailOrder={detailOrder}
                            orderItem={order_item}
                            pickupConfirmed={pickupConfirmed}
                        />
                    )])
                });

                setGotCartList(true);
            } else {
                setCartList([(
                    <div className="no-rides">
                        <h1>Empty</h1>
                    </div>
                )])
            }
        } 
    }

    const confirmPickup = () => {
        updateOrderStatus(detailOrder, "in transit");
    }

    // Toggle 'Start delivery' Modal
    const toggleConfirmPickupModal = () => {
        if(detailOrder?.items_picked?.length === detailOrder?.cart.items.length) {
            setConfirmPickupModal(!confirmPickupModal);
        }
    }

    // Toggle 'Start delivery' Modal
    const toggleCustomerCodeModal = () => {
        setCustomerCodeModal(!customerCodeModal);
    }

    const handleHasArrived = () => {
        updateOrderStatus(detailOrder, "arrived");
    }

    const handleFinishDelivery = () => {
        if(customerCode === detailOrder._id.substring(0, 4).toLowerCase()) {
            updateOrderStatus(detailOrder, "delivered");
        } else {
            setAlert('Wrong code.', 'danger')
        }
    }

    // Toggle 'Start delivery' Modal
    const toggleQuitDeliveryModal = () => {
        toggle_QuitDelivery_Modal();
    }

    const handleQuitDelivery = () => {

       // -- convert date object represented as {seconds, nanoseconds} to the number of milliseconds elapsed since the epoch
       // -- for Date.now comparison

        // Convert seconds and nanoseconds to milliseconds
        const formatted_exp_date = (detailOrder.est_complete_time_ISO.seconds * 1000) + (detailOrder.est_complete_time_ISO.nanoseconds / 1000000);

        // If the current time is passed the expiration date
        if(Date.now() > formatted_exp_date) {

            // Refund the customer, 
            // Remove the driver from order 
            // Update order status to 'driver cancelled'
            cancel_And_Refund_Order(campus_name, detailOrder, user)
        } else {

            // Remove the driver
            // Update order status to 'waiting'
            // -> so someone else can pick up the delivery offer
            remove_Driver_From_Order(campus_name, detailOrder._id, user, detailOrder)
        }
    }

    return (
        <Fragment>
            <div className="orderPage">

                {hasArrived ? (
                    <div className="orderPage__body">
                        {!(hasFinished) ? (
                            <Fragment>
                                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', flexDirection: 'column', justifyContent:'flex-start'}}>
                                    <h1>Enter Customer Code</h1>
                                    {/* Disclaimer text */}
                                    <div style={{marginTop: '10px', color: 'rgb(83, 100, 113)', fontSize:'15px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                                        <span>Enter the 4-digit code we sent to the customer.</span>
                                    </div>
                                </div>
                                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', justifyContent:'flex-start', borderBottom:'1px solid #e6ecf0'}}>
                                    
                                    {/* Input */}
                                    <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', justifyContent:'center'}}>
                                        <input
                                            type="text"
                                            placeholder="Customer Code"
                                            className="input"
                                            name="customerCode"
                                            value={customerCode}
                                            onChange={e => onChange(e)}
                                            // maxlength="30"
                                            style={{width: '100%'}}
                                        />
                                    </div>
                                </div>

                                {/* Submit */}
                                <div style={{width: '100%', boxSizing:'border-box', padding:'0 10px', display:'flex', justifyContent:'flex-end'}}>
                                    <Button onClick={handleFinishDelivery} variant="outlined" className={`sidebar__tweet`} fullWidth>Finish Delivery</Button>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', flexDirection: 'column', justifyContent:'flex-start'}}>
                                    <h1>Delivery Complete!</h1>
                                </div>
                                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', borderBottom:'1px solid #e6ecf0'}}>
                                    
                                    <div style={{padding: '10px 8px'}}>
                                        <div style={{minHeight: '40px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                                            <h3>You got paid:</h3>
                                            <div style={{fontSize: '16px'}}>
                                                $8.25
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{padding: '10px 8px'}}>
                                        <div style={{minHeight: '40px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                                            <h3>You spent:</h3>
                                            <div style={{fontSize: '16px'}}>
                                                $5
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{padding: '10px 8px'}}>
                                        <div style={{minHeight: '40px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                                            <h2>Total profit:</h2>
                                            <div style={{fontSize: '16px'}}>
                                                $3.25
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>

                                <div style={{width: '100%', boxSizing:'border-box', padding:'0 10px'}}>
                                    <Button onClick={() => window.location = '/offers'} variant="outlined" className={`sidebar__tweet`} fullWidth>Got It</Button>
                                    <Button onClick={() => window.location = '/settings/payments'} variant="outlined" className={`sidebar__tweet ghost`} fullWidth>View Payments</Button>
                                </div>
                            </Fragment>
                        )}
                </div>
                ) : (
                    <div className="orderPage__body">
                        {/* Banner Image */}

                        {/* <div style={{width: '100%', flex: 1, display: 'flex', justifyContent: 'center'}}>
                            <div style={{height: '171.818px', maxWidth: '672px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: '8px', overflow: 'hidden', border: '1px solid #e6ecf0', position: 'relative'}}>
                                <h2 style={{fontWeight: '400', marginBottom: '5px'}}>Your order arrived in:</h2>
                                <h1 style={{color: 'rgb(29, 155, 240)', fontSize: '40px', marginRight: '-40px'}}>{timer} <span style={{fontWeight: '400', fontSize: '24px'}}>mins</span></h1>
                            </div>
                        </div> */}

                        {!pickupConfirmed ? (
                            <div style={{marginBottom: '16px', paddingBottom: '16px', textAlign: 'center', width: '100%', borderBottom: '1px solid #e6ecf0'}}>
                                <h1>Pick-up from:</h1>
                                <div style={{marginTop: '8px'}}>
                                    <h3>The Dollar Tree</h3>
                                    <h3 style={{color: 'rgb(29, 155, 240)'}}>1614 W Lindsey St</h3>
                                    <h4>Norman, OK 73069</h4>
                                </div>
                                <a href="https://www.google.com/maps/dir/35.1925164,-97.420025/the+dollar+tree+lindset/@35.1923556,-97.4858587,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x87b2684b601abcbb:0xb93b14674c473c50!2m2!1d-97.4652554!2d35.2029696?entry=ttu" target="_blank">
                                    <Button variant="outlined" className="sidebar__tweet black" fullWidth>Get Directions</Button>
                                </a>
                            </div>
                        ) : (
                            <div style={{marginBottom: '16px', paddingBottom: '16px', textAlign: 'center', width: '100%', borderBottom: '1px solid #e6ecf0'}}>
                                <h1>Final Destination:</h1>
                                <div style={{marginTop: '8px'}}>
                                    <h3 style={{color: 'rgb(29, 155, 240)'}}>{detailOrder.location_details.street_number}{' '}{detailOrder.location_details.street_name}</h3>
                                    {detailOrder.location_details.room_number && (
                                        <h4>Room: {detailOrder.location_details.room_number}</h4>
                                    )}
                                    <h4>{detailOrder.location_details.city}{', '}{detailOrder.location_details.state}{' '}{detailOrder.location_details.postalCode}</h4>
                                </div>
                                <a href={`https://www.google.com/maps/search/?api=1&query=${detailOrder.formatted_address}&query_place_id=${detailOrder.location_details.placeId}`} target="_blank">
                                    <Button variant="outlined" className="sidebar__tweet black" fullWidth>Get Directions</Button>
                                </a>
                            </div>
                        )}

                        {/* <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                            <h3>Deliver 3 items</h3>
                            {detailOrder?.est_complete_time && <span style={{marginTop: '3px'}}>Est. Delivery Time: {detailOrder.est_complete_time} </span>}
                        </div> */}

                        {/* <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                            <h3>Delivered!</h3>
                            <span style={{marginTop: '3px'}}>Completed Oct 14, 2022, 2:49 PM </span>
                        </div> */}

                        {!pickupConfirmed ? (
                            <Fragment>
                                <div onClick={() => window.location = '/delivery/howto'} className='checkout_form_toggle'>
                                    <div style={{minWidth:'100%', minHeight:'48px', alignItems:'center', display:'flex', padding:'8px 0', boxSizing:'border-box'}}>
                                        <div style={{marginRight:'12px', height:'40px', alignItems:'center', justifyContent:'center', display:'flex', width:'40px', padding:'6px', borderRadius:'40px'}}>
                                            <HelpOutlineOutlinedIcon />
                                        </div>
                                        <div style={{flexGrow: 1}}>
                                            <h2 style={{fontWeight:'bold', lineHeight:'23.8px', fontSize:'18px'}}>
                                                How To Deliver
                                            </h2>
                                        </div>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <div style={{fontSize:'32px', marginLeft:'32px'}}>
                                                <ChevronRightIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 {/* <div onClick={() => window.location = '/settings/help'} className='checkout_form_toggle'>
                                    <div style={{minWidth:'100%', minHeight:'48px', alignItems:'center', display:'flex', padding:'8px 0', boxSizing:'border-box'}}>
                                        <div style={{marginRight:'12px', height:'40px', alignItems:'center', justifyContent:'center', display:'flex', width:'40px', padding:'6px', borderRadius:'40px'}}>
                                            <HeadsetMicOutlinedIcon />
                                        </div>
                                        <div style={{flexGrow: 1}}>
                                            <h2 style={{fontWeight:'bold', lineHeight:'23.8px', fontSize:'18px'}}>
                                                Customer Support
                                            </h2>
                                            <p style={{margin: '6px 0', margin: '0', fontSize: '14px'}}>
                                                Have an issue? Chat with us.
                                            </p>
                                        </div>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <div style={{fontSize:'32px', marginLeft:'32px'}}>
                                                <ChevronRightIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </Fragment>
                        ) : (
                            <div className='checkout_form_toggle'>
                                <div style={{minWidth:'100%', borderBottom: '1px solid #e6ecf0', minHeight:'48px', alignItems:'center', display:'flex', padding:'8px 0', boxSizing:'border-box'}}>
                                    <div style={{marginRight:'12px', height:'40px', alignItems:'center', justifyContent:'center', display:'flex', width:'40px', padding:'6px', borderRadius:'40px'}}>
                                        <Avatar src='https://firebasestorage.googleapis.com/v0/b/mycontacts-9ba5a.appspot.com/o/images%2FprofilePics%2Fp3TQfcImGgWB5joQ7mpRHvwLSuZ2.JPG?alt=media&token=f77711f0-f898-43ad-b8fd-f758aa980e56' />
                                    </div>
                                    <div style={{flexGrow: 1}}>
                                        <h2 style={{fontWeight:'bold', lineHeight:'23.8px', fontSize:'18px'}}>
                                            Hi, I'm<span style={{color: 'rgb(83, 100, 113)', fontWeight: 400, fontSize: '16px', marginLeft: '3px'}}>({detailOrder.user.first_name})</span>
                                        </h2>
                                        <p style={{margin: '6px 0', margin: '0', fontSize: '14px'}}>
                                            Have an issue? Call me.
                                        </p>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <a href={`tel:${detailOrder.phone}`} target="_blank">
                                            <div style={{fontSize:'32px', color: 'rgb(29, 155, 240)', width: '40px', height: '40px', borderRadius: '50%', border: '1px solid #e6ecf0', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight:'16px'}}>
                                                <PhoneInTalkIcon />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="orderPage__section payment">

                            <div>
                                <h3>
                                    {!pickupConfirmed ? 'Buy' : 'Deliver'} these items:
                                </h3>

                            </div>
                            {/* Spacing element */}
                            <div style={{height: '50px'}}>
                                
                            </div>
                        </div>

                        <div className="orderPage__items">
                            <h3>Items ({qty})</h3>

                            {!cartList.length > 0 || !gotCartList ? <Spinner /> : cartList}
                        </div>

                        <div style={{height: '16px'}}></div>

                        {!pickupConfirmed ? (
                            <Button onClick={toggleConfirmPickupModal} variant="outlined" className={`sidebar__tweet ${detailOrder?.items_picked?.length === detailOrder?.cart.items.length ? 'black' : 'disabled'}`} fullWidth>Confirm Pickup</Button>
                        ) : (
                            <Button onClick={toggleCustomerCodeModal} variant="outlined" className="sidebar__tweet" fullWidth>Enter Customer Code</Button>
                        )}

                        

                        <div style={{width: '100%', marginTop: '24px', minWidth: '300px'}}>
                            <div onClick={toggleQuitDeliveryModal} style={{color: 'red', textAlign: 'center', lineHeight: '23.8px', width: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                                Quit Delivery
                            </div>
                        </div>

                        <div style={{height: '100px'}}></div>

                    </div>
                )}

            </div>

            {/* Confirm pick-up modal */}
            <ModalContainer show={confirmPickupModal} onClose={toggleConfirmPickupModal}>
                <Modal>

                    {/* Title */}
                    <div>
                        <h2>Confirm Pickup</h2>
                    </div>

                    {/* Description */}
                    <p>Are you sure?</p>
                    
                    {/* Actions */}
                    <Button 
                        onClick={confirmPickup}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Yes, continue
                    </Button>

                    <Button 
                        onClick={toggleConfirmPickupModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>

            {/* Set Has Arrived modal */}
            <ModalContainer show={customerCodeModal} onClose={toggleCustomerCodeModal}>
                <Modal>

                    {/* Title */}
                    <div>
                        <h2>Enter Customer Code</h2>
                    </div>

                    {/* Description */}
                    <p>Ask <b>Sarah</b> for the code!</p>
                    
                    {/* Actions */}
                    <Button 
                        onClick={handleHasArrived}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Enter Code
                    </Button>

                    <Button 
                        onClick={toggleCustomerCodeModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>

            {/* Quit delivery modal */}
            <ModalContainer show={driver_quitDeliveryModal} onClose={toggleQuitDeliveryModal}>
                <Modal>

                    {/* Title */}
                    <div>
                        <h2>Quit Delivery</h2>
                    </div>

                    {/* Description */}
                    <p>Are you sure?</p>
                    
                    {/* Actions */}
                    <Button 
                        onClick={handleQuitDelivery}
                        variant="outlined" 
                        className="sidebar__tweet danger"
                        fullWidth
                    >
                        Yes, Quit
                    </Button>

                    <Button 
                        onClick={toggleQuitDeliveryModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

DeliveryDetail.propTypes = {
    nav: PropTypes.object.isRequired,
    toggle_QuitDelivery_Modal: PropTypes.func.isRequired,
    cancel_And_Refund_Order: PropTypes.func.isRequired, 
    remove_Driver_From_Order: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    nav: state.nav
});

export default connect(mapStateToProps, { 

  // Connecting actions from redux to the component
  toggle_QuitDelivery_Modal,
  cancel_And_Refund_Order, 
  remove_Driver_From_Order

})(DeliveryDetail);
