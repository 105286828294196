import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Google Maps API
import { useLoadScript } from '@react-google-maps/api';

// Redux
import { connect } from 'react-redux';

// Actions
import { getUnreadCount } from '../../actions/notificationActions';
import { getUnreadChats } from '../../actions/chatActions';
import { addLastModifiedFieldToChats } from '../../actions/chatActions';
import { add_campus_id_to_all_posts } from '../../actions/postActions';
import { toggleLocationModal, goBack } from '../../actions/navActions';
import { getLocation, setDeliveryTime } from '../../actions/locationActions';

// Routing
import { Link, useHistory, useLocation } from 'react-router-dom';

// Components - imported
import Widgets from '../Widgets/Widgets';
import { Avatar } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import Delivery_Widget from '../../Marketplace/common/Delivery_Widget';

// Icons - material UI
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ScheduleIcon from '@material-ui/icons/Schedule';

// IMGS
import miitoken_logo from '../../utils/imgs/miitoken_logo.png';

const Layout = ({ 
    getUnreadCount,
    getUnreadChats,
    children, 
    user, 
    page, 
    auth, 
    nav: {
        campus_name
    }, 
    chat, 
    cart: {
        cart
    },
    zone: {
        detailZone,
    },
    notification, 
    notificationsPage, 
    sideNav, 
    handleSlideMenu, 
    totalPosts,
    handleScroll,
    addLastModifiedFieldToChats,
    add_campus_id_to_all_posts,
    
    // -- Shop Landing Page ---

    locationState: {
        detailLocation,
        deliveryTime,
        loading
    },
    toggleLocationModal,
    goBack,
    setDeliveryTime,
    getLocation
    // -- END: Shop Landing Page ---
}) => {
    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [authDropdown, setAuthDropdown] = useState(false);
    
    const [badgeValue, setBadgeValue] = useState(0);

    // Retrieve the current location using the "useLocation" hook from React Router
    let location = useLocation()

    // Get the current URL and extract the "filter" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("filter");

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;
    const notFullScreen = windowWidth <= 1170;

    // Google Maps API
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"]
    });

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Apply CSS classes to the feed header based on the value of "sideNav" and "isMobile"
    useEffect(() => {
        if(!isMobile) {
            if(sideNav) {
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                    return "feed__header";
                }
            } else {
                if(document.getElementById('feed-header') !== null) {
                    setTimeout(() => {
                        document.getElementById('feed-header').classList.add("active");
                    }, 700)
                }
            }
        } else {
            if(document.getElementById('feed-header') !== null) {
                document.getElementById('feed-header').classList.remove("active");
                return "feed__header";
            }
        }

        if(!isTablet) {
            if(profileDropdown) setProfileDropdown(false);
        }
    }, [sideNav, isMobile, isTablet])

    // Fetch unread notification count and unread chat count when the authenticated user changes
    useEffect(() => {
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [auth.user]);

    // Update the badge value based on the counts of unread notifications and unread chats
    useEffect(() => {
        setBadgeValue(notification.num_unread + chat.num_unread_chats);
    }, [notification.num_unread, chat.num_unread_chats])

    // ---- Delivery Location Logic -----
    useEffect(() => {

        getLocation();

    }, []);

    // -- Get the Delivery Time estimate
    useEffect(() => {

        // Create a callback function to handle Distance Matrix Service response
        const callback = (response) => {
            console.log('DRIVING DISTANCE MATRIX');
            console.log(response);
            console.log('MATRIX TIME: ', response.rows[0].elements[0].duration.text);
            console.log('MATRIX DISTANCE: ', response.rows[0].elements[0].distance.text);
            setDeliveryTime(response.rows[0].elements[0].duration.text)
        };

        if(isLoaded && detailLocation && detailZone?.location_details?.coordinates) {
        
            // Create a new DistanceMatrixService instance
            const distanceMatrixService = new window.google.maps.DistanceMatrixService();
        
            // Call the Distance Matrix API
            distanceMatrixService.getDistanceMatrix(
            {
                destinations: [detailLocation.coordinates],
                // Measure delivery time based on the time it takes to get from the customer to the Delivery Zone
                origins: [{lng: detailZone.location_details.coordinates.lng, lat: detailZone.location_details.coordinates.lat}], 
                travelMode: "DRIVING",
            },
                callback
            );
        }
    }, [isLoaded, detailLocation, detailZone]);

    // ---- END: Delivery Location Logic -----

    // const getUnreadCountN = async () => {
    //     if(auth.user) {
    //         const unreadNotifications = await axios.get(`/api/notifications/unread/${auth.user._id}`);
        
    //         console.log('LAYOUT  UNREAD NOTIFICATIONS #');
    //         console.log(unreadNotifications.data);

    //         const tempNum = unreadNotifications.data + badgeValue;

    //         console.log('LAYOUT UNREAD MESSAGES FINAL');
    //         console.log(tempNum)
    //         setBadgeValue( prev => prev + unreadNotifications.data );
    //     }
    // }

    // const getUnreadCountM = async () => {
    //     if(auth.user) {
    //         const unreadMessages = await axios.get(`/api/chats/unread/${auth.user._id}`);
        
    //         console.log('LAYOUT UNREAD MESSAGES #');
    //         console.log(unreadMessages.data);

    //         const tempNum = unreadMessages.data + badgeValue;

    //         console.log('LAYOUT UNREAD MESSAGES FINAL');
    //         console.log(tempNum);
    //         setBadgeValue( prev => prev + unreadMessages.data );
    //     }
    // }

    // Redirect the user to the previous page or a specific URL when the "goBack" function is called
    const history = useHistory();
    // const goBack = () => {

    //     let previousURL = document.referrer;

    //     var pathArray = previousURL.split( '/' );
    //     var host = pathArray[2];

    //     console.log(pathArray);

    //     // Check the source of the previous URL and navigate accordingly
    //     if((typeof(host) !== 'undefined' && host.length) && (host.toLowerCase().includes("miitoken") || host.toLowerCase().includes("localhost:3000") || host.toLowerCase().includes("mycontacts-9ba5a"))) {
    //         if(pathArray[4] && pathArray[4].toLowerCase().includes("set-up")) {
    //             window.location.href = '/';
    //         } else if(pathArray[3] && pathArray[4] && pathArray[3].toLowerCase().includes("messages")) {
    //             window.location.href = `/${pathArray[3]}/${pathArray[4]}`;
    //         } else if(previousURL.toLowerCase().includes("?filter=welcome")) {
    //             window.location.href = '/';
    //         } else {
    //             // history.goBack();
    //             window.location.href = previousURL;
    //         }
    //     } else {
    //         window.location.href = '/';
    //     }

    // }

    const goBackFromPost = () => {

        let previousURL = document.referrer;

        var pathArray = previousURL.split( '/' );
        var host = pathArray[2];

        console.log(pathArray);

        // Check the source of the previous URL and navigate accordingly
        if((typeof(host) !== 'undefined' && host.length) && (host.toLowerCase().includes("miitoken") || host.toLowerCase().includes("localhost:3000") || host.toLowerCase().includes("mycontacts-9ba5a"))) {
            if(pathArray[4] && pathArray[4].toLowerCase().includes("set-up")) {
                window.location.href = '/home';
            } else if(pathArray[5] && pathArray[5].toLowerCase().includes("likes")) {
                window.location.href = '/home';
            } else if(pathArray[3] && pathArray[4] && pathArray[3].toLowerCase().includes("messages")) {
                window.location.href = `/${pathArray[3]}/${pathArray[4]}`;
            } else if(pathArray[3] && pathArray[4] && pathArray[3].toLowerCase().includes("post")) {
                window.location.href = previousURL;
            } else if(pathArray[3] && pathArray[3].toLowerCase().includes("search")) {
                window.location.href = previousURL;
            } else if(pathArray[3] && (pathArray[3].toLowerCase().includes("notifications") || pathArray[3].toLowerCase().includes("saved"))) {
                history.goBack();
            } else if(previousURL.toLowerCase().includes("?filter=welcome")) {
                window.location.href = '/home';
            } else {
                // history.goBack();
                window.location.href = '/home';
            }
        } else {
            window.location.href = '/home';
        }

    }

    let user_name;

    if(!auth.loading && auth.isAuthenticated) {
        user_name = `${auth.user.first_name} ${auth.user.last_name && auth.user.last_name}`
    }

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(auth.user && auth.user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = auth.user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }


    return (
        <div className="feed__container">
            <div onScroll={handleScroll} className="feed">
                {/**Header */}
                <div className="feed__header" id="feed-header">
                        
                    {/*
                        Render the header content based on the current page and filter
                        Display a menu icon for mobile devices and handle slide menu if clicked
                        Display the appropriate header text based on the filter value
                        Display a badge with the badgeValue if it's greater than 0 and it's a mobile device
                    */}
      
                    {notificationsPage && (
                        <Fragment>
                            <div style={{ marginRight: '20px', marginLeft: '20px', marginBottom: '15px', display: 'flex', justifyContent: 'space-between', flexGrow: '1'}}>
                                {/* Header with menu icon and header text */}
                                <h2 style={{display:'flex', alignItems: 'center', fontSize:'20px'}}>
                                
                                    <div onClick={goBack} style={{marginRight: '10px'}} className="shopFeed__backBtn noMargin">
                                        <div>
                                            <ArrowBackIcon />
                                        </div>
                                    </div>

                                    {/* Alerts Badge: displayed if it's a mobile device and badgeValue is greater than 0 */}
                                    {isMobile && badgeValue > 0 ? (
                                        <span className="feed-header-badge home">
                                            {badgeValue}
                                        </span>
                                    ) : null}

                                    {/* Header text */}
                                    Alerts

                                </h2>
                                
                            </div>
                            
                        </Fragment>
                    )}

                    {/* Orders Page Content */}
                    {page === 'orders' && <h2 onClick={isTablet ? handleSlideMenu : undefined}>My Orders</h2>}


                    {(page === 'grocery' || page === 'offers') && (
                        <Fragment>
                            <div style={{ marginRight: '20px', marginLeft: '20px', marginBottom: '15px', display: 'flex', justifyContent: 'space-between', flexGrow: '1'}}>
                                {/* Header with menu icon and header text */}
                                <h2 style={{display:'flex'}} onClick={isTablet ? handleSlideMenu : undefined}>
                                    {isMobile && (
                                        <Fragment>
                                            <div style={{marginRight:'15px'}}>
                                                <MenuIcon />
                                            </div>

                                            <div> 
                                                <img className="sidebar__twitterIcon" src={miitoken_logo} style={{maxHeight: '24px', marginLeft: '0px', marginBottom: '0px'}} alt="logo" />
                                            </div>
                                        </Fragment>
                                    )}

                                    {/* Display the header text */}
                                    {page === 'grocery' && 'Grocery'}
                                    {page === 'offers' && 'Delivery'}

                                    {/* Alerts Badge: displayed if it's a mobile device and badgeValue is greater than 0 */}
                                    {isMobile && badgeValue > 0 ? (
                                        <span className="feed-header-badge home">
                                            {badgeValue}
                                        </span>
                                    ) : null}
                                </h2>
                                
                                <div style={{position:'relative', display: 'flex'}}>
                                    {/* <div className="post__avatar layoutHeader" style={{ color: 'rgb(83, 100, 113)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px', marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                        <NightsStayOutlinedIcon />
                                    </div> */}

                                    {/* {!auth.loading && !auth.isAuthenticated ? (
                                        <div onClick={() => setAuthDropdown(!authDropdown)} className="post__avatar layoutHeader" style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                            <Avatar />
                                        </div>
                                    ) : (
                                        notFullScreen && (
                                            <div onClick={() => setProfileDropdown(!profileDropdown)} className="post__avatar layoutHeader" style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>

                                                {auth.user && auth.user.img ? (
                                                    <Avatar src={optimized_profile_img_path} />
                                                ) : (
                                                    <DefaultAvatar name={auth.user && `${auth.user.first_name} ${auth.user.last_name}`} />
                                                )}
                                            </div>
                                        )
                                    )} */}

                                    <ShoppingCartOutlinedIcon />
                                    

                                    {/* Dropdown Menu */}
                                    <div className={profileDropdown ? "profile-dropdown-topNavbar active" : "profile-dropdown-topNavbar"}>
                                        <div onClick={() => window.location = `/${auth.user ? auth.user.username : 'profile'}`} className="menu-item" style={isMobile ? {padding: '1rem 1rem 0px 1rem'} : undefined}>
                                            <div className='sidebar__profile topNav'>
                                                <div className="post__avatar">

                                                    {auth.user && auth.user.img ? (
                                                        <Avatar src={optimized_profile_img_path} />
                                                    ) : (
                                                        <DefaultAvatar name={auth.user && `${auth.user.first_name} ${auth.user.last_name}`} />
                                                    )}
                                                </div>
                                                <div  className='sidebar__profile__text__container'>
                                                    <div className='sidebar__profile__text'>
                                                        <div>
                                                            <span>
                                                                {user_name}
                                                            </span>
                                                        </div>
                                                        {auth.user && (
                                                            <div>
                                                                <div>
                                                                    <span>@{auth.user.username}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />
                                        
                                        <div onClick={() => window.location = '/saved'} className="menu-item">
                                            My Saved
                                        </div>

                                        <div onClick={() => window.location = '/settings/account'} className="menu-item">
                                            Account Settings
                                        </div>

                                        <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />
                                        
                                        <div onClick={() => window.location = '/logout'} className="menu-item danger">
                                            Log Out
                                        </div>

                                        {/* <div onClick={() => addLastModifiedFieldToChats()} className="menu-item danger">
                                            Update chats
                                        </div> */}
                                    </div>

                                    {/* Hidden Overlay for dropdown */}
                                    <div
                                        className={`hidden-overlay ${profileDropdown ? "show" : ""}`}
                                        onClick={() => setProfileDropdown(!profileDropdown)}
                                    />

                                    {/* Auth Dropdown Menu */}
                                    <div className={authDropdown ? "profile-dropdown-topNavbar active" : "profile-dropdown-topNavbar"}>
                                        <div className='auth_dropdown__topNavbar'>
                                            <div className='auth_dropdown_avatar'>
                                                <Avatar />
                                            </div>
                                            <h1>
                                                Sign up or log in to your account.
                                            </h1>
                                            <p>Takes less than a few seconds.</p>
                                            <div className='register_btns'>
                                                <button 
                                                    onClick={() => window.location = '/register'}
                                                    style={{width: '100%', height:'50px'}}
                                                    className={`register_btn login active`}
                                                >
                                                    Sign Up
                                                </button>
                                                <button 
                                                    onClick={() => window.location = '/login'}
                                                    className={`register_btn login hoverBlue`}
                                                    style={{width: '100%', height:'50px', background:'transparent', color:'rgb(24, 105, 245)', border:'1px solid rgb(24, 105, 245)'}}
                                                >
                                                    Log in
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Hidden Overlay for Auth dropdown */}
                                    <div
                                        className={`hidden-overlay ${authDropdown ? "show" : ""}`}
                                        onClick={() => setAuthDropdown(!authDropdown)}
                                    />
                                    
                                </div>
                                
                            </div>
                        </Fragment>
                    )}

                    {page === 'saved' && (
                        <Fragment>

                            {/* Header with menu icon and header text */}
                            <div style={{display:'flex', alignItems:'center'}} className="profile__headerText likePage">
                                
                                <div onClick={isTablet ? handleSlideMenu : undefined} style={{display:'flex', alignItems:'center'}}>
                                    {/* Menu Icon: displayed if mobile */}
                                    {isMobile && (
                                        <div style={{marginRight:'15px'}}>
                                            <MenuIcon />
                                        </div>
                                    )}

                                    {/* Title and username */}
                                    <div>
                                        <h3 style={{fontWeight: 800}}>
                                            Saved
                                        </h3>
                                        <p style={{fontSize: '13px', color: 'rgb(83, 100, 113)'}}> @{auth.user.username}</p>
                                    </div>

                                    {/* Alerts Badge: displayed if it's a mobile device and badgeValue is greater than 0 */}
                                    {isMobile && badgeValue > 0 ? (
                                        <span className="feed-header-badge home">
                                            {badgeValue}
                                        </span>
                                    ) : null}
                                </div>

                                <div style={{flex: 1}} />

                                <div style={{marginRight: '20px', position:'relative', display: 'flex'}}>
                                    {/* <div className="post__avatar layoutHeader" style={{ color: 'rgb(83, 100, 113)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px', marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                        <NightsStayOutlinedIcon />
                                    </div> */}

                                    <div onClick={() => window.location = '/search'} className="post__avatar layoutSearch">
                                        <SearchIcon />
                                    </div>

                                    {notFullScreen && (
                                        <div onClick={() => setProfileDropdown(!profileDropdown)} className="post__avatar layoutHeader" style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>

                                            {auth.user && auth.user.img ? (
                                                <Avatar src={optimized_profile_img_path} />
                                            ) : (
                                                <DefaultAvatar name={auth.user && `${auth.user.first_name} ${auth.user.last_name}`} />
                                            )}
                                        </div>
                                    )}

                                    {/* Dropdown Menu */}
                                    <div className={profileDropdown ? "profile-dropdown-topNavbar active" : "profile-dropdown-topNavbar"}>
                                        <div onClick={() => window.location = `/${auth.user ? auth.user.username : 'profile'}`} className="menu-item" style={isMobile ? {padding: '1rem 1rem 0px 1rem'} : undefined}>
                                            <div className='sidebar__profile topNav'>
                                                <div className="post__avatar">
                                                    {auth.user && auth.user.img ? (
                                                        <Avatar src={optimized_profile_img_path} />
                                                    ) : (
                                                        <DefaultAvatar name={auth.user && `${auth.user.first_name} ${auth.user.last_name}`} />
                                                    )}
                                                </div>
                                                <div  className='sidebar__profile__text__container'>
                                                    <div className='sidebar__profile__text'>
                                                        <div>
                                                            <span>
                                                                {user_name}
                                                            </span>
                                                        </div>
                                                        {auth.user && (
                                                            <div>
                                                                <div>
                                                                    <span>@{auth.user.username}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />
                                        
                                        <div onClick={() => window.location = '/saved'} className="menu-item">
                                            My Saved
                                        </div>

                                        <div onClick={() => window.location = '/settings/account'} className="menu-item">
                                            Account Settings
                                        </div>

                                        <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />
                                        
                                        <div onClick={() => window.location = '/logout'} className="menu-item danger">
                                            Log Out
                                        </div>
                                    </div>

                                    {/* Hidden Overlay for dropdown */}
                                    <div
                                        className={`hidden-overlay ${profileDropdown ? "show" : ""}`}
                                        onClick={() => setProfileDropdown(!profileDropdown)}
                                    />
                                </div>
                                    
                            </div>

                        </Fragment>
                    )}
                    
                    {/* Shop Landing Page */}

                    {page === 'grocery landing' && (
                        <div className="layout__profileHeader">

                            <div onClick={isTablet ? handleSlideMenu : undefined} style={{display:'flex', alignItems:'center'}}>
                                {/* Menu Icon: displayed if mobile */}
                                {isMobile && (
                                    <Fragment>
                                        <div style={{marginRight:'15px'}}>
                                            <MenuIcon />
                                        </div>

                                        {badgeValue > 0 && (
                                            <span className="feed-header-badge defaultBadge">
                                                {badgeValue}
                                            </span>
                                        )}
                                    </Fragment>
                                )}
                            
                            </div>

                            <Delivery_Widget />

                            {/* Cart Icon */}
                            {!isTablet && (
                                <div onClick={() => {window.location = '/cart'}} style={{height: '44px', boxSizing: 'border-box', cursor: 'pointer', background:'#000', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '64px', overflow:'hidden', paddingLeft: '8px'}}>
                                    <div style={{height: '40px', marginRight: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', borderRadius: '64px', position: 'relative'}}>
                                        <div style={{height: '40px', display: 'flex', alignItems: 'center', width: '40px', justifyContent: 'center', padding: '6px', borderRadius: '40px'}}>
                                            <div style={{fontSize: '24px', color: '#fff'}}>
                                                <LocalMallOutlinedIcon />
                                            </div>
                                        </div>
                                        {cart.qty > 0 && (
                                            <div style={{position: 'absolute', minWidth: '18px', background: 'rgba(250,216,105,1.00)', left: '21px', height: '18px', top: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1px', borderRadius: '24px'}}>
                                                <div style={{lineHeight: '15.8px', fontWeight: 'bold', fontSize: '12px'}}>
                                                    {cart.qty}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div style={{textAlign: 'center', marginRight:'40px', paddingRight: '24px', flex: 1}}>
                                        <div style={{color: '#fff', fontWeight: 'bold', lineHeight: '23.8px', fontSize: '18px'}}>
                                            My Bag
                                        </div>
                                    </div>
                                </div>
                            )}

                            {isTablet && (
                                <div onClick={() => window.location = '/cart'} className="shopFeed__cog">
                                    <ShoppingCartOutlinedIcon />

                                    {cart.qty > 0 && (
                                        <span className="notification-badge badge_for_top_cart">
                                            {cart.qty}
                                        </span>
                                    )}

                                </div>
                            )}
                                
                        </div>
                    )}
                    
                </div>

                {/* Display Layout content (posts, notifications, etc.) */}
                {children}
            </div>

            {/* Display 'website stats' box (widgets) if not tablet */}
            {/* {!notFullScreen && <Widgets />} */}
        </div>
    )
}

Layout.propTypes = {
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
    // Prop type validation for navigation
    nav: PropTypes.object.isRequired,
    // Prop type validation for notification
    notification: PropTypes.object.isRequired,
    // Prop type validation for chat
    chat: PropTypes.object.isRequired,
    locationState: PropTypes.object.isRequired,
    zone: PropTypes.object.isRequired,
    // Prop type validation for function to get unread notifications
    getUnreadCount: PropTypes.func.isRequired,
    // Prop type validation for function to get unread chats
    getUnreadChats: PropTypes.func.isRequired,
    addLastModifiedFieldToChats: PropTypes.func.isRequired,
    add_campus_id_to_all_posts: PropTypes.func.isRequired,
    toggleLocationModal: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    setDeliveryTime: PropTypes.func.isRequired,
    getLocation: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    // Mapping the AUTHENTICATION state
    auth: state.auth,
    // Mapping the NAVIGATION state
    nav: state.nav,
    // Mapping the NOTIFICATION state
    notification: state.notification,
    // Mapping the CHAT state
    chat: state.chat,
    // Mapping the LOCATION state
    locationState: state.location,
    cart: state.cart,
    zone: state.zone
})

export default connect(mapStateToProps, {
    
    // Connecting actions to the component
    getUnreadCount, 
    getUnreadChats,
    addLastModifiedFieldToChats,
    add_campus_id_to_all_posts,
    toggleLocationModal,
    goBack,
    setDeliveryTime,
    getLocation
})(Layout);
