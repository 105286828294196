import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Firebase
import { db } from '../../../../utils/firebase-config';
import { collection, getDocs, doc} from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { addToCart, decrement, removeItem } from '../../../../actions/cartActions';

// Components
import ImageBox from './ImageBox';
import Discount_Disclaimer from '../../Discount_Components/Discount_Disclaimer';

// Icons
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const Product_Card = ({
    detailProduct,
    cart,
    addToCart,
    decrement,
    removeItem,
    page
}) => {

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [productShowImg, setProductShowImg] = useState(null);
    const [gotProductImgs, setGotProductImgs] = useState(false);

    const [imgLoaded, setImgLoaded] = useState(false);

    const [isInCart, setIsInCart] = useState(false);
    const [cartQty, setCartQty] = useState(0);

    const { items } = cart;

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    useEffect(() => {

        if(detailProduct && !gotProductImgs) {
            // Fetch product images
            getProductImgs()

            // Don't fetch imgs again after this
            setGotProductImgs(true);
        }
    }, [detailProduct]);

    useEffect(() => {
        
        checkCartStatus()
    }, [cart]);

    // Function to get post images
    async function getProductImgs() {

        const docRef = doc(db, 'products', detailProduct._id)
        const colRef = collection(docRef, "img_gallery")

        console.log('FETCHING IMGS');

        try {
            console.log('GALLERY DATA')

            const galleryData = await getDocs(colRef);
            const galleryList = galleryData.docs.map((doc) => ({...doc.data(), id: doc.id}));

            console.log(galleryList);

            for (const gallery_img of galleryList) {

                // --- ImageKit (img optimization service) ---
   
                // Replace the firebase URL with ImageKit.io URL-endpoint
                gallery_img.img_path = gallery_img.img_path.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");

                if(gallery_img.img_order === 1) {
                    setProductShowImg(gallery_img);
                }
            }

            setGotProductImgs(true);

        } catch (err) {

            console.log('ERROR:');
            console.log(err);
        }
    }

    const checkCartStatus = () => {
        setIsInCart(false);

        if(items.length > 0) {
            items.map(item => {
                if(item.item?._id === detailProduct?._id) {
                    setIsInCart(true);
                    setCartQty(item.qty);
                }
            })
        }
    }

    const handleAddToCart = () => {

        addToCart(detailProduct._id, productShowImg.img_path)
    }

    const handleDecrement = () => {
        if(cartQty) {
            if(cartQty >= 2) {
                decrement(detailProduct._id)
            } else {
                removeItem(detailProduct._id)
            }
        }
    }

    // let prod_class = null;

    // if(page === "category_main") {
    //     prod_style = {width: '25%', marginBottom: '24px', marginLeft: '4px', height: '100%', padding: '4px', borderRadius: '8px'}
    // } else {
    //     prod_class = {}
    // }

    let prod_link = "#";
    let prod_name = null;

    if(detailProduct) {
        prod_link = `/details/${detailProduct._id}`;
        prod_name = detailProduct.name
    }

    return (
        <div style={{width: `${(isMobile && page === 'category_main') ? '30%' : '176px'}`, minWidth: `${(isMobile && page === 'category_main') ? 'auto' : `${page === 'category' ? '126px' : '166px'}`}`, marginLeft: '4px', height: `auto`, maxHeight: `${isMobile ? '250px' : 'auto'}`, padding: '4px', borderRadius: '8px'}}>
            <div style={{position: 'relative'}}>
                <a style={{cursor: 'pointer'}} href={prod_link}>
                    <div style={{borderTopRightRadius: '4px', borderTopLeftRadius: '4px', position: 'relative', width: '100%', overflow: 'hidden'}}>
                        {productShowImg && <ImageBox img_obj={productShowImg} imgLoaded={imgLoaded} setImgLoaded={setImgLoaded} />}
                    </div>
                </a>
                {imgLoaded && (
                    <div style={{position: 'absolute', zIndex: 10, bottom: '0px', width: '100%', cursor: 'pointer'}}>
                        <div style={{position: 'relative', minHeight: `${(isMobile && page === 'category_main') ? '28px' : '36px'}`, display: 'flex'}}>
                            {isInCart && (
                                <div style={{width: '100%', height: '100%', bottom: '0px', right: '0px', opacity: 1, position: 'absolute', borderRadius: '64px', border: '2px solid #fff', padding: '2px', flex: 1, backgroundColor: 'rgba(236,238,233,1.00)', display: 'flex', justifyContent: 'flex-end'}}>
                                    <div onClick={handleDecrement} style={{borderRadius: '16px', height: '100%', width: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#fff', boxSizing: 'border-box'}}>
                                        <DeleteOutlineIcon />
                                    </div>
                                    <div style={{paddingRight: `${(isMobile && page === 'category_main') ? '28px' : '16px'}`, fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1}}>
                                        {cartQty}
                                    </div>
                                </div>
                            )}
                            <div style={{position: 'absolute', bottom: '0px', right: '0px'}}>
                                <div style={{height: `${(isMobile && page === 'category_main') ? '32px' : '36px'}`, width: `${!isMobile ? '36px' : '32px'}`, padding: '2px', borderRadius: '64px'}}>
                                    <div style={{borderColor: '#fff', borderStyle:'solid', borderRadius: '64px', borderWidth: '2px', height: `${!isMobile ? '32px' : '28px'}`, width: `${!isMobile ? '32px' : '28px'}`}}>
                                        <div onClick={handleAddToCart} style={{borderRadius: '50%', borderColor: 'rgb(0, 127, 219)', borderStyle:'solid', borderWidth: '2px', height: `${!isMobile ? '28px' : '24px'}`, width: `${!isMobile ? '28px' : '24px'}`, overflow:'hidden', background: 'rgba(0,164,255,1.00)', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <div style={{fontSize: '24px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <AddIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{position: 'absolute', bottom: '0px', right: '0px'}}>
                                <div style={{height: '36px', width: '36px', padding: '2px', borderRadius: '64px'}}>
                                    <div style={{borderColor: '#fff', borderStyle:'solid', borderRadius: '64px', borderWidth: '2px', height: '32px', width: '32px'}}>
                                        <div onClick={handleAddToCart} style={{borderRadius: '50%', borderColor: 'rgb(0, 127, 219)', borderStyle:'solid', borderWidth: '2px', height: '28px', width: '28px', overflow:'hidden', background: 'rgba(0,164,255,1.00)', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <div style={{fontSize: '24px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <AddIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                )}
            </div>
            {imgLoaded && (
                <a href={prod_link} style={{cursor: 'pointer', marginTop: '8px', textDecoration: 'none'}}>
                    <span className='line-clamp' style={{color: '#000', lineHeight: '15.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontSize: '12px', fontWeight: 'bold', overflow: 'hidden'}}>
                        {detailProduct?.name}
                    </span>
                    <div style={{margin: '2px 0'}}>
                        <span style={{color: '#000', lineHeight: '15.8px', fontSize: '12px', fontWeight: 'bold'}}>
                            ${detailProduct?.price}
                        </span>
                    </div>
                    <span style={{color: 'rgb(92, 92, 92)', lineHeight: '15.8px', maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '12px', overflow: 'hidden'}}>
                        {/* 22oz */}
                    </span>
                </a>
            )}

            <Discount_Disclaimer productID={detailProduct._id} />
        </div>
    )
}

Product_Card.propTypes = {
    cart: PropTypes.object.isRequired,
    addToCart: PropTypes.func.isRequired,
    decrement: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired

}

const mapStateToProps = state => ({
    cart: state.cart.cart
})

export default connect(mapStateToProps, { addToCart, decrement, removeItem })(Product_Card);