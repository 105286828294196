import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';
import { format, parseISO, fromUnixTime, formatDistanceToNow, isToday, isTomorrow } from 'date-fns';

// Google Maps API
import { useLoadScript, DistanceMatrixService } from '@react-google-maps/api';

// Draft JS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

// Firebase
import { db, auth, storage } from "../../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { getProductById } from '../../actions/productActions';
import { addToCart, decrement, removeItem } from '../../actions/cartActions';
import { setPage, add_To_Recent_History, toggleModal_DiscountList_Preview } from '../../actions/navActions';
import { getProductLocations } from '../../actions/locationActions';
import { getProductCollections } from '../../actions/collectionActions';

// Layout Wrapper
import ShopLayout from '../../components/layout/ShopLayout';

// Components
import ImageOverview from '../components/ImageOverview/ImageOverview';
import HowItWorks from '../common/HowItWorks';
import LocationList from '../components/LocationList/LocationList';
import DiscountsBlock from '../components/ProductPage_Components/DiscountsBlock';

// Icons
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const ProductPage = ({
    product: {
        detailProduct
    },
    locationState: {
        detailLocation,
        locations
    },
    collection: {
        item_collections
    },
    cart,
    getProductById,
    addToCart,
    decrement, 
    removeItem,
    setPage,
    add_To_Recent_History,
    getProductLocations,
    getProductCollections,
    toggleModal_DiscountList_Preview
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Product Description
    const [descriptionState, setDescriptionState] = useState(null);
    const [gotDescription, setGotDescription] = useState(false);

    // Product Imgs
    const [productImgs, setProductImgs] = useState(null);
    const [gotProductImgs, setGotProductImgs] = useState(false);

    // Show Img Index
    const [showImage, setShowImage] = useState(0);

    // Cart Value
    const [isInCart, setIsInCart] = useState(false);
    const [cartQty, setCartQty] = useState(0);

    // Delivery Time & Distance
    const [deliveryTime, setDeliveryTime] = useState(null);
    const [deliveryDistance, setDeliveryDistance] = useState(null);
    const [locationCoordinates, setLocationCoordinates] = useState(null);

    // Google Maps API
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"]
    });

    const { items, qty } = cart;

    // Get URL params
    const { productID } = useParams();

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        setPage('product detail');
        add_To_Recent_History(url.pathname);  // current url

        getProductById(productID);

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    useEffect(() => {
        
        checkCartStatus()
    }, [cart]);

    useEffect(() => {

        if(detailProduct) {
            getProductLocations(detailProduct._id);
            getProductCollections(detailProduct._id);
        };

    }, [detailProduct]);

    useEffect(() => {

        if(locations.length > 0) {
            fetchLocationData();
        }
        
    }, [locations]);

    // -- Get the Delivery Time estimate
    useEffect(() => {

        // Create a callback function to handle Distance Matrix Service response
        const callback = (response) => {
            console.log('DRIVING DISTANCE MATRIX');
            console.log(response);
            console.log('MATRIX TIME: ', response.rows[0].elements[0].duration.text);
            console.log('MATRIX DISTANCE: ', response.rows[0].elements[0].distance.text);
            
            setDeliveryDistance(response.rows[0].elements[0].distance.text)

            const deliveryTime_in_seconds = response.rows[0].elements[0].duration.value

            // Get the current time
            var currentTime = new Date();

            // Add 195 seconds to the current time
            currentTime.setSeconds(currentTime.getSeconds() + deliveryTime_in_seconds);

            // Now, `currentTime` holds the time after adding 195 seconds
            console.log("Time after adding seconds:", currentTime);

            const today_or_tomo = getTodayOrTomorrowString(currentTime);
            const exact_time = format(currentTime, "h:mm a");
            const display_time = today_or_tomo + " " + exact_time;

            console.log("Time to display:", display_time);

            setDeliveryTime(display_time)
        };

        if(isLoaded && detailLocation && locationCoordinates) {
        
            // Create a new DistanceMatrixService instance
            const distanceMatrixService = new window.google.maps.DistanceMatrixService();
        
            // Call the Distance Matrix API
            distanceMatrixService.getDistanceMatrix(
            {
                destinations: [detailLocation.coordinates],
                // Measure delivery time based on the time it takes to get to the center of the OU campus (origin based on GMaps data)
                origins: [{lng:locationCoordinates.lng, lat:locationCoordinates.lat}], 
                travelMode: "DRIVING",
            },
                callback
            );
        }
    }, [isLoaded, detailLocation, locationCoordinates]);

    function getTodayOrTomorrowString(date) {
        if (isToday(date)) {
            return 'Today';
        } else if (isTomorrow(date)) {
            return 'Tomorrow';
        } else {
            return format(date, 'MMMM do, yyyy'); // Format the date normally if neither today nor tomorrow
        }
    }

    const fetchLocationData = async () => {
        try {
            // Create a reference to the specified post document in the "posts" collection
            const docRef = doc(db, 'locations', locations[0]._id)

            // Retrieve the document with the specified ID from the "posts" collection
            const locationDoc = await getDoc(docRef);
    
            console.log('ITEM DETAIL LOCATION DATA');
            console.log(locationDoc.data())

            setLocationCoordinates(locationDoc.data().location_details.coordinates)

        } catch (err) {
            
            // Log the error to the console
            console.log(err);
        }
    }

    const checkCartStatus = () => {
        setIsInCart(false);

        if(items.length > 0) {
            items.map(item => {
                if(item.item._id === productID) {
                    setIsInCart(true);
                    setCartQty(item.qty);
                }
            })
        }
    }

    // Function to get post images
    async function getProductImgs() {
        
        const docRef = doc(db, 'products', productID)
        const colRef = collection(docRef, "img_gallery")

        // console.log('FETCHING IMGS')

        try {

            // console.log('GALLERY DATA')

            const galleryData = await getDocs(colRef);
            let galleryList = galleryData.docs.map((doc) => {

                // --- ImageKit (img optimization service) ---
   
                // Replace the firebase URL with ImageKit.io URL-endpoint
                const optimized_img_path = doc.data().img_path.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");

                return ({...doc.data(), img_path: optimized_img_path, id: doc.id})
            });

            // console.log(galleryList);

            if(galleryList.length > 0) {
                galleryList = galleryList.sort((a, b) => a.img_order - b.img_order);
            }

            setProductImgs(galleryList)
            setGotProductImgs(true);
        } catch (err) {
            console.log('ERROR:');
            console.log(err);
        }
    }

    const changeImage = (index) => {
        setShowImage(index)
    }

    // Decrement Show Img
    const decImage = () => {
        let tempImgIndex = showImage - 1; 
        const imgLength = productImgs.length - 1;

        if(showImage > 0) {
            setShowImage(tempImgIndex);
        } else {
            setShowImage(imgLength);
        }
    }

    // Inceremnt Show Img
    const incImage = () => {

        console.log('INCREASE IMG');
        let tempImgIndex = showImage + 1; 
        const imgLength = productImgs.length - 1;

        if(showImage < imgLength) {
            setShowImage(tempImgIndex);
        } else {
            setShowImage(0)
        }
    }

    // Decrement cart value
    const handleDecrement = () => {
        if(cartQty) {
            if(cartQty >= 2) {
                decrement(productID)
            } else {
                removeItem(productID)
            }
        }
    }

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more products are met
        // if (newScrollY >= scrollHeight - 1 && !showBottomSpinner && !noMoreProducts && filter !== null && products.length > 0) {
        //     console.log('scrolling');
        //     loadMore();
        // }
    }

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Product Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // Check if post images are not fetched yet and detailProduct exists
    if(!gotProductImgs && detailProduct !== null) {

        // Fetch post images
        getProductImgs()

        // Don't fetch imgs again after this
        setGotProductImgs(true);
    }

    if (detailProduct && !gotDescription) {
        console.log('DESCRIPTION BLOCK')

        let unRawState;

        if(detailProduct.description !== null) {
            unRawState = EditorState.createWithContent(
                convertFromRaw(JSON.parse(detailProduct.description))
            );
        
            console.log(unRawState)

            console.log(draftToHtml(convertToRaw(unRawState.getCurrentContent())))
            
            const unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

            setDescriptionState(unRawDescription);
        }

        setGotDescription(true)
    }

    if(detailProduct) {
        return (
            <ShopLayout handleScroll={handleScroll} page="detail">
                <div style={{width: '100%', padding: '0 15px', boxSizing: 'border-box'}}>
                    {/* Breadcrumbs */}
                    {/* <ul className="breadcrumbs">
                        <li className="breadcrumbs__item">
                            <a href="#" className="breadcrumbs__link">Marketplace</a>
                        </li>
                        <li className="breadcrumbs__item">
                            <a href="#" className="breadcrumbs__link breadcrumbs__link--active">Trending</a>
                        </li>
                    </ul> */}

                    <div style={{width: '100%', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: `${isTablet ? 'column' : 'row'}`, boxSizing:'border-box'}}>
                        
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', marginRight: '20px'}}>
                            <div className="product-detail-image-container showImage" style={{position: 'relative',}} onClick={incImage}>
                                {(!isTablet && gotProductImgs && productImgs?.length > 0) && (
                                    <img src={productImgs[showImage].img_path} className="img-fluid" alt="product" />
                                )}

                                {(gotProductImgs && productImgs?.length > 1) && (
                                        <div className="detail-image-overlay">
                                            <div className="detail-overlay-icon-container left">
                                                <KeyboardArrowLeftIcon />
                                            </div>
                                            <div className="detail-overlay-icon-container right">
                                                <KeyboardArrowRightIcon />
                                            </div>
                                        </div>
                                )}
                            </div>

                            {(!isTablet && gotProductImgs && productImgs?.length > 1) && (
                                <div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                    <div style={{marginTop:'10px'}}>
                                        <p style={{color:'#808080', fontWeight:'bold', fontSize:'12px'}}>
                                            <i style={{marginRight:'10px'}} class="far fa-hand-pointer"></i> 
                                            Click image for more photos...
                                        </p>
                                    </div>
                                    
                                    <div className="product-detail-main-container">
                                        {(gotProductImgs && productImgs?.length > 0) && (
                                            <Fragment>
                                                {productImgs.map((image, index) => {
                                                    if (index === showImage) {
                                                        return (
                                                            <div className="product-detail-image-container">
                                                                <img key={index} style={{border:'2px solid rgb(29, 155, 240)'}} onClick={() => changeImage(index)} src={productImgs[index].img_path} alt={detailProduct.name} />
                                                                <div className="detail-image-overlay" onClick={() => changeImage(index)}></div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="product-detail-image-container">
                                                                <img key={index} onClick={() => changeImage(index)} src={productImgs[index].img_path} alt={detailProduct.name} />
                                                                <div className="detail-image-overlay" onClick={() => changeImage(index)}></div>
                                                            </div>
                                                        ) 
                                                    }
                                                })}
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* <div style={{width:'100%', display:'flex'}}>
                                <div style={{width:'100%', minWidth:'138px', display:'flex', justifyContent:'center'}}>
                                    <div style={{height:'260px', display:'flex', alignItems:'center', width:'100%'}}>
                                        <div style={{width:'228px', margin: '0 auto', position:'relative'}}>
                                            {productImgs && <img style={{width:'100%', height:'100%', objectFit:'contain', aspectRatio:'1 / 1'}} src={productImgs[0].img_path} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <div style={{background:'rgba(0,0,0,1.00)', borderRadius:'4px', padding:'4px', margin:'4px'}}>
                                </div>
                                <div style={{background:'rgba(0,0,0,0.20)', borderRadius:'4px', padding:'4px', margin:'4px'}}>
                                </div>
                                <div style={{background:'rgba(0,0,0,0.20)', borderRadius:'4px', padding:'4px', margin:'4px'}}>
                                </div>
                            </div> */}
                        </div>

                        {(isTablet && productImgs?.length > 0) && <ImageOverview images={productImgs} changeImage={changeImage} showImage={showImage} detailProduct={detailProduct} isTablet={isTablet} />}

                        <div style={{marginTop:'32px', display:'flex', flexDirection:'column', marginBottom:'16px', width:'100%', flex: 1}}>
                            {/* <div style={{display:'flex', alignSelf: 'flex-start'}}>
                                <div style={{backgroundColor:'rgb(170, 200, 245)', padding: '0 8px', borderRadius:'64px', display: 'flex', justifyContent: 'center', minWidth: '24px', minHeight: '24px', alignItems:'center'}}>
                                    <div style={{lineHeight: '15.8px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize:'12px', overflow:'hidden'}}>
                                        COLD
                                    </div>
                                </div>
                            </div> */}
                            <p className='line-clamp' style={{marginTop: '4px', lineHeight: '24px', fontSize: '18px', fontWeight: 'bold', overflow: 'hidden'}}>
                                {detailProduct.name}
                            </p>

                            {deliveryTime && (
                                <p className='line-clamp' style={{color:'rgb(92, 92, 92)', marginTop: '4px', fontSize: '14px', overflow: 'hidden'}}>
                                    Delivery by <span style={{color: 'rgb(49 159 41)', fontWeight: '600'}}>{deliveryTime}</span>
                                </p>
                            )}

                            <div style={{marginBottom: '20px', marginTop: '16px', display: 'flex', alignItems: 'center'}}>
                                <div style={{lineHeight: '23.8px', fontWeight: 'bold', fontSize: '30px', color: '#AE0000'}}>
                                    ${detailProduct.price}
                                </div>
                      
                            </div>
                            {/* <LocationList productID={productID} detailProduct={detailProduct} productImgs={productImgs} /> */}

                            {/* <div style={{height: '32px', display: 'flex', justifyContent: 'space-between', marginBottom: '16px', alignItems: 'center'}}>
                                <div style={{marginRight: '4px', lineHeight: '18.5px', fontSize: '14px'}}>
                                    52oz
                                </div>
                            </div> */}

                            {/* Available Discounts Section */}
                            
                            <DiscountsBlock 
                                collectionList={item_collections}
                                page="product detail"
                                toggleModal_DiscountList_Preview={toggleModal_DiscountList_Preview}
                            />
                            
                            {/* END: Available Discounts Section */}

                            {!isMobile && (
                                <Fragment>
                                    {!isInCart ? (
                                        <div style={{width: '100%', marginBottom: '24px', minWidth: '300px'}}>
                                            <div onClick={() => addToCart(productID, productImgs[0].img_path)} style={{backgroundColor: 'rgb(0, 164, 255)', borderColor: 'rgb(0, 164, 255)', minWidth: '88px', minHeight: '48px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                                                <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                                                    Add To Bag
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{marginBottom: '24px', width: '100%', minWidth: '300px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <div style={{display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'64px', height:'48px', overflow:'hidden', position:'relative', flex: '0.4'}}>
                                                <div style={{display:'flex', cursor:'pointer', alignItems:'center', padding:'2px', boxSizing:'border-box', background:'rgb(236, 238, 233)', borderRadius:'64px', height:'100%', position:'absolute', width:'100%'}}>
                                                    <div onClick={handleDecrement} style={{borderRadius:'19px', height:'38px', width:'38px', background:'#fff', cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                        <RemoveIcon />
                                                    </div>
                                                    <div style={{alignItems:'center', justifyContent:'center', flex:1, display:'flex'}}>
                                                        <div style={{color:'rgb(0, 0, 0)', lineHeight:'23.8px', fontWeight: 'bold', fontSize: '18px', }}>
                                                            {cartQty}
                                                        </div>
                                                    </div>
                                                    <div onClick={() => addToCart(productID, productImgs[0].img_path)} style={{borderRadius:'19px', height:'38px', width:'38px', color: '#fff', background:'rgba(0,164,255,1.00)', cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                        <AddIcon />
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{marginLeft: '8px', display: 'flex', flex: '0.6'}}>
                                                <div onClick={() => {window.location = '/cart'}} style={{height: '48px', cursor: 'pointer', background:'#000', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '64px', overflow:'hidden', paddingLeft: '8px', flex: 1}}>
                                                    <div style={{height: '40px', marginRight: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', borderRadius: '64px', position: 'relative'}}>
                                                        <div style={{height: '40px', display: 'flex', alignItems: 'center', width: '40px', justifyContent: 'center', padding: '6px', borderRadius: '40px'}}>
                                                            <div style={{fontSize: '24px', color: '#fff'}}>
                                                                <LocalMallOutlinedIcon />
                                                            </div>
                                                        </div>
                                                        {qty > 0 && (
                                                            <div style={{position: 'absolute', minWidth: '18px', background: 'rgba(250,216,105,1.00)', left: '21px', height: '18px', top: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1px', borderRadius: '24px'}}>
                                                                <div style={{lineHeight: '15.8px', fontWeight: 'bold', fontSize: '12px'}}>
                                                                    {qty}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div style={{textAlign: 'center', marginRight:'40px', paddingRight: '24px', flex: 1}}>
                                                        <div style={{color: '#fff', fontWeight: 'bold', lineHeight: '23.8px', fontSize: '18px'}}>
                                                            My Bag
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            )}

                            <div style={{lineHeight: '18.5px', fontSize: '14px', marginBottom: '20px'}}>
                                {descriptionState !== null && (<div dangerouslySetInnerHTML={{__html: descriptionState}} />)}
                            </div>
                            
                            <hr/>
                            <HowItWorks />
                            
                        </div>
                    </div>
                </div>

                <div style={{height: '100px'}}></div>
            </ShopLayout>
        )
    } else {
        return <></>
    }
}

ProductPage.propTypes = {
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    toggleModal_DiscountList_Preview: PropTypes.func.isRequired,
    getProductById: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    decrement: PropTypes.func.isRequired, 
    removeItem: PropTypes.func.isRequired,
    getProductLocations: PropTypes.func.isRequired,
    getProductCollections: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    locationState: PropTypes.object.isRequired,
    collection: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    product: state.product,
    cart: state.cart.cart,
    locationState: state.location,
    collection: state.collection
})

export default connect(mapStateToProps, { 
    setPage,
    add_To_Recent_History,
    toggleModal_DiscountList_Preview,
    getProductById, 
    addToCart, 
    decrement, 
    removeItem,
    getProductLocations,
    getProductCollections
})(ProductPage);