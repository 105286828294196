import { 
    GET_ORDER, 
    GET_ORDERS, 
    CLEAR_ORDER, 
    CLEAR_ORDERS, 
    ORDERS_LOADING,
    ORDER_ERROR,
    // Driver Action Types
    GET_DRIVER_DELIVERIES
} from "../actions/types";

const initialState = {
    order: null,
    orders: [],
    loading: true,
    error: {},
    // Driver Deliveries
    deliveries: [],
    delivery_loading: true
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case ORDERS_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_ORDER:
            return {
                ...state,
                order: payload,
                loading: false
            }
        case GET_ORDERS:

            return {
                ...state,
                orders: payload,
                loading: false
            }
        case CLEAR_ORDER:
            return {
                ...state,
                order: null,
                loading: false
            }
        case CLEAR_ORDERS:
            return {
                ...state,
                orders: [],
                loading: false
            }
        case ORDER_ERROR:

            // Update the error state with the payload value and set loading to false
            return {
                ...state,
                error: payload,
                loading: false
            };

// -------------------
// Driver Action Types (below)
        case GET_DRIVER_DELIVERIES:

            // Set the posts state to the payload value and set loading to false
            return {
                ...state,
                deliveries: payload,
                delivery_loading: false
            };
        default:
            return state;
    }
}