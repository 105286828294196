import {
    SET_ZONES,
    GET_ZONES,
    ZONE_ERROR,
    DELETE_ZONE,
    ADD_ZONE,
    GET_ZONE,
    EDIT_ZONE,
    CLEAR_ZONES,
    ZONES_LOADING,
    SET_ZONE_CLOSED,
    SET_ZONE_OPEN,
    SET_STORE_HOURS,
    CHECKED_IF_ZONE_OPEN
  } from '../actions/types';
  
  const initialState = {
    zones: [],
    detailZone: null,
    loading: true,
    isOpen: false,
    checked_isOpen: false,
    store_hours: null,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case ZONES_LOADING:

            // Set the loading state to true
            return {
                ...state,
                loading: true
            };
      case GET_ZONES:
        return {
          ...state,
          zones: payload,
          loading: false
        };
      case GET_ZONE:
        return {
          ...state,
          detailZone: payload,
          loading: false
        };
      case ADD_ZONE:
        return {
          ...state,
          zones: [payload, ...state.zones],
          detailZone: payload,
          loading: false
        };
      case EDIT_ZONE:
        return {
            ...state,
            detailZone: payload,
            loading: false
        };
      case DELETE_ZONE:
        return {
          ...state,
          zones: state.zones.filter(zone => zone._id !== payload),
          loading: false
        };
      case ZONE_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
      case CLEAR_ZONES:
        return {
            ...state,
            zones: [],
            loading: false
        }
      case SET_ZONE_OPEN:
          return {
              ...state,
              isOpen: true,
              checked_isOpen: true
          }
      case SET_ZONE_CLOSED:
        return {
            ...state,
            isOpen: false,
            checked_isOpen: true
        };
      case CHECKED_IF_ZONE_OPEN:
        return {
            ...state,
            checked_isOpen: true
        };
      case SET_STORE_HOURS:
        return {
            ...state,
            store_hours: payload,
        };
      default:
        return state;
    }
  }