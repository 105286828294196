import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Icons - material UI
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';

const Mobile_CartBtn = ({
    auth,
    cart: {
        cart
    },
    nav: {
        sideNav
    }
}) => {

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    let marginBottom = '0px';

    if(!auth.isAuthenticated && !sideNav) {
        marginBottom = '90px';
    } else {
        marginBottom = '50px';
    }

    if(!(cart.qty > 0 && isTablet)) {

        return <></>
    } else {

        return (
            <div onClick={() => {window.location = '/cart'}} style={{position: 'absolute', zIndex: 10, left: '16px', right: '16px', bottom: `${marginBottom}`, boxSizing: 'border-box', height: '64px', marginBottom: '16px', display: 'flex'}}>
                <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#000', cursor: 'pointer', padding: '4px 4px 4px 8px', borderRadius: '32px', border: '2px solid #fff', flex: 1, boxSizing: 'border-box'}}>
                    <div style={{marginRight: '4px', position: 'relative'}}>
                        <div style={{background: 'rgb(0, 164, 255)', height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px', borderRadius: '50%', boxSizing: 'border-box'}}>
                            <LocalMallOutlinedIcon />
                        </div>
                        <div style={{height: '20px', width: '20px', position: 'absolute', background: 'rgba(250,216,105,1.00)', top: '24px', left: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%'}}>
                            <div style={{color: '#000', fontSize: '12px', fontWeight: 'bold'}}>
                                {cart.qty}
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingLeft: '16px', flex: 1}}>
                        <div style={{color: '#fff', fontSize: '18px', fontWeight: 'bold'}}>
                            My Bag
                        </div>
                        {/* <div style={{color: 'rgb(248, 203, 69)', fontSize: '14px'}}>
                            320 Puff Points
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

Mobile_CartBtn.propTypes = {
    auth: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    cart: state.cart,
    nav: state.nav
})

export default connect(mapStateToProps, null)(Mobile_CartBtn);