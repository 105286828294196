import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types'

// Firebase
import { db } from '../../../utils/firebase-config';
import { collection, getDocs, doc} from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { markItemPicked } from '../../../actions/orderActions';

const DeliveryItem = ({
    auth,
    detailOrder,
    orderItem,
    pickupConfirmed,
    markItemPicked,
}) => {

    // Item Images
    const [postImgs, setPostImgs] = useState(null);
    const [gotPostImgs, setGotPostImgs] = useState(false);
    const [hasImg, setHasImg] = useState(false);

    // Item Status
    const [checked_if_picked, setChecked_if_picked] = useState(false);
    const [isPicked, setIsPicked] = useState(false);

    // Trigger if post HAS imgs - for font size reasons
    useEffect(() => {
        handleHasImgState();
    }, [postImgs]);

    // For font size reasons
    const handleHasImgState = () => {
        if(postImgs && postImgs.length > 0) {
            
            setHasImg(true)
        }
    }

    // Function to get post images
    async function getPostImgs() {

        const docRef = doc(db, 'products', orderItem.item._id)
        const colRef = collection(docRef, "img_gallery")

        try {

            const galleryData = await getDocs(colRef);
            let galleryList = galleryData.docs.map((doc) => ({...doc.data(), _id: doc.id}));

            if(galleryList.length > 0) {
                galleryList = galleryList.sort((a, b) => a.img_order - b.img_order);
            }
            
            setPostImgs(galleryList)
            setGotPostImgs(true);
        } catch (err) {
            console.log('ERROR:');
            console.log(err);
        }
    }

    // Check if post images are not fetched yet and detailPost exists
    if(!gotPostImgs && orderItem) {

        // Fetch post images
        getPostImgs()

        // Don't fetch imgs again after this
        setGotPostImgs(true);
    }

    // Checks if the item has already been the Picked by the delivery partner
    if(!checked_if_picked) {
        if(detailOrder?.items_picked?.filter(item_picked => item_picked._id.toString() === orderItem.item._id).length > 0){
            setIsPicked(true);
        }
        
        // Marks that the check for likes has been performed
        setChecked_if_picked(true);
    }  

    let imgInfo = [];

    if(postImgs && postImgs.length > 0) {
        postImgs.map(image => {
            imgInfo.push({
                img_path: image.img_path,
                width: image.img_width,
                height: image.img_height,
                img_order: image.img_order,
            })
        })
    } else {
        imgInfo = null;
    }

    return (
        <div className='orderItem_container'>
            <div className="orderItem" key={orderItem.item._id}>
                <div className="orderItem__info--container">
                    <div className="orderItem__details">
                        <div className="orderItem__img">
                            {hasImg && <img style={{height: '100%'}} src={imgInfo[0].img_path} alt="img" />}
                        </div>
                        <div className="orderItem__info">
                            <p className="line-clamp-1">{orderItem.item.name}</p>
                            {/* <p>
                                <span>M 12</span>
                                <span>Grey</span>
                            </p> */}
                        </div>
                    </div>
                    
                </div>
                <div className="orderItem__totals">
                    <p style={{margin:'0 1rem'}}>x</p>
                    <p style={{margin:'0 1rem'}}>{orderItem.qty}</p>
                    <p style={{margin:'0 1rem'}}>${orderItem.price}</p>
                </div>
            </div>

            {!pickupConfirmed && (
                <div style={{width: '100%', display: 'flex', marginBottom: '10px'}}>
                    {!isPicked ? (
                        <div onClick={() => markItemPicked(detailOrder._id, detailOrder, orderItem.item._id)} style={{flex: 1, padding: '10px 5px 0px 0px', boxSizing: 'border-box'}}>
                            <div style={{background: '#fff', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                <div style={{color: '#000', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                    Scan Item
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{flex: 1, padding: '10px 5px 0px 0px', boxSizing: 'border-box'}}>
                            <div style={{background: '#4BB543', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #4BB543'}}>
                                <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                    Confirmed
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            )}
        </div>
    )
}

DeliveryItem.propTypes = {
    auth: PropTypes.object.isRequired,
    markItemPicked: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { 
    
  // Connecting actions from redux to the component
  markItemPicked

})(DeliveryItem);
