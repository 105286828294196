import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types'

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, add_To_Recent_History } from '../actions/navActions';
import { getOrdersByUserID } from '../actions/orderActions';

// Components - imported
import Orders_List from '../components/Order/Orders_List';

const MyOrdersList = ({ 
    auth: {
        user
    }, 
    setPage, 
    add_To_Recent_History,
    toggleSideNav,
    getOrdersByUserID,
    history
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {

        // Set current page to 'orders' in navigation state
        setPage('orders');
        add_To_Recent_History(url.pathname);  // current url


    }, []);

    useEffect(() => {

        if(user) {
            getOrdersByUserID(user._id);
        }

    }, [user]);

    // Function to handle Mixpanel tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.identify(user._id);
        mixpanel.track("Orders List Page View");
    }

    // Track Mixpanel event when the component mounts
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && user !== null) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (
        <Fragment>
            <div className="inbox__container">
                <Orders_List history={history} />   
            </div>
        </Fragment>
    )
}

MyOrdersList.propTypes = {
    toggleSideNav: PropTypes.func.isRequired, // Function to toggle the side navigation
    setPage: PropTypes.func.isRequired, // Function to set the page
    add_To_Recent_History: PropTypes.func.isRequired,
    getOrdersByUserID: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired, // Object representing the authentication state
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    toggleSideNav, 
    setPage,
    add_To_Recent_History,
    getOrdersByUserID
})(MyOrdersList);
