import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom';
import Div100vh, { use100vh } from 'react-div-100vh';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { setAlert } from '../../actions/alertActions';
import { toggleSideNav, setPage } from '../../actions/navActions';

// Layout Wrapper
import DriverLayout from '../../components/layout/DriverLayout';

// Components
import { Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import DeliveryDetail from '../components/DeliveryDetail/DeliveryDetail';
import HowToDeliver from '../common/HowToDeliver';

// Icons

const DeliverySupport = ({
    auth,
    nav: { 
        sideNav, 
        page, 
    },
    setPage,
    toggleSideNav,
    setAlert
}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());

        setPage('delivery support');

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;
    const notFullScreen = windowWidth <= 1170;

    const pageHeight = use100vh();

    // Handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)
    }

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Delivery Support Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

  return (
    <div className="app" style={{ height: pageHeight }}>
        <div className='sidebar'></div>
        <DriverLayout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="delivery support">

            {/* Main Content */}
            <div style={{padding: '16px', borderBottom: '1px solid #e6ecf0'}}>
                <div className="settings__body terms">
                    <div className="trust-and-safety" style={{paddingTop:'20px'}}>
                        <h5>Need Help?</h5>
                        <h3>REACH US ON Social</h3>
                        <div>
                            <p>We are here to help solve problems and investigate issues when they arise. Please contact us for assistance.</p>
                            <Button onClick={() => window.open("https://www.instagram.com/oubuysell/", "_blank")} variant="outlined" className="sidebar__tweet ghost" fullWidth>DM us @OUBuySell</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{height: '100px'}}></div>
        </DriverLayout>
    </div>
  )
}

DeliverySupport.propTypes = {
    // Prop: Object representing below states(s)
    setPage: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    // Connect: Map below states from redux store to component props
    auth: state.auth, 
    nav: state.nav
});

export default connect(mapStateToProps, { 

  // Connecting actions from redux to the component
  setPage,
  toggleSideNav,
  setAlert

})(DeliverySupport);