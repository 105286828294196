import {
    SET_COLLECTIONS,
    GET_COLLECTIONS,
    COLLECTION_ERROR,
    DELETE_COLLECTION,
    GET_COLLECTION,
    CLEAR_COLLECTIONS,
    COLLECTIONS_LOADING,
    GET_ITEM_COLLECTIONS
  } from '../actions/types';
  
  const initialState = {
    collections: [],
    detailCollection: null,
    item_collections: [],
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case COLLECTIONS_LOADING:

            // Set the loading state to true
            return {
                ...state,
                loading: true
            };
      case GET_COLLECTIONS:
        return {
          ...state,
          collections: payload,
          loading: false
        };
      case GET_COLLECTION:
        return {
          ...state,
          detailCollection: payload,
          loading: false
        };
      case SET_COLLECTIONS: 
        const categories = payload;
        let tempCategories = categories; 
        
        if(state.collections.length > 0) {
            tempCategories = [...state.collections, ...tempCategories ];
        }

        return {
            ...state,
            collections: tempCategories,
            loading: false
        };
      case DELETE_COLLECTION:
        return {
          ...state,
          collections: state.collections.filter(collection => collection._id !== payload),
          loading: false
        };
      case COLLECTION_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
      case CLEAR_COLLECTIONS:
        return {
            ...state,
            collections: [],
            loading: false
        }
      case GET_ITEM_COLLECTIONS:
        return {
            ...state,
            item_collections: payload,
            loading: false
        }
      default:
        return state;
    }
  }