import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { getAllOrders } from '../../actions/orderActions';
import { toggleSideNav, setPage } from '../../actions/navActions';

// Layout Wrapper
import Layout from '../../components/layout/Layout';

// Components
import Spinner from '../../components/common/Spinner';
import OfferBlock from '../components/Offers/OfferBlock'
import HowItWorks from '../common/HowItWorks';

const OffersPage = ({ 
    getAllOrders, 
    setPage,
    toggleSideNav,
    order: {
        loading, 
        orders
    }, 
    nav: { 
        sideNav, 
        page, 
        postModal 
    }
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [orderList, setOrderList] = useState([]);
    const [gotOrders, setGotOrders] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Inifite scroll
    const [lastPageDoc, setLastPageDoc] = useState(null);
    const [noMorePosts, setNoMorePosts] = useState(false);
    const [showBottomSpinner, setShowBottomSpinner] = useState(false)

    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        setPage('offers');
        getAllOrders();

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

        renderOrderList();
    }, [orders]);


    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Offers Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)
    }

    const renderOrderList = () => {
        setOrderList([]);

        if(orders === null) {
            setOrderList([(
                <Spinner />
            )])
        }
        else { 
            if(orders.length > 0) {

                orders.map(order_item => {
                    setOrderList(orderList => [...orderList, (
                        <OfferBlock
                            key={order_item._id}
                            detailOrder={order_item}
                        />
                    )])
                });

                setGotOrders(true);
            } else {
                setOrderList([(
                    <div className="no-rides">
                        <h1>No Offers</h1>
                    </div>
                )])
            }
        } 
    }
    
    return (
        <Layout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="offers">
            <div className="feed_headerTabs">
                <Link to="/">
                    <div>
                        <h3>Resale</h3>
                        <div className="block__underline"></div>
                    </div>
                </Link>
                <Link to="/marketplace">
                    <div>
                        <h3>Grocery</h3>
                        <div className="block__underline"></div>
                    </div>
                </Link>
                <Link to="/offers">
                    <div className="active">
                        <h3>Delivery</h3>
                        <div className="block__underline"></div>
                    </div>
                </Link>
            </div>

            <HowItWorks page="landing" />

            {!orderList.length > 0 || !gotOrders ? <Spinner /> : orderList}

            <div style={{height: '100px'}}></div>
        </Layout>
    )
}

OffersPage.propTypes = {
    getAllOrders: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    order: state.order,
    nav: state.nav,
})

export default connect(mapStateToProps, { 
    getAllOrders, 
    toggleSideNav,
    setPage
})(OffersPage);