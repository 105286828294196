import React from 'react';

import Overview from './Overview';
import ImageOverviewItems from './ImageOverviewItems';

const ImageOverview = ({images, changeImage, showImage, detailProduct, isTablet}) => (
    <Overview>
        <ImageOverviewItems images={images} changeImage={changeImage} showImage={showImage} detailProduct={detailProduct} isTablet={isTablet} />
    </Overview>
);

export default ImageOverview
