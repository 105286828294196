import {
    SET_DISCOUNT_GROUPS,
    GET_DISCOUNT_GROUPS,
    DISCOUNT_GROUP_ERROR,
    DELETE_DISCOUNT_GROUP,
    ADD_DISCOUNT_GROUP,
    GET_DISCOUNT_GROUP,
    EDIT_DISCOUNT_GROUP,
    CLEAR_DISCOUNT_GROUPS,
    DISCOUNT_GROUPS_LOADING,
    UPDATE_DISCOUNT_GROUP_IMG
  } from '../actions/types';
  
  const initialState = {
    discount_Groups: [],
    detailDiscount_Group: null,
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case DISCOUNT_GROUPS_LOADING:

            // Set the loading state to true
            return {
                ...state,
                loading: true
            };
      case GET_DISCOUNT_GROUPS:
        return {
          ...state,
          discount_Groups: payload,
          loading: false
        };
      case GET_DISCOUNT_GROUP:
        return {
          ...state,
          detailDiscount_Group: payload,
          loading: false
        };
      case ADD_DISCOUNT_GROUP:
        return {
          ...state,
          discount_Groups: [payload, ...state.discount_Groups],
          detailDiscount_Group: payload,
          loading: false
        };
      case EDIT_DISCOUNT_GROUP:
        return {
            ...state,
            detailDiscount_Group: payload,
            loading: false
        };
      case DELETE_DISCOUNT_GROUP:
        return {
          ...state,
          discount_Groups: state.discount_Groups.filter(discount_Group => discount_Group._id !== payload),
          loading: false
        };
      case DISCOUNT_GROUP_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
      case CLEAR_DISCOUNT_GROUPS:
        return {
            ...state,
            collections: [],
            loading: false
        }
      case UPDATE_DISCOUNT_GROUP_IMG:

        // Update state with user data
        return {
            ...state,
            detailDiscount_Group: {
                ...state.detailDiscount_Group,
                display_img: payload
            }
        }
      default:
        return state;
    }
  }