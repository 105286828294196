import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Firebase

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, add_To_Recent_History } from '../../actions/navActions';

// Layout Wrapper
import Layout from '../../components/layout/Layout';

// Components
import Register_body from './register/Register_body'

// Modal

const Register = ({ 
    setPage, 
    add_To_Recent_History,
    nav: { 
        sideNav, 
    },
    auth: {
        user,
        isAuthenticated
    },
    toggleSideNav,
    history
}) => {

    // State variable to track if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    let previousUrl = url.searchParams.get("goBack");

    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Set the current page
        setPage('register');
        add_To_Recent_History(url.pathname);  // current url

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Handles Mixpanel tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

        // Track "About Page View" event
        mixpanel.track("Grocery About Page View");
    }

    // Checks if the environment is production and Mixpanel tracking hasn't been sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {

        // Call Mixpanel tracking function
        handleMixpanel();

        // Set Mixpanel event as sent
        setSentMixpanel(true);
    }

    const isMobile = windowWidth <= 769;

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)
    }

    // If the user is authenticated, navigate back
    if(isAuthenticated && user) {
        if(previousUrl === 'cart') {
            window.location.href = "/checkout";
        } else {
            window.location.href = "/search";
        }
    }

    return (
        <Fragment>

            <Register_body history={history} />

            {/* Add space to the bottom of the page b/c of auth alert  */}
            <div style={{height: '300px'}}></div>

        </Fragment>
    )
}

Register.propTypes = {
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    nav: PropTypes.object.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setPage, 
    add_To_Recent_History,
    toggleSideNav
})(Register);
