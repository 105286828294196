import axios from 'axios';

import { setAlert  } from './alertActions';
import { createNotification } from './notificationActions';
import { sendEmail } from './emailActions';

import { db, auth, storage } from '../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';
import { uploadBytes, ref, getDownloadURL, uploadBytesResumable, deleteObject } from 'firebase/storage';

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import {
    GET_POSTS,
    SET_POSTS,
    SET_LAST_POSTS,
    GET_POST,
    UPDATE_POST_LIKES,
    ADD_POST,
    DELETE_POST,
    GET_COMMENTS,
    SET_COMMENTS,
    ADD_COMMENT,
    REMOVE_COMMENT,
    COMMENTS_LOADING,
    POSTS_LOADING,
    CLEAR_POSTS,
    CLEAR_POST,
    POST_ERROR,
    UPDATE_EDITOR_TEXT,
    CLEAR_EDITOR_TEXT,
    UPDATE_EDITOR_CATEGORY,
    CLEAR_EDITOR_CATEGORY,
    UPDATE_EDITOR_FILES,
    REMOVE_EDITOR_FILE,
    CLEAR_EDITOR_FILES,
    UPDATE_URLS,
    REMOVE_URL,
    CLEAR_URLS,
    UPDATE_LAST_POST_ADDED,
    POST_UPLOADING,
    POST_UPLOADING_IMGS
} from './types';

// Reference to the "posts" collection in Firestore
const postsCollectionRef = collection(db, "posts");


const removeHtmlTags = (str) => {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}

// Get posts
export const getPosts = () => async dispatch => {
    try {
        console.log('GETTING DOCS')

        // Retrieve all documents from the "posts" collection
        const data = await getDocs(postsCollectionRef);

        // Map the document data to an array of posts with the document ID included
        const postList = data.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('SHOW POST LIST')
        console.log(postList);

        // Dispatch an action of type GET_POSTS with the postList as the payload
        dispatch({
            type: GET_POSTS,
            payload: postList
        });
    } catch (err) {
        console.log(err)

        // Dispatch an action of type SET_POSTS with an empty array as the payload to clear the posts in the state
        dispatch({
            type: SET_POSTS,
            payload: []
        })
    }
}

// Get single Post by id
export const getPostById = id => async dispatch => {
    dispatch(setPostsLoading());
    try {

        // Create a reference to the specified post document in the "posts" collection
        const docRef = doc(db, 'posts', id)

        // Retrieve the document with the specified ID from the "posts" collection
        const postDoc = await getDoc(docRef);

        console.log('GOT POST BY ID');
        console.log(postDoc.data())
  
        if(postDoc.data()) {
            // Dispatch an action of type GET_POST with the post data and ID as the payload
            dispatch({
                type: GET_POST,
                payload: {
                    ...postDoc.data(),
                    _id: id
                }
            });
        } else {
            dispatch({
                type: GET_POST,
                payload: null
            });

            dispatch(setAlert('Sorry, that post has been deleted.', 'okay'));
        }
    } catch (err) {

        // Dispatch an action of type POST_ERROR with an error message and status code as the payload
        dispatch({
            type: POST_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        
        // Log the error to the console
        console.log(err);
    }
}

// Get Posts By User Id
export const getPostsByUserId = id => async dispatch => {
    dispatch(clearPosts());
    console.log('GETTING USER POSTS');
    try {
        console.log('QUERYING USER POSTS');

        // Create a query to retrieve posts where the user ID matches the provided ID, ordered by creation date in descending order
        const q = query(postsCollectionRef, where("user._id", "==", id), orderBy('createdAt', 'desc'));

        console.log('QUERY USER RESULTS');
        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each post
        const userPosts = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('USER POSTS DATA');
        console.log(userPosts);

        // Dispatch an action of type SET_POSTS with the user posts as the payload
        dispatch({
            type: SET_POSTS,
            payload: userPosts
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type SET_POSTS with an empty array as the payload in case of an error
        dispatch({
            type: SET_POSTS,
            payload: []
        })
    }
};

// filter the posts with images attached
export const getAllPostPhotos = id => async dispatch => {
    dispatch(setPostsLoading());
    console.log('GETTING GALLERY POSTS');
    try {
        console.log('QUERYING GALLERY POSTS');

        // Create a query to retrieve posts where the user ID matches the provided ID, 
        // the image count is greater than or equal to 1, 
        // ordered by image count and creation date in descending order
        const q = query(postsCollectionRef, where("img_count", ">=", 1), orderBy('img_count'), orderBy('createdAt', 'desc'));

        console.log('QUERY GALLERY RESULTS');
        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each post
        const galleryPosts = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('GALLERY POST DATA');
        console.log(galleryPosts);

        // Dispatch an action of type GET_POSTS with the gallery posts as the payload
        dispatch({
            type: GET_POSTS,
            payload: galleryPosts
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type SET_POSTS with null as the payload in case of an error
        dispatch({
            type: SET_POSTS,
            payload: null
        })
    }
}

// filter the posts with images attached
export const getUserPhotos = id => async dispatch => {
    dispatch(setPostsLoading());
    console.log('GETTING GALLERY POSTS');
    try {
        console.log('QUERYING GALLERY POSTS');

        // Create a query to retrieve posts where the user ID matches the provided ID, 
        // the image count is greater than or equal to 1, 
        // ordered by image count and creation date in descending order
        const q = query(postsCollectionRef, where("user._id", "==", id), where("img_count", ">=", 1), orderBy('img_count'), orderBy('createdAt', 'desc'));

        console.log('QUERY GALLERY RESULTS');
        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each post
        const galleryPosts = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('GALLERY POST DATA');
        console.log(galleryPosts);

        // Dispatch an action of type GET_POSTS with the gallery posts as the payload
        dispatch({
            type: GET_POSTS,
            payload: galleryPosts
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type SET_POSTS with null as the payload in case of an error
        dispatch({
            type: SET_POSTS,
            payload: null
        })
    }
}

// filter the posts by category
export const getCategoryPosts = (category) => async dispatch => {
    dispatch(setPostsLoading());
    console.log('GETTING CATEGORY POSTS');
    try {
        console.log('QUERYING CAT POSTS');

        // Create a query to retrieve posts where the category matches the provided category
        const q = query(postsCollectionRef, where("category", "==", category));

        console.log('QUERY CAT RESULTS');
        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each post
        const categoryPosts = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('CAT POST DATA');
        console.log(categoryPosts);

        // Dispatch an action of type GET_POSTS with the category posts as the payload
        dispatch({
            type: GET_POSTS,
            payload: categoryPosts
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type SET_POSTS with null as the payload in case of an error
        dispatch({
            type: SET_POSTS,
            payload: null
        })
    }
}

// Get posts user liked
export const getLikedPosts = (userId, lastPageDoc) => async dispatch => {

    if(!lastPageDoc) dispatch(setPostsLoading());
    
    console.log('GETTING LIKED POSTS');
    try {
        console.log('QUERYING  POSTS');

        let q;

        if(lastPageDoc) {
            // Create a query to retrieve posts where the like_id_list contains the provided userId, starting at the last visible document and fetch the next 3 posts.
            q = query(postsCollectionRef, where("like_id_list", "array-contains", userId), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(3));
        } else {
            // Create a query to retrieve posts where the like_id_list contains the provided userId
            q = query(postsCollectionRef, where("like_id_list", "array-contains", userId), orderBy('lastModified', 'desc'), limit(3));
        }

        console.log('QUERYING  RESULT');
        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each post
        const likedPosts = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('LIKED POST DATA');
        console.log(likedPosts);

         // Dispatch an action of type SET_POSTS or SET_LAST_POSTS with the liked posts as the payload
        if(likedPosts.length < 2 && lastPageDoc) {
            dispatch({
                type: SET_LAST_POSTS,
                payload: likedPosts
            });
        } else {
            dispatch({
                type: SET_POSTS,
                payload: likedPosts
            });
        }


    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type SET_POSTS with an empty array as the payload in case of an error
        dispatch({
            type: SET_POSTS,
            payload: []
        })
    }
};

// Fill the posts array with everything in the given array
export const setPosts = arrayOfPosts => dispatch => {
    console.log('SETTING POSTS NOW');

    dispatch({
        type: GET_POSTS,
        payload: arrayOfPosts
    });
};

// Add new posts to the post state inifinite scroll array
export const setSearchPosts = arrayOfPosts => dispatch => {
    console.log('SETTING SEARCH POSTS NOW');

    dispatch({
        type: SET_POSTS,
        payload: arrayOfPosts
    });
};

// Add Post
export const addPost = ( 
    campus_id, 
    campus_name,
    formData, 
    imgData, 
    imgDimensions, 
    postData 
) => async dispatch => {
    
    try {

        if(imgData?.length > 0) {
            dispatch({
                type: POST_UPLOADING,
                payload: true
            });
        }

        // Create Post

        console.log('FRONTEND FORM DATA');
        console.log(formData);  

        // Extract the necessary data from the formData object
        const {
            text,
            username,
            user
        } = formData;

        let category = null;
        let avatar = null;

        let parent_comment = null;
        

        // Check if the category and avatar fields exist in the formData object
        // If they exist, assign their values to the respective variables
        if(formData.category) {
            category = formData.category;
        }
        if(formData.avatar) {
            avatar = formData.avatar;
        }
        if(postData) {
            parent_comment = postData._id;
        }

        // Create a new document in the "posts" collection with the post data
        const result = await addDoc(postsCollectionRef, {
            campus_id,
            text,
            category,
            username,
            user,
            avatar,
            post_commented_on: parent_comment,
            img_count: imgData.length,
            likes: [],
            comments: [],
            date: Date.now(),
            createdAt: serverTimestamp(),
            lastModified: serverTimestamp()
        });

        console.log('SENT TO FIREBASE')
        console.log(result.id);


        // --- Update the totalPosts # in the user document
            
            if(!postData) {
                const userRef = doc(db, 'users', user._id)

                console.log('GOT USER REFERENCE');

                // Update the totalPosts field using the increment function
                await updateDoc(userRef, {
                    totalPosts: increment(1)
                })
            }
            
        // END update the totalPosts # in the user document

        
        // --- Add Images to post ---


                // Get the document reference for the post
                const docRef = doc(db, 'posts', result.id)

                // Get the collection reference for the image gallery subcollection
                const colRef = collection(docRef, "img_gallery")

                console.log('IMG DATA');
                console.log(imgData)

                let imgUpload_counter = 0;

                console.log('IMG LENGTH');
                console.log(imgUpload_counter)
                
                // Iterate over the imgData array and upload each image to the storage
                imgData.map(async (img) => {
                    
                    // Create a storage reference for the image
                    const storageRef = ref(storage, `images/posts/${result.id}/${img.name}`);

                    // Upload the image file to the storage
                    const res = await uploadBytesResumable(storageRef, img);

                    console.log('Uploaded a blob or file!');
                    console.log(res);

                    // Get the download URL of the uploaded image
                    const imgPath = await getDownloadURL(storageRef);

                    // Retrieve the existing image gallery data
                    const galleryData = await getDocs(colRef);

                    console.log('IMG GALLERY LENGTH');
                    console.log(galleryData.docs.length)

                    console.log('IMG PATH');
                    console.log(imgPath);

                    console.log('IMG FILENAME');
                    console.log(img.name);

                    let orderNum = 1;
                    
                    // If existing gallery data has documents increment to orderNum to the sum + 1
                    if(galleryData.docs && galleryData.docs.length > 0) {
                        orderNum = galleryData.docs.length + 1
                    }
                    console.log('ORDER NUM');
                    console.log(orderNum);

                    // Find the dimensions information of the current image
                    const imgInfo = imgDimensions.find(dimensionObj => dimensionObj.fileName === img.name);

                    console.log('IMG INFO - DIMENSIONS');
                    console.log(imgInfo);

                    let newImg = {}; 

                    if(imgInfo) {

                        // Create a new image object with dimensions information
                        newImg = {
                            img_path: imgPath,
                            img_name: img.name,
                            img_order: orderNum,
                            img_width: imgInfo.width,
                            img_height: imgInfo.height
                        };
                    } else {

                        // Create a new image object without dimensions information
                        newImg = {
                            img_path: imgPath,
                            img_name: img.name,
                            img_order: orderNum,
                            img_width: 0,
                            img_height: 0
                        };
                    }

                    // Add the new image to the "img_gallery" subcollection
                    const gal = await addDoc(colRef, newImg);

                    console.log('GAL:');
                    console.log(gal);

                    if(imgUpload_counter + 1 === imgData.length) {
                        dispatch({
                            type: POST_UPLOADING,
                            payload: false
                        });

                        // Set a timeout to give enough time for the post to be added
                        setTimeout(() => {
            
                            // Display an alert message to notify the user that the post was sent
                            dispatch(setAlert('Your post was sent.', 'okay'));
                        }, 1000 );

                    } else {
                        imgUpload_counter = imgUpload_counter + 1
                    }
                    
                    // await updateDoc(docRef, {
                    //     img_gallery: tempArray
                    // });
                });


            // await addImagesToPost(imgData);

        // --- END ADD IMGS ---



        // Get Post with Images

        // const postData = await axios.get(`/api/posts/${res.data._id}`);

        // dispatch({
        //     type: ADD_POST,
        //     payload: postData.data
        // });

        if(postData) { // If post is a Comment

            // --- Add the new comment to state for display w/o reloading page

                // Fetch the added comment document
                const commentDoc = await getDoc(result);

                // Create a full comment object by merging the comment data with the added _id property
                const fullComment = {
                    ...commentDoc.data(),
                    _id: commentDoc.id
                }
                
                // Dispatch the full comment to the store using the SET_COMMENTS action type
                dispatch({
                    type: SET_COMMENTS,
                    payload: [fullComment]
                });

                // Display a success message to the user
                dispatch(setAlert('Your reply was sent', 'okay'));

            // --- END add the new comment to state for display w/o reloading page

            // --- Update the lastModified date for all the parent post documents

                // Update the lastModified field for all parent post documents
                const updateParentPostsLastModified = async (postCommentedOn) => {
                    let post = postCommentedOn;
                
                    while (post.post_commented_on !== null) {
                    const parentPostRef = doc(db, 'posts', post.post_commented_on);
                
                    console.log('GOT PARENT POST REFERENCE');
                
                    // Update the lastModified date field using Firebase's serverTimestamp function
                    await updateDoc(parentPostRef, {
                        lastModified: serverTimestamp()
                    });
                
                    // Fetch the parent post to continue the loop
                    const parentPostSnapshot = await getDoc(parentPostRef);
                    post = parentPostSnapshot.data();
                    }
                };

                // Update the last modified date for the post that was commented on
                const postCommentedOnRef = doc(db, 'posts', postData._id)

                console.log('GOT POST COMMENTED ON REFERENCE');

                // Update the lastModified date field using firebase's serverTimestamp function
                await updateDoc(postCommentedOnRef, {
                    lastModified: serverTimestamp()
                })
                
                // Call the function to update the lastModified field for all parent posts
                await updateParentPostsLastModified(postData);
            
            // --- END update the lastModified date for all the parent post documents

            // --- Create Notification ---- >

                if(!(postData.user._id === formData.user._id)) {
                    
                    // Create a notification for the comment
                    if(postData.post_commented_on) {
                        dispatch(createNotification(
                            {                       // formData
                                type: 'comment reply',
                                postId: postData._id,
                                message: formData.text
                            }, 
                            postData.user._id,   // toId
                            formData.user.first_name,  // first_name
                            formData.user.last_name,     // last_name
                            formData.avatar     // avatar
                        ));

                        // Send notifications to all parent post users
                        const notifyParentPosts = async (postCommentedOn) => {
                            let post = postCommentedOn;
                        
                            while (post.post_commented_on !== null) {
                            const parentPostRef = doc(db, 'posts', post.post_commented_on);
                        
                            console.log('GOT PARENT POST REFERENCE');
                        
                            // Fetch the parent post to continue the loop
                            const parentPostSnapshot = await getDoc(parentPostRef);

                            if(parentPostSnapshot.data().post_commented_on) {
                                dispatch(createNotification(
                                    {                       // formData
                                        type: 'comment interaction',
                                        postId: postData._id,
                                        message: formData.text
                                    }, 
                                    postData.user._id,   // toId
                                    formData.user.first_name,  // first_name
                                    formData.user.last_name,     // last_name
                                    formData.avatar     // avatar
                                ));
                            } else {
                                dispatch(createNotification(
                                    {                       // formData
                                        type: 'post interaction',
                                        postId: postData._id,
                                        message: formData.text
                                    }, 
                                    postData.user._id,   // toId
                                    formData.user.first_name,  // first_name
                                    formData.user.last_name,     // last_name
                                    formData.avatar     // avatar
                                ));
                            }

                            post = parentPostSnapshot.data();
                            }
                        };

                        notifyParentPosts(postData)
                    } else {
                        dispatch(createNotification(
                            {                       // formData
                                type: 'comment',
                                postId: postData._id,
                                message: formData.text
                            }, 
                            postData.user._id,   // toId
                            formData.user.first_name,  // first_name
                            formData.user.last_name,     // last_name
                            formData.avatar     // avatar
                        ));
                    }
                }

            // --- END Create Notification ----

            // --- Send Email ----

                if(!(postData.user._id === formData.user._id)) {

                    console.log('SENDING EMAIL FOR COMMENT')

                    let unRawState;
                    let unRawDescription;

                    if(formData.text !== null) {
                        unRawState = EditorState.createWithContent(
                            convertFromRaw(JSON.parse(formData.text))
                        );

                        unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

                        console.log('PLAIN TEXT')
                        console.log(removeHtmlTags(unRawDescription));
                    }

                    if(postData.post_commented_on) {
                        dispatch(sendEmail(
                            {                       // formData
                                type: 'comment reply',
                                to_userId: postData.user._id,
                                from_user: {
                                    first_name: formData.user.first_name,
                                    last_name: formData.user.last_name,
                                    username: formData.user.username,
                                    avatar: formData.user.img
                                },
                                postId: postData._id,
                                message: removeHtmlTags(unRawDescription)
                            },
                            campus_name
                        ));

                        const notifyParentPosts = async (postCommentedOn) => {
                            let post = postCommentedOn;
                        
                            while (post.post_commented_on !== null) {
                            const parentPostRef = doc(db, 'posts', post.post_commented_on);
                        
                            console.log('GOT PARENT POST REFERENCE');
                        
                            // Fetch the parent post to continue the loop
                            const parentPostSnapshot = await getDoc(parentPostRef);

                            if(parentPostSnapshot.data().post_commented_on) {
                                dispatch(sendEmail(
                                    {                       // formData
                                        type: 'comment interaction',
                                        to_userId: postData.user._id,
                                        from_user: {
                                            first_name: formData.user.first_name,
                                            last_name: formData.user.last_name,
                                            username: formData.user.username,
                                            avatar: formData.user.img
                                        },
                                        postId: postData._id,
                                        message: removeHtmlTags(unRawDescription)
                                    }, 
                                    campus_name
                                ));
                            } else {
                                dispatch(sendEmail(
                                    {                       // formData
                                        type: 'post interaction',
                                        to_userId: postData.user._id,
                                        from_user: {
                                            first_name: formData.user.first_name,
                                            last_name: formData.user.last_name,
                                            username: formData.user.username,
                                            avatar: formData.user.img
                                        },
                                        postId: postData._id,
                                        message: removeHtmlTags(unRawDescription)
                                    }, 
                                    campus_name
                                ));
                            }

                            post = parentPostSnapshot.data();
                            }
                        };

                        notifyParentPosts(postData)
                    } else {
                        dispatch(sendEmail(
                            {                       // formData
                                type: 'comment',
                                to_userId: postData.user._id,
                                from_user: {
                                    first_name: formData.user.first_name,
                                    last_name: formData.user.last_name,
                                    username: formData.user.username,
                                    avatar: formData.user.img
                                },
                                postId: postData._id,
                                message: removeHtmlTags(unRawDescription)
                            }, 
                            campus_name
                        ));
                    }
                    
                    console.log('SENT EMAIL ACTION')
                }

            // --- END Send Email ---

            // Redirect the user to the post page
            // if(history) {
            //     window.location.href = `/post/${postData._id}`;
            //     // history.push(`/post/${postId}`);
            // }
        } else {

            dispatch({
                type: UPDATE_LAST_POST_ADDED,
                payload: result.id
            });

            if(!(imgData?.length > 0)) {
                    
                // Display an alert message to notify the user that the post was sent
                dispatch(setAlert('Your post was sent.', 'okay'));
            }
        }
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err);
    //   dispatch({
    //     type: POST_ERROR,
    //     payload: { msg: err.response.statusText, status: err.response.status }
    //   });
    }
};

// Delete Post
export const deletePost = (postId) => async dispatch => {
    console.log('DELETING POST!!!!!')
    console.log(postId);

    // TODO: Delete comment from post

    // Get reference to the post document
    const postRef = doc(db, 'posts', postId)

    // Get reference to the "img_gallery" subcollection of the post
    const colRef = collection(postRef, "img_gallery")

    try {
        // --- Delete IMGS from post ---
            let galleryData;

            // Check if the "img_gallery" subcollection exists
            if(colRef) {
                // Fetch the documents in the "img_gallery" subcollection
                galleryData = await getDocs(colRef);
            }

            console.log('GALLERY DATA')
            console.log(galleryData);

            // If img_gallery HAS images
            if(galleryData?.docs && galleryData.docs.length > 0) {
                console.log('DELETING GALLERY')
                console.log(galleryData.docs[0].data().img_name);

                // Iterate over the galleryData and delete each image
                galleryData.docs.map(async (imgData) => {
                    try {
                        // Get reference to post img_gallery
                        const imgRef = doc(db, `posts/${postId}/img_gallery`, imgData.id);
                    
                        console.log('DELETING IMGS PROCESS')
                        
                        // Get reference to the image file in the storage
                        const storageRef = ref(storage, `images/posts/${postId}/${imgData.data().img_name}`);

                        // Delete the image file from the storage
                        await deleteObject(storageRef);

                        // Delete the image document from the "img_gallery" subcollection
                        await deleteDoc(imgRef);

                    } catch (err) {
                        console.log('ERROR');
                        console.log(err);

                        dispatch(setAlert('Couldn\'t delete post images', 'danger'));
                    }
                })
            }

        // --- END delete IMGS ---

        // --- UPDATE user total post # ---

            // Fetch the post document
            const postDoc = await getDoc(postRef);

            if(!postDoc?.data().post_commented_on) {
                // Get reference to the user document of the post's owner
                const userRef = doc(db, 'users', postDoc.data().user._id);

                console.log('GOT USER REFERENCE');
                
                // Decrement the totalPosts field of the user document by 1
                await updateDoc(userRef, {
                    totalPosts: increment(-1)
                })
            }
        
        // --- END update user total post # ---

        // --- DELETE POST AND COMMENTS ---

        // Function to delete all of a post's comments
        const deletePostComments = async (postData) => {
            try {
                // Create a query to retrieve comments where the post_commented_on field matches the provided post ID, ordered by creation date in descending order
                const commentQuery = query(postsCollectionRef, where("post_commented_on", "==", postData.id));
            
                console.log('QUERY COMMENTS RESULTS');
                console.log(commentQuery);
            
                // Execute the query and retrieve the query snapshot
                const querySnapshot = await getDocs(commentQuery);
            
                // Map through the document snapshots in the query snapshot and extract the data and ID for each comment
                const postComments = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

                console.log('GOT POST COMMENTS');
                console.log(postComments);
            
                // Recursively fetch comments of comments
                const fetchCommentsOfComments = async (comments) => {
                    console.log('FETCHING COMMENTS OF COMMENTS');
                    for (let i = 0; i < comments.length; i++) {

                        console.log('SINGLE COMMENT');
                        console.log(comments[i]);

                        const comment = comments[i];
                        
                        // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
                        const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'desc'));
                        
                        // Execute the query and retrieve the query snapshot
                        const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
                        
                        // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                        const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                        
                        // Append the comments of comments to the current comment
                        comment.commentsOfComments = commentsOfComments;

                        console.log('END SINGLE COMMENT');
                        
                        // Recursively fetch comments of comments for the current comment
                        await fetchCommentsOfComments(commentsOfComments);
                    }

                    console.log('END FETCHING COMMENTS OF COMMENTS');
                };
            
                // Fetch comments of comments for the initial set of comments
                await fetchCommentsOfComments(postComments);
            
                // Now postComments array contains all comments and comments of comments
                console.log('--- > ALL COMMENTS');
                console.log(postComments);

                console.log('START DELETING COMMENTS')

                const deleteCommentsOfComments = async (comments) => {
                    console.log('DELETING COMMENTS OF COMMENTS');
                    for (let i = 0; i < comments.length; i++) {
              
                      console.log('SINGLE COMMENT');
                      console.log(comments[i]);
              
                      const comment = comments[i];

                      // --- Remove the IMGS of the comment

                      const removeCommentImgs = async (commentData) => {
                        const postCommentRef = doc(db, 'posts', commentData._id)

                        // Get reference to the "img_gallery" subcollection of the post
                        const commentColRef = collection(postCommentRef, "img_gallery")

                        let commentGalleryData;

                        // Check if the "img_gallery" subcollection exists
                        if(commentColRef) {
                            // Fetch the documents in the "img_gallery" subcollection
                            commentGalleryData = await getDocs(commentColRef);
                        }

                        console.log('GALLERY DATA')
                        console.log(commentGalleryData);

                        // If img_gallery HAS images
                        if(commentGalleryData?.docs && commentGalleryData.docs.length > 0) {
                            console.log('DELETING GALLERY')
                            console.log(commentGalleryData.docs[0].data().img_name);

                            // Iterate over the commentGalleryData and delete each image
                            commentGalleryData.docs.map(async (imgData) => {
                                try {
                                    // Get reference to post img_gallery
                                    const imgRef = doc(db, `posts/${commentData._id}/img_gallery`, imgData.id);
                                
                                    console.log('DELETING IMGS PROCESS')
                                    
                                    // Get reference to the image file in the storage
                                    const storageRef = ref(storage, `images/posts/${commentData._id}/${imgData.data().img_name}`);

                                    // Delete the image file from the storage
                                    await deleteObject(storageRef);

                                    // Delete the image document from the "img_gallery" subcollection
                                    await deleteDoc(imgRef);

                                    console.log('END deleting IMGS process')

                                } catch (err) {
                                    console.log('ERROR');
                                    console.log(err);

                                    dispatch(setAlert('Couldn\'t delete post images', 'danger'));
                                }
                            })
                        }
                      }

                      await removeCommentImgs(comment);

                      // --- END Remove the IMGS of the comment
              
                      // Delete the current comment
                      await deleteDoc(doc(db, 'posts', comment._id));

                        // Dispatch the REMOVE_COMMENT action with the commentId payload to remove the comment from the state
                        dispatch({
                            type: REMOVE_COMMENT,
                            payload: comment._id
                        });
              
                      // Delete comments of comments recursively
                      if (comment.commentsOfComments && comment.commentsOfComments.length > 0) {
                        await deleteCommentsOfComments(comment.commentsOfComments);
                      }
              
                      console.log('END SINGLE COMMENT');
                    }
              
                    console.log('END DELETING COMMENTS OF COMMENTS');
                };
              
                // Delete comments of comments for the initial set of comments
                await deleteCommentsOfComments(postComments);

                console.log('END DELETING COMMENTS')
            } catch (error) {
                console.log('ERROR DELETING POST COMMENTS')
                console.log(error);
            }

            
        };
    
        await deletePostComments(postDoc);
        
        // Delete the post document
        await deleteDoc(postRef);

        // --- END DELETE POST AND COMMENTS ---
        
        if(!postDoc.data().post_commented_on) {
            // Dispatch the DELETE_POST action with the postId
            dispatch({
                type: DELETE_POST,
                payload: postId
            });

            // Clear the currently displayed post
            dispatch(clearPost());

            // Set an alert to inform the user that the post was deleted
            dispatch(setAlert('Your post was deleted', 'okay'));
        } else {

            // Dispatch the REMOVE_COMMENT action with the commentId payload to remove the comment from the state
            dispatch({
                type: REMOVE_COMMENT,
                payload: postDoc.id
            });

            // Display a success alert
            dispatch(setAlert('Comment Removed', 'success'));
        }

    } catch (err) {
        console.log('ERROR');
        console.log(err);

        dispatch(setAlert('Something went wrong', 'danger'));
    //     dispatch({
    //         type: POST_ERROR,
    //         payload: { msg: err.response.statusText, status: err.response.status }
    //     });
    }
}

export const setPostUploadingImgs = (value) => dispatch => {
    dispatch({
        type: POST_UPLOADING_IMGS,
        payload: value
    });
}

// Add like
export const addLike = (campus_name, postId, fromUserData, postData) => async dispatch => {
    
    // Get the current list of likes from the post data
    const likeList = postData.likes;
    
    try {
        console.log('ADDING LIKE!!!!!')
        console.log(postId);

        // Get Firebase Post & Likes of post collection ref
        const postRef = doc(db, 'posts', postId)
        const colRef = collection(postRef, "likes")


        console.log('FROM USER DATA:');
        console.log(fromUserData);

        console.log('Like List DATA:');
        console.log(likeList);
        
        // Create the new like object
        const newLike = {
            user: {
                _id: fromUserData._id,
                username: fromUserData.username,
                first_name: fromUserData.first_name,
                last_name: fromUserData.last_name,
                img: fromUserData.img
            }
        };

        // Check if post already liked by same user
        if(likeList.filter(like => like.user._id.toString() === fromUserData._id).length > 0) {
            // Get the index of the like to remove
            const removeIndex = likeList.map(like => like.user._id.toString()).indexOf(fromUserData._id);

            const likeID = likeList[removeIndex]._id;

            // Remove the like from the post
            likeList.splice(removeIndex, 1);

            console.log('Removed Like Data:');
            console.log(likeList);

            // Create array for the list of like ID's
            const id_array = [];

            likeList.map((like) => {
                id_array.push(like.user._id);
            })

            console.log('Removed Like ID LIST Data:');
            console.log(id_array);

            // Update the post document with the updated likes and like_id_list
            await updateDoc(postRef, {
                likes: likeList,
                like_id_list: id_array
            })
        } else {
            // const likeData = await addDoc(colRef, newLike);

            // Add the new like to the likeList
            likeList.push(newLike);

            console.log('NEW Like DATA:');
            console.log(likeList);

            // Create array for the list of like ID's
            const id_array = [];

            likeList.map((like) => {
                id_array.push(like.user._id);
            })

            console.log('Removed Like ID LIST Data:');
            console.log(id_array);

            // Update the post document with the updated likes and like_id_list
            await updateDoc(postRef, {
                likes: likeList,
                like_id_list: id_array
            })

            console.log('SUCCESSFULLY LIKED');
            

            // Dispatch an alert to indicate that the post was saved
            dispatch(setAlert('Post saved.', 'okay'));

            // --- Create Notification ---- >

                if(!(postData.user._id === fromUserData._id)) {

                    // Create a notification for the like action
                    dispatch(createNotification(
                        {                       // formData
                            type: 'like',
                            postId: postId,
                            message: postData.text
                        }, 
                        postData.user._id,   // toId
                        fromUserData.first_name,  // first_name
                        fromUserData.last_name,     // last_name
                        fromUserData.img     // avatar
                    ));
                }

            // --- END Create Notification

            // --- Send Email ----

            if(!(postData.user._id === fromUserData._id)) {
                
                console.log('SENDING EMAIL FOR LIKE')

                let unRawState;
                let unRawDescription;

                if(postData.text !== null) {
                    unRawState = EditorState.createWithContent(
                        convertFromRaw(JSON.parse(postData.text))
                    );

                    unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

                    console.log('PLAIN TEXT')
                    console.log(removeHtmlTags(unRawDescription));
                }

                dispatch(sendEmail(
                    {                       // formData
                        type: 'like',
                        to_userId: postData.user._id,   // toId
                        from_user: {
                            first_name: fromUserData.first_name,  // first_name
                            last_name: fromUserData.last_name,   // last_name
                            username: fromUserData.username,   // username
                            avatar: fromUserData.img         // avatar
                        },
                        postId: postId,
                        message: removeHtmlTags(unRawDescription)
                    }, 
                    campus_name
                ));
                
                console.log('SENT EMAIL ACTION')
            }

            // --- END Send Email ---
        }
  
    //   dispatch({
    //     type: UPDATE_POST_LIKES,
    //     payload: { id, likes: res.data }
    //   });

      
    } catch (err) {
      console.log(err)
    }
};

// Get Post Comment
export const getComments = (postId) => async dispatch => {
    dispatch(setCommentsLoading());
    console.log('GETTING COMMENTS')

    try {
        // Create a query to retrieve comments where the post_commented_on field matches the provided post ID, ordered by creation date in descending order
        const commentQuery = query(postsCollectionRef, where("post_commented_on", "==", postId), orderBy('createdAt', 'asc'));
      
        console.log('QUERY USER RESULTS');
        console.log(commentQuery);
      
        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(commentQuery);
      
        // Map through the document snapshots in the query snapshot and extract the data and ID for each comment
        const postComments = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
      
        // Recursively fetch comments of comments
        const fetchCommentsOfComments = async (comments) => {
          for (let i = 0; i < comments.length; i++) {
            const comment = comments[i];
            
            // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
            const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'desc'));
            
            // Execute the query and retrieve the query snapshot
            const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
            
            // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
            const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
            
            // Append the comments of comments to the current comment
            comment.commentsOfComments = commentsOfComments;
            
            // Recursively fetch comments of comments for the current comment
            await fetchCommentsOfComments(commentsOfComments);
          }
        };
      
        // Fetch comments of comments for the initial set of comments
        await fetchCommentsOfComments(postComments);
      
        // Now postComments array contains all comments and comments of comments
        console.log('--- > ALL COMMENTS');
        console.log(postComments);

        dispatch({
            type: GET_COMMENTS,
            payload: postComments
        });
      } catch (error) {
        console.log('ERROR');
        console.log(error);
      }

    // try {
    //     console.log('GETTING COMMENTS 2')

    //     // Get the reference to the post and comments collection
    //     const postRef = doc(db, 'posts', postId);
    //     const commentsCollectionRef = collection(postRef, "comments")

    //     // Create a query to fetch the comments in descending order of createdAt
    //     const q = query(commentsCollectionRef, orderBy('createdAt', 'desc'));

    //     // Fetch the comment data
    //     const commentData = await getDocs(q);

    //     // Map the comment documents to an array of comment objects with the added _id property
    //     const commentList = commentData.docs.map((doc) => ({...doc.data(), _id: doc.id}));

    //     console.log('SHOW POST COMMENTS ListN ACTIONS');
    //     console.log(commentList)
    
    //     // Dispatch the comment list to the store
    //     dispatch({
    //         type: GET_COMMENTS,
    //         payload: commentList
    //     });

    // } catch (err) {
    //     console.log(err)

    //     // If an error occurs, dispatch an empty comment list to the store
    //     dispatch({
    //         type: SET_COMMENTS,
    //         payload: []
    //     })
    // }
}

// Add Comment
export const addComment = (postId, formData, postData, history) => async dispatch => {

    if(!formData.avatar) formData.avatar = "";
    
    try {
        
        console.log('IN COMMENT!!!!!')
        console.log(postId);

        // Add comment to post

        // Get the reference to the post and comments collection
        const docRef = doc(db, 'posts', postId)
        const colRef = collection(docRef, "comments")

        console.log('FORM DATA');
        console.log(formData)
        
        // Object that represents the comment to be added to the post
        const newComment = {
            text: formData.text,
            username: formData.username,
            avatar: formData.avatar,
            user: formData.user,
            date: Date.now(),
            createdAt: serverTimestamp()
        };

        // Add the new comment to the "comments" subcollection of the post
        const commentRef = await addDoc(colRef, newComment);

        // Fetch the added comment document
        const commentDoc = await getDoc(commentRef);

        // Create a full comment object by merging the comment data with the added _id property
        const fullComment = {
            ...commentDoc.data(),
            _id: commentDoc.id
        }

        console.log('---- Comment Data:');
        console.log(fullComment);
        
        // Dispatch the full comment to the store using the SET_COMMENTS action type
        dispatch({
            type: SET_COMMENTS,
            payload: [fullComment]
        });

        // Display a success message to the user
        dispatch(setAlert('Your reply was sent', 'okay'));

        // --- Create Notification ---- >

        // Create a notification for the comment
        dispatch(createNotification(
            {                       // formData
                type: 'comment',
                postId: postId,
                message: formData.text
            }, 
            postData.user._id,   // toId
            formData.user.first_name,  // first_name
            formData.user.last_name,     // last_name
            formData.avatar     // avatar
        ));

        // --- END Create Notification ----

        // --- Send Email ----

        console.log('SENDING EMAIL FOR COMMENT')

        let unRawState;
        let unRawDescription;

        if(formData.text !== null) {
            unRawState = EditorState.createWithContent(
                convertFromRaw(JSON.parse(formData.text))
            );

            unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

            console.log('PLAIN TEXT')
            console.log(removeHtmlTags(unRawDescription));
        }

        dispatch(sendEmail(
            {                       // formData
                type: 'comment',
                to_userId: postData.user._id,
                from_user: {
                    first_name: formData.user.first_name,
                    last_name: formData.user.last_name,
                    username: formData.user.username,
                    avatar: formData.user.img
                },
                postId: postId,
                message: removeHtmlTags(unRawDescription)
            }, 

        ));
        
        console.log('SENT EMAIL ACTION')

        // --- END Send Email ---

        // Redirect the user to the post page
        if(history) {
            window.location.href = `/post/${postId}`;
            // history.push(`/post/${postId}`);
        }

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        // Check if the error has a response property
        if(err.response) {
            const errors = err.response.data.errors;

            // Check if there are errors in the response data
            if(errors) {

                // Loop through the errors and dispatch the setAlert action for each error
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Dispatch the setAlert action with a generic error message if the error doesn't have a response property
            dispatch(setAlert('Something went wrong', 'danger'));
        }
        // dispatch({
        //     type: POST_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        // });
        // console.log({msg: err.response.statusText, status: err.response.status})
    }
}

// Delete Comment
export const deleteComment = (postId, commentId) => async dispatch => {
    console.log('DELETING COMMENT!!!!!')
    console.log(postId);

    // Delete comment from post
    const docRef = doc(db, `posts/${postId}/comments`, commentId)

    try {
        console.log('COMMENT ID');
        console.log(commentId)

        // Delete the comment document
        await deleteDoc(docRef);

        // Dispatch the REMOVE_COMMENT action with the commentId payload to remove the comment from the state
        dispatch({
            type: REMOVE_COMMENT,
            payload: commentId
        });

        // Display a success alert
        dispatch(setAlert('Comment Removed', 'success'));

    } catch (err) {

        console.log('ERROR');
        console.log(err);

        // Display an error alert if something goes wrong during the deletion
        dispatch(setAlert('Something went wrong', 'danger'));

        // Uncomment the following code if you have specific error handling logic or dispatches for the POST_ERROR action
        // dispatch({
        //     type: POST_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        // });
    }
}   

// Set State of Text Editor
export const setEditorText = editorData => dispatch => {

    dispatch({
        type: UPDATE_EDITOR_TEXT,
        payload: editorData
    });
};

// Clear Editor Text
export const clearEditorText = () => dispatch => {
    dispatch({
        type: CLEAR_EDITOR_TEXT
    });

}

// Set State of Editor Category
export const setEditorCategory = categoryData => dispatch => {

    dispatch({
        type: UPDATE_EDITOR_CATEGORY,
        payload: categoryData
    });
};

// Clear Editor Category
export const clearEditorCategory = () => dispatch => {
    dispatch({
        type: CLEAR_EDITOR_CATEGORY
    });
}

// Set State of Editor Files
export const setEditorFiles = fileData => dispatch => {

    dispatch({
        type: UPDATE_EDITOR_FILES,
        payload: fileData
    });
};

// Clear Editor File State
export const clearEditorFiles = () => dispatch => {
    dispatch({
        type: CLEAR_EDITOR_FILES
    });

}

// Add 1 Temporary Image url to urls
export const setTemporaryUrls = urlData => dispatch => {
    // console.log('LOOK FOR URL HERE');
    // console.log(urlData);
    dispatch({
        type: UPDATE_URLS,
        payload: urlData
    });
};

// Remove 1 url from urls
export const removeTempUrl = index => dispatch => {
    console.log('LOOK FOR REMOVE INDEX HERE');
    console.log(index);
    dispatch({
        type: REMOVE_URL,
        payload: index
    });
};

// Clear temp urls
export const clearUrls = () => dispatch => {
    dispatch({
        type: CLEAR_URLS
    });

}

// Posts loading
export const setPostsLoading = () => {
    return {
        type: POSTS_LOADING
    }
}

// Comments loading
export const setCommentsLoading = () => {
    return {
        type: COMMENTS_LOADING
    }
}

// Remove all posts
export const clearPosts = () => dispatch => {

    // Dispatch an action to set loading state for posts
    dispatch(setPostsLoading());

    dispatch({
        type: CLEAR_POSTS
    });

}

// Remove all posts
export const clearPost = () => dispatch => {
    dispatch({
        type: CLEAR_POST
    });

}

// ---------------------------------------- //
// ------- BULK DB minipulation functions -------------
// ----------------------------------------

export const addLastModifiedFieldToPosts = () => async dispatch => {
    const updatePostsLastModified = async () => {
        console.log('UPDATING: LAST MODIFIED POSTS NOW...');
        dispatch(setAlert('UPDATING LAST MODIFIED POSTS NOW...', 'okay'));

        const postsCollectionRef = collection(db, 'posts');
        const querySnapshot = await getDocs(postsCollectionRef);
        
        const updatePromises = querySnapshot.docs.map(async (documentSnapshot) => {
            if(!documentSnapshot.data().lastModified) {
                const createdAt = documentSnapshot.data().createdAt;
        
                const postRef = doc(db, 'posts', documentSnapshot.id);
                await updateDoc(postRef, {
                    lastModified: createdAt
                });
            }
        });
        
        await Promise.all(updatePromises);
        
        dispatch(setAlert('All posts updated successfully', 'okay'));

        console.log('All posts updated successfully.');
    };
      
    // Call the function to update all the posts
    await updatePostsLastModified();

}

export const addPostCommentedOnFieldToPosts = () => async dispatch => {
    const addPostCommentedOnField = async () => {
        console.log('ADDING: PARENT COMMENT TO POSTS NOW...');
        dispatch(setAlert('ADDING: PARENT COMMENT TO POSTS NOW...', 'okay'));

        const postsCollectionRef = collection(db, 'posts');
        const querySnapshot = await getDocs(postsCollectionRef);
        
        const updatePromises = querySnapshot.docs.map(async (documentSnapshot) => {
            if(!documentSnapshot.data().post_commented_on) {
        
                const postRef = doc(db, 'posts', documentSnapshot.id);
                await updateDoc(postRef, {
                    post_commented_on: null
                });
            }
        });
        
        await Promise.all(updatePromises);
        
        dispatch(setAlert('All posts updated successfully', 'okay'));

        console.log('All posts updated successfully.');
    };
      
    // Call the function to update all the posts
    await addPostCommentedOnField();

}

export const createPostForAllNestedComments = () => async dispatch => {
    const swapNestedCommentsToPosts = async () => {
        console.log('SWITCHING: NESTED COMMENTS TO POSTS NOW...');
        dispatch(setAlert('SWITCHING NESTED COMMENTS TO POSTS NOW...', 'okay'));

        const postsCollectionRef = collection(db, 'posts');
        const querySnapshot = await getDocs(postsCollectionRef);
        
        const updatePromises = querySnapshot.docs.map(async (documentSnapshot) => {

            console.log('GETTING POST COMMENTS')

            // Get the reference to the post and comments collection
            const postRef = doc(db, 'posts', documentSnapshot.id);
            const commentsCollectionRef = collection(postRef, "comments")

            // Fetch the comment data
            const commentData = await getDocs(commentsCollectionRef);

            if(commentData && commentData.docs?.length > 0) {
                // Map the comment documents to an array of comment objects with the added _id property
                commentData.docs.map(async (commentDoc) => {
                    let category = null;
                    let avatar = null;

                    if(commentDoc.data().user.img) {
                        avatar = commentDoc.data().user.img;
                    }
            
                    // Create a new document in the "posts" collection with the post data
                    await addDoc(postsCollectionRef, {
                        text: commentDoc.data().text,
                        category,
                        username: `${commentDoc.data().user.first_name}${commentDoc.data().user.last_name && ' ' + commentDoc.data().user.last_name}`,
                        user: commentDoc.data().user,
                        avatar,
                        post_commented_on: documentSnapshot.id,
                        img_count: 0,
                        likes: [],
                        comments: [],
                        date: commentDoc.data().date,
                        createdAt: commentDoc.data().createdAt,
                        lastModified: commentDoc.data().createdAt
                    });

                    // Delete the comment from the post sub-collection
                    const commentRef = doc(db, `posts/${documentSnapshot.id}/comments`, commentDoc.id)
                    await deleteDoc(commentRef);
                });
            
        
            }
        });
        
        await Promise.all(updatePromises);
        
        dispatch(setAlert('All posts updated successfully', 'okay'));

        console.log('All posts updated successfully.');
    };
      
    // Call the function to update all the posts
    await swapNestedCommentsToPosts();

}

// ADD OU campus_id to all current users
export const add_campus_id_to_all_posts = () => async dispatch => {

    console.log('ADDING CAMPUS ID TO POSTS...');

    // Try block to handle the update operation
    try {
  
        console.log('GETTING ALL POSTS')

        // Retrieve all documents from the 'users' collection
        const data = await getDocs(postsCollectionRef);

        // Create a user list by mapping each document to an object and including the document ID
        const postList = data.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('SHOW POST LIST')
        console.log(postList);

        // Loop through the post list and update each document with the 'campus_id' field
        postList.forEach(async (post) => {

            // Reference to the post document in the Firestore database
            const postDoc = doc(db, "posts", post._id);

            try {
                console.log('ADDING FIELD')
                // Update the post doc by adding the campus_id field
                await updateDoc(postDoc, {
                    campus_id: 'b7nqQGbbbzw08uh0MoPA',
                })
            } catch (error) {
                console.log('ERROR UPDATING USER');
                console.log(error)
            }

        });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('DONE Updating Posts!', 'success'));

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        // Display a generic error message if no specific error response is received
        setAlert('Something went wrong', 'danger')
    }
}