import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { getProducts, getProductsInCollection } from '../../../actions/productActions';
import { getMenuPage } from '../../../actions/menuActions';

// Components
import Spinner from '../../../components/common/Spinner';
import Product_Card from '../ProductList/ProductCard/Product_Card';
import Empty_Card from '../ProductList/ProductCard/Empty_Card';

const CategoryMain = ({
    menuID,
    collection,
    product: {
        products,
        no_more_products,
        loading,
        show_bottom_spinner
    },
    menu: {
        detailMenu
    },
    getMenuPage,
    getProductsInCollection,
    getProducts,
    page,
    filter,
    loadMore,
    setCanLoadMore,
    canLoadMore,
    last_page_doc
}) => {

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    //  Collection List
    const [productList, setProductList] = useState([]);
    const [gotProducts, setGotProducts] = useState(false);

    const [gotMenuPage, setGotMenuPage] = useState(false);

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    useEffect(() => {

        if(!gotMenuPage && menuID) {

            getMenuPage(menuID);
            setGotMenuPage(true);
        }
    }, [menuID]);

    useEffect(() => {

        if(detailMenu?.collection_id && gotMenuPage) {

            getProductsInCollection(detailMenu?.collection_id);
        }
    }, [detailMenu, gotMenuPage])

    useEffect(() => {

        if(page === 'grocery landing') getProducts();

    }, [page])
    
    useEffect(() => {

        renderItemList();

    }, [products, canLoadMore]);

    const renderItemList = async () => {
        setProductList([]);

        if((products === null && loading) || !gotProducts) {
            setProductList([(
                <div className="no-rides">
                    <Spinner />
                </div>
            )])
        }
        else { 
            if(products.length > 0) {
                if(!isMobile) {
                    products.map(product_item => {
                        setProductList(productList => [...productList, (
                            <Fragment>
                                <Product_Card 
                                    detailProduct={product_item}
                                    page={"category_main"}
                                />
                            </Fragment>
                        )])
                    })  
                } else {
                    for (let i = 0; i < products.length; i++) {
                        if(i % 3 === 0) {
                            setProductList(productList => [...productList, (
                                <Fragment>
                                    {products[i] && (
                                        <div style={{padding: '0', display:'flex', borderRadius:'6px', overflow:'hidden', width: '100%'}}>
                                            <Product_Card 
                                                detailProduct={products[i]}
                                                page={"category_main"}
                                            />
                                            {products[i + 1] ? (
                                                <Fragment>
                                                    <Product_Card 
                                                        detailProduct={products[i + 1]}
                                                        page={"category_main"}
                                                    />

                                                    {products[i + 2] ? (
                                                        <Product_Card 
                                                            detailProduct={products[i + 2]}
                                                            page={"category_main"}
                                                        />
                                                    ) : (
                                                        <Fragment>
                                                            {!no_more_products && (<Empty_Card last_page_doc={last_page_doc} page={"category_main"} loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} />)}
                                                        </Fragment>
                                                    )}
                                                </Fragment>
                                                
                                            ) : (
                                                <Fragment>
                                                    {!no_more_products && (<Empty_Card last_page_doc={last_page_doc} page={"category_main"} loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} />)}
                                                </Fragment>
                                            )}

                                        </div>
                                    )}
                                </Fragment>
                            )])
                        }  
                    }  
                }
            } else {
                if(page === 'search' && filter) {
                    setProductList([(
                        <div className="no-results" style={{marginTop:'80px', minHeight: '200px'}}>
                            <div className='search_imgContainer'>
                                <img style={{maxWidth:'55%'}} src="https://abs.twimg.com/responsive-web/client-web/rubber-chicken-800x400.v1.3a2d1b19.png" />
                            </div>
                            
                            <h1>No results for <br/>"{filter}"</h1>
                            <h2>Try searching for something else</h2>
                        </div>
                    )])
                } else {
                    setProductList([(
                        <div className="no-results" style={{marginTop:'80px', minHeight: '200px'}}>
                            <div className='search_imgContainer'>
                                <img style={{maxWidth:'55%'}} src="https://abs.twimg.com/responsive-web/client-web/rubber-chicken-800x400.v1.3a2d1b19.png" />
                            </div>
                            
                            <h1>No results...</h1>
                        </div>
                    )])
                }
            }
        } 

        setGotProducts(true);
    }

    return (
        <Fragment>
            <div style={{padding: '0', display:'flex', borderRadius:'6px', overflow:'hidden', flexWrap:'wrap', width: '100%'}}>
                {productList}

                {(!no_more_products && products.length !== 0 && !isMobile) && (<Empty_Card last_page_doc={last_page_doc} page={"category_main"} loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} />)}

                {(isMobile && products.length !== 0 && ( (products.length % 3) === 0 ) && !no_more_products ) && (<Empty_Card last_page_doc={last_page_doc} page={"category_main"} loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} />)}
            </div>

            {/* Render a spinner at the bottom if there are more products to load */}
            {(show_bottom_spinner && !no_more_products && !loading) && <Spinner />}
            
            {/* Display a message when there are no more products */}
            {(no_more_products && products.length !== 0) && (<div style={{color: 'rgb(108, 126, 138)'}} className="no-rides">You've reached the end.</div>)}
        </Fragment>
    )
}

CategoryMain.propTypes = {
    product: PropTypes.object.isRequired,
    collection: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
    getMenuPage: PropTypes.func.isRequired,
    getProductsInCollection: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    product: state.product,
    collection: state.collection,
    menu: state.menu
})

export default connect(mapStateToProps, { getMenuPage, getProductsInCollection, getProducts })(CategoryMain);