import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { getProductLocations } from '../../../actions/locationActions';

// Components
import Spinner from '../../../components/common/Spinner';
import LocationBlock from './LocationBlock';
import { Button } from '@material-ui/core';

const LocationList = ({
    location: {
        loading,
        locations
    },
    productID,
    detailProduct,
    productImgs,
    getProductLocations,
}) => {

    useEffect(() => {

        getProductLocations(productID);

    }, [productID]);

    let locationList;

    if(locations === null || loading) {

        // Display a spinner while loading chat data
        locationList = <Spinner />;
    }
    else {
        if(locations.length > 0) {

            // Render the list of chats
            locationList = locations.map(location => {
                if(location) {
                    return (
                        <LocationBlock
                            key={location._id}
                            location_obj={location}
                            detailProduct={detailProduct}
                            productID={productID}
                            productImgs={productImgs}
                        />
                    )
                }
            })
        }
        else {

            // Display a message if there are no chats
            locationList = (
                <div className="no-chats">
                    <p>This item has not yet been indexed.</p>
                    <Button  
                        variant="outlined" 
                        className="noChats__btn"
                    >
                            Add Location
                    </Button>
                </div>
            );
        }
    }

    return (
        <Fragment>
            {locationList}
        </Fragment>
    )
}

LocationList.propTypes = {
    location: PropTypes.object.isRequired,
    getProductLocations: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    location: state.location
})

export default connect(mapStateToProps, { 
    getProductLocations
})(LocationList);