import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

import mixpanel from 'mixpanel-browser';

// Firebase
import { auth } from '../../../utils/firebase-config';
import { isSignInWithEmailLink, sendSignInLinkToEmail } from "firebase/auth";

// Redux
import { connect } from 'react-redux';

// Actions
import { login, register } from '../../../actions/authActions';

// Layout Wrapper
import ModalPage from '../../../components/layout/ModalPage';

// Components - imported
import { Button } from '@material-ui/core';
import Alert from '../../../components/common/Alert';
import Logo from '../../../components/common/Logo';

// Icons -imported
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { setAlert } from '../../../actions/alertActions';


// Initial State
const initialState = {
    verification_code: ''
}

const ClaimAccount = ({ 
    setAlert, 
    login, 
    register, 
    auth: { 
        user 
    }, 
    nav: {
        campus_id,
        campus_ext
    },
    history
}) => {

    // State variable for tracking Mixpanel event
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // State variable for form data
    const [formData, setFormData] = useState(initialState);

    // State variable for storing email
    const [email, setEmail] = useState('');

    // Get the current URL
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    // Get the value of the 'email' query parameter from the URL
    const emailParam = url.searchParams.get("email");

    // Get the value of the 'first' query parameter from the URL
    const firstNameParam = url.searchParams.get("first");

     // Get the value of the 'last' query parameter from the URL
    const lastNameParam = url.searchParams.get("last");

    useEffect(() => {
        const beginClaim = async () => {
            try {
                // Get the saved email from localStorage
                let saved_email = window.localStorage.getItem("emailForSignIn");

                console.log('---EMAIL PARAM---')
                console.log(emailParam);

                // Verify the user went through an email link and the saved email is not null
                if (isSignInWithEmailLink(auth, window.location.href) /*&& saved_email*/) {

                    console.log('0 -> URL PARAMS EMAIL')
                    console.log(emailParam)
                    console.log('0 -> URL PARAMS FIRST')
                    console.log(firstNameParam)
                    console.log('0 -> URL PARAMS LAST')
                    console.log(lastNameParam)

                    // Prompt the user to enter their email if no email in URL
                    if (!saved_email) {
                        saved_email = window.prompt('Please re-enter your email for confirmation');
                    }

                    if (firstNameParam && lastNameParam) {

                        // Register the user
                        register(saved_email, firstNameParam, lastNameParam, campus_id, campus_ext);

                        console.log('REGISTER THE USER')
                    } else {

                        // Sign in the user
                        login(saved_email, campus_ext);

                        console.log('SIGN THE USER IN')
                    }
                    
                    

                    // console.log('USER');
                    // console.log(res);
                    // console.log(res.email);
                }
            } catch (err) {

                // Set an alert message in case of an error
                setAlert(`${err.message}`, 'danger');
            }
        }

        beginClaim();
        
    }, []);

    useEffect(() => {

        // Get the saved email from localStorage
        const saved_email = window.localStorage.getItem("emailForSignIn");
        
        if(emailParam) {
            console.log('URL PARAMS EMAIL')
            console.log(emailParam)
            console.log('URL PARAMS FIRST')
            console.log(firstNameParam)
            console.log('URL PARAMS LAST')
            console.log(lastNameParam)

            // Set the email for UI display
            setEmail(emailParam);

            // Begin the flow to send the email link
            if (!saved_email) {
                trySignIn(emailParam);
            }
        } 

    }, [emailParam]);


    const trySignIn = async (sentEmail) => {

        let callbackURL;

        // If 'first name' and 'last name' parameters exist, it is a register callback
        if (firstNameParam && lastNameParam) {

             // Register Callback
             callbackURL = `${process.env.REACT_APP_DOMAIN}login/sent?email=${sentEmail}&first=${firstNameParam}&last=${lastNameParam}`;
        } else {

            // Otherwise, it is a log in callback
            // Log In Callback
            callbackURL = `${process.env.REACT_APP_DOMAIN}login/sent?email=${sentEmail}`;
        }

        // Configuration for the action code settings
        const actionCodeSettings = {

            // URL you want to redirect back to.
            // MUST BE in the authorized domains list in the Firebase Console.
            url: callbackURL,

            // This must be true. Indicates that the email action code will be handled in the app
            handleCodeInApp: true
        };
           
        
        try {
            console.log('EMAIL SENT')

            // Remove token if already exists from previous login attempt
            const token = window.localStorage.getItem("emailForSignIn");
            if(token) window.localStorage.removeItem("emailForSignIn");

            // Send the sign-in link to the provided email address
            await sendSignInLinkToEmail(auth, sentEmail, actionCodeSettings)

            // Store the email address in local storage for later verification
            window.localStorage.setItem("emailForSignIn", sentEmail)

            // Display a success message to the user
            setAlert('Email sent.', 'okay')

            console.log('EMAIL SENT 2')
        } catch (err) {

            // If an error occurs during the process, display an error message
            setAlert('An unknown error has occurred', 'danger');
            // alert('An unknown error has occurred')
        }
    }

    // Destructure verification_code from formData
    const { verification_code } = formData;

    // Update form data onChange
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    const onSubmit = async e => {
        e.preventDefault();
        
        console.log('START VERIFICATION PROCESS...')
    }

    // Go back to the previous page
    const goBack = (e) => {
        history.goBack();
    }

    // Redirect to home if user is already logged in
    if(user) {
        window.location.href = "/home";
        // history.push("/home");
    }

    // Redirect to login if no email parameter is present
    if(!emailParam) {
        window.location.href = "/login";
    }

     // Initialize and track Mixpanel event for claim account page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Claim Account Page View");
    }

    // Send Mixpanel event only in production and if not already sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

  return (
    <div className='email_link_page'>

        {/* Image */}
        <img src="https://next-images.opencollective.com/_next/image?url=%2Fstatic%2Fimages%2Fsign-in-illustration.png&w=1920&q=75" />
        
        {/* Title */}
        <p style={{color:'rgb(20, 20, 20)', marginBottom:'0', marginTop:'0', fontSize:'32px', lineHeight:'40px', fontWeight:'700', letterSpacing:'-0.4px'}}>
            Your magic link is on its way!
        </p>
        <p style={{color:'gray', marginTop:'10px', marginBottom:'32px', fontSize:'16px', fontWeight:'500', lineHeight:'24px', letterSpacing:'-0.4px'}}>
            This may take 1-2 minutes.
        </p>

        {/* Email confirmation */}
        <p style={{color:'rgb(49, 50, 51)', marginTop:'32px', marginBottom:'0', fontSize:'20px', lineHeight:'28px', fontWeight:'500', letterSpacing:'-0.4px'}}>
            We've sent it to{' '}
            <strong>{email}</strong>
        </p>

        {/* Button Actions */}
        <div style={{marginTop:'24px', marginTop:'24px', marginBottom:'24px', boxSizing:'border-box'}}>
            
            <a href="https://outlook.office.com/mail/" style={{cursor:'pointer', color:'#3385ff', textDecoration:'none'}} target="_blank">
                Go to your mail
            </a>

            {' '} | {' '}

            <a onClick={() => trySignIn(email)} href="#" style={{cursor:'pointer', color:'#3385ff', textDecoration:'none'}}>
                Resend Email
            </a>
            
        </div>

        {/* Additional information */}
        <p style={{color:'rgb(49, 50, 51)', marginTop:'32px', marginBottom:'32px', fontSize:'16px', fontWeight:'500', lineHeight:'24px', letterSpacing:'-0.4px'}}>
            You’ll be redirected from the link in the email, you can safely close this tab.
        <br/>
        This is called passwordless login.
        </p>

        {/* Not your email option */}
        <div style={{marginTop:'24px', marginTop:'24px', marginBottom:'24px', boxSizing:'border-box'}}>
            <a href="/login?method=password" style={{cursor:'pointer', color:'#3385ff', textDecoration:'none'}}>
                Log in with password
            </a>
        </div>
    </div>
    // <ModalPage>
    //     {/* <Alert /> */}

    //     <div style={{margin: '15px 10px'}} onClick={goBack} className="form__backBtn">
    //         <ArrowBackIcon />
    //     </div>

    //     <div>
    //         <h2>Complete your claim</h2>
    //         <p>You are about to claim <b>@tundeadepitan</b>.</p>
    //     </div>
            
    //     <Logo />

    //     <div>
    //         <p>We sent:<br/><b>{email}</b><br/> a link to login by email.</p>
    //     </div>

    //     <div onClick={() => trySignIn(email)} style={{width:'100%', textAlign:'center'}} className="auth__footer">
    //         <a href="#">Resend Email</a>
    //     </div>
    // </ModalPage>
  )
}

ClaimAccount.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setAlert, 
    login, 
    register 
})(ClaimAccount);