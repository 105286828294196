import { db, auth } from '../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, orderBy, serverTimestamp, startAfter, limit } from 'firebase/firestore';

import {  
    GET_NOTIFICATIONS,
    SET_NOTIFICATIONS,
    SET_LAST_NOTIFICATIONS,
    GET_ACTIVITY,
    SET_UNREAD_COUNT,
    NOTIFICATION_ERROR,
    CLEAR_NOTIFICATIONS,
    NOTIFICATIONS_LOADING
} from './types';

// Reference to the "notifications" collection in Firestore
const notificationCollectionRef = collection(db, "notifications");

// Get Current user notifications 
export const getUserNotifications = (lastPageDoc) => async dispatch => {

    console.log('GETTING USER NOTIFICATIONS');

    if(!lastPageDoc) dispatch(setNotificationsLoading());

    try {
        console.log('QUERYING USER NOTIFICATIONS');

        let q;

        if(lastPageDoc) {
            // Create a query to fetch notifications for the current user, starting at the last visible document and fetch the next 3
            q = query(notificationCollectionRef, where("to_user", "==", auth.currentUser.uid), orderBy('createdAt', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        } else {
            // Create a query to fetch notifications for the current user
            q = query(notificationCollectionRef, where("to_user", "==", auth.currentUser.uid), orderBy('createdAt', 'desc'), limit(15));
        }

        console.log('QUERY NOTIFICATION RESULTS');
        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map the query snapshot to an array of notification objects with document ID
        const notifications = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('USER NOTIFICATION DATA');
        console.log(notifications);

        // Dispatch an action to update the state with the retrieved notifications
        if(notifications.length < 2 && lastPageDoc) {
            dispatch({
                type: SET_LAST_NOTIFICATIONS,
                payload: notifications
            });
        } else {
            dispatch({
                type: SET_NOTIFICATIONS,
                payload: notifications
            });
        }

    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action to handle the notification error
        dispatch({
            type: NOTIFICATION_ERROR,
            payload: []
        })
    }
}

// Get Current user activity
export const getUserActivity = () => async dispatch => {
    console.log('GETTING ACTIV NOTIFICATIONS');
    try {
        console.log('QUERYING ACTIV NOTIFICATIONS');

        // Create a query to fetch activity notifications for the current user
        const q = query(notificationCollectionRef, where("from_user", "==", auth.currentUser.uid));

        console.log('QUERY NOTIFICATION RESULTS');
        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map the query snapshot to an array of activity notifications objects with document ID
        const notifications = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('ACTIVITY NOTIFICATION DATA');
        console.log(notifications);

        // Dispatch an action to update the state with the retrieved activity notifications
        dispatch({
            type: GET_ACTIVITY,
            payload: notifications
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action to handle the notification error
        dispatch({
            type: NOTIFICATION_ERROR,
            payload: []
        })
    }
}

// Add to Noticiations
export const setNotifications = notificationData => async dispatch => {
    try {
        console.log('FRONTEND NOTIFICATION DATA');
        console.log(notificationData);

        // Dispatch an action to update the state with the notification data
        dispatch({
            type: SET_NOTIFICATIONS,
            payload: notificationData
        });

    } catch (err) {

        // Dispatch an action to handle the error case with an empty payload
        dispatch({
            type: SET_NOTIFICATIONS,
            payload: []
        })
    }
};

// Create New Notification
export const createNotification = ( formData, toId, first_name, last_name, avatar) => async dispatch => {

    const {
        type,
        postId,
        chatId,
        message
    } = formData;

    try {

        // Create Notification
        console.log('FRONTEND TO USER ID');
        console.log(toId);

        // --- Prepare the fields for the new notification document

        const docFields = {};

        docFields.type = type;
        if(postId) docFields.postId = postId;
        if(chatId) docFields.chatId = chatId;
        
        docFields.message = message;
        docFields.to_user = toId;
        docFields.from_user = auth.currentUser.uid;
        docFields.from_username = first_name + ' ' + last_name;
        if(avatar) docFields.from_avatar = avatar;
        docFields.read = false;
        docFields.date = Date.now();
        docFields.createdAt = serverTimestamp();

        // Add the new notification document to the notifications collection
        await addDoc(notificationCollectionRef, docFields);

    } catch (err) {
        console.log('ERROR CREATING NOTIFICATION!!!');
        console.log(err);

        // Handle the error if creating the notification fails
        //   dispatch({
        //     type: NOTIFICATION_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        //   });
    }
};

// Edit Notification
export const markNotificationRead = ( notificationId ) => async dispatch => {

    // Reference to the notification document in the Firestore database
    const notificationDoc = doc(db, "notifications", notificationId);

    console.log('MARKING NOTIFICATION READ')

    try {

        // Create Notification
        console.log('FRONTEND TO NOTIFICATION ID');
        console.log(notificationId);

        // Update the notification document to mark it as read
        await updateDoc(notificationDoc, {
            read: true
        })

    }  catch (err) {

        // Handle the error if updating the notification fails
        dispatch({
            type: NOTIFICATION_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

// Get Un-read notifications count
export const getUnreadCount = (id) => async dispatch => {
    console.log('GETTING UN-READ NOTIFY ###');
    try {
        console.log('QUERYING NOTIFICATIONS POSTS');

        // Query notifications that belong to the user with the specified ID and are marked as unread
        const q = query(notificationCollectionRef, where("to_user", "==", id), where("read", "==", false));

        console.log(q);

        // Execute the query and get the query snapshot
        const querySnapshot = await getDocs(q);

        // Calculate the number of unread notifications by getting the length of the query snapshot's docs array
        const unreadCount = querySnapshot.docs.length;

        console.log('## OF UN-READ NOTIFY:');
        console.log(unreadCount);

        // Dispatch an action to set the unread count in the state
        dispatch({
            type: SET_UNREAD_COUNT,
            payload: unreadCount
        });

    } catch (err) {

        // Handle the error if fetching the unread count fails
        console.log('ERROR!!!')
        console.log(err)
        dispatch({
            type: SET_UNREAD_COUNT,
            payload: 0
        })
    }
}

// Notifications loading
export const setNotificationsLoading = () => {
    return {
        type: NOTIFICATIONS_LOADING
    }
}

// Remove all Notifications
export const clearNotifications = () => dispatch => {

    // Dispatch an action to set loading state for notifications
    dispatch(setNotificationsLoading());

    // Dispatch an action to clear all notifications in the state
    dispatch({
        type: CLEAR_NOTIFICATIONS
    });

}

