
// Firebase
import { db, auth, storage } from "../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

// import { setAlert } from './alertActions';

import { 
    SET_PRODUCTS, 
    SET_LAST_PRODUCTS,
    GET_PRODUCTS,
    GET_PRODUCT, 
    CLEAR_PRODUCTS, 
    PRODUCT_ERROR, 
    PRODUCTS_LOADING, 
    SET_LAST_PAGE_DOC,
    SET_SHOW_BOTTOM_SPINNER,
    SET_NO_MORE_PRODUCTS
} from './types';

// Reference to the collections in Firestore
const productsCollectionRef = collection(db, "products");
const variantsCollectionRef = collection(db, "variants");
const categoryCollectionRef = collection(db, "categories");

// Fill the products array with everything in the given array
export const setProducts = arrayOfProducts => dispatch => {
    console.log('SETTING PRODUCTS NOW');

    dispatch({
        type: GET_PRODUCTS,
        payload: arrayOfProducts
    });
};

// Add new products to the product state inifinite scroll array
export const setSearchProducts = arrayOfProducts => dispatch => {
    console.log('SETTING SEARCH PRODUCTS NOW');

    dispatch({
        type: SET_PRODUCTS,
        payload: arrayOfProducts
    });
};

// Get Products
export const getProducts = (lastPageDoc) => async dispatch => {

    if(!lastPageDoc) dispatch(setProductsLoading());
    
    try {
        console.log('GETTING PRODUCTS')

        let q;

        if(lastPageDoc) {
            // Create a query to retrieve posts where the like_id_list contains the provided userId, starting at the last visible document and fetch the next 3 posts.
            q = query(productsCollectionRef, orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(6));
        } else {
            // Create a query to retrieve posts where the like_id_list contains the provided userId
            q = query(productsCollectionRef, orderBy('lastModified', 'desc'), limit(6));
        }

        console.log('FETCHING ITEMS');

        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        const itemsList = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        let lastDocument = itemsList[itemsList.length - 1];

        if(lastDocument) {

            // Create a reference to the specified product document in the "items" collection of the Category
            const docRef = doc(productsCollectionRef, lastDocument._id);

            // Retrieve the document with the specified ID from the "products" collection
            const productDoc = await getDoc(docRef);

            // Set the last visible document for the next load
            dispatch({
                type: SET_LAST_PAGE_DOC,
                payload: productDoc
            });

            dispatch(set_ShowBottomSpinner(false));
        }

        console.log('SHOW PRODUCT LIST')
        console.log(itemsList);

        // Dispatch an action of type SET_PRODUCT or SET_LAST_PRODUCT with the products as the payload
        if(itemsList.length < 2 && lastPageDoc) {
            dispatch({
                type: SET_LAST_PRODUCTS,
                payload: itemsList
            });
        } else {
            dispatch({
                type: SET_PRODUCTS,
                payload: itemsList
            });
        }
        
        // dispatch(setAllProductLocations(res.data));
    } catch (err) {
        console.log(err)

        dispatch({
            type: SET_PRODUCTS,
            payload: []
        })
    }
};

// Get Collection Products
export const getProductsInCollection = (collectionId, lastPageDoc) => async dispatch => {
    
    if(!lastPageDoc) dispatch(setProductsLoading());

    console.log('GETTING COLLECTION PRODUCTS')

    try {

        const collectionRef = doc(db, 'categories', collectionId)
        const collectionItemsRef = collection(collectionRef, "items")

        console.log('QUERYING  PRODUCTS');

        let q;

        if(lastPageDoc) {
            // Create a query to retrieve posts where the like_id_list contains the provided userId, starting at the last visible document and fetch the next 3 posts.
            q = query(collectionItemsRef, orderBy('item_order', 'asc'), startAfter(lastPageDoc || 0), limit(6));
        } else {
            // Create a query to retrieve posts where the like_id_list contains the provided userId
            q = query(collectionItemsRef, orderBy('item_order', 'asc'), limit(6));
        }

        console.log('FETCHING ITEMS');

        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        const itemsList = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        let lastDocument = itemsList[itemsList.length - 1];

        if(lastDocument) {
            // Create a reference to the specified product document in the "items" collection of the Category
            const docRef = doc(collectionItemsRef, lastDocument._id);

            // Retrieve the document with the specified ID from the "products" collection
            const productDoc = await getDoc(docRef);

            // Set the last visible document for the next load
            dispatch({
                type: SET_LAST_PAGE_DOC,
                payload: productDoc
            });

            dispatch(set_ShowBottomSpinner(false));
        }

        let product_list = [];
        // START loop
        for (const item of itemsList) {

            // Create a reference to the specified product document in the "products" collection
            const prodRef = doc(db, 'products', item.product_id);

            // Retrieve the document with the specified ID from the "products" collection
            const productDoc = await getDoc(prodRef);

            product_list.push({
                ...productDoc.data(), 
                _id: productDoc.id
            });
        }

        console.log('GOT PRODUCTS IN COLLECTION');
        console.log(product_list)

        // Dispatch an action of type SET_PRODUCT or SET_LAST_PRODUCT with the products as the payload
        if(product_list.length < 2 && lastPageDoc) {
            dispatch({
                type: SET_LAST_PRODUCTS,
                payload: product_list
            });
        } else {
            dispatch({
                type: SET_PRODUCTS,
                payload: product_list
            });
        }

    } catch (err) {

        // Dispatch an action of type PRODUCT_ERROR with an error message and status code as the payload
        dispatch({
            type: PRODUCT_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        
        // Log the error to the console
        console.log('ERROR!!!')
        console.log(err);
    }
};

// Get single Product
export const getProductById = (id) => async dispatch => {

    dispatch(setProductsLoading());

    try {

        // Create a reference to the specified product document in the "products" collection
        const docRef = doc(db, 'products', id)

        // Retrieve the document with the specified ID from the "products" collection
        const productDoc = await getDoc(docRef);

        console.log('GOT PRODUCT BY ID');
        console.log(productDoc.data())
  
        if(productDoc.data()) {
            // Dispatch an action of type GET_PRODUCT with the product data and ID as the payload
            dispatch({
                type: GET_PRODUCT,
                payload: {
                    ...productDoc.data(),
                    _id: productDoc.id
                }
            });
        } else {
            dispatch({
                type: GET_PRODUCT,
                payload: null
            });

            // dispatch(setAlert("Sorry, that product doesn't exist.", "okay"));
        }
    } catch (err) {

        // Dispatch an action of type PRODUCT_ERROR with an error message and status code as the payload
        dispatch({
            type: PRODUCT_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        
        // Log the error to the console
        console.log('ERROR!!!')
        console.log(err);
    }
}

// Products loading
export const setProductsLoading = () => {
    return {
        type: PRODUCTS_LOADING
    }
} 

// Remove all items
export const clearProducts = () => dispatch => {
    // dispatch(setProductsLoading());

    // dispatch({
    //     type: CLEAR_PRODUCTS
    // });

}

export const set_ShowBottomSpinner = (value) => dispatch => {
    // dispatch(setProductsLoading());

    dispatch({
        type: SET_SHOW_BOTTOM_SPINNER,
        payload: value
    });

}

export const set_LastPageDoc = (productDoc) => dispatch => {
    // dispatch(setProductsLoading());

    dispatch({
        type: SET_LAST_PAGE_DOC,
        payload: productDoc
    });

}

export const set_NoMoreProducts = (value) => dispatch => {
    // dispatch(setProductsLoading());

    dispatch({
        type: SET_NO_MORE_PRODUCTS,
        payload: value
    });

}