import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { togglePostModal, toggleCommentModal } from '../../actions/navActions';
import { addPost, addComment, setEditorText, clearEditorText, setEditorCategory, clearEditorCategory, setEditorFiles, clearEditorFiles, setTemporaryUrls, removeTempUrl, clearUrls, setPostUploadingImgs } from '../../actions/postActions';
import { setAlert } from '../../actions/alertActions';

// Components - material UI
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';

import ImageBlock from '../common/ImageBlock';
import Spinner from '../common/Spinner';
import TextEditor from '../common/TextEditor';

// Icons = material UI
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import GifOutlinedIcon from '@material-ui/icons/GifOutlined';
import CategoryIcon from '@material-ui/icons/Category';
import CheckIcon from '@material-ui/icons/Check';

// Modal
import ModalContainer from '../modal/ModalContainer';
import PageModal from '../modal/PageModal';

import CategoryData from '../../utils/categoryData/postCategories';
import CategoryPage from './Categories';

// Initial State
const initialState = {
    text: ''
}

const TweetBox = ({ 
    isTablet, 
    handleDisplayModal, 
    togglePostModal,
    toggleCommentModal,
    addPost, 
    addComment, 
    setEditorText,
    clearEditorText,
    setEditorCategory, 
    clearEditorCategory,
    setEditorFiles,
    clearEditorFiles,
    setTemporaryUrls, 
    removeTempUrl, 
    clearUrls, 
    setPostUploadingImgs,
    setAlert, 
    postId, 
    auth: { 
        user_id,
        user 
    }, 
    post: { 
        post,
        editorText,
        editorCategory,
        editorFiles,
        urls 
    }, 
    nav: { 
        page,
        postModal,
        commentModal,
        campus_id,
        campus_name
    }, 
    comment, 
    modal, 
    sliding, 
    compose,
    history 
}) => {
    
    // Analytics: State for tracking Mixpanel 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // State for storing file dimensions
    const [widthHeights, setWidthHeights] = useState([]);
    
    // const [fileUrls, setFileUrls] = useState(null);
    // const [imageContent, setImageContent] = useState([]);

    // Text Editor State - Description
    // State for resetting the text editor
    const [resetEditor, setResetEditor] = useState(false);
    
    // State for displaying the full text editor
    const [showFullEditor, setShowFullEditor] = useState(false);

    // For the purpose of displaying Spinner on img pre-rendering
    // State for counting the number of files to upload
    const [filesToUploadNum, setFilesToUploadNum] = useState(0); 

    // State for toggling the dropdown
    const [dropdown, setDropdown] = useState(false);

    // State for menu height
    const [menuHeight, setMenuHeight] = useState(null);

    // State for toggling the category modal
    const [categoryModal, setCategoryModal] = useState(false);

    // State for tracking the clicked category
    const [categoryClicked, setCategoryClicked] = useState('');

    const [inputCharacterLength, setInputCharacterLength] = useState(0);

    const [didAlertForCharLimit, setDidAlertForCharLimit] = useState(false);

    // Log file dimensions when widthHeights state changes
    useEffect(() => {
        console.log('FILE DIMENSIONS')
        console.log(widthHeights);
    }, [widthHeights]);

    // Update the number of files to upload when editorFiles state changes
    useEffect(() => {
        setFilesToUploadNum(editorFiles.length);
    }, [editorFiles]);

    useEffect(() => {

        let characterCount = 0;

        for (let i = 0; i < JSON.parse(editorText)?.blocks.length; i++) {
            characterCount += JSON.parse(editorText)?.blocks[i].text.length

            if(i !== 0) { // For all line breaks add 2 characters
                characterCount += 2
            }
        }

        setInputCharacterLength(characterCount)

    }, [editorText]);

    // Get page URL
    var _URL = window.URL || window.webkitURL;

    // Toggle the category modal
    const toggleCategoryModal = () => {
        setCategoryModal(!categoryModal);
    }

    // Trigger the file upload process
    const fileUploadButton = () => {
        console.log(editorFiles);
        let fileList = [];
        editorFiles.map(file => fileList.push(file));

        let fileObj, img;

        // Open file selection dialog when file input is clicked
        document.getElementById('file').click();

        // Process selected files
        document.getElementById('file').onchange = (e) =>{   
            if(editorFiles.length + document.getElementById('file').files.length > 4 ) {
                setAlert('Please choose up to 4 photos.', 'okay')
            } else {
                for (var i = 0; i < document.getElementById('file').files.length; i++) {
                    
                    if(!document.getElementById('file').files[i]) return;
                    
                    const fileName = document.getElementById('file').files[i].name;

                    // --- File extension

                    const fileExt = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length)
                    console.log('File ext: ', fileExt);

                    if(!(fileExt.includes('jpeg') || 
                        fileExt.toLowerCase().includes('jpg') || 
                        fileExt.includes('png') || 
                        fileExt.includes('gif') ||
                        // Add all video extensions as well
                        fileExt.includes('mp4') ||
                        fileExt.includes('mov') ||  
                        fileExt.includes('avi')
                    )) {
                        setAlert('Only photos are allowed!', 'danger');
                        return;
                    }

                    // --- END File extension
                    
                    fileList.push(document.getElementById('file').files[i])
                    
    
                    // Gettting file dimensions
                    if((
                        fileExt.includes('mp4') ||
                        fileExt.includes('mov') ||  
                        fileExt.includes('avi')
                    )) {
                        // Getting video dimensions
                        console.log('---- VIDEO DIMENSIONS HERE: -----');
                        const video = document.createElement('video');
                        const fileObj = document.getElementById('file').files[i];

                        video.onloadedmetadata = function() {
                            console.log('Video Width: ' + this.videoWidth + ', Height: ' + this.videoHeight);
                            console.log('File Name: ' + fileName);

                            // Assuming you have a function to update state, like setWidthHeights
                            setWidthHeights(currentState => [
                                ...currentState,
                                {
                                    fileName,
                                    width: this.videoWidth,
                                    height: this.videoHeight
                                }
                            ]);
                        };

                        video.onerror = function() {
                            console.log('Not a valid video file: ' + fileObj.type);
                        };

                        video.src = window.URL.createObjectURL(fileObj);
                        // End video dimensions
                    } else {
                        // Getting file dimensions
                        console.log('---- FILE SIZE HERE: -----')
                        img = new Image();
                        fileObj = document.getElementById('file').files[i]
        
                        img.onload = function() {
                            console.log(this.width + " " + this.height);
                            console.log(fileName)
                            setWidthHeights(currentState => [...currentState, {
                                fileName,
                                width: this.width,
                                height: this.height
                            }])
                        };

                        img.onerror = function() {
                            console.log( "not a valid file: " + fileObj.type);
                        };
                        img.src = _URL.createObjectURL(fileObj);
                        

                        console.log("fILE Name: " + fileObj.name);
                    }

                    // End file dimensions
    
                }
                
                console.log('FILE LIST');
                console.log(fileList);
    
                setEditorFiles(fileList);
    
                // console.log('FILE URLS')
                // console.log(fileUrls);
                // onSubmitImage(fileList);
    
                clearUrls();
                onSubmitImage(fileList);
            }
        }

        // Track Mixpanel event for files uploaded (in production)
        if (process.env.NODE_ENV === 'production') {
            mixpanel.track("Files Uploaded - TweetBox", {
                "# of Imgs": `${fileList.length}`
            });
        }

    }


    const onSubmitImage = async (fileList) => {

        if(fileList?.length > 0) {
            setPostUploadingImgs(true);
        }

        let imgUpload_counter = 0;

        // Iterate through each file in the fileList
        fileList.map(file => {

            const fileExt = file.name.substring(file.name.lastIndexOf('.')+1, file.name.length)

            if((
                fileExt.includes('mp4') ||
                fileExt.includes('mov') ||  
                fileExt.includes('avi')
            )) {
                setTemporaryUrls(_URL.createObjectURL(file));

                if(imgUpload_counter + 1 === fileList.length) {
                    setPostUploadingImgs(false);
        
                } else {
                    imgUpload_counter = imgUpload_counter + 1
                }
                
            } else {
                let reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    // console.log('FILE PATH')
                    // console.log(reader.result);

                    // Set temporary URLs for the uploaded images
                    setTemporaryUrls(reader.result);

                    if(imgUpload_counter + 1 === fileList.length) {
                        setPostUploadingImgs(false);
            
                    } else {
                        imgUpload_counter = imgUpload_counter + 1
                    }
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                }
            }
        })
    
        console.log('IMG FILES');
        console.log(fileList);

        console.log('FILE URLS');
        // console.log(urls);
        // console.log(urls.length);
        
        // setUrls(urls);

        // addProductImg(fileList, detailProduct._id);
    };

    // const setUrls = (urls) => {
    //     setTemporaryUrls(urls);

    //     // renderImageContent();
    // }

    const submit = async e => {
        e.preventDefault();

        // -- Handle post/comment submission
        if(JSON.parse(editorText)?.blocks[0].text) {
            if(inputCharacterLength > 500) {
                setAlert("Error: Too many characters!", 'danger')
            } else {
                console.log('TEXT HERE');
                console.log(editorText);

                let data = {};
                if(editorText !== null) data.text = editorText;
                if(editorCategory) {
                    console.log('CATTY HERE');
                    console.log(editorCategory.value);

                    // Set the selected category value in the data object
                    data.category = editorCategory.value;
                }

                if(user) {
                    if(user.first_name !== null)data.username = `${user.first_name}${user.last_name && ' ' + user.last_name}`;
                    if(user.img !== null)data.avatar = user.img;
                    if(user_id !== null)data.user = {
                        _id: user._id,
                        username: user.username,
                        img: user.img,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        verified: user.verified,
                    };
                }

                if(sliding) {
                    if(postId) {

                        // Add a comment to a post with provided data, post, and history
                        // addComment(postId, data, post, history);
                        addPost(campus_id, campus_name, data, editorFiles, widthHeights, post);
                    } else {

                        // Add a new post with provided data, editorFiles, widthHeights, and history
                        addPost(campus_id, campus_name, data, editorFiles, widthHeights);
                    }

                } else {
                    if(postId) {

                        // Add a comment to a post with provided data and post
                        // addComment(postId, data, post);
                        addPost(campus_id, campus_name, data, editorFiles, widthHeights, post);
                    } else {
                        // Add a new post with provided data, editorFiles, and widthHeights
                        addPost(campus_id, campus_name, data, editorFiles, widthHeights);
                    }
                }

                // Clear the editor state after submission
                clearEditorFiles();
                clearUrls();
                clearEditorText();
                setEditorCategory(null);
                setResetEditor(true);

                // Toggle post and comment modals if necessary
                if(postModal) togglePostModal();
                if(commentModal) toggleCommentModal();

                // Mixpanel event tracking for post submission
                if (process.env.NODE_ENV === 'production') {
                    mixpanel.track("Submit Post", {
                        "First Name": `${user.first_name}`,
                        "Last Name": `${user.last_name}`,
                        "# of Imgs": `${editorFiles.length}`,
                        "Chosen Category": `${data.category}`
                    });
                }
            }
        }
    }

    const removeImg = async index => {
        console.log('OLD FILES HERE')
        console.log(editorFiles);

        // Create a new file list by copying each file from the editorFiles array
        let fileList = [];
        await editorFiles.map(file => fileList.push(file));

        // Remove the image file at the specified index
        fileList.splice(index, 1);

        console.log('NEW FILES HERE')
        console.log(fileList)
        
        // Update the editorFiles state with the updated file list
        setEditorFiles(fileList);

        // Remove / reset file input
        // var oldInput = document.getElementById("file");
        // oldInput.remove();

        // // creating a new input
        // var newInput = document.createElement("input");

        // newInput.type = "file";
        // newInput.name = "file";
        // newInput.id = "file";
        // newInput.files = null;

        // newInput.setAttribute("hidden", "")
        // newInput.setAttribute("multiple", "")

        // // Append as child to the parent
        // document.getElementById("fileInput-container").appendChild(newInput);

        // Remove temporary URL associated with the removed image
        await removeTempUrl(index);
    }

    const todo = () => {
        
        if(page === 'create') {

            // Toggle the category modal for post creation
            toggleCategoryModal();
        } else {

            // Toggle the dropdown for category selection
            setDropdown(!dropdown)
        }

        // Mixpanel event tracking for category modal toggle
        if (process.env.NODE_ENV === 'production') {
            mixpanel.track("Toggle Category Modal");
        }
    }

    // Handle post category switch
    const chooseCategory = (category) => {
        setEditorCategory(category);
        setCategoryClicked(category.value);

        // What happens after category is clicked
        setTimeout(() => {
            todo();
            setCategoryClicked('');

            if(!editorCategory || editorCategory.value !== category.value) {
                
                // Set an alert if the category is switched
                setAlert('Category switched', 'okay')
            }
        }, 200);

        // Mixpanel event tracking for switching post category
        if (process.env.NODE_ENV === 'production') {
            mixpanel.track("Switch Post Category", {
                "Chosen Category": `${category}`
            });
        }
    }

    // Redirct to full screen create page when tweetBox is clicked on mobile
    const handleBoxClick = () => {
        if(comment) {
            // Redirect to create comment page with postId
            window.location.href = `/create/comment/${postId}`;
        } else {
            // Redirect to create post page & add a goBack parameter
            window.location.href = `/create/post?goBack=${page}`;
        }
    }

    const setAlertAction = () => {

        if(!didAlertForCharLimit) {
            // Display an alert message to notify the user that the post was sent
            setAlert("Error: Too many characters!", 'danger');
            setDidAlertForCharLimit(true);

            // Set timer to not show alert for EVERY time user types a character
            setTimeout(() => {
                
                setDidAlertForCharLimit(false);
            }, 3000 );
        }
    }

    // Close dropdown if screen shrinks to Tablet size
    if (isTablet && dropdown) {
        setDropdown(false);
    }

    // --- ImageKit (img optimization service) ---

    let optimized_profile_img_path;

    if(user && user.img) {

        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    return (
        <Fragment>
            <div onClick={isTablet ? () => handleBoxClick() : () => setShowFullEditor(true)} className={modal ? "tweetBox__modal" : "tweetBox"}>

                {/* TweetBox Profile image - left side 3:7 */}
                <div onClick={() => window.location.href = `/${user && user.username}`} className={modal ? "tweetBox__modal__avatar" : "post__avatar"}>
                    {user && user.img ? (
                        <Avatar src={optimized_profile_img_path} />
                    ) : (
                        <DefaultAvatar name={user && `${user.first_name} ${user.last_name}`} />
                    )}
                </div>

                {/* TweetBox Body (input, dropdown menu etc.) - right side 3:7 */}
                <div className={modal ? `tweetBox__modal__body ${sliding && 'full_page'}` : "tweetBox__body"}>
                    
                    {/* Display post user name when commenting */}
                    {comment && (
                        <div className={modal || comment ? "tweetBox__modal__reply" : "tweetBox__reply" }>
                            <p>Replying to <span>{post && `${post.username}`}</span></p>
                        </div>
                    )}

                    {/* Display 'post creation' input OR 'commenting' input */}
                    {compose ? (

                        /* Render textEdidor for Post creation */
                        <div style={{minHeight:`${filesToUploadNum > 0 ? '120px' : '360px'}`}}>
                            <div className="tweetBox__compose__input">

                                {/* Render text editor */}
                                <TextEditor maxLength={500} inputCharacterLength={inputCharacterLength} setAlertAction={setAlertAction} postId={postId} descriptionObj={editorText} setDescriptionObj={setEditorText} resetEditor={resetEditor} setResetEditor={setResetEditor} />
                               
                            </div>

                            {/* Display images from Post */}
                            <ImageBlock filesToUploadNum={filesToUploadNum} dimensions={widthHeights} removeImg={removeImg} admin />
                        </div>
                    ) : (
                        
                        /* Render textEdidor for Comments */
                        <Fragment>
                            <div className={modal ? "tweetBox__modal__input" : "tweetBox__input"}>
                                {modal ? (

                                    // Render the text editor in a modal
                                    <TextEditor maxLength={500} inputCharacterLength={inputCharacterLength} setAlertAction={setAlertAction} postId={postId} descriptionObj={editorText} setDescriptionObj={setEditorText} resetEditor={resetEditor} setResetEditor={setResetEditor} />
                                ) : (

                                    // If isTablet textEditor is not rendered bc user will be re-routed on click (i.e. handleBoxClick function)
                                    isTablet ? (
                                        postId ? (

                                            // Render a specific text for commenting on a post
                                            <div>Write a comment...</div>
                                        ) : (

                                            // Render a specific text for creating a new post
                                            <div>What are you selling?</div>
                                        )
                                    ) : (

                                        // Render the text editor outside of a modal
                                        <TextEditor maxLength={500} inputCharacterLength={inputCharacterLength} setAlertAction={setAlertAction} postId={postId} descriptionObj={editorText} setDescriptionObj={setEditorText} resetEditor={resetEditor} setResetEditor={setResetEditor} />
                                    )
                                    
                                )}
                            </div>

                                {/* Display images from comment */}
                                <ImageBlock filesToUploadNum={filesToUploadNum} dimensions={widthHeights} removeImg={removeImg} admin />
                        </Fragment>
                    )}

                    {/* Add space for the Image Block - cuz its position absolute */}
                    {filesToUploadNum > 0 && (
                        <div style={{marginTop: '240px'}}></div>
                    )} 

                    {/* Render category selection section */}
                    {postId || isTablet ? (
                        showFullEditor && (
                            <div className={modal ? "tweetBox__modal__setting--container" : "tweetBox__setting--container"}>

                                {/* Character Count Display */}
                                <div style={{color:'rgb(27, 149, 224)', marginRight:'20px', display:'flex', justifyContent:'flex-end', width:'100%', fontSize:'14px', overflowWrap:'break-word'}}>
                                    <span>{editorText ? `${inputCharacterLength}` : '0'} / 500</span>
                                </div>
                            </div>
                        )
                    ) : (
                        modal || showFullEditor ? (
                            <div className={modal ? "tweetBox__modal__setting--container" : "tweetBox__setting--container"}>
                                <div onClick={todo} className={modal ? "tweetBox__modal__setting" : "tweetBox__setting" }>

                                    {/* Render the icon for the selected category or a default category icon */}
                                    {editorCategory ? editorCategory.icon : <CategoryIcon fontSize="small" />}

                                    {/* Render the text for the selected category or a default category text */}
                                    <p>{editorCategory ? editorCategory.text : 'All Categories'}</p>
                                </div>

                                {/* Character Count Display */}
                                <div style={{color:'rgb(27, 149, 224)', marginRight:'20px', fontSize:'14px', overflowWrap:'break-word'}}>
                                    <span>{editorText ? `${inputCharacterLength}` : '0'} / 500</span>
                                </div>

                                {/* Render the category dropdown */}
                                <div className={`category-dropdown ${dropdown ? 'active' : ''} ${compose ? 'compose' : ''}`} style={{height: menuHeight}}>
                                    <CategoryPage chooseCategory={chooseCategory} postCategory={editorCategory} categoryClicked={categoryClicked} />
                                </div>

                                {/* Render the hidden overlay for closing the category dropdown */}
                                <div
                                    className={`hidden-overlay ${dropdown ? "show" : ""}`}
                                    onClick={() => setDropdown(!dropdown)}
                                />
                            </div>
                        ) : null
                    )}

                    {/* Render file upload section */}
                    <div id="fileInput-container" className={modal ? "tweetBox__modal__actions" : "tweetBox__actions"}>
                        <input
                            type="file"
                            name="file"
                            id="file"
                            accept="image/png, image/gif, image/jpeg, image/jpg, video/*"
                            hidden 
                            multiple={!(urls.length > 2)}
                        />
                        <div className={modal ? "tweetBox__modal__icons" : "tweetBox__icons"}>

                            {/* Render the block for adding a photo */}
                            <div onClick={fileUploadButton} className={modal ? "tweetBox__modal__setting" : "tweetBox__setting"}>

                                {/* Icon */}
                                <AddPhotoAlternateOutlinedIcon fontSize="small" />

                                {/* text with 2 spaces */}
                                &nbsp;&nbsp;Photo/Video
                            </div>
                            {/* <div className={modal ? "tweetBox__modal__icon" : "tweetBox__icon"}>
                                <GifOutlinedIcon />
                            </div> */}
                        </div>

                        {/* Render the tweet button or send button */}
                        {modal ? (
                            <Button onClick={submit} className="tweetBox__modal__tweetButton">
                                {comment ? 'Reply' : 'Post'}
                            </Button>
                        ) :(
                            <button onClick={submit} className="secondaryBtn">
                                {comment ? 'Send Reply' : 'Send Post'}
                            </button>
                        )}
                        
                    </div>
                </div>
                
                {/* <input 
                    className="tweetBox__imageInput"
                    placeholder="Optional: Enter image URL" 
                    type="text"
                ></input> */}
            </div>

            <ModalContainer show={categoryModal} onClose={toggleCategoryModal}>
                
                {/* Render the page modal */}
                <PageModal>
                    <div className="category-page">

                        {/* Render the CategoryPage component */}
                        <CategoryPage closeModal={toggleCategoryModal} chooseCategory={chooseCategory} postCategory={editorCategory} categoryClicked={categoryClicked} />
                    </div>
                </PageModal>
            </ModalContainer>
        </Fragment>
    )
}

TweetBox.propTypes = {
    togglePostModal: PropTypes.func.isRequired,  // Prop: Function to toggle post modal
    toggleCommentModal: PropTypes.func.isRequired,  // Prop: Function to toggle comment modal
    addPost: PropTypes.func.isRequired,  // Prop: Function to add a post
    addComment: PropTypes.func.isRequired,  // Prop: Function to add a comment
    setEditorText: PropTypes.func.isRequired,  // Prop: Function to set editor text
    clearEditorText: PropTypes.func.isRequired,  // Prop: Function to clear editor text
    setEditorCategory: PropTypes.func.isRequired,  // Prop: Function to set editor category
    clearEditorCategory: PropTypes.func.isRequired,  // Prop: Function to clear editor category
    setEditorFiles: PropTypes.func.isRequired,  // Prop: Function to set editor files
    clearEditorFiles: PropTypes.func.isRequired,  // Prop: Function to clear editor files
    setTemporaryUrls: PropTypes.func.isRequired,  // Prop: Function to set temporary URLs
    removeTempUrl: PropTypes.func.isRequired,  // Prop: Function to remove temporary URL
    clearUrls: PropTypes.func.isRequired,  // Prop: Function to clear URLs
    setPostUploadingImgs: PropTypes.func.isRequired, // Prop: Function to update post_uploading state
    setAlert: PropTypes.func.isRequired,  // Prop: Function to set an alert
    auth: PropTypes.object.isRequired,  // Prop: Object representing authentication state
    post: PropTypes.object.isRequired,  // Prop: Object representing post state
    nav: PropTypes.object.isRequired,  // Prop: Object representing navigation state
}

const mapStateToProps = state => ({
    auth: state.auth,  // Connect: Map authentication state from redux store to auth prop
    post: state.post,  // Connect: Map post state from redux store to post prop
    nav: state.nav  // Connect: Map navigation state from redux store to nav prop
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    togglePostModal, 
    toggleCommentModal, 
    addPost, 
    addComment, 
    setEditorText, 
    clearEditorText,
    setEditorCategory, 
    clearEditorCategory, 
    setEditorFiles, 
    clearEditorFiles, 
    setTemporaryUrls, 
    removeTempUrl, 
    clearUrls, 
    setPostUploadingImgs,
    setAlert 
})(TweetBox);
