import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import mixpanel from 'mixpanel-browser';

// Google Maps API
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

// Redux
import { connect } from 'react-redux';

// Actions
import { addLocation, clearLocations, getLocations, setLocationActive } from '../../actions/locationActions';
import { toggleLocationModal } from '../../actions/navActions';
import { setAlert } from '../../actions/alertActions';

// components - material UI
import { Button } from '@material-ui/core';
import Spinner from '../../components/common/Spinner';
import LocationList_Item from '../../components/modal/LocationModal/LocationList_Item';

// icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';

// -- Initial form states ----

const initialState = {
    // Address Location Data
    city: '',
    state: '',
    country: '',
    area: '',
    stateProvince: '',
    street_number: '',
    formatted_address: '',
    street_name: '',
    postalCode: '',
    placeId: '',
    latLng: '',
};

const initialCoordinates = {
    lat: null, 
    lng: null
};
// -- END: Initial form states ----

const GuestAddressPage = ({ 
    handleSlideMenu, 
    nav: {
        locationModal
    },
    history,
    locationState: {
        loading,
        locations
    },
    auth: {
        isAuthenticated
    },
    addLocation, 
    clearLocations, 
    getLocations, 
    setLocationActive,
    toggleLocationModal,
    setAlert
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    // List of current addresses
    const [locationList, setLocationList] = useState([]);
    const [gotLocationList, setGotLocationList] = useState(false);

    // --- Address Location stuff

    const [addNewAddress, setAddNewAddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(false);
    const [address, setAddress] = useState('');
    const [addressData, setAddressData] = useState(initialState);
    const [coordinates, setCoordinates] = useState(initialCoordinates);
    const [roomNumber, setRoomNumber] = useState('');

    const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false);

    // --- END: Address Location stuff

    // Google Maps API
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"]
    });

    // Extract URL and previous URL page
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    let previousUrl = url.searchParams.get("goBack");

    useEffect(() => {

         // Add event listener for window resize
         window.addEventListener('resize', () => handleWindowSizeChange());

         if(locationModal) toggleLocationModal();

         // Cleanup the event listener when the component unmounts
         return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => { 
        
        getLocations();
    }, []);

    useEffect(() => {

        renderLocationList();
    }, [locations]);

    // Function to handle apt/suit number change
    const on_Room_Num_Change = e => setRoomNumber(e.target.value);

    const onAddressInputChange = e => {
        
        setSelectedAddress(false);
    };

    const resetPage = () => {
        
        setAddNewAddress(false);
        setSelectedAddress(false);
        setAddress('');
        setAddressData(initialState);
        setCoordinates(initialCoordinates);
    }

    const handleLocationSelect = async (value) => {

        // TODO: Add try catch block w/ error handling for if location not found -> when user clicks enter on bad address text
        const result = await geocodeByAddress(value);
        const latLng = await getLatLng(result[0])
        // END: TODO 

        console.log('VALUE:');
        console.log(value);
        console.log('RESULTS:')
        console.log(result);
        console.log('LATLNG');
        console.log(latLng);
    
        let locationTags = [];
    
        if(result[0].types && result[0].types.length > 0) {
            result[0].types.map(type => locationTags.push(type));
        };

        const address = result[0].formatted_address;
        const placeId = result[0].place_id;
        const addressArray =  result[0].address_components;
        const city = getCity(addressArray);
        const country = getCountry(addressArray);
        const area = getArea(addressArray);
        const state = getState(addressArray);
        const postalCode = getPostalCode(addressArray);
        const street = getStreet(addressArray);
        const number = getNumber(addressArray);
    
    
        console.log('city: ' + city);
        console.log('state: ' + state);
        console.log('country: ' + country);
        console.log('area: ' + area);
        console.log('state: ' + state);
        console.log('number: ' + number);
        console.log('street: ' + street);
        console.log('postalCode: ' + postalCode);
        console.log("formatted address: " + address);
        console.log("placeId: " + placeId);

        if(city.toLowerCase() === 'norman') {
            setAddress(value);
            setAddressData({
                ...addressData,
                city: (city) ? city : '',
                state: (state) ? state : '',
                country: (country) ? country : '',
                area: (area) ? area : '',
                stateProvince: (state) ? state : '',
                street_number: (number) ? number : '',
                formatted_address: (address) ? address : '',
                street_name: (street) ? street : '',
                postalCode: (postalCode) ? postalCode : '',
                placeId: (placeId) ? placeId : '',
                latLng: (latLng) ? latLng : {},
                location_tags: (locationTags) ? locationTags : []
            })

            setCoordinates(latLng);
            setSelectedAddress(true);
        } else {
            setAlert("Must be in Norman, Oklahoma", 'danger')
        }

        setLoadingCurrentLocation(false);
    };

    const getCity = ( addressArray ) => {
        let city = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0] && 'locality' === addressArray[ i ].types[0] ) {
                city = addressArray[ i ].long_name;
                return city;
            }
        }
    };
  
    const getArea = ( addressArray ) => {
        let area = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0]  ) {
                for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
                    if ( 'administrative_area_level_2' === addressArray[ i ].types[j] ) {
                        area = addressArray[ i ].long_name;
                        return area;
                    }
                }
            }
        }
    };
  
    const getCountry = ( addressArray ) => {
        let area = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0]  ) {
                for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
                    if ( 'country' === addressArray[ i ].types[j] ) {
                        area = addressArray[ i ].long_name;
                        return area;
                    }
                }
            }
        }
    };
  
    const getPostalCode = ( addressArray ) => {
        let area = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0]  ) {
                for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
                    if ( 'postal_code' === addressArray[ i ].types[j] ) {
                        area = addressArray[ i ].long_name;
                        return area;
                    }
                }
            }
        }
    };

    const getState = ( addressArray ) => {
        let state = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'administrative_area_level_1' === addressArray[ i ].types[0] ) {
                    state = addressArray[ i ].long_name;
                    return state;
                }
            }
        }
    };
  
    const getNumber = ( addressArray ) => {
        let state = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'street_number' === addressArray[ i ].types[0] ) {
                    state = addressArray[ i ].long_name;
                    return state;
                }
            }
        }
    };
  
    const getStreet = ( addressArray ) => {
        let state = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'route' === addressArray[ i ].types[0] ) {
                    state = addressArray[ i ].long_name;
                    return state;
                }
            }
        }
    };
    

    const onSubmitLocation = () => {

        const newLocation = {
            name: '',
            street_name: addressData.street_name,
            street_number: addressData.street_number,
            city: addressData.city,
            state: addressData.state,
            postalCode: addressData.postalCode,
            country: addressData.country,
            area: addressData.area,
            placeId: addressData.placeId,
            stateProvince: addressData.stateProvince,
            formatted_address: addressData.formatted_address,
            room_number: roomNumber,
            location_tags: addressData.location_tags,
            coordinates: addressData.latLng
        }

        console.log('FORMATTED ADDRESS')
        console.log(newLocation)
    
        addLocation(newLocation, locations);

        resetPage();
    };
    
    const renderLocationList = () => {
        setLocationList([]);

        if(locations === null) {
            setLocationList([(
                <Spinner />
            )])
        }
        else { 
            if(locations.length > 0) {

                locations.map(location_item => {
                    setLocationList(locationList => [...locationList, (
                        <LocationList_Item
                            detailLocation={location_item}
                            setLocationActive={setLocationActive}
                            locations={locations}
                        />
                    )])
                });

            }
        } 

        setGotLocationList(true);
    }

    const goBack = () => {
        if(previousUrl) {
            history.goBack();
        } else {
            window.location.href = '/marketplace';
        }
    }

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Guest Address Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    // Redirect logic for non-mobile devices
    if(isAuthenticated) {
        history.push({
            pathname: `/settings/addresses`,
            // search: `?goBack=${previousUrl}`,
        })
    }

    // -- Get current location logic --
    
    const success = async (pos) => {
        const lat = pos.coords.latitude;
        const lng = pos.coords.longitude;

        console.log(`YOUR CURRENT POSTION: latitude: ${lat}, longitude: ${lng}`)

        try {
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
            );
            
            console.log('GEOCODE RESPONSE!!')
            console.log(response)

            handleLocationSelect(response.data.results[0].formatted_address)

          } catch (error) {

            console.error('Error fetching address from google api: ', error);
          }
    }

    const error = (err) => {
        console.log('GEOLOCATION ERROR - FAILED')
        console.log(err);
    }

    const options = {};


    const getCurrentLocation = () => {
        console.log('GEO LOCATION CALLED')
        if(navigator.geolocation) {
            setLoadingCurrentLocation(true);
            navigator.geolocation.getCurrentPosition(success, error, options)
        } else {
            alert("Location services not supported by this browser.")
        }
    }

    // -- END: Get current location logic --

    return (
        <div style={{width: '100vw'}} className="settings">

            {/* Header */}
            <div className="settings__header">

                {/* Back Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                </div>

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        Add an Address
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div style={{padding: '20px 20px 0px'}} className="settings__body">

                {isLoaded && (
                    <Fragment>

                        {!selectedAddress ? (
                            <AddressAutoComplete address={address} setAddress={setAddress} handleLocationSelect={handleLocationSelect} getCurrentLocation={getCurrentLocation} loadingCurrentLocation={loadingCurrentLocation} />
                        ) : (
                            <Fragment>
                                <div style={{display: 'flex'}}>
                                    <input 
                                        autocomplete="off"
                                        style={{width:'100%', outline:'none', padding:'0 10px', height:'50px', fontSize:'14px', border:'2px solid #e6ecf0', borderRadius:'5px'}}
                                        placeholder="Enter Address"
                                        value={address}
                                        onChange={e => onAddressInputChange(e)}
                                        type="text"
                                        name="address"
                                    /> 
                                </div>

                                <div style={{width: '100%', height: '230px', overflow: 'hidden', borderRadius: '16px'}}>
                                    {!coordinates.lat ? <Spinner /> : ( 
                                        <GoogleMap 
                                            zoom={17} 
                                            center={{lat: coordinates.lat, lng: coordinates.lng}} 
                                            mapContainerClassName='map-container'
                                            options={{
                                                disableDefaultUI: true
                                            }}
                                        >
                                            <Marker position={{ lat: coordinates.lat, lng: coordinates.lng }} />
                                        </GoogleMap>
                                    )}
                                </div>

                                <div style={{background: 'rgb(254, 249, 217)', margin: '10px 0', borderRadius: '16px', display: 'flex', alignItems: 'center', width: '100%', minHeight: '48px', padding: '16px 0', boxSizing: 'border-box'}}>
                                    <div style={{height:'36px', alignItems:'center', width: '36px', justifyContent:'center', display:'flex', padding:'0px 6px', display:'flex', borderRadius:'50%', boxSizing:'border-box'}}>
                                        <div style={{fontSize:'24px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <RoomOutlinedIcon />
                                        </div>
                                    </div>
                                    <div style={{flex: 1, boxSizing: 'border-box', marginLeft: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                        <div style={{fontSize: '20px', fontWeight: 'bold'}}>
                                            {addressData.street_number}{' '}{addressData.street_name}
                                        </div>
                                        <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px', overflow: 'hidden'}}>
                                            {addressData.city}{', '}{addressData.state}
                                        </div>
                                    </div>
                                </div>

                                <div style={{display:'flex', marginBottom: '10px', width: '100%'}}>
                                    <input 
                                        autoComplete="off"
                                        autocomplete="chrome-off"
                                        style={{width:'100%', outline:'none', padding:'0 10px', height:'50px', fontSize:'14px', border:'2px solid #e6ecf0', borderRadius:'5px'}}
                                        placeholder="Apt/Dorm Number (optional)"
                                        type="text"
                                        name="roomNumber"
                                        onChange={e => on_Room_Num_Change(e)}
                                    />
                                </div>
                                
                                <div onClick={onSubmitLocation} style={{backgroundColor: 'rgb(0, 164, 255)', borderColor: 'rgb(0, 164, 255)', minWidth: '88px', minHeight: '48px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                                    <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                                        Save and Finish
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </Fragment>

                )}

                {address.trim() === '' && (
                    <Fragment>
                        {/* <div className='location_list_item link'>
                            <div style={{height:'36px', alignItems:'center', width: '36px', justifyContent:'center', display:'flex', padding:'0px 6px', display:'flex', borderRadius:'50%', boxSizing:'border-box'}}>
                                <div style={{fontSize:'24px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <AddCircleOutlineIcon />
                                </div>
                            </div>
                            <div style={{flex: 1, boxSizing: 'border-box', marginLeft: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <div style={{fontSize: '20px', fontWeight: 'bold'}}>
                                    Get Current Location
                                </div>
                            </div>
                        </div> */}

                        {locationList.length > 0 && (
                            <div style={{width: '100%', fontWeight: 400, borderTop: '1px solid #e6ecf0', paddingTop: '20px'}}>
                                <h3 style={{fontWeight: 400,}} >Saved Addresses</h3>
                            </div>
                        )}
                        
                        {gotLocationList ? locationList : <Spinner />}
                    </Fragment>
                )}
            </div>
        </div>
    )
}

const AddressAutoComplete = ({ address, setAddress, handleLocationSelect, getCurrentLocation, loadingCurrentLocation }) => {

    return (
        <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleLocationSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div style={{width: '100%', flex: 1}}>
                    <div style={{display: 'flex'}}>
                        <input 
                            autocomplete="off"
                            style={{width:'100%', outline:'none', padding:'0 10px', height:'50px', fontSize:'14px', border:'2px solid #e6ecf0', borderRadius:'5px'}}
                            {...getInputProps({
                                placeholder: "Enter Address",
                                type:"text",
                                name:"address",
                            })}  
                        /> 
                    </div>

                    <div className="modal-table-list-container modular">
                        {!loadingCurrentLocation ? (
                            <div onClick={() => getCurrentLocation()} className='location_list_item link'>
                                <div style={{height:'36px', alignItems:'center', width: '36px', justifyContent:'center', display:'flex', padding:'0px 6px', display:'flex', borderRadius:'50%', boxSizing:'border-box'}}>
                                    <div style={{fontSize:'24px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <RoomOutlinedIcon />
                                    </div>
                                </div>
                                <div style={{flex: 1, boxSizing: 'border-box', marginLeft: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <div style={{fontSize: '20px', fontWeight: 'bold'}}>
                                        Get current location
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='location_list_item no_hover'>
                                <div style={{height:'36px', alignItems:'center', width: '36px', justifyContent:'center', display:'flex', padding:'0px 6px', display:'flex', borderRadius:'50%', boxSizing:'border-box'}}>
                                    <div style={{fontSize:'24px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Spinner />
                                    </div>
                                </div>
                                <div style={{flex: 1, boxSizing: 'border-box', marginLeft: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <div style={{fontSize: '20px', fontWeight: 'bold'}}>
                                        loading... 
                                    </div>
                                </div>
                            </div>
                        )}

                        {loading ? (
                            <Spinner />
                        ) : null} 

                        {suggestions.map((suggestion) => {

                            // TODO: Implement the below classes and styles to show active suggestions (i.e. on key up/down)
                            const className = suggestion.active 
                                ?  "" 
                                : "";
                            const style = {
                                backgroundColor: suggestion.active ? "rgb(236, 238, 233)" : "#fff"
                            }
                            console.log(suggestion)
                            
                            return (
                                <div key={suggestion.index} {...getSuggestionItemProps(suggestion, { style })} className='location_list_item'>
                                    <div style={{height:'36px', alignItems:'center', width: '36px', justifyContent:'center', display:'flex', padding:'0px 6px', display:'flex', borderRadius:'50%', boxSizing:'border-box'}}>
                                        <div style={{fontSize:'24px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <RoomOutlinedIcon />
                                        </div>
                                    </div>
                                    <div style={{flex: 1, boxSizing: 'border-box', marginLeft: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                        <div style={{fontSize: '20px', fontWeight: 'bold'}}>
                                            {suggestion.formattedSuggestion.mainText}
                                        </div>
                                        <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px', overflow: 'hidden'}}>
                                            {suggestion.formattedSuggestion.secondaryText}
                                        </div>
                                    </div>
                                    {/* <div style={{color: 'rgb(29, 155, 240)', display: 'flex', alignItems: 'center'}}>
                                        <CheckIcon />
                                    </div> */}
                                </div>
                            )
                        })}
                        

                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}

GuestAddressPage.propTypes = {
    nav: PropTypes.object.isRequired,
    locationState: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    addLocation: PropTypes.func.isRequired, 
    clearLocations: PropTypes.func.isRequired, 
    getLocations: PropTypes.func.isRequired, 
    setLocationActive: PropTypes.func.isRequired,
    toggleLocationModal: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    locationState: state.location,
    nav: state.nav,
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    addLocation, 
    clearLocations, 
    getLocations, 
    setLocationActive,
    toggleLocationModal,
    setAlert
})(GuestAddressPage);
