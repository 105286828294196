import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Components
import Spinner from '../../../components/common/Spinner';
import Cart_List_Item from './Cart_List_Item';

const Cart_List = ({
    cart: {
        cart,
        loading
    }
}) => {

    const [cartList, setCartList] = useState([]);
    const [gotCartList, setGotCartList] = useState(false);

    const { items } = cart;

    useEffect(() => {

        renderCartList();
    }, [items]);

    const renderCartList = () => {
        setCartList([]);

        console.log(items)

        if(items === null) {
            setCartList([(
                <Spinner />
            )])
        }
        else { 
            if(items.length > 0) {

                items.map(cart_item => {
                    setCartList(cardList => [...cardList, (
                        <Cart_List_Item item={cart_item} />
                    )])
                });

                setGotCartList(true);
            } else {
                setCartList([(
                    <div className="no-products">
                        <h1>Your bag is empty</h1>
                    </div>
                )])
            }
        } 
    }

    return (
        <Fragment>
            {cartList}
        </Fragment>
    )
}

Cart_List.propTypes = {
    cart: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    cart: state.cart
})

export default connect(mapStateToProps, null)(Cart_List);