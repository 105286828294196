import React, { Fragment, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Blurhash } from 'react-blurhash';

// Redux
import { connect } from 'react-redux';

const ImageBox = ({
    img_src,
    img_obj, 
    imgLoaded, 
    setImgLoaded,
    borderRadius
}) => {

    // --- ImageKit (img optimization service) ---

    let optimized_img_path = '';
   
    // Replace the firebase URL with ImageKit.io URL-endpoint
    if(img_obj) {
        optimized_img_path = img_obj.img_path.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    } else if (img_src) {
        optimized_img_path = img_src;
    }

   useEffect(() => {
        const img = new Image()
        img.onload = () => {
            setImgLoaded(true)
        }
        img.src = optimized_img_path

   }, [img_obj, img_src])

   const maxHeight = 176;
            
    // Get the original dimensions of the image
    var originalWidth = 500;
    var originalHeight = 500;

    if(img_obj) {
        originalWidth = img_obj.width;
        originalHeight = img_obj.height;
    }

    // Calculate the scaling factor based on the max height
    var scale = maxHeight / originalHeight;

    // Calculate the new dimensions based on the scaling factor
    var newWidth = originalWidth * scale;
    var newHeight = maxHeight;

  return (
    <Fragment>
        {!imgLoaded && (
            <div style={{
                overflow: 'hidden',
                width: `100%`,
                height: `100%`,
                borderRadius: `${borderRadius ? borderRadius : '16px'}`,
                background: '#e6ecf0',
                display: 'inline-block',
                border: '1px solid rgb(207, 217, 222)',
                position: 'relative'
            }}>
                <Blurhash
                    hash="L8I;kmVf#t8^$+RktlpI00t+pc.T"
                    width={newWidth}
                    height={newHeight}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1} 
                />
            </div>
        )}

        {imgLoaded && (

            <img 
                style={{aspectRatio: '1 / 1', objectFit: 'contain', width: '100%', height: '100%'}} 
                src={optimized_img_path} 
                alt="img" 
                loading='lazy'
            />
        )}
    </Fragment>
  )
}

ImageBox.propTypes = {

}

const mapStateToProps = state => ({
    // Mapping the auth state from the Redux store to the 'auth' prop

});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component

})(ImageBox);