import { 
    GET_REFUNDS,
    REFUND_ERROR,
    CLEAR_REFUNDS,
    REFUNDS_LOADING
} from "../actions/types";

const initialState = {
    refunds: [],
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case REFUNDS_LOADING:

            // Set the loading state to true
            return {
                ...state,
                loading: true
            };
        case GET_REFUNDS:

            // Set the refunds state to the payload value,
            // mark all refunds as read, and set loading to false
            return {
                ...state,
                refunds: payload,
                loading: false
            }
        case REFUND_ERROR:

            // Set the error state to the payload value and set loading to false
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case CLEAR_REFUNDS:

            // Clear the refunds state and set loading to false
            return {
                ...state,
                refunds: [],
                loading: false,
            }
        default:
            return state;
    }
}