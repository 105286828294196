import React from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

import CurrencyFormat from '../../common/CurrencyFormat';

const Checkout_Totals = ({ 
    cart: {
        cart: {
            subtotal,
            total,
            tax,
            tip,
            discountPrice
        }
    }
 }) => {

    return (
        <div style={{marginBottom: '16px', padding: '0 16px'}}>
            <div style={{minHeight: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                <div style={{fontSize: '15px', fontWeight: 500}}>
                    Subtotal :
                </div>
                {subtotal !== discountPrice ? (
                    <div style={{fontSize: '15px', fontWeight: 500}}>
                        <s style={{color: 'rgb(92, 92, 92)'}}><CurrencyFormat value={subtotal} /></s> <CurrencyFormat value={discountPrice} />
                        
                    </div>
                ) : (
                    <div style={{fontSize: '15px', fontWeight: 500}}>
                        <CurrencyFormat value={subtotal} />
                        
                    </div>
                )}
            </div>
            {/* <div style={{minHeight: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                <div style={{fontSize: '15px', fontWeight: 500}}>
                    Delivery Fee :
                </div>
                <div style={{fontSize: '15px', fontWeight: 500}}>
                    $ 0.00
                </div>
            </div> */}
            <div style={{minHeight: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                <div style={{fontSize: '15px', fontWeight: 500}}>
                    Tax Fee :
                </div>
                <div style={{fontSize: '15px', fontWeight: 500}}>
                    <CurrencyFormat value={tax} />
                </div>
            </div>
            <div style={{minHeight: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                <div style={{fontSize: '15px', fontWeight: 500}}>
                    Driver tip :
                </div>
                <div style={{fontSize: '15px', fontWeight: 500}}>
                    <CurrencyFormat value={tip} />
                </div>
            </div>
            <div style={{minHeight: '40px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                <div style={{fontSize: '15px', fontWeight: 500}}>
                    Total :
                </div>
                <div style={{fontSize: '15px', fontWeight: 500}}>
                    <CurrencyFormat value={total} />
                </div>
            </div>
        </div>
    )
}

Checkout_Totals.propTypes = {
    cart: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    cart: state.cart
})

export default connect(mapStateToProps, null)(Checkout_Totals);
