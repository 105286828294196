import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleAuthModal, toggleLocationModal } from '../../actions/navActions';
import { setPage, add_To_Recent_History } from '../../actions/navActions';
import { clearCart } from '../../actions/cartActions';

// Layout Wrapper
import CartLayout from '../../components/layout/CartLayout';

// Components
import Cart_List from '../components/Cart/Cart_List';
import Discount_List from '../components/Cart/Discount_List';
import { Button } from '@material-ui/core';
import ChangeAddress from '../../components/Order/ChangeAddress';
import ChangePhone from '../components/Cart/ChangePhone';
import CouponBlock from '../components/Cart/CouponBlock';
import CurrencyFormat from '../common/CurrencyFormat';
import Delivery_Widget from '../common/Delivery_Widget';

// Modal
import Modal from '../../components/modal/Modal';
import ModalContainer from '../../components/modal/ModalContainer';

// Icons
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LockIcon from '@material-ui/icons/Lock';

// Imgs
import closed_sign from '../../utils/imgs/closed_sign.jpg'

const CartPage = ({
    auth,
    cart: {
        cart,
        loading
    },
    discount: {
        discounts
    },
    zone: {
        detailZone,
        isOpen
    },
    locationState: {
        detailLocation,
        deliveryTime,
    },
    toggleLocationModal,
    toggleAuthModal,
    setPage,
    add_To_Recent_History,
    clearCart
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const [cartList, setCartList] = useState([]);
    const [gotCartList, setGotCartList] = useState(false);
    const [cartHasItems, setCartHasItems] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Modal Toggles
    const [clearCartModal, setClearCartModal] = useState(false);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const show = url.searchParams.get("show");

    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());

        setPage('cart');
        add_To_Recent_History(url.pathname);  // current url

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

        if(cart?.items.length > 0) {
            setCartHasItems(true)
        } else {
            setCartHasItems(false)
        }

    }, [cart]);


    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }

            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

    }

    const toggleClearCartModal = () => {
        setClearCartModal(!clearCartModal);
    }

    const handleClearCart = () => {
        clearCart();

        toggleClearCartModal();

        setCartHasItems(false);
    }

    const handle_go_to_checkout = () => {

        if(!auth.loading && auth.user) {
            if(detailLocation) {
                if(auth.user.phone?.length > 0) {
                    window.location = "/checkout"
                } else {
                    window.location = "/cart?show=add_phone"
                }
            } else {
                window.location = "/cart?show=add_address"
            }
        } else {
            toggleAuthModal('checkout');
        }
    }

    let discountList;

    // Check if discounts are null or not yet fetched
    if(discounts.length > 0) {

        // Iterate through the discounts array and render each discount component
        discountList = discounts.map(discount => {

                return (
                    <CouponBlock discount={discount} />
                )

        })
    }

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Cart Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (

        <Fragment>

            {/* Render the CartLayout component as the layout wrapper for the checkout page */}
            <CartLayout page="cart" handleScroll={handleScroll}>
                {!isOpen && detailZone ? (
                    <Fragment>
                        <div style={{padding: '24px 15px 0'}}>
                            <Delivery_Widget />
                        </div>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                            <img style={{width: '100%', maxWidth: '300px', objectFit: 'contain'}} src={closed_sign} alt="store closed sign" />
                        </div>

                        <div className='no-rides'></div>
                    </Fragment>  
                ) : (
                    <Fragment>
                        {show ? (
                            <Fragment>
                                {show === 'add_address' ? (
                                    <ChangeAddress isCart />
                                ) : (
                                    <ChangePhone />
                                )}
                            </Fragment>
                        ) : (
                            <div style={{padding: '24px 15px 0'}}>

                                {cart.items?.length > 0 && (
                                    <Fragment>

                                        <Delivery_Widget />

                                        <div style={{marginBottom:'12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <div style={{minHeight:'40px', alignItems:'center', display:'flex'}}>
                                                <div style={{display:'flex', alignItems:'center',}}>
                                                    <div style={{maxWidth:'80%'}}>
                                                        <div style={{lineHeight: '23.8px', fontSize:'18px', fontWeight:'bold'}}>
                                                            Subtotal:
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{lineHeight: '23.8px', fontSize:'18px', fontWeight:'bold', marginLeft: '5px', color: '#AE0000'}}>
                                                    <CurrencyFormat value={cart.subtotal} />
                                                </div>
                                                <div style={{lineHeight: '23.8px', fontSize:'14px', marginLeft: '5px', color:'rgb(92, 92, 92)'}}>
                                                    ({cart.qty} items)
                                                </div>
                                            </div>

                                            <div onClick={toggleClearCartModal} style={{background: '#fff', padding: '4px 10px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                                <div style={{color: '#ff4b2b', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '14px', overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
                                                    <DeleteForeverOutlinedIcon />
                                                    Clear
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}

                                <div>
                                    <div style={{marginBottom:'12px', display:'flex', flexDirection:'column'}}>
                                        {cart.items?.length > 0 && (
                                            <Fragment>
                                                <div onClick={() => window.location = '/add-code'} className='cart_section_btn'>
                                                    <div style={{minWidth:'100%', minHeight:'48px', alignItems:'center', display:'flex', padding:'8px 0', boxSizing:'border-box'}}>
                                                        <div style={{marginRight:'12px', height:'40px', alignItems:'center', justifyContent:'center', display:'flex', width:'40px', padding:'6px', borderRadius:'40px'}}>
                                                            <LocalOfferOutlinedIcon />
                                                        </div>
                                                        <div style={{flexGrow: 1, fontWeight:'bold', lineHeight:'23.8px', fontSize:'18px', display: 'flex', flexDirection: 'column'}}>
                                                            {!discounts.length > 0 ? (
                                                                <span>Add promo code</span>
                                                            ) : (
                                                                <span>Promo applied</span>
                                                            )}
                                                            
                                                            {/* <span style={{fontWeight: 400, marginTop: '5px', fontSize: '14px', color: 'rgb(220, 35, 66)', lineHeight: '18.5px'}}>You need to have $25.09 in your bag in order for code 50GBNFL to apply</span> */}
                                                        </div>
                                                        <div style={{display:'flex', alignItems:'center'}}>
                                                            <div style={{fontSize:'32px', marginLeft:'32px'}}>
                                                                <KeyboardArrowRightIcon />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {discountList}
                                            </Fragment>
                                        )}

                                        <div style={{paddingBottom:'16px', paddingLeft:'16px', paddingRight:'16px'}}>
                                            {/* Cart Item */}
                                            <Cart_List />
                                            {/* END: Cart Item */}
                                        </div>

                                        {cart.items?.length > 0 && (
                                            <Fragment>
                                                {detailZone?.min_order_amount > cart.subtotal && (
                                                    <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                                                        A small order fee of $4.95 will be applied for orders below $12.95. Add more items to your basket to remove this fee.
                                                    </div>
                                                )}


                                                {discounts.length > 0 && (
                                                    <div style={{background:'rgb(0, 100, 207)', width:'100%', borderRadius:'16px', padding:'16px', fontSize:'24px', lineHeight:'31.4px', color:'#fff', boxSizing: 'border-box', fontWeight:'bold'}}>
                                                        You're saving ${parseFloat((cart.subtotal - cart.discountPrice).toFixed(2))} on this order with discounts.
                                                    </div>
                                                )}
                                            </Fragment>
                                        )}
                                    </div>

                                    {cart.items?.length > 0 ? (
                                        <Fragment>
                                            <div style={{marginBottom:'12px'}}>
                                                <div style={{marginBottom: '16px', padding: '0 16px'}}>
                                                    {discounts.length > 0 && <Discount_List />}

                                                </div>
                                                <div style={{minHeight:'40px', alignItems:'center', display:'flex', justifyContent: 'space-between'}}>
                                                    <div style={{flex: 1}}>
                                                        <div style={{maxWidth:'80%'}}>
                                                            <div style={{lineHeight: '23.8px', fontSize:'18px', fontWeight:'bold'}}>
                                                                Subtotal:

                                                                {!discounts.length > 0 && (
                                                                    <span style={{lineHeight: '23.8px', fontWeight: 400, fontSize:'14px', marginLeft: '5px', color:'rgb(92, 92, 92)'}}>
                                                                        ({cart.qty} items)
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {!discounts.length ? (
                                                        <div style={{lineHeight: '23.8px', fontSize:'18px', fontWeight:'bold', marginLeft: '5px', color: '#AE0000'}}>
                                                            <CurrencyFormat value={cart.subtotal} />
                                                        </div>
                                                    ) : (
                                                        <div style={{lineHeight: '23.8px', fontSize:'18px', fontWeight:'bold', marginLeft: '5px', color: '#AE0000'}}>
                                                            <CurrencyFormat value={cart.discountPrice} />
                                                        </div>
                                                    )}

                                                </div>
                                            </div>

                                            <div onClick={() => window.location = '/settings/privacy'} style={{marginBottom: '16px', width:'100%', borderTop: '5px solid #e6ecf0', paddingTop: '10px', cursor:'pointer'}}>
                                                <div style={{minWidth:'100%', height:'70px', alignItems:'center', display:'flex', boxSizing:'border-box'}}>
                                                    <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                                                        <div className='secure_badge_container'>
                                                            <div style={{marginBottom: '2px'}}>
                                                                <VerifiedUserIcon />
                                                            </div>
                                                            
                                                            Safe Payment Options
                                                        </div>
                                                        <div className='secure_badge_container'>
                                                            <div style={{marginBottom: '2px'}}>
                                                                <LockIcon />
                                                            </div>
                                                            Secure Privacy
                                                        </div>
                                                    </div>
                                                    
                                                    <div style={{display:'flex', alignItems:'center'}}>
                                                        <div style={{fontSize:'32px', marginLeft:'32px'}}>
                                                            <KeyboardArrowRightIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {(!isMobile) && (
                                                <Fragment>
                                                    {cartHasItems ? (
                                                        <div style={{width: '100%', marginTop: '24px', marginBottom: '16px', minWidth: '300px'}}>
                                                            <div onClick={handle_go_to_checkout} style={{backgroundColor: '#000', borderColor: 'rgb(0, 164, 255)', minWidth: '88px', minHeight: '48px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                                                                <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                                                                    Checkout
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div style={{width: '100%', marginTop: '24px', marginBottom: '8px', minWidth: '300px'}}>
                                                            <div onClick={() => window.location = '/marketplace/search'} style={{backgroundColor: 'rgb(0, 164, 255)', borderColor: 'rgb(0, 164, 255)', minWidth: '88px', minHeight: '48px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                                                                <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                                                                    Find What You Need !
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )}   

                                            <div className='cart_safety_msg'>
                                        
                                                <LockIcon />
                                                <span>
                                                    You will not be charged until you review this order on the next page
                                                </span>
                                            </div>

                                            <div className='cart_safety_msg'>
                                        
                                                
                                                <VerifiedUserIcon />
                                                <span style={{fontWeight: 'bold'}}>
                                                    Safe Payment Options: 
                                                    <span style={{color: '#0a8800', marginLeft: '3px', fontWeight: 500}}>Miitoken is committed to protecting your payment information.</span>
                                                </span>
                                            </div>

                                        </Fragment>
                                    ) : (
                                        <div style={{width: '100%', marginTop: '24px', minWidth: '300px'}}>
                                            {(!isMobile) && (
                                                <Button onClick={() => window.location = '/marketplace'} variant="outlined" className="sidebar__tweet" fullWidth>
                                                    Find What You Need !
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div style={{height: '100px'}}></div>
                            </div>
                        )}
                    </Fragment>
                )}
            </CartLayout>

            {/* Clear Cart Modal */}
            <ModalContainer show={clearCartModal} onClose={toggleClearCartModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Clear Bag</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Are you sure?</p>

                    {/* Modal Actions */}
                    <div className='btn_row'>

                        <Button 
                            onClick={toggleClearCartModal}
                            variant="outlined" 
                            className="sidebar__tweet ghost blackText"
                            fullWidth
                        >
                            No
                        </Button>

                        {/* Put space between buttons */}
                        <div style={{width: '30px'}}></div>

                        <Button 
                            onClick={handleClearCart}
                            variant="outlined" 
                            className="sidebar__tweet danger"
                            fullWidth
                        >
                            Yes, Clear
                        </Button>
                    </div>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

CartPage.propTypes = {
    auth: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    locationState: PropTypes.object.isRequired,
    zone: PropTypes.object.isRequired,
    toggleLocationModal: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    clearCart: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    cart: state.cart,
    locationState: state.location,
    discount: state.discount,
    zone: state.zone
})

export default connect(mapStateToProps, { 
    toggleAuthModal,
    toggleLocationModal,
    setPage, 
    add_To_Recent_History,
    clearCart
})(CartPage);