import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types'
import { format, formatRelative, subDays, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleAuthModal, toggleProfileModal, toggle_StartDelivery_Modal } from '../../../actions/navActions';
import { addDriverToOrder } from '../../../actions/orderActions';

// Icons - material UI
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';
// import CheckIcon from '@mui/icons-material/Check';
// import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Modal
import ModalContainer from '../../../components/modal/ModalContainer';
import Modal from '../../../components/modal/Modal';

// Components - imported
import { Button } from '@material-ui/core';
import Spinner from '../../../components/common/Spinner';
import OrderItem from './OrderItem';
import HowToDeliver from '../../common/HowToDeliver';

const OfferDetail = ({
    auth: {
        isAuthenticated,
        user
    },
    nav: {
        driver_startDeliveryModal,
        campus_name
    },
    detailOrder,
    toggleAuthModal, 
    toggleProfileModal,
    toggle_StartDelivery_Modal,
    addDriverToOrder
}) => {

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Orders List
    const [cartList, setCartList] = useState([]);
    const [gotCartList, setGotCartList] = useState(false);

    // - Timer ----

    const [timer, setTimer] = useState('00:00:00');

    const getTimeRemaining = (e) => {

        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);

        return {
            total, hours, minutes, seconds
        };
    }
 
    const startTimer = (e) => {
        
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
                    
        if (total >= 0) {
 
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable

            if(hours == 0) {
                setTimer(
                    (minutes > 9 ? minutes : '0' + minutes) + ':'
                    + (seconds > 9 ? seconds : '0' + seconds)
                )
            } else {
                setTimer(
                    (hours > 9 ? hours : '0' + hours) + ':' +
                    (minutes > 9 ? minutes : '0' + minutes) + ':'
                    + (seconds > 9 ? seconds : '0' + seconds)
                )
            }
        }
    }
 
    const clearTimer = (e) => {
 
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next   
        setTimer('00:00');
 
        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
 
    const getDeadTime = (remainingSeconds) => {
        let deadline = new Date();
 
        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(remainingSeconds);
        return deadline;
    }

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
 
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {

        function formatTimestamp(timestamp) {
            const seconds = timestamp.seconds;
            const nanoseconds = timestamp.nanoseconds;
          
            const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1e6); // Convert nanoseconds to milliseconds
            const isoDate = new Date(milliseconds).toISOString();
          
            return isoDate;
        }


        if(detailOrder?.est_complete_time_ISO) {
            
            const isoFormattedDate = formatTimestamp(detailOrder.est_complete_time_ISO);

            const targetDateTime = isoFormattedDate; // Replace with your desired target date and time in ISO format
            const remainingSeconds = secondsUntilDateTime(targetDateTime);

            console.log('SETTING TIMER');
            console.log(detailOrder.est_complete_time_ISO)
            console.log(detailOrder.est_complete_time_ISO.seconds)
            console.log(isoFormattedDate)

            clearTimer(getDeadTime(remainingSeconds));
        }
    }, []);

    const secondsUntilDateTime = (targetDateTime) => {
        const currentDate = new Date();
        const targetDate = new Date(targetDateTime);
        
        if (targetDate <= currentDate) {
            return 0; // The target date and time has already passed
        }
        
        const timeDifference = targetDate - currentDate;
        const secondsRemaining = timeDifference / 1000; // Convert milliseconds to seconds
        
        return Math.floor(secondsRemaining);
    }

    // - END: Timer ----

    const {
        cart: {
            items,
            total,
            subtotal,
            tax,
            qty,
            tip
        }
    } = detailOrder

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    useEffect(() => {

        renderCartList();
    }, []);

    const renderCartList = () => {
        setCartList([]);

        if(items === null) {
            setCartList([(
                <div className="no-rides">
                    <h1>Empty</h1>
                </div>
            )])
        }
        else { 
            if(items.length > 0) {

                items.map(order_item => {
                    setCartList(cartList => [...cartList, (
                        <OrderItem
                            orderItem={order_item}
                        />
                    )])
                });

                setGotCartList(true);
            } else {
                setCartList([(
                    <div className="no-rides">
                        <h1>Empty</h1>
                    </div>
                )])
            }
        } 
    }

    // Toggle 'Start delivery' Modal
    const toggleStartDeliveryModal = () => {
        toggle_StartDelivery_Modal();
    }

    // Logic for handling the Start Delivery action
    const handleStartDelivery = () => {
        if(detailOrder.status === 'waiting') {
            if(driver_startDeliveryModal && user) {
                toggle_StartDelivery_Modal();
    
                // Redirect to delivery page
                // window.location = `/delivery/${detailOrder._id}`;
                addDriverToOrder(campus_name, detailOrder._id, user, detailOrder)
            } else {
                if(isAuthenticated) {
                    if(user?.profile_set_up) {
                        if(user.stripe_onboarding_complete) {
                            if(user?.phone?.length > 0) {
                                toggleStartDeliveryModal();
                            } else {
                                toggleAuthModal('driver phone')
                            }  
                        } else {
                            toggleAuthModal('driver payments')
                        }
                    } else {
                        toggleAuthModal('driver profile')
                    }
                } else {
                    toggleAuthModal('driver register')
                }
            }
        }
    }

    return (
        <Fragment>
            <div className="orderPage">

                <div className="orderPage__body">
                    {/* Banner Image */}

                    {/* <div style={{width: '100%', flex: 1, display: 'flex', justifyContent: 'center'}}>
                        <div style={{height: '171.818px', maxWidth: '672px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: '8px', overflow: 'hidden', border: '1px solid #e6ecf0', position: 'relative'}}>
                            <h2 style={{fontWeight: '400', marginBottom: '5px'}}>Your order arrived in:</h2>
                            <h1 style={{color: 'rgb(29, 155, 240)', fontSize: '40px', marginRight: '-40px'}}>{timer} <span style={{fontWeight: '400', fontSize: '24px'}}>mins</span></h1>
                        </div>
                    </div> */}

                    {detailOrder.status === 'waiting' && (
                        <Fragment>
                            <div style={{ paddingBottom: '16px', textAlign: 'center', width: '100%', borderBottom: '1px solid #e6ecf0'}}>
                                <h1>Get Paid <span style={{color: 'rgb(29, 155, 240)'}}>${total}</span></h1>
                            </div>

                            <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                                <h3>Deliver {qty} item(s)</h3>
                                {/* {detailOrder?.est_complete_time && <span style={{marginTop: '3px'}}>Complete Delivery By: {detailOrder.est_complete_time} </span>} */}
                                {/* <span style={{marginTop: '3px'}}>Complete by: {detailOrder.est_complete_time}</span> */}
                            </div>

                            <div style={{width: '100%', flex: 1, display: 'flex', justifyContent: 'center'}}>
                                <div style={{height: '171.818px', maxWidth: '672px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: '8px', overflow: 'hidden', border: '1px solid #e6ecf0', position: 'relative'}}>
                                    <h2 style={{fontWeight: '400', marginBottom: '5px'}}>This offer expires in:</h2>
                                    <h1 style={{color: '#AE0000', fontSize: '40px', marginRight: '-40px'}}>{timer} <span style={{fontWeight: '400', fontSize: '24px'}}>mins</span></h1>
                                </div>
                            </div>

                            <div className="orderPage__section payment">

                                <div>
                                    <h3>
                                        Pick up these items:
                                    </h3>

                                </div>
                                {/* Spacing element */}
                                <div style={{height: '50px'}}>
                                    
                                </div>
                            </div>
                        </Fragment>
                    )}

                    {detailOrder.status === 'delivered' && (
                        <Fragment>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '16px', textAlign: 'center', width: '100%', borderBottom: '1px solid #e6ecf0'}}>
                                <h1>Delivered </h1>
                                <span className='offer_header_icon' style={{color: '#4BB543', marginLeft: '5px'}}><DoneAllOutlinedIcon /></span>
                            </div>

                            <div style={{marginBottom: '16px', background:'#90EE90', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                                <h3>This order was delivered </h3>
                                {/* {detailOrder?.est_complete_time && <span style={{marginTop: '3px'}}>Complete Delivery By: {detailOrder.est_complete_time} </span>} */}
                                {/* <span style={{marginTop: '3px'}}>Complete by: {detailOrder.est_complete_time}</span> */}
                            </div>

                        </Fragment>
                    )}

                    {(detailOrder.status === 'cancelled' || detailOrder.status === 'customer cancelled' || detailOrder.status === 'driver cancelled') && (
                        <Fragment>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '16px', textAlign: 'center', width: '100%', borderBottom: '1px solid #e6ecf0'}}>
                                <h1>Order Cancelled </h1>
                                <span className='offer_header_icon' style={{color: 'rgb(249, 24, 128)', marginLeft: '5px'}}><SentimentDissatisfiedOutlinedIcon /></span>
                            </div>

                            {detailOrder.status === 'driver cancelled' ? (
                                <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                                    <h3>Driver flaked</h3>
                                </div>
                            ) : (
                                <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                                    <h3>Customer cancelled</h3>
                                </div>
                            )}

                        </Fragment>
                    )}
    

                    {detailOrder.status === 'expired' && (
                        <Fragment>
                            <div style={{ paddingBottom: '16px', textAlign: 'center', width: '100%', borderBottom: '1px solid #e6ecf0'}}>
                                <h1>You Missed: <span style={{color: '#AE0000'}}>${total}</span></h1>
                            </div>

                            <div style={{marginBottom: '16px', background:'#ffcccb', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                                <h3>This offer expired</h3>
                                {/* {detailOrder?.est_complete_time && <span style={{marginTop: '3px'}}>Complete Delivery By: {detailOrder.est_complete_time} </span>} */}
                                {/* <span style={{marginTop: '3px'}}>Complete by: {detailOrder.est_complete_time}</span> */}
                            </div>
                        </Fragment>
                    )}

                    {(detailOrder.status === 'collecting' || detailOrder.status === 'in transit' || detailOrder.status === 'arrived') && (
                        <Fragment>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '16px', textAlign: 'center', width: '100%', borderBottom: '1px solid #e6ecf0'}}>
                                <h1>In Transit</h1>
                                <span className='offer_header_icon' style={{color: 'rgb(29, 155, 240)', marginLeft: '5px'}}><AutorenewOutlinedIcon /></span>
                            </div>

                            <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                                <h3>LIVE - This order is in transit</h3>
                                {/* {detailOrder?.est_complete_time && <span style={{marginTop: '3px'}}>Complete Delivery By: {detailOrder.est_complete_time} </span>} */}
                                {/* <span style={{marginTop: '3px'}}>Complete by: {detailOrder.est_complete_time}</span> */}
                            </div>
                        </Fragment>
                    )}

                    <div className="orderPage__items">
                        <h3>Items ({qty})</h3>

                        {!cartList.length > 0 || !gotCartList ? <Spinner /> : cartList}
                    </div>

                    {!isMobile && (
                        <Button 
                            variant="outlined" 
                            className={`sidebar__tweet ${detailOrder.status === "waiting" ? 'black' : 'disabled'}`} 
                            fullWidth
                            onClick={handleStartDelivery}
                        >
                            Do This Delivery!
                        </Button>
                    )}

                    <div style={{marginTop: '20px'}}>
                        <hr/>
                        <HowToDeliver page="offer" />
                    </div>

                    <div style={{height: '100px'}}></div>

                    {/* <h3>Order Summary</h3>
                    <div className="orderPage__section totals">
                        <div>
                            <p>Subtotal:</p>
                            <p>Shipping & Handling:</p>
                            <p>Estimated Tax:</p>
                            <p>Completed Total:</p>
                        </div>
                        <div>
                            <p>$84.99</p>
                            <p>$0.00</p>
                            <p>$3.98</p>
                            <p>$84.99</p>
                        </div>
                    </div> */}
                </div>

            </div>

        {/* Start delivery modal */}
        <ModalContainer show={driver_startDeliveryModal} onClose={toggleStartDeliveryModal}>
            <Modal>

                {/* Title */}
                <div>
                    <h2>Do This Delivery!</h2>
                </div>

                {/* Description */}
                <p>Get paid <b style={{color: 'rgb(29, 155, 240)'}}>$3.25</b></p>
                
                {/* Actions */}
                <Button 
                    onClick={handleStartDelivery}
                    variant="outlined" 
                    className="sidebar__tweet black"
                    fullWidth
                >
                    Let's Go!
                </Button>

                <Button 
                    onClick={toggleStartDeliveryModal}
                    variant="outlined" 
                    className="sidebar__tweet ghost blackText"
                    fullWidth
                >
                    Never Mind
                </Button>
            </Modal>
        </ModalContainer>
        </Fragment>
    )
}

OfferDetail.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    toggleProfileModal: PropTypes.func.isRequired,
    toggle_StartDelivery_Modal: PropTypes.func.isRequired,
    addDriverToOrder: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 
  // Connecting actions from redux to the component
  toggleAuthModal, 
  toggleProfileModal,
  toggle_StartDelivery_Modal,
  addDriverToOrder

})(OfferDetail);
