import { setAlert } from './alertActions';

// Firebase
import { db, auth, storage } from '../utils/firebase-config';
import { collection, FieldValue, query, where, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';

import {
  GET_LOCATIONS,
  LOCATION_ERROR,
  DELETE_LOCATION,
  ADD_LOCATION,
  EDIT_LOCATION,
  GET_LOCATION,
  CLEAR_LOCATIONS,
  SET_DELIVERY_TIME,
  SET_IS_MARKETPLACE,
  SET_LOCATION_ACTIVE
} from './types';

// Reference to the "users" collection in Firestore
const usersCollectionRef = collection(db, "users");

function generateRandomID(number_of_digits) {

    let randomDigits = '';

    for (let i = 0; i < number_of_digits; i++) {

      const randomDigit = Math.floor(Math.random() * 10); // Generate a random digit (0-9)
      randomDigits += randomDigit.toString();
    }

    return randomDigits;
  }

// Set Locations in Locations array
export const getLocations = () => async dispatch => {

    try {

        let locationList = null;
        
        if(auth.currentUser) {
            const userRef = doc(db, 'users', auth.currentUser?.uid)

            // Retrieve the document with the specified id from the 'users' collection
            const userDoc = await getDoc(userRef);

            console.log('GOT USER BY ID');
            console.log(userDoc.data())

            if(userDoc.data().addresses) {
                locationList = userDoc.data().addresses;
            } else {
                locationList = [];
            }

            dispatch({
                type: GET_LOCATIONS,
                payload: locationList
            });
        } else {
            locationList = [];

            dispatch({
                type: GET_LOCATIONS,
                payload: locationList
            });
        }

    } catch (err) {
        console.log('ERROR!!!')
        console.log(err);

        dispatch({
            type: GET_LOCATIONS,
            payload: []
        });
    }
}

// Set Location to detailLocation
export const getLocation = () => async dispatch => {

    try {
        let detailLocation = null; 
    
        if(localStorage.location_bs_1) {

            detailLocation = JSON.parse(localStorage.location_bs_1)
        } else {

            if(auth?.currentUser) {

                const userRef = doc(db, 'users', auth.currentUser?.uid)

                // Retrieve the document with the specified id from the 'users' collection
                const userDoc = await getDoc(userRef);

                console.log('GOT USER BY ID');
                console.log(userDoc.data())
                
                const locationList = userDoc.data().addresses;

                locationList?.map(address => {
                    
                    if(address.active) {

                        detailLocation = address;

                        // Store detailLocation in local storage
                        localStorage.setItem("location_bs_1", JSON.stringify(detailLocation));
                    }
                })   
            }
        }

        console.log('GOT LOCATION:');
        console.log(detailLocation);

        dispatch({
            type: GET_LOCATION,
            payload: detailLocation
        });

    } catch (err) {

        console.log('ERROR!!!');
        console.log(err);
    }
}

// Add Location
export const addLocation = (formData, currentUserLocations, user_obj) => async dispatch => {
    console.log('ADDING LOCATION');

    if(!currentUserLocations) {
        currentUserLocations = []
    }
    try {

        formData._id = generateRandomID(10); // Generate random uid 10 digits long
        formData.active = true;

        if(auth?.currentUser) {
            
            console.log('FETCHING CURRENT USER LOCATIONS')
            console.log(currentUserLocations);

            currentUserLocations.map(address => {
                if(address.active) {
                    address.active = false;
                }
            })

            console.log('UPDATING USER')

            // Create a reference to the specified user document in the "users" collection
            const userRef = doc(db, 'users', auth.currentUser.uid)
            
            // Update the user's phone number
            await updateDoc(userRef, {
                addresses: [formData, ...currentUserLocations]
            })

            console.log('SUCCESS ADDING LOCATION')
        }

        localStorage.setItem("location_bs_1", JSON.stringify(formData));

        dispatch({
            type: ADD_LOCATION,
            payload: formData
        });

        dispatch(setAlert('New address added!', 'okay'));

        if(user_obj) {

            // Check if user has added there phone number
            if(user_obj?.phone?.length > 0) {

                // if yes, contiinue to checkout
                window.location = '/checkout'
            } else {

                // if not redirect to add_phone page
                window.location = '/cart?show=add_phone'
            }
        }

    } catch (err) {
        
        console.log('ERROR!!!')
        console.log(err);
    }
};

// Set Location as active 
export const setLocationActive = (locationID, currentUserLocations) => async dispatch => {

    console.log('SET LOCATION ACTIVE');

    if(currentUserLocations?.length > 0) {
        try {

            if(auth?.currentUser) {
                
                console.log('FETCHING CURRENT USER LOCATIONS')
                console.log(currentUserLocations);
    
                currentUserLocations.map(address => {
                    if(address?._id === locationID) {
                        address.active = true;
                    } else {
                        address.active = false;
                    }
                })
    
                console.log('UPDATING USER')
    
                // Create a reference to the specified user document in the "users" collection
                const userRef = doc(db, 'users', auth.currentUser.uid)
                
                // Update the user's phone number
                await updateDoc(userRef, {
                    addresses: [...currentUserLocations]
                })
    
                console.log('SUCCESS UPDATING LOCATIONS')
            }
    
            dispatch({
                type: SET_LOCATION_ACTIVE,
                payload: currentUserLocations
            });
    
            dispatch(setAlert('New address active!', 'okay'));
    
        } catch (err) {
            
            console.log('ERROR!!!')
            console.log(err);
        }
    } else {
        console.log('ERROR!')
        console.log('No locations provided')
    }

}

export const setDeliveryTime = (distanceMatrixTime) => dispatch => {

    // -- Extract the numeric part of the distance Matrix string (i.e. "26 mins")

    // Use regular expressions to extract hours and minutes
    const timeComponents = distanceMatrixTime.match(/(\d+) hours (\d+) mins/);

    if (timeComponents?.length >= 2) {
        const hours = parseInt(timeComponents[1]);
        const mins = parseInt(timeComponents[2]);
        const totalMinutes = (hours * 60) + mins;

        console.log('PARSED DISTANCE TIME (in minutes):', totalMinutes)

        dispatch({ 
            type: SET_DELIVERY_TIME,
            payload: totalMinutes 
        });
    
    } else {

        const totalMinutes = parseInt(distanceMatrixTime); 

        dispatch({ 
            type: SET_DELIVERY_TIME,
            payload: totalMinutes 
        });
    }
}

// Edit Location
export const editLocation = (formData, locationId, storeId, history) => async dispatch => {
//   try {
//       const config = {
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         };
//         console.log('FRONTEND FORM DATA');
//         console.log(formData);
//     const res = await axios.post(`/api/darkstores/edit/${locationId}/${storeId}`, formData, config);

//     dispatch({
//       type: EDIT_LOCATION,
//       payload: res.data
//     });

//     history.push(`/admin/location/${storeId}/${res.data._id}?show=detail`);

//     dispatch(setAlert('Location Updated', 'success'));
//   } catch (err) {
//     dispatch({
//       type: LOCATION_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
};


// Delete project
export const deleteLocation = (locations, locationID) => async dispatch => {
    try {

        let locationList = null;
        
        if(auth.currentUser) {
            const userRef = doc(db, 'users', auth.currentUser?.uid)

            // Retrieve the document with the specified id from the 'users' collection
            const userDoc = await getDoc(userRef);

            console.log('GOT USER BY ID');
            console.log(userDoc.data())

            if(userDoc.data().addresses) {
                locationList = userDoc.data().addresses;
            } else {
                locationList = [];
            }

            locationList.map((address, index) => {
                if (address._id === locationID) {
                    locationList.splice(index, 1); // Remove the object at the index
                }
            })

            // Update the user's phone number
            await updateDoc(userRef, {
                addresses: [...locationList]
            })

            console.log('SUCCESS ADDING LOCATION')

        } else {
            locationList = locations;

            locationList.map((address, index) => {
                if (address._id === locationID) {
                    locationList.splice(index, 1); // Remove the object at the index
                }
            })
        }

        dispatch({
            type: GET_LOCATIONS,
            payload: locationList
        });

    } catch (err) {
        console.log('ERROR!!!')
        console.log(err);

        dispatch({
            type: GET_LOCATIONS,
            payload: []
        });
    }
};

export const clearLocations = () => dispatch => {

    dispatch({ 
        type: CLEAR_LOCATIONS 
    });
}

// Get Product Locations
export const getProductLocations = (productID) => async dispatch => {

    try {

        let locationList = [];
        
        const productRef = doc(db, 'products', productID);
        const productDoc = await getDoc(productRef);

        if (productDoc.exists()) {
            const data = productDoc.data();
            if (data.locations) {
                locationList = data.locations;
            }
        }

        let product_location_data = [];

        for (const location of locationList) {
            const locationDoc = await getDoc(doc(db, 'locations', location.location_id, 'items', productID));
            if (locationDoc.exists()) {
                product_location_data.push({...locationDoc.data(), _id: location.location_id});
            } else {
                console.log('No such document for product', productID, 'at location', location.location_id);
            }
        }

        console.log('ITEM LOCATION DATA')
        console.log(product_location_data)

        dispatch({
            type: GET_LOCATIONS,
            payload: product_location_data
        });


    } catch (err) {
        console.log('ERROR!!!')
        console.log(err);

        dispatch({
            type: GET_LOCATIONS,
            payload: []
        });
    }
}