import React, { useState } from 'react';
import moment from 'moment';

import { Avatar } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DefaultAvatar from 'react-avatar';

// Firebase
import { db } from '../../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const Notification = ({ notification, Icon, headerText }) => {

    // --- Convert DraftJS text to HTML text

    // Flag indicating if the text has been processed
    const [gotText, setGotText] = useState(false);

    // State to store the converted text
    const [textState, setTextState] = useState(null);

    // Get parent post for like page purposes and displaying the 'replying to'
    const [gotParentPost, setGotParentPost] = useState(false);
    const [replyToPost, setReplyToPost] = useState(null);

    // --- Determine the CSS class for the notification icon based on the notification type

    let iconClass;
    if(notification.type === 'like') {
        iconClass = "notification__icon like";
    } else if(notification.type === 'message' || notification.type === 'message like') {
        iconClass = "notification__icon mail";
    } else if(notification.type === 'comment interaction' || notification.type === 'post interaction') {
        iconClass = "notification__icon replyToComment";
    } else {
        iconClass = "notification__icon";
    }

    // Check if the notification exists and the text has not been processed yet
    if (notification && !gotText) {
        console.log('TEXT BLOCK')

        // --- Convert the raw DraftJS content to EditorState
        let unRawState;

        if(notification.message) {

            if((notification.message.includes('{"blocks":[{')) ) {
                unRawState = EditorState.createWithContent(
                    convertFromRaw(JSON.parse(notification.message))
                );
            
                console.log(unRawState)
    
                console.log(draftToHtml(convertToRaw(unRawState.getCurrentContent())))
                
                // Convert the EditorState to HTML text and store it in a variable
                const unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));
    
                setTextState(unRawDescription);
            } else {
                
                setTextState(notification.message);
            }
        }

        
        // let editorState = post.text
        // const rawContentState = editorState.getCurrentContent();
 
        // const markup = draftToHtml(
        //     rawContentState
        // );

        // console.log(markup)

        // Set gotText to true to prevent redundant conversions
        setGotText(true)
    }

    // Function to handle redirecting the user to a specific post when clicked
    const handleRedirect = () => {
        if(notification.postId && notification.postId !== '') {
            window.location.href = `/post/${notification.postId}`;
        }

        if(notification.chatId && notification.chatId !== '') {
            window.location.href = `/messages/${notification.chatId}`;
        }
    }

    const getParentPost = async () => {
        const docRef = doc(db, 'posts', notification.postId);
        const postData = await getDoc(docRef);

        if(postData && postData.data()?.user) {
            setReplyToPost({ ...postData.data(), _id: postData.id });
        }
    }

    if(!gotParentPost && (notification.type === 'comment interaction' || notification.type === 'post interaction')) {
        getParentPost();

        setGotParentPost(true);
    }
    

    return (
        <div onClick={handleRedirect} className={`notification ${!notification.read && 'active'}`}>
            <div className={iconClass}>
                <Icon />
            </div>
            <div className="notification__body">
                <div className="notification__header">

                    {/* Display the relative time of the notification */}
                    <p className="notification__special">{moment(notification.date).startOf('hour').fromNow()}</p>
                    
                    {/* Avatar of the notification sender */}
                    {notification.from_avatar ? (
                        <Avatar src={notification.from_avatar} />
                    ) : (
                        <DefaultAvatar name={notification.from_username && notification.from_username} />
                    )}
                    
                    {/* Display the header text */}
                    <div className="notification__headerText">
                        <h3>

                            {/* Display the sender's username */}
                            {notification.from_username && notification.from_username} {' '}
                            <span className="notification__headerSpecial">

                                {/* Display a verified badge icon */}
                                <VerifiedUserIcon className="notification__badge active"></VerifiedUserIcon>
                            </span>
                        </h3>

                        {/* Display the header text */}
                        <p>{headerText}</p>
                    </div>

                    {replyToPost && (
                        <div className='replying_to_title'>
                            Replying to {' '}

                            <a href={`/${replyToPost.user.username}`}>
                                {replyToPost.user.first_name} {replyToPost.user.last_name && ' ' + replyToPost.user.last_name}
                            </a>
                        </div>
                    )}

                    {/* Display the Converted HTML text */}
                    <div className="notification__headerDescription">
                        {/* <p>{notification.message && notification.message}</p> */}

                        {textState !== null && (<div dangerouslySetInnerHTML={{__html: textState}} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification;
