
// Firebase
import { db, auth, storage } from "../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

import { setAlert } from './alertActions';
import { goBack, isOver24HoursAgo } from './navActions';
import { getProductsInCollection } from './productActions';

import {
    GET_ZONES,
    SET_ZONES,
    ZONE_ERROR,
    DELETE_ZONE,
    ADD_ZONE,
    GET_ZONE,
    EDIT_ZONE,
    ZONES_LOADING,
    CLEAR_ZONES,
    SET_ZONE_CLOSED,
    SET_ZONE_OPEN,
    SET_STORE_HOURS,
    CHECKED_IF_ZONE_OPEN
} from './types';

// Reference to the "collections" collection in Firestore
const zoneCollectionRef = collection(db, "zones");

// Get Collections
export const getZones = () => async dispatch => {
  
  console.log('ENTER GET ZONES');

  try {

    console.log('GETTING ZONES')

    // Construct a new query starting at the last visible document and fetch the next 3 posts.
    const getQuery = query(zoneCollectionRef, orderBy('lastModified', 'desc'), limit(15));

    // Retrieve all documents from the "categories" collection
    const data = await getDocs(getQuery);

    // Map the document data to an array of categories with the document ID included
    const zoneList = data.docs.map((doc) => ({...doc.data(), _id: doc.id}));

    console.log('SHOW CATEGORY LIST')
    console.log(zoneList);

    // Dispatch an action of type GET_CATEGORIES with the zoneList as the payload
    dispatch({
        type: GET_ZONES,
        payload: zoneList
    });

  } catch (err) {
    
    console.log('ERROR!!!')
    console.log(err);

  }
};

// Get Currently Active Delivery Zone
export const getDeliveryZone = () => async dispatch => {

    try {
        let active_delivery_zoneID = null; 
        let locally_stored_zone_obj = null;
    
        if(localStorage.delivery_zone_bs_1) {

            console.log('GETTING DELIVERY ZONE FROM LOCAL STORAGE')

            locally_stored_zone_obj = JSON.parse(localStorage.delivery_zone_bs_1)

            if(locally_stored_zone_obj?.createdAt && !isOver24HoursAgo(locally_stored_zone_obj.createdAt)) {
                active_delivery_zoneID = locally_stored_zone_obj.zone_id;
            } else {
                console.log('The createdAt field of ZONE is over 24 hours in the past. DELETE');
                localStorage.removeItem("delivery_zone_bs_1");
            }

        } else {

            if(auth?.currentUser) {

                console.log('GETTING DELIVERY ZONE FROM USER DOC')

                const userRef = doc(db, 'users', auth.currentUser?.uid)

                // Retrieve the document with the specified id from the 'users' collection
                const userDoc = await getDoc(userRef);

                console.log('GOT USER BY ID');
                console.log(userDoc.data())

                if(userDoc.data().active_delivery_zoneID) {

                    active_delivery_zoneID = userDoc.data().active_delivery_zoneID;
                    
                    const new_zone_obj = {
                        zone_id: active_delivery_zoneID,
                        createdAt: Date.now()
                    };

                    // Store detailZone in local storage
                    localStorage.setItem("delivery_zone_bs_1", JSON.stringify(new_zone_obj));
                }
            }
        }

        let detailZone = null;
    
        if(active_delivery_zoneID) {
            const zoneRef = doc(db, 'zones', active_delivery_zoneID);

            const zoneDoc = await getDoc(zoneRef);

            detailZone = {
                _id: zoneDoc.id,
                ...zoneDoc.data()
            };
        }

        console.log('GOT DELIVERY ZONE:');
        console.log(detailZone);

        dispatch({
            type: GET_ZONE,
            payload: detailZone
        });

        // --- Check Zone's Open_Hours (check if Open or Closed)

        if(detailZone && detailZone?.zone_status) {
            dispatch(checkIfZoneOpen(detailZone));
        } else {
            dispatch(setCheckedIfZoneOpen());
        }

        // --- END: Check Zone's Open_Hours (check if Open or Closed)

    } catch (err) {

        console.log('ERROR!!!');
        console.log(err);
    }
}

// Set Delivery Zone as Active by Zone ID
export const setActiveZoneById = zoneID => async dispatch => {
  dispatch(setZonesLoading());

    try {

        // Create a reference to the specified category document in the "zones" collection
        const docRef = doc(db, 'zones', zoneID)

        // Retrieve the document with the specified ID from the "categories" collection
        const zoneDoc = await getDoc(docRef);

        const detailZone = {
            _id: zoneDoc.id,
            ...zoneDoc.data()
        };

        console.log('GOT ZONE BY ID');
        console.log(zoneDoc.data())
  
        if(zoneDoc.data()) {

            const new_zone_obj = {
                zone_id: zoneID,
                createdAt: Date.now()
            };

            localStorage.setItem("delivery_zone_bs_1", JSON.stringify(new_zone_obj));

            // Dispatch an action of type GET_ZONE with the category data and ID as the payload
            dispatch({
                type: GET_ZONE,
                payload: detailZone
            });

            // -- Update the users active delivery zone

            if(auth?.currentUser) {

                console.log("UPDATING THE USER'S ACTIVE DELIVERY ZONE FOR USER DOC")

                const userRef = doc(db, 'users', auth.currentUser?.uid)

                 await updateDoc(userRef, {
                    active_delivery_zoneID: zoneID
                });
            }
                
            // -- END: Update the users active delivery zone

            dispatch(goBack());
            // window.location.href = `/search`;

        } else {
            dispatch({
                type: GET_ZONE,
                payload: null
            });

            dispatch(setAlert("Sorry, that zone doesn't exist.", "okay"));
        }
    } catch (err) {

        // Dispatch an action of type ZONE_ERROR with an error message and status code as the payload
        dispatch({
            type: ZONE_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        
        // Log the error to the console
        console.log('ERROR!!!')
        console.log(err);
    }
}

// Create Zone
export const createZone = (formData) => async dispatch => {
    
    console.log('CREATING ZONE IN ACTIONS');

    try {

        console.log('FRONTEND FORM DATA');
        console.log(formData);

        formData.date = Date.now();
        formData.createdAt = serverTimestamp();
        formData.lastModified = serverTimestamp();

        // Retrieve all documents from the "categories" collection
        const data = await getDocs(zoneCollectionRef);

        // Map the document data to an array of categories with the document ID included
        const zoneList = data.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        let orderNum = 1;
                    
        // If existing gallery data has documents increment to orderNum to the sum + 1
        if(zoneList?.length > 0) {
            orderNum = zoneList.length + 1
        }
        console.log('ZONE ORDER NUM');
        console.log(orderNum);

        formData.zone_num = orderNum;

        // Create a new document in the "zones" collection with the zone data
        const result = await addDoc(zoneCollectionRef, formData);

        console.log('SENT TO FIREBASE')
        console.log(result.id);
    
        dispatch(setAlert('Zone Created', 'success'));
    } catch (err) {

      console.log('ERROR!!!')
      console.log(err)

    }
};

// Edit Zone
export const editZone_OrderFee = (zone_data) => async dispatch => {
  console.log('INITIATE EDITING ZONE ORDER FEE');

  try {

      console.log('FRONTEND FORM DATA');
      console.log(zone_data); 

      // Extract the necessary data from the zone_data object
      const {
        small_order_fee,
        min_order_amount
      } = zone_data;

      console.log('UPDATING ZONE ORDER FEE');

      // Get the document reference
      const zoneRef = doc(db, 'zones', 'jqLCMUVu5ySWHrHTetyz')

      const parsed_order_fee = Number(small_order_fee);
      const parsed_min_order = Number(min_order_amount)
      
      // Update the document with the new information
      await updateDoc(zoneRef, {
            small_order_fee: parsed_order_fee,
            min_order_amount: parsed_min_order
      });

      console.log('ZONE ORDER FEE UPDATED!')

      // Get updated data for display
    //   const updated_zone_doc = await getDoc(zoneRef);
    //   console.log('GOT UPDATED ZONE DOCUMENT');
    //   console.log(updated_zone_doc.data())

    //   dispatch({
    //       type: GET_ZONE,
    //       payload: {
    //           _id: updated_zone_doc.id,
    //           ...updated_zone_doc.data()
    //       }
    //   });

      dispatch(setAlert('Saved Changes!', 'success'));
  } catch (err) {
      
      console.log('ERROR EDITING ZONE!!!');
      console.log(err);
  }
};

// Delete zone
export const deleteZone = id => async dispatch => {
//   try {
//     await axios.delete(`/api/categories/${id}`);

//     dispatch({
//       type: DELETE_ZONE,
//       payload: id
//     });

//     // dispatch(setAlert('Collection Removed', 'success'));
//   } catch (err) {
//     dispatch({
//       type: ZONE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
};

// Zones loading
export const setZonesLoading = () => {
  return {
      type: ZONES_LOADING
  }
} 

// Remove all zones
export const clearZones = () => dispatch => {
  dispatch({
      type: CLEAR_ZONES
  });
}

export const setCheckedIfZoneOpen = () => dispatch => {
    dispatch({
        type: CHECKED_IF_ZONE_OPEN
    });
}

export const checkIfZoneOpen = (detailZone) => dispatch => {

    const {
        open_hours,
        zone_status
    } = detailZone

    console.log('CHECKING IF ZONE IS OPEN');

    const currentDate = new Date();
    const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
    const currentTime = currentDate.getHours() + ":" + currentDate.getMinutes() + " " + (currentDate.getHours() >= 12 ? "PM" : "AM");

    console.log('CURRENT DATE');
    console.log(currentDate);
    console.log('CURRENT DAY');
    console.log(currentDay);
    console.log('CURRENT TIME');
    console.log(currentTime);

    if(zone_status === 'open with hours') {
        if (open_hours && open_hours[currentDay]) {
            if(open_hours[currentDay].closed) {
                console.log('store hours set to closed');

                dispatch({
                    type: SET_ZONE_CLOSED
                });
            } else {
                
                console.log('OEPN HOURS CURRENT DAY IS TRUE');
                const closeTime = open_hours[currentDay].close_time;
                const currentTime24 = convertTo24Hour(currentTime);
                const closeTime24 = convertTo24Hour(closeTime);
                const openTime = open_hours[currentDay].open_time;
                const openTime24 = convertTo24Hour(openTime);
        
                console.log('closeTime:', closeTime);
                console.log('openTime:', openTime);
                console.log('currentTime24:', currentTime24);
                console.log('closeTime24:', closeTime24);
                console.log('openTime24:', openTime24);
        
                dispatch(setStoreHours(openTime, closeTime, currentDay));
        
                // Parse as integers for comparison
                const currentTime24Int = parseInt(currentTime24.replace(":", ""), 10);
                const closeTime24Int = parseInt(closeTime24.replace(":", ""), 10);
                const openTime24Int = parseInt(openTime24.replace(":", ""), 10);
        
                console.log('currentTime24Int:', currentTime24Int);
                console.log('closeTime24Int:', closeTime24Int);
                console.log('openTime24Int:', openTime24Int);
        
                if(closeTime24Int > openTime24Int) {
                    if (currentTime24Int < closeTime24Int && currentTime24Int > openTime24Int) {
                    
                        console.log('current is NOT greater than close time');
                        dispatch({
                            type: SET_ZONE_OPEN
                        });
                    } else {
                        console.log('current is greater than close time');
                        dispatch({
                            type: SET_ZONE_CLOSED
                        });
                    }
                } else {
                    if (currentTime24Int < closeTime24Int && currentTime24Int < openTime24Int) {
                    
                        console.log('current is NOT greater than close time');
                        dispatch({
                            type: SET_ZONE_OPEN
                        });
                    } else if (currentTime24Int < closeTime24Int && currentTime24Int > openTime24Int) {
                        
                        console.log('current is NOT greater than close time');
                        dispatch({
                            type: SET_ZONE_OPEN
                        });
                    } else if (currentTime24Int > closeTime24Int && currentTime24Int > openTime24Int) {
                        
                        console.log('current is greater than close time');
                        dispatch({
                            type: SET_ZONE_OPEN
                        });
                    } else {
                        console.log('current is greater than close time');
                        dispatch({
                            type: SET_ZONE_CLOSED
                        });
                    }
                }
            }
            
        } else {
            console.log('OEPN HOURS CURRENT DAY IS FALSE');
            dispatch({
                type: SET_ZONE_CLOSED
            });
        }
    }

    if(zone_status === 'open with no hours') {
        console.log('store open - but HAS NO HOURS');
        dispatch({
            type: SET_ZONE_OPEN
        });
    }

    if(zone_status === 'temporarily closed') {
        console.log('store temporarily closed');
        dispatch({
            type: SET_ZONE_CLOSED
        });
    }

    if(zone_status === 'permanently closed') {
        console.log('store permanently closed');
        dispatch({
            type: SET_ZONE_CLOSED
        });
    }
}  

export const setStoreHours = (open_time, close_time, current_day) => dispatch => {
    dispatch({
        type: SET_STORE_HOURS,
        payload: {
            open_time,
            close_time,
            current_day
        }
    });
}

// Utility functions

const convertTo24Hour = (time12h) => {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);
  
    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
  
    // Ensure hours and minutes are zero-padded if necessary
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    if(formattedHours > 24) {
        formattedHours -= 12;
    }
  
    return `${formattedHours}:${formattedMinutes}`;
  };