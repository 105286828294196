import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { registerWithEmailAndPassword } from '../../../actions/authActions';
import { setAlert } from '../../../actions/alertActions';
import { getDiscountById } from '../../../actions/discountActions';

// Layout Wrapper
import BigModalPage from '../../../components/layout/BigModalPage';

// Sliding Register Form
import RegisterForm from './Form_Register';

// Icons
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import miitoken_logo from '../../../utils/imgs/miitoken_logo_2.png';
import couponIMG from '../../../utils/imgs/coupon.png';

// Initial State 
const initialState = {
    name: '',
    email: '',
    password: ''
};

const Register_body = ({
    auth: { 
        isAuthenticated,
        user,
        account_loading
    }, 
    nav: {
        campus_id,
        campus_name,
        campus_ext
    },
    discount: {
        detailDiscount
    },
    registerWithEmailAndPassword,
    setAlert,
    getDiscountById,
    history 
}) => {

    // State for tracking if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // State for tracking the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // States for form data
    const [formData, setFormData] = useState(initialState);
    const [termsOfService, setTermsOfService] = useState(false);

    // State to disable submit button if required fields are empty
    const [disableBtn, setDisableBtn] = useState(true);

    // Get the URL filter and check if there is a "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("redirect");
    const url_Email = url.searchParams.get("email");

    const { 
        name,
        email,
        password
     } = formData;

     useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);
    
    // Handle the "redirect" parameter in the URL
    useEffect(() => {
        if(filter && filter === 'true') {
            setAlert('Failed: Wrong Email.', 'danger')
        } 

    }, [filter]);

    useEffect(() => {

        // Disable the submit button if name, email, password, or termsOfService is empty
        if (name === '' || email === '' || password === '' || !termsOfService) {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
        
    }, [name, email, password, termsOfService]);

    useEffect(() => {
        if(url_Email) {
            console.log('URL EMAIL EXISTS')
            console.log(url_Email)
            setFormData({ ...formData, ['email']: url_Email});
        } 

    }, [url_Email]);

    useEffect(() => {
        
        if(localStorage.auth_Discount_Reward) {
            getDiscountById(localStorage.auth_Discount_Reward);
        }

    }, [localStorage.auth_Discount_Reward]);

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Validate the input against a regular expression pattern for email
    const validateEmail = (input) => {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
        if (input.match(validRegex)) {
      
          return true;
      
        } else {
      
          return false;
      
        }
    }

    const handleAlertToggle = (e) => {
        e.preventDefault();
        
        const nameArray = name.split(' ');

        let last_name = '';

        last_name = name.split(' ').slice(-1).join(' ');
        
        // Perform validations for name and email
        if(nameArray.length > 1 && last_name.length > 1) {
            if(name === '') {
                setAlert('Name is required.', 'danger');
            } else if (email === '') {
                setAlert('Email is required.', 'danger');
            } else if (!validateEmail(email)) {
                setAlert('Invalid email address!', 'danger');
            } else if (password === '') {
                setAlert('Password is required.', 'danger');
            } else {
                // Register
                console.log('START REGISTER');
                handleRegister(e);
            }
        } else {
            setAlert('Full name is required', 'danger');
        }
    }

    // Function to handle input change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    const handleRegister = (e) => {
        e.preventDefault();
    
        const first_name = name.split(' ').slice(0, -1).join(' ');

        let last_name = '';

        last_name = name.split(' ').slice(-1).join(' ');

        // Register the user
        registerWithEmailAndPassword(email, password, first_name, last_name, campus_id, campus_ext);

        // Redirect to login link page with the provided data
        // window.location.href = `/login/sent?email=${email}&first=${first_name}&last=${last_name}`;
    };

    // Function to handle Mixpanel event tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Register Page View");
    }

    // Send Mixpanel event only in production and if not already sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

   
    return (
        <div className='register_container'>
            <div className='register_content_container'>
                <div className='register_content'>
                    <div style={{boxSizing:'border-box', textAlign:'center'}}>
                        
                        {/* Logo */}
                        <div onClick={() => window.location = '/'} className='register_logo'>
                            <img src={miitoken_logo} />
                        </div>

                        {/* Title */}
                        <div className='register_title'>
                        Create your account
                        </div>

                        {/* Header Description */}
                        <div className='register_title secondary'>
                        Set up your profile details to continue
                        </div>

                        {detailDiscount ? (
                            <Fragment>
                                 {/* Header Description */}
                                <div className='disclaimer'>
                                    1 New User gift waiting for you
                                </div>

                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <p style={{fontSize: '12px', color: '#808080', fontWeight: 600, textAlign: 'center'}}>
                                        Added to account immediately after Sign Up
                                    </p>
                                    
                                </div>

                            

                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
                                    <div style={{height: '100px', margin: '0 10px', position: 'relative'}} onClick={() => window.location = '/'} className='register_logo'>
                                        <img src={couponIMG} />
                                        <div style={{position: 'absolute', top: 0, marginTop: 'auto', paddingLeft: '20px', letterSpacing: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30%', width: '100%', fontSize: '12px', fontWeight: 'bold'}}>
                                            New User Gift
                                        </div>
                                        <div style={{position: 'absolute', top: 0, paddingLeft: '20px', letterSpacing: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', fontSize: '28px', fontWeight: 'bold'}}>
                                            ${detailDiscount.discount_value} OFF
                                        </div>
                                        <div style={{position: 'absolute', bottom: 0, marginTop: 'auto', paddingLeft: '20px', letterSpacing: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40%', width: '100%', fontSize: '13px', fontWeight: 500}}>
                                            For orders over $10.00
                                        </div>
                                    </div>
                                    {/* <div style={{height: '100px'}} onClick={() => window.location = '/'} className='register_logo'>
                                        <img src={couponIMG} />
                                    </div> */}
                                </div>
                            </Fragment>
                        ) : (
                            <div style={{width: '100%', height: '1px', marginTop: '20px', background: '#e6ecf0'}}>
                                {/* spacer */}
                            </div>
                        )}
                    </div>

                    {/* Register Form */}
                    <form>
                        <div className='register_form'>

                            {/* Form Title */}
                            <div className='register_form_title'>
                                Your profile information 
                                <hr style={{background:'rgb(196, 199, 204)', height:'1px', flex:'.96 1 0%', boxSizing:'border-box'}} />
                            </div>

                            {/* Form Inputs */}
                            <div className='register_form_inputs'>

                                {/* Email Input */}
                                <div className='register_input_container'>
                                    <label className='register_input_label'>
                                        Your email
                                    </label>
                                    <input 
                                        // className='register_input_element' 
                                        type="text"
                                        placeholder={`e.g., yourname@email.com`}
                                        className="input"
                                        name="email"
                                        value={email}
                                        // maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>

                                {/* Name Input */}
                                <div className='register_input_container'>
                                    <label className='register_input_label'>
                                        Your name
                                    </label>
                                    <input 
                                        // className='register_input_element' 
                                        type="text"
                                        placeholder="e.g., John Doe"
                                        className="input"
                                        name="name"
                                        value={name}
                                        // maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>

                                {/* Password Input */}
                                <div className='register_input_container'>
                                    <label className='register_input_label'>
                                        Password
                                    </label>
                                    <input 
                                        // className='register_input_element' 
                                        type="password"
                                        placeholder="Password"
                                        className="input"
                                        name="password"
                                        value={password}
                                        maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>
                                <hr style={{background:'rgb(196, 199, 204)', height:'0.5px', flex:'1 1 0%', margin:'0px 32px 0px 16px'}} />
                                
                                {/* Terms of service Checkbox */}
                                <div style={{boxSizing:'border-box', marginTop:'17px'}}>
                                    <div className='register_checkBox_container'>
                                        <input 
                                            style={{cursor:'pointer'}}
                                            type="checkbox" 
                                            name="termsOfService" 
                                            checked={termsOfService}
                                            onChange={() => setTermsOfService(!termsOfService)}
                                        />
                                        <label style={{margin:'0px 0px 0px 10px'}}>I agree with the <a href="/settings/terms" style={{fontSize:'14px', color:'rgb(24, 105, 245)'}}>Terms of Service</a></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Form Actions */}
                        <div className='register_btns'>
                            <div 
                                style={{cursor:'pointer', outline:'0px', border:'1px solid rgb(220, 222, 224)', borderRadius:'100px', letterSpacing:'-0.4px', fontWeight:'500', background:'#fff', color:'rgb(49, 50, 51)', lineHeight:'17px', padding:'14px 24px', marginTop:'24px', marginRight:'16px', height:'60px', width:'120px', fontSize:'14px', display:'flex', alignItems:'center', justifyContent:'center', textDecoration:'none', boxSizing:'border-box'}}
                                onClick={() => history.goBack()}
                            >
                                {/* <ArrowBackIcon /> */}
                                Go Back
                            </div>

                            {!account_loading ? (
                                <button 
                                    disabled={disableBtn}
                                    onClick={handleAlertToggle} 
                                    className={`register_btn ${!disableBtn ? 'active' : '' }`}
                                >
                                    Create account
                                </button>
                            ) : (
                                <button 
                                    disabled={true}
                                    className={`register_btn active`}
                                >
                                    Creating...
                                </button>
                            )}

                        </div>
                    </form>
                </div>
            </div>

            {/* Page footer - fixed to bottom of screen */}
            <div className='register_footer'>
                <hr style={{height:'1px', background:'rgb(232, 233, 235)'}} />
                <div className='register_footer_nav'>
                    <a href='/settings/trust_and_safety'>
                    Read our privacy policy
                    </a>
                    <a href='/settings/help'>
                    Contact support
                    </a>
                </div>
            </div>
        </div>
    )
}

Register_body.propTypes = {
    registerWithEmailAndPassword: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    getDiscountById: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav,
    discount: state.discount
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    registerWithEmailAndPassword,
    setAlert,
    getDiscountById
})(Register_body);
