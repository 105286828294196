// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const PROFILE_UPDATE_LOADING = 'PROFILE_UPDATE_LOADING';
export const PROFILE_UPDATE_LOADED = 'PROFILE_UPDATE_LOADED';
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING';
export const ACCOUNT_LOADING_DONE = 'ACCOUNT_LOADING_DONE';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_USER_IMG = 'UPDATE_USER_IMG';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_ERROR = 'USER_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const CLEAR_USER = 'CLEAR_USER';
// Marketplace
export const UPDATE_GUEST_PHONE = 'UPDATE_GUEST_PHONE';

// Nav
export const SET_NAV1 = 'SET_NAV1';
export const SET_NAV2 = 'SET_NAV2';
export const SET_NAV3 = 'SET_NAV3';
export const REMOVE_NAV1 = 'REMOVE_NAV1';
export const REMOVE_NAV2 = 'REMOVE_NAV2';
export const REMOVE_NAV3 = 'REMOVE_NAV3';
export const SET_PAGE = 'SET_PAGE';
export const TOGGLE_USER_MODAL = 'TOGGLE_USER_MODAL';
export const TOGGLE_PROFILE_MODAL = 'TOGGLE_PROFILE_MODAL';
export const TOGGLE_AUTH_MODAL = 'TOGGLE_AUTH_MODAL';
export const SET_PROFILE_MODAL = 'SET_PROFILE_MODAL';
export const TOGGLE_POST_MODAL = 'TOGGLE_POST_MODAL';
export const TOGGLE_DISCOUNT_MODAL = 'TOGGLE_DISCOUNT_MODAL';
export const TOGGLE_MODAL_DISCOUNT_DETAILS = 'TOGGLE_MODAL_DISCOUNT_DETAILS';
export const TOGGLE_MODAL_DISCOUNT_LIST_PREVIEW = 'TOGGLE_MODAL_DISCOUNT_LIST_PREVIEW';
export const TOGGLE_MODAL_STORE_HOURS = 'TOGGLE_MODAL_STORE_HOURS';
export const TOGGLE_MODAL_HOW_IT_WORKS = 'TOGGLE_MODAL_HOW_IT_WORKS';
export const TOGGLE_MODAL_PROCESSING_ORDER = 'TOGGLE_MODAL_PROCESSING_ORDER';
export const SET_POST_MODAL = 'SET_POST_MODAL';
export const TOGGLE_COMMENT_MODAL = 'TOGGLE_COMMENT_MODAL';
export const SET_COMMENT_MODAL = 'SET_COMMENT_MODAL';
export const TOGGLE_SHARE_MODAL = 'TOGGLE_SHARE_MODAL';
export const SET_MAIN_NAV = 'SET_MAIN_NAV';
export const TOGGLE_SIDE_NAV = 'TOGGLE_SIDE_NAV';
export const TOGGLE_ITEM_MODAL = 'TOGGLE_ITEM_MODAL';
export const TOGGLE_COLLECTION_MODAL = 'TOGGLE_COLLECTION_MODAL';
export const TOGGLE_LOCATION_MODAL = 'TOGGLE_LOCATION_MODAL';
export const REMOVE_ITEM_MODAL = 'REMOVE_ITEM_MODAL';
export const REMOVE_COLLECTION_MODAL = 'REMOVE_COLLECTION_MODAL';
export const REMOVE_LOCATION_MODAL = 'REMOVE_LOCATION_MODAL';  
export const SET_IS_MARKETPLACE = 'SET_IS_MARKETPLACE';  
// Driver Portal
export const TOGGLE_QUIT_DELIV_MODAL = 'TOGGLE_QUIT_DELIV_MODAL'; 
export const TOGGLE_START_DELIV_MODAL = 'TOGGLE_START_DELIV_MODAL'; 
export const TOGGLE_CONFIRM_PICKUP_MODAL = 'TOGGLE_CONFIRM_PICKUP_MODAL'; 
export const TOGGLE_CUSTOMER_CODE_MODAL = 'TOGGLE_CUSTOMER_CODE_MODAL'; 
// Campus Logic
export const SET_CAMPUS_ID = 'SET_CAMPUS_ID'; 
    

// Posts
export const GET_POSTS = 'GET_POSTS';
export const SET_POSTS = 'SET_POSTS';
export const SET_LAST_POSTS = 'SET_LAST_POSTS';
export const GET_POST = 'GET_POST';
export const UPDATE_POST_LIKES = 'UPDATE_POST_LIKES';
export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';
export const GET_COMMENTS = 'GET_COMMENTS';
export const SET_COMMENTS = 'SET_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const COMMENTS_LOADING = 'COMMENTS_LOADING';
export const POSTS_LOADING = 'POSTS_LOADING';
export const CLEAR_POSTS = 'CLEAR_POSTS';
export const CLEAR_POST = 'CLEAR_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_EDITOR_TEXT = 'UPDATE_EDITOR_TEXT';
export const CLEAR_EDITOR_TEXT = 'CLEAR_EDITOR_TEXT';
export const UPDATE_EDITOR_CATEGORY = 'UPDATE_EDITOR_CATEGORY';
export const CLEAR_EDITOR_CATEGORY = 'CLEAR_EDITOR_CATEGORY';
export const UPDATE_EDITOR_FILES = 'UPDATE_EDITOR_FILES';
export const REMOVE_EDITOR_FILE = 'REMOVE_EDITOR_FILE';
export const CLEAR_EDITOR_FILES = 'CLEAR_EDITOR_FILES';
export const UPDATE_URLS = 'UPDATE_URLS';
export const REMOVE_URL = 'REMOVE_URL';
export const CLEAR_URLS = 'CLEAR_URLS';
export const UPDATE_LAST_POST_ADDED = 'UPDATE_LAST_POST_ADDED';
export const POST_UPLOADING = 'POST_UPLOADING';
export const POST_UPLOADING_IMGS = 'POST_UPLOADING_IMGS';

// Chats
export const CHATS_LOADING = 'CHATS_LOADING';
export const CHAT_LOADING = 'CHAT_LOADING';
export const GET_CHAT = 'GET_CHAT';
export const GET_CHATS = 'GET_CHATS';
export const CREATE_CHAT = 'CREATE_CHAT';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const GET_MESSAGES = 'GET_MESSAGES';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_MESSAGE = 'SET_MESSAGE';
export const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';
export const SET_UNREAD_CHATS = 'SET_UNREAD_CHATS';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const CHAT_ERROR = 'CHAT_ERROR';
export const CLEAR_CHAT = 'CLEAR_CHAT';
export const CLEAR_CHATS = 'CLEAR_CHATS';
export const UPDATE_EDITOR_IMG_FILE = 'UPDATE_EDITOR_IMG_FILE';
export const CLEAR_EDITOR_IMG_FILE = 'CLEAR_EDITOR_IMG_FILE';
export const UPDATE_IMG_URL = 'UPDATE_IMG_URL';
export const REMOVE_IMG_URL = 'REMOVE_IMG_URL';

// Notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_LAST_NOTIFICATIONS = 'SET_LAST_NOTIFICATIONS';
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING';
    
// Searches
export const GET_RECENT_SEARCHES = 'GET_RECENT_SEARCHES';
export const GET_POPULAR_SEARCHES = 'GET_POPULAR_SEARCHES';
export const SEARCHES_ERROR = 'SEARCHES_ERROR';
export const CLEAR_POPULAR_SEARCHES = 'CLEAR_POPULAR_SEARCHES';
export const POPULAR_SEARCHES_LOADING = 'POPULAR_SEARCHES_LOADING';
export const CLEAR_RECENT_SEARCHES = 'CLEAR_RECENT_SEARCHES';
export const RECENT_SEARCHES_LOADING = 'RECENT_SEARCHES_LOADING';
export const DELETE_RECENT_SEARCH = 'DELETE_RECENT_SEARCH';


// --- Marketplace ------------------------------------------


// Products
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const SET_LAST_PRODUCTS = 'SET_LAST_PRODUCTS';
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const GET_PRODUCT = 'GET_PRODUCT';
export const UPDATE_LAST_PRODUCT_ADDED = 'UPDATE_LAST_PRODUCT_ADDED';
export const SET_LAST_PAGE_DOC = 'SET_LAST_PAGE_DOC';
export const SET_SHOW_BOTTOM_SPINNER = 'SET_SHOW_BOTTOM_SPINNER';
export const SET_NO_MORE_PRODUCTS = 'SET_NO_MORE_PRODUCTS';

// Collections
export const SET_COLLECTIONS = 'SET_COLLECTIONS';
export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const COLLECTION_ERROR = 'COLLECTION_ERROR';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const GET_COLLECTION = 'GET_COLLECTION';
export const CLEAR_COLLECTIONS = 'CLEAR_COLLECTIONS';
export const COLLECTIONS_LOADING = 'COLLECTIONS_LOADING';
export const GET_ITEM_COLLECTIONS = 'GET_ITEM_COLLECTIONS';

// CART
export const GET_CART = 'GET_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TOTALS = 'ADD_TOTALS';
export const DECREMENT_ITEM = 'DECREMENT_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const CLEAR_CART = 'CLEAR_CART';
export const CART_ERROR = 'CART_ERROR';
export const CART_LOADING = 'CART_LOADING';
export const UPDATE_DRIVER_TIP = 'UPDATE_DRIVER_TIP'; 
export const APPLY_DISCOUNT = 'APPLY_DISCOUNT'; 

// Menu
export const SET_MENUS = 'SET_MENUS';
export const GET_MENUS = 'GET_MENUS';
export const SET_MODAL_MENU = 'SET_MODAL_MENU';
export const MENU_ERROR = 'MENU_ERROR';
export const DELETE_MENU = 'DELETE_MENU';
export const UPDATE_MENU_ITEMS = 'UPDATE_MENU_ITEMS';
export const ADD_MENU = 'ADD_MENU';
export const GET_MENU = 'GET_MENU';
export const EDIT_MENU = 'EDIT_MENU';
export const OPEN_MENU_MODAL = 'OPEN_MENU_MODAL';
export const CLOSE_MENU_MODAL = 'CLOSE_MENU_MODAL';
export const CLEAR_MENUS = 'CLEAR_MENUS';
export const MENU_UPLOADING = 'MENU_UPLOADING';
export const MENUS_LOADING = 'MENUS_LOADING';

// Locations
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const LOCATION_ERROR = 'LOCATION_ERROR';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const ADD_LOCATION = 'ADD_LOCATION';
export const EDIT_LOCATION = 'EDIT_LOCATION';
export const GET_LOCATION = 'GET_LOCATION';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const SET_DELIVERY_TIME = 'SET_DELIVERY_TIME';
export const SET_LOCATION_ACTIVE = 'SET_LOCATION_ACTIVE';

// Orders
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const ORDERS_LOADING = 'ORDERS_LOADING';
export const ORDER_ERROR = 'ORDER_ERROR';
// -- Driver Portal
export const GET_DRIVER_DELIVERIES = 'GET_DRIVER_DELIVERIES';

// REFUNDS
export const GET_REFUNDS = 'GET_REFUNDS';
export const REFUND_ERROR = 'REFUND_ERROR';
export const CLEAR_REFUNDS = 'CLEAR_REFUNDS';
export const REFUNDS_LOADING = 'REFUNDS_LOADING';

// Discounts
export const GET_DISCOUNTS = 'GET_DISCOUNTS';
export const SET_DISCOUNTS = 'SET_DISCOUNTS';
export const DISCOUNT_ERROR = 'DISCOUNT_ERROR';
export const DELETE_DISCOUNT = 'DELETE_DISCOUNT';
export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export const GET_DISCOUNT = 'GET_DISCOUNT';
export const EDIT_DISCOUNT = 'EDIT_DISCOUNT';
export const DISCOUNTS_LOADING = 'DISCOUNTS_LOADING';
export const MODAL_DISCOUNT_LOADING = 'MODAL_DISCOUNT_LOADING';
export const CLEAR_DISCOUNTS = 'CLEAR_DISCOUNTS';
export const CLEAR_DISCOUNT = 'CLEAR_DISCOUNT';
export const SET_MODAL_DISCOUNT = 'SET_MODAL_DISCOUNT';
export const CLEAR_MODAL_DISCOUNT = 'CLEAR_MODAL_DISCOUNT';
export const GET_AVAILABLE_DISCOUNTS = 'GET_AVAILABLE_DISCOUNTS';
export const SET_MODAL_DETAIL_DISCOUNT = 'SET_MODAL_DETAIL_DISCOUNT';

// Discount Groups
export const GET_DISCOUNT_GROUPS = 'GET_DISCOUNT_GROUPS';
export const SET_DISCOUNT_GROUPS = 'SET_DISCOUNT_GROUPS';
export const DISCOUNT_GROUP_ERROR = 'DISCOUNT_GROUP_ERROR';
export const DELETE_DISCOUNT_GROUP = 'DELETE_DISCOUNT_GROUP';
export const ADD_DISCOUNT_GROUP = 'ADD_DISCOUNT_GROUP';
export const GET_DISCOUNT_GROUP = 'GET_DISCOUNT_GROUP';
export const EDIT_DISCOUNT_GROUP = 'EDIT_DISCOUNT_GROUP';
export const DISCOUNT_GROUPS_LOADING = 'DISCOUNT_GROUPS_LOADING';
export const CLEAR_DISCOUNT_GROUPS = 'CLEAR_DISCOUNT_GROUPS';
export const UPDATE_DISCOUNT_GROUP_IMG = 'UPDATE_DISCOUNT_GROUP_IMG';

// Zones
export const GET_ZONES = 'GET_ZONES';
export const SET_ZONES = 'SET_ZONES';
export const ZONE_ERROR = 'ZONE_ERROR';
export const DELETE_ZONE = 'DELETE_ZONE';
export const ADD_ZONE = 'ADD_ZONE';
export const GET_ZONE = 'GET_ZONE';
export const EDIT_ZONE = 'EDIT_ZONE';
export const ZONES_LOADING = 'ZONES_LOADING';
export const CLEAR_ZONES = 'CLEAR_ZONES';
export const SET_ZONE_CLOSED = 'SET_ZONE_CLOSED';
export const SET_ZONE_OPEN = 'SET_ZONE_OPEN';
export const SET_STORE_HOURS = 'SET_STORE_HOURS';
export const CHECKED_IF_ZONE_OPEN = 'CHECKED_IF_ZONE_OPEN';




