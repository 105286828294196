import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { getModalDiscountById, setModalDiscount, get_All_Discounts_From_CollectionList, handleToggle_DiscountDetails_Modal } from '../../../actions/discountActions';

// Components
import Spinner from '../../../components/common/Spinner';

// Icons
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

// modal_detailDiscount

const DiscountsBlock = ({
    discount: {
        loading, 
        available_discounts,
        modal_detailDiscount
    },
    nav: {
        modal_discountDetails,
    },
    page,
    collectionList,
    get_All_Discounts_From_CollectionList,
    setModalDiscount,
    getModalDiscountById,
    handleToggle_DiscountDetails_Modal
}) => {

    const [discountList, setDiscountList] = useState([]);
    const [gotDiscounts, setGotDiscounts] = useState(false);

    useEffect(() => {

        renderDiscountList();
    }, [available_discounts]);

    useEffect(() => {
        console.log('COLLECTION LIST CHANGED')

        if(page === 'product detail') {
            console.log('CALLING GET DISCOUNTS')
            get_All_Discounts_From_CollectionList(collectionList, available_discounts);
        }

    }, [collectionList]);

    const renderDiscountList = () => {
        setDiscountList([]);

        if(available_discounts === null) {
            setDiscountList([(
                <Spinner />
            )])
        }
        else { 
            if(available_discounts.length > 0) {

                available_discounts.map(discount_obj => {
                    setDiscountList(discountList => [...discountList, (
                        <Discount_Item
                            detailDiscount={discount_obj}
                            handleToggle_DiscountDetails_Modal={handleToggle_DiscountDetails_Modal}
                        />
                    )])
                });

                setGotDiscounts(true);
            } else {
                setDiscountList([(
                    <div style={{display: 'none'}}>
                        <h1>No Discounts</h1>
                    </div>
                )])
            }
        } 
    }

    return (
        <Fragment>
            {discountList}
        </Fragment>
    )
}


DiscountsBlock.propTypes = {
    discount: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    handleToggle_DiscountDetails_Modal: PropTypes.func.isRequired,
    get_All_Discounts_From_CollectionList: PropTypes.func.isRequired,
    setModalDiscount: PropTypes.func.isRequired,
    getModalDiscountById: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    discount: state.discount,
    nav: state.nav
})

export default connect(mapStateToProps, { 
    handleToggle_DiscountDetails_Modal,
    get_All_Discounts_From_CollectionList,
    setModalDiscount,
    getModalDiscountById

 })(DiscountsBlock);


const Discount_Item = ({
    handleToggle_DiscountDetails_Modal,
    detailDiscount
}) => {


    return (

        <div style={{background: 'rgb(229, 255, 242)', marginBottom: '20px', width: '100%', height: 'fit-content', borderRadius: '25px', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', padding: '10px', boxSizing: 'border-box'}}>
            <div className='icon_20' style={{paddingTop: '5px', boxSizing: 'border-box'}}>
                <CheckCircleOutlinedIcon />
            </div>
            <div style={{padding: '5px', boxSizing: 'border-box'}}>
                {(detailDiscount.discount_type === 'buy x get y' && detailDiscount.percentage_off_each && detailDiscount.min_quantity) && (
                    <h3 style={{fontSize: '18px',}}>Buy {detailDiscount.min_quantity} Get {detailDiscount.getY_quantity} for {detailDiscount.percentage_off_each !== '100' ? `${detailDiscount.percentage_off_each}% OFF` : 'FREE'}</h3>
                )}

                {(detailDiscount.discount_type === 'amount off products' && detailDiscount.amount_off_each && detailDiscount.min_quantity) && (
                    <h3 style={{fontSize: '18px',}}>Buy {detailDiscount.min_quantity} Get {detailDiscount.getY_quantity} ${detailDiscount.amount_off_each} OFF</h3>
                )}
                
                <div style={{fontSize: '14px', marginTop: '5px'}}>Discount applies to select products. Discount calculated using the lower priced item, but will apply across all qualifying and discounted items. Add all items to cart to receive discount. Discount not applicable to tax. Pricing, promotions and availability may vary by location and on Miitoken. Offer expires 7/31/24.</div>
                <div onClick={() => handleToggle_DiscountDetails_Modal(detailDiscount)} style={{textDecoration: 'underline', fontWeight: 'bold', fontSize: '14px', marginTop: '5px', cursor: 'pointer'}}>Coupon Details</div>
            </div>
        </div>
    )
}