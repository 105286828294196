
// Firebase
import { db, auth, storage } from "../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

import { setAlert } from './alertActions';
import { getCart } from './cartActions';
import { toggleModal_DiscountList_Preview } from "./navActions";

import Cart from './Cart';

import {
    GET_DISCOUNTS,
    SET_DISCOUNTS,
    DISCOUNT_ERROR,
    DELETE_DISCOUNT,
    ADD_DISCOUNT,
    GET_DISCOUNT,
    EDIT_DISCOUNT,
    DISCOUNTS_LOADING,
    MODAL_DISCOUNT_LOADING,
    CLEAR_DISCOUNTS,
    CLEAR_DISCOUNT,
    APPLY_DISCOUNT,
    SET_MODAL_DISCOUNT,
    CLEAR_MODAL_DISCOUNT,
    GET_AVAILABLE_DISCOUNTS,
} from './types';

// Reference to the "collections" collection in Firestore
const discountCollectionRef = collection(db, "discounts");

// Get Collections
export const getDiscounts = (lastPageDoc) => async dispatch => {
  
  console.log('ENTER GET DISCOUNTS');

  try {

    console.log('GETTING DISCOUNTS')

    // Construct a new query starting at the last visible document and fetch the next 3 posts.
    const getQuery = query(discountCollectionRef, orderBy('lastModified', 'desc'), limit(15));

    // Retrieve all documents from the "categories" collection
    const data = await getDocs(getQuery);

    // Map the document data to an array of categories with the document ID included
    const categoryList = data.docs.map((doc) => ({...doc.data(), _id: doc.id}));

    console.log('SHOW CATEGORY LIST')
    console.log(categoryList);

    // Dispatch an action of type GET_CATEGORIES with the categoryList as the payload
    dispatch({
        type: GET_DISCOUNTS,
        payload: categoryList
    });

  } catch (err) {
    
    console.log('ERROR!!!')
    console.log(err);

    // dispatch({
    //   type: DISCOUNT_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status }
    // });
  }
};

// Get Discounts user claimed
export const getDiscountsByUserID = (userId, lastPageDoc) => async dispatch => {

  if(!lastPageDoc) dispatch(setDiscountsLoading());
  
  console.log('GETTING CLAIMED DISCOUNTS');
  try {
      console.log('QUERYING  DISCOUNTS');

      let q;

      if(lastPageDoc) {
          // Create a query to retrieve posts where the claim_id_list contains the provided userId, starting at the last visible document and fetch the next 3 posts.
          q = query(discountCollectionRef, where("claim_id_list", "array-contains", userId), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0));
      } else {
          // Create a query to retrieve posts where the claim_id_list contains the provided userId
          q = query(discountCollectionRef, where("claim_id_list", "array-contains", userId), orderBy('lastModified', 'desc'));
      }

      console.log('QUERYING  RESULT');
      console.log(q);

      // Execute the query and retrieve the query snapshot
      const querySnapshot = await getDocs(q);

      // Map through the document snapshots in the query snapshot and extract the data and ID for each post
      const discountList = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

      console.log('CLAIMED DISCOUNTS LIST DATA');
      console.log(discountList);

      // Dispatch an action of type SET_DISCOUNTS with the list of discounts as the payload
      dispatch({
        type: SET_DISCOUNTS,
        payload: discountList
      });


  } catch (err) {
      console.log('ERROR!!!')
      console.log(err)

      // Dispatch an action of type SET_DISCOUNTS with an empty array as the payload in case of an error
      dispatch({
          type: SET_DISCOUNTS,
          payload: []
      })
  }
};

// Get Collection Products
export const getDiscountsInGroup = discount_GroupID => async dispatch => {

  console.log('GETTING DISCOUNTS IN DISCOUNT GROUP')
  console.log(discount_GroupID)

  dispatch(setDiscountsLoading());

  try {

      const discount_GroupRef = doc(db, 'discount_Groups', discount_GroupID)
      const subCollectionRef = collection(discount_GroupRef, "discounts")

      // Construct a new query 
      const subCollection_Query = query(subCollectionRef, orderBy('order_num', 'desc'));

      console.log('FETCHING DISCOUNTS');

      const discountsData = await getDocs(subCollection_Query);
      const discountsList = discountsData.docs.map((doc) => ({...doc.data(), id: doc.id}));

      console.log('GOT DISCOUNTS');
      console.log(discountsList)

      let discount_obj_list = [];
      // START loop
      for (const discount of discountsList) {

          // Create a reference to the specified discount document in the "discounts" collection
          const discountRef = doc(db, 'discounts', discount.discount_id);

          // Retrieve the document with the specified ID from the "discounts" collection
          const discountDoc = await getDoc(discountRef);

          discount_obj_list.push({
              ...discountDoc.data(),
              _id: discountDoc.id
          });
      }

      console.log('GOT DISCOUNTS IN DISCOUNT GROUP');
      console.log(discount_obj_list)


      // Dispatch an action of type GET_DISCOUNTS with the discount_obj_list as the payload
      dispatch({
          type: GET_DISCOUNTS,
          payload: discount_obj_list
      });
  } catch (err) {

      // Dispatch an action of type PRODUCT_ERROR with an error message and status code as the payload
      // dispatch({
      //     type: PRODUCT_ERROR,
      //     payload: { msg: "something went wrong", status: 500 }
      // });
      
      // Log the error to the console
      console.log('ERROR GETTING DISCOUNTS IN DISCOUNT GROUP!!!')
      console.log(err);
  }
};

// Set Collections
export const setDiscounts = (array_of_discount_objects) => async dispatch => {
  
    console.log('ENTERED SET DISCOUNTS');
  
    try {
  
      console.log('SETTING DISCOUNTS')
  
  
       // Dispatch an action to add the message object to the state
       dispatch({
            type: SET_DISCOUNTS,
            payload: array_of_discount_objects
        });
  
    } catch (err) {
      
      console.log('ERROR!!!')
      console.log(err);
  
      // dispatch({
      //   type: DISCOUNT_ERROR,
      //   payload: { msg: err.response.statusText, status: err.response.status }
      // });
    }
  };

export const removeDiscount = (discountID, dont_reset_discounts) => async dispatch => {
    let cart = new Cart(localStorage.cart_bs_1 ? JSON.parse(localStorage.cart_bs_1) : {});

    cart.removeDiscount(discountID);

    if(dont_reset_discounts) { // this is to reset the input field in the add promo page when remove btn is clicked
      dispatch(clearDiscount())
    }

    // Store the cart in local storage
    localStorage.setItem("cart_bs_1", JSON.stringify(cart));
    
    dispatch(getCart(dont_reset_discounts)); // dont reset so this function can be reused on promo page
}

// Get single collection by id
export const getDiscountById = discountID => async dispatch => {
  dispatch(setDiscountsLoading());

  console.log('GETTING DISCOUNT BY ID');

    try {

        // Create a reference to the specified discount document in the "discounts" collection
        const docRef = doc(db, 'discounts', discountID)

        // Retrieve the document with the specified ID from the "discounts" collection
        const discountDoc = await getDoc(docRef);

        console.log('GOT DISCOUNT BY ID');
        console.log(discountDoc.data())
  
        if(discountDoc.data()) {
            // Dispatch an action of type GET_DISCOUNT with the category data and ID as the payload
            dispatch({
                type: GET_DISCOUNT,
                payload: {
                    _id: discountDoc.id,
                    ...discountDoc.data()
                }
            });
        } else {
            dispatch({
                type: GET_DISCOUNT,
                payload: null
            });

            dispatch(setAlert("Sorry, that discount doesn't exist.", "okay"));
        }
    } catch (err) {

        // Dispatch an action of type DISCOUNT_ERROR with an error message and status code as the payload
        dispatch({
            type: DISCOUNT_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        
        // Log the error to the console
        console.log('ERROR!!!')
        console.log(err);
    }
}

// Get single discount by id
export const getModalDiscountById = discountID => async dispatch => {
  dispatch(setModalDiscountsLoading());

  console.log('GETTING MODAL DISCOUNT BY ID');

    try {

        // Create a reference to the specified discount document in the "discounts" collection
        const docRef = doc(db, 'discounts', discountID)

        // Retrieve the document with the specified ID from the "discounts" collection
        const discountyDoc = await getDoc(docRef);

        console.log('GOT DISCOUNT BY ID');
        console.log(discountyDoc.data())
  
        if(discountyDoc.data()) {
            // Dispatch an action of type GET_DISCOUNT with the category data and ID as the payload
            dispatch({
                type: SET_MODAL_DISCOUNT,
                payload: {
                    _id: discountyDoc.id,
                    ...discountyDoc.data()
                }
            });
        } else {
            dispatch({
                type: SET_MODAL_DISCOUNT,
                payload: null
            });

            dispatch(setAlert("Sorry, that discount doesn't exist.", "okay"));
        }
    } catch (err) {

        // Dispatch an action of type DISCOUNT_ERROR with an error message and status code as the payload
        dispatch({
            type: DISCOUNT_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        
        // Log the error to the console
        console.log('ERROR!!!')
        console.log(err);
    }
}

// Create Discount
export const createDiscount = (formData) => async dispatch => {
    
    console.log('CREATING DISCOUNT IN ACTIONS');

    try {

      console.log('FRONTEND FORM DATA');
      console.log(formData);

    formData.date = Date.now();
    formData.createdAt = serverTimestamp();
    formData.lastModified = serverTimestamp();


    // Create a new document in the "discounts" collection with the discount data
    const result = await addDoc(discountCollectionRef, formData);

    console.log('SENT TO FIREBASE')
    console.log(result.id);


      // dispatch({
      //   type: ADD_COLLECTION,
      //   payload: res.data
      // });
  
      dispatch(setAlert('Discount Created', 'success'));
    } catch (err) {

      console.log('ERROR!!!')
      console.log(err)

    }
};

// Edit Discount
export const applyDiscount = (discount_code, userData) => async dispatch => {
    console.log('APPLYING DISCOUNT NOW');

    let cart = new Cart(localStorage.cart_bs_1 ? JSON.parse(localStorage.cart_bs_1) : {});

    try {

        console.log('FRONTEND DATA');
        console.log(discount_code); 

        // Create a query to fetch user documents based on the specified username
        const q = query (discountCollectionRef, where("discount_code_lowercase", "==", discount_code.toLowerCase()))
        console.log(q);

        // Retrieve the query snapshot containing the user documents
        const querySnapshot = await getDocs(q);
        console.log('FETCHED DISCOUNT CODE DATA');
        console.log(querySnapshot.docs[0].data());

        const discount_obj = {
            _id: querySnapshot.docs[0].id, 
            ...querySnapshot.docs[0].data()
        }

        // Dispatch an action of type SET_POSTS with the user posts as the payload
        dispatch({
            type: SET_DISCOUNTS,
            payload: [discount_obj]
        });

        if(userData) {
          dispatch(claimDiscount(discount_obj._id, userData, discount_obj));
        }

        dispatch(getDiscountById(discount_obj._id));


        // -- Add discount to cart --

        console.log('ADDING DISCOUNT TO CART')
        cart.add_discount(discount_obj, discount_obj._id);

        // Store the cart in local storage
        localStorage.setItem("cart_bs_1", JSON.stringify(cart));
  
        // Dispatch an action of type APPLY_DISCOUNT with the cart data as the payload
        dispatch({
            type: APPLY_DISCOUNT,
            payload: cart
        });

        // -- END: Add discount to cart --
        

        dispatch(setAlert('Discount Applied!', 'success'));
    } catch (err) {
        
        console.log('ERROR EDITING COLLECTION!!!');
        console.log(err);
    }
};

// Claim Discount
export const claimDiscount = (discountId, userData, discountData) => async dispatch => {
    
  // Get the current list of likes from the discount data
  let userObj_Claimed_List = [];
  
  if(discountData.claims) {
    userObj_Claimed_List = discountData.claims;
  }
  
  try {
      console.log('ADDING CLAIM!!!!!')
      console.log(discountId);

      // Get Firebase Discount & Claims of discount collection ref
      const discountRef = doc(db, 'discounts', discountId)
      const colRef = collection(discountRef, "claims")


      console.log('FROM USER DATA:');
      console.log(userData);

      console.log("List OF USER ID's OF PEOPLE WHO CLAIMED DISCOUNT DATA:");
      console.log(userObj_Claimed_List);
      
      // Create the new claim object
      const newClaim = {
          user: {
              _id: userData._id,
              username: userData.username,
              first_name: userData.first_name,
              last_name: userData.last_name,
              img: userData.img
          }
      };

      // Check if discount already has been claimed by same user
      if(userObj_Claimed_List.filter(claim => claim.user._id.toString() === userData._id).length > 0) {
          
          // Dispatch an alert to indicate that the post was saved
          // dispatch(setAlert('Coupon already claimed.', 'danger'));
      } else {
          // const claimData = await addDoc(colRef, newClaim);

          // Add the new claim to the userObj_Claimed_List
          userObj_Claimed_List.push(newClaim);

          console.log('NEW Claim DATA:');
          console.log(userObj_Claimed_List);

          // Create array for the list of claim ID's
          const id_array = [];

          userObj_Claimed_List.map((claim) => {
              id_array.push(claim.user._id);
          })

          console.log('Removed Claim ID LIST Data:');
          console.log(id_array);

          // Update the post document with the updated claims and claim_id_list
          await updateDoc(discountRef, {
              claims: userObj_Claimed_List,
              claim_id_list: id_array
          })

          console.log('SUCCESSFULLY CLAIMED');
          

          // Dispatch an alert to indicate that the post was saved
          dispatch(setAlert('Coupon claimed.', 'okay'));
      }

  //   dispatch({
  //     type: UPDATE_POST_LIKES,
  //     payload: { id, likes: res.data }
  //   });

    
  } catch (err) {
    console.log('ERROR CLAIMING DISCOUNT')
    console.log(err)
    dispatch(setAlert('Error claiming discount.', 'danger'));
  }
};

// Delete discount
export const deleteDiscount = id => async dispatch => {
//   try {
//     await axios.delete(`/api/categories/${id}`);

//     dispatch({
//       type: DELETE_COLLECTION,
//       payload: id
//     });

//     // dispatch(setAlert('Collection Removed', 'success'));
//   } catch (err) {
//     dispatch({
//       type: DISCOUNT_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
};

// Discounts loading
export const setDiscountsLoading = () => {
  return {
      type: DISCOUNTS_LOADING
  }
} 

// Discounts loading
export const setModalDiscountsLoading = () => {
  return {
      type: MODAL_DISCOUNT_LOADING
  }
} 

// Remove all discounts
export const clearDiscounts = () => dispatch => {
  dispatch({
      type: CLEAR_DISCOUNTS
  });
}


// Remove detail discount
export const clearDiscount = () => dispatch => {
  dispatch({
      type: CLEAR_DISCOUNT
  });
}

// Remove Discount from modal
export const clearModalDiscount = () => dispatch => {
  dispatch({
      type: CLEAR_MODAL_DISCOUNT
  });
}

export const setModalDiscount = (detailDiscount) => dispatch => {
    dispatch({
        type: SET_MODAL_DISCOUNT,
        payload: detailDiscount
    });
}

// Get All Discounts from the given list and add them to the discounts state
export const get_All_Discounts_From_CollectionList = (collectionList, currentDiscounts) => async dispatch => {
  
    console.log('ENTER GET COLLECTION DISCOUNTS');
  
    try {

        const discount_list = await get_All_Discounts_From_CollectionList_helper(collectionList, currentDiscounts);

       // Dispatch an action of type GET_AVAILABLE_DISCOUNTS with the discountDocuments as the payload
       dispatch({
           type: GET_AVAILABLE_DISCOUNTS,
           payload: discount_list
       });
  
    } catch (err) {
      
      console.log('ERROR!!!')
      console.log(err);

      // Dispatch an action of type DISCOUNT_ERROR with an error message and status code as the payload

      dispatch({
        type: DISCOUNT_ERROR,
        payload: { msg: "Something went wrong", status: err.status || 500 },
      });
    }
};

// -- Functions for Global Use ---

// Get All Discounts from the given list and add them to the discounts state
export const get_All_Discounts_From_CollectionList_helper = async (collectionList, currentDiscounts) => {
  
    console.log('ENTER HELPING GET COLLECTION DISCOUNTS');
  
    try {
  
        console.log('2 HELPING GETT COLLECTION DISCOUNTS')

        let discountIDSet = new Set(); // use a Set to store unique discountID values.

        // Add current discount IDs to the set
        currentDiscounts.forEach(discount_obj => {
            if (discount_obj._id) {
                discountIDSet.add(discount_obj._id);
            }
        });

        // Loop through collectionList to add discount IDs to the set
        collectionList.forEach(collection_obj => {
            if (collection_obj.discounts) {
                collection_obj.discounts.forEach(discountID => {
                    if (discountID) {
                        discountIDSet.add(discountID);
                    }
                });
            }
        });

        // Convert the set to an array and return
        const new_DiscountID_List = Array.from(discountIDSet);

        console.log('LIST OF DISCOUNT IDS FROM COLLECTIONS');
        console.log(new_DiscountID_List);

        // -- Get Discount documents

       // Fetch the discount documents using the discount IDs
        let discountDocuments = [];
        const discountPromises = new_DiscountID_List.map(async discountID => {
            try {
                console.log('FETCHING DISCOUNT ID DOCUMENT');
                const discountRef = doc(db, 'discounts', discountID)

                // Retrieve the document with the specified ID from the "discounts" collection
                const snapshot = await getDoc(discountRef);

                if (snapshot.exists()) {
                    console.log('PUSHING DISCOUNT DOC');
                    discountDocuments.push({_id: snapshot.id, ...snapshot.data()});
                } else {
                    console.log(`Discount document with ID ${discountID} does not exist.`);
                }

            } catch (error) {
                console.error(`Error fetching discount document with ID ${discountID}:`, error);
            }

        });

        await Promise.all(discountPromises); // Wait for all promises to resolve

        return discountDocuments;

       // -- END: Get Discount documents and add them to state
  
    } catch (err) {
      
      console.log('ERROR!!!')
      console.log(err);

      return [];
    }
};

export const handleToggle_DiscountDetails_Modal = (discount_obj) => async dispatch => {
  
    console.log('HANDLING TOGGLE OF DISCOUNT DETAILS MODAL');
  
    try {
  
        console.log('2 TOGGLING DISCOUNT DETAILS MODAL')

        if(discount_obj._id) {
            console.log(discount_obj);

            dispatch(getModalDiscountById(discount_obj._id));
            console.log('ROAD 1')
        } else {
            dispatch(setModalDiscount(null));
            console.log('ROAD 2')
        }

        dispatch(toggleModal_DiscountList_Preview());

       // -- END: Get Discount documents and add them to state
  
    } catch (err) {
      
      console.log('ERROR TOGGLING DISCOUNT DETAILS MODAL!!!')
      console.log(err);
    }
};