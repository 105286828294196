import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Firebase
import { db, auth, storage } from "../../../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { addToCart, decrement, removeItem } from '../../../actions/cartActions';

// Components
import { Button } from '@material-ui/core';
import Spinner from '../../../components/common/Spinner';
import Product_Card from '../ProductList/ProductCard/Product_Card';

// Icons

const Category_Widget = ({
    collectionId,
    cart,
    addToCart, 
    decrement, 
    removeItem
}) => {

    //  Collection List
    const [categoryItems, setCategoryItems] = useState(null);

    useEffect(() => {
        
        getItems();
    }, [collectionId]);


    const getItems = async () => {
        // --- Get Products In Collection ---

        const collectionRef = doc(db, 'categories', collectionId)
        const collectionItemsRef = collection(collectionRef, "items")

        console.log('FETCHING ITEMS');

        const itemsData = await getDocs(collectionItemsRef);
        const itemsList = itemsData.docs.map((doc) => ({...doc.data(), id: doc.id}));

        let product_list = [];
        // START loop
        for (const item of itemsList) {

            // Create a reference to the specified product document in the "products" collection
            const prodRef = doc(db, 'products', item.product_id);

            // Retrieve the document with the specified ID from the "products" collection
            const productDoc = await getDoc(prodRef);

            product_list.push({
                ...productDoc.data(), 
                _id: productDoc.id
            });
        }

        console.log('GOT PRODUCTS IN COLLECTION');
        console.log(product_list)

        setCategoryItems(product_list);

        // - END: Get Products In Collection ---
    }

    let productList;

    // Check if the array is null or not yet fetched
    if(categoryItems === null) {
        productList = <Spinner />;
    }
    else {
        if(categoryItems.length > 0) {

            console.log('IN CAT ITEMS FOR 0:')

            let default_columns;
            
            if((categoryItems.length % 2) === 0) {
                default_columns = 2;
            }  else {
                default_columns = 3;
            }
            
            const num_of_rows = new Array(Math.ceil(categoryItems.length / default_columns)).fill(0);

            // Iterate through the array and render each post component
            productList = num_of_rows.map((row, i) => {
                var set_of_items = categoryItems.slice( (i*default_columns), ( (i*default_columns) + default_columns ));

                return (
                    <div style={{width: '100%', display: 'grid', gridTemplateColumns: `repeat(${set_of_items.length}, 1fr)`, padding: '0 20px 0px', borderBottom: '1px solid #e6ecf0', boxSizing: 'border-box'}}>
                        {set_of_items.map((product_obj, index) => {

                            const is_set_of_1 = set_of_items.length === 1;

                            return (

                                <Category_Card 
                                    product_obj={product_obj} 
                                    cart={cart} 
                                    addToCart={addToCart} 
                                    decrement={decrement} 
                                    removeItem={removeItem} 
                                    index={index} 
                                    is_set_of_1={is_set_of_1} 
                                    set_of_items={set_of_items} 
                                />
                            )
                        })}
                    </div>
                )
                
            })
        }
        else {
            // Display a message when there are no posts
            productList = (
                <div className="no-rides">
                    <h1>No Items</h1>
                </div>
            );
        }
    }

    return (
        <Fragment>
            {productList}
        </Fragment>
    )
}

// Category Overview Header
const Category_Card = ({
    product_obj,
    cart,
    addToCart,
    decrement,
    removeItem,
    index,
    is_set_of_1,
    set_of_items
}) => {

    // Product Image Info
    const [productShowImg, setProductShowImg] = useState(null);
    const [gotProductImgs, setGotProductImgs] = useState(false);

    const [isInCart, setIsInCart] = useState(false);
    const [cartQty, setCartQty] = useState(0);

    const { items } = cart;

    useEffect(() => {
        
        if(!gotProductImgs && product_obj) {

           // Fetch post images
            getProductImgs()

            // Don't fetch imgs again after this
            setGotProductImgs(true);
        }
    }, [product_obj]);

    useEffect(() => {
        
        checkCartStatus()
    }, [cart]);

    // Function to get post images
    async function getProductImgs() {

        const docRef = doc(db, 'products', product_obj._id)
        const colRef = collection(docRef, "img_gallery")

        console.log('FETCHING IMGS');

        try {
            console.log('GALLERY DATA')

            const galleryData = await getDocs(colRef);
            const galleryList = galleryData.docs.map((doc) => ({...doc.data(), id: doc.id}));

            console.log(galleryList);

            for (const gallery_img of galleryList) {

                // --- ImageKit (img optimization service) ---
   
                // Replace the firebase URL with ImageKit.io URL-endpoint
                gallery_img.img_path = gallery_img.img_path.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
                
                if(gallery_img.img_order === 1) {
                    setProductShowImg(gallery_img);
                }
            }

            setGotProductImgs(true);

        } catch (err) {

            console.log('ERROR:');
            console.log(err);
        }
    }

    const checkCartStatus = () => {
        setIsInCart(false);

        if(items.length > 0) {
            items.map(item => {
                if(item.item?._id === product_obj?._id) {
                    setIsInCart(true);
                    setCartQty(item.qty);
                }
            })
        }
    }

    const handleAddToCart = () => {

        addToCart(product_obj._id, productShowImg.img_path)

    }

    const handleDecrement = () => {
        if(cartQty) {
            if(cartQty >= 2) {
                decrement(product_obj._id)
            } else {
                removeItem(product_obj._id)
            }
        }
    }

    let prod_link = "#";
    let prod_name = null;

    if(product_obj) {
        prod_link = `/details/${product_obj._id}`;
        prod_name = product_obj.name
    }

    return (
        <div style={{padding: '10px', borderRight: `${( (index+1) % set_of_items.length ) === 0 ? 'none' : '1px solid #e6ecf0'}`, height: '100%', boxSizing: 'border-box'}}>

            <a href={prod_link} style={{cursor: 'pointer', marginTop: '8px', textDecoration: 'none'}}>
                <div style={{width: `${is_set_of_1 ? '50%' : '100%'}`, margin: '0 auto'}}>
                    <div style={{borderTopRightRadius: '4px', borderTopLeftRadius: '4px', position: 'relative', width: '100%', overflow: 'hidden'}}>
                        {productShowImg && <img style={{aspectRatio: '1 / 1', objectFit: 'contain', width: '100%', height: '100%'}} src={productShowImg.img_path} alt="img" />}
                    </div>
                </div>

                <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                    <h1 className='line-clamp' style={{marginBottom: '3px', color: '#333', marginTop: '4px', fontSize: '16px', overflow: 'hidden'}}>
                        {product_obj.name}
                    </h1>
                    <div style={{lineHeight: '23.8px', fontWeight: 'bold', fontSize: '18px', color: '#AE0000'}}>
                        ${product_obj.price}
                    </div>
                    {/* <div style={{marginRight: '4px', lineHeight: '18.5px', fontSize: '14px', color: 'rgb(92, 92, 92)'}}>
                        52oz
                    </div> */}
                    
                </div>
            </a>

            {isInCart ? (
                <Button onClick={handleDecrement} className="sidebar__tweet danger" fullWidth>
                    Remove
                </Button>
                ) : (
                <Button onClick={handleAddToCart} className="sidebar__tweet black" fullWidth>
                    Add
                </Button>
            )}
            
        </div>
    )
}

Category_Widget.propTypes = {
    addToCart: PropTypes.func.isRequired,
    decrement: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { 
    addToCart, 
    decrement, 
    removeItem 
})(Category_Widget);