import { db, auth, storage } from '../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, orderBy, serverTimestamp, startAt } from 'firebase/firestore';
import { setAlert } from './alertActions';

// Reference to the "notifications" collection in Firestore
const verificationCollectionRef = collection(db, "verifications");
const emailCollectionRef = collection(db, "mail");

const formatFirebaseTimestamp = (date) => {
    var formattedDate = date.toISOString();
  
    // Replace "T" with a space
    formattedDate = formattedDate.replace("T", " ");
  
    // Remove milliseconds
    formattedDate = formattedDate.split(".")[0];
  
    return formattedDate;
}

// Action creator to set an alert
export const addVerificationCode = (campus_name) => async dispatch => {
    
    console.log('SENDING VERIFICATION CODE');

    // Generate a unique 4 digit code
    const randomCode = Math.floor(Math.random() * 9000 + 1000);

    // Reference to the user document in the Firestore database
    const userRef = doc(db, "users", auth.currentUser.uid);

    try {

        // Fetch the user document
        const userDoc = await getDoc(userRef);

        if (!userDoc) return;

        // Create a new verification document
        // Add the new notification document to the notifications collection
        await addDoc(verificationCollectionRef, {
            userId: auth.currentUser.uid,
            code: randomCode,
            date: Date.now(),
            createdAt: serverTimestamp()
        });

        console.log('ADDED VERIFICATION CODE');

        // --- Send email
        const docFields = {};
        
        docFields.date = Date.now();
        docFields.createdAt = serverTimestamp();

        
        docFields.to = userDoc.data().email;
            docFields.message = {
                subject: `${randomCode} is your MiiToken verification code`,
                html: `<div><h1 style="color:#333;font-size:20px">Confirm your email address</h1> <div><div style="color:#333333;margin-top:12px">Please enter this verification code to continue on MiiToken:</div><pre style="padding:16px 24px;border:1px solid #eeeeee;background-color:#f4f4f4;border-radius:3px;font-family:monospace;margin-bottom:24px">${randomCode}</pre></div> <div style="color:#aaaaaa;margin-top:12px">Verification codes expire after two hours.<div style="margin-top:12px">If you didn't try to login, you can safely ignore this email.</div></div> <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888"><div><a href="https://www.miitoken.com" style="color:inherit" target="_blank"><span class="il">MiiToken</span>.com</a>, the simplest way to buy groceries.</div></div></div>`,
            };

        console.log('EMAIL FIELDS')
        console.log(docFields);

        // Add the new email document to the mail collection
        await addDoc(emailCollectionRef, docFields);

        console.log('SENT VERIFICATION EMAIL');

        dispatch(setAlert('Email sent', 'okay'));
    } catch (err) {
        console.log('ERROR');
        console.log(err);

        // Dispatch an alert to notify that something went wrong
        dispatch(setAlert('Something went wrong', 'danger'));
    }
}

// Remove the verification document from firestore DB
export const removeVerificationCode = (id) => async dispatch => {

    // TODO: ADD CLOUD FUNCTION TO DELETE VERIFICATION DOC AFTER 2 HOURS
    
    // Create a reference to the chat document using the provided id
    const verificationRef = doc(db, 'verifications', id)
    
    try {

        // Delete the verification document
        await deleteDoc(verificationRef);  

    } catch (err) {
        console.log('ERROR');
        console.log(err);

        // Dispatch an alert to notify that something went wrong
        dispatch(setAlert('Something went wrong', 'danger'));
    }

}

// Action creator to set an alert
export const checkVerificationCode = (verifyCode) => async dispatch => {
    
    console.log('CHECKING VERIFICATION CODE');

    // Reference to the user document in the Firestore database
    const userRef = doc(db, "users", auth.currentUser.uid);
    
    try {

        // --- FETCH the users verification document ----

        // Get the current date and time
        let currentDate = new Date();
        
        // Subtract two hours
        currentDate.setHours(currentDate.getHours() - 2);
        
        // Format the date as a Firebase server timestamp
        const formattedDate = formatFirebaseTimestamp(currentDate);

        const verificationQuery = query(verificationCollectionRef, where("userId", "==", auth.currentUser.uid), where("code", "==", Number(verifyCode)), orderBy('date', 'desc'), startAt(formattedDate));

        // Retrieve the verifications that match the query
        const verifySnapshot = await getDocs(verificationQuery);

        // Map the retrieved chats to an array of chat objects with additional "_id" field
        const verifications = verifySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('FETCHED VERIFICATION DOCS');
        console.log(verifications);

        // Check if the randomly generated code field is equal to the user entered verifyCode
        // If so update the fetch and update the user doc, delete the verification document
        // and redirect to home 
        if(verifications[0]?.code === Number(verifyCode)) {
            // Update the userDoc to be verified
            await updateDoc(userRef, {
                verified: true,
                date: Date.now()
            })

            dispatch(removeVerificationCode(verifications[0]._id));

            console.log('SUCCESSFULLY VERIFIED');

            window.location.href = '/home';
        } else {
            dispatch(setAlert('Your code was incorrect. Please try again.', 'danger'));
        }

    } catch (err) {
        console.log('ERROR');
        console.log(err);

        // Dispatch an alert to notify that something went wrong
        dispatch(setAlert('Something went wrong', 'danger'));
    }
    
}