import React, { useState, useEffect } from 'react';

// Firebase
import { db } from '../../utils/firebase-config';
import { collection, getDocs, doc} from 'firebase/firestore';

const OrderItem = ({
    orderItem
}) => {

    const [postImgs, setPostImgs] = useState(null);
    const [gotPostImgs, setGotPostImgs] = useState(false);
    const [hasImg, setHasImg] = useState(false);

    // Trigger if post HAS imgs - for font size reasons
    useEffect(() => {
        handleHasImgState();
    }, [postImgs]);

    // For font size reasons
    const handleHasImgState = () => {
        if(postImgs && postImgs.length > 0) {
            
            setHasImg(true)
        }
    }

    // Function to get post images
    async function getPostImgs() {

        const docRef = doc(db, 'products', orderItem.item._id)
        const colRef = collection(docRef, "img_gallery")

        try {

            const galleryData = await getDocs(colRef);
            let galleryList = galleryData.docs.map((doc) => ({...doc.data(), _id: doc.id}));

            if(galleryList.length > 0) {
                galleryList = galleryList.sort((a, b) => a.img_order - b.img_order);
            }
            
            setPostImgs(galleryList)
            setGotPostImgs(true);
        } catch (err) {
            console.log('ERROR:');
            console.log(err);
        }
    }

    // Check if post images are not fetched yet and detailPost exists
    if(!gotPostImgs && orderItem) {

        // Fetch post images
        getPostImgs()

        // Don't fetch imgs again after this
        setGotPostImgs(true);
    }

    let imgInfo = [];

    if(postImgs && postImgs.length > 0) {
        postImgs.map(image => {
            imgInfo.push({
                img_path: image.img_path,
                width: image.img_width,
                height: image.img_height,
                img_order: image.img_order,
            })
        })
    } else {
        imgInfo = null;
    }

    return (
        <div className="orderItem" key={orderItem.item._id}>
            <div className="orderItem__info--container">
                <div className="orderItem__details">
                    <div className="orderItem__img">
                        {hasImg && <img style={{height: '100%'}} src={imgInfo[0].img_path} alt="img" />}
                    </div>
                    <div className="orderItem__info">
                        <p className="line-clamp-3">{orderItem.item.name}</p>
                        {/* <p>
                            <span>M 12</span>
                            <span>Grey</span>
                        </p> */}
                    </div>
                </div>
                
            </div>
            <div className="orderItem__totals">
                <p style={{margin:'0 1rem'}}>x</p>
                <p style={{margin:'0 1rem'}}>{orderItem.qty}</p>
                <p style={{margin:'0 1rem'}}>${orderItem.price}</p>
            </div>
        </div>
    )
}

export default OrderItem;
