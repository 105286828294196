import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

//  Actions - imported
import { editUserName, editUserBio } from '../actions/authActions';
import { setPage, toggleAuthModal, add_To_Recent_History } from '../actions/navActions';
import { getUserByUsername, getUserById } from '../actions/authActions';
import { getPostsByUserId, getUserPhotos, setPosts, getLikedPosts } from '../actions/postActions';
import { createChat } from '../actions/chatActions';

// wrapper layout
import Layout from '../components/layout/Layout';

// Modal
import Modal from '../components/modal/Modal';
import ModalContainer from '../components/modal/ModalContainer';

// Components
import { Button } from '@material-ui/core';
import Spinner from '../components/common/Spinner';
import ProfileHeader from '../components/Profile/ProfileHeader';
import Post from '../components/Post/Post';
import Image_Block from './MediaPage/Image_Block';

// Icons
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';

// Edit profile - page
import ProfileEdit from '../components/Profile/ProfileEdit';

const initialState = {
    first_name: '',
    last_name: '',
    bio: ''
};

const Profile = ({ 
    setPage,
    add_To_Recent_History,
    toggleAuthModal,
    auth: { 
        user, 
        modalUser, 
        loading 
    }, 
    post,
    nav: {
        page
    },
    getPostsByUserId, 
    getUserPhotos,
    getLikedPosts,
    setPosts,
    getUserById,
    createChat,
    getUserByUsername, 
    editUserName, 
    editUserBio,
    match,
    history
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Load correct user 
    const [gotModalUser, setGotModalUser] = useState(false);

    // Check if user is verified
    const [checkedVerification, setCheckedVerification] = useState(false);

    // Posts
    const [posts, setFeedPosts] = useState([]);
    const [gotPosts, setGotPosts] = useState(false);

    // Get Post Total 
    const [gotPostTotal, setGotPostTotal] = useState(false);
    const [totalPosts, setTotalPosts] = useState(0);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Inifite scroll
    const [lastPageDoc, setLastPageDoc] = useState(null);
    const [noMorePosts, setNoMorePosts] = useState(false);
    const [showBottomSpinner, setShowBottomSpinner] = useState(false)
    

    // Modal toggles
    const [messageModal, setMessageModal] = useState(false);

    // Get the "show" and "redirect" parameters from the URL
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("show");
    const showMessageModal = url.searchParams.get("redirect");

    // Firebase collection ref
    const postsCollectionRef = collection(db, "posts");

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {
        if(modalUser) {

            // Redirect to the user's profile if showMessageModal is true
            if(showMessageModal === 'true') {
                toggleMessageModal();

                history.push(`/${modalUser.username}`)
            }
        }

    }, [modalUser])

    useEffect(() => {

        // Set the current page to 'profile'
        setPage('profile');
        add_To_Recent_History(url.pathname);  // current url

        // Get the user by username from the route parameter
        if(match.params.username) {
            getUserByUsername(match.params.username);
        } else {
            if(!loading && user) {
                getUserByUsername(user.username);
            }
        }
    }, [match.params.username, user])

    useEffect(() => {
        setGotPosts(false);
        fetchUserPosts();

    }, [modalUser, filter]);

    // --- Profile TABS logic ---

    const fetchUserPosts = () => {

        if(modalUser) {
            if(filter) {

                // Load posts with photos if on the profile's 'photos' tab
                if(filter === 'photos') {

                    getUserPhotos(modalUser._id)
                    setGotPosts(true);
                } else if(filter === 'likes') {

                    getLikedPosts(modalUser._id)
                    setGotPosts(true);
                } else if(filter === 'replies') {

                    // Query posts collection to fetch posts by user ID
                    const q = query(postsCollectionRef, where("user._id", "==", modalUser._id), where("post_commented_on", "!=", null), orderBy('post_commented_on'), orderBy('lastModified', 'desc'), limit(10));

                    // Fetch initial posts
                    onSnapshot(q, async (snapshot) => {
                        console.log('UPDATING POSTS...');
                        const tempPostList = snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

                        // Recursively fetch comments of comments
                        const fetchCommentsOfComments = async (comments) => {
                            for (let i = 0; i < comments.length; i++) {
                            const comment = comments[i];
                            
                            // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
                            const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'asc'));
                            
                            // Execute the query and retrieve the query snapshot
                            const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
                            
                            // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                            const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                            
                            // Append the comments of comments to the current comment
                            comment.commentsOfComments = commentsOfComments;
                            
                            // Recursively fetch comments of comments for the current comment
                            await fetchCommentsOfComments(commentsOfComments);
                            }
                        };
                        
                        // Fetch comments of comments for the initial set of comments
                        await fetchCommentsOfComments(tempPostList);

                        setFeedPosts(tempPostList);
                        setPosts(tempPostList);
                        
                        setGotPosts(true);

                        // Get the last visible document for infinite scroll
                        setLastPageDoc(snapshot.docs[snapshot.docs.length - 1]);
                    })
                }
                
                
            } else {

                // Query posts collection to fetch posts by user ID
                const q = query(postsCollectionRef, where("user._id", "==", modalUser._id), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), limit(10));

                // Fetch initial posts
                onSnapshot(q, async (snapshot) => {
                    console.log('UPDATING POSTS...');
                    const tempPostList = snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

                    // Recursively fetch comments of comments
                    const fetchCommentsOfComments = async (comments) => {
                        for (let i = 0; i < comments.length; i++) {
                        const comment = comments[i];
                        
                        // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
                        const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'asc'));
                        
                        // Execute the query and retrieve the query snapshot
                        const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
                        
                        // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                        const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                        
                        // Append the comments of comments to the current comment
                        comment.commentsOfComments = commentsOfComments;
                        
                        // Recursively fetch comments of comments for the current comment
                        await fetchCommentsOfComments(commentsOfComments);
                        }
                    };
                    
                    // Fetch comments of comments for the initial set of comments
                    await fetchCommentsOfComments(tempPostList);

                    setFeedPosts(tempPostList);
                    setPosts(tempPostList);
                    
                    setGotPosts(true);

                    // Get the last visible document for infinite scroll
                    setLastPageDoc(snapshot.docs[snapshot.docs.length - 1]);
                })
            }

            // Update the user's total posts
            if(modalUser.totalPosts) {
                setTotalPosts(modalUser.totalPosts);
            }
        }
    }

    // --- END: Profile TABS ---

    // Message Logic

    const toggleMessageModal = () => {
        
        setMessageModal(!messageModal);
    }

    const continueMessage = () => {
        if(user) {
            createNewChat();
        } else {
            toggleMessageModal();
            toggleAuthModal('message');
        }
    }

    // Create a new chat between users
    const createNewChat = async () => {

        // Query existing chats between users
        const chatsCollectionRef = collection(db, "chats");

        // Check if chat with 2 users already exists by chat ID

        console.log('CHECK USER CHAT HERE')
        
        const chatsQuery = query(chatsCollectionRef, where("from_user._id", "==", user._id), where("to_user._id", "==", modalUser._id));
        const otherChatsQuery = query(chatsCollectionRef, where("to_user._id", "==", user._id), where("from_user._id", "==", modalUser._id));

        const chatsSnapshot = await getDocs(chatsQuery);
        const chats = chatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        const otherChatsSnapshot = await getDocs(otherChatsQuery);
        const otherChats = otherChatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Check if chats were retrieved in console (for testing)
        console.log('CHECKING CHATS DATA');
        console.log(chats);
        
        console.log('CHECKING OTHER CHATS DATA');
        console.log(otherChats);

        console.log('CHECK USER CHAT HERE PART 2')

        // If array length > 0 then there is already existing chat & redirect instead
      
        if(chats.length > 0) {
            history.push(`/messages/${chats[0]._id}`);
        } else if (otherChats.length > 0) {
            history.push(`/messages/${otherChats[0]._id}`);
        } else {
            createChat(modalUser, user, history);
        }

        setMessageModal(!messageModal);
        
        console.log('SHOULD BE DONE CREATING');
    }

    // End

    const toggleEdit = () => {
        // setEditMode(!editMode);
        window.location.href = "/profile/edit";
        // history.push('/profile/edit')
    }

    // const getPostTotal = async id => {
    //     const postTotal = await axios.get(`/api/posts/user/number-of-posts/${modalUser._id}`);

    //     setTotalPosts(postTotal.data);

    //     setGotPostTotal(true);
    // }

    // if(!loading && user && !gotUser) {
    //     getUserByUsername(match.params.username);
    //     setGotUser(true);
    // }

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more posts are met
        if (newScrollY >= scrollHeight - 1 && !showBottomSpinner && !noMorePosts) {
            console.log('scrolling');
            loadMore();
        }
    }

    const loadMore = () => {
        console.log("last", lastPageDoc);

        setShowBottomSpinner(true);
        
        // Construct a new query starting at the last document fetched,
        // and fetch the next set of posts.
        const nextQuery = query(postsCollectionRef, where("user._id", "==", modalUser._id), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(3));

        // Fetch the next set of posts
        onSnapshot(nextQuery, async (snapshot) => {
            // If there are no more documents in the database to load
            if(snapshot.docs.length < 2) {
                setNoMorePosts(true);
            } else {

                console.log('UPDATING POSTS...');
                
                const tempPostList = [...posts, ...snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}))];

                // Recursively fetch comments of comments
                const fetchCommentsOfComments = async (comments) => {
                    for (let i = 0; i < comments.length; i++) {
                    const comment = comments[i];
                    
                    // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
                    const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'asc'));
                    
                    // Execute the query and retrieve the query snapshot
                    const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
                    
                    // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                    const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                    
                    // Append the comments of comments to the current comment
                    comment.commentsOfComments = commentsOfComments;
                    
                    // Recursively fetch comments of comments for the current comment
                    await fetchCommentsOfComments(commentsOfComments);
                    }
                };
                
                // Fetch comments of comments for the initial set of comments
                await fetchCommentsOfComments(tempPostList);

                // Update the posts state with the new posts
                setFeedPosts(tempPostList);
    
                // Get the last visible document for the next load
                setLastPageDoc(snapshot.docs[snapshot.docs.length - 1]);
    
                // Hide the loading spinner
                setShowBottomSpinner(false);
            }

        })
    }

    // if(modalUser && !gotModalUser) {
    //     if(filter) {
    //         if(filter === 'photos') {
    //             getUserPhotos(modalUser._id)
    //         }
            
    //     } else {
    //         getPostsByUserId(modalUser._id);
    //     } 
        
    //     // getPostTotal(modalUser._id)
    //     setGotModalUser(true);
    // }

    // Check if user is verified. If no redirect to verify page
    if(user && !user.verified && !checkedVerification) {
        window.location.href = '/verify';

        setCheckedVerification(true);
    }

    let postList;

    // Choose where to get source of posts data
    let postListRef;

    if(filter) {
        if(filter === 'photos' || filter === 'likes' || filter === 'replies') {
            postListRef = post.posts;
        }
    } else {
        postListRef = posts;
    }
  
    if(postListRef === null || !gotPosts) {
        postList = <Spinner />;
    }
    else {
        if(postListRef.length > 0) {
            // Iterate through the posts array and render each post component
            postList = postListRef.map(post => {

                if (filter === 'photos') {
                    return (

                        <Image_Block key={post._id} detailPost={post} />
                    )
                } else {
                    const renderPostRecursive = (postComments, parentPost) => {
                    
                        const lastComment = postComments[postComments.length - 1]; // Get the last comment
    
                        return (
                            <Fragment>
                                <Post key={lastComment._id} postDoc={lastComment} parentPost={parentPost} />
    
                                {/* Recursive call to render comments of comments */}
                                {lastComment.commentsOfComments && lastComment.commentsOfComments.length > 0 && (
                                    <div className="comments-of-comments">
                                    {renderPostRecursive(lastComment.commentsOfComments, lastComment)}
                                    </div>
                                )}
                            </Fragment>
                        );
    
                    };
    
                    if(post.commentsOfComments && post.commentsOfComments.length > 0) {
                        return (
                            <div className='post_container'>
                                <Post key={post._id} postDoc={post} />
    
                                {renderPostRecursive(post.commentsOfComments, post)}
                            </div>
                        )
                    } else {
                        return (
                            <Post key={post._id} postDoc={post} />
                        )
                    }
                }
                      

                    // return (
                    //     <Post key={post._id} post={post} />
                    // )

            })
        }
        else {
            postList = (
                <div className="no-rides">
                    <h1>No Posts</h1>
                </div>
            );
        }
    }

    // Initialize Mixpanel and track Page View
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Profile View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <Fragment>
            <Layout handleScroll={handleScroll} page="profile" user={modalUser} totalPosts={totalPosts}>

                {!modalUser ? (
                    <div className="no-post">
                        <h1>This page doesn’t exist. </h1>
                        <p>Try searching for something else.</p>
                        <Button  
                            onClick={() => window.location ='/search'}
                            className="noChats__btn"
                        >
                            Search
                        </Button>
                    </div>
                ) : (
                    <Fragment>
                        {/* Render the profile header */}
                        <ProfileHeader toggleMessageModal={toggleMessageModal} toggleEdit={toggleEdit} />
                        
                        {/* Render the post list */}
                        {filter === 'photos' ? (
                            <div style={{width: '100%', height: 'fit-content', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'min-content', borderBottom: '1px solid #ddd'}}>
                                
                                {postList}
                            </div>
                        ) : postList}

                        {/* Show a spinner at the bottom if more posts are being loaded */}
                        {showBottomSpinner && !noMorePosts && <Spinner />}
                        
                        {/* Render a message when there are no more posts to load */}
                        <div className="no-rides">{(noMorePosts && postListRef.length !== 0) && "You've reached the end."}</div>
                        
                        {/* Render a button to create a new post for tablet devices */}
                        {/* {!loading && user ? (
                            isTablet && (
                                <Link to={`/create/post?goBack=${page}`} id="fixed-button" className="show"><AddIcon /></Link>
                            )
                        ) : null} */}
                    </Fragment>
                )}

            </Layout>

            {/* Start a Conversation modal */}
            <ModalContainer show={messageModal} onClose={toggleMessageModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Start Conversation?</h2>
                    </div>

                    {/* Modal Text */}
                    <p>Start a private conversation with <b style={{color: '#333'}}>{modalUser ? `${modalUser.first_name}${modalUser.last_name && ' ' + modalUser.last_name}` : 'this person'}</b>.</p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={continueMessage}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Start Conversation
                    </Button>
                    <Button 
                        onClick={toggleMessageModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

Profile.propTypes = {
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    getUserByUsername: PropTypes.func.isRequired,
    getPostsByUserId: PropTypes.func.isRequired,
    getUserPhotos: PropTypes.func.isRequired,
    getLikedPosts: PropTypes.func.isRequired,
    setPosts: PropTypes.func.isRequired,
    getUserById: PropTypes.func.isRequired,
    createChat: PropTypes.func.isRequired,
    editUserName: PropTypes.func.isRequired, 
    editUserBio: PropTypes.func.isRequired, 
    auth: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    post: state.post,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage,
    add_To_Recent_History,
    toggleAuthModal,
    getUserByUsername,
    getPostsByUserId,
    getUserPhotos,
    getLikedPosts,
    setPosts,
    getUserById,
    createChat,
    editUserName, 
    editUserBio,
})(Profile);
