import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Firebase
import { db } from '../../../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Google Maps API
import { useLoadScript, DistanceMatrixService } from '@react-google-maps/api';

import { format, parseISO, fromUnixTime, formatDistanceToNow, isToday, isTomorrow } from 'date-fns';

// Redux
import { connect } from 'react-redux';

// Actions
import { addToCart, decrement, removeItem } from '../../../actions/cartActions';

// Icons
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const LocationBlock = ({
    locationState: {
        detailLocation
    },
    cart,
    location_obj,
    detailProduct,
    productImgs,
    addToCart, 
    decrement, 
    removeItem
}) => {

    const [itemQty, setItemQty] = useState(0);
    const [itemPrice, setItemPrice] = useState("");

    const [locationName, setLocationName] = useState(null);
    const [locationCoordinates, setLocationCoordinates] = useState(null);
    const [deliveryTime, setDeliveryTime] = useState(null);
    const [deliveryDistance, setDeliveryDistance] = useState(null);
    const [locationHours, setLocationHours] = useState(null);
    const [locationTax, setLocationTax] = useState(null);

    // Cart Value
    const [isInCart, setIsInCart] = useState(false);
    const [cartQty, setCartQty] = useState(0);

    const { items, qty } = cart;

    // Google Maps API
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"]
    });

    useEffect(() => {

        fetchLocationData();
        
        if(location_obj.price) {
            setItemPrice(location_obj.price)
        } else {
            setItemPrice(detailProduct.price)
        }

        if(location_obj.inventory_qty) {
            setItemQty(location_obj.inventory_qty)
        } else {
            setItemQty(detailProduct.inventory_qty)
        }
    }, [location_obj]);

    useEffect(() => {
        
        checkCartStatus()
    }, [cart]);

    // -- Get the Delivery Time estimate
    useEffect(() => {

        // Create a callback function to handle Distance Matrix Service response
        const callback = (response) => {
            console.log('DRIVING DISTANCE MATRIX');
            console.log(response);
            console.log('MATRIX TIME: ', response.rows[0].elements[0].duration.text);
            console.log('MATRIX DISTANCE: ', response.rows[0].elements[0].distance.text);
            
            setDeliveryDistance(response.rows[0].elements[0].distance.text)

            const deliveryTime_in_seconds = response.rows[0].elements[0].duration.value

            // Get the current time
            var currentTime = new Date();

            // Add 195 seconds to the current time
            currentTime.setSeconds(currentTime.getSeconds() + deliveryTime_in_seconds);

            // Now, `currentTime` holds the time after adding 195 seconds
            console.log("Time after adding seconds:", currentTime);

            const today_or_tomo = getTodayOrTomorrowString(currentTime);
            const exact_time = format(currentTime, "h:mm a");
            const display_time = today_or_tomo + " " + exact_time;

            console.log("Time to display:", display_time);

            setDeliveryTime(display_time)
        };

        if(isLoaded && detailLocation && locationCoordinates) {
        
            // Create a new DistanceMatrixService instance
            const distanceMatrixService = new window.google.maps.DistanceMatrixService();
        
            // Call the Distance Matrix API
            distanceMatrixService.getDistanceMatrix(
            {
                destinations: [detailLocation.coordinates],
                // Measure delivery time based on the time it takes to get to the center of the OU campus (origin based on GMaps data)
                origins: [{lng:locationCoordinates.lng, lat:locationCoordinates.lat}], 
                travelMode: "DRIVING",
            },
                callback
            );
        }
    }, [isLoaded, detailLocation, locationCoordinates]);

    const checkCartStatus = () => {
        setIsInCart(false);

        if(items.length > 0) {
            items.map(item => {
                if(item.item._id === location_obj.product_id && item.locationID === location_obj._id) {
                    setIsInCart(true);
                    setCartQty(item.qty);
                }
            })
        }
    }

    const fetchLocationData = async () => {
        try {
            // Create a reference to the specified post document in the "posts" collection
            const docRef = doc(db, 'locations', location_obj._id)

            // Retrieve the document with the specified ID from the "posts" collection
            const locationDoc = await getDoc(docRef);
    
            console.log('ITEM DETAIL LOCATION DATA');
            console.log(locationDoc.data())

            setLocationName(locationDoc.data().name)
            setLocationCoordinates(locationDoc.data().location_details.coordinates)
            
            if(locationDoc.data().open_time && locationDoc.data().close_time) {
                setLocationHours([locationDoc.data().open_time, locationDoc.data().close_time])
            }

            if(locationDoc.data().tax_amt) {
                setLocationTax(locationDoc.data().tax_amt)
            }

        } catch (err) {
            
            // Log the error to the console
            console.log(err);
        }
    }

    // Decrement cart value
    const handleDecrement = () => {
        if(cartQty) {
            if(cartQty >= 2) {
                decrement(location_obj.product_id)
            } else {
                removeItem(location_obj.product_id)
            }
        }
    }


    function getTodayOrTomorrowString(date) {
        if (isToday(date)) {
            return 'Today';
        } else if (isTomorrow(date)) {
            return 'Tomorrow';
        } else {
            return format(date, 'MMMM do, yyyy'); // Format the date normally if neither today nor tomorrow
        }
    }

    function formatAsDollarValue(number) {
        console.log('NUMBER TO CONVERT: ', number)
        if (typeof number !== 'number') {
            return number
        }
    
        const dollars = Math.floor(number);
        const cents = Math.round((number - dollars) * 100).toString().padStart(2, '0');

        console.log('DOLLARS: ', dollars);
        console.log('CENTS: ', cents);
        console.log(Number(dollars + '.' + cents).toFixed(2));
        return Number(dollars + '.' + cents).toFixed(2);
    }

  return (
        <div style={{width: '100%', padding: '5px', display:'flex', justifyContent:'space-between', alignItems:'center',  height:'fit-content', borderBottom: '1px solid #e6ecf0'}}>
            <div style={{display:'flex', alignItems:'center', flex: 1}}>
                <a style={{cursor:'pointer', textDecoration:'none', wordWrap:'break-word'}}>
                    <div style={{marginRight:'24px', padding:'0 4px', display:'flex', alignItems:'center'}}>
                        <div style={{paddingRight:'32px', display:'flex', flexDirection:'column', justifyContent:'space-between', paddingLeft:'8px'}}>
                            <div className='line-clamp' style={{marginBottom:'4px', lineHeight: '26.8px', overflow:'hidden' , fontWeight: 'bold', fontSize: '24px', color: '#AE0000', maxWidth:'100%'}}>
                                ${location_obj?.price ? location_obj.price : detailProduct.price}
                            </div>
                            {locationTax && (
                                <span style={{fontSize:'12px', color:'rgb(92, 92, 92)', lineHeight:'15.8px'}}>
                                    +${location_obj?.price ? formatAsDollarValue(location_obj.price*(locationTax/100)) : formatAsDollarValue(detailProduct.price*(locationTax/100))} est. tax
                                </span>
                            )}
                            <span style={{fontSize:'12px', color:'rgb(92, 92, 92)', lineHeight:'15.8px'}}>
                                {deliveryDistance && deliveryDistance + ' - '}
                                {itemQty > 0 ? (
                                    <span style={{color: 'rgb(29, 155, 240)', fontWeight: '600'}}>In stock</span>
                                ) : (
                                    <span style={{color: '#ff4b2b', fontWeight: '600'}}>Out of stock</span>
                                )}
                            </span>
                            {locationHours && (
                                <span style={{fontSize:'12px', color:'rgb(92, 92, 92)', lineHeight:'15.8px'}}>
                                    Open: {locationHours[0]} - {locationHours[1]}
                                </span>
                            )}
                            {deliveryTime && (
                                <span style={{fontSize:'12px', color:'rgb(92, 92, 92)', lineHeight:'15.8px'}}>
                                    Delivery by <span style={{color: 'rgb(49 159 41)', fontWeight: '600'}}>{deliveryTime}</span>
                                </span>
                            )}
                            
                            <span style={{marginTop:'4px', fontWeight: '600', fontSize:'13px', color:'#000', lineHeight:'15.8px'}}>
                                {locationName}
                            </span>
                        </div>
                    </div>
                </a>
            </div>
            <div>
            <Fragment>
                    {!isInCart ? (
                        <div style={{width: '120px'}}>
                            <div onClick={() => addToCart(location_obj.product_id, productImgs[0].img_path, location_obj._id)} style={{backgroundColor: 'rgb(0, 164, 255)', borderColor: 'rgb(0, 164, 255)', minWidth: '88px', minHeight: '48px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                                <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                                    Add to Bag
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{width: '120px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'64px', height:'48px', overflow:'hidden', position:'relative', flex: '1'}}>
                                <div style={{display:'flex', cursor:'pointer', alignItems:'center', padding:'2px', boxSizing:'border-box', background:'rgb(236, 238, 233)', borderRadius:'64px', height:'100%', position:'absolute', width:'100%'}}>
                                    <div onClick={handleDecrement} style={{borderRadius:'19px', height:'38px', width:'38px', background:'#fff', cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <RemoveIcon />
                                    </div>
                                    <div style={{alignItems:'center', justifyContent:'center', flex:1, display:'flex'}}>
                                        <div style={{color:'rgb(0, 0, 0)', lineHeight:'23.8px', fontWeight: 'bold', fontSize: '18px', }}>
                                            {cartQty}
                                        </div>
                                    </div>
                                    <div onClick={() => addToCart(location_obj.product_id, productImgs[0].img_path, location_obj._id)} style={{borderRadius:'19px', height:'38px', width:'38px', color: '#fff', background:'rgba(0,164,255,1.00)', cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <AddIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Fragment>
            </div>
        </div>
  )
}

LocationBlock.propTypes = {
    locationState: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    addToCart: PropTypes.func.isRequired,
    decrement: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    locationState: state.location,
    cart: state.cart.cart
})

export default connect(mapStateToProps, { 
    addToCart, 
    decrement, 
    removeItem
})(LocationBlock);