module.exports = function Cart(oldCart) {
    this.items = oldCart.items || {};
    this.totalQty = oldCart.totalQty || 0;
    this.totalPrice = oldCart.totalPrice || 0;
    this.discounts = oldCart.discounts || {};
    this.discountPrice = oldCart.discountPrice || 0;

    this.add = (item, id, imageURL, locationID) => {
        let storedItem = this.items[id];
        if (!storedItem) {
            storedItem = this.items[id] = {item: item, qty: 0, price: 0, imageURL: imageURL, locationID: locationID};
        }
        storedItem.qty++;
        storedItem.price = Number(storedItem.item.price) * Number(storedItem.qty);
        this.totalQty++;

        const newPrice = this.totalPrice + parseFloat(Number(storedItem.item.price).toFixed(2));
        
        this.totalPrice = parseFloat(newPrice.toFixed(2));
    };

    this.reduceByOne = (id) => {
        this.items[id].qty--;
        this.items[id].price -= this.items[id].item.price;
        this.totalQty--;

        let itemPrice = Number(this.items[id].item.price);

        const newPrice = this.totalPrice - parseFloat(itemPrice.toFixed(2));

        if(newPrice >= 0) {
            this.totalPrice = parseFloat(newPrice.toFixed(2));
        } else {
            this.totalPrice = 0;
        }
        

        if (this.items[id].qty <= 0) {
            delete this.items[id];
        }
    };

    this.removeItem = (id) => {
        this.totalQty -= this.items[id].qty;

        let itemPrice = Number(this.items[id].price);

        const newPrice = this.totalPrice - parseFloat(itemPrice.toFixed(2));

        if(newPrice >= 0) {
            this.totalPrice = parseFloat(newPrice.toFixed(2));
        } else {
            this.totalPrice = 0;
        }

        delete this.items[id];
    };

    // this.addTip = (tipAmount) => {

    //     this.driverTip = tipAmount;

    //     const newPrice = this.totalPrice + tipAmount;

    //     if(newPrice >= 0) {
    //         this.totalPrice = parseFloat(newPrice.toFixed(2));
    //     } else {
    //         this.totalPrice = 0;
    //     }

    // };

    this.addTotals = () => {

        let subTotal = 0;

        for (const id in this.items) {
            subTotal += this.items[id].price
        }

        let parsedTotal = parseFloat(subTotal.toFixed(2));
        
        this.totalPrice = parsedTotal;

        for (const discountID in this.discounts) {
            const tempDiscount = this.discounts[discountID];
            if(tempDiscount.discount_type === 'amount off order' && tempDiscount.type_of_value === 'fixed amount') {
                parsedTotal -= tempDiscount.discount_value
            }
        }

        if(parsedTotal >= 0) {
            this.discountPrice = parseFloat(parsedTotal.toFixed(2));
        } else {
            this.discountPrice = 0;
        }

    }

    this.generateArray = () => {
        const arr = [];
        for (const id in this.items) {
            arr.push(this.items[id]);
        }
        return arr;
    };

    // --  Discounts -- 

    this.add_discount = (discount, discountID) => {
        let stored_discount = this.discounts[discountID];
        if (!stored_discount) {
            stored_discount = this.discounts[discountID] = discount;
        }
    };

    this.removeDiscount = (discountID) => {

        delete this.discounts[discountID];
    };
};