import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';

import mixpanel from 'mixpanel-browser';

// Google Maps API
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

// Redux
import { connect } from 'react-redux';

// Layout Wrapper
import CartLayout from '../components/layout/CartLayout';

// Actions
import { toggleLocationModal, add_To_Recent_History } from '../actions/navActions';
import { setAlert } from '../actions/alertActions';
import { getZones, setActiveZoneById } from '../actions/zoneActions';

// Models
import ModalContainer from '../components/modal/ModalContainer';
import DiscountModal from '../components/modal/DiscountModal';
import Modal from '../components/modal/Modal';

// components - material UI
import { Button } from '@material-ui/core';
import Spinner from '../components/common/Spinner';

// icons - material UI
import InstagramIcon from '@material-ui/icons/Instagram';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';

const initialState = {
    // Address Location Data
    city: '',
    state: '',
    country: '',
    area: '',
    stateProvince: '',
    street_number: '',
    formatted_address: '',
    street_name: '',
    postalCode: '',
    placeId: '',
    latLng: '',
};


const initialCoordinates = {
    lat: null, 
    lng: null
};

const Select_City_Page = ({ 
    getZones,
    setActiveZoneById,
    nav: {
        locationModal
    },
    zone: {
        zones,
        detailZone
    },
    toggleLocationModal,
    setAlert,
    add_To_Recent_History
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    // List of current addresses
    const [zoneList, setZoneList] = useState([]);
    const [gotZoneList, setGotZoneList] = useState(false);

    // --- Address Location stuff

    const [addNewAddress, setAddNewAddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(false);
    const [address, setAddress] = useState('');
    const [addressData, setAddressData] = useState(initialState);
    const [coordinates, setCoordinates] = useState(initialCoordinates);
    const [roomNumber, setRoomNumber] = useState('');

    const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false);

    // --- END: Address Location stuff

    // Modals
    const [modal_Confirmation, setModal_Confirmation] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    const [noStoresAvailable, setNoStoresAvailable] = useState(false);

    // Google Maps API
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"]
    });

    // Extract URL and previous URL page
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    let previousUrl = url.searchParams.get("goBack");

    useEffect(() => {

         // Add event listener for window resize
         window.addEventListener('resize', () => handleWindowSizeChange());

         add_To_Recent_History(url.pathname);  // current url

         if(locationModal) toggleLocationModal();

         // Cleanup the event listener when the component unmounts
         return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        getZones();
    }, []);

    useEffect(() => {

        renderZoneList();
    }, [zones]);

    useEffect(() => {

        if(selectedCity) setModal_Confirmation(true);
    }, [selectedCity]);

    // Function to handle apt/suit number change
    const on_Room_Num_Change = e => setRoomNumber(e.target.value);

    const onAddressInputChange = e => {
        
        setSelectedAddress(false);
    };

    const toggleModal_Confirmation = () => {
        if(modal_Confirmation) {
            setSelectedCity(null);
        }

        setModal_Confirmation(!modal_Confirmation);
    }

    const resetPage = () => {
        
        // setAddNewAddress(false);
        setSelectedAddress(false);
        setAddress('');
        setAddressData(initialState);
        setCoordinates(initialCoordinates);
    }

    const handleLocationSelect = async (value) => {

        // TODO: Add try catch block w/ error handling for if location not found -> when user clicks enter on bad address text
        const result = await geocodeByAddress(value);
        const latLng = await getLatLng(result[0])
        // END: TODO 

        console.log('VALUE:');
        console.log(value);
        console.log('RESULTS:')
        console.log(result);
        console.log('LATLNG');
        console.log(latLng);
    
        let locationTags = [];
    
        if(result[0].types && result[0].types.length > 0) {
            result[0].types.map(type => locationTags.push(type));
        };

        const address = result[0].formatted_address;
        const placeId = result[0].place_id;
        const addressArray =  result[0].address_components;
        const city = getCity(addressArray);
        const country = getCountry(addressArray);
        const area = getArea(addressArray);
        const state = getState(addressArray);
        const postalCode = getPostalCode(addressArray);
        const street = getStreet(addressArray);
        const number = getNumber(addressArray);
    
    
        console.log('city: ' + city);
        console.log('state: ' + state);
        console.log('country: ' + country);
        console.log('area: ' + area);
        console.log('state: ' + state);
        console.log('number: ' + number);
        console.log('street: ' + street);
        console.log('postalCode: ' + postalCode);
        console.log("formatted address: " + address);
        console.log("placeId: " + placeId);

        if(city.toLowerCase() === 'norman') {
            setAddress(value);
            setAddressData({
                ...addressData,
                city: (city) ? city : '',
                state: (state) ? state : '',
                country: (country) ? country : '',
                area: (area) ? area : '',
                stateProvince: (state) ? state : '',
                street_number: (number) ? number : '',
                formatted_address: (address) ? address : '',
                street_name: (street) ? street : '',
                postalCode: (postalCode) ? postalCode : '',
                placeId: (placeId) ? placeId : '',
                latLng: (latLng) ? latLng : {},
                location_tags: (locationTags) ? locationTags : []
            })

            setCoordinates(latLng);
            setSelectedAddress(true);

            setSelectedCity(zones[0]);
        } else {
            setAlert("No stores in your area", 'danger');
            setNoStoresAvailable(true);
        }

        setLoadingCurrentLocation(false);
    };

    const getCity = ( addressArray ) => {
        let city = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0] && 'locality' === addressArray[ i ].types[0] ) {
                city = addressArray[ i ].long_name;
                return city;
            }
        }
    };
  
    const getArea = ( addressArray ) => {
        let area = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0]  ) {
                for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
                    if ( 'administrative_area_level_2' === addressArray[ i ].types[j] ) {
                        area = addressArray[ i ].long_name;
                        return area;
                    }
                }
            }
        }
    };
  
    const getCountry = ( addressArray ) => {
        let area = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0]  ) {
                for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
                    if ( 'country' === addressArray[ i ].types[j] ) {
                        area = addressArray[ i ].long_name;
                        return area;
                    }
                }
            }
        }
    };
  
    const getPostalCode = ( addressArray ) => {
        let area = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0]  ) {
                for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
                    if ( 'postal_code' === addressArray[ i ].types[j] ) {
                        area = addressArray[ i ].long_name;
                        return area;
                    }
                }
            }
        }
    };

    const getState = ( addressArray ) => {
        let state = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'administrative_area_level_1' === addressArray[ i ].types[0] ) {
                    state = addressArray[ i ].long_name;
                    return state;
                }
            }
        }
    };
  
    const getNumber = ( addressArray ) => {
        let state = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'street_number' === addressArray[ i ].types[0] ) {
                    state = addressArray[ i ].long_name;
                    return state;
                }
            }
        }
    };
  
    const getStreet = ( addressArray ) => {
        let state = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'route' === addressArray[ i ].types[0] ) {
                    state = addressArray[ i ].long_name;
                    return state;
                }
            }
        }
    };
    

    const handleSwitchZone = () => {

        if(selectedCity) {
            setActiveZoneById(selectedCity._id);
            toggleModal_Confirmation();
        }

    };

    const toggleModal_LoadingCurrentLocation = () => {
        setLoadingCurrentLocation(!loadingCurrentLocation);
    }
    
    const renderZoneList = () => {
        setZoneList([]);

        if(zones === null) {
            setZoneList([(
                <Spinner />
            )])
        }
        else { 
            if(zones.length > 0) {

                zones.map(zone_item => {
                    setZoneList(zoneList => [...zoneList, (
                        <div onClick={() => setSelectedCity(zone_item)} className='store_zone_block'>
                            {zone_item.display_img && (
                                <div className='store_zone_block-image'>
                                    <img src={zone_item.display_img} />
                                </div>
                            )}

                            <div className='store_zone_block-text'>
                                <div style={{fontSize: '20px', fontWeight: 500}}>
                                    {zone_item.zone_name}
                                </div>

                                {detailZone?._id === zone_item._id && (
                                    <div className='icon_16' style={{fontSize: '13px', fontWeight: 500, borderRadius: '40px', width: 'fit-content', marginTop: '5px', padding: '3px 10px', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#0a8800', boxSizing: 'border-box'}}>

                                        <CheckIcon />

                                        <span style={{margin: '0 0 0 2px'}}>
                                            Active
                                        </span>
                                        
                                    </div>
                                )}
                            </div>
                        </div>
                    )])
                });

            }
        } 

        setGotZoneList(true);
    }

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Select City Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    // -- Get current location logic --
    
    const success = async (pos) => {
        const lat = pos.coords.latitude;
        const lng = pos.coords.longitude;

        console.log(`YOUR CURRENT POSTION: latitude: ${lat}, longitude: ${lng}`)

        try {
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
            );
            
            console.log('GEOCODE RESPONSE!!')
            console.log(response)

            handleLocationSelect(response.data.results[0].formatted_address)

          } catch (error) {

            console.error('Error fetching address from google api: ', error);
          }
    }

    const error = (err) => {
        console.log('GEOLOCATION ERROR - FAILED')
        console.log(err);
    }

    const options = {};


    const getCurrentLocation = () => {
        console.log('GEO LOCATION CALLED')
        if(navigator.geolocation) {
            setLoadingCurrentLocation(true);
            navigator.geolocation.getCurrentPosition(success, error, options)
        } else {
            alert("Location services not supported by this browser.")
        }
    }

    // -- END: Get current location logic --

    return (
        <Fragment>

            <CartLayout page="select city">

                {/* Modal Content */}
                <div className="location-modal select_city_zone">
                    {!zones.length > 0 ? (
                        <div className='no-rides'>No Stores Available</div>
                    ) : (
                        <Fragment>
                            <div style={{width: '100%', fontSize: '18px', fontWeight: 500, textAlign: 'center', marginBottom: '10px', padding: '10px'}}>
                                Where would you like to shop?
                            </div>

                            {isLoaded && (
                                <Fragment>
                                    <div onClick={() => getCurrentLocation()} className='store_zone_block'>
                                        <div className='store_zone_block-image'>
                                            <div style={{fontSize:'24px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <NotListedLocationIcon />
                                            </div>
                                        </div>
                                        <div className='store_zone_block-text'>
                                            <div style={{fontSize: '24px', fontWeight: 'bold'}}>
                                                Closest Store
                                            </div>
                                            <div style={{fontSize: '14px', fontWeight: 500, padding: '5px 5px 5px 0'}}>
                                                Automatically pick the Miitoken store nearest to you
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{width: '100%', fontSize: '22px', fontWeight: 500, textAlign: 'center', marginBottom: '10px', padding: '10px'}}>
                                        Or manually select store:
                                    </div>
                                </Fragment>
                            )}
                            
                            {gotZoneList ? zoneList : <Spinner />}
                        </Fragment>
                    )}
                </div>

                <div className="no-rides"></div>
            </CartLayout>

            <ModalContainer show={modal_Confirmation} onClose={toggleModal_Confirmation}>
                <DiscountModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start transparent no_header">
                        <div onClick={toggleModal_Confirmation} className="closeBtn">
                            <CloseOutlinedIcon />
                        </div>
                    </div>

                    {selectedCity ? (
                        <div style={{flexDirection: 'column'}}>
                            <div className='icon_18 icon_green icon_margin_right' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '16px', padding: '10px'}}>
                                <CheckIcon />
                                
                                Is this correct?
                            </div>

                            {selectedCity.display_img && (
                                <div style={{width: '100%', maxHeight: '200px'}} className='store_zone_block-image'>
                                    <img src={selectedCity.display_img} />
                                </div>
                            )}

                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', fontSize: '18px'}}>
                                <span style={{fontWeight: 'bold'}}>
                                    {selectedCity.zone_name}
                                </span>
                                {selectedCity.formatted_address && (
                                    <span style={{color: '#808080', fontSize: '`16px'}}>
                                        {selectedCity.formatted_address}
                                    </span>
                                )}
                            </div>
                            

                            <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                                {/* Sign Up Button */}
                                <Button 
                                    variant="outlined" 
                                    className="sidebar__tweet"
                                    onClick={handleSwitchZone}
                                >
                                    Yes, Confirm
                                </Button>

                                <div onClick={toggleModal_Confirmation} style={{textDecoration: 'underline', textAlign: 'center', fontWeight: 'bold', margin: '20px 0', cursor: 'pointer'}}>
                                    Cancel
                                </div>
                            </div>
                        </div>
                    ) : (
                            <div>Something went wrong</div>
                    )}

                </DiscountModal>
            </ModalContainer>

            {/* Discard Post Modal */}
            <ModalContainer show={loadingCurrentLocation} onClose={toggleModal_LoadingCurrentLocation}>
                <DiscountModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start transparent no_header">
                        <div onClick={toggleModal_LoadingCurrentLocation} className="closeBtn">
                            <CloseOutlinedIcon />
                        </div>
                    </div>

                    {/* Modal Title */}
                    <div>
                        <h2>Searching for closest store...</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Patience is a virtue.</p>
                    
                    <Spinner />
                </DiscountModal>
            </ModalContainer>
        </Fragment>
    )
}

Select_City_Page.propTypes = {
    nav: PropTypes.object.isRequired,
    zone: PropTypes.object.isRequired,
    getZones: PropTypes.func.isRequired,
    setActiveZoneById: PropTypes.func.isRequired,
    toggleLocationModal: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    zone: state.zone,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    getZones,
    setActiveZoneById,
    toggleLocationModal,
    setAlert,
    add_To_Recent_History
})(Select_City_Page);
