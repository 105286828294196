import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Actions
import { getLocation, setDeliveryTime } from '../../actions/locationActions';
import { goBack } from '../../actions/navActions';

// Google Maps API
import { useLoadScript, DistanceMatrixService } from '@react-google-maps/api';

// Components - imported
import CartInfo from '../Checkout/CartInfo';

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StoreIcon from '@material-ui/icons/Store';

const CartLayout = ({ 
    locationState: {
        detailLocation,
    },
    nav: { 
        sideNav, 
    },
    zone: {
        detailZone
    },
    children, 
    setDeliveryTime,
    goBack,
    getLocation,
    handleScroll,
    page
}) => {

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Google Maps API
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"]
    });

    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    // Get URL parameters (to decide which Title text to display)
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const showParam = url.searchParams.get("show");

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Apply CSS classes to the feed header based on the value of "sideNav" and "isMobile"
    useEffect(() => {
        if(!isMobile) {
            if(sideNav) {
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                    return "feed__header";
                }
            } else {
                if(document.getElementById('feed-header') !== null) {
                    setTimeout(() => {
                        document.getElementById('feed-header').classList.add("active");
                    }, 700)
                }
            }
        } else {
            if(document.getElementById('feed-header') !== null) {
                document.getElementById('feed-header').classList.remove("active");
                return "feed__header";
            }
        }
    }, [sideNav, isMobile, isTablet]);

    useEffect(() => {

        getLocation();

    }, []);

    // -- Get the Delivery Time estimate
    useEffect(() => {

        // Create a callback function to handle Distance Matrix Service response
        const callback = (response) => {
            console.log('DRIVING DISTANCE MATRIX');
            console.log(response);
            console.log('MATRIX TIME: ', response.rows[0].elements[0].duration.text);
            console.log('MATRIX DISTANCE: ', response.rows[0].elements[0].distance.text);
            setDeliveryTime(response.rows[0].elements[0].duration.text)
        };

        if(isLoaded && detailLocation && detailZone?.location_details?.coordinates) {
        
            // Create a new DistanceMatrixService instance
            const distanceMatrixService = new window.google.maps.DistanceMatrixService();
        
            // Call the Distance Matrix API
            distanceMatrixService.getDistanceMatrix(
            {
                destinations: [detailLocation.coordinates],
                // Measure delivery time based on the time it takes to get from the customer to the Delivery Zone
                origins: [{lng: detailZone.location_details.coordinates.lng, lat: detailZone.location_details.coordinates.lat}], 
                travelMode: "DRIVING",
            },
                callback
            );
        }
    }, [isLoaded, detailLocation, detailZone]);

    // Redirect the user to the previous page or a specific URL when the "goBack" function is called
    const history = useHistory();

    // const goBack = () => {

    //     let previousURL = document.referrer;

    //     var pathArray = previousURL.split( '/' );
    //     var host = pathArray[2];

    //     console.log(pathArray);

    //     if(page === 'checkout' || page === 'discount code') {
    //         window.location = '/cart';
    //     } else {
    //         // Check the source of the previous URL and navigate accordingly
    //         if((typeof(host) !== 'undefined' && host.length) && (host.toLowerCase().includes("miitoken") || host.toLowerCase().includes("localhost:3000") || host.toLowerCase().includes("orless-14074"))) {
    //             if(pathArray[3] && pathArray[3].toLowerCase().includes("checkout")) {
    //                 window.location.href = '/';
    //             } else {
    //                 // history.goBack();
    //                 window.location.href = previousURL;
    //             }
    //         } else {
    //             window.location.href = '/';
    //         }
    //     }

    // }

    return (
        <div className="feed__container">
            {/* Feed container */}
            <div onScroll={handleScroll} className="feed">

                {/* Feed Header */}
                <div className="feed__header" id="feed-header">

                    {/* Layout header */}
                    <div className={page !== 'select city' ? "layout__profileHeader" : "layout__profileHeader skinny big_icon"}>

                        {/* Back button */}
                        <div onClick={goBack} className="profile__btnContainer">
                            
                            {page === 'select city' && <StoreIcon />}
                            {page !== 'select city' && <ArrowBackIcon />}
                        </div>

                        {/* Layout title */}
                        <div className="profile__headerText">
                            {showParam ? (
                                <h3>
                                    Complete Checkout
                                </h3>
                            ) : (
                                <h3>
                                    {page === 'discount code' && 'Add promotion or code'}
                                    {page === 'select city' && 'Select Your Store'}
                                    {page === 'cart' && 'My Bag'}
                                    {page === 'checkout' && 'Complete Checkout'}
                                </h3>
                            )}
                        </div>
                    </div>
                    
                </div>

                {/* Render the content */}
                {children}
            </div>

            {/* Render the CartInfo component */}
            {/* {(!isTablet && page !== 'orders') && (
                <CartInfo />
            )} */}
        </div>
    )
}

CartLayout.propTypes = {
    locationState: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    zone: PropTypes.object.isRequired,
    getLocation: PropTypes.func.isRequired,
    setDeliveryTime: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    locationState: state.location,
    nav: state.nav,
    zone: state.zone,
})

export default connect(mapStateToProps, { 
    getLocation, 
    setDeliveryTime, 
    goBack 
})(CartLayout);
