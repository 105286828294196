import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ImageBox from '../ProductList/ProductCard/ImageBox';

const Category_Icon = ({
    link,
    img_url,
    text
}) => {

    const [imgLoaded, setImgLoaded] = useState(false);

    return (
        <a href={link} style={{textDecoration: 'none', color: '#000'}}>
            <div className='circular_tab'>
                <div className='circular_tab_img_container'>
                    {/* <img src={img_url} /> */}
                    <ImageBox borderRadius="50%" img_src={img_url} imgLoaded={imgLoaded} setImgLoaded={setImgLoaded} />
                </div>
                <div className='circular_tab_text'>
                    <span>
                        {text}
                    </span>
                </div>
            </div>
        </a>
    )
}

Category_Icon.propTypes = {}

export default Category_Icon