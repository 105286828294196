import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions - imported
import { toggleSideNav, setPage } from '../../actions/navActions';
import { getRefundsByUserID } from '../../actions/refundActions';

// Components
import Spinner from '../common/Spinner';
import Refund_Block from './Refund_Block';

const Refunds_List = ({
    auth: {
        user
    },
    nav: {
        sideNav
    },
    order,
    refund,
    getRefundsByUserID
}) => {

    // Orders List
    const [refundList, setRefundList] = useState([]);
    const [gotRefunds, setGotRefunds] = useState(false);

    useEffect(() => {

        if(user) {
            getRefundsByUserID(user._id);
        }

    }, [user]);

    useEffect(() => {

        renderRefundList();
    }, [refund.refunds]);

    const renderRefundList = () => {
        setRefundList([]);

        if(refund.refunds === null) {
            setRefundList([(
                <Spinner />
            )])
        }
        else { 
            if(refund.refunds.length > 0) {

                refund.refunds.map(refund_item => {
                    setRefundList(refundList => [...refundList, (
                        <Refund_Block
                            refund_obj={refund_item}
                        />
                    )])
                });

                setGotRefunds(true);
            } else {
                setRefundList([(
                    <div className="no-rides">
                        <h1>No Refunds</h1>
                    </div>
                )])
            }
        } 
    }


    return (
        <Fragment>

            <div style={{width: '100%', fontWeight: 400, borderTop: '1px solid #e6ecf0', paddingTop: '20px'}}>
                <h3 style={{fontWeight: 400,}} >Active</h3>
            </div>

            {!refundList.length > 0 || !gotRefunds ? <Spinner /> : refundList}

            <div style={{width: '100%', fontWeight: 400, borderTop: '1px solid #e6ecf0', paddingTop: '20px'}}>
                <h3 style={{fontWeight: 400,}} >Completed</h3>
            </div>

            {!refundList.length > 0 || !gotRefunds ? <Spinner /> : refundList}
            
        </Fragment>
    )
}

Refunds_List.propTypes = {
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    refund: PropTypes.object.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    getRefundsByUserID: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    auth: state.auth,
    order: state.order,
    refund: state.refund
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    toggleSideNav, 
    getRefundsByUserID

})(Refunds_List);