import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Firebase

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleDiscountModal, setPage, add_To_Recent_History } from '../../actions/navActions';
import { clearModalDiscount, getDiscountById } from '../../actions/discountActions';
import { setAlert } from '../../actions/alertActions';

// Layout Wrapper
import Layout from '../../components/layout/Layout';

// Components
import AuthPage_Body from './AuthPage_Body';
import Spinner from '../../components/common/Spinner';
import { Button } from '@material-ui/core';

// Modal
import ModalContainer from '../../components/modal/ModalContainer';
import DiscountModal from '../../components/modal/DiscountModal';

// Icons
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

// Images
import couponIMG from '../../utils/imgs/coupon.png';

const AuthPage = ({ 
    setPage, 
    add_To_Recent_History,
    nav: { 
        discountModal,
    },
    discount: {
        modal_discount,
        modal_loading,
    },
    auth: {
        user
    },
    toggleDiscountModal,
    clearModalDiscount,
    getDiscountById,
    setAlert
}) => {

    // State variable to track if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    let previousUrl = url.searchParams.get("goBack");

    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Set the current page
        setPage('Auth');
        add_To_Recent_History(url.pathname);  // current url

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {
        
        if(localStorage.auth_Discount_Reward) {
            getDiscountById(localStorage.auth_Discount_Reward);
        }

    }, [localStorage.auth_Discount_Reward]);

    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Handles Mixpanel tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

        // Track "About Page View" event
        mixpanel.track("Auth Page View");
    }

    // Checks if the environment is production and Mixpanel tracking hasn't been sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {

        // Call Mixpanel tracking function
        handleMixpanel();

        // Set Mixpanel event as sent
        setSentMixpanel(true);
    }

    const isMobile = windowWidth <= 769;

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)
    }

    const handleDiscountModal = () => {
        if(discountModal) {
            clearModalDiscount();
        }

        toggleDiscountModal();
    }

    const handle_claim_discount = () => {

        if(modal_discount) {

            // claimDiscount(modal_discount._id, auth.user, modal_discount);
            localStorage.setItem("auth_Discount_Reward", modal_discount._id);

            handleDiscountModal();

        } else {
            setAlert("Coupon doesn't exist.", 'okay')
        }
    }

    // Redirect if the user is already logged in
    if(user) {

        if(previousUrl === 'cart') {
            window.location.href = "/checkout";
        } else {
            window.location.href = "/search";
        }
    }

    return (
        <Fragment>
        

            <AuthPage_Body />

            {/* Add space to the bottom of the page b/c of auth alert  */}
            <div style={{height: '300px'}}></div>

            {/* Discount Pop Up Modal */}
            <ModalContainer show={discountModal} onClose={handleDiscountModal}>
                <DiscountModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start transparent no_header">
                        <div onClick={handleDiscountModal} className="closeBtn">
                            <CloseOutlinedIcon />
                        </div>
                    </div>

                    {modal_loading && <Spinner />}

                    {!modal_loading && (
                        <Fragment>
                            {modal_discount ? (
                                <Fragment>
                                    <div style={{height: '125px', width: '100%', display: 'flex', justifyContent: 'center'}}>
                                        <img style={{objectFit: 'cover', height: '100%'}} src={couponIMG} />
                                    </div>
                                    <div style={{flexDirection: 'column', textAlign: 'center', marginTop: '10px'}}>
                                        <h3 style={{fontSize: '24px', fontWeight: 'bold'}}>
                                            You've unlocked ${modal_discount.discount_value} off your next order!
                                            {/* You've unlocked a New User Gift! */}
                                        </h3>
                                        <p style={{fontSize: '14px', fontWeight: 500}}>
                                            Min order $20+. Terms apply.
                                        </p>
                                        
                                        <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                                            {/* Sign Up Button */}
                                            <Button 
                                                variant="outlined" 
                                                className="sidebar__tweet"
                                                onClick={handle_claim_discount}
                                            >
                                                Claim offer
                                            </Button>
                
                                            <div onClick={handleDiscountModal} style={{textDecoration: 'underline', fontWeight: 'bold', margin: '20px 0', cursor: 'pointer'}}>
                                                No thanks
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <div>Sorry :( </div>
                            )}
                        </Fragment>
                    )}
                </DiscountModal>
            </ModalContainer>

        </Fragment>
    )
}

AuthPage.propTypes = {
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    toggleDiscountModal: PropTypes.func.isRequired,
    clearModalDiscount: PropTypes.func.isRequired,
    getDiscountById: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    discount: state.discount,
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setPage, 
    add_To_Recent_History,
    toggleDiscountModal,
    clearModalDiscount,
    getDiscountById,
    setAlert
})(AuthPage);
