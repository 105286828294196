import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom'

// Redux
import { connect } from 'react-redux';

// Actions
import { register } from '../../../actions/authActions';
import { setAlert } from '../../../actions/alertActions';

// Components - imported
import { Button } from '@material-ui/core';

import Logo from '../../../components/common/Logo';

// Icons - imported
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


// Initial State 
const initialState = {
    name: '',
    email: '',
    phone: '',
    month: '',
    day: '',
    year: '',
    verification_code: '',
    password: ''
};

const Form_Register = ({ register, setAlert, alert }) => {

    // Form data, initialized with an initial state
    const [formData, setFormData] = useState(initialState);

    // State variable for email notification, default value set to true
    const [getEmailNotify, setGetEmailNotify] = useState(true);

    // Destructuring form data from the formData state
    const { 
        name,
        email,
        phone,
        month,
        day,
        year,
        verification_code,
        password
     } = formData;

    // State variable for alert modal, initialized with a default value of false
    const [alertModal, setAlertModal] = useState(false);

    // Accessing the history object from React Router
    let history = useHistory();

    // Go back to the previous page using the history object
    const goBack = (e) => {
        history.goBack();
    }

    const validateEmail = (input) => {

        // Regular expression pattern to validate email format
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
        if (input.match(validRegex)) {
            
            // Email is valid
            return true;
        } else {

            // Email not invalid
            return false;
        }
    }

    const handleAlertToggle = (e) => {
        const nameArray = name.split(' ');

        let last_name = '';

        last_name = name.split(' ').slice(-1).join(' ');
        
        if(nameArray.length > 1 && last_name.length > 1) {
            if(name === '') {

                // Set an alert message indicating that name is required
                setAlert('Name is required.', 'danger');
            } else if (email === '') {

                // Set an alert message indicating that email is required
                setAlert('Email is required.', 'danger');
            } else if (!validateEmail(email)) {

                // Set an alert message indicating that email is invalid
                setAlert('Invalid email address!', 'danger');
            } else {

                // Register function to initiate the registration process
                console.log('START REGISTER');
                handleRegister(e);
            }
        } else {

            // Set an alert message indicating that full name is required
            setAlert('Full name is required', 'danger');
        }
    }

    // Update the form data state when input values change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    const handleRegister = (e) => {
        e.preventDefault();
    
        // Extract the first name from the full name
        const first_name = name.split(' ').slice(0, -1).join(' ');

        let last_name = '';

        // Extract the last name from the full name
        last_name = name.split(' ').slice(-1).join(' ');

        // Redirect to the login/sent page with query parameters for email, first name, and last name
        window.location.href = `/login/sent?email=${email}&first=${first_name}&last=${last_name}`;
    
        // register({
        //     first_name,
        //     last_name,
        //     email,
        //     phone,
        //     month,
        //     day,
        //     year,
        //     getEmailNotify,
        //     password
        // });

        // setFormData(initialState);
    };

    return (
        <div className="form-transition-element">

                {/* Button to go back */}
                <div onClick={goBack} className="form__backBtn">
                    <ArrowBackIcon />
                </div>
                    
                {/* Logo */}
                <Logo />

                {/* Title */}
                <div>
                    <h2>Create your account</h2>
                </div>

                {/* <div style={{height:'25px'}}>
                    <Alert />
                </div> */}
                
                {/* Input fields */}
                <div className="modal__inputs" style={{flex:1}}>

                    {/* Name */}
                    <input
                        type="text"
                        placeholder="Name"
                        className="input"
                        name="name"
                        value={name}
                        maxlength="30"
                        onChange={e => onChange(e)}
                    />

                    {/* Email */}
                    <input
                        type="text"
                        placeholder="Email"
                        className="input"
                        name="email"
                        value={email}
                        maxlength="30"
                        onChange={e => onChange(e)}
                    />

                    {/* Form disclaimer */}
                    <div style={{color:'rgb(27, 149, 224)', marginTop:'16px', fontSize:'15px', overflowWrap:'break-word'}}>
                        <a href="/login" style={{color:'rgb(27, 149, 224)', textDecoration:'none'}}>Already have an account?</a>
                    </div>
                
                </div>

                {/* Form Actions */}
                <div>
                    <Button 
                        variant="outlined" 
                        className="sidebar__tweet" 
                        fullWidth
                        // disabled={recommendationTags.length > 2 ? false : true} 
                        onClick={handleAlertToggle} 
                        // className={recommendationTags.length > 2 ? "step-form-btn" : "step-form-btn disabled"}
                    >
                        Next
                    </Button>
                </div>

                {/* Form footer */}
                <div className="auth__footer">
                    {/* <a href={`/register`}>Forgot password?</a>
                    <a href={`/login`}>Log In</a> */}
                </div>
            </div>
    )
}

Form_Register.propTypes = {
    register: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    alert: state.alert
});

export default connect(mapStateToProps, { register, setAlert })(Form_Register);
