import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { changeGuestPhone, fetchGuestPhone } from '../../actions/authActions'; 

// icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const GuestAddressPage = ({ 
    history,
    auth: {
        isAuthenticated,
        loading,
        user
    },
    changeGuestPhone,
    fetchGuestPhone
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Input Form Data
    const [phoneNumber, setPhoneNumber] = useState('');

    // Extract URL and previous URL page
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    let previousUrl = url.searchParams.get("goBack");

    useEffect(() => {

         // Add event listener for window resize
         window.addEventListener('resize', () => handleWindowSizeChange());

         // Cleanup the event listener when the component unmounts
         return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        if(!isAuthenticated && !loading) fetchGuestPhone();

    }, [loading, isAuthenticated]);

    useEffect(() => {

        // Load user data
        if (!loading && user?.phone) {

            setPhoneNumber(user.phone)
        }

    }, [user]);

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Guest Phone # Page View");
    }

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    const goBack = () => {
        if(previousUrl) {
            history.goBack();
        } else {
            window.location.href = '/checkout';
        }
    }

    const onChange = e => setPhoneNumber(e.target.value);

    const handleSubmit = () => {
        changeGuestPhone(phoneNumber);
    }

    // Redirect logic for non-mobile devices
    if(isAuthenticated) {
        history.push({
            pathname: `/settings/phone`,
            // search: `?goBack=${previousUrl}`,
        })
    }

    return (
        <div style={{width: '100vw'}} className="settings">

            {/* Header */}
            <div className="settings__header">

                {/* Back Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                </div>

                {/* Title */}
                <div className="settings__headerText">
                    <h3>
                        Mobile Number
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div style={{padding: '20px 20px 0px', borderBottom:'1px solid #e6ecf0',}} className="settings__body">

                {/* Modal Content */}
        
                <div style={{margin: '8px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '704px'}}>
                    <div style={{fontSize: '14px', width: '100%', paddingLeft: '10px', paddingTop: '10px', boxSizing: 'border-box'}}>
                        We'll use this number to text or call you about any updates about this order
                    </div>
                    <div style={{width: '100%', boxSizing:'border-box', padding:'0px 10px 10px 10px', display:'flex'}}>
                    
                        {/* Phone Input */}
                        <input
                            type="phone"
                            placeholder="Phone"
                            className="input"
                            name="phone"
                            value={phoneNumber}
                            onChange={e => onChange(e)}
                            maxlength="20"
                            style={{width: '100%'}}
                        />
                    </div>

                    <div style={{width: '100%', marginTop: '24px', minWidth: '300px'}}>
                        <div onClick={handleSubmit} style={{backgroundColor: 'rgb(0, 164, 255)', borderColor: 'rgb(0, 164, 255)', minWidth: '88px', minHeight: '48px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                            <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                                Confirm
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

GuestAddressPage.propTypes = {
    auth: PropTypes.object.isRequired,
    changeGuestPhone: PropTypes.func.isRequired,
    fetchGuestPhone: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    changeGuestPhone,
    fetchGuestPhone
})(GuestAddressPage);
