import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Main_Settings = ({
    auth,
    chat,
    notification,
    handleSlideMenu,
    formData,
    getUnreadChats,
    getUnreadCount,
    toggleUsernameModal,
    toggleEmailModal, 
    togglePhoneModal,
    togglePasswordModal,
    history
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    
    // Update badge value when notification or user change
    useEffect(() => {
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update badge value based on notification and chat counts
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Form data
    const { username, phone, email, password, password2 } = formData;

    // Go back to main settings page
    const goBack = () => {
        window.location.href = '/settings';
        
        // let previousURL = document.referrer;

        // var pathArray = previousURL.split( '/' );
        // var host = pathArray[2];

        // console.log(pathArray);

        // // Check if from this source or from chat box page
        // if((typeof(host) !== 'undefined' && host.length) && (host.toLowerCase().includes("oubuysell") || host.toLowerCase().includes("localhost:3000"))) {
        //     if(pathArray[4] && pathArray[4].toLowerCase().includes("set-up")) {
        //         window.location.href = '/home';
        //     } else if(pathArray[3] && pathArray[4] && pathArray[3].toLowerCase().includes("messages")) {
        //         window.location.href = `/${pathArray[3]}/${pathArray[4]}`;
        //     } else if(previousURL.toLowerCase().includes("?filter=welcome")) {
        //         window.location.href = '/home';
        //     } else {
        //         history.goBack();
        //     }
        // } else {
        //     window.location.href = '/home';
        // }
    }

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings account page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Account Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {

        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">

            {/* Settings Header */}
            <div className="settings__header">
                {/* {isTablet && ( */}

                    {/* // Back Button & Badge Value */}
                    <div onClick={goBack} className="profile__btnContainer">
                        <ArrowBackIcon />
                        {isMobile && badgeValue > 0 ? (
                            <span className="feed-header-badge defaultBadge">
                                {badgeValue}
                            </span>
                        ) : null}
                    </div>
                {/* )} */}

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        Account settings
                    </h3>
                </div>
            </div>

            <div className="settings__body">

                {/* Username */}
                <a style={{textDecoration:'none'}} href="/settings/account/screen_name">
                    <div className="settings__item">
                        <div>
                            <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                                <span>Username</span>
                            </div>
                            <div style={{color: 'rgb(83, 100, 113)', fontSize:'13px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                                <span>@{username}</span>
                            </div>
                        </div>
                        <ChevronRightIcon />
                    </div>
                </a>

                {/* Password */}
                <a style={{textDecoration:'none'}} href="/settings/account/password">
                    <div className="settings__item">
                        <div>
                            <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                                <span>Password</span>
                            </div>
                            <div style={{color: 'rgb(83, 100, 113)', fontSize:'13px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                                <span>Change your password</span>
                            </div>
                        </div>
                        <ChevronRightIcon />
                    </div>
                </a>

                {/* Email */}
                <a style={{textDecoration:'none'}} href="/settings/account/email">
                    <div className="settings__item">
                        <div>
                            <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                                <span>Email</span>
                            </div>
                            <div style={{color: 'rgb(83, 100, 113)', fontSize:'13px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                                <span>{email}</span>
                            </div>
                        </div>
                        <ChevronRightIcon />
                    </div>
                </a>

                {/* Phone */}
                <a style={{textDecoration:'none'}} href="/settings/account/phone">
                    <div className="settings__item">
                        <div>
                            <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                                <span>Phone</span>
                            </div>
                            <div style={{color: 'rgb(83, 100, 113)', fontSize:'13px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                                <span>{phone}</span>
                            </div>
                        </div>
                        <ChevronRightIcon />
                    </div>
                </a>

                {/* Verified */}
                <div className="settings__item noHover">
                    <div>
                        <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                            <span>Verified</span>
                        </div>
                        <div style={{color: 'rgb(83, 100, 113)', fontSize:'13px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                            <span>{auth.user && (auth.user.verified ? 'Yes' : 'No')}.</span>
                        </div>
                    </div>
                </div>

                {/* Joined Date */}
                <div className="settings__item noHover">
                    <div>
                        <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                            <span>Account created</span>
                        </div>
                        <div style={{color: 'rgb(83, 100, 113)', fontSize:'12px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                            <span>{moment(auth.user.date).format('MMMM Do YYYY, h:mm:ss a')}</span>
                        </div>
                    </div>
                </div>

                {/* Gender */}
                <a style={{textDecoration:'none'}} href="/settings/account/gender">
                    <div className="settings__item">
                        <div>
                            <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                                <span>Gender</span>
                            </div>
                            <div style={{color: 'rgb(83, 100, 113)', fontSize:'13px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                                <span>{auth.user && auth.user.gender && auth.user.gender !== '' ? `${auth.user.gender.charAt(0).toUpperCase() + auth.user.gender.slice(1)}` : 'None'}</span>
                            </div>
                        </div>
                        <ChevronRightIcon />
                    </div>
                </a>

                {/* Birth Date */}
                <a style={{textDecoration:'none'}} href="/settings/account/birth_date">
                    <div className="settings__item">
                        <div>
                            <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                                <span>Birth date</span>
                            </div>
                            <div style={{color: 'rgb(83, 100, 113)', fontSize:'13px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                                <span>{auth.user && auth.user.birth_date && auth.user.birth_date.month && auth.user.birth_date.day && auth.user.birth_date.year ? `${auth.user.birth_date.month.charAt(0).toUpperCase() + auth.user.birth_date.month.slice(1)} ${auth.user.birth_date.day}, ${auth.user.birth_date.year}` : 'None'}</span>
                            </div>
                        </div>
                        <ChevronRightIcon />
                    </div>
                </a>
                {/* <a style={{textDecoration:'none'}} href="/logout">
                    <div className="settings__item logout">
                        <p>Deactivate your account</p>
                    </div>
                </a> */}
                {/* <div className="settings__section">
                    <div>
                        <p>
                            @{username}
                        </p>
                    </div>
                    <div onClick={toggleUsernameModal}>
                        <p>Edit</p>
                    </div>
                </div>
                <div className="settings__section">
                    <div>
                        <p>
                            {email}
                        </p>
                    </div>
                    <div onClick={toggleEmailModal}>
                        <p>Edit</p>
                    </div>
                </div>
                <div className="settings__section">
                    <div>
                        <p>
                            {phone}
                        </p>
                    </div>
                    <div onClick={togglePhoneModal}>
                        <p>Edit</p>
                    </div>
                </div>
                <div className="settings__section">
                    <div>
                        <p>
                            Password
                        </p>
                    </div>
                    <div onClick={togglePasswordModal}>
                        <p>Edit</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Main_Settings;
