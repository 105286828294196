import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions - imported
import { toggleSideNav, setPage, add_To_Recent_History } from '../actions/navActions';
import { getOrdersByUserID, getOrderById } from '../actions/orderActions';

// Components - imported
import CartLayout from '../components/layout/CartLayout';
import OrderPage from '../components/Order/OrderPage';
import OrderDetail from '../components/Order/OrderDetail';
import { Button } from '@material-ui/core';
import Orders_List from '../components/Order/Orders_List';
import ChangeAddress from '../components/Order/ChangeAddress';

const MyOrders = ({
    auth: {
        user
    },
    order,
    setPage,
    add_To_Recent_History,
    getOrdersByUserID,
    getOrderById,
    match,
    history
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const show = url.searchParams.get("show");

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        setPage('orders');
        add_To_Recent_History(url.pathname);  // current url

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    useEffect(() => {

        if(user) {
            getOrdersByUserID(user._id);
        }
    }, [user])

    useEffect(() => {

        // Fetch chat data if chat ID is provided in the URL params
        if(match.params.orderID) {
            getOrderById(match.params.orderID)
        }

    }, [match.params.orderID]);

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Orders List Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="inbox__container">

            {!isTablet && (
                <Orders_List history={history} />
            )}

            {order.order ? (
                <Fragment>
                    {show === 'change_address' ? (
                        <ChangeAddress 
                            detailOrder={order.order}
                        />
                    ) : (
                        <OrderDetail
                            detailOrder={order.order}
                        />
                    )}
                </Fragment>
            ) : (
                <div className="no-chats-container">
                    <div className="no-chats">
                        <h1>You don’t have an order selected</h1>
                        <p>Choose one from your existing orders, or start shopping!</p>
                        <Button  
                            onClick={() => window.location = '/'} 
                            variant="outlined" 
                            className="noChats__btn"
                        >
                            Start Shopping
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

MyOrders.propTypes = {
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    getOrdersByUserID: PropTypes.func.isRequired,
    getOrderById: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    auth: state.auth,
    order: state.order
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    getOrdersByUserID,
    getOrderById
})(MyOrders);
