import { db, auth } from '../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, orderBy, serverTimestamp, startAfter, limit } from 'firebase/firestore';

import { setAlert } from './alertActions';

import {  
    GET_REFUNDS,
    REFUND_ERROR,
    CLEAR_REFUNDS,
    REFUNDS_LOADING
} from './types';

// Reference to the "refunds" collection in Firestore
const refundCollectionRef = collection(db, "refunds");

// Get the logged in user refunds 
export const getRefundsByUserID = (userID) => async dispatch => {

    console.log('GETTING USER REFUNDS');

    dispatch(setRefundsLoading());

    try {
        console.log('QUERYING USER REFUNDS');

        // Create a query to retrieve refunds where the user ID matches the provided ID, ordered by creation date in descending order
        const q = query(refundCollectionRef, where("user_id", "==", userID), orderBy('createdAt', 'desc'));

        console.log('QUERY REFUNDS RESULTS');
        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map the query snapshot to an array of refund objects with document ID
        const refunds = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('USER REFUNDS DATA');
        console.log(refunds);

        // Dispatch an action to update the state with the retrieved refunds
        dispatch({
            type: GET_REFUNDS,
            payload: refunds
        });

    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action to handle the notification error
        dispatch({
            type: REFUND_ERROR,
            payload: []
        })
    }
}

// Add New Refund
export const addRefund = (refund_obj, order_id) => async dispatch => {

    console.log('ADDING REFUND!!!')
    try {

        // Create Refund

        // Add the new refund document to the refund collection
        await addDoc(refundCollectionRef, {
            order_id,
            refund_id: refund_obj.id,
            user_id: auth.currentUser.uid,
            ...refund_obj,
            date: Date.now(),
            createdAt: serverTimestamp()
        });

        dispatch(setAlert('Refund Initiated', 'Success'))

    } catch (err) {
        console.log('ERROR CREATING REFUND!!!');
        console.log(err);
        dispatch(setAlert('Error creating refund', 'danger'))

        // Handle the error if creating the notification fails
        //   dispatch({
        //     type: NOTIFICATION_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        //   });
    }
};

// Refunds loading
export const setRefundsLoading = () => {
    return {
        type: REFUNDS_LOADING
    }
}

// Remove all Refunds
export const clearRefunds = () => dispatch => {

    // Dispatch an action to set loading state for refunds
    dispatch(setRefundsLoading());

    // Dispatch an action to clear all refunds in the state
    dispatch({
        type: CLEAR_REFUNDS
    });

}

