import React from 'react'
import PropTypes from 'prop-types'

// Components
import Category_Icon from './Category_Icon';


// Category Images
import searchIcon_img from '../../../utils/imgs/searchIcon_img.png';
import shoppingBag_img from '../../../utils/imgs/shoppingBag_img.jpg';
import snackIcon_img from '../../../utils/imgs/snackIcon_img.png';
import iceCreamIcon_img from '../../../utils/imgs/iceCreamIcon_img.png';
import breadIcon_img from '../../../utils/imgs/breadIcon_img.png';
import eggsIcon_img from '../../../utils/imgs/eggsIcon_img.png';
import frozenMealsIcon_img from '../../../utils/imgs/frozenMealsIcon_img.png';
import milkIcon_img from '../../../utils/imgs/milkIcon_img.png';
import cerealicon_img from '../../../utils/imgs/cerealicon_img.png';
import sodaIcon_img from '../../../utils/imgs/sodaIcon_img.jpg';
import meatIcon_img from '../../../utils/imgs/meatIcon_img.png';
import waterIcon_img from '../../../utils/imgs/waterIcon_img.jpg';
import snack_img from '../../../utils/imgs/snack_img.png';
import iceCream_img from '../../../utils/imgs/iceCream_img.jpg';
import bread_img from '../../../utils/imgs/bread_img.png';
import eggs_img from '../../../utils/imgs/eggs_img.png';
import frozenMeal_img from '../../../utils/imgs/frozenMeal_img.jpg';
import milk_img from '../../../utils/imgs/milk_img.jpg';
import cereal_img from '../../../utils/imgs/cereal_img.jpg';
import soda_img from '../../../utils/imgs/soda_img.png';
import meats_img from '../../../utils/imgs/meats_img.png';
import water_img from '../../../utils/imgs/water_img.png';

const Category_Icon_List = props => {
    return (
        <div className='horiz-category-nav' style={{height: 'fit-content', width: '100%', marginTop: '15px', display: 'flex', boxSizing: 'border-box', overflowX: 'scroll', overflowY: 'auto', paddingBottom: '12px', borderBottom: '1px solid #e6ecf0'}}>
            <Category_Icon text="Search" link="/marketplace/search" img_url={searchIcon_img} />
            <Category_Icon text="My Bag" link="/cart" img_url={shoppingBag_img} />
            <Category_Icon text="Snacks" link="/snacks/JIlp5ujnantCKwvuc7VM" img_url={snackIcon_img} />
            <Category_Icon text="Ice Cream" link="/ice-cream/icpnzM2bOClFdrS4zqxi" img_url={iceCreamIcon_img} />
            <Category_Icon text="Bread" link="/bread/tkaiyFAesuVPEWtoVg7o" img_url={breadIcon_img} />
            <Category_Icon text="Eggs" link="/eggs/KIUM9rB6rNXRtETtn4Q5" img_url={eggsIcon_img} />
            <Category_Icon text="Frozen Meals" link="/frozen-meals/WEPjjmFvSLfGNjdadi8i" img_url={frozenMealsIcon_img} />
            <Category_Icon text="Milk" link="/milk/59aKz9kT8sGvUJ3NL72e" img_url={milkIcon_img} />
            <Category_Icon text="Cereal" link="/cereal/4sLwYBaMQENnhSnCSMTs" img_url={cerealicon_img} />
            <Category_Icon text="Soda" link="/soda/uWXLXkqYLzK83optessP" img_url={sodaIcon_img} />
            <Category_Icon text="Meats" link="/meats/tvGkFvTxlg9FHCXaztbu" img_url={meatIcon_img} />
            <Category_Icon text="Water" link="/water/1TtkQolpa7e4rfc9uciK" img_url={waterIcon_img} />
        </div>
    )
}

Category_Icon_List.propTypes = {}

export default Category_Icon_List