import React, { useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { getMenuPage, getMenuItems } from '../../actions/menuActions';
import { getCollectionsByIdList } from '../../actions/collectionActions';
import { getProductsInCollection, set_ShowBottomSpinner } from '../../actions/productActions';
import { setPage, add_To_Recent_History } from '../../actions/navActions';

// Layout Wrapper
import ShopLayout from '../../components/layout/ShopLayout';

// Components 
import SlideMenu from '../../Marketplace/components/SlideMenu/SlideMenu';
import Category_List from '../../Marketplace/components/CategoryList/Category_List';
import CategoryMain from '../../Marketplace/components/CategoryList/CategoryMain';
import FAQ_Container from '../components/FAQ/FAQ_Container';
import Mobile_CartBtn from '../components/Cart/Mobile_CartBtn';

// Icons
import SearchIcon from '@material-ui/icons/Search';

const CollectionPage = ({
    getMenuPage,
    getMenuItems,
    setPage,
    add_To_Recent_History,
    menu,
    cart: {
        cart
    },
    product: {
        no_more_products,
        products,
        show_bottom_spinner,
        last_page_doc,
        loading
    },
    getProductsInCollection,
    set_ShowBottomSpinner
}) => {

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Temporarily disable to load more function to avoid duplicate fetch requests
    const [canLoadMore, setCanLoadMore] = useState(true);

    // Get URL params
    const { menuID, handle } = useParams();
    // Put "menu" data into forms
    const detailMenu = menu.detailMenu;
    const loadingMenu = menu.loading;
    const menus = menu.menus;

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Set nav page
        setPage('collection');
        add_To_Recent_History(url.pathname);  // current url

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    useEffect(() => {

            // -- Get Product Data
            if(!detailMenu && menus?.length > 0) getMenuPage("FuuNWfB2pTIbzPEdDIsW");
    }, []);

    useEffect(() => {

        if(detailMenu) {

            console.log('MENU ID:', menuID)
            getMenuItems(detailMenu._id, menuID);
        }
    }, [detailMenu]);

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more products are met
        if (newScrollY >= scrollHeight - 100 && !show_bottom_spinner && !no_more_products && products.length > 0 && detailMenu?.collection_id && canLoadMore) {
            console.log('scrolling');
            loadMore(last_page_doc);
        }
    }

    // Function to load more paginated posts
    const loadMore = (lastPage_doc) => {
        console.log("LOADING MORE");
        console.log("last", lastPage_doc);

        // Temporarily disable to load more function to avoid duplicate fetch requests
        setCanLoadMore(false);

        // Show spinner
        set_ShowBottomSpinner(true);

        getProductsInCollection(detailMenu?.collection_id, lastPage_doc);

        // Re-enable the load more function after 1 second
        setTimeout(() => {
            setCanLoadMore(true);
        }, 1000);

    }

    const capitalizeAndRemoveHyphens = (inputString) => {
        // Replace '-' with ' ' to prepare for splitting into words
        const stringWithoutHyphens = inputString.replace(/-/g, ' ');

        // Split the input string into words
        const words = stringWithoutHyphens.split(/\s+/);

        // Initialize an empty array to store the modified words
        const modifiedWords = [];

        // Loop through each word
        for (const word of words) {
            // Capitalize the first letter of the word and make the rest lowercase
            const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            
            // Push the capitalized word into the modifiedWords array
            modifiedWords.push(capitalizedWord);
        }

        // Join the modified words back into a single string
        const resultString = modifiedWords.join(' ');

        return resultString;
    }

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Grocery Collection Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (
        <ShopLayout handleScroll={handleScroll} page="collections">

            {/* Search Input */}
            {isTablet && (
                <div onClick={() => window.location = '/search?autoFocus=true'} style={{marginRight: '20px', marginBottom: '10px'}} className={`widgets__input`}>
                    <SearchIcon className="widgets__searchIcon" />
                    <input 
                        id="search-input" 
                        placeholder={`Search Buy / Sell`}
                        type="text" 
                        autocomplete="off" 

                    />
                </div>
            )}
            
            <div style={{paddingTop: '15px', boxSizing: 'border-box', paddingLeft: '15px'}}>
                {/* Breadcrumbs */}
                {/* <ul className="breadcrumbs">
                    <li className="breadcrumbs__item">
                        <a href="#" className="breadcrumbs__link">Marketplace</a>
                    </li>
                    <li className="breadcrumbs__item">
                        <a href="#" className="breadcrumbs__link breadcrumbs__link--active">Trending</a>
                    </li>
                </ul> */}

                {!loading && (
                    <h2 style={{marginLeft: '5px'}}>{capitalizeAndRemoveHyphens(handle)}</h2>
                )}

                {menus?.length > 0 ? (
                    <Fragment>

                        {/* Sub-Category Slide Menu */}
                        <SlideMenu />

                        {/* Banner Image */}
                        {/* <div style={{display: 'flex'}}>
                            <div style={{flex: 1}}>
                                <a style={{cursor: 'pointer'}} href="#">
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                        <div style={{height: '171.818px', width: '672px', borderRadius: '8px', overflow: 'hidden', position: 'relative'}}>
                                            <img style={{objectFit: 'cover', width: '100%', height: '100%'}} src="https://campaigns-cdn.gopuff.com/assets/ovTNebRTDSLfdXWTsmR9.png" />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div> */}

                        {/* Product Category Overview */}
                        <Category_List />
                    </Fragment>
                ) : (
                    <CategoryMain menuID={menuID} loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} />
                )}

                {/* FAQ container */}
                {/* <FAQ_Container handle={handle} /> */}
            </div>

            <div style={{height: '100px'}}></div>

            <Mobile_CartBtn />
        </ShopLayout>
    )
}

CollectionPage.propTypes = {
    getMenuPage: PropTypes.func.isRequired,
    getMenuItems: PropTypes.func.isRequired,
    getCollectionsByIdList: PropTypes.func.isRequired,
    getProductsInCollection: PropTypes.func.isRequired,
    set_ShowBottomSpinner: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    collection: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    collection: state.collection,
    menu: state.menu,
    cart: state.cart,
    product: state.product
})

export default connect(mapStateToProps, { 
    getMenuPage, 
    getMenuItems, 
    getCollectionsByIdList, 
    getProductsInCollection,
    set_ShowBottomSpinner,
    setPage,
    add_To_Recent_History
})(CollectionPage);