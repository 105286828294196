import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { setAlert } from '../actions/alertActions';
import { applyDiscount, removeDiscount } from '../actions/discountActions';
import { add_To_Recent_History } from '../actions/navActions'

// Components - imported
import { Button } from '@material-ui/core';
import Coupon_List from '../components/Coupon/Coupon_List';

// Layout Wrapper
import CartLayout from '../components/layout/CartLayout';

// Icons -imported
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Logo from '../components/common/Logo';

// Initial State 
const initialState = {
    discount_code: ''
};

const Add_DiscountForm = ({ 
    setAlert,
    add_To_Recent_History,
    applyDiscount,
    removeDiscount,
    auth: {
        isAuthenticated, 
        loading,
        user
    },
    nav: {
        campus_name
    },
    discount: {
        detailDiscount,
        recently_applied_discount
    }
 }) => {

    // States for form data
    const [formData, setFormData] = useState(initialState); 

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {
        
        add_To_Recent_History(url.pathname);  // current url

    }, [])

    useEffect(() => {
        
        if(detailDiscount?.discount_code) {
            setFormData({ ...formData, 'discount_code': detailDiscount.discount_code});
        } else {
            setFormData(initialState)
        }

    }, [detailDiscount])

    const { 
        discount_code
     } = formData;


    // Function to handle input change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    // Log the user out and Redirect to the login page
    const goBack = () => {

        window.location.href = '/cart';

    }

    const todo = (e) => {
        e.preventDefault();
        
        if(discount_code !== '') {
            applyDiscount(discount_code, user);
        } else {
            setAlert('Please enter discount code', 'danger');
        } 
    }

    const handleRemoveDiscount = () => {

        removeDiscount(detailDiscount._id, true) // the 'true' is to flag function NOT to reset discounts
    }

    return (
        <CartLayout page="discount code">

            <div style={{padding: '0 20px'}}>

                {/* Input Container */}
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>

                    {/* Verification Code input */}
                    <input
                        type="text"
                        placeholder="Enter discount code"
                        className="input"
                        name="discount_code"
                        value={discount_code}
                        onChange={e => onChange(e)}
                        style={{flex: 1}}
                    />

                    <div>
                        {detailDiscount?.discount_code ? (
                            <button 
                                variant="outlined" 
                                className="secondaryBtn"
                                style={{height: '50px', marginRight: '0', background: '#000', color: '#fff'}}
                                onClick={() => handleRemoveDiscount()} 
                            >
                                Remove
                            </button>
                        ) : (
                            <button 
                                variant="outlined" 
                                className="secondaryBtn"
                                style={{height: '50px', minWidth: '100px', marginRight: '0', justifyContent: 'center', background: '#000', color: '#fff'}}
                                onClick={(e) => todo(e)} 
                            >
                                Apply
                            </button>
                        )}
                        
                    </div>

                </div>

                {recently_applied_discount && (
                    <div style={{marginBottom: '20px'}}>
                        <div style={{margin: '8px', textAlign: 'center', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'8px', borderRadius:'8px', lineHeight:'15.8px', fontWeight: 'bold', fontSize:'13px'}}>
                            Coupon applied!
                        </div>
                    </div>
                )}

                <Coupon_List page="cart" applyDiscount={applyDiscount} setFormData={setFormData} formData={formData} /> 
            </div>

            <div className="no-rides"></div>
        </CartLayout>
    )
}

Add_DiscountForm.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    applyDiscount: PropTypes.func.isRequired,
    removeDiscount: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav,
    discount: state.discount
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setAlert,
    add_To_Recent_History,
    applyDiscount,
    removeDiscount
})(Add_DiscountForm);
