import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Components
import Spinner from '../../../components/common/Spinner';
import SlideMenu_Option from './SlideMenu_Option';

// Icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const Slide_Menu = ({
    menu: {
        menus
    }
}) => {

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Menu List
    const [menuList, setMenuList] = useState([]);
    const [gotMenus, setGotMenus] = useState(false);

    const [scrollAtStart, setScrollAtStart] = useState(true);
    const [scrollAtEnd, setScrollAtEnd] = useState(true);

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    useEffect(() => {

        if(!(menus.length > 3)) {
            setScrollAtEnd(true);
        } else {
            setScrollAtEnd(false);
        }

        if(menus?.length > 0) {
            renderMenuList()
        }

    }, [menus]);

    useEffect(() => {
        let carousel = document.querySelector(`.menu_carousel`);

        renderBtns()

        function renderBtns() {
            if(carousel) {
                carousel.addEventListener("scroll", (event) => {
                    if(carousel.scrollLeft === 0) {
                        setScrollAtStart(true);
                    } else {
                        setScrollAtStart(false)
                    }
    
                    if(carousel.scrollLeft >= 60) {
                        setScrollAtEnd(true);
                    } else {
                        setScrollAtEnd(false)
                    }
                })
            }
        }

        // Cleanup the event listener when the component unmounts
        return () => carousel.removeEventListener('scroll', null);
    }, []);


    const dec_carousel = () => {
        let carousel = document.querySelector(`.menu_carousel`);

        if(!scrollAtStart) {
            let width = carousel.clientWidth;
            carousel.scrollLeft -= width;
            console.log('DEC CAROUSEL')
            console.log(carousel.scrollLeft)
            console.log(width)
        }
    }
    const inc_carousel = () => {
        let carousel = document.querySelector(`.menu_carousel`);

        if(!scrollAtEnd) {
            let width = carousel.clientWidth;
            carousel.scrollLeft += width;
            console.log('INC CAROUSEL')
            console.log(carousel.scrollLeft)
            console.log(width)
        }
    }

    const renderMenuList = async () => {
        setMenuList([]);

        if(menus === null) {
            setMenuList([(
                <Spinner />
            )])
        }
        else { 
            if(menus.length > 0) {
                menus.map(async menu_block => {

                    if(menu_block?.handle && menu_block?.name && menu_block?._id) {
                        setMenuList(menuList => [...menuList, (
                        
                            /* Slide Menu Option */
                            <SlideMenu_Option option={menu_block} />
    
                        )]);
                    }
                });
            } else {
                setMenuList([(
                    <h1>Nothing found...</h1>
                )])
            }
        } 

        setGotMenus(true);
    }

    return (
        <div style={{zIndex: 10, top: 0, padding: `${!isTablet ? '16px 16px 16px 0px' : '16px 0px 16px 0px'}`}}>
            <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center'}}>
                
                {/* Left Arrow Btn */}
                {!isTablet && (
                    <div onClick={dec_carousel} style={{marginRight: '8px'}}>
                        <ArrowBtn isActive={!scrollAtStart}>
                            <KeyboardArrowLeftIcon />
                        </ArrowBtn>
                    </div>
                )}

                {/* Slide Menu Option Container */}
                <SlideMenu>

                    {/* Slide Menu Option list */}
                    {menuList}
                </SlideMenu>

                {/* Right Arrow Btn */}
                {!isTablet && (
                    <div onClick={inc_carousel} style={{marginLeft: '8px'}}>
                        <ArrowBtn isActive={!scrollAtEnd}>
                            <KeyboardArrowRightIcon />
                        </ArrowBtn>
                    </div>
                )}
            </div>
        </div>
    )
}

// Arrow Btn 
const ArrowBtn = ({isActive, children}) => {

    return (
        <div className={`horizontal_carousel-btn ${isActive ? 'active' : ''}`}>
            <div style={{fontSize: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {children}
            </div>
        </div>
    )
}

// Slide Menu Options Container
const SlideMenu = ({ children }) => {
    
    return (
        <div className={`menu_carousel`}>
            {children}
        </div>
    )
}

Slide_Menu.propTypes = {
    menu: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    menu: state.menu
})

export default connect(mapStateToProps, null)(Slide_Menu);