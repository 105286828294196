import React from 'react'
import PropTypes from 'prop-types';

// Icons
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Disclaimer = ({
    children,
    show_Call_To_Action,
    link
}) => {
    return (
        <div onClick={() => window.location.href = link} className='disclaimer green_text space_between'>

            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    
                    {children}

                    {show_Call_To_Action && (
                        <div style={{fontSize: '12px', fontWeight: 500, color: '#808080', textDecoration: 'underline'}}>
                            click here
                        </div>
                    )}
                </div>
                
                <div>
                    <ChevronRightIcon />
                </div>
            </div>
        </div>
  )
}

Disclaimer.propTypes = {}

export default Disclaimer