import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

import { removeDiscount } from '../../../actions/discountActions';

// Icons
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const CouponBlock = ({
    discount,
    removeDiscount
}) => {

    const [show, setShow] = useState(true);

    const handleRemove = (discountID) => {
        removeDiscount(discountID)
        // setShow(false);
    }

    return (
        <div style={{border: '2px solid rgba(38,191,127,1.00)', borderRadius: '4px', width: '100%', marginBottom: '10px', display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px'}}>
                <LocalOfferOutlinedIcon />
            </div>
            <div style={{flex: 1, padding: '10px'}}>
                <p style={{fontSize: '14px', color: 'grey'}}>Promo code</p>
                <span style={{fontWeight: 500}}>{discount.discount_code}</span>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div onClick={() => handleRemove(discount._id)} className='closeBtn'>
                    <CloseIcon />
                </div>
                <div style={{color: 'rgba(38,191,127,1.00)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px'}}>
                    <CheckIcon />
                </div>
            </div>
        </div>
    )
}

CouponBlock.propTypes = {
    removeDiscount: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { 
    removeDiscount 
})(CouponBlock);