import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Actions

// Components - imported
import { Button } from '@material-ui/core';

const DiscountList_Preview_Modal_Body = ({
    modal_discount,
    handleModal_DiscountList_Preview
}) => {

  return (
        <div style={{flexDirection: 'column',  marginTop: '30px'}}>
            <div style={{fontSize: '28px', fontWeight: 'bold', margin: '8px 0'}}>
                {(modal_discount.discount_type === "buy x get y" && modal_discount.percentage_off_each && modal_discount.min_quantity ) && (
                    <Fragment>
                        Buy {modal_discount.min_quantity} Get {modal_discount.getY_quantity} for {modal_discount.percentage_off_each !== '100' ? `${modal_discount.percentage_off_each}% OFF` : 'FREE'}
                    </Fragment>
                )}

                {(modal_discount.discount_type === "amount off product" && modal_discount.amount_off_each && modal_discount.min_quantity ) && (
                    <Fragment>
                        Buy {modal_discount.min_quantity} Get {modal_discount.getY_quantity} ${modal_discount.amount_off_each} OFF
                    </Fragment>
                )}
            </div>

            <div style={{marginBottom: '16px', fontSize: '18px', margin: '2px 0', fontWeight: 500}}>
                Discount will be applied in bag.
            </div>


            <div style={{margin: '16px 0'}}>
                <div style={{fontSize: '16px', margin: '2px 0', fontWeight: 'bold'}}>
                    Terms and Conditions
                </div>
                <div style={{fontSize: '14px', margin: '2px 0', fontWeight: 500}}>
                    Discount applies to select products. Discount calculated using the lower priced item, but will apply across all qualifying and discounted items. Add all items to cart to receive discount. Discount not applicable to tax. Pricing, promotions and availability may vary by location and on Miitoken. Offer expires 7/31/24.
                </div>
            </div>
            
            <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                {/* Sign Up Button */}
                <Button 
                    variant="outlined" 
                    className="sidebar__tweet"
                    onClick={handleModal_DiscountList_Preview}
                >
                    Shop Now
                </Button>

            </div>
        </div>
  )
}

DiscountList_Preview_Modal_Body.propTypes = {}

export default DiscountList_Preview_Modal_Body