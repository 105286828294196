import React, { useState, useEffect } from 'react';

import DefaultAvatar from 'react-avatar';

// Icons
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TimerOffOutlinedIcon from '@material-ui/icons/TimerOffOutlined';
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined';
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';

// Components
import Spinner from '../../../components/common/spinner.gif';
import Item_Img from './Item_Img';

const OfferBlock = ({ 
    detailOrder
}) => {

    // Orders List
    const [cartList, setCartList] = useState([]);
    const [gotCartList, setGotCartList] = useState(false);

    const {
        cart: {
            items,
            total,
            subtotal,
            tax,
            qty,
            tip
        }
    } = detailOrder

    useEffect(() => {

        renderCartList();
    }, []);

    const renderCartList = () => {
        setCartList([]);

        if(items === null) {
            setCartList([(
                <></>
            )])
        }
        else { 
            if(items.length > 0) {

                items.map(order_item => {
                    setCartList(cartList => [...cartList, (
                        <Item_Img key={order_item.item._id} orderItem={order_item} />
                    )])
                });

                setGotCartList(true);
            } else {
                setCartList([(
                    <></>
                )])
            }
        } 
    }

    return (
        <div className={`notification`} onClick={() => window.location = `/offer/${detailOrder?._id}`}>
            <div className="notification__body left_side">
                <div className="notification__header">

                    {/* Display the relative time of the notification */}
                    <p className="notification__special">4 days ago</p>
                    
                    {/* Avatar of the notification sender */}
                    <div style={{display: 'flex', alignItems: 'center', width: '100%', overflowX: 'scroll'}}>
                        {!cartList.length > 0 || !gotCartList ? <></> : cartList}
                    </div>
                    
                    {/* Display the header text */}
                    <div className="notification__headerText" style={{paddingBottom: '5px'}}>
                        <h3>Items({cartList.length})</h3>
                    </div>

                    <div className="notification__special">
                        3.4 mi.
                    </div>

                    {/* Display the Converted HTML text */}
                    <div className="notification__headerDescription">

                        Hi! <b>1 person</b> requested <b>{cartList.length} items</b> and will pay you <b>$5.25</b> to pick up / deliver the items
                    </div>

                    <div style={{width: '100%', display: 'flex', marginBottom: '10px'}}>

                        <div style={{flex: 1, padding: '10px 5px 0px 0px', boxSizing: 'border-box'}}>
                            {detailOrder.status === 'waiting' && (
                                <div style={{background: '#000', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #000'}}>
                                    <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                        Do This Delivery!
                                    </div>
                                </div>
                            )}
                            {(detailOrder.status === 'collecting' || detailOrder.status === 'in transit' || detailOrder.status === 'arrived') && (
                                <div style={{background: '#fff', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                    <div style={{color: '#000', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                        In Transit
                                    </div>
                                </div>
                            )}
                            {detailOrder.status === 'delivered' && (
                                <div style={{background: '#fff', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                    <div style={{color: '#000', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                        Delivered
                                    </div>
                                </div>
                            )}
                            {detailOrder.status === 'expired' && (
                                <div style={{background: '#fff', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                    <div style={{color: '#000', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                        Offer Expired
                                    </div>
                                </div>
                            )}

                            {(detailOrder.status === 'cancelled' || detailOrder.status === 'customer cancelled') && (
                                <div style={{background: '#fff', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                    <div style={{color: '#000', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                        Order Cancelled
                                    </div>
                                </div>
                            )}

                            {detailOrder.status === 'driver cancelled' && (
                                <div style={{background: '#fff', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                    <div style={{color: '#000', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                        Driver Flaked
                                    </div>
                                </div>
                            )}
                        </div>
                
                    </div>
                </div>
            </div>

            {detailOrder.status === 'waiting' && (
                <div className="notification__icon right_side">
                    <p style={{textAlign: 'center', fontWeight: 'bold'}}>Get paid:</p>
                    <div style={{display: 'flex', color: 'rgb(29, 155, 240)', justifyContent: 'center', alignItems: 'center'}}>
                        <AttachMoneyIcon />
                        <h2 style={{fontSize: '28px', fontWeight: 'bold', marginLeft: '-8px'}}>{detailOrder.driver_tip}</h2>
                    </div>
                </div>
            )}

            {(detailOrder.status === 'collecting' || detailOrder.status === 'in transit' || detailOrder.status === 'arrived') && (
                <div className="notification__icon right_side">
                    <p style={{textAlign: 'center', fontWeight: 'bold'}}>In Transit:</p>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <AutorenewOutlinedIcon />
                    </div>
                </div>
            )}

            {detailOrder.status === 'delivered' && (
                <div className="notification__icon right_side">
                    <p style={{textAlign: 'center', fontWeight: 'bold'}}>Complete:</p>
                    <div style={{display: 'flex', color: '#4BB543', justifyContent: 'center', alignItems: 'center'}}>
                        <DoneAllOutlinedIcon />
                    </div>
                </div>
            )}

            {detailOrder.status === 'expired' && (
                <div className="notification__icon right_side">
                    <p style={{textAlign: 'center', fontWeight: 'bold'}}>Expired:</p>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <TimerOffOutlinedIcon />
                    </div>
                </div>
            )}

            {(detailOrder.status === 'cancelled' || detailOrder.status === 'customer cancelled' || detailOrder.status === 'driver cancelled') && (
                <div className="notification__icon right_side">
                    <p style={{textAlign: 'center', fontWeight: 'bold'}}>Cancelled:</p>
                    <div style={{display: 'flex', color: 'rgb(249, 24, 128)', justifyContent: 'center', alignItems: 'center'}}>
                        <SentimentDissatisfiedOutlinedIcon />
                    </div>
                </div>
            )}
        </div>
    )
}

export default OfferBlock;
