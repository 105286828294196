import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from './Spinner';

// Firebase
import { db } from '../../utils/firebase-config';
import { collection, getDocs, doc} from 'firebase/firestore';

// Light Box
import { SRLWrapper } from "simple-react-lightbox";

// Components
import Image_TweetBox from './Image_TweetBox';
import New_ImageBox from './New_ImageBox';

// Icons = material UI
import CloseIcon from '@material-ui/icons/Close';
import New_ImageChat from '../ChatBox/Messages/Message/New_ImageChat';


const ImageBlock = ({ 
    filesToUploadNum,
    detailPost, 
    dimensions,
    removeImg, 
    admin, 
    post: { 
        urls,
        postUploading,
        last_post_added
    }, 
    nav,
    setHasImg,
    setCheckHasImg,
    checkHasImg,
    executeScroll
}) => {

    const [postImgs, setPostImgs] = useState(null);
    const [gotPostImgs, setGotPostImgs] = useState(false);

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);
    
    // Trigger if post HAS imgs - for font size reasons
    useEffect(() => {
        handleHasImgState();
    }, [postImgs]);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 480;
    const isTablet = windowWidth <= 1000;


    // Function to get post images
    async function getPostImgs() {
        const docRef = doc(db, 'posts', detailPost._id)
        const colRef = collection(docRef, "img_gallery")
        // console.log('FETCHING IMGS')
        try {
            // console.log('GALLERY DATA')
            const galleryData = await getDocs(colRef);
            const galleryList = galleryData.docs.map((doc) => ({...doc.data(), id: doc.id}));
            // console.log(galleryList);
            setPostImgs(galleryList)
            setGotPostImgs(true);
        } catch (err) {
            console.log('ERROR:');
            console.log(err);
        }
    }


    // Check if post images are not fetched yet and detailPost exists
    useEffect(() => {
        
        if(detailPost && last_post_added === detailPost._id) {

            // Fetch post images
            getPostImgs()

            // // Don't fetch imgs again after this
            // setGotPostImgs(true);
        }
    }, [postUploading]);

    // Check if post images are not fetched yet and detailPost exists
    if(!gotPostImgs && detailPost) {

        // Fetch post images
        getPostImgs()

        // Don't fetch imgs again after this
        setGotPostImgs(true);
    }

    // const getPostImgs = async () => {
    //     console.log('FETCHING IMGS')
    //     try {
    //         console.log('GALLERY DATA')
    //         const galleryData = await getDocs(colRef);
    //         const galleryList = galleryData.docs.map((doc) => ({...doc.data(), id: doc.id}));
    //         console.log(galleryList);
    //         setPostImgs(galleryList)
    //         setGotPostImgs(true);
    //     } catch (err) {
    //         console.log('ERROR:');
    //         console.log(err);
    //     }
    // }

    let imgInfo = [];

    if(admin) {
        for (var i = 0; i < urls.length; i++) { 
            if(dimensions && dimensions.length > 0 && dimensions[i] && dimensions[i].width && dimensions[i].height) {
                console.log('********** FILE NAME *********')
                console.log(dimensions[i].fileName)
                console.log(urls[i])
                imgInfo.push({
                    img_name: dimensions[i].fileName,
                    img_path: urls[i],
                    width: dimensions[i].width,
                    height: dimensions[i].height
                })
            } else {
                console.log('********** FILE NAME 2 *********')
                imgInfo.push({
                    img_path: urls[i],
                    width: 0,
                    height: 0
                })
            }
        }
    } else {
        if(postImgs && postImgs.length > 0) {
            postImgs.map(image => {
                imgInfo.push({
                    img_name: image.img_name,
                    img_path: image.img_path,
                    width: image.img_width,
                    height: image.img_height
                })
            })
        } else {
            imgInfo = null;
        }
    }

    // For font size reasons
    const handleHasImgState = () => {
        if(postImgs && postImgs.length > 0 && checkHasImg != null && !checkHasImg) {
            setHasImg(true)
            setCheckHasImg(true);
            // console.log('CHECK HAS IMAGE')
        }
    }

    let imageContent = (<></>);
    // if(imgInfo) {

    //     // Display 4 images in a grid layout
    //     if(imgInfo.length >= 4) {
    //         imageContent = (
    //             <div className={admin ? "tweetBox__imgContainer" : "post__imgContainer"}>
                    
    //                 {/* First Row */}
    //                 <div className={admin ? "tweetBox__imgRow" : "post__imgRow"}>
    //                     <div className={admin ? "tweetBox__imgColumn active" : "post__imgColumn"}>
                            
    //                         {/* Image 1 */}
    //                         <Image_TweetBox index={0} imgInfo={imgInfo} removeImg={removeImg} executeScroll={executeScroll} admin={admin} />
        
    //                     </div>
    //                     <div className={admin ? "tweetBox__imgColumn active" : "post__imgColumn"}>
                            
    //                         {/* Image 2 */}
    //                         <Image_TweetBox index={1} imgInfo={imgInfo} removeImg={removeImg} executeScroll={executeScroll} admin={admin} />

    //                     </div>
    //                 </div>

    //                 {/* Second Row */}
    //                 <div className={admin ? "tweetBox__imgRow" : "post__imgRow"}>
    //                     <div className={admin ? "tweetBox__imgColumn active" : "post__imgColumn"}>
                            
    //                         {/* Image 3 */}
    //                         <Image_TweetBox index={2} imgInfo={imgInfo} removeImg={removeImg} executeScroll={executeScroll} admin={admin} />

    //                     </div>
    //                     <div className={admin ? "tweetBox__imgColumn active" : "post__imgColumn"}>
                            
    //                         {/* Image 4 */}
    //                         <Image_TweetBox index={3} imgInfo={imgInfo} removeImg={removeImg} executeScroll={executeScroll} admin={admin} />
                            
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     } 
        
    //     // Display 3 images in a grid layout
    //     else if(imgInfo.length == 3) {
    //         imageContent = (
    //             <div className={admin ? "tweetBox__imgContainer" : "post__imgContainer"}>

    //                 {/* First Row */}
    //                 <div className={admin ? "tweetBox__imgRow active" : "post__imgRow"}>
                        
    //                     {/* Image 1 */}
    //                     <Image_TweetBox index={0} imgInfo={imgInfo} removeImg={removeImg} executeScroll={executeScroll} admin={admin} />

    //                 </div>

    //                 {/* Second Row */}
    //                 <div className={admin ? "tweetBox__imgRow" : "post__imgRow"}>
    //                     <div className={admin ? "tweetBox__imgColumn active" : "post__imgColumn"}>
                            
    //                         {/* Image 2 */}
    //                         <Image_TweetBox index={1} imgInfo={imgInfo} removeImg={removeImg} executeScroll={executeScroll} admin={admin} />
                            
    //                     </div>
    //                     <div className={admin ? "tweetBox__imgColumn active" : "post__imgColumn"}>

    //                         {/* Image 3 */}
    //                         <Image_TweetBox index={2} imgInfo={imgInfo} removeImg={removeImg} executeScroll={executeScroll} admin={admin} />

    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     } 
        
    //     // Display 2 images in a grid layout
    //     else if(imgInfo.length == 2) {
    //         imageContent = (
    //             <div className={admin ? "tweetBox__imgContainer two_images" : "post__imgContainer two_images"}>
                    
    //                 {/* First Row */}
    //                 <div className={admin ? "tweetBox__imgRow active" : "post__imgRow"}>
                        
    //                     {/* Image 1 */}
    //                     <div style={{boxSizing:'border-box',marginLeft:'auto',marginRight:'auto',overflow:'hidden',position:'relative',overflow:'hidden',display:'flex',alignItems:'center',justifyContent:'center',flexWrap:'wrap',borderRadius:'16px',borderWidth:'1px',borderStyle:'solid',borderColor:'rgb(207,217,222)',width:'100%', maxWidth:'260px',height:'250px',zIndex:10}}>
    //                         <div sytle={{position:'absolute',overflow:'hidden',top:0,right:0,left:0,bottom:0,display:'flex',justifyContent:'center',alignItems:'center', height:'250px'}}>
    //                             <img 
    //                                 src={imgInfo[0]?.img_path}
    //                                 alt="image" 
    //                                 style={{height:'250px'}}
    //                             /> 
    //                         </div>
    //                     </div>
    //                     {/* <Image_TweetBox index={0} imgInfo={imgInfo} removeImg={removeImg} executeScroll={executeScroll} admin={admin} /> */}

    //                 </div>
    //                 <div className={admin ? "tweetBox__imgRow active" : "post__imgRow"}>
                        
    //                     {/* Image 2 */}
    //                     <div style={{boxSizing:'border-box',marginLeft:'auto',marginRight:'auto',overflow:'hidden',position:'relative',overflow:'hidden',display:'flex',alignItems:'center',justifyContent:'center',flexWrap:'wrap',borderRadius:'16px',borderWidth:'1px',borderStyle:'solid',borderColor:'rgb(207,217,222)',width:'100%', maxWidth:'260px',height:'250px',zIndex:10}}>
    //                         <div sytle={{position:'absolute',overflow:'hidden',top:0,right:0,left:0,bottom:0,display:'flex',justifyContent:'center',alignItems:'center', height:'250px'}}>
    //                             <img 
    //                                 src={imgInfo[1]?.img_path}
    //                                 alt="image" 
    //                                 style={{height:'250px'}}
    //                             /> 
    //                         </div>
    //                     </div>
    //                     {/* <Image_TweetBox index={1} imgInfo={imgInfo} removeImg={removeImg} executeScroll={executeScroll} admin={admin} /> */}

    //                 </div>
    //             </div>
    //         )
    //     } 
        
    //     // Display 1 images in a grid layout
    //     else if(imgInfo.length == 1) {
    //         imageContent = (
    //             <div className={admin ? "tweetBox__imgContainer active one_image" : "post__imgContainer one_image"}>
                    
    //                 {/* Image 1 */}
    //                 <Image_TweetBox index={0} imgInfo={imgInfo} removeImg={removeImg} executeScroll={executeScroll} admin={admin} />

    //             </div>
    //         )
    //     }
    // }

    if(imgInfo) {
        imageContent = imgInfo.map((img_obj, index) => {

            return (  
                <Fragment>
                    {img_obj.img_name?.includes('mov') || img_obj.img_name?.includes('mp4') ? 
                        <New_ImageChat 
                            index={index}
                            img_obj={img_obj}
                            executeScroll={executeScroll}
                            admin={admin}
                            removeImg={removeImg}
                        />
                    : 
                        <New_ImageBox
                            index={index}
                            img_obj={img_obj}
                            executeScroll={executeScroll}
                            admin={admin}
                            removeImg={removeImg}
                        />
                    }
                </Fragment>
                
            )
        })
    }

    const options = {
        caption: {
            showCaption: false // Set to false to hide the caption
        },
        buttons: {
            showAutoplayButton: false, // Set to false to hide the autoplay button
            showFullscreenButton: false, // Set to false to hide the fullscreen button
            showThumbnailsButton: false, // Set to false to hide the thumbnails button
        }
    }

    const optionsNoThumbnail = {
        caption: {
            showCaption: false 
        },
        thumbnails: {
            showThumbnails: false 
        },
        buttons: {
            showAutoplayButton: false, 
            showFullscreenButton: false, 
            showNextButton: false,
            showPrevButton: false,
            showThumbnailsButton: false, 
        }
    }


    // const images = [];

    // if(detailPost && detailPost.img_gallery) {
    //     detailPost.img_gallery.map(imageObj => {
    //         images.push({
    //             src: `https://oubuysell.com/api/users/image/${imageObj.img_name}`,
    //             thumbnail: `https://oubuysell.com/api/users/image/${imageObj.img_name}`,
    //             caption: '',
    //             width: 'auto',
    //             height: 'auto'
    //         })
    //     })
    // }

    return (
        <Fragment>

            {/* SRLWrapper is a lightbox for full screen image display */}
            <SRLWrapper options={nav.page === 'home' || nav.page === 'profile' ? optionsNoThumbnail : options}>
                
                {/* Render if this component originates from the tweetBox */}
                {/* {admin ? (
                    filesToUploadNum > 0 ? (

                        // Render the image content if there are URLs available
                        urls.length > 0 ? (
                            imageContent
                        ) : (

                            // Render spinner placeholders for 4 images when there are files to upload
                            filesToUploadNum >= 4 ? (
                                <div className="tweetBox__imgContainer">
                                    <div className="tweetBox__imgRow">
                                        <div className="tweetBox__imgColumn active">
                                            <div className="tweetBox__img--image">
                                                <Spinner />
                                            </div>
                                        </div>
                                        <div className="tweetBox__imgColumn active">
                                            <div className="tweetBox__img--image">
                                                <Spinner />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tweetBox__imgRow">
                                        <div className="tweetBox__imgColumn active">
                                            <div className="tweetBox__img--image">
                                                <Spinner />
                                            </div>
                                        </div>
                                        <div className="tweetBox__imgColumn active">
                                            <div className="tweetBox__img--image">
                                                <Spinner />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (

                                // Render spinner placeholders for 3 images when there are files to upload
                                filesToUploadNum == 3 ? (
                                    <div className="tweetBox__imgContainer">
                                        <div className="tweetBox__imgRow active">
                                            <div className="tweetBox__img--image">
                                                <Spinner />
                                            </div>
                                        </div>
                                        <div className="tweetBox__imgRow">
                                            <div className="tweetBox__imgColumn active">
                                                <div className="tweetBox__img--image">
                                                    <Spinner />
                                                </div>
                                            </div>
                                            <div className="tweetBox__imgColumn active">
                                                <div className="tweetBox__img--image">
                                                    <Spinner />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (

                                    // Render spinner placeholders for 2 images when there are files to upload
                                    filesToUploadNum == 2 ? (
                                        <div className="tweetBox__imgContainer">
                                            <div className="tweetBox__imgRow active">
                                                <div className="tweetBox__img--image">
                                                    <Spinner />
                                                </div>
                                            </div>
                                            <div className="tweetBox__imgRow active">
                                                <div className="tweetBox__img--image">
                                                    <Spinner />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (

                                        // Render spinner placeholders for 1 image when there are files to upload
                                        filesToUploadNum == 1 && (
                                            <div className={admin ? "tweetBox__imgContainer active one_image" : "post__imgContainer one_image"}>
                                                <div className="tweetBox__img--image">
                                                    <Spinner />
                                                </div>
                                            </div>
                                        )
                                    )
                                )
                            )
                        )
                    ) : (

                    // Render an empty fragment when there are no files to upload

                    <></>
                    
                    )
                ) : imageContent // Render the image content for non-admin users
                } */}

                <div className={`new_imgContainer ${admin ? 'admin' : ''}`}>
                    {imageContent}
                </div>
            </SRLWrapper>
        </Fragment>
    )
}


ImageBlock.propTypes = {
    // Prop type validation for post data
    post: PropTypes.object.isRequired,
    // Prop type validation for navigation
    nav: PropTypes.object.isRequired,
}

// Map these states from Redux store to the component props
const mapStateToProps = state => ({
    post: state.post,
    nav: state.nav
})

// Connect the ImageBlock component to the Redux store and export it
export default connect(mapStateToProps, null)(ImageBlock);

