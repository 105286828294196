import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Actions
import { changeUserPhone } from '../../../actions/authActions';
import { setPage, add_To_Recent_History } from '../../../actions/navActions';

// Modal

// Components - imported
import { Button } from '@material-ui/core';

// Icons - material UI

const ChangePhone = ({
    auth: {
        user
    },
    changeUserPhone,
    setPage, 
    add_To_Recent_History
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Input Field
    const [phoneNumber, setPhoneNumber] = useState('');

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {

        console.log('CURRENT URL OBJ')
        console.log(url)

    }, []);

    useEffect(() => {

        if(user?.phone) {
            setPhoneNumber(user.phone)
        }

    }, [user]);

    // Initialize Mixpanel and track settings account page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Cart: Add Phone # Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {

        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    const phoneTodo = (e) => {
        onSubmitPhone(e);
    }

    const onSubmitPhone = async e => {
        e.preventDefault();

        if(user) {
            const userId = user._id; 


            changeUserPhone(
                { 
                    phone: phoneNumber,
                    userId,
                },
                true
            );

            console.log('ADDING PHONE # FRONT END')
            console.log(phoneNumber);
        }
    }

    return (
        <Fragment>
            <div className="settings">
                
                {/* Title */}
                <div style={{padding: '10px 20px 0'}}>
                    <h3>
                        Add Phone Number
                    </h3>
                </div>

                {/* Main Content */}
                <div className="settings__body">
                    <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', justifyContent:'center', borderBottom:'1px solid #e6ecf0'}}>
                        
                        {/* Phone Input */}
                        <input
                            type="phone"
                            placeholder="Enter Phone #"
                            className="input"
                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={e => setPhoneNumber(e.target.value)}
                            maxLength="20"
                            style={{width: '100%'}}
                        />
                    </div>

                    {/* Submit */}
                    <div style={{width: '100%', boxSizing:'border-box', padding:'0 10px', display:'flex', justifyContent:'flex-end'}}>
                        <Button 
                            variant="outlined" 
                            className="sidebar__tweet save" 
                            onClick={phoneTodo}
                        >
                            Save and Continue
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

ChangePhone.propTypes = {
    auth: PropTypes.object.isRequired,
    changeUserPhone: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    changeUserPhone,
    setPage, 
    add_To_Recent_History
})(ChangePhone);
