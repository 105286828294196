import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

// Components - imported
import { Button } from '@material-ui/core';

// Images
import placeOrder_img from '../../utils/imgs/placeOrder_img.png';
import ultraFastDeliv_img from '../../utils/imgs/ultraFastDeliv_img.jpg';
import groceryBag_img from '../../utils/imgs/groceryBag_img.png';

const HowItWorks = () => {

    // Render the component
    return (
        <Fragment>
            <div style={{padding: '16px'}}>
                {/* <div style={{color:'rgb(15, 20, 25)', marginBottom: '1.5rem', textAlign:'center', fontWeight:'700', fontSize:'24px', lineHeight:'24px', overflowWrap:'break-word'}}>
                    <span style={{fontSize: '1.125rem'}}>Grocery &mdash; How it Works</span>
                </div> */}
                <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                    <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem', overflow: 'hidden'}}>
                        <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '100%', height: 'auto'}} src={placeOrder_img} />
                    </div>
                    <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                            Pick your groceries
                        </h5>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                            Browse our categories.
                        </h5>
                    </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                    <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem', overflow: 'hidden'}}>
                        <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '100%', height: 'auto'}} src={ultraFastDeliv_img} />
                    </div>
                    <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                            Ultra fast delivery
                        </h5>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                            In 30 minutes or less.
                        </h5>
                    </div>
                </div>
                
                <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem', overflow: 'hidden'}}>
                        <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '100%', height: 'auto'}} src={groceryBag_img} />
                    </div>
                    <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                            WE'RE OPEN
                        </h5>
                        <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                            8am to 1am Daily
                        </h5>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

// Prop types for the component
HowItWorks.propTypes = {

    // Prop type validation for function to toggle the Authentication modal
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { 

    // Connecting actions from Redux to the component
})(HowItWorks);
