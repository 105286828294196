import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions - imported
import { toggleSideNav, setPage } from '../../actions/navActions';
import { getDiscountsByUserID } from '../../actions/discountActions';

// Components
import Spinner from '../common/Spinner';
import Coupon_Block from './Coupon_Block';

const Coupon_List = ({
    auth: {
        user
    },
    discount,
    cart: {
        cart
    },
    getDiscountsByUserID,
    page,
    applyDiscount,
    setFormData,
    formData
}) => {

    // Orders List
    const [couponList, setCouponList] = useState([]);
    const [gotDiscounts, setGotDiscounts] = useState(false);

    useEffect(() => {

        if(user) {
            getDiscountsByUserID(user._id);
        }

    }, [user]);

    useEffect(() => {

        renderCouponList();
    }, [discount.discounts, cart.discounts]);

    const renderCouponList = () => {
        setCouponList([]);

        if(discount.discounts === null) {
            setCouponList([(
                <Spinner />
            )])
        }
        else { 
            if(discount.discounts.length > 0) {

                discount.discounts.map(discount_item => {
                    setCouponList(discountList => [...discountList, (
                        <Coupon_Block
                            page={page}
                            applyDiscount={applyDiscount} 
                            setFormData={setFormData} 
                            formData={formData}
                            discount_obj={discount_item}
                            cart={cart}
                        />
                    )])
                });

                setGotDiscounts(true);
            } else {
                setCouponList([(
                    <div className="no-rides">
                        <h1>No Coupons</h1>
                    </div>
                )])
            }
        } 
    }


    return (
        <Fragment>

            {couponList}
            
        </Fragment>
    )
}

Coupon_List.propTypes = {
    auth: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    getDiscountsByUserID: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    discount: state.discount,
    cart: state.cart
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    toggleSideNav, 
    getDiscountsByUserID

})(Coupon_List);