import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions - imported
import { toggleSideNav, setPage } from '../../actions/navActions';

// Components
import Spinner from '../common/Spinner';
import Order from './Order';

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Orders_List = ({
    auth: {
        user
    },
    nav: {
        sideNav
    },
    order,
    history
}) => {

    // Orders List
    const [orderList, setOrderList] = useState([]);
    const [gotOrders, setGotOrders] = useState(false);

    useEffect(() => {

        // Update the header animation based on the sideNav state
        if(sideNav) {
            document.getElementById('feed-header').classList.remove("active");
            return "feed__header";
        } else {
            setTimeout(() => {
                document.getElementById('feed-header').classList.add("active");
            }, 700)
        }
    }, [sideNav])

    useEffect(() => {

        renderOrderList();
    }, [order.orders]);

    const renderOrderList = () => {
        setOrderList([]);

        if(order.orders === null) {
            setOrderList([(
                <Spinner />
            )])
        }
        else { 
            if(order.orders.length > 0) {

                order.orders.map(order_item => {
                    setOrderList(orderList => [...orderList, (
                        <Order
                            detailOrder={order_item}
                        />
                    )])
                });

                setGotOrders(true);
            } else {
                setOrderList([(
                    <div className="no-rides">
                        <h1>No Orders</h1>
                    </div>
                )])
            }
        } 
    }

    // Extract URL and previous URL page
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    let previousUrl = url.searchParams.get("goBack");

    const goBack = () => {
        if(previousUrl) {
            history.goBack();
        } else {
            window.location.href = '/settings';
        }
    }

    return (
        <div className="feed">
            {/**Header */}
            <div className="feed__header" id="feed-header">
                <div style={{display: 'flex', alignItems: 'center', paddingBottom: '10px', boxSizing: 'border-box'}}>
                {/* Left arrow Icon */}
                <div onClick={goBack} style={{marginRight: '10px'}} className="shopFeed__backBtn">
                    <div>
                        <ArrowBackIcon />
                    </div>
                </div>
                <h2>My Orders</h2>
                </div>
                
            </div>

            {orderList}
            
        </div>
    )
}

Orders_List.propTypes = {
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    auth: state.auth,
    order: state.order
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    toggleSideNav, 
})(Orders_List);