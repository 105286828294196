import React, { useState, useEffect } from 'react';

// Firebase
import { db } from '../../../utils/firebase-config';
import { collection, getDocs, doc} from 'firebase/firestore';

const Item_Img = ({
    orderItem
}) => {

    const [itemImgs, setItemImgs] = useState(null);
    const [gotItemImgs, setGotItemImgs] = useState(false);
    const [hasImg, setHasImg] = useState(false);

    // Trigger if item HAS imgs - for font size reasons
    useEffect(() => {
        handleHasImgState();
    }, [itemImgs]);

    // For font size reasons
    const handleHasImgState = () => {
        if(itemImgs && itemImgs.length > 0) {
            
            setHasImg(true)
        }
    }

    // Function to get item images
    async function getItemImgs() {

        const docRef = doc(db, 'products', orderItem.item._id)
        const colRef = collection(docRef, "img_gallery")

        try {

            const galleryData = await getDocs(colRef);
            let galleryList = galleryData.docs.map((doc) => ({...doc.data(), _id: doc.id}));

            if(galleryList.length > 0) {
                galleryList = galleryList.sort((a, b) => a.img_order - b.img_order);
            }
            
            setItemImgs(galleryList)
            setGotItemImgs(true);
        } catch (err) {
            console.log('ERROR:');
            console.log(err);
        }
    }

    // Check if item images are not fetched yet and detailitem exists
    if(!gotItemImgs && orderItem) {

        // Fetch item images
        getItemImgs()

        // Don't fetch imgs again after this
        setGotItemImgs(true);
    }

    let imgInfo = [];

    if(itemImgs && itemImgs.length > 0) {
        itemImgs.map(image => {
            imgInfo.push({
                img_path: image.img_path,
                width: image.img_width,
                height: image.img_height,
                img_order: image.img_order,
            })
        })
    } else {
        imgInfo = null;
    }

    return (
        <div className="orderItem__img">
            {hasImg && <img style={{height: '100%'}} src={imgInfo[0].img_path} alt="img" />}
        </div>
    )
}

export default Item_Img;
