import { combineReducers } from 'redux';
import alert from './alertReducer';
import auth from './authReducer';
import nav from './navReducer';
import chat from './chatReducer';
import notification from './notificationReducer';
import search from './searchReducer';

// -- Marketplace ---

import menu from './menuReducer';
import cart from './cartReducer';
import product from './productReducer';
import collection from './collectionReducer';
import location from './locationReducer';
import order from './orderReducer';
import refund from './refundReducer';
import discount from './discountReducer';
import discount_group from './discount_GroupReducer';
import zone from './zoneReducer';

export default combineReducers({
    alert,
    auth,
    nav,
    chat,
    notification,
    search,

// -- Marketplace ----

    menu,
    cart,
    product,
    collection,
    location,
    order,
    refund,
    discount,
    discount_group,
    zone
});