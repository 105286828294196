import React, { Fragment } from 'react';
import styled from 'styled-components';

import { HorizontalNav } from '../../../components/common/HorizontalNav';

const ImageOverviewItems = ({images, changeImage, showImage, detailProduct}) => {
    
    let imageList;

    if(images && images.length > 0) {
        imageList = images.map((image, index) => (
            <ImageOverviewItem>
                <div className="mobile-detail-image-container">
                    <img key={index} style={{height:'100%'}} onClick={() => changeImage(index)} src={images[index].img_path} alt={detailProduct.name} />
                    <div className="detail-image-overlay" onClick={() => changeImage(index)}></div>
                </div>
            </ImageOverviewItem>
        ));
    }
    else {
        imageList = <p style={{margin:0}}>No more photos...</p>
    }

    return (
        <HorizontalNav background="white">
            {imageList}
            
            {images && images.length > 0 ? (
                <ImageOverviewItem>
                    <div style={{height:'200px', width:'200px'}}></div>
                </ImageOverviewItem>
            ) : null}
        </HorizontalNav>
    )
}

const ImageOverviewItem = styled.div`
    display: inline-box;
    margin: 0 5px;
`;

export default ImageOverviewItems;
