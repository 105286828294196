import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Route, useHistory, Link } from 'react-router-dom';
import Div100vh, { use100vh } from 'react-div-100vh';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faTwitter, faSnapchat } from '@fortawesome/free-brands-svg-icons'
// import { faTwitter } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// Firebase
import { db } from './utils/firebase-config';
import { collection, getDocs, query, where, doc } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Routing
import Routes from './components/routes/Routes';

// Actions
import { setAlert } from './actions/alertActions';
import { toggleSideNav, toggleUserModal, toggleProfileModal, togglePostModal, toggleShareModal, toggleAuthModal, setProfileModal, toggle_StartDelivery_Modal, toggleDiscountModal, toggleModal_DiscountDetails, toggleModal_DiscountList_Preview, toggleModal_StoreHours, toggleModal_HowItWorks, toggleModal_ProcessingOrder } from './actions/navActions';
import { createChat, getUnreadChats } from './actions/chatActions';
import { setUsers, getUserById, clearUser } from './actions/authActions';
import { getUnreadCount } from './actions/notificationActions';
import { addToCart, decrement, removeItem } from './actions/cartActions';
import { getDriverDeliveries } from './actions/orderActions';
import { clearModalDiscount, claimDiscount, getModalDiscountById } from './actions/discountActions';

// Components - imported
import SidebarMobile from './components/sidebar/SidebarMobile';
import Sidebar from './components/sidebar/Sidebar';
import Backdrop from './components/common/Backdrop';
import HowItWorks from './Marketplace/common/HowItWorks';
import StoreHours from './Marketplace/common/StoreHours';
import DiscountList_Preview_Modal_Body from './Marketplace/components/Discount_Components/Discount_Modal_Bodies/DiscountList_Preview_Modal_Body';

// Modal
import Modal from './components/modal/Modal';
import AuthModal from './components/modal/AuthModal';
import UserModal from './components/modal/UserModal';
import BigModal from './components/modal/BigModal';
import ModalContainer from './components/modal/ModalContainer';
import CommentModal from './components/modal/CommentModal';
import LocationModal from './components/modal/LocationModal/LocationModal';
import DiscountModal from './components/modal/DiscountModal';

// Components - imported
import { Avatar, Button } from '@material-ui/core';
import Spinner from './components/common/Spinner';
import Logo from './components/common/Logo';
import TweetBox from './components/TweetBox/TweetBox';
import DefaultAvatar from 'react-avatar';
import AuthPage_Body from './pages/auth/AuthPage_Body';

import UserTable from './components/Tables/UserTable/UserTable';

// Sliding Edit Profile Form - Modal content
import ProfileForm from './components/Profile/ProfileModal/ProfileForm';

// Icons - material UI
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SearchIcon from '@material-ui/icons/Search';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CreateIcon from '@material-ui/icons/Create';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddIcon from '@material-ui/icons/Add';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import InstagramIcon from '@material-ui/icons/Instagram';
import RemoveIcon from '@material-ui/icons/Remove';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import FastfoodOutlinedIcon from '@material-ui/icons/FastfoodOutlined';
import FastfoodIcon from '@material-ui/icons/Fastfood';

// Images
import couponIMG from './utils/imgs/coupon.png';
import miitoken_logo_2 from './utils/imgs/miitoken_logo_2.png';

const Body = ({ 
    createChat,
    setUsers,
    getUserById,
    clearUser,
    auth,
    setAlert,
    toggleUserModal,
    toggleProfileModal,
    toggleAuthModal,
    toggleDiscountModal,
    toggleModal_DiscountDetails,
    toggleModal_DiscountList_Preview,
    toggleModal_StoreHours,
    toggleModal_HowItWorks,
    toggleModal_ProcessingOrder,
    clearModalDiscount,
    claimDiscount,
    getModalDiscountById,
    setProfileModal,
    toggleShareModal,
    toggleSideNav,
    toggle_StartDelivery_Modal,
    getDriverDeliveries,
    nav: { 
        sideNav,
        userModal,
        profileModal,
        commentModal,
        shareModal,
        authModal,
        authModalType,
        page,
        loading,
        discountModal,
        modal_discountDetails,
        modal_DiscountList_Preview,
        modal_StoreHours,
        modal_HowItWorks,
        modal_ProcessingOrder
    },
    notification,
    chat,
    cart,
    addToCart,
    decrement,
    removeItem,
    product: {
        detailProduct
    },
    order: {
        delivery_loading,
        deliveries
    },
    locationState: {
        detailLocation,
    },
    discount: {
        modal_discount,
        modal_loading
    },
    zone
}) => {

    // Window width state
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // SideNav and Overlay should disappear if not isMobile
    const [resetSideNav, setResetSideNav] = useState(false);

    // State to determine highlighted mobile navbar option - bottom of screen
    const [navHighlight, setNavHighlight] = useState(null);

    // Modal toggles
    const [accountUpdatingModal, setAccountUpdatingModal] = useState(false);
    const [messageModal, setMessageModal] = useState(false);

    // --- Search stuff

    const [searchText, setSearchText] = useState('');

    const [searchUserList, setSearchUserList] = useState([]);
    
    const [userListOptionIndex, setUserListOptionIndex] = useState(-1);

    const [searchUserListArray, setSearchUserListArray] = useState([]);

    // Cart Value
    const [isInCart, setIsInCart] = useState(false);
    const [cartQty, setCartQty] = useState(0);
    const [prodID, setProductID] = useState(null);
    const [cartHasItems, setCartHasItems] = useState(false);

    // --- END Search stuff

    let history = useHistory();

    // Get URL parameters
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const showProfileModal = url.searchParams.get("setUp");
    const cartShowFilter = url.searchParams.get("show");

    // Algoia Search
    const searchUsersClient = algoliasearch('H8YQ3RB3ZI', 'b797e6858550b93ae143de5b4941f4e9');
    const usersIndex = searchUsersClient.initIndex('users');

    // --- Alert Badges

        // State to determine whether to display the badge or not
        const [showBadge, setShowBadge] = useState(false);

        // State to hold the value to be displayed on the badge
        const [badgeValue, setBadgeValue] = useState(0);

        const [chatBadgeValue, setChatBadgeValue] = useState(0);

        // State to track if the badge value has been lowered
        const [loweredBadge, setLoweredBadge] = useState(false);

    // --- END Alert Badges

    // -- New User Gift Modal
        const [isNewUserGift, setIsNewUserGift] = useState(false);
    // -- END: New User Gift Modal

    // Marketplace
    const { items, qty } = cart;

    // Get URL params
    useEffect(() => {
        if(detailProduct) {

            
            console.log('PRODUCT ID: ', detailProduct._id)
            setProductID(detailProduct._id);

        }
    }, [detailProduct]);

    // Check if the user has unread notifications or messages
    useEffect(() => {
        if(auth.user) {

                // If there are unread notifications
                if(!notification.read) {

                    // Fetch the count of unread notifications
                    getUnreadCount(auth.user._id);
                } 

                // Fetch the count of unread messages
                getUnreadChats();

        }
    }, [notification.notifications, notification.read, auth.user, page]);

    // Lower the badge value if the chat is opened
    useEffect(() => {
        if(chat.chat && !loweredBadge) {
            
            // Lower the badge value by 1
            lowerBadgeValue();
        }

        if(page === 'notifications') {
            setBadgeValue(0);
        }
        
    }, [chat.chat, page])

    // Update the badge value based on the unread notifications or messages count
    useEffect(() => {

        // Update the badge value with the count of unread notifications
        setBadgeValue(notification.num_unread);

        // Update the badge value with the count of unread messages
        setChatBadgeValue(chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])


    // Lower the badge value by 1
    const lowerBadgeValue = () => {
        console.log('LOWER BADGE #');

        // Decrease the badge value by 1
        const newValue = badgeValue - 1;
        const newChatValue = chatBadgeValue - 1;
        
        // Update the badge value state
        setBadgeValue(newValue);
        setChatBadgeValue(newChatValue);

        // Set the flag to indicate that the badge value has been lowered
        setLoweredBadge(true);
    }

    // Listen for window resize
    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        getDriverDeliveries()

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Check if user is assigned to any deliveries
    useEffect(() => {
        
        if(auth.user) {
            getDriverDeliveries(auth.user._id)
        }

    }, [auth.user]);

    // SideNav and Overlay should disappear if not isMobile
    useEffect(() => {
        if(resetSideNav) {
            toggleSideNav();
            setResetSideNav(false);
        }
    }, [resetSideNav]);

    // --- Set the navigation highlight based on the current page
    useEffect(() => {
        if(page === 'home') {
            setNavHighlight('home');
        }
        if(page === 'search' || page === 'marketplace search') {
            setNavHighlight('search');
        }
        if(page === 'notifications') {
            setNavHighlight('notifications');
        }

        if(page === 'messages') {
            setNavHighlight('messages');
        }

        if(page === 'saved') {
            setNavHighlight('saved');
        }

        if(page === 'profile') {
            setNavHighlight('profile');
        }

        if(page === 'settings') {
            setNavHighlight('settings');
        }

        // - Marketplace ---
        
        if(page === 'marketplace' || page === 'product detail' || page === 'cart' || page === 'checkout' || page === 'collection' || page === 'grocery landing') {
            setNavHighlight('grocery');
        }

    }, [page]);

    useEffect(() => {
        
        checkCartStatus()
    }, [cart, prodID]);

    const checkCartStatus = () => {
        console.log('CHECKING CART STATUS, ', prodID)
        setIsInCart(false);

        if(items.length > 0) {

            setCartHasItems(true);

            items.map(item => {
                if(item.item._id === prodID) {
                    setIsInCart(true);
                    setCartQty(item.qty);
                }
            })
        } else {
            setCartHasItems(false);
        }
    }

    const handleAddToCart = async () => {
        console.log('HANDLE ADD TO CART');

        // Function to get product images
        const productDocRef = doc(db, 'products', prodID)
        const imgGalleryRef = collection(productDocRef, "img_gallery")
        // console.log('FETCHING IMGS')
        try {
            // console.log('GALLERY DATA')
            const galleryData = await getDocs(imgGalleryRef);
            const galleryList = galleryData.docs.map((doc) => ({...doc.data(), id: doc.id}));

            // Add Item to cart along with its image URL
            addToCart(prodID, galleryList[0].img_path);
        
        } catch (err) {
            console.log('ERROR:');
            console.log(err);

            setAlert('Error adding to bag!', 'danger')
        }
    }
    
    // Update window width on resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    const pageHeight = use100vh();

    // Show the Edit Profile modal if showProfileModal is true
    useEffect(() => {
        if(auth.user && showProfileModal === 'true') {
            setProfileModal(true);
        }

    }, [page, auth.user])

    useEffect(() => {

        if(page === 'messages') {
            if(searchText.length === 0) {
                clearUser();
    
                setSearchUserList([]);
                
                setSearchUserListArray([]);
            } else {
                clearUser();
            }
        }

    }, [searchText])

    useEffect(() => {

        if(auth.modalUser && searchText.length > 0) {
            toggleMessageModal()
        }

    }, [auth.modalUser])

    // Toggle Auth Discount Reward Modal after Registration
    useEffect(() => {

        if(localStorage.auth_Reward_Claimed) {
            getModalDiscountById(localStorage.auth_Reward_Claimed);
            setIsNewUserGift(true);
            toggleDiscountModal();
        }

    }, [localStorage.auth_Reward_Claimed])

    // Add Font Awesome icon from the FA library
    library.add(fab, faTwitter)

    const toggleAccountUpdatingModal = () => {
        setAccountUpdatingModal(!accountUpdatingModal);
    }

    const handleModal_ProcessingOrder = () => {
        toggleModal_ProcessingOrder();
    }

    const handleUserClick = (hit) => {
        if(auth.modalUser) {
            clearUser();
        } else {
            getUserById(hit.objectID);
        }
    }

    // Function to handle input change in 'New Message' user search modal
    const onChange = e => setSearchText(e.target.value);

    const onDownArrow = () => {

        // TODO: Detect arrow keys when the input is not in focus, 
        // currently if user clicks the cancel button the arrows dont work unless the user clicks the input again

        console.log('SEARCH ARRAY')
        console.log(searchUserListArray.length)

        console.log('SEARCH INDEX')
        console.log(userListOptionIndex + 1)

        console.log('REQ LENGTH INDEX')
        console.log(searchUserList.length)


        let nextIndex = userListOptionIndex + 1;

        if(nextIndex > searchUserListArray.length - 1) {
            nextIndex = 0;
        }

        setUserListOptionIndex(nextIndex);
    }

    const onUpArrow = () => {

        let nextIndex = userListOptionIndex - 1;

        if(nextIndex === -1 ) {
            nextIndex = searchUserListArray.length - 1;
        }

        setUserListOptionIndex(nextIndex);
    }

    const handleUserModal = () => {
        if(userModal) {
            toggleUserModal();
            clearUser();
            setUserListOptionIndex(-1); // Reset search dropdown index
            setSearchUserList([]); // Reset user search
            setSearchUserListArray([]); // Reset user search index array
            setSearchText(''); // Reset searchText
        } else {
            toggleUserModal();
        }
    }

    const toggleMessageModal = () => {

        if(auth.modalUser) {
            setMessageModal(!messageModal);
        }
    }

    const handleDiscountModal = () => {
        if(discountModal) {
            clearModalDiscount();
        }

        if(isNewUserGift && auth.user && modal_discount) {

            claimDiscount(localStorage.auth_Reward_Claimed, auth.user, modal_discount);
            localStorage.removeItem('auth_Reward_Claimed');
            
        }

        toggleDiscountModal();
    }

    const handleModal_DiscountDetails = () => {
        if(modal_discount) {
            clearModalDiscount();
        }

        toggleModal_DiscountDetails();
    }

    const handleModal_DiscountList_Preview = () => {
        if(modal_discount) {
            clearModalDiscount();
        }

        toggleModal_DiscountList_Preview();
    }

    const handleModal_StoreHours = () => {

        toggleModal_StoreHours();
    }

    const handleModal_HowItWorks = () => {

        toggleModal_HowItWorks();
    }

    // User clicked the 'Cancel' button in the message modal
    const toggleTodo = () => {
        toggleMessageModal();
        clearUser();
    }

    // Handle chat creation
    const createNewChat = async () => {

        // Query existing chats between users
        const chatsCollectionRef = collection(db, "chats");

        // Check if chat with 2 users already exists by chat ID

        console.log('CHECK USER CHAT HERE')
        
        const chatsQuery = query(chatsCollectionRef, where("from_user._id", "==", auth.user._id), where("to_user._id", "==", auth.modalUser._id));
        const otherChatsQuery = query(chatsCollectionRef, where("to_user._id", "==", auth.user._id), where("from_user._id", "==", auth.modalUser._id));

        const chatsSnapshot = await getDocs(chatsQuery);
        const chats = chatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        const otherChatsSnapshot = await getDocs(otherChatsQuery);
        const otherChats = otherChatsSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Check if chats were retrieved in console (for testing)
        console.log('CHECKING CHATS DATA');
        console.log(chats);
        
        console.log('CHECKING OTHER CHATS DATA');
        console.log(otherChats);

        console.log('CHECK USER CHAT HERE PART 2')

        // If array length > 0 then there is already existing chat & redirect instead
      
        if(chats.length > 0) {
            history.push(`/messages/${chats[0]._id}`);
        } else if (otherChats.length > 0) {
            history.push(`/messages/${otherChats[0]._id}`);
        } else {
            createChat(auth.modalUser, auth.user, history);
        }

        handleUserModal();
        setMessageModal(!messageModal);
        
        console.log('SHOULD BE DONE CREATING');
    }

    // Redirect to register page
    const registerLink = (goBack_Page) => {

        if(goBack_Page === 'cart') {
            window.location = '/user_registration?goBack=cart';
        } else {
            window.location = '/user_registration';
        }
    }

    // Handle copy action
    const handleCopy = () => {

        /* Get the text field */
        var copyLink = document.getElementById("linkToCopy");

        /* Select the text field */
        copyLink.select();
        copyLink.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyLink.value);

        /*Close Modal & show Alert */
        toggleShareModal();
        setAlert('Copied to clipboard', 'okay');
    }

    const handleUserSearch = async (queryText) => {

        if(searchText.length - 1 > 0) {
            setUserListOptionIndex(-1); // Reset search dropdown index

            try {

                if(queryText === '') return;

                const results = await usersIndex.search(queryText);

                console.log('SEARCH RESULT');
                console.log(results);

                setSearchUserList(results.hits.map((doc) => ({...doc, _id: doc.objectID})));

                setSearchUserListArray([...results.hits.map((doc) => ({...doc, _id: doc.objectID}))]);

            } catch (error) {
                console.log(error);
                setSearchUserList([]);
            }
        }
    }

    // Message Modal -- Submit and create new chat on 'Enter'
    const handleSubmit = (queryText) => {
        if(userListOptionIndex > -1) {
            handleUserClick(searchUserListArray[userListOptionIndex])

            console.log('SEARCH THIS WORD')
            console.log(searchUserListArray[userListOptionIndex]);
        } else {
            if(queryText === '') return;

            window.alert('Please select a user!');
        }
    }

    // --  Marketplace ---

    // Decrement cart value
    const handleDecrement = () => {
        if(prodID) {
            if(cartQty) {
                if(cartQty >= 2) {
                    decrement(prodID)
                } else {
                    removeItem(prodID)
                }
            }
        }
    }

    const handle_go_to_checkout = () => {

        if(!auth.loading && auth.user) {
            if(detailLocation) {
                if(auth.user.phone?.length > 0) {
                    window.location = "/checkout"
                } else {
                    window.location = "/cart?show=add_phone"
                }
            } else {
                window.location = "/cart?show=add_address"
            }
        } else {
            if(isTablet) {
                window.location.href = '/user_registration?goBack=cart';
            } else {
                toggleAuthModal('checkout');
            }
        }
    }

    const handle_claim_discount = () => {

        if(modal_discount) {
            if(auth.user) {
                claimDiscount(modal_discount._id, auth.user, modal_discount);

            } else {
                
                // Toggles the authentication modal
                toggleAuthModal('claim discount');
            }

            toggleDiscountModal();
        } else {
            setAlert("Coupon doesn't exist.", 'okay')
        }
    }

    if(isMobile && profileModal && !loading && page !== 'set-up') {

        // If the user is on a mobile device, the profile modal is open,
        // the page is not in the loading state, and the page is not 'set-up',
        // redirect the user to the profile set-up page with a query parameter for navigation.
        history.push({
            pathname: '/profile/set-up',
            search: `?goBack=${page}`,
        })
    }

    if(isMobile && userModal && !loading && page !== 'create') {
        
        // If the user is on a mobile device, the post modal is open,
        // the page is not in the loading state, and the page is not 'create',
        // redirect the user to the Create Post page with a query parameter for navigation.
        history.push({
            pathname: `/create/message`,
            search: `?goBack=${page}`,
        })
    }

    // Redirect user if they are assigned to any deliveries
    // if(!delivery_loading && deliveries.length > 0 && auth.user && page !== 'delivery detail' && page !== 'how to deliver' && page !== 'delivery support') {

    //     // If the user is on a mobile device, the profile modal is open,
    //     // the page is not in the loading state, and the page is not 'set-up',
    //     // redirect the user to the profile set-up page with a query parameter for navigation.
    //     history.push({
    //         pathname: `/delivery/${deliveries[0]._id}`,
    //         search: `?redirect=${page}`,
    //     })
    // }

    let backdrop;

    // Create a backdrop component if the sideNav is open
    if (sideNav) {
        backdrop = <Backdrop click={toggleSideNav} />;
    }

    // SideNav and Overlay should disappear if not isMobile
    if (sideNav && !isMobile && !resetSideNav) {

        // If the sideNav is open, the device is not a mobile device,
        // and the resetSideNav state is not set, set the resetSideNav state to true
        // to show the sideNav and overlay again.
        setResetSideNav(true);
    }

    if (sideNav && isMobile && resetSideNav) {

        // If the sideNav is open, the device is a mobile device,
        // and the resetSideNav state is set, set the resetSideNav state to false
        // to hide the sideNav and overlay.
        setResetSideNav(false);
    }

    if (showProfileModal === 'true' && auth.user && auth.user.profile_set_up && page === 'profile') {

        // If the showProfileModal query parameter is 'true', the user is authenticated,
        // their profile is set up, and page is set to 'profile' 
        // (meaning they just completed their profile),
        // --> redirect the user to the profile page.
        window.location.href = "/profile";
    }

    let user_list = [];

    if(searchUserList.length > 0) {

        // Iterate through the posts array and render each post component   
        for (let i = 0; i < searchUserList.length; i++) {
            const hit = searchUserList[i];
            
            if (hit) {
                user_list.push(
                    <div key={hit.objectID} onClick={() => handleUserClick(hit)} className={userListOptionIndex === i || (auth.modalUser && (hit.objectID === auth.modalUser._id)) ? "userTable__block active" : "userTable__block"}>
                        
                        {/* User Avatar - left */}
                        <div className="userTable__avatar">
                            {hit.img ? (
                                <Avatar src={hit.img} />
                            ) : (
                                <DefaultAvatar name={`${hit.first_name} ${hit.last_name}`} />
                            )}
                        </div>

                        {/* Display the user's name & username */}
                        <div className="userTable__info">
                            <h3>
                                {hit.first_name}{' '}{hit.last_name}
                                <span className="userTable__infoSpecial">
                                    <VerifiedUserIcon className="userTable__badge"></VerifiedUserIcon>
                                </span>
                            </h3>
                            <p>@{hit.username}</p>
                        </div>

                        {/* Show if User Block has been clicked */}
                        <div className="userTable__active">

                            {/* Check if the current user is the same as the selected user */}
                            {auth.modalUser && (hit.objectID === auth.modalUser._id) && <CheckIcon />}
                        </div>
                    </div>
                );
            }
        }
    }

    // const TheHit = ({ hit }) => {
    //     console.log('THE HIT');
    //     console.log(hit)
    //     return (
    //         <div key={hit.objectID} onClick={() => handleUserClick(hit)} className={`userTable__block ${auth.modalUser && (hit.objectID === auth.modalUser._id) ? 'active' : undefined}`}>

    //             {/* User Avatar - left */}
    //             <div className="userTable__avatar">

    //                 {hit.img ? (
    //                     <Avatar src={hit.img} />
    //                 ) : (
    //                     <DefaultAvatar name={`${hit.first_name} ${hit.last_name}`} />
    //                 )}
    //             </div>

    //             {/* Display the user's name & username */}
    //             <div className="userTable__info">
    //                 <h3>
    //                     {hit.first_name}{' '}{hit.last_name}
    //                     <span className="userTable__infoSpecial">
    //                         <VerifiedUserIcon className="userTable__badge"></VerifiedUserIcon>
    //                     </span>
    //                 </h3>
    //                 <p>@{hit.username}</p>
    //             </div>

    //             {/* Show if User Block has been clicked */}
    //             <div className="userTable__active">

    //                 {/* Check if the current user is the same as the selected user */}
    //                 {auth.modalUser && (hit.objectID === auth.modalUser._id) && <CheckIcon />}
    //             </div>
    //         </div>
    //     )
    // }

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(auth.user && auth.user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = auth.user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    let totalBadgeValue;

    totalBadgeValue = badgeValue + chatBadgeValue;

    return (
        <Fragment>
            
            {/* Main App */}
            <div className={sideNav ? "app open": "app"} style={{ height: pageHeight }}>

                {/* Sidebar */}
                {!isMobile ? <Sidebar isMobile={isMobile} isTablet={isTablet} /> : <SidebarMobile slideMenu={sideNav} handleSlideMenu={toggleSideNav} /> }

                {backdrop}

                {/* Routes => Different Pages */}
                <Route component={Routes} />

            </div>
            

            <div className={`mobile-navbar ${!auth.loading && !sideNav && (page !== 'chat' && page !== 'checkout' && page !== 'offer detail') ? "Show" : ""} ${page === "product detail" ? "stacked" : ''}`}>

                    <div className='mobile-navbar-inner'>

                        <div onClick={() => window.location = '/'} className={`mobile-navbar-inner-btn ${navHighlight === "grocery" ? 'active' : ''}`}>                    
                            {navHighlight === "grocery" ? <FastfoodIcon /> : <FastfoodOutlinedIcon />}
                        </div>

                        <div onClick={() => window.location = '/search'} className={`mobile-navbar-inner-btn ${navHighlight === "search" ? 'active' : ''}`}>
                            <SearchOutlinedIcon />
                        </div>
                        

                        <div onClick={() => window.location = '/settings'} className={`mobile-navbar-inner-btn ${navHighlight === "settings" ? 'active' : ''}`}>
                            {!auth.loading && !auth.isAuthenticated ? (
                                <div className={`post__avatar layoutHeader ${navHighlight === "settings" ? 'active' : ''}`} style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px', position: 'relative'}}>
                                    <Avatar />

                                    {totalBadgeValue > 0 && (
                                        <span className="notification-badge badge_for_bottom_nav">
                                            {totalBadgeValue}
                                        </span>
                                    )}
                                    
                                </div>
                            ) : (
                
                                <div className={`post__avatar layoutHeader ${navHighlight === "settings" ? 'active' : ''}`} style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px', position: 'relative'}}>

                                    {auth.user && auth.user.img ? (
                                        <Avatar src={optimized_profile_img_path} />
                                    ) : (
                                        <DefaultAvatar name={auth.user && `${auth.user.first_name} ${auth.user.last_name}`} />
                                    )}

                                    {totalBadgeValue > 0 && (
                                        <span className="notification-badge badge_for_bottom_nav">
                                            {totalBadgeValue}
                                        </span>
                                    )}
                                </div>
                            )}

  
                        </div>

                    </div>
            </div>

            {/* -- Authentication Bottom Alert --- */}

            {/* Authentication Bar found at the bottom of screen - if not logged in */}
            <div className={`auth-alert stacked ${!auth.loading && !auth.isAuthenticated && !sideNav && page != 'cart' && page != 'product detail' ? "Show" : ""}`}>
      
                {/* Desktop Authentication Bar - fixed to bottom of screen - if not logged in */}
                <div className="auth-alert-inner">
                    <div className="auth-alert-headers">
                        
                        {/* Title */}
                        <h2>Groceries, in minutes</h2>
                    </div>

                    {/* Actions */}
                    <div style={{marginLeft: '20px'}}>
                        <Button 
                            onClick={registerLink}
                            className="sidebar__tweet white authAlert noMargin lessPadding"
                        >
                            Join
                        </Button>
                    </div>
                </div>
      
            </div>

            {/* --- Marketplace ----  */}
            
            {/* Mobile 'Add To Cart' btn found at the bottom of screen - on Product Detail Pages */}
            <div className={`mobile-navbar stacked top_of_stack ${isMobile && !sideNav && page === 'product detail' && prodID ? "Show" : ""}`}>

                {!isInCart ? (
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', boxSizing: 'border-box',}}>
                        <div onClick={() => handleAddToCart()} style={{backgroundColor: 'rgb(0, 164, 255)', borderColor: 'rgb(0, 164, 255)', minWidth: '88px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                            <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                                Add to Bag
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{boxSizing: 'border-box', width: '100%', height: '100%', minWidth: '300px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'64px', height:'80%', overflow:'hidden', position:'relative', flex: '0.4'}}>
                            <div style={{display:'flex', cursor:'pointer', alignItems:'center', padding:'2px', boxSizing:'border-box', background:'rgb(236, 238, 233)', borderRadius:'64px', height:'100%', position:'absolute', width:'100%'}}>
                                <div onClick={handleDecrement} style={{borderRadius:'50%', height:'30px', width: '30px', background:'#fff', cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <RemoveIcon />
                                </div>
                                <div style={{alignItems:'center', justifyContent:'center', flex:1, display:'flex'}}>
                                    <div style={{color:'rgb(0, 0, 0)', lineHeight:'23.8px', fontWeight: 'bold', fontSize: '18px', }}>
                                        {cartQty}
                                    </div>
                                </div>
                                <div onClick={() => handleAddToCart()} style={{borderRadius:'50%', height:'30px', width: '30px', color: '#fff', background:'rgba(0,164,255,1.00)', cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <AddIcon />
                                </div>
                            </div>
                        </div>
                        <div style={{marginLeft: '8px', display: 'flex', flex: '0.6', height: '80%'}}>
                            <div onClick={() => {window.location = '/cart'}} style={{cursor: 'pointer', background:'#000', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '64px', overflow:'hidden', paddingLeft: '8px', flex: 1}}>
                                <div style={{marginRight: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', borderRadius: '64px', position: 'relative'}}>
                                    <div style={{display: 'flex', alignItems: 'center', width: '40px', justifyContent: 'center', padding: '6px', borderRadius: '40px'}}>
                                        <div style={{fontSize: '24px', color: '#fff'}}>
                                            <LocalMallOutlinedIcon />
                                        </div>
                                    </div>
                                    {qty > 0 && (
                                        <div style={{position: 'absolute', minWidth: '13px', background: 'rgba(250,216,105,1.00)', left: '21px', height: '13px', top: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1px', borderRadius: '24px'}}>
                                            <div style={{lineHeight: '15.8px', fontWeight: 'bold', fontSize: '11px'}}>
                                                {qty}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div style={{textAlign: 'center', marginRight:'40px', paddingRight: '24px', flex: 1}}>
                                    <div style={{color: '#fff', fontWeight: 'bold', lineHeight: '23.8px', fontSize: '18px'}}>
                                        My Bag
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Mobile 'Checkout' btn found at the bottom of screen - on the Cart Page*/}
            <div className={`mobile-navbar ${isMobile && (zone.isOpen || zone.detailZone === null) && !sideNav && page === 'cart' && cartHasItems && !cartShowFilter ? "Show" : ""}`}>

                <div style={{width: '100%', padding: '8px', boxSizing: 'border-box'}}>
                    <div onClick={handle_go_to_checkout} style={{backgroundColor: '#000', borderColor: '#000', minWidth: '88px', minHeight: '48px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                        <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                            Checkout
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile IF PAGE = CART PAGE but cart is empty btn found at the bottom of screen - on the Cart Page*/}
            <div className={`mobile-navbar ${isMobile && !sideNav && page === 'cart' && !cartHasItems ? "Show" : ""}`}>

                <div style={{width: '100%', padding: '8px', boxSizing: 'border-box'}}>
                    <div onClick={() => window.location = "/marketplace/search"} style={{backgroundColor: 'rgb(0, 164, 255)', borderColor: 'rgb(0, 164, 255)', minWidth: '88px', minHeight: '48px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                        <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                            Find What You Need !
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile 'Place Order' btn found at the bottom of screen - on Checkout Page */}
            {/* <div className={`mobile-navbar ${isMobile && !sideNav && page === 'checkout' ? "Show" : ""}`}>

                <div style={{width: '100%', padding: '8px', boxSizing: 'border-box'}}>
                    <div onClick={() => window.location = "/checkout"} style={{backgroundColor: 'rgb(0, 164, 255)', borderColor: 'rgb(0, 164, 255)', minWidth: '88px', minHeight: '48px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                        <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                            Place Order
                        </div>
                    </div>
                </div>
            </div> */}

            {/* --- END: Marketplace ----  */}

            {/* --- Driver Portal ----  */}

            <div className={`mobile-navbar ${isMobile && !sideNav && page === 'offer detail' ? "Show" : ""}`}>

                <div style={{width: '100%', padding: '8px', boxSizing: 'border-box'}}>
                    <div onClick={toggle_StartDelivery_Modal} style={{backgroundColor: 'rgb(0, 164, 255)', borderColor: 'rgb(0, 164, 255)', minWidth: '88px', minHeight: '48px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', boxSizing: 'border-box', borderRadius: '64px', borderWidth: '2px', flex: 1}}>
                        <div style={{color: '#fff', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '18px', overflow: 'hidden'}}>
                            Do This Delivery!
                        </div>
                    </div>
                </div>
            </div>

            {/* --- END: Driver Portal ----  */}

            {/* Discount Pop Up Modal */}
            <ModalContainer show={discountModal} onClose={handleDiscountModal}>
                <DiscountModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start transparent no_header">
                        <div onClick={handleDiscountModal} className="closeBtn">
                            <CloseOutlinedIcon />
                        </div>
                    </div>

                    {modal_loading && <Spinner />}

                    {!modal_loading && (
                        <Fragment>
                            {modal_discount ? (
                                <Fragment>
                                    {!isNewUserGift ? (
                                        <Fragment>
                                            <div style={{height: '125px', width: '100%', display: 'flex', justifyContent: 'center'}}>
                                                <img style={{objectFit: 'cover', height: '100%'}} src={couponIMG} />
                                            </div>
                                            <div style={{flexDirection: 'column', textAlign: 'center', marginTop: '10px'}}>
                                                <h3 style={{fontSize: '24px', fontWeight: 'bold'}}>
                                                    You've unlocked ${modal_discount.discount_value} off your next order!
                                                </h3>
                                                <p style={{fontSize: '14px', fontWeight: 500}}>
                                                    Min order $20+. Terms apply.
                                                </p>
                                                
                                                <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                                                    {/* Sign Up Button */}
                                                    <Button 
                                                        variant="outlined" 
                                                        className="sidebar__tweet"
                                                        onClick={handle_claim_discount}
                                                    >
                                                        Claim offer
                                                    </Button>
                        
                                                    <div onClick={handleDiscountModal} style={{textDecoration: 'underline', fontWeight: 'bold', margin: '20px 0', cursor: 'pointer'}}>
                                                        No thanks
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment> 
                                    ) : (
                                        <Fragment>
                                             <div style={{flexDirection: 'column', textAlign: 'center', marginTop: '0px'}}>
                                                <h4 style={{fontSize: '20px', fontWeight: 'bold'}}>
                                                   Congrats!
                                                </h4>

                                                <p style={{fontSize: '14px', fontWeight: 500}}>
                                                    --You've unlocked!--
                                                </p>
                                                
                                                <h3 style={{fontSize: '26px', color: 'rgb(249, 24, 128)', fontWeight: 'bold'}}>
                                                   New User Gifts
                                                </h3>
                                            </div>
                                            <div style={{height: '125px', width: '100%', display: 'flex', justifyContent: 'center'}}>
                                                <img style={{objectFit: 'cover', height: '100%'}} src={couponIMG} />
                                            </div>
                                            <div style={{flexDirection: 'column', textAlign: 'center', marginTop: '10px'}}>
                                                
                                                <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                                                    {/* Sign Up Button */}
                                                    <Button 
                                                        variant="outlined" 
                                                        className="sidebar__tweet"
                                                        onClick={handleDiscountModal}
                                                    >
                                                        ENJOY NOW!
                                                    </Button>
                        
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                    
                                </Fragment>
                            ) : (
                                <div>Sorry :( </div>
                            )}
                        </Fragment>
                    )}
                </DiscountModal>
            </ModalContainer>

            {/* Modal to show the details of Claimed Coupons */}
            <ModalContainer show={modal_discountDetails} onClose={handleModal_DiscountDetails}>
                <DiscountModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start transparent no_header">
                        <div onClick={handleModal_DiscountDetails} className="closeBtn">
                            <CloseOutlinedIcon />
                        </div>
                    </div>

                    {modal_loading && <Spinner />}

                    {!modal_loading && (
                        <Fragment>
                            {modal_discount ? (
                                <Fragment>
                                    <div style={{flexDirection: 'column',  marginTop: '30px'}}>
                                        <div style={{fontSize: '28px', fontWeight: 'bold', margin: '8px 0'}}>
                                            Enjoy ${modal_discount.discount_value} off your order!
                                        </div>
                                        <div style={{marginBottom: '16px'}}>
                                            <div style={{fontSize: '18px', margin: '2px 0', fontWeight: 'bold'}}>
                                                Expiration
                                            </div>
                                            <div style={{fontSize: '18px', margin: '2px 0', fontWeight: 500}}>
                                                Wednesday, July 17, 2024 at 4:03 PM CDT 
                                            </div>
                                        </div>

                                        <div style={{marginBottom: '16px'}}>
                                            <div style={{fontSize: '18px', margin: '2px 0', fontWeight: 'bold'}}>
                                                Rules
                                            </div>
                                            <div style={{fontSize: '18px', margin: '2px 0', fontWeight: 500}}>
                                                <ul>
                                                    {modal_discount.type_of_value === 'fixed amount' && (
                                                        <li style={{margin: '2px 0'}}>
                                                            ${modal_discount.discount_value} off {modal_discount.num_uses > 0 ? `${modal_discount.num_uses} orders.` : '1 order.'} 
                                                        </li>
                                                    )}
                                                    
                                                    {modal_discount.min_purchase_amt > 0 && (
                                                        <li style={{margin: '2px 0'}}>
                                                            Minimum cart value of ${modal_discount.min_purchase_amt} needed for offer to apply.
                                                        </li>
                                                    )}

                                                    {modal_discount.min_quantity > 0 && (
                                                        <li style={{margin: '2px 0'}}>
                                                            Minimum cart quanity of {modal_discount.min_quantity} items needed for offer to apply.
                                                        </li>
                                                    )}

                                                    {(!modal_discount.min_quantity && !modal_discount.min_purchase_amt) && (
                                                        <li style={{margin: '2px 0'}}>
                                                            No minimums needed for offer to apply.
                                                        </li>
                                                    )}

                                                    {modal_discount.can_combine ? (
                                                        <li style={{margin: '2px 0'}}>
                                                            Can combine with other discounts.
                                                        </li>
                                                    ) : (
                                                        <li style={{margin: '2px 0'}}>
                                                            Can not combine.
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                                            {/* Sign Up Button */}
                                            <Button 
                                                variant="outlined" 
                                                className="sidebar__tweet"
                                                onClick={handleModal_DiscountDetails}
                                            >
                                                Got it
                                            </Button>

                                            <div onClick={() => window.location = '/settings/coupons'} style={{textDecoration: 'underline', fontWeight: 'bold', margin: '20px auto', cursor: 'pointer'}}>
                                                View My Coupons
                                            </div>
                
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <div>Sorry :( </div>
                            )}
                        </Fragment>
                    )}
                </DiscountModal>
            </ModalContainer>

            {/* Modal to show the details of Automatic Discounts */}
            <ModalContainer show={modal_DiscountList_Preview} onClose={handleModal_DiscountList_Preview}>
                <DiscountModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start transparent no_header">
                        <div onClick={handleModal_DiscountList_Preview} className="closeBtn">
                            <CloseOutlinedIcon />
                        </div>
                    </div>

                    {modal_loading && <Spinner />}

                    {!modal_loading && (
                        <Fragment>
                            {modal_discount ? (
                                <DiscountList_Preview_Modal_Body 
                                    modal_discount={modal_discount}
                                    handleModal_DiscountList_Preview={handleModal_DiscountList_Preview}
                                />
                            ) : (
                                <div>Sorry :( </div>
                            )}
                        </Fragment>
                    )}
                </DiscountModal>
            </ModalContainer>

            {/* Store Hours Modal */}
            <ModalContainer show={modal_StoreHours} onClose={handleModal_StoreHours}>
                <DiscountModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start transparent no_header">
                        <div onClick={handleModal_StoreHours} className="closeBtn">
                            <CloseOutlinedIcon />
                        </div>
                    </div>

                    {zone.loading && <Spinner />}

                    {!zone.loading && (
                        <Fragment>
                            {zone.detailZone ? (
                                <StoreHours handleModal_StoreHours={handleModal_StoreHours} />
                            ) : (
                                <div>Sorry :( </div>
                            )}
                        </Fragment>
                    )}
                </DiscountModal>
            </ModalContainer>

            {/* How It Works Modal */}
            <ModalContainer show={modal_HowItWorks} onClose={handleModal_HowItWorks}>
                <DiscountModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start transparent no_header">
                        <div onClick={handleModal_HowItWorks} className="closeBtn">
                            <CloseOutlinedIcon />
                        </div>
                    </div>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img style={{width: '35%'}} src={miitoken_logo_2} />
                    </div>

                    <div style={{flexDirection: 'column'}}>
                        <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '24px'}}>
                            The #1 College Delivery Service
                        </div>
                        <HowItWorks />
                    </div>

                    <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                        {/* Sign Up Button */}
                        <Button 
                            variant="outlined" 
                            className="sidebar__tweet"
                            onClick={() => window.location = '/select-city'}
                        >
                            Select Your Campus
                        </Button>

                    </div>
                    
                </DiscountModal>
            </ModalContainer>

            {/* Share Website Modal */}
            <ModalContainer show={shareModal} onClose={toggleShareModal}>

                {/* Share Website Modal Content */}
                <CommentModal shareGroup>
                    <div className="closeBtn__container bothSides modalClose">

                        {/* Submit Button */}
                        <div style={{fontSize: '16px', margin:'10px 0', display: 'flex', alignItems:  'center', padding: '7px 16px', fontWeight: '600', textAlign: 'center'}}>
                            Share
                        </div>

                        {/* Close Button */}
                        <button onClick={toggleShareModal} className="secondaryBtn">
                            <CloseOutlinedIcon /> Close
                        </button>
                    </div>
                    <div style={{position:'relative', width:'100%', display:'flex', flexDirection:'row', flex:'1', flexBasis:'0.000000001px'}}>
                        <div style={{overflow:'hidden', whiteSpace:'nowrap'}}>

                            {/* Share via Email */}
                            <a target="_blank" href="mailto:tunde@campusbuysell.com">
                                <div style={{display:'inline-block', marginRight:'8px'}}>
                                    <div style={{margin:'1px 0', border:'none', borderRadius:'3px', padding:'5px 1px 2px', outline:'none'}}>
                                        <div style={{background:'#f9f9f9', border:'1px solid rgba(0, 0, 0, 0.1)', borderRadius:'50%', marginLeft:'4px', marginRight:'4px', marginBottom:'3px', display:'inline-flex', alignItems:'center', justifyContent:'center', position:'relative', verticalAlign:'middle', width:'60px', height:'60px'}}>
                                            <MailIcon style={{color:'#333', width:'35px', height:'35px'}} />
                                        </div>
                                        <div style={{color:'#030303', margin:'auto', width:'68px', maxHeight:'42px', textAlign:'center', whiteSpace:'normal', overflow:'hidden', fontSize:'12px', lineHeight:'1.8rem', fontWeight:'400'}}>
                                        Email
                                        </div>
                                    </div>
                                </div>
                            </a>

                            {/* Share via Twitter */}
                            <a target="_blank" href="https://twitter.com/OUBuySell">
                                <div style={{display:'inline-block', marginRight:'8px'}}>
                                    <div style={{margin:'1px 0', border:'none', borderRadius:'3px', padding:'5px 1px 2px', outline:'none'}}>
                                        <div style={{background:'#1DA1F2', border:'1px solid rgba(0, 0, 0, 0.1)', borderRadius:'50%', marginLeft:'4px', marginRight:'4px', marginBottom:'3px', display:'inline-flex', alignItems:'center', justifyContent:'center', position:'relative', verticalAlign:'middle', width:'60px', height:'60px'}}>
                                            <FontAwesomeIcon icon={faTwitter} style={{color:'#fff', width:'35px', height:'35px'}} />
                                            {/* <i class="fa-brands fa-twitter"></i> */}
                                        </div>
                                        <div style={{color:'#030303', margin:'auto', width:'68px', maxHeight:'42px', textAlign:'center', whiteSpace:'normal', overflow:'hidden', fontSize:'12px', lineHeight:'1.8rem', fontWeight:'400'}}>
                                        Twitter
                                        </div>
                                    </div>
                                </div>
                            </a>

                            {/* Share via Instagram */}
                            <a target="_blank" href="https://www.instagram.com/OUBuySell/?hl=en">
                                <div style={{display:'inline-block', marginRight:'8px'}}>
                                    <div style={{margin:'1px 0', border:'none', borderRadius:'3px', padding:'5px 1px 2px', outline:'none'}}>
                                        <div style={{background:'#2BB741', border:'1px solid rgba(0, 0, 0, 0.1)', borderRadius:'50%', marginLeft:'4px', marginRight:'4px', marginBottom:'3px', display:'inline-flex', alignItems:'center', justifyContent:'center', position:'relative', verticalAlign:'middle', width:'60px', height:'60px'}}>
                                            <InstagramIcon style={{color:'#fff', width:'35px', height:'35px'}} />
                                        </div>
                                        <div style={{color:'#030303', margin:'auto', width:'68px', maxHeight:'42px', textAlign:'center', whiteSpace:'normal', overflow:'hidden', fontSize:'12px', lineHeight:'1.8rem', fontWeight:'400'}}>
                                        Instagram
                                        </div>
                                    </div>
                                </div>
                            </a>

                            {/* Share via Snapchat */}
                            <a target="_blank" href="https://www.snapchat.com/add/OUBuySell?sender_web_id=97d31af2-e177-4aaf-b31a-5ab768c332ad&device_type=desktop&is_copy_url=true">
                                <div style={{display:'inline-block', marginRight:'8px'}}>
                                    <div style={{margin:'1px 0', border:'none', borderRadius:'3px', padding:'5px 1px 2px', outline:'none'}}>
                                        <div style={{background:'#FFFC00', border:'1px solid rgba(0, 0, 0, 0.1)', borderRadius:'50%', marginLeft:'4px', marginRight:'4px', marginBottom:'3px', display:'inline-flex', alignItems:'center', justifyContent:'center', position:'relative', verticalAlign:'middle', width:'60px', height:'60px'}}>
                                            <FontAwesomeIcon icon={faSnapchat} style={{color:'#333', width:'35px', height:'35px'}} />
                                        </div>
                                        <div style={{color:'#030303', margin:'auto', width:'68px', maxHeight:'42px', textAlign:'center', whiteSpace:'normal', overflow:'hidden', fontSize:'12px', lineHeight:'1.8rem', fontWeight:'400'}}>
                                        Sanpchat
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    {/* Share via URL link */}
                    <div style={{marginTop:'8px', marginBottom:'24px', display:'inline-block', height:'42px', width:' 100%'}}>
                        <div onClick={handleCopy} style={{height:'100%', cursor:'pointer', justifyContent:'space-between', border:'1px solid rgba(0, 0, 0, 0.1)', backgroundColor:'#f9f9f9', borderRadius:'2px', display:'flex',alignItems:'center', paddingRight:'16px'}}>
                            <input 
                                readOnly
                                type="text"
                                placeholder={process.env.REACT_APP_DOMAIN}
                                name="linkToCopy"
                                id="linkToCopy"
                                value={process.env.REACT_APP_DOMAIN}
                                style={{width:'100%', cursor:'copy', marginLeft:'16px', border:'none', outline:'none', whiteSpace:'nowrap', backgroundColor:'rgba(0,0,0,0)', color:'#030303', fontSize:'14px'}} 
                            />
                            <div style={{padding:'0 8px', color:'#065fd4', borderRadius:'2px 3px', margin:'0 0 0', textTransform:'uppercase', fontSize:'14px', fontWeight:'500', letterSpacing:'0.5px'}}>
                                <a href="#" style={{textDecoration:'none', color:'#065fd4', fontWeight:'500'}}>
                                    Copy
                                </a>
                            </div>
                        </div>
                    </div>
                </CommentModal>
            </ModalContainer>

            {/* Authentication Modal - if not logged in && triggered an Action */}
            <ModalContainer show={authModal} onClose={toggleAuthModal}>
                <AuthModal>
                    {/* Close Button */}
                    <button onClick={toggleAuthModal} className="secondaryBtn authModal">
                        <CloseOutlinedIcon /> Close
                    </button>

                    {/* -- END: Marketplace */}

                    <AuthPage_Body />
                    
                </AuthModal>
            </ModalContainer>

            {/* Sliding Edit Profile Modal */}
            <ModalContainer show={profileModal} onClose={toggleProfileModal}>
                <BigModal>
                    <ProfileForm />
                </BigModal>
            </ModalContainer>

            {/* Message User Modal */}
            <ModalContainer show={userModal} onClose={handleUserModal}>
                <UserModal>
                    {/* Modal Header */}
                    <div style={{display:'flex', justifyContent:'space-between'}}>

                            {/* Close Button */}
                            <button onClick={handleUserModal} className="secondaryBtn close">
                                <CloseIcon /> 
                                <span>Close</span>
                            </button>

                        {/* Modal Title */}
                        <h2>New Message</h2>

                        {/* Submit Button */}
                        <div>
                            <Button 
                                onClick={toggleMessageModal} 
                                className={`tweetBox__tweetButton ${!auth.modalUser ? 'disabled' : ''}`}
                                disabled={!auth.modalUser}
                            >
                                Next
                            </Button>
                        </div>
                    </div>

                    {/* User Search Bar */}
                    <div className="modal__search">
                        {/* <SearchIcon />
                        <SearchBox /> */}
                        {/* <input placeholder="Search" className="input" type="text" /> */}

                        <SearchIcon />
                        <input 
                            id="search-input" 
                            placeholder="Search OU Buy / Sell" 
                            type="text" 
                            autocomplete="off" 
                            value={searchText}
                            onChange={e => onChange(e)}
                            onKeyDown={(e) => {
                                if(e.key === 'Enter') {
                                    handleSubmit(searchText);
                                } else if(e.keyCode == '40') { // down arrow
                                    onDownArrow();
                                } else if(e.keyCode == '38') { // up arrow
                                    onUpArrow();
                                } else {
                                    handleUserSearch(searchText);
                                }
                            }}
                        />
                    </div>
                    
                    {/* User Search Results */}
                    <div className="modal-table-list-container modular">
                        {/* <UserTable /> */}
                        {/* <Hits hitComponent={TheHit} /> */}

                        {user_list}

                        {!user_list?.length > 0 && ( 

                            <div className='menu-title' style={{display: 'flex', width:'100%', color:'rgb(83, 100, 113)', fontSize:'15px', cursor:'default', justifyContent:'center', alignItems: 'center', paddingTop: '12px'}}>Try searching someone's name . . .</div>
                        )}
                    </div>
                </UserModal>
            </ModalContainer>

            {/* Create Message confirmation Modal */}  
            <ModalContainer show={messageModal} onClose={toggleMessageModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Start Conversation?</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Start a private conversation with <b style={{color: '#333'}}>{auth.modalUser ? `${auth.modalUser.first_name} ${auth.modalUser.last_name && auth.modalUser.last_name}` : 'this person'}</b>.</p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={createNewChat}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Start Conversation
                    </Button>
                    <Button 
                        onClick={toggleTodo}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>

            <ModalContainer show={auth.profile_update_loading} onClose={toggleAccountUpdatingModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Updating account data...</h2>
                    </div>

                    {/* Modal Description */}
                    <p>This can take up to 30 seconds.</p>
                    <p>DO NOT re-load the page!</p>
                    
                    <Spinner />
                </Modal>
            </ModalContainer>

            {/**********************************
             *                                 *
             * --------- Marketplace --------- *
             *                                 *
            * ********************************/}

            {/* Location Modal */}
            <LocationModal />

            <ModalContainer show={modal_ProcessingOrder} onClose={handleModal_ProcessingOrder}>
                <Modal>

                    <div className='icon_50' style={{color: '#0a8800', justifyContent: 'center', display: 'flex'}}>
                        <VerifiedUserIcon />
                    </div>

                    {/* Modal Title */}
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <h3>Payment processing...</h3>
                    </div>
                    
                    <Spinner />

                    {/* Modal Description */}
                    <p>Miitoken is committed to protecting your payment information.</p>

                    <div style={{marginTop: '8px'}}>
                        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                            <img 
                                src="https://aimg.kwcdn.com/upload_aimg/temu/da7f463a-916f-4d91-bcbb-047317a1c35e.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                style={{width: '39px', height: '26px', marginRight: '6px'}}
                            />
                            <img 
                                src="https://aimg.kwcdn.com/upload_aimg/temu/b79a2dc3-b089-4cf8-a907-015a25ca12f2.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                style={{width: '39px', height: '26px', marginRight: '6px'}}
                            />
                            <img 
                                src="https://aimg.kwcdn.com/upload_aimg/temu/936bf9dc-9bb2-4935-9c5a-a70b800d4cf1.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                style={{width: '39px', height: '26px', marginRight: '6px'}}
                            />
                            <img 
                                src="https://aimg.kwcdn.com/upload_aimg/temu/3f39097d-e751-4891-af08-41b63ebc876e.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                style={{width: '39px', height: '26px', marginRight: '6px'}}
                            />
                            <img 
                                src="https://aimg.kwcdn.com/upload_aimg/temu/fb599a1d-6d42-49f2-ba7a-64b16d01b226.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                style={{width: '39px', height: '26px', marginRight: '6px'}}
                            />
                            <img 
                                src="https://aimg.kwcdn.com/upload_aimg/temu/c6962c14-ad79-4856-89e4-32205f96a7de.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                style={{width: '39px', height: '26px', marginRight: '6px'}}
                            />
                            <img 
                                src="https://aimg.kwcdn.com/upload_aimg/temu/c3e5eb19-1b60-4c2b-87e1-4528fb390cbf.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                style={{width: '39px', height: '26px', marginRight: '6px'}}
                            />
                        </div>
                    </div>

                </Modal>
            </ModalContainer>
        </Fragment>
    )
}



Body.propTypes = {
    // Props for navigation
    nav: PropTypes.object.isRequired,
    // Props for authentication
    auth: PropTypes.object.isRequired,
    // Props for the notification state
    notification: PropTypes.object.isRequired,
    // Props for the chat state,
    chat: PropTypes.object.isRequired,
    // Marketplace
    cart: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    zone: PropTypes.object.isRequired,
    locationState: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    // Function to set an alert
    setAlert: PropTypes.func.isRequired,
    // Function to toggle the side navigation
    toggleSideNav: PropTypes.func.isRequired,
    // Function to toggle the user modal
    toggleUserModal: PropTypes.func.isRequired,
    // Function to toggle the profile modal
    toggleProfileModal: PropTypes.func.isRequired,
    // Function to toggle the post modal
    togglePostModal: PropTypes.func.isRequired,
    // Function to toggle the share modal
    toggleShareModal: PropTypes.func.isRequired,
    // Function to toggle the authentication modal
    toggleAuthModal: PropTypes.func.isRequired,
    toggleDiscountModal: PropTypes.func.isRequired,
    toggleModal_DiscountDetails: PropTypes.func.isRequired,
    toggleModal_DiscountList_Preview: PropTypes.func.isRequired,
    toggleModal_StoreHours: PropTypes.func.isRequired,
    toggleModal_HowItWorks: PropTypes.func.isRequired,
    toggleModal_ProcessingOrder: PropTypes.func.isRequired,
    toggle_StartDelivery_Modal: PropTypes.func.isRequired,
    // Function to set the profile modal
    setProfileModal: PropTypes.func.isRequired,
    // Function to create a chat
    createChat: PropTypes.func.isRequired,
    // Function to set users
    setUsers: PropTypes.func.isRequired,
    // Function to get a user by ID
    getUserById: PropTypes.func.isRequired,
    // Function to clear the current modalUser
    clearUser: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    decrement: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    getDriverDeliveries: PropTypes.func.isRequired,
    clearModalDiscount: PropTypes.func.isRequired,
    claimDiscount: PropTypes.func.isRequired,
    getModalDiscountById: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    // Mapping the navigation state
    nav: state.nav,
    // Mapping the authentication state
    auth: state.auth,
    // Mapping the notification state for the alert badges
    notification: state.notification,
    // Mapping the chat state for the alert badge
    chat: state.chat,
    // Marketplace
    cart: state.cart.cart,
    product: state.product,
    order: state.order,
    locationState: state.location,
    discount: state.discount,
    zone: state.zone
})

export default connect(mapStateToProps, { 
    // Connecting actions to the component
    setAlert,
    toggleSideNav, 
    toggleUserModal, 
    toggleProfileModal, 
    togglePostModal,
    toggleShareModal,
    toggleAuthModal, 
    toggleDiscountModal,
    toggleModal_DiscountDetails,
    toggleModal_DiscountList_Preview,
    toggleModal_StoreHours,
    toggleModal_HowItWorks,
    toggleModal_ProcessingOrder,
    toggle_StartDelivery_Modal,
    setProfileModal,
    createChat, 
    setUsers,
    getUserById,
    clearUser,
    addToCart,
    decrement,
    removeItem,
    getDriverDeliveries,
    clearModalDiscount,
    claimDiscount,
    getModalDiscountById
})(Body);
