import React, { useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { setSearchProducts, set_ShowBottomSpinner, set_LastPageDoc, set_NoMoreProducts } from '../../actions/productActions';
import { setPage, add_To_Recent_History } from '../../actions/navActions';
import { getPopularSearches_Marketplace } from '../../actions/searchActions';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch/lite';

// Layout Wrapper
import ShopLayout from '../../components/layout/ShopLayout';

// Components 
import Spinner from '../../components/common/Spinner';
import CategoryMain from '../../Marketplace/components/CategoryList/CategoryMain';
import Mobile_CartBtn from '../components/Cart/Mobile_CartBtn';
import CategoryBlock from '../components/Landing/CategoryBlock';

// Icons
import SearchIcon from '@material-ui/icons/Search';
import SignalCellularAltOutlinedIcon from '@material-ui/icons/SignalCellularAltOutlined';

// Category Images
import searchIcon_img from '../../utils/imgs/searchIcon_img.png';
import shoppingBag_img from '../../utils/imgs/shoppingBag_img.jpg';
import snackIcon_img from '../../utils/imgs/snackIcon_img.png';
import iceCreamIcon_img from '../../utils/imgs/iceCreamIcon_img.png';
import breadIcon_img from '../../utils/imgs/breadIcon_img.png';
import eggsIcon_img from '../../utils/imgs/eggsIcon_img.png';
import frozenMealsIcon_img from '../../utils/imgs/frozenMealsIcon_img.png';
import milkIcon_img from '../../utils/imgs/milkIcon_img.png';
import cerealicon_img from '../../utils/imgs/cerealicon_img.png';
import sodaIcon_img from '../../utils/imgs/sodaIcon_img.jpg';
import meatIcon_img from '../../utils/imgs/meatIcon_img.png';
import waterIcon_img from '../../utils/imgs/waterIcon_img.jpg';
import snack_img from '../../utils/imgs/snack_img.png';
import iceCream_img from '../../utils/imgs/iceCream_img.jpg';
import bread_img from '../../utils/imgs/bread_img.png';
import eggs_img from '../../utils/imgs/eggs_img.png';
import frozenMeal_img from '../../utils/imgs/frozenMeal_img.jpg';
import milk_img from '../../utils/imgs/milk_img.jpg';
import cereal_img from '../../utils/imgs/cereal_img.jpg';
import soda_img from '../../utils/imgs/soda_img.png';
import meats_img from '../../utils/imgs/meats_img.png';
import water_img from '../../utils/imgs/water_img.png';

const SearchPage = ({
    product: {
        products,
        no_more_products,
        show_bottom_spinner,
        last_page_doc,
    },
    cart: {
        cart
    },
    search: {
        popular_searches
    },
    setSearchProducts,
    set_ShowBottomSpinner,
    set_LastPageDoc,
    set_NoMoreProducts,
    setPage,
    add_To_Recent_History,
    getPopularSearches_Marketplace
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Temporarily disable to load more function to avoid duplicate fetch requests
    const [canLoadMore, setCanLoadMore] = useState(true);

    // Algolia search
    const searchClient = algoliasearch('H8YQ3RB3ZI', '5ac154251c4dc66618a5d9031842c478'); // change the secret key (2nd) to specific api key
    const searchIndex = searchClient.initIndex('products');

    // Get the current URL and extract the "filter" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("q");
    const url_AutoFocus = url.searchParams.get("autoFocus");
    
    const SPACE_HEIGHT = 250; // Hieght of className 'no-rides' which is a spacer to make sure user can always scroll down

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        setPage('marketplace search');
        add_To_Recent_History(url.pathname);  // current url
        getPopularSearches_Marketplace();

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        console.log('scrolling 2');

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more products are met
        if (newScrollY >= scrollHeight - (SPACE_HEIGHT+1) && !show_bottom_spinner && !no_more_products && filter !== null && products.length > 0 && canLoadMore) {
            console.log('scrolling');
            loadMore(last_page_doc);
        }
    }

    // Function to load more paginated posts
    const loadMore = async (lastPage_doc) => {

        try {
            console.log("last", lastPage_doc);

            // Temporarily disable to load more function to avoid duplicate fetch requests
            setCanLoadMore(false);

            // Show spinner
            set_ShowBottomSpinner(true);

            const results = await searchIndex.search(filter, {
                page: lastPage_doc,
                hitsPerPage: 5
            });

            console.log('SEARCH RESULT');
            console.log(results);

            // setPosts(results.hits.map((doc) => ({...doc, _id: doc.objectID})));

            // Fetch initial products
        
            console.log('UPDATING POSTS...');
            const tempProductList = results.hits.map((doc) => ({...doc, _id: doc.objectID}));

            if(tempProductList.length < 2) {
                set_NoMoreProducts(true);
            }

            setSearchProducts(tempProductList);

            // Get the last visible document for the next load
            set_LastPageDoc(lastPage_doc + 1);
    
            // Hide the loading spinner
            set_ShowBottomSpinner(false);

            // Re-enable the load more function after 1 second
            setTimeout(() => {
                setCanLoadMore(true);
            }, 1000);

        } catch (error) {
            console.log('Error: Loading more search results')
            console.log(error);
        }
    }

    // END - Infinite Scroll

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Grocery Search Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    let popularSearch_list;

    // Check if posts are null or not yet fetched
    if(popular_searches === null) {
        popularSearch_list = <Spinner />;
    }
    else {
        if(popular_searches.length > 0) {

            // Iterate through the posts array and render each post component
            popularSearch_list = popular_searches.map(popular_search => {
                if(popular_search) {
                    return (
                        <div onClick={() => window.location = `/marketplace/search?q=${popular_search.text}`} className="search-dropdown-item" key={popular_search._id}>
                            <div className='search_dropdown_item_icon'>
                                <SearchIcon />
                            </div>
                            <div className='search_dropdown_item_option'>
                                <div className='generated_result'>
                                    <span>
                                        
                                    </span>
                                    <span>
                                        {popular_search.text}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        } else {
            // Display a default message 
            popularSearch_list = (
                <div className="no-rides" style={{marginTop:'80px', minHeight: '200px'}}>
                    <h1>Start typing to search food / drinks…</h1>
                    <img style={{maxWidth:'70%'}} src="https://cdn.hashnode.com/res/hashnode/image/upload/v1634545140037/TJzLog6cK.png?w=800&h=602&auto=compress&auto=compress,format&format=webp" />
                </div>
            );
        }
    }

    return (
        <ShopLayout lastPageDoc={last_page_doc} setLastPageDoc={set_LastPageDoc} setShowBottomSpinner={set_ShowBottomSpinner} handleScroll={handleScroll} page="search" autoFocus={url_AutoFocus}>

            <div style={filter !== null ? {paddingTop: '15px', paddingLeft: '15px'} : {}}>
                {/* Breadcrumbs */}
                {/* <ul className="breadcrumbs">
                    <li className="breadcrumbs__item">
                        <a href="#" className="breadcrumbs__link">Marketplace</a>
                    </li>
                    <li className="breadcrumbs__item">
                        <a href="#" className="breadcrumbs__link breadcrumbs__link--active">Trending</a>
                    </li>
                </ul> */}

                {filter !== null ? (
                    <Fragment>
                        <div style={{marginBottom: '20px'}}>
                            <h3>Showing {products.length ? products.length : ''} results for "{filter}"</h3>
                        </div>
            
                        <CategoryMain loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} last_page_doc={last_page_doc} />

                        <div style={{height: {SPACE_HEIGHT}}} className="no-rides"></div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                            <span>Explore Categories</span>
                        </div>
                        <CategoryBlock text={`Snacks`} link={`/snacks/JIlp5ujnantCKwvuc7VM`} photoURL={snack_img} />
                        <CategoryBlock text={`Ice Cream`} link={`/ice-cream/icpnzM2bOClFdrS4zqxi`} photoURL={iceCream_img} />
                        <CategoryBlock text={`Bread`} link={`/bread/tkaiyFAesuVPEWtoVg7o`} photoURL={bread_img} />
                        <CategoryBlock text={`Eggs`} link={`/eggs/KIUM9rB6rNXRtETtn4Q5`} photoURL={eggs_img} />
                        <CategoryBlock text={`Frozen Meals`} link={`/frozen-meals/WEPjjmFvSLfGNjdadi8i`} photoURL={frozenMeal_img} />
                        <CategoryBlock text={`Milk`} link={`/milk/59aKz9kT8sGvUJ3NL72e`} photoURL={milk_img} />
                        <CategoryBlock text={`Cereal`} link={`/cereal/4sLwYBaMQENnhSnCSMTs`} photoURL={cereal_img} />
                        <CategoryBlock text={`Soda`} link={`/soda/uWXLXkqYLzK83optessP`} photoURL={soda_img} />
                        <CategoryBlock text={`Meats`} link={`/meats/tvGkFvTxlg9FHCXaztbu`} photoURL={meats_img} />
                        <CategoryBlock text={`Water`} link={`/water/1TtkQolpa7e4rfc9uciK`} photoURL={water_img} />
                    </Fragment>
                )}

                {/* Render a spinner at the bottom if there are more posts to load */}
                {show_bottom_spinner && !no_more_products && <Spinner />}
            </div>

            <div style={{height: '50px'}}></div>

            <Mobile_CartBtn />
        </ShopLayout>
    )
}

SearchPage.propTypes = {
    product: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    setSearchProducts: PropTypes.func.isRequired,
    set_ShowBottomSpinner: PropTypes.func.isRequired,
    set_LastPageDoc: PropTypes.func.isRequired,
    set_NoMoreProducts: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    getPopularSearches_Marketplace: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    product: state.product,
    cart: state.cart,
    search: state.search
})

export default connect(mapStateToProps, { 
    setSearchProducts,
    set_ShowBottomSpinner,
    set_LastPageDoc,
    set_NoMoreProducts,
    setPage,
    add_To_Recent_History,
    getPopularSearches_Marketplace
})(SearchPage);