import { getAllUsers } from './authActions';
import BrowseHistory from './BrowseHistory';

import { 
    SET_PAGE, 
    SET_NAV1, 
    REMOVE_NAV1, 
    SET_NAV2, 
    REMOVE_NAV2, 
    SET_NAV3, 
    REMOVE_NAV3, 
    SET_MAIN_NAV,
    TOGGLE_ITEM_MODAL,
    TOGGLE_COLLECTION_MODAL,
    TOGGLE_LOCATION_MODAL,
    REMOVE_ITEM_MODAL,
    REMOVE_COLLECTION_MODAL,
    REMOVE_LOCATION_MODAL,
    TOGGLE_SIDE_NAV,
    TOGGLE_USER_MODAL,
    TOGGLE_PROFILE_MODAL,
    TOGGLE_AUTH_MODAL,
    TOGGLE_DISCOUNT_MODAL,
    TOGGLE_MODAL_DISCOUNT_DETAILS,
    TOGGLE_MODAL_DISCOUNT_LIST_PREVIEW,
    TOGGLE_MODAL_STORE_HOURS,
    TOGGLE_MODAL_HOW_IT_WORKS,
    TOGGLE_MODAL_PROCESSING_ORDER,
    SET_PROFILE_MODAL,
    TOGGLE_POST_MODAL,
    TOGGLE_SHARE_MODAL,
    SET_POST_MODAL,
    TOGGLE_COMMENT_MODAL,
    SET_COMMENT_MODAL,
    
    // Marketplace
    SET_IS_MARKETPLACE,
    // Driver Portal
    TOGGLE_QUIT_DELIV_MODAL,
    TOGGLE_START_DELIV_MODAL,
    TOGGLE_CONFIRM_PICKUP_MODAL,
    TOGGLE_CUSTOMER_CODE_MODAL,
    // Campus Logic
    SET_CAMPUS_ID
} from './types';

export const setPage = (page) => dispatch => {

    dispatch({
        type: SET_PAGE,
        payload: page
    });
}

export const goBack = () => {

    const url_SkipList = [ // Do not visit these urls when going back
        '/add-code',
        '/select-city',
        '/login',
        '/user_registration',
        '/user_registration?goBack=cart',
        '/register',
        '/forgotpassword',
        '/verify',
        '/addresses',
        '/contact-phone',
        '/logout',
        '/login/sent',
        '/settings/refunds',
        '/profile/edit',
        '/settings/howitworks',
        '/settings/account/screen_name',
        '/settings/account/password',
        '/settings/account/email',
        '/settings/account/phone',
        '/settings/account/gender',
        '/settings/account/birth_date',
        '/settings/payments',
        '/settings/coupons',
        '/settings/addresses',
        '/settings/account',
        '/settings/refunds',
        '/settings/howitworks',
        '/settings/help',
        '/settings/feedback',
        '/settings/terms',
        '/settings/delivery_partner_terms',
        '/settings/privacy',
        '/settings/cookies',
        '/cart?show=add_phone',
        '/cart?show=add_address',
        '/checkout'
    ]; 

    let browseHistory;
    console.log('GOING BACK')

    if(localStorage.recent_history) {
        console.log('NAV HISTORY')
        console.log(localStorage.recent_history)

        browseHistory = new BrowseHistory(JSON.parse(localStorage.recent_history));

        const url_filter = (window.location.href);
        const url = new URL(url_filter);

        let currentURL = url.pathname;
        if(url.search) {
            currentURL += url.search;
        }
        let previousURL;

        console.log('currentURL', currentURL);

        // Keep popping until we find a URL that is not equal to the current URL
        while ( !browseHistory.isEmpty() && ( browseHistory.peek() === currentURL || url_SkipList.includes( browseHistory.peek() ) ) ) {
            console.log('REMOVING LAST HISTORY ITEM');
            previousURL = browseHistory.pop();
            console.log('previousURL', previousURL);
        }

        if (!browseHistory.isEmpty()) {
            previousURL = browseHistory.pop();
        }

        console.log('FINAL GOING BACK URL', previousURL)

        // Store the current page in local storage as recent_history
        localStorage.setItem("recent_history", JSON.stringify(browseHistory));

        // If we have found a different URL, navigate to it
        if (previousURL && previousURL !== currentURL) {
            // console.log('OPTION 1', previousURL)
            window.location.href = previousURL;
        } else {
            // console.log('OPTION 2', previousURL)
            // If no different URL is found, navigate to home
            window.location.href = '/home';
        }
  
    } else {
        // console.log('OPTION 3')
        window.location.href = '/home';
    }
}


export const add_To_Recent_History = (currentURL) => dispatch => {
    let browseHistory;
    
    if(localStorage.recent_history) {
        console.log('NAV HISTORY')
        console.log(localStorage.recent_history)

        browseHistory = new BrowseHistory(JSON.parse(localStorage.recent_history));

        // Delete Recent History if its over 24 hours old
        if (isOver24HoursAgo(browseHistory.createdAt)) {
            console.log('The createdAt field is over 24 hours in the past. DELETE');
            localStorage.removeItem("recent_history");

            // Reset Browse History
            browseHistory = new BrowseHistory();
            browseHistory.setTimestamp();
        }
        
    } else {

        browseHistory = new BrowseHistory();
        browseHistory.setTimestamp();
    }

    console.log('BROWSER HISTORY 2.0 !!')
    console.log(browseHistory)

    const url_filter = (window.location.href);
    let url = new URL(url_filter);

    if (url.search) {
        currentURL += url.search;
    }

    if(currentURL !== browseHistory.peek()) {
        browseHistory.push(currentURL); // page is a string [ 'Home Page' ] 
    } else if (url.search){ // if the url's are equal than add it to the stack
        browseHistory.push(currentURL+url.search);
    }

    console.log('BROWSER HISTORY 3.0 !!')
    console.log(browseHistory)

    // Store the current page in local storage as recent_history
    localStorage.setItem("recent_history", JSON.stringify(browseHistory));
}

export const toggleUserModal = () => dispatch => {
    // dispatch(getAllUsers());

    dispatch({
        type: TOGGLE_USER_MODAL,
    });
}

export const toggleProfileModal = () => dispatch => {
    dispatch({
        type: TOGGLE_PROFILE_MODAL,
    });
}

export const togglePostModal = () => dispatch => {
    dispatch({
        type: TOGGLE_POST_MODAL,
    });
}

export const setPostModal = (value) => dispatch => {
    dispatch({
        type: SET_POST_MODAL,
        payload: value
    });
}

export const toggleCommentModal = () => dispatch => {
    dispatch({
        type: TOGGLE_COMMENT_MODAL,
    });
}

export const setCommentModal = (value) => dispatch => {
    dispatch({
        type: SET_COMMENT_MODAL,
        payload: value
    });
}

export const toggleAuthModal = (type) => dispatch => {
    dispatch({
        type: TOGGLE_AUTH_MODAL,
        payload: type
    });
}

export const setProfileModal = (value) => dispatch => {
    dispatch({
        type: SET_PROFILE_MODAL,
        payload: value
    });
}

export const toggleShareModal = () => dispatch => {
    dispatch({
        type: TOGGLE_SHARE_MODAL,
    });
}

export const toggleDiscountModal = () => dispatch => {
    dispatch({
        type: TOGGLE_DISCOUNT_MODAL,
    });
}

export const toggleModal_DiscountDetails = () => dispatch => {
    dispatch({
        type: TOGGLE_MODAL_DISCOUNT_DETAILS,
    });
}

export const toggleModal_DiscountList_Preview = () => dispatch => {
    dispatch({
        type: TOGGLE_MODAL_DISCOUNT_LIST_PREVIEW,
    });
}

export const toggleModal_StoreHours = () => dispatch => {
    dispatch({
        type: TOGGLE_MODAL_STORE_HOURS,
    });
}

export const toggleModal_HowItWorks = () => dispatch => {
    dispatch({
        type: TOGGLE_MODAL_HOW_IT_WORKS,
    });
}

export const toggleModal_ProcessingOrder = () => dispatch => {
    dispatch({
        type: TOGGLE_MODAL_PROCESSING_ORDER,
    });
}

export const setNav1 = (nav) => dispatch => {
    dispatch({
        type: SET_NAV1,
        payload: nav
    });
}

export const setNav2 = (nav) => dispatch => {
    dispatch({
        type: SET_NAV2,
        payload: nav
    });
}
export const setNav3 = (nav) => dispatch => {
    dispatch({
        type: SET_NAV3,
        payload: nav
    });
}
export const removeNav1 = () => dispatch => {
    dispatch({
        type: REMOVE_NAV1,
    });
}
export const removeNav2 = () => dispatch => {
    dispatch({
        type: REMOVE_NAV2,
    });
}
export const removeNav3 = () => dispatch => {
    dispatch({
        type: REMOVE_NAV3,
    });
}

// Set Admin Nav
export const setMainNav = (value) => {
    return {
        type: SET_MAIN_NAV,
        payload: value
    }
}

// Toggle Main Side Nav
export const toggleSideNav = () => dispatch => {
    dispatch({
        type: TOGGLE_SIDE_NAV,
    });
}

export const toggleItemModal = () => dispatch => {
    dispatch({
        type: TOGGLE_ITEM_MODAL,
    });
}

export const toggleCollectionModal = () => dispatch => {
    dispatch({
        type: TOGGLE_COLLECTION_MODAL,
    });
}

export const toggleLocationModal = () => dispatch => {
    dispatch({
        type: TOGGLE_LOCATION_MODAL,
    });
}

export const removeItemModal = () => dispatch => {
    dispatch({
        type: REMOVE_ITEM_MODAL,
    });
}

export const removeCollectionModal = () => dispatch => {
    dispatch({
        type: REMOVE_COLLECTION_MODAL,
    });
}

export const removeLocationModal = () => dispatch => {
    dispatch({
        type: REMOVE_LOCATION_MODAL,
    });
}

// --- MARKETPLACE ---
export const setIsMarketplace = (value) => dispatch => {
    dispatch({
        type: SET_IS_MARKETPLACE,
        payload: value
    });
}

// --- DRIVER PORTAL ----

export const toggle_QuitDelivery_Modal = () => dispatch => {
    dispatch({
        type: TOGGLE_QUIT_DELIV_MODAL,
    });
}

export const toggle_StartDelivery_Modal = () => dispatch => {
    dispatch({
        type: TOGGLE_START_DELIV_MODAL,
    });
}

export const toggle_ConfirmPickup_Modal = () => dispatch => {
    dispatch({
        type: TOGGLE_CONFIRM_PICKUP_MODAL,
    });
}

export const toggle_CustomerCode_Modal = () => dispatch => {
    dispatch({
        type: TOGGLE_CUSTOMER_CODE_MODAL,
    });
}

// --- END: DRIVER PORTAL ----

// -- CAMPUS LOGIC -- 

export const set_campus_id = (campusID) => dispatch => {
    dispatch({
        type: SET_CAMPUS_ID,
        payload: campusID
    });
}


// -- Utility Functions --

export const  isOver24HoursAgo = (createdAt) => {
    const currentTime = Date.now();
    const timeDifference = currentTime - createdAt;
    const hoursDifference = timeDifference / (1000 * 60 * 60); // Convert milliseconds to hours
    return hoursDifference > 24;
}