import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Redux
import { connect } from 'react-redux';

// Actions
import { getUnreadCount } from '../../actions/notificationActions';
import { getUnreadChats } from '../../actions/chatActions';
import { addLastModifiedFieldToChats } from '../../actions/chatActions';
import { toggle_QuitDelivery_Modal } from '../../actions/navActions';

// Routing
import { Link, useHistory, useLocation } from 'react-router-dom';

// Components - imported
import Widgets from '../Widgets/Widgets';
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';

// Icons - material UI
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';

const DriverLayout = ({ 
    getUnreadCount,
    getUnreadChats,
    children, 
    user, 
    page, 
    auth, 
    nav, 
    post: { post }, 
    chat, 
    notification, 
    notificationsPage, 
    sideNav, 
    handleSlideMenu, 
    totalPosts,
    handleScroll,
    addLastModifiedFieldToChats,
    toggle_QuitDelivery_Modal
}) => {
    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [authDropdown, setAuthDropdown] = useState(false);
    
    const [badgeValue, setBadgeValue] = useState(0);

    // Retrieve the current location using the "useLocation" hook from React Router
    let location = useLocation()

    // Get the current URL and extract the "filter" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const status = url.searchParams.get("status");

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;
    const notFullScreen = windowWidth <= 1170;

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Apply CSS classes to the feed header based on the value of "sideNav" and "isMobile"
    useEffect(() => {
        if(!isMobile) {
            if(sideNav) {
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                    return "feed__header";
                }
            } else {
                if(document.getElementById('feed-header') !== null) {
                    setTimeout(() => {
                        document.getElementById('feed-header').classList.add("active");
                    }, 700)
                }
            }
        } else {
            if(document.getElementById('feed-header') !== null) {
                document.getElementById('feed-header').classList.remove("active");
                return "feed__header";
            }
        }

        if(!isTablet) {
            if(profileDropdown) setProfileDropdown(false);
        }
    }, [sideNav, isMobile, isTablet])

    // Fetch unread notification count and unread chat count when the authenticated user changes
    useEffect(() => {
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [auth.user]);

    // Update the badge value based on the counts of unread notifications and unread chats
    useEffect(() => {
        setBadgeValue(notification.num_unread + chat.num_unread_chats);
    }, [notification.num_unread, chat.num_unread_chats])

    // Redirect the user to the previous page or a specific URL when the "goBack" function is called
    const history = useHistory();
    const goBack = () => {

        let previousURL = document.referrer;

        var pathArray = previousURL.split( '/' );
        var host = pathArray[2];

        console.log(pathArray);

        if(page === 'delivery') {
            if(status) {
                // Check the source of the previous URL and navigate accordingly
                if((typeof(host) !== 'undefined' && host.length) && (host.toLowerCase().includes("oubuysell") || host.toLowerCase().includes("localhost:3000") || host.toLowerCase().includes("mycontacts-9ba5a"))) {
                    if(pathArray[4] && pathArray[4].toLowerCase().includes("set-up")) {
                        window.location.href = '/home';
                    } else if(pathArray[3] && pathArray[4] && pathArray[3].toLowerCase().includes("messages")) {
                        window.location.href = `/${pathArray[3]}/${pathArray[4]}`;
                    } else if(previousURL.toLowerCase().includes("?filter=welcome")) {
                        window.location.href = '/home';
                    } else {
                        // history.goBack();
                        window.location.href = previousURL;
                    }
                } else {
                    window.location.href = '/home';
                }
            } else {
                toggle_QuitDelivery_Modal();
            }
        } else if(page === 'how to deliver' || page === 'delivery support') {
            history.goBack();
        } else {
            window.location.href = '/offers';
        }

    }

    const goBackFromPost = () => {

        let previousURL = document.referrer;

        var pathArray = previousURL.split( '/' );
        var host = pathArray[2];

        console.log(pathArray);

        // Check the source of the previous URL and navigate accordingly
        if((typeof(host) !== 'undefined' && host.length) && (host.toLowerCase().includes("oubuysell") || host.toLowerCase().includes("localhost:3000") || host.toLowerCase().includes("mycontacts-9ba5a"))) {
            if(pathArray[4] && pathArray[4].toLowerCase().includes("set-up")) {
                window.location.href = '/home';
            } else if(pathArray[5] && pathArray[5].toLowerCase().includes("likes")) {
                window.location.href = '/home';
            } else if(pathArray[3] && pathArray[4] && pathArray[3].toLowerCase().includes("messages")) {
                window.location.href = `/${pathArray[3]}/${pathArray[4]}`;
            } else if(pathArray[3] && pathArray[4] && pathArray[3].toLowerCase().includes("post")) {
                window.location.href = previousURL;
            } else if(pathArray[3] && pathArray[3].toLowerCase().includes("search")) {
                window.location.href = previousURL;
            } else if(pathArray[3] && (pathArray[3].toLowerCase().includes("notifications") || pathArray[3].toLowerCase().includes("saved"))) {
                history.goBack();
            } else if(previousURL.toLowerCase().includes("?filter=welcome")) {
                window.location.href = '/home';
            } else {
                // history.goBack();
                window.location.href = '/home';
            }
        } else {
            window.location.href = '/home';
        }

    }

    let user_name;

    if(!auth.loading && auth.isAuthenticated) {
        user_name = `${auth.user.first_name} ${auth.user.last_name && auth.user.last_name}`
    }


    return (
        <div className="feed__container">
            <div onScroll={handleScroll} className="feed">
                {/**Header */}
                <div className="feed__header" id="feed-header">
                        
                    {/*
                        Render the header content based on the current page and filter
                        Display a menu icon for mobile devices and handle slide menu if clicked
                        Display the appropriate header text based on the filter value
                        Display a badge with the badgeValue if it's greater than 0 and it's a mobile device
                    */}
                    {page === 'offer' && (
                        <div className="layout__profileHeader">

                            {/* Alert Badge container with back arrow */}
                            <div onClick={goBack} className="profile__btnContainer">
                                <ArrowBackIcon />
                                {isMobile && badgeValue > 0 ? (
                                    <span className="feed-header-badge defaultBadge">
                                        {badgeValue}
                                    </span>
                                ) : null}
                            </div>
                            <div className="profile__headerText">

                                {/* Title for a specific post */}
                                <h3 style={{display:'flex'}}>
                                    Delivery
                                </h3>
                            </div>

                            <div style={{flex: 1}} />

                            <div style={{marginRight: '20px', position:'relative', display: 'flex'}}>
                                {/* <div className="post__avatar layoutHeader" style={{ color: 'rgb(83, 100, 113)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px', marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                    <NightsStayOutlinedIcon />
                                </div> */}

                                {!auth.loading && !auth.isAuthenticated ? (
                                    <div onClick={() => setAuthDropdown(!authDropdown)} className="post__avatar layoutHeader" style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                        <Avatar />
                                    </div>
                                ) : (
                                    notFullScreen && (
                                        <div onClick={() => setProfileDropdown(!profileDropdown)} className="post__avatar layoutHeader" style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                            {auth.user && auth.user.img ? (
                                                <Avatar src={auth.user.img} />
                                            ) : (
                                                <DefaultAvatar name={auth.user && `${auth.user.first_name} ${auth.user.last_name}`} />
                                            )}
                                        </div>
                                    )
                                )}

                                {/* Dropdown Menu */}
                                <div className={profileDropdown ? "profile-dropdown-topNavbar active" : "profile-dropdown-topNavbar"}>
                                    <div onClick={() => window.location = `/${auth.user ? auth.user.username : 'profile'}`} className="menu-item" style={isMobile ? {padding: '1rem 1rem 0px 1rem'} : undefined}>
                                        <div className='sidebar__profile topNav'>
                                            <div className="post__avatar">
                                                {auth.user && auth.user.img ? (
                                                    <Avatar src={auth.user.img} />
                                                ) : (
                                                    <DefaultAvatar name={auth.user && `${auth.user.first_name} ${auth.user.last_name}`} />
                                                )}
                                            </div>
                                            <div  className='sidebar__profile__text__container'>
                                                <div className='sidebar__profile__text'>
                                                    <div>
                                                        <span>
                                                            {user_name}
                                                        </span>
                                                    </div>
                                                    {auth.user && (
                                                        <div>
                                                            <div>
                                                                <span>@{auth.user.username}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />
                                    
                                    <div onClick={() => window.location = '/saved'} className="menu-item">
                                        My Saved
                                    </div>

                                    <div onClick={() => window.location = '/settings/account'} className="menu-item">
                                        Account Settings
                                    </div>

                                    <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />
                                    
                                    <div onClick={() => window.location = '/logout'} className="menu-item danger">
                                        Log Out
                                    </div>
                                </div>

                                {/* Hidden Overlay for dropdown */}
                                <div
                                    className={`hidden-overlay ${profileDropdown ? "show" : ""}`}
                                    onClick={() => setProfileDropdown(!profileDropdown)}
                                />

                                {/* Auth Dropdown Menu */}
                                <div className={authDropdown ? "profile-dropdown-topNavbar active" : "profile-dropdown-topNavbar"}>
                                    <div className='auth_dropdown__topNavbar'>
                                        <div className='auth_dropdown_avatar'>
                                            <Avatar />
                                        </div>
                                        <h1>
                                            Sign up or log in to your account.
                                        </h1>
                                        <p>Takes less than a few seconds.</p>
                                        <div className='register_btns'>
                                            <button 
                                                onClick={() => window.location = '/register'}
                                                style={{width: '100%', height:'50px'}}
                                                className={`register_btn login active`}
                                            >
                                                Sign Up
                                            </button>
                                            <button 
                                                onClick={() => window.location = '/login'}
                                                className={`register_btn login hoverBlue`}
                                                style={{width: '100%', height:'50px', background:'transparent', color:'rgb(24, 105, 245)', border:'1px solid rgb(24, 105, 245)'}}
                                            >
                                                Log in
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Hidden Overlay for Auth dropdown */}
                                <div
                                    className={`hidden-overlay ${authDropdown ? "show" : ""}`}
                                    onClick={() => setAuthDropdown(!authDropdown)}
                                />
                            </div>
                                
                        </div>
                    )}

                    {(page === 'delivery' || page === 'how to deliver' || page === 'delivery support') && (
                        <div className="layout__profileHeader">

                            {/* Alert Badge container with back arrow */}
                            <div onClick={goBack} className="profile__btnContainer">
                                <ArrowBackIcon />
                                {isMobile && badgeValue > 0 ? (
                                    <span className="feed-header-badge defaultBadge">
                                        {badgeValue}
                                    </span>
                                ) : null}
                            </div>
                            <div className="profile__headerText">

                                {/* Title for a specific post */}
                                <h3 style={{display:'flex'}}>
                                    Delivery
                                </h3>
                            </div>

                            <div style={{flex: 1}} />
                            
                            {page !== 'delivery support' && (
                                <div style={{marginRight: '20px', position:'relative', display: 'flex'}}>
                                    <Button 
                                        variant="outlined" 
                                        className="sidebar__tweet heart noMargin" 
                                        onClick={() => window.location = '/delivery/support'}
                                    >   
                                        <div style={{marginRight: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <HeadsetMicOutlinedIcon />
                                        </div>
                                        Get Help
                                    </Button>
                                </div>
                            )}
                                
                        </div>
                    )}
                </div>

                {/* Display Layout content (posts, notifications, etc.) */}
                {children}
            </div>

            {/* Display 'website stats' box (widgets) if not tablet */}
            {!notFullScreen && <Widgets />}
        </div>
    )
}

DriverLayout.propTypes = {
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
    // Prop type validation for navigation
    nav: PropTypes.object.isRequired,
    // Prop type validation for notification
    notification: PropTypes.object.isRequired,
    // Prop type validation for chat
    chat: PropTypes.object.isRequired,
    // Prop type validation for post
    post: PropTypes.object.isRequired,
    // Prop type validation for function to get unread notifications
    getUnreadCount: PropTypes.func.isRequired,
    // Prop type validation for function to get unread chats
    getUnreadChats: PropTypes.func.isRequired,
    addLastModifiedFieldToChats: PropTypes.func.isRequired,
    toggle_QuitDelivery_Modal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    // Mapping the authentication state
    auth: state.auth,
    // Mapping the navigation state
    nav: state.nav,
    // Mapping the notification state
    notification: state.notification,
    // Mapping the chat state
    chat: state.chat,
    // Mapping the post state
    post: state.post
})

export default connect(mapStateToProps, {
    
    // Connecting actions to the component
    getUnreadCount, 
    getUnreadChats,
    addLastModifiedFieldToChats,
    toggle_QuitDelivery_Modal
})(DriverLayout);
