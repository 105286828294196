import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Firebase
import { db } from '../../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleDiscountModal, toggleModal_DiscountDetails } from '../../actions/navActions';
import { getModalDiscountById } from '../../actions/discountActions';

// Components
import Spinner from '../common/Spinner';

// Icons
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const Discount_Group_List = ({
    auth: {
        user
    },
    discount_groupID,
    toggleDiscountModal,
    toggleModal_DiscountDetails,
    getModalDiscountById
}) => {

    const [discounts, setDiscounts] = useState([]);
    const [discountList, setDiscountList] = useState([]);
    const [gotDiscounts, setGotDiscounts] = useState(false);

    const [discountsLoading, setDiscountsLoading] = useState(false);

    useEffect(() => {

        if(discount_groupID) {
  
            // -- Get Product Data
            fetchDiscountsInGroup(discount_groupID);
  
        }
    }, []);

    const fetchDiscountsInGroup = async (discount_GroupID) => {
        console.log('GETTING DISCOUNTS IN DISCOUNT GROUP')
        console.log(discount_GroupID)

        setDiscountsLoading(true);

        try {
            const discount_GroupRef = doc(db, 'discount_Groups', discount_GroupID)
            const subCollectionRef = collection(discount_GroupRef, "discounts")
      
            // Construct a new query 
            const subCollection_Query = query(subCollectionRef, orderBy('order_num', 'desc'));
      
            console.log('FETCHING DISCOUNTS');
      
            const discountsData = await getDocs(subCollection_Query);
            const discountsList = discountsData.docs.map((doc) => ({...doc.data(), id: doc.id}));
      
            console.log('GOT DISCOUNTS');
            console.log(discountsList)
      
            let discount_obj_list = [];
            // START loop
            for (const discount of discountsList) {
      
                // Create a reference to the specified discount document in the "discounts" collection
                const discountRef = doc(db, 'discounts', discount.discount_id);
      
                // Retrieve the document with the specified ID from the "discounts" collection
                const discountDoc = await getDoc(discountRef);
      
                discount_obj_list.push({
                    ...discountDoc.data(),
                    _id: discountDoc.id
                });
            }
      
            console.log('GOT DISCOUNTS IN DISCOUNT GROUP');
            console.log(discount_obj_list)
      
      
            // Dispatch an action of type GET_DISCOUNTS with the discount_obj_list as the payload
            setDiscounts(discount_obj_list);
            setDiscountsLoading(false);
        } catch (err) {
            console.log('ERROR GETTING DISCOUNTS IN DISCOUNT GROUP!!!')
            console.log(err);
            setDiscountsLoading(false);
        }
    }

    useEffect(() => {

        renderDiscountList();
    }, [discounts, user]);

    const renderDiscountList = () => {
        setDiscountList([]);

        if(discounts === null) {
            setDiscountList([(
                <Spinner />
            )])
        }
        else { 
            if(discounts.length > 0) {

                // -- Move claimed coupons to the end of array

                let reorderedDiscounts = [...discounts];

                if(user) {
                    // Filter discounts to separate those including user._id and those that do not
                    const includedDiscounts = discounts.filter(discount_item =>
                        discount_item.claim_id_list?.includes(user._id)
                    );
                    const excludedDiscounts = discounts.filter(discount_item =>
                        !discount_item.claim_id_list?.includes(user._id)
                    );

                    // Concatenate both arrays to reorder with includedDiscounts at the end
                    reorderedDiscounts = [...excludedDiscounts, ...includedDiscounts];
                }

                // -- END: Move claimed coupons to the end of array

                reorderedDiscounts.map(detailDiscount => {
                    if(detailDiscount.display_img) {
                        setDiscountList(discountList => [...discountList, (
                            <Discount_Group_List_Item
                                user={user}
                                detailDiscount={detailDiscount}
                                toggleDiscountModal={toggleDiscountModal}
                                toggleModal_DiscountDetails={toggleModal_DiscountDetails}
                                getModalDiscountById={getModalDiscountById}
                            />
                        )])
                    }
                });

                setGotDiscounts(true);
            } else {
                setDiscountList([(
                    <div className="no-rides">
                        <h1>No Discounts</h1>
                    </div>
                )])
            }
        } 
    }

    return (
        <div style={{display: 'flex', padding: '10px 0', width: '100%', overflow: 'scroll'}}>
            {discountList}

            <div style={{minWidth: '100px'}}>
                {/* spacer */}
            </div>
        </div>
    )
}

Discount_Group_List.propTypes = {
    auth: PropTypes.object.isRequired,
    discount_group: PropTypes.object.isRequired,
    toggleDiscountModal: PropTypes.func.isRequired,
    toggleModal_DiscountDetails: PropTypes.func.isRequired,
    getModalDiscountById: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  discount_group: state.discount_group,  // Connect: Map product state from redux store to post prop
  auth: state.auth

});

export default connect(mapStateToProps, { 

  // Connecting actions from redux to the component
  toggleDiscountModal,
  toggleModal_DiscountDetails,
  getModalDiscountById

})(Discount_Group_List);

const Discount_Group_List_Item = ({
    user,
    detailDiscount,
    toggleDiscountModal,
    toggleModal_DiscountDetails,
    getModalDiscountById
}) => {

    const [claimed, setClaimed] = useState(false);

    useEffect(() => {

        if(user && detailDiscount?.claim_id_list?.includes(user._id) ) {
  
            setClaimed(true);
  
        }
    }, []);

    

    const handleDiscountClick = () => {
        getModalDiscountById(detailDiscount._id)
        toggleDiscountModal()
    }

    const handle_GetDetails = () => {
        getModalDiscountById(detailDiscount._id)
        toggleModal_DiscountDetails();
    }

    if(detailDiscount.discount_type === 'amount off order') {
        return (
            <div onClick={!claimed ? handleDiscountClick : handle_GetDetails} style={{width: '100%', display: 'flex', justifyContent: 'center', cursor: 'pointer', margin: '0px 10px'}}>
                <div style={{height: '150px', width: '300px', border: `${claimed ? '2px solid #e6ecf0' : 'none'}`, borderRadius: '8px', overflow: 'hidden', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    { claimed ? (
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                            <div style={{color: '#32cd32'}}>
                                <CheckCircleOutlineOutlinedIcon />
                            </div>
                            <div>
                                {detailDiscount.type_of_value === 'fixed amount' && `$${detailDiscount.discount_value} OFF`}
                                {detailDiscount.type_of_value === 'percentage' && `${detailDiscount.discount_value}% OFF`}
                            </div>
                            <div>
                                Coupon Claimed
                            </div>
                            <div style={{fontWeight: 'bold', fontSize: '14px', cursor: 'pointer', textDecoration: 'underline'}}>
                                View Details
                            </div>
                            
                        </div>
                    ) : (
                        <img style={{objectFit: 'cover', margin: 'auto', width: '100%'}} src={detailDiscount.display_img} />
                    )}
                    
                </div>
            </div>
            
        )
    } else {
        return (
            <a style={{cursor: 'pointer', margin: '0px 10px'}} href="#">
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <div style={{height: '150px', width: '300px', borderRadius: '8px', overflow: 'hidden', position: 'relative'}}>
                        <img style={{objectFit: 'cover', height: '100%'}} src={detailDiscount.display_img} />
                    </div>
                </div>
            </a>
        )
    }
  }