import React from 'react'
import PropTypes from 'prop-types'

const Overview = props => {
  return (
    <div>
        <div style={{display: 'flex', alignItems: 'center', padding: '0 50px 0 30px'}}>
            <h5 style={{fontWeight:'300', color:'#3c4043'}}>{props.title}</h5>
            <div style={{flex: '1'}} />
        </div>

        {props.children}
    </div>
  )
}

Overview.propTypes = {}

export default Overview