import React from 'react'
import PropTypes from 'prop-types'

function SlideMenu_Option({
    option
}) {
    return (
        <a style={{textDecoration: 'none', marginRight: '4px'}} href={`/${option.handle}/${option._id}`}>
            <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', height: '42px', padding: '0 12px', borderRadius: '64px', overflow: 'hidden', background: 'rgb(236, 238, 233)'}}>
                <span style={{lineHeight: '18.5px', marginTop: '0', color: '#000', fontWeight: 'bold', maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px', overflow: 'hidden'}}>
                    {option.name}
                </span>
            </div>
        </a>
    )
}

SlideMenu_Option.propTypes = {}

export default SlideMenu_Option
