import React, { useState, useEffect } from 'react';

import { Button } from '@material-ui/core';

// Icons
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';

// Components

const CategoryBlock = ({
    photoURL,
    text,
    link
}) => {

    return (
        <div onClick={() => window.location = `${link}`} className={`notification`} style={{height: '100px'}}>
            <div className="categoryBlock_img">
                <img style={{height: '100%'}} src={photoURL} />
            </div>

            <div className='categoryBlock_content'>
                <h1>{text}</h1>
                <Button
                    variant="outlined" 
                    className="sidebar__tweet heart noMargin" 
                >
                    <LocalMallOutlinedIcon />
                    <span style={{marginLeft: '5px'}}>
                        Shop 
                    </span>
                    
                </Button>
            </div>
        </div>
    )
}

export default CategoryBlock;
