import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Firebase

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, add_To_Recent_History } from '../../actions/navActions';

// Layout Wrapper
import Layout from '../../components/layout/Layout';

// Components
import CategoryBlock from '../components/Landing/CategoryBlock';

// Modal

const CategoriesPage = ({ 
    setPage, 
    add_To_Recent_History,
    nav: { 
        sideNav, 
    },
    toggleSideNav
}) => {

    // State variable to track if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Set the current page
        setPage('Grocery About');
        add_To_Recent_History(url.pathname);  // current url

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Handles Mixpanel tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

        // Track "About Page View" event
        mixpanel.track("Grocery About Page View");
    }

    // Checks if the environment is production and Mixpanel tracking hasn't been sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {

        // Call Mixpanel tracking function
        handleMixpanel();

        // Set Mixpanel event as sent
        setSentMixpanel(true);
    }

    const isMobile = windowWidth <= 769;

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)
    }

    return (
        <Fragment>
            <Layout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="grocery">
                <div className="feed_headerTabs">
                    <Link to="/">
                        <div>
                            <h3>Browse</h3>
                            <div className="block__underline"></div>
                        </div>
                    </Link>
                    <Link to="/marketplace/categories">
                        <div className="active">
                            <h3>Categories</h3>
                            <div className="block__underline"></div>
                        </div>
                    </Link>
                    <Link to="/marketplace/about">
                        <div>
                            <h3>About</h3>
                            <div className="block__underline"></div>
                        </div>
                    </Link>
                </div>

                <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                    <span>Explore Categories</span>
                </div>

                {/* Category Blocks here */}
                <CategoryBlock text={`Snacks`} link={`/snacks/JIlp5ujnantCKwvuc7VM`} photoURL={"https://publicassets.gopuff.com/taxonomy-collection/fab6d757-842f-4995-a0de-7aecb7dce938_snackcheetos2.png"} />
                <CategoryBlock text={`Ice Cream`} link={`/ice-cream/icpnzM2bOClFdrS4zqxi`} photoURL={"https://shop.benjerry.com/cdn/shop/products/35670_US_IC_Choc-Chip-Cookie-Dough_473ml_FOP-1000x1000-5cf5f439-d10e-442b-97af-c7653877aa2f.png?v=1677669924"} />
                <CategoryBlock text={`Bread`} link={`/bread/tkaiyFAesuVPEWtoVg7o`} photoURL={"https://purepng.com/public/uploads/large/purepng.com-sliced-breadfood-bread-941524637257xz7f4.png"} />
                <CategoryBlock text={`Eggs`} link={`/eggs/KIUM9rB6rNXRtETtn4Q5`} photoURL={"https://i.pinimg.com/originals/c1/dc/78/c1dc789fb4da37fd8dbcca718d092fc7.png"} />
                <CategoryBlock text={`Frozen Meals`} link={`/frozen-meals/WEPjjmFvSLfGNjdadi8i`} photoURL={"https://media.officedepot.com/images/f_auto,q_auto,e_sharpen,h_450/products/9939834/9939834_o07/9939834"} />
                <CategoryBlock text={`Milk`} link={`/milk/59aKz9kT8sGvUJ3NL72e`} photoURL={"https://i5.walmartimages.com/seo/Great-Value-Milk-2-Reduced-Fat-Gallon-Plastic-Jug_22a6459a-13b6-4057-aeae-45e62c69e8f8.47f793426ff66fa6432c948d836704f0.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF"} />
                <CategoryBlock text={`Cereal`} link={`/cereal/4sLwYBaMQENnhSnCSMTs`} photoURL={"https://i.etsystatic.com/32219777/r/il/4068bd/3373361462/il_570xN.3373361462_dfrg.jpg"} />
                <CategoryBlock text={`Soda`} link={`/soda/uWXLXkqYLzK83optessP`} photoURL={"https://publicassets.gopuff.com/taxonomy-collection/0a965143-bec5-4b8c-a618-091b96daf1fe_newpepsi5.png"} />
                <CategoryBlock text={`Meats`} link={`/meats/tvGkFvTxlg9FHCXaztbu`} photoURL={"https://purepng.com/public/uploads/large/purepng.com-meatfood-meat-red-fresh-grill-cook-beef-steak-9415246237676ksid.png"} />
                <CategoryBlock text={`Water`} link={`/water/1TtkQolpa7e4rfc9uciK`} photoURL={"https://images.freshop.com/00068274194740/3c508651fdaf3449731742905a079882_large.png"} />

                {/* Add space to the bottom of the page b/c of auth alert  */}
                <div style={{height: '300px'}}></div>
            </Layout>

        </Fragment>
    )
}

CategoriesPage.propTypes = {
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    nav: PropTypes.object.isRequired,
    toggleSideNav: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setPage, 
    add_To_Recent_History,
    toggleSideNav
})(CategoriesPage);
