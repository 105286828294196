import React, { Fragment, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

// icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Privacy_Policy = ({ 
    handleSlideMenu, 
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    campus_name,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings Privacy Policy page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Privacy Policy Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">
            
            {/**Header */}
            <div className="settings__header">
                {/* {isTablet && ( */}
                    <div onClick={goBack} className="profile__btnContainer">
                        <ArrowBackIcon />
                        {isMobile && badgeValue > 0 ? (
                            <span className="feed-header-badge defaultBadge">
                                {badgeValue}
                            </span>
                        ) : null}
                    </div>
                {/* )} */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        Privacy Policy
                    </h3>
                </div>
            </div>

            <div className="settings__body terms">
                <div className="terms-of-service">
                    <h2 className="MuiTypography-root jss3 MuiTypography-h3 MuiTypography-colorTextPrimary">Privacy Policy</h2>
                    <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextPrimary">Last updated: June 12, 2024</p>
                    <div>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                            We are Cardboard Express Inc., a company registered in the United States of America (we might refer to ourselves as "<b>Miitoken</b>", "<b>we</b>", "<b>our</b>" or "<b>us</b>"). We operate a delivery service that offers a range of convenience store items for sale (that's what we mean when we say "<b>Services</b>" in these terms) through https://miitoken.com/ (which we call our "Website") and/or our mobile application (which we call our "<b>App</b>"). Your safety and privacy are important to us.
                        </p>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                            This Privacy Policy ("Policy") is mostly focused on our users and because of that it is written to be as accessible to them as possible. This Policy tells you everything you need to know about how we use your personal information. 
                        </p>
                        <ol style={{paddingLeft:'0.7em'}}>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                    Your safety
                                </li>
                            </strong>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                            Your safety when using our Services is important to us. The Website, App and Services are intended for use only by persons who are at least 18 years of age. If you are under the age of 18, you confirm you have received permission from your parent or guardian before using the Services.
                            </p>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                    What information do we ask for about you? 
                                </li>
                            </strong>
                            <ol type="A" style={{paddingLeft:'24px'}}>

                                <li style={{paddingBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    To use our Services you will need to register for an account with us through the registration form available on the Website or App (we call this your "User Account"). If you are under 18, your parent or guardian will need to be the holder of the User Account.
                                </li>

                                <li style={{paddingBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    You or, if necessary, your parent or guardian will need to enter the following information into the User Account:

                                    <ul>
                                        <li>full name</li>
                                        <li>address</li>
                                        <li>email address</li>
                                        <li>phone number</li>
                                    </ul>
                                </li>

                                <li style={{paddingBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    If you purchase alcohol or medical products[JR1]  via MiiToken, you will need to show our delivery drivers a valid ID to confirm you are of legal age to purchase one of these products. 
                                </li>
                            </ol>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                    How will we use your information?
                                </li>
                            </strong>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                Data protection law requires us to provide a lot of detail about the type of personal information we collect and use and the reasons we are allowed to collect and use it. It's a bit technical but there is a link to that information here[JR2] . We use information held about you (and where applicable, your parent or guardian) in the following ways:
                            </p>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5', textDecoration: 'underline'}}>
                                Information you give to us: 
                            </p>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                We will use this information to:
                            </p>
                            <ul style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                <li>
                                    To set up your User Account (or if you are under 18, your parent or guardian's User Account), including:
                                    <ul style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                        <li>
                                            verifying payments that you make through the Website and/or App; and
                                        </li>
                                        <li>
                                            notifying you about changes to our Services.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Provide you with information about our Services we feel may interest you, if you have told us you are happy to hear from us.
                                </li>
                                <li>
                                Make sure that:
                                    <ul style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                        <li>
                                            content from our Website and App is easy for you to read and understand on your devices; and
                                        </li>
                                        <li>
                                            we answer any questions you have and give you any information that you ask for.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Confirm you are of legal age to purchase any age-restricted products.
                                </li>
                            </ul>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5', textDecoration: 'underline'}}>
                                Information we collect about you from your use of our Services:
                            </p>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                We will use some information to help us:
                            </p>
                            <ul style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                <li>
                                    to run our Website, our App and our Services and to help us spot problems;
                                </li>
                                <li>
                                    to understand how people use our Website and our App so we can improve them;
                                </li>
                                <li>
                                    to keep our Website and App safe and secure;
                                </li>
                                <li>
                                    to make sure any advertising we send or show to you is relevant to you; and
                                </li>
                            </ul>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                    Sharing your information with others
                                </li>
                            </strong>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                We will share your User Account information with:
                            </p>
                            <ul style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                <li>
                                    our delivery drivers (specifically your name and address );
                                </li>
                                <li>
                                    any service providers hired to provide administrative, communication or payment processing services;
                                </li>
                                <li>
                                    any affiliated companies; and
                                </li>
                                <li>
                                    any professional advisers.
                                </li>
                            </ul>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                How long do we keep your information? 
                                </li>
                            </strong>
                            <ul style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                <li>
                                    We normally store your User Account for as long as you have a Membership with us and for 30 days after you delete your User Account. In some circumstances we may have to store information for longer, for example if we are resolving a query or a complaint with you.
                                </li>
                            </ul>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                How do we protect your information?
                                </li>
                            </strong>
                            <ul style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                <li>
                                    We make sure your information is safe and secure, and that only you, your parent or guardian and our partners described in paragraph 4 can view your information in your User Account. 
                                </li>
                                <li>
                                    Sharing information over the internet is never completely secure. Although we will do our best to keep your information safe, we cannot promise that it will always be secure before it gets to us. Once we have received your information, we will use strict procedures and security to try to prevent anyone getting access to it without our permission.
                                </li>
                                <li>
                                    The Website and the App may have links to other websites. We don't run those so it is important that you look at their privacy policies, or where applicable ask your parent or guardian to do so, to understand how they might use your personal information.
                                </li>
                            </ul>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                What are your rights?
                                </li>
                            </strong>
                            <ul style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                <li>
                                    You can ask us:  
                                    <ul style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                        <li>
                                            for a copy of any information we have about you (for example, your name and address);
                                        </li>
                                        <li>
                                            to correct or delete information about you; and
                                        </li>
                                        <li>
                                            to stop using information about you while we check if it's okay to do so.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    If we send you and your parent or guardian any advertising, you can tell us to stop at any time.
                                </li>
                                <li>
                                    If we are using your personal information because you have given us permission, you have the right to change your mind at any time. 
                                </li>
                                <li>
                                    You can ask us to do any of these things at any time by contacting us at <a href="mailto:support@miitoken.com" rel="noopener noreferrer" target="_blank">support@miitoken.com</a>. If we can't do something you have asked, we will reply and explain why we can't.
                                </li>
                                <li>
                                    If you are still not happy, you can speak to your local data protection authority. The Information Commissioner is in charge of personal data protection in the UK and can give you more information about your rights and what you can expect from us: https://ico.org.uk/global/contact-us/. 
                                </li>
                            </ul>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                Where we store your information
                                </li>
                            </strong>
                            <ul style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                <li>
                                    The Website and App run through a third-party platform and you can find a link to their privacy policy here: https://firebase.google.com/terms/data-processing-terms. 
                                </li>
                                <li>
                                    We, Fancy, won't move any of your personal information outside of the European Economic Area (the EEA).
                                </li>
                            </ul>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                Payment processing
                                </li>
                            </strong>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                We use a third party to process your payments and you can find a link to their privacy policy here: https://stripe.com/en-gb/privacy.
                            </p>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                Changes to this policy
                                </li>
                            </strong>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                If we make any changes to this privacy policy, a new version will be posted on this page and we will let you know about important changes by email. This policy was last updated on [insert date].
                            </p>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                Contact Us
                                </li>
                            </strong>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                We'd love to hear what you think about this policy. If you've got any questions or comments please let us know:
                            </p>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                REACH US ON SOCIAL: @miitoken
                            </p>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="no-rides" />
        </div>
    )
}

export default Privacy_Policy;
