import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Date formatting
import { format, lightFormat, formatISO } from 'date-fns';

// Actions
import { toggleModal_DiscountDetails } from '../../actions/navActions';
import { getModalDiscountById } from '../../actions/discountActions';

// Components
import Item_Img from '../../Drivers/components/Offers/Item_Img';

// Icons
import { Button } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

const Coupon_Block = ({
    discount_obj,
    cart,
    toggleModal_DiscountDetails,
    getModalDiscountById,
    page,
    applyDiscount,
    setFormData,
    formData
}) => {

    console.log('CART DISCOUNTS')
    console.log(Object.keys(cart.discounts).includes(discount_obj._id))
    console.log(discount_obj._id)

    const handle_GetDetails = () => {
        getModalDiscountById(discount_obj._id)
        toggleModal_DiscountDetails();
    }

    const handleApplyDiscount = (e) => {
        e.preventDefault();

        // setFormData({ ...formData, 'discount_code': discount_obj.discount_code});

        applyDiscount(discount_obj.discount_code);
    }

    return (
        <div className='coupon' key={discount_obj._id}>
            <div style={{display: 'flex', width: '100%'}}>
                <div style={{flex: 1}}>
                    <h3 style={{marginBottom: '10px', overflow:'hidden' , fontSize:'18px', fontWeight:'600', color:'#000', maxWidth:'100%'}}>
                        {discount_obj.type_of_value === 'fixed amount' && `Enjoy $${discount_obj.discount_value} off your order!`}
                        {discount_obj.type_of_value === 'percentage' && `Enjoy ${discount_obj.discount_value}% off your order!`}
                    </h3>
                    <div className='coupon_row'>
                        <div style={{marginTop: '2px', marginBottom: '2px', fontWeight: 'bold', fontSize: '14px'}}>
                            Code:
                        </div>
                        <div style={{marginTop: '2px', marginBottom: '2px', marginLeft: '5px', fontSize: '14px'}}>
                            {discount_obj.discount_code}
                        </div>
                    </div>
                    <div className='coupon_row'>
                        <div style={{marginTop: '2px', marginBottom: '2px', fontWeight: 'bold', fontSize: '14px'}}>
                            Expires:
                        </div>
                        <div style={{marginTop: '2px', marginBottom: '2px', marginLeft: '5px', fontSize: '14px'}}>
                            {discount_obj.expiration_date ? discount_obj.expiration_date : 'no expiration date'}
                        </div>
                    </div>
                    <div className='coupon_row column'>
                        <div style={{marginTop: '2px', marginBottom: '2px', fontWeight: 'bold', fontSize: '14px'}}>
                            Rules:
                        </div>
                        <div style={{marginTop: '2px', marginBottom: '2px', marginLeft: '5px', fontSize: '14px'}}>
                            <ul>
                                {discount_obj.type_of_value === 'fixed amount' && (
                                    <li>
                                        ${discount_obj.discount_value} off {discount_obj.num_uses > 0 ? `${discount_obj.num_uses} orders.` : '1 order.'} 
                                    </li>
                                )}
                                
                                {discount_obj.min_purchase_amt > 0 && (
                                    <li>
                                        Minimum cart value of ${discount_obj.min_purchase_amt} needed for offer to apply.
                                    </li>
                                )}

                                {discount_obj.min_quantity > 0 && (
                                    <li>
                                        Minimum cart quanity of {discount_obj.min_quantity} items needed for offer to apply.
                                    </li>
                                )}

                                {(!discount_obj.min_quantity && !discount_obj.min_purchase_amt) && (
                                    <li>
                                        No minimums needed for offer to apply.
                                    </li>
                                )}

                                {discount_obj.can_combine ? (
                                    <li>
                                        Can combine with other discounts.
                                    </li>
                                ) : (
                                    <li>
                                        Can not combine.
                                    </li>
                                )}

                                
                            </ul>
                        </div>
                    </div>
                    <div className='coupon_row has_badge'>
                        {/*extract an array of all key values from object then map()*/}
                        {Object.keys(cart.discounts).includes(discount_obj._id) ? (
                            <div className='coupon_badge'>
                                <div className='coupon_badge_icon'>
                                    <CheckCircleOutlinedIcon />
                                </div>
                                <div style={{color: '#fff', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold'}}>
                                    Applied
                                </div>
                            </div>
                        ) : (
                            <div className='coupon_badge inactive'>
                                <div className='coupon_badge_icon'>
                                    <CheckCircleOutlinedIcon />
                                </div>
                                <div style={{color: '#fff', textOverflow: 'ellipsis', fontSize: '12px', fontWeight: 'bold'}}>
                                    Claimed
                                </div>
                            </div>
                        )}
                        <div onClick={handle_GetDetails} style={{marginTop: '2px', marginBottom: '2px', marginLeft: '32px', cursor: 'pointer', fontWeight: 'bold', fontSize: '14px', textDecoration: 'underline'}}>
                            Details
                        </div>
                    </div>
                </div>

                <div>
                    {!Object.keys(cart.discounts).includes(discount_obj._id) && (
                        <button onClick={(e) => handleApplyDiscount(e)} className='secondaryBtn' style={{color:'#000'}}>Apply</button>
                    )}
                </div>
            </div>

            {Object.keys(cart.discounts).includes(discount_obj._id) && (
                <div className='coupon_row has_badge'>
                    <div style={{marginTop: '2px', marginBottom: '2px', marginLeft: '5px', fontSize: '14px'}}>
                        * Miitoken applies the coupon with the highest discount value to your order.
                    </div>
                </div>
            )}
        </div>
    )
}

Coupon_Block.propTypes = {
    toggleModal_DiscountDetails: PropTypes.func.isRequired,
    getModalDiscountById: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props

});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    toggleModal_DiscountDetails,
    getModalDiscountById

})(Coupon_Block);
