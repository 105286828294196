import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../utils/firebase-config';

// Redux
import { connect } from 'react-redux';

// Actions
import { login, loginWithEmailAndPassword, checkIfUserExists, signInWithGoogle } from '../../actions/authActions';
import { setAlert } from '../../actions/alertActions';
import { getModalDiscountById } from '../../actions/discountActions';
import { toggleDiscountModal } from '../../actions/navActions';


// Layout Wrapper
import ModalPage from '../../components/layout/ModalPage';

// Components - imported
import { Button } from '@material-ui/core';
import Alert from '../../components/common/Alert';

// Icons -imported
import TwitterIcon from '@material-ui/icons/Twitter';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Logo from '../../components/common/Logo';

import miitoken_logo from '../../utils/imgs/miitoken_logo_2.png';
import couponIMG from '../../utils/imgs/coupon.png';
import google_logo from '../../utils/imgs/google_logo.jpg';
import facebook_logo from '../../utils/imgs/facebook_logo.png';
import apple_logo from '../../utils/imgs/apple_logo.png';


// Initial State
const initialState = {
    email: ''
}

const AuthPage_Body = ({
    auth: { 
        isAuthenticated, 
        user,
        account_loading
    }, 
    nav: {
        campus_name,
        campus_ext
    },
    discount: {
        detailDiscount
    },
    loginWithEmailAndPassword,
    checkIfUserExists,
    signInWithGoogle,
    setAlert,
    toggleDiscountModal,
    getModalDiscountById,
    history
}) => {

    // State for tracking if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);
    
    // State for tracking the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // State for form data
    const [formData, setFormData] = useState(initialState);

    // State to toggle login methods
    const [loginWithPassword, setLoginWithPassword] = useState(true);

    // State to toggle email code inputs
    const [showEmailCode, setShowEmailCode] = useState(false);

    // Get the URL filter and check if there is a "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("redirect");
    const authMethod = url.searchParams.get("method");
    const url_discountID = url.searchParams.get("discount");

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {
        
        if(url_discountID) {
            if(!localStorage.auth_Discount_Reward) {
                getModalDiscountById(url_discountID);
                toggleDiscountModal();
            } else {
                if(!(localStorage.auth_Discount_Reward === url_discountID)) {
                    localStorage.removeItem('auth_Discount_Reward');
                    localStorage.removeItem('auth_Reward_Claimed');
                    getModalDiscountById(url_discountID);
                    toggleDiscountModal();
                }
            }
        }

    }, [url_discountID]);

    // Handle the "redirect" parameter in the URL
    useEffect(() => {
        if(filter && filter === 'true') {
            setAlert('Failed: Wrong Email.', 'danger')
        } 

    }, [filter]);

    // Handle the Authentication Method via URL parameter
    useEffect(() => {
        if(authMethod && authMethod === 'password') {
            setLoginWithPassword(true)
        } 

        if(authMethod && authMethod === 'noPassword') {
            setLoginWithPassword(false)
        } 
    }, [authMethod]);

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Function to go back in history
    const goBack = (e) => {
        history.goBack();
    }

    // Check if the device is mobile or tablet
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Destructure the email value from form data
    const { email, password } = formData;

    // Function to handle input change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    // Function to validate email format
    const validateEmail = (input) => {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
        if (input.match(validRegex)) {
      
          return true;
      
        } else {
      
          return false;
      
        }
    }

    

    const handleEmailSignIn = e => {
        e.preventDefault();
        
        if(email === '') {
            setAlert('Email is required.', 'danger');
        } else if (!validateEmail(email)) {
            setAlert('Invalid email address!', 'danger');
        } else {

            checkIfUserExists(email);
        }
    }

    // Function to handle Password form submission
    const onSubmit = async e => {
        e.preventDefault();
        
        if(email === '') {
            setAlert('Email is required.', 'danger');
        } else if (!validateEmail(email)) {
            setAlert('Invalid email address!', 'danger');
        } else if (password === '') {
            setAlert('Password is required.', 'danger');
        } else {

            // Login action
            console.log('START REGISTER');
            handleLogin(e);
            
        }
    }

    // Function to handle Email Code verification & submission
    const onSubmitAuthCode = async e => {
        e.preventDefault();
        
        window.alert('Processing verification code...')
    }

    const handleLogin = (e) => {
        e.preventDefault();

        // Authenticate the user
        loginWithEmailAndPassword(email, password);
    };

    // Function to handle Mixpanel event tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Login Page View");
    }

    // Send Mixpanel event only in production and if not already sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    const handleGoogleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then(async (result) => {
            console.log('RETURNED USER')
            console.log(result.user);
            console.log(result.user.email);

            const {
                displayName,
                email,
                phoneNumber,
                photoURL,
                emailVerified
            } = result.user;

            signInWithGoogle({
                displayName,
                email,
                phoneNumber,
                photoURL,
                emailVerified
            })
        })
    }

    return (

        <div className='register_container login'>
            <div className='register_content_container login'>
                <div className='register_content'>
                    <div style={{boxSizing:'border-box', textAlign:'center'}}>
                        
                        {/* Logo */}
                        <div onClick={() => window.location = '/'} className='register_logo'>
                            <img src={miitoken_logo} />
                        </div>

                        {detailDiscount ? (
                            <Fragment>
                                 {/* Header Description */}
                                <div className='disclaimer'>
                                    1 New User gift waiting for you
                                </div>

                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <p style={{fontSize: '12px', color: '#808080', fontWeight: 600, textAlign: 'center'}}>
                                        Added to account immediately after Sign Up
                                    </p>
                                    
                                </div>

                            

                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
                                    <div style={{height: '100px', margin: '0 10px', position: 'relative'}} onClick={() => window.location = '/'} className='register_logo'>
                                        <img src={couponIMG} />
                                        <div style={{position: 'absolute', top: 0, marginTop: 'auto', paddingLeft: '20px', letterSpacing: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30%', width: '100%', fontSize: '12px', fontWeight: 'bold'}}>
                                            New User Gift
                                        </div>
                                        <div style={{position: 'absolute', top: 0, paddingLeft: '20px', letterSpacing: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', fontSize: '28px', fontWeight: 'bold'}}>
                                            ${detailDiscount.discount_value} OFF
                                        </div>
                                        <div style={{position: 'absolute', bottom: 0, marginTop: 'auto', paddingLeft: '20px', letterSpacing: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40%', width: '100%', fontSize: '13px', fontWeight: 500}}>
                                            For orders over $10.00
                                        </div>
                                    </div>
                                    {/* <div style={{height: '100px'}} onClick={() => window.location = '/'} className='register_logo'>
                                        <img src={couponIMG} />
                                    </div> */}
                                </div>
                            </Fragment>
                        ) : (
                            <div style={{width: '100%', height: '1px', marginTop: '20px', background: '#e6ecf0'}}>
                                {/* spacer */}
                            </div>
                        )}
                        
                    </div>
                    
                    <div style={{marginTop: '20px'}}>
                        

                        {/* <div onClick={handleGoogleSignIn} className='delivery_widget authPage black'>

                            <span style={{fontWeight: 'bold'}}>
                                Continue with Email
                            </span>
                        </div> */}

                        <div>
                        
                            {/* <div className='delivery_widget authPage'>
                                <div style={{height: '90%', margin: '5px', borderRadius: '50%', border: '1px solid #e6ecf0', boxSizing: 'border-box'}}>
                                    <img style={{objectFit: 'cover', height: '100%'}} src={facebook_logo} />
                                </div>
                                <span style={{fontWeight: 'bold'}}>
                                    Continue with Facebook
                                </span>
                            </div>

                            <div className='delivery_widget authPage'>
                                <div style={{height: '90%', margin: '5px', borderRadius: '50%', border: '1px solid #e6ecf0', overflow: 'hidden', boxSizing: 'border-box'}}>
                                    <img style={{objectFit: 'cover', height: '100%'}} src={apple_logo} />
                                </div>
                                <span style={{fontWeight: 'bold'}}>
                                    Continue with Apple
                                </span>
                            </div> */}

                            <div onClick={handleGoogleSignIn} className='delivery_widget authPage grey_background'>
                                <div style={{height: '90%', margin: '5px', padding: '3px', borderRadius: '50%', border: '1px solid #e6ecf0', boxSizing: 'border-box'}}>
                                    <img style={{objectFit: 'cover', height: '100%'}} src={google_logo} />
                                </div>
                                <span style={{fontWeight: 'bold'}}>
                                    Continue with Google
                                </span>
                            </div>

                            <p style={{fontSize: '12px', color: '#808080', fontWeight: 600, marginTop: '1rem', textAlign: 'center'}}>
                                --Or continue with--
                            </p>
                        </div>

                        <div>

                            {/* Input fields */}
                            <div className='register_form_inputs'>       
                                {/* Input & Label */}
                                <div className='register_input_container login'>
                                    <label for="email" className='register_input_label login'>
                                        Enter email address
                                    </label>
                                    <input 
                                        // className='register_input_element login' 
                                        type="text"
                                        placeholder={`e.g., yourname@email.com`}
                                        className="input"
                                        name="email"
                                        value={email}
                                        // maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>
                            </div>

                        </div>

                        {/* /* Form Actions */}
                        <div className='register_btns'>
                            {!account_loading ? (
                                <Button 
                                    onClick={handleEmailSignIn} 
                                    className="sidebar__tweet black"
                                    fullWidth
                                >
                                    Continue with email
                                </Button>
                            ) : (
                                <Button 
                                    className="sidebar__tweet black"
                                    disabled={true}
                                    fullWidth
                                >
                                    processing...
                                </Button>
                            )}
                        </div>
                    </div>
                    
                </div>
            </div>

            {/* Page footer - fixed to bottom of screen */}
            <div className='register_footer'>
                <hr style={{height:'1px', background:'rgb(232, 233, 235)'}} />
                <div className='register_footer_nav'>
                    <a href='/settings/terms'>
                    Read our privacy policy
                    </a>
                    <a href='/settings/help'>
                    Contact support
                    </a>
                </div>
            </div>
        </div>
        
        // <ModalPage>
        //     {/* <Alert /> */}

        //     <div style={{margin: '15px 10px'}} onClick={goBack} className="form__backBtn">
        //         <ArrowBackIcon />
        //     </div>
                
        //     <Logo />

        //     <div>
        //         <h2>Log in with your email</h2>
        //         <p>Enter your email to continue</p>
        //     </div>
        //     <div className="modal__inputs">
        //         <input
        //             type="text"
        //             placeholder="Email"
        //             className="input"
        //             name="email"
        //             value={email}
        //             onChange={e => onChange(e)}
        //         />
        //         {/* <input
        //             type="password"
        //             placeholder="Password"
        //             className="input"
        //             name="password"
        //             value={password}
        //             onChange={e => onChange(e)}
        //         /> */}
        //     </div>
        //     <div>
        //         <Button 
        //             variant="outlined" 
        //             className="sidebar__tweet" 
        //             fullWidth
        //             onClick={onSubmit}
        //         >
        //             Continue
        //         </Button>
        //     </div>
        //     <div className="auth__footer">
        //         <a href={`/register`}>Don't have one?</a>
        //         <a href={`/register`}>Create an account</a>
        //     </div>
        // </ModalPage>
    )
}

// Prop types for the Login component
AuthPage_Body.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    toggleDiscountModal: PropTypes.func.isRequired,
    getModalDiscountById: PropTypes.func.isRequired,
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    checkIfUserExists: PropTypes.func.isRequired,
    signInWithGoogle: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav,
    discount: state.discount
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    loginWithEmailAndPassword,
    setAlert,
    toggleDiscountModal,
    getModalDiscountById,
    checkIfUserExists,
    signInWithGoogle
})(AuthPage_Body);
