import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, add_To_Recent_History, toggleModal_HowItWorks } from '../../actions/navActions';
import { getMenuPage, getMenuItems } from '../../actions/menuActions';
import { getProducts, set_ShowBottomSpinner } from '../../actions/productActions';


// Layout Wrapper
import Layout from '../../components/layout/Layout';

// Components
import { Button } from '@material-ui/core';
import Spinner from '../../components/common/Spinner';
import HowItWorks from '../common/HowItWorks';
import CategoryBlock from '../components/Landing/CategoryBlock';
import Category_List from '../components/CategoryList/Category_List';
import Category_Widget from '../components/CategoryList/Category_Widget';
import Discount_Group_List from '../../components/Discounts/Discount_Group_List';
import Disclaimer from '../common/Disclaimer';
import CategoryMain from '../../Marketplace/components/CategoryList/CategoryMain';
import ImageBox from '../components/ProductList/ProductCard/ImageBox';
import Category_Icon_List from '../components/Category_Icon_List/Category_Icon_List';

// Icons
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import CheckIcon from '@material-ui/icons/Check';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';

// Category Images
import searchIcon_img from '../../utils/imgs/searchIcon_img.png';
import shoppingBag_img from '../../utils/imgs/shoppingBag_img.jpg';
import snackIcon_img from '../../utils/imgs/snackIcon_img.png';
import iceCreamIcon_img from '../../utils/imgs/iceCreamIcon_img.png';
import breadIcon_img from '../../utils/imgs/breadIcon_img.png';
import eggsIcon_img from '../../utils/imgs/eggsIcon_img.png';
import frozenMealsIcon_img from '../../utils/imgs/frozenMealsIcon_img.png';
import milkIcon_img from '../../utils/imgs/milkIcon_img.png';
import cerealicon_img from '../../utils/imgs/cerealicon_img.png';
import sodaIcon_img from '../../utils/imgs/sodaIcon_img.jpg';
import meatIcon_img from '../../utils/imgs/meatIcon_img.png';
import waterIcon_img from '../../utils/imgs/waterIcon_img.jpg';
import snack_img from '../../utils/imgs/snack_img.png';
import iceCream_img from '../../utils/imgs/iceCream_img.jpg';
import bread_img from '../../utils/imgs/bread_img.png';
import eggs_img from '../../utils/imgs/eggs_img.png';
import frozenMeal_img from '../../utils/imgs/frozenMeal_img.jpg';
import milk_img from '../../utils/imgs/milk_img.jpg';
import cereal_img from '../../utils/imgs/cereal_img.jpg';
import soda_img from '../../utils/imgs/soda_img.png';
import meats_img from '../../utils/imgs/meats_img.png';
import water_img from '../../utils/imgs/water_img.png';

const Landing = ({ 
    setPage,
    add_To_Recent_History,
    toggleSideNav,
    getMenuPage, 
    getMenuItems,
    getProducts,
    set_ShowBottomSpinner,
    nav: { 
        sideNav, 
        page,
        campus_name
    },
    cart: {
        cart
    },
    menu: {
        detailMenu
    },
    auth,
    product: {
        no_more_products,
        products,
        show_bottom_spinner,
        last_page_doc,
        loading
    },
    toggleModal_HowItWorks
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Temporarily disable to load more function to avoid duplicate fetch requests
    const [canLoadMore, setCanLoadMore] = useState(true);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const test_param = url.searchParams.get("test");
    
    useEffect(() => {

        if(!auth.loading && !localStorage.delivery_zone_bs_1) {
            toggleModal_HowItWorks();
        }

    }, [auth.loading]);

    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        console.log('CURRENT URL OBJ')
        console.log(url)
        setPage('grocery landing');
        add_To_Recent_History(url.pathname);  // current url

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

            // -- Get Product Data
            if(!detailMenu) getMenuPage("FuuNWfB2pTIbzPEdDIsW");
    }, []);

    useEffect(() => {

        if(detailMenu) {

            getMenuItems(detailMenu._id);
        }

    }, [detailMenu]);


    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Grocery Landing Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more products are met
        if (newScrollY >= scrollHeight - 100 && !show_bottom_spinner && !no_more_products && products.length > 0 && canLoadMore) {
            console.log('scrolling');
            loadMore(last_page_doc);
        }
    }

    // Function to load more paginated posts
    const loadMore = (lastPage_doc) => {
        console.log("LOADING MORE");
        console.log("last", lastPage_doc);

        // Temporarily disable to load more function to avoid duplicate fetch requests
        setCanLoadMore(false);

        // Show spinner
        set_ShowBottomSpinner(true);

        getProducts(lastPage_doc);

        // Re-enable the load more function after 1 second
        setTimeout(() => {
            setCanLoadMore(true);
        }, 1000);

    }
    
    return (
        <Layout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="grocery landing">

            {/* <div className="feed_headerTabs">
                <Link to="/">
                    <div className="active">
                        <h3>Browse</h3>
                        <div className="block__underline"></div>
                    </div>
                </Link>
                <Link to="/marketplace/about">
                    <div>
                        <h3>How It Works</h3>
                        <div className="block__underline"></div>
                    </div>
                </Link>
            </div> */}

            {/* Search Input */}
            <div onClick={() => window.location = '/search?autoFocus=true'} style={{marginRight: '20px', marginBottom: '10px'}} className={`widgets__input`}>
                <SearchIcon className="widgets__searchIcon" />
                <input 
                    id="search-input" 
                    placeholder={`Search Buy / Sell`}
                    type="text" 
                    autocomplete="off" 

                />
            </div>

            {(!auth.isAuthenticated  && !auth.loading) && (
                <Disclaimer show_Call_To_Action={true} link="/user_registration?discount=H4jWsBP1UFZvfR2nh194">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        New User gift waiting for you!
                    </div>
                </Disclaimer>
            )}

            <div className='disclaimer green_black_text'>

                <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                    
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <CreditCardIcon />
                        Safe payments
                    </div>

                    <span style={{margin: '0 10px'}}>
                        | 
                    </span>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <LocalShippingOutlinedIcon />
                        Fast Delivery
                    </div>
                
                </div>
            </div>

            {/* Search Block here */}
            <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                <span>Find what you need</span>
            </div>

            <Category_Icon_List />

            <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                <span>Pick Any Discount</span>
            </div>

            <Discount_Group_List discount_groupID="jLeWSXT5ZRGLfD85l603" />

            {/* <Fragment>
                <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                    <span>Ice Cream</span>
                </div>

                <div style={{padding: '16px', borderBottom: '1px solid #e6ecf0'}}>
                    <div style={{margin: '8px', textAlign: 'center', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', fontSize:'12px'}}>
                        <h2>Delivery In 30 mins</h2>
                    </div>
                </div>

                <Category_Widget collectionId="JBisLQiwxIfbzrOqL3pL" cart={cart} />

                <Category_List />
            </Fragment> */}

            {/* <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                <span>Find Your Groceries</span>
            </div>

            <Fragment>
                <Category_List />
            </Fragment> */}

            <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                <span>How It Works</span>
            </div>

            <div style={{borderBottom: '1px solid #e6ecf0'}}>
                <HowItWorks />
            </div>
            
            {/* 
            <Button 
                onClick={() => window.location = '/search'} 
                className="sidebar__tweet black"
                fullWidth
            >
                Shop Now
            </Button> */}

            <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                <span>You might like...</span>
            </div>

            <CategoryMain page="grocery landing" loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} />

            {/* Add space to the bottom of the page */}
            <div className="no-rides"></div>
        </Layout>
    )
}

Landing.propTypes = {
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    nav: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    getMenuPage: PropTypes.func.isRequired,
    getMenuItems: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    set_ShowBottomSpinner: PropTypes.func.isRequired,
    toggleModal_HowItWorks: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    nav: state.nav,
    cart: state.cart,
    menu: state.menu,
    auth: state.auth,
    product: state.product
})

export default connect(mapStateToProps, { 
    toggleSideNav,
    setPage,
    add_To_Recent_History,
    getMenuPage, 
    getMenuItems,
    getProducts,
    set_ShowBottomSpinner,
    toggleModal_HowItWorks
})(Landing);