import React from 'react';

const CurrencyFormat = ({ value }) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    return (
        <span>
            {formatter.format(value)}
        </span>
    );
};

export default CurrencyFormat;
