import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Firebase
import { db, auth, storage } from "../../../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { getCollectionsByIdList } from '../../../actions/collectionActions';

// Components
import Spinner from '../../../components/common/Spinner';
import Product_Card from '../ProductList/ProductCard/Product_Card';

// Icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const Category_List = ({
    menu: {
        menus
    }
    ,
    collection: {
        collections
    },
    getCollectionsByIdList
}) => {

    //  Collection List
    const [collectionList, setCollectionList] = useState(false);
    const [gotCollections, setGotCollections] = useState(false);

    useEffect(() => {

        if(menus?.length > 0) {

            getCollectionsByIdList(menus);
        }
    }, [menus]);

    useEffect(() => {

        if(collections.length > 0 && !gotCollections) {
            renderCollectionList();
        }

    }, [collections]);

    // Sliding Carousel Style Options
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1000 },
          items: 4,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1000, min: 769 },
          items: 3,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 769, min: 0 },
          items: 3,
          slidesToSlide: 1 // optional, default to 1.
        }
    };

    const renderCollectionList = async () => {
        setCollectionList([]);

        if(collections === null) {
            setCollectionList([(
                <Spinner />
            )])
        }
        else { 
            if(collections.length > 0) {
                try {

                    const promises = collections.map(async collection_block => {

                        const index = menus?.findIndex((menu_item) => menu_item.collection_id === collection_block._id);

                        // --- Get Products In Collection ---

                        const collectionRef = doc(db, 'categories', collection_block._id)
                        const collectionItemsRef = collection(collectionRef, "items")

                        console.log('FETCHING ITEMS');

                        const itemsData = await getDocs(collectionItemsRef);
                        const itemsList = itemsData.docs.map((doc) => ({...doc.data(), id: doc.id}));

                        let product_list = [];
                        // START loop
                        for (const item of itemsList) {

                            // Create a reference to the specified product document in the "products" collection
                            const prodRef = doc(db, 'products', item.product_id);

                            // Retrieve the document with the specified ID from the "products" collection
                            const productDoc = await getDoc(prodRef);

                            product_list.push({
                                ...productDoc.data(), 
                                _id: productDoc.id
                            });
                        }

                        console.log('GOT PRODUCTS IN COLLECTION');
                        console.log(product_list)

                        let itemList;

                        if(product_list === null) {
                            itemList = (
                                <Spinner />
                            );
                        }
                        else { 
                            if(product_list.length > 0) {
                                itemList = product_list.map(item_block => {
                                    return (
                                        <Product_Card 
                                            detailProduct={item_block}
                                            page={"category"}
                                        />
                                    )
                                })
                            } else {
                                itemList = (
                                    <h1>Nothing found...</h1>
                                );
                            }
                        }

                        // - END: Get Products In Collection ---

                        return (
                            
                            /* Product Category Overview */
                            <div style={{marginTop: '16px', width: '100%'}}>

                                <Overview_Header index={index} menus={menus} collection_block={collection_block} />
                                
                                {/* Horizontal Scroll List */}
                                {/* <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    responsive={responsive}
                                    autoPlaySpeed={1000}
                                    customTransition="all .5"
                                    transitionDuration={500}
                                    >
                                    {itemList}
                                </Carousel> */}
                                
                                <Horizontal_ScrollList index={index}>
                                    {itemList}
                                </Horizontal_ScrollList>
       
                            </div>
                        );
                    });

                    // Wait for all promises to resolve and then update the collectionList state
                    const resolvedComponents = await Promise.all(promises);
                    setCollectionList([...resolvedComponents]);

                } catch (error) {
                    setCollectionList([(
                        <h1>Error...</h1>
                    )])

                    console.log('Error!!')
                    console.log(error)
                }
            } else {
                setCollectionList([(
                    <h1>Nothing found...</h1>
                )])
            }
        } 

        setGotCollections(true);
    }

    return (
        <Fragment>
            {collectionList}
        </Fragment>
    )
}

// Category Overview Header
const Overview_Header = ({ 
    index,
    menus,
    collection_block 
}) => {

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [scrollAtStart, setScrollAtStart] = useState(true);
    const [scrollAtEnd, setScrollAtEnd] = useState(false);

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    useEffect(() => {
        let carousel = document.querySelector(`.horizontal_carousel-${index}`);

        if(collection_block.num_of_items > 3) {
            renderBtns();
        } else {
            setScrollAtEnd(true);
        }

        function renderBtns() {
            if(carousel) {
                carousel.addEventListener("scroll", () => {
                    if(carousel.scrollLeft === 0) {
                        setScrollAtStart(true);
                    } else {
                        setScrollAtStart(false)
                    }
    
                    if(carousel.scrollLeft >= carousel.clientWidth / 3) {
                        setScrollAtEnd(true);
                    } else {
                        setScrollAtEnd(false)
                    }
                })
            }
        }

        // Cleanup the event listener when the component unmounts
        return () => carousel.removeEventListener('scroll', null);
    }, []);


    const dec_carousel = (index) => {
        let carousel = document.querySelector(`.horizontal_carousel-${index}`);

        if(!scrollAtStart) {
            let width = carousel.clientWidth;
            carousel.scrollLeft -= width;
            console.log('DEC CAROUSEL')
            console.log(carousel.scrollLeft)
            console.log(width)
        }
    }
    const inc_carousel = (index) => {
        let carousel = document.querySelector(`.horizontal_carousel-${index}`);

        if(!scrollAtEnd) {
            let width = carousel.clientWidth;
            carousel.scrollLeft += width;
            console.log('INC CAROUSEL')
            console.log(carousel.scrollLeft)
            console.log(width)
        }
    }

    return (
        <div style={{padding: '0 8px 8px 8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        
            {/* Overview Title */}
            <div style={{cursor: 'default', flex: 1, overflow: 'hidden'}}>
                <h2 style={{fontSize: `${!isTablet ? '28px' : '24px'}`, maxWidth: '100%', lineHeight: '31.4px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                    {collection_block.name}
                </h2>
            </div>
            {/* END: Overview Title */}

            {/* See More Button Container */}
            <div style={{display: 'flex', flexWrap: 'wrap', marginLeft: '16px', justifyContent: 'center', alignItems: 'center'}}>
                
                {/* See More link */}
                <div style={{marginRight: '16px', marginLeft: '8px', display: 'flex', alignItems: 'center'}}>
                    <div onClick={() => { window.location = `/${menus[index].handle}/${menus[index]._id}`}} style={{padding: '5px 16px', background: 'rgb(236, 238, 233)', cursor: 'pointer', display: 'flex', alignItems: 'center', borderRadius: '64px'}}>
                        <div style={{fontSize: '12px', fontWeight: 'bold', overflow: 'hidden'}}>
                            See all
                        </div>

                    </div>
                </div>
                {/* END: See More link */}
                
                {!isTablet && (
                    <Fragment>

                        {/* Left/Right Btn Container */}
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            
                            {/* Arrow Btn */}
                            <div onClick={() => dec_carousel(index)} className={`horizontal_carousel-btn ${!scrollAtStart ? 'active' : ''}`}>
                                <div style={{fontSize: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <KeyboardArrowLeftIcon />
                                </div>
                            </div>
                            {/* END: Arrow Btn */}

                            <div style={{width: '8px'}}>
                                {/* Spacer element */}
                            </div>

                            {/* Arrow Btn */}
                            <div onClick={() => inc_carousel(index)} className={`horizontal_carousel-btn ${!scrollAtEnd ? 'active' : ''}`}>
                                <div style={{fontSize: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <KeyboardArrowRightIcon />
                                </div>
                            </div>
                            {/* END: Arrow Btn */}
                        </div>
                        {/* END: Left/Right Btn Container */}
                    </Fragment>
                )}
                
            </div>
            {/* END: See More Button Container */}
        </div>
    )
}

{/* Horizontal Scroll List */}
const Horizontal_ScrollList = ({ index, children }) => {

    return (
        <div className={`horizontal_carousel horizontal_carousel-${index}`} style={{overflowX: 'auto', overflowY: 'hidden', display: 'flex', flexGrow: 1}}>
            {children}
        </div>
    )
}

Category_List.propTypes = {
    menu: PropTypes.object.isRequired,
    collection: PropTypes.object.isRequired,
    getCollectionsByIdList: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    menu: state.menu,
    collection: state.collection
})

export default connect(mapStateToProps, { getCollectionsByIdList })(Category_List);