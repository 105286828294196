import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom';
import Div100vh, { use100vh } from 'react-div-100vh';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { setAlert } from '../../actions/alertActions';
import { getOrderById, updateOrderStatus, transferPayout } from '../../actions/orderActions';
import { toggleSideNav, setPage } from '../../actions/navActions';

// Layout Wrapper
import DriverLayout from '../../components/layout/DriverLayout';

// Components
import DefaultAvatar from 'react-avatar';
import DeliveryDetail from '../components/DeliveryDetail/DeliveryDetail';

// Icons
// import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
// import { Button } from '@mui/material';
// import MenuIcon from '@material-ui/icons/Menu';

const DeliveryPage = ({
    auth,
    order: {
        order,
        loading
    },
    nav: { 
        sideNav, 
        page, 
    },
    setPage,
    getOrderById,
    updateOrderStatus,
    transferPayout,
    toggleSideNav,
    setAlert
}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Get URL params
    const { orderID } = useParams();

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const redirect = url.searchParams.get("redirect");

    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());

        setPage('delivery detail');

        if(redirect) {
            setAlert('Complete this delivery to get paid!', 'okay');
        }

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

        if(orderID) {

            // -- Get Product Data
            if(!order) getOrderById(orderID);
        }

    }, [orderID, loading]);

    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;
    const notFullScreen = windowWidth <= 1170;

    const pageHeight = use100vh();

    // Handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)
    }

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Delivery Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

  return (
    <div className="app" style={{ height: pageHeight }}>
        <div className='sidebar'></div>
        <DriverLayout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="delivery">

            {order && (
                <DeliveryDetail 
                    detailOrder={order} 
                    updateOrderStatus={updateOrderStatus}
                    transferPayout={transferPayout}
                    setAlert={setAlert}
                    auth={auth}
                />
            )}

            <div style={{height: '100px'}}></div>
        </DriverLayout>
    </div>

    // <Fragment>
    //     <div className="detail-container">
    //         <div className="store-table-header" style={{padding:'20px 20px 0 20px'}}>
    //             <Delivery_Header />
    //         </div>
    //         <div className="store-table-body" style={{padding:'10px'}}>
    //             <div id="product-content-wrapper">
    //                 <div class="product-admin-main">

    //                     {order && (
    //                         <Fragment>

    //                             <div class="product-privacy-box">
    //                                 <p>Pick-up From:</p>
    //                                 <p>1614 W Lindsey St, Norman, OK 73069</p>
    //                             </div>
    //                             <OrderItemsBlock
    //                                 page="delivery"
    //                                 items={order.cart.items}
    //                             />  

    //                             <div class="product-privacy-box">
    //                                 <p>Customer:</p>
    //                                 <p>{order.user.first_name}{' '}{order.user.last_name}</p>
    //                                 <p>3 orders</p>
    //                             </div>

    //                             <div class="product-privacy-box">
    //                                 <p>Deliver To:</p>
    //                                 <p>{order.user.first_name}{' '}{order.user.last_name}</p>
    //                                 <p>{order.location_details.street_number}{' '}{order.location_details.street_name}</p>
    //                                 <p>{order.location_details.city}, {order.location_details.state} {order.location_details.postalCode}</p>
    //                             </div>

    //                         </Fragment>
    //                     )}  
    //                 </div>

    //             </div>
    //         </div>
    //     </div>

    // </Fragment>
  )
}

DeliveryPage.propTypes = {
    setPage: PropTypes.func.isRequired,
    getOrderById: PropTypes.func.isRequired,
    updateOrderStatus: PropTypes.func.isRequired,
    transferPayout: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,  // Prop: Object representing product state
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    order: state.order,  // Connect: Map order state from redux store to component props
    nav: state.nav
});

export default connect(mapStateToProps, { 

  // Connecting actions from redux to the component
  setPage,
  getOrderById,
  updateOrderStatus,
  transferPayout,
  toggleSideNav,
  setAlert

})(DeliveryPage);