import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

// Actions

// Components - imported
import { Button } from '@material-ui/core';

// Images

const StoreHours = ({
    zone,
    handleModal_StoreHours
}) => {

    // Render the component
    return (
        <div style={{flexDirection: 'column',  marginTop: '30px'}}>
            <div style={{fontSize: '28px', fontWeight: 'bold', margin: '8px 0'}}>
                Store Hours 
                {!zone.isOpen && (
                    <span style={{color: 'red', fontSize: '20px', marginLeft: '5px'}}>(We're Closed)</span>
                )}
            </div>

            <div style={{marginBottom: '16px'}}>
                <div style={{fontSize: '18px', margin: '2px 0', fontWeight: 500}}>
                    {zone.detailZone?.open_hours ? (
                        <ul style={{listStyleType: 'none'}}>
                            {zone.detailZone.open_hours['monday'] && (
                                <li style={zone?.store_hours?.current_day === 'monday' ? {margin: '5px 0', listStyleType: 'initial', fontWeight: 'bold'} : {margin: '5px 0'}}>
                                    Monday: {zone.detailZone.open_hours['monday'].open_time} - {zone.detailZone.open_hours['monday'].close_time}
                                </li>
                            )}
                            {zone.detailZone.open_hours['tuesday'] && (
                                <li style={zone?.store_hours?.current_day === 'tuesday' ? {margin: '5px 0', listStyleType: 'initial', fontWeight: 'bold'} : {margin: '5px 0'}}>
                                    Tuesday: {zone.detailZone.open_hours['tuesday'].open_time} - {zone.detailZone.open_hours['tuesday'].close_time}
                                </li>
                            )}
                            {zone.detailZone.open_hours['wednesday'] && (
                                <li style={zone?.store_hours?.current_day === 'wednesday' ? {margin: '5px 0', listStyleType: 'initial', fontWeight: 'bold'} : {margin: '5px 0'}}>
                                    Wednesday: {zone.detailZone.open_hours['wednesday'].open_time} - {zone.detailZone.open_hours['wednesday'].close_time}
                                </li>
                            )}
                            {zone.detailZone.open_hours['thursday'] && (
                                <li style={zone?.store_hours?.current_day === 'thursday' ? {margin: '5px 0', listStyleType: 'initial', fontWeight: 'bold'} : {margin: '5px 0'}}>
                                    Thursday: {zone.detailZone.open_hours['thursday'].open_time} - {zone.detailZone.open_hours['thursday'].close_time}
                                </li>
                            )}
                            {zone.detailZone.open_hours['friday'] && (
                                <li style={zone?.store_hours?.current_day === 'friday' ? {margin: '5px 0', listStyleType: 'initial', fontWeight: 'bold'} : {margin: '5px 0'}}>
                                    Friday: {zone.detailZone.open_hours['friday'].open_time} - {zone.detailZone.open_hours['friday'].close_time}
                                </li>
                            )}
                            {zone.detailZone.open_hours['saturday'] && (
                                <li style={zone?.store_hours?.current_day === 'saturday' ? {margin: '5px 0', listStyleType: 'initial', fontWeight: 'bold'} : {margin: '5px 0'}}>
                                    Saturday: {zone.detailZone.open_hours['saturday'].open_time} - {zone.detailZone.open_hours['saturday'].close_time}
                                </li>
                            )}
                            {zone.detailZone.open_hours['sunday'] && (
                                <li style={zone?.store_hours?.current_day === 'sunday' ? {margin: '5px 0', listStyleType: 'initial', fontWeight: 'bold'} : {margin: '5px 0'}}>
                                    Sunday: {zone.detailZone.open_hours['sunday'].open_time} - {zone.detailZone.open_hours['sunday'].close_time}
                                </li>
                            )}
                        </ul>
                    ) : (
                        <div>
                            Something went wrong :(
                        </div>
                    )}
                </div>
            </div>
            
            <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                {/* Sign Up Button */}
                <Button 
                    variant="outlined" 
                    className="sidebar__tweet"
                    onClick={handleModal_StoreHours}
                >
                    Got it
                </Button>

            </div>
        </div>
    )
}

// Prop types for the component
StoreHours.propTypes = {

    // Prop type validation for function to toggle the Authentication modal
    zone: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    zone: state.zone
})

export default connect(mapStateToProps, { 

    // Connecting actions from Redux to the component

})(StoreHours);
