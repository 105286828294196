import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { format, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';

// Firebase
import { db } from '../../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { cancel_And_Refund_Order } from '../../actions/orderActions'; 
import { setAlert } from '../../actions/alertActions';

// Modal
import Modal from '../../components/modal/Modal';
import ModalContainer from '../../components/modal/ModalContainer';

// Components - imported
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import OrderItem from './OrderItem';
import Spinner from '../common/Spinner';
import CurrencyFormat from '../../Marketplace/common/CurrencyFormat';

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';

const OrderDetail = ({
    nav: {
        campus_name
    },
    order: {
        order: {
            cart
        }
    },
    detailOrder,
    cancel_And_Refund_Order,
    setAlert
}) => {

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);
    const history = useHistory();

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Live Order Data
    const [liveOrderDetails, setLiveOrderDetails] = useState(detailOrder);

    // Orders List
    const [cartList, setCartList] = useState([]);
    const [gotCartList, setGotCartList] = useState(false);

    // Modal Toggles
    const [cancelOrderModal, setCancelOrderModal] = useState(false);
    const [callDriverModal, setCallDriverModal] = useState(false);

    // Processing order state
    const [cancellingOrder, setCancellingOrder] = useState(false);

    // - Timer ----

    const [timer, setTimer] = useState('00:00:00');

    const getTimeRemaining = (e) => {

        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);

        return {
            total, hours, minutes, seconds
        };
    }
 
    const startTimer = (e) => {
        
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
                    
        if (total >= 0) {
 
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable

            if(hours == 0) {
                setTimer(
                    (minutes > 9 ? minutes : '0' + minutes) + ':'
                    + (seconds > 9 ? seconds : '0' + seconds)
                )
            } else {
                setTimer(
                    (hours > 9 ? hours : '0' + hours) + ':' +
                    (minutes > 9 ? minutes : '0' + minutes) + ':'
                    + (seconds > 9 ? seconds : '0' + seconds)
                )
            }
        }
    }
 
    const clearTimer = (e) => {
 
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next   
        setTimer('00:00');
 
        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
 
    const getDeadTime = (remainingSeconds) => {
        let deadline = new Date();
 
        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(remainingSeconds);
        return deadline;
    }

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
 
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {

        function formatTimestamp(timestamp) {
            const seconds = timestamp.seconds;
            const nanoseconds = timestamp.nanoseconds;
          
            const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1e6); // Convert nanoseconds to milliseconds
            const isoDate = new Date(milliseconds).toISOString();
          
            return isoDate;
        }


        if(detailOrder?.est_complete_time_ISO) {
            
            const isoFormattedDate = formatTimestamp(detailOrder.est_complete_time_ISO);

            const targetDateTime = isoFormattedDate; // Replace with your desired target date and time in ISO format
            const remainingSeconds = secondsUntilDateTime(targetDateTime);

            console.log('SETTING TIMER');
            console.log(detailOrder.est_complete_time_ISO)
            console.log(detailOrder.est_complete_time_ISO.seconds)
            console.log(isoFormattedDate)

            clearTimer(getDeadTime(remainingSeconds));
        }
    }, []);

    const secondsUntilDateTime = (targetDateTime) => {
        const currentDate = new Date();
        const targetDate = new Date(targetDateTime);
        
        if (targetDate <= currentDate) {
            return 0; // The target date and time has already passed
        }
        
        const timeDifference = targetDate - currentDate;
        const secondsRemaining = timeDifference / 1000; // Convert milliseconds to seconds
        
        return Math.floor(secondsRemaining);
    }

    // - END: Timer ----

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const updated = url.searchParams.get("updated");

    // Firebase collection ref
    const ordersCollectionRef = collection(db, "orders");

    const {
        cart: {
            items,
            total,
            subtotal,
            tax,
            qty,
            tip
        }
    } = liveOrderDetails

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Load order snapshot - to get LIVE ORDER UPDATES W/O RELODING PAGE
        const unsubscribe = load_Order_Snapshot(detailOrder._id)

        // Clean up by removing event listener on component unmount
        return () => unsubscribe();
        
    }, [detailOrder]);

    // Function to load more paginated posts
    const load_Order_Snapshot = (orderId) => {
        console.log("LOADING LIVE ORDER UPDATES!");
        
        // Reference the specific order document by ID
        const orderDocRef = doc(ordersCollectionRef, orderId);

        // Listen for real-time updates on the order document
        const unsubscribe = onSnapshot(orderDocRef, (orderSnapshot) => {

            if (orderSnapshot.exists()) {

                const orderData = orderSnapshot.data();
                const orderID = orderSnapshot.id;

                console.log('UPDATING ORDER DETAILS:', orderData);

                // Update the live order details in useState
                setLiveOrderDetails({_id: orderID, ...orderData});

                // Alert the user their order has been updated
                setAlert("Order Updated!", "okay");
            } else {
                console.log("Order document does not exist");
            }
        });

        // Remember to unsubscribe from the snapshot listener when needed
        return unsubscribe;
    }

    // Listen for address_changes alert trigger and remove it after the alert has triggered
    useEffect(() => {

        if(updated === 'address_changed' && detailOrder) {
            setAlert('Address Updated!', 'success');

            // Remove ALERT trigger from URL
            history.push(`/orders/${detailOrder._id}`)
        }

    }, [detailOrder, updated]);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings account page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Order Detail Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {

        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    // Go back to main settings page
    const goBack = () => {
        window.location.href = '/orders';
    }

    useEffect(() => {

        renderCartList();
    }, []);

    const renderCartList = () => {
        setCartList([]);

        if(items === null) {
            setCartList([(
                <div className="no-rides">
                    <h1>Empty</h1>
                </div>
            )])
        }
        else { 
            if(items.length > 0) {

                items.map(order_item => {
                    setCartList(cartList => [...cartList, (
                        <OrderItem
                            orderItem={order_item}
                        />
                    )])
                });

                setGotCartList(true);
            } else {
                setCartList([(
                    <div className="no-rides">
                        <h1>Empty</h1>
                    </div>
                )])
            }
        } 
    }

    // -- Cancel Order

    const toggleCancelOrderModal = () => {
        setCancelOrderModal(!cancelOrderModal);
    }

    const handleCancelOrder = () => {
        setCancellingOrder(true);
        cancel_And_Refund_Order(campus_name, detailOrder);
    }

    // -- END: Cancel Order

    // -- Change Address

    const handle_change_address = () => {
        
        if(liveOrderDetails.status === 'waiting' || liveOrderDetails.status === 'collecting') {
            history.push(`/orders/${liveOrderDetails._id}?show=change_address`)
        } else {
            setAlert("Too late! Your Delivery partner is ON THE WAY!", 'danger');

            if(liveOrderDetails.driver_id_list && liveOrderDetails.driver_id_list.length > 0) {
                toggle_call_driver_modal();
            }
        }
    }

    // -- END: Change Address

    // -- Call Driver Address
    const handle_call_driver = () => {

        let driverPhone = liveOrderDetails?.driver[0]?.user?.phone;

        if(driverPhone) {
            window.location = `tel:${driverPhone}`;
        } else {
            setAlert("No Phone # on file!", 'danger');
        }

        toggle_call_driver_modal()
    }

    const toggle_call_driver_modal = () => {
        setCallDriverModal(!callDriverModal);
    }

    // -- END: Call Driver Address

    return (
        <Fragment>
            <div className="orderPage">

                {/* OrderPage Header */}
                <div className="orderPage__header">
                    {isTablet && (

                        // Back Button & Badge Value
                        <div onClick={goBack} className="profile__btnContainer">
                            <ArrowBackIcon />
                        </div>
                    )}

                    {/* Title */}
                    <div className="orderPage__headerText">
                        <h3>
                            Order Details
                        </h3>
                    </div>
                </div>

                <div className="orderPage__body">

                    {liveOrderDetails?.status === 'delivered' && (
                        <div style={{marginBottom: '8px', background:'#90EE90', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                            <h3>Order Complete!</h3>
                            {liveOrderDetails?.est_complete_time && <span style={{marginTop: '3px'}}>Delivered: {format(liveOrderDetails.date, "MMM do")} at {format(liveOrderDetails.date, "h:mm a")} </span>}
                        </div>
                    )}

                    {liveOrderDetails?.status === 'arrived' && (
                        <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                            <h3>Your delivery partner has arrived!</h3>
                            <span style={{marginTop: '3px'}}>Please give them your code.</span>
                        </div>
                    )}

                    {liveOrderDetails?.status === 'in transit' && (
                        <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                            <h3>Your order is on the way!</h3>
                            <span style={{marginTop: '3px'}}>Please get your code ready.</span>
                        </div>
                    )}

                    {liveOrderDetails?.status === 'collecting' && (
                        <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                            <h3>A delivery partner is collecting your items</h3>
                            <span style={{marginTop: '3px'}}>Just chill. Your order will arrive soon.</span>
                        </div>
                    )}

                    {liveOrderDetails?.status === 'waiting' && (
                        <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                            <h3>Order Received</h3>
                            <span style={{marginTop: '3px'}}>Waiting for an available delivery partner to pick up your order.</span>
                        </div>
                    )}

                    {liveOrderDetails?.status === 'expired' && (
                        <div style={{marginBottom: '16px', background:'#ffcccb', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                            <h3>This order expired</h3>
                            <span style={{marginTop: '3px'}}>We sent you a full refund.</span>
                        </div>
                    )}

                    {(liveOrderDetails?.status === 'cancelled' || liveOrderDetails?.status === 'customer cancelled') && (
                        <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                            <h3>We sent you a full refund</h3>
                            <span style={{marginTop: '3px'}}>You cancelled this order in the past.</span>
                        </div>
                    )}

                    {liveOrderDetails?.status === 'driver cancelled' && (
                        <div style={{marginBottom: '16px', background:'#ffcccb', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                            <h3>Order Returned</h3>
                            <span style={{marginTop: '3px'}}>Looks like your delivery partner was unable to deliver your order. We sent you a full refund.</span>
                        </div>
                    )}

                    

                    {/* <div style={{ paddingBottom: '16px', textAlign: 'center', width: '100%', borderBottom: '1px solid #e6ecf0'}}>
                        <h1>You Paid: ${total}</h1>
                    </div> */}

                    {/* Banner Image */}
                    {liveOrderDetails?.status === 'waiting' && (
                        <div style={{width: '100%', flex: 1, display: 'flex', justifyContent: 'center'}}>
                            <div style={{height: '171.818px', maxWidth: '672px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: '8px', overflow: 'hidden', border: '1px solid #e6ecf0', position: 'relative'}}>
                                <h2 style={{fontWeight: '400', marginBottom: '5px'}}>Your order expires in:</h2>
                                <h1 style={{color: '#AE0000', fontSize: '40px', marginRight: '-40px'}}>{timer} <span style={{fontWeight: '400', fontSize: '24px'}}>mins</span></h1>
                            </div>
                        </div>
                    )}

                    {liveOrderDetails?.status !== 'delivered' && liveOrderDetails?.status !== 'customer cancelled' && liveOrderDetails?.status !== 'driver cancelled' && liveOrderDetails?.status !== 'expired' && liveOrderDetails?.status !== 'cancelled' && (
                        <div className='gray' style={{minWidth:'100%', minHeight:'48px', borderBottom: '1px solid #e6ecf0', justifyContent:'center', display:'flex', flexDirection: 'column', padding:'8px 0', boxSizing:'border-box'}}>
                            <h2 style={{fontWeight: 500, lineHeight:'23.8px', fontSize:'18px'}}>
                                Your Code: <span style={{color: 'rgb(29, 155, 240)'}}>#{liveOrderDetails?._id.slice(0, 4)}</span>
                            </h2>
                        </div>
                    )}

                    {/* <div style={{marginBottom: '16px', background:'rgb(254, 249, 217)', flexWrap:'wrap', display:'flex', flexDirection: 'column', padding:'16px', borderRadius:'8px', lineHeight:'15.8px', textAlign:'left', fontSize:'12px'}}>
                        <h3>Delivered!</h3>
                        <span style={{marginTop: '3px'}}>Completed Oct 14, 2022, 2:49 PM </span>
                    </div> */}

                    {/* <div style={{width: '100%', flex: 1, display: 'flex', justifyContent: 'center'}}>
                        <div className='tweetBox__img--image' style={{height: '200px', maxWidth: '672px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: '8px', overflow: 'hidden', border: '1px solid #e6ecf0', position: 'relative'}}>
                            <img className="fullWidth" src="https://firebasestorage.googleapis.com/v0/b/mycontacts-9ba5a.appspot.com/o/images%2Fposts%2FmNqcxc9n3JTzxOQEp5oS%2FIMG_20230823_101556.jpg?alt=media&token=005ff8a1-0b2a-4de2-af92-6769155ec23a" />
                        </div>
                    </div> */}

                    {liveOrderDetails?.status !== 'delivered' && liveOrderDetails?.driver && liveOrderDetails?.driver.length > 0 && (
                        <div className='checkout_form_toggle'>
                            <div style={{minWidth:'100%', borderBottom: '1px solid #e6ecf0', minHeight:'48px', alignItems:'center', display:'flex', padding:'8px 0', boxSizing:'border-box'}}>
                                <div style={{marginRight:'12px', height:'40px', alignItems:'center', justifyContent:'center', display:'flex', width:'40px', padding:'6px', borderRadius:'40px'}}>
                                    <Avatar src='https://firebasestorage.googleapis.com/v0/b/mycontacts-9ba5a.appspot.com/o/images%2FprofilePics%2Fp3TQfcImGgWB5joQ7mpRHvwLSuZ2.JPG?alt=media&token=f77711f0-f898-43ad-b8fd-f758aa980e56' />
                                </div>
                                <div style={{flexGrow: 1}}>
                                    <h2 className='line-clamp-1' style={{fontWeight:'bold', lineHeight:'23.8px', fontSize:'18px'}}>
                                        Delivery Partner
                                    </h2>
                                    <span className='line-clamp-1' style={{color: 'rgb(83, 100, 113)', fontWeight: 400, fontSize: '14px'}}>
                                        ({liveOrderDetails?.driver[0].user.first_name}{' '}{liveOrderDetails?.driver[0].user.last_name})
                                    </span>
                                    <p className='line-clamp-1' style={{margin: '6px 0', margin: '0', fontSize: '14px'}}>
                                        Have an issue? Call me.
                                    </p>
                                </div>
                                <div onClick={toggle_call_driver_modal} style={{display:'flex', alignItems:'center'}}>
                                    <div style={{fontSize:'32px', color: 'rgb(249, 24, 128)', width: '40px', height: '40px', borderRadius: '50%', border: '1px solid #e6ecf0', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight:'16px'}}>
                                        <PhoneInTalkIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    

                    {/* {liveOrderDetails?.status === 'delivered' && (
                        <div className="orderPage__section delivery">
                            <div>
                                <h3>
                                    Order Arrived In:
                                </h3>
                                
                            </div>
                            <div>
                                <p>10:43 mins</p>
                            </div>
                        </div>
                    )} */}

                    {liveOrderDetails?.status !== 'customer cancelled' && liveOrderDetails?.status !== 'driver cancelled' && liveOrderDetails?.status !== 'expired' && liveOrderDetails?.status !== 'cancelled' && (
                        <div className="orderPage__section delivery">
                            <div>
                                {liveOrderDetails?.status === 'delivered' ? (
                                    <h3>
                                        Delivered To:
                                    </h3>
                                ): (
                                    <h3>
                                        Deliver To:
                                    </h3>
                                )}
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', textAlign: 'right'}}>
                                <p>{liveOrderDetails.location_details.street_number}{' '}{liveOrderDetails.location_details.street_name}</p>
                                {liveOrderDetails.location_details.room_number && (
                                    <p>Room: {liveOrderDetails.location_details.room_number}</p>
                                )}
                                <p>{liveOrderDetails.location_details.city}{', '}{liveOrderDetails.location_details.state} {liveOrderDetails.location_details.postalCode}</p>
                            </div>
                            {/* <div>
                                <p>Pick up</p>
                                <p>6100 Glenhollow Dr.</p>
                                <p>2pm - 2:30pm</p>
                            </div> */}
                        </div>
                    )}

                    <div className="orderPage__section payment">

                        <div>
                            <h3>
                                Payment Status:
                            </h3>

                        </div>
                        {liveOrderDetails?.refund_id ? (
                            <div>
                                <div className='danger'>
                                    <MoneyOffIcon />
                                    <p>Refunded </p>
                                </div>
                                <p>Your Card</p>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <CheckIcon />
                                    <p>Payed </p>
                                </div>
                                <p>With Card</p>
                            </div>
                        )}
                    </div>

                    <div style={{minWidth:'100%', minHeight:'48px', borderBottom: '1px solid #e6ecf0', alignItems:'center', display:'flex', padding:'8px 0', boxSizing:'border-box'}}>
                        <div style={{height:'30px', width:'30px', alignItems:'center', justifyContent:'center', display:'flex', padding:'6px', borderRadius:'40px'}}>
                            <LocalMallOutlinedIcon />
                        </div>
                        <div style={{flexGrow: 1}}>
                            <h2 style={{fontWeight:'bold', lineHeight:'23.8px', fontSize:'18px'}}>
                                {qty} Items
                            </h2>
                        </div>
                    </div>
                    
                    {liveOrderDetails?.status !== 'delivered' && liveOrderDetails?.status !== 'customer cancelled' && liveOrderDetails?.status !== 'driver cancelled' && liveOrderDetails?.status !== 'expired' && liveOrderDetails?.status !== 'cancelled' && (
                        <div style={{width: '100%', display: 'flex'}}>

                            <div style={{flex: 1, padding: '10px 5px 0px 0px', boxSizing: 'border-box'}}>
                                <div onClick={handle_change_address} style={{backgroundColor: '#e6ecf0', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                    <div style={{color: '#000', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                        Change Address
                                    </div>
                                </div>
                            </div>

                            <div style={{flex: 1, padding: '10px 0px 0px 5px', boxSizing: 'border-box'}}>
                                <div onClick={toggleCancelOrderModal} style={{background: '#fff', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                    <div style={{color: '#000', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                        Cancel Order
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                    )}

                    {/* {liveOrderDetails?.status === 'delivered' ? (
                        <div style={{width: '100%', display: 'flex'}}>

                            <div style={{flex: 1, padding: '10px 5px 0px 0px', boxSizing: 'border-box'}}>
                                <div style={{backgroundColor: '#e6ecf0', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                    <div style={{color: '#000', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                        Reorder
                                    </div>
                                </div>
                            </div>

                            <div style={{flex: 1, padding: '10px 0px 0px 5px', boxSizing: 'border-box'}}>
                                <div style={{background: '#fff', width: '100%', padding: '4px 0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', borderRadius: '64px', border: '2px solid #e6ecf0'}}>
                                    <div style={{color: 'rgb(249, 24, 128)', lineHeight: '23.8px', maxWidth: '100%', textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '16px', overflow: 'hidden'}}>
                                        Rate Order
                                    </div>
                                </div>
                            </div>
                    
                    </div> */}
        

                    <div className="orderPage__items" style={{borderBottom: '1px solid #e6ecf0'}}>

                        {!cartList.length > 0 || !gotCartList ? <Spinner /> : cartList}
                    </div>

                    <div style={{padding: '16px 0'}}>
                        {liveOrderDetails?.refund_id ? (
                             <h3>Amount Refunded</h3>
                        ) : (
                            <h3>Order Summary</h3>
                        )}

                        <div className="orderPage__section totals">
                            <div>
                                <p>Subtotal:</p>
                                <p>Driver Tip:</p>
                                <p>Tax:</p>
                                {liveOrderDetails?.refund_id ? (
                                    <p>Refund Total:</p>
                                ) : (
                                    <p>Completed Total:</p>
                                )}
                            </div>
                            <div>
                                {liveOrderDetails.cart.discounts && (liveOrderDetails.cart.subtotal !== liveOrderDetails.cart.discountPrice) ? (
                                    <span style={{display: 'flex', alignItems: 'center'}}>
                                        <s style={{color: 'rgb(92, 92, 92)', fontSize: '13px', marginRight: '5px'}}><CurrencyFormat value={liveOrderDetails.cart.subtotal} /></s> <p style={{margin: '0px'}}><CurrencyFormat value={liveOrderDetails.cart.discountPrice} /></p>
                                    </span>
                                ) : (
                                    <p>${liveOrderDetails.cart.subtotal}</p>
                                )}
                                
                                {/* <p>${liveOrderDetails.cart.subtotal}</p> */}
                                <p>${liveOrderDetails.cart.tip}</p>
                                <p>${liveOrderDetails.cart.tax}</p>
                                <p>${liveOrderDetails.cart.total}</p>
                            </div>
                        </div>
                    </div>

                    <div onClick={() => window.location = '/settings/help'} className='checkout_form_toggle'>
                        <div style={{minWidth:'100%', minHeight:'48px', alignItems:'center', display:'flex', padding:'8px 0', boxSizing:'border-box'}}>
                            <div style={{marginRight:'12px', height:'40px', alignItems:'center', justifyContent:'center', display:'flex', width:'40px', padding:'6px', borderRadius:'40px'}}>
                                <HeadsetMicOutlinedIcon />
                            </div>
                            <div style={{flexGrow: 1}}>
                                <h2 style={{fontWeight:'bold', lineHeight:'23.8px', fontSize:'18px'}}>
                                    Customer Support
                                </h2>
                                <p style={{margin: '6px 0', margin: '0', fontSize: '14px'}}>
                                    Have an issue? Chat with us.
                                </p>
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{fontSize:'32px', marginLeft:'32px'}}>
                                    <ChevronRightIcon />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{height: '100px'}}></div>

                </div>

            </div>

            {/* Cancel / Refund Order Modal */}
            <ModalContainer show={cancelOrderModal} onClose={toggleCancelOrderModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Cancel Order</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Are you sure? You will be refunded the full amount: <b>${cart.total}</b></p>
                    
                    {/* Modal Actions */}
                        
                        {!cancellingOrder ? (
                            <Button 
                                onClick={handleCancelOrder}
                                variant="outlined" 
                                className="sidebar__tweet danger"
                                fullWidth
                            >
                                Yes, Cancel Order
                            </Button>
                        ) : (
                            <Button 
                                variant="outlined" 
                                className="sidebar__tweet danger"
                                fullWidth
                                disabled={true}
                            >
                                Cancelling...
                            </Button>
                        )}

                        <Button 
                            onClick={toggleCancelOrderModal}
                            variant="outlined" 
                            className="sidebar__tweet ghost blackText"
                            fullWidth
                        >
                            Never Mind
                        </Button>
                </Modal>
            </ModalContainer>

            {/* Cancel / Refund Order Modal */}
            <ModalContainer show={callDriverModal} onClose={toggle_call_driver_modal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Call your Driver!</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Have an issue or want to change something?</p>
                    
                    {/* Modal Actions */}
                        
                        <Button 
                            onClick={handle_call_driver}
                            variant="outlined" 
                            className="sidebar__tweet danger"
                            fullWidth
                        >
                            Call Driver
                        </Button>

                        <Button 
                            onClick={toggle_call_driver_modal}
                            variant="outlined" 
                            className="sidebar__tweet ghost blackText"
                            fullWidth
                        >
                            Never Mind
                        </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

OrderDetail.propTypes = {
    nav: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    cancel_And_Refund_Order: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    order: state.order
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    cancel_And_Refund_Order, 
    setAlert
})(OrderDetail);
