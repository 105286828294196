import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';

import mixpanel from 'mixpanel-browser';

// Stripe API
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

// Redux
import { connect } from 'react-redux';

// Actions
import { setPage, add_To_Recent_History, toggleLocationModal, toggleModal_ProcessingOrder } from '../../actions/navActions';
import { fetchGuestPhone } from '../../actions/authActions';
import { updateDriverTip, createOrder } from '../../actions/cartActions';
import { setAlert } from '../../actions/alertActions';

// Layout Wrapper
import CartLayout from '../../components/layout/CartLayout';

// Components
import Checkout_Totals from '../components/Checkout/Checkout_Totals';
import { Button } from '@material-ui/core';

// Icons
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Spinner from '../../components/common/Spinner';
import CheckIcon from '@material-ui/icons/Check';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import LockIcon from '@material-ui/icons/Lock';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

// Initial State 
const initialState = {
    deliveryOption: "fast",
    scheduleTime: '',
    deliveryInstructions: '',
};

const CheckoutPage = ({
    locationState: {
        detailLocation,
        deliveryTime,
    },
    locationState,
    auth: {
        isAuthenticated,
        user,
        loading
    },
    nav: {
        campus_name
    },
    discount: {
        discounts
    },
    zone,
    cart,
    setPage,
    add_To_Recent_History,
    toggleLocationModal,
    toggleModal_ProcessingOrder,
    fetchGuestPhone,
    updateDriverTip,
    createOrder,
    setAlert
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const [cartList, setCartList] = useState([]);
    const [gotCartList, setGotCartList] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Form state
    const [deliveryNotes, setDeliveryNotes] = useState('');
    const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState('');
    const [estimatedDeliveryTime_ISO, setEstimatedDeliveryTime_ISO] = useState('');

    // Toggle Forms
    const [switchDeliveryOption, setSwitchDeliveryOption] = useState(0);
    const [driverTipOption, setDriverTipOption] = useState(1);
    const [toggleDeliveryInstructions, setToggleDeliveryInstructions] = useState(false);
    const [togglePhoneNumber, setTogglePhoneNumber] = useState(false);
    const [toggleCardForm, setToggleCardForm] = useState(false);

    // Processing order state
    const [processingOrder, setProcessingOrder] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        setPage('checkout');
        add_To_Recent_History(url.pathname);  // current url

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

        let cardNumberElement;
        let cardExpiryElement;
        let cardCvcElement;

        if(elements) {
            // Load Stripe Card Elements
            cardNumberElement = elements.create('cardNumber');
            cardExpiryElement = elements.create('cardExpiry');
            cardCvcElement = elements.create('cardCvc');

            cardNumberElement.mount('#card-number')
            cardExpiryElement.mount('#card-expiry')
            cardCvcElement.mount('#card-cvc')
            
            // END: Load Stripe Card Elements
        }

        return () => {
            if(elements) {
                cardNumberElement.unmount('#card-number')
                cardExpiryElement.unmount('#card-expiry')
                cardCvcElement.unmount('#card-cvc')
            }
        };
    }, [elements]);

    useEffect(() => {

        if(!isAuthenticated && !loading) fetchGuestPhone();

    }, [loading, isAuthenticated]);

    useEffect(() => {

        if(!cart.loading) handleDriverTipOption(driverTipOption)

    }, [cart.loading]);

    useEffect(() => {

        function addMinutes(date, minutes) {
            date.setMinutes(date.getMinutes() + minutes);
        
            return date;
        }

        if(deliveryTime) {
            
            var today = new Date();
            
            const newDate = addMinutes(today, deliveryTime);

            var date_est = (newDate.getMonth()+1)+'-'+newDate.getDate()+'-'+newDate.getFullYear();
            var time_est = newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
            var estimatedTime = date_est+' '+time_est;

            console.log('EST. DELIVERY TIME');
            console.log(estimatedTime)
            console.log(newDate)

            setEstimatedDeliveryTime(estimatedTime);
            setEstimatedDeliveryTime_ISO(newDate);
        }

    }, [deliveryTime]);


    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Generate a random 10-digit number 
    // ( to be ID for the Stripe transfer_group )
    const generateRandom10DigitNumber = () => {
        const min = 1000000000; // Smallest 10-digit number (10^9)
        const max = 9999999999; // Largest 10-digit number (10^10 - 1)
        const random10DigitNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        return random10DigitNumber.toString();
    }

    // Function to handle input change
    const onChange = e => setDeliveryNotes(e.target.value);

    const handleSubmitOrder = async () => {

        // Check if stripe is loaded before processing payment
        if (!stripe || !elements || !isAuthenticated || !zone.isOpen || !zone.detailZone) {
            // Stripe or Elements not ready, handle error
            return;
        }

        setProcessingOrder(true);
        toggleModal_ProcessingOrder();

        // Generate a random 10-digit number 
        // ( to be ID for the Stripe transfer_group )
        const transfer_group_ID = generateRandom10DigitNumber();

        const bilingDetails = {
            name: 'tunde adepitan',
            email: 'tundeadepitan@ou.edu',
            address: {
                city: 'plano',
                line1: '6100 glenhollow dr.',
                state: 'tx',
                postal_code: '75093',
                country: 'US'
            }
        }

        const { data: clientSecret } = await axios.post("https://intense-beyond-56709-63aadc62ac0b.herokuapp.com/payment_intents", {
            amount: cart.cart.total * 100,
            transfer_group: transfer_group_ID
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('CLIENT SECRET: ', clientSecret);

        const cardElement = elements.getElement('cardNumber');

        const paymentMethodReq = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: bilingDetails
        })

        console.log('PAYMENT METHOD REQ');
        console.log(paymentMethodReq);

        
        if(paymentMethodReq.error) {
            setAlert(paymentMethodReq.error.message, 'danger');
            setProcessingOrder(false);
            toggleModal_ProcessingOrder();
        } else if (paymentMethodReq.paymentMethod) {

            // Payment Intent Succeeded,
            // Run payment intent to confirm payment
            const confirmedCardPayment = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethodReq.paymentMethod.id
            });
    
            console.log('CONFIRMED PAYMENT ID');
            console.log(confirmedCardPayment);
    
            // -- CREATE ORDER
            
            // Build Order object
            const newOrder = {};
            newOrder.status = 'waiting';
            newOrder.transfer_group = transfer_group_ID;
            newOrder.first_name = user.first_name;
            newOrder.last_name = user.last_name;
            newOrder.placeId = detailLocation.placeId;
            newOrder.formatted_address = detailLocation.formatted_address;
            newOrder.driver_tip = cart.cart.tip;
    
            let delivery_type = '';
    
            if (switchDeliveryOption === 0) {
                delivery_type = 'FAST';
            } else if (switchDeliveryOption === 1) {
                delivery_type = 'SCHEDULED';
            } else {
                return; // Invalid index
            }
    
            if(delivery_type !== null) newOrder.delivery_type = delivery_type;
            if(estimatedDeliveryTime !== '') newOrder.est_complete_time = estimatedDeliveryTime;
            if(estimatedDeliveryTime_ISO !== '') newOrder.est_complete_time_ISO = estimatedDeliveryTime_ISO;
    
            // if(address_2) newOrder.address_2 = address_2;
            newOrder.phone = user.phone;
            if(deliveryNotes) newOrder.delivery_instructions = deliveryNotes;
    
            // Build location obj
            newOrder.location_details = detailLocation;
    
            newOrder.user = null;
            newOrder.userID = null;
    
            if(user?._id) newOrder.user = user;
            if(user?._id) newOrder.userID = user._id;
            newOrder.cart = cart.cart;
            newOrder.paymentId = confirmedCardPayment.paymentIntent.id;
    
            createOrder(newOrder, campus_name);
    
            // -- END: CREATE ORDER
        } else {
            setAlert('Something went wrong!', 'danger');
            setProcessingOrder(false);
            toggleModal_ProcessingOrder();
        }
    }

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

    }

    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    const handleDriverTipOption = (index) => {
        
        let tipAmount;

        if (index === 0) {
            tipAmount = 2.49;
        } else if (index === 1) {
            tipAmount = 3.49;
        } else if (index === 2) {
            tipAmount = 4.49;
        } else if (index === 3) {
            tipAmount = 0.00;
        } else {
            return; // Invalid index
        }

        // Update cart price
        if(tipAmount !== null) updateDriverTip(tipAmount);
        
        setDriverTipOption(index);
    }

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Checkout Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // Styline Options

    const cardElementOptions = {

        // inject styles into `CardElement` iframe
        // style: {
        //     base: {
        //         fontSize: '16px',
        //         color: '#000',
        //         "::placeholder": {
        //             color: '#000'
        //         }
        //     }, 
        //     invalid: {
        //         color: 'red',
        //         iconColor: '#red'
        //     },
        //     complete: {

        //     }
        // },

        hidePostalCode: true,
    }

    if (!zone.loading && zone.checked_isOpen) {

        if(!zone.isOpen && zone.detailZone) {
            window.location.href = "/cart";

        } else if(!zone.detailZone) {
            window.location.href = "/select-city";
        }
    }

    if (!locationState.loading && !detailLocation) {

        // Can't view Checkout page if there is no location in state
        window.location.href = "/cart?show=add_address";
    }

    if (user && !(user?.phone?.length > 0)) {

        // Can't view Checkout page if user phone number is empty
        window.location.href = "/cart?show=add_phone";
    }

    if (!loading && !isAuthenticated) {

        // Can't view Checkout page if not logged in
        window.location.href = "/login";
    }

    return (
        <CartLayout page="checkout" handleScroll={handleScroll}>
            {(!stripe || !elements) ? (
                <div style={{padding: '0 15px'}}>
                    <Spinner />
                </div>
            
            ) : (
            <div style={{padding: '0 15px'}}>
                {/* <h1 style={{marginTop: '24px', marginBottom: '0', color: 'rgb(0, 0, 0)', lineHeight: '42.6px'}}>
                    Delivery Details
                </h1> */}

                {/* <div className='disclaimer green_black_text'>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <CheckIcon />
                            Free Delivery
                        </div>

                    </div>
                </div> */}

                {discounts.length > 0 && (
                    <div className='disclaimer green_black_text'>

                        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: 400}}>
                                <LoyaltyOutlinedIcon />
                                You're saving <span style={{fontWeight: 'bold', margin: '0 3px'}}>${parseFloat((cart.cart.subtotal - cart.cart.discountPrice).toFixed(2))}</span> on this order
                            </div>

                        </div>
                    </div>
                )}
                
                <div style={{overflow: 'hidden', margin: '10px 0', display: 'flex', flexGrow: 1}}>
                    {/* <div onClick={() => setSwitchDeliveryOption(0)} className={`checkout_delivery_option ${switchDeliveryOption === 0 && 'active'}`}>
                        <div style={{padding: '4px 0', margin: '0 4px'}}>
                            <div style={{padding: '0 4px 4px', display: 'flex', justifyContent: 'space-between'}}>
                                <QueryBuilderIcon />

                                {switchDeliveryOption === 0 ? (
                                    <CheckCircleIcon />
                                ) : (
                                    <RadioButtonUncheckedOutlinedIcon />
                                )}
                            </div>
                            <h2 style={{fontSize: '18px', marginTop: '4px', marginLeft: '4px', lineHeight: '23.8px'}}>
                                Fast
                            </h2>

                            {deliveryTime && (
                                <div className='icon-container' style={{display: 'flex', color: `${switchDeliveryOption === 0 ? 'rgba(0,153,89,1.00)' : '#000'}`, padding: '5px 0', alignItems: 'center'}}>
                                    <OfflineBoltOutlinedIcon />
                                    <p style={{fontSize: '17px', fontWeight: 'bold', margin: '0', lineHeight: '23.8px'}}>{deliveryTime}-{deliveryTime+6} min</p>
                                </div>
                            )}
                        </div>
                    </div> */}
                    {/* <div onClick={() => setSwitchDeliveryOption(1)} className={`checkout_delivery_option ${switchDeliveryOption === 1 && 'active'}`}>
                        <div style={{padding: '4px 0', margin: '0 4px'}}>
                            <div style={{padding: '0 4px 4px', display: 'flex', justifyContent: 'space-between'}}>
                                <CalendarTodayOutlinedIcon />
                                
                                {switchDeliveryOption === 1 ? (
                                    <CheckCircleIcon />
                                ) : (
                                    <RadioButtonUncheckedOutlinedIcon />
                                )}
                            </div>
                            <h2 style={{fontSize: '18px', marginTop: '4px', marginLeft: '4px', lineHeight: '23.8px'}}>
                                Scheduled
                            </h2>
                            <div className='icon-container' style={{display: 'flex', padding: '5px 0 5px 10px', alignItems: 'center'}}>
                                <p style={{fontSize: '17px', fontWeight: '500', margin: '0', lineHeight: '23.8px'}}>Pick a time</p>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <p style={{margin: '6px 16px', lineHeight: '18.5px', fontSize: '14px'}}>
                    Do you want this order delivered now or later?
                </p> */}
                <p style={{margin: '6px 16px', lineHeight: '18.5px', fontSize: '14px'}}>
                    Your order will arrive as fast as possible!
                </p>
                <div onClick={() => toggleLocationModal()} className='checkout_form_toggle'>
                    <div style={{minWidth:'100%', minHeight:'48px', alignItems:'center', display:'flex', padding:'8px 0', boxSizing:'border-box'}}>
                        <div style={{marginRight:'12px', height:'40px', alignItems:'center', justifyContent:'center', display:'flex', width:'40px', padding:'6px', borderRadius:'40px'}}>
                            <RoomOutlinedIcon />
                        </div>
                        <div style={{flexGrow: 1}}>
                        {detailLocation ? (
                            <Fragment>
                                <h2 style={{fontWeight:'bold', lineHeight:'23.8px', fontSize:'18px'}}>
                                    {detailLocation.street_number}{' '}{detailLocation.street_name}
                                </h2>
                                {detailLocation.room_number && (
                                    <p style={{margin: '6px 0', margin: '0', fontSize: '14px'}}>
                                        Room: {detailLocation.room_number}
                                    </p>
                                )}
                                <p style={{margin: '6px 0', margin: '0', fontSize: '14px'}}>
                                    {detailLocation.city}{', '}{detailLocation.state} {detailLocation.postalCode}
                                </p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <h2 style={{fontWeight:'bold', color: 'rgb(29, 155, 240)', lineHeight:'23.8px', fontSize:'18px'}}>
                                    Add Address
                                </h2>
                            </Fragment>
                        )}
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{fontSize:'32px', marginLeft:'32px'}}>
                                <ChevronRightIcon />
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => {
                    if(isAuthenticated) {
                        window.location = '/settings/account/phone';
                    } else {
                        window.location = '/contact-phone?goBack=checkout';
                    }
                }} className='checkout_form_toggle'>
                    <div style={{minWidth:'100%', minHeight:'48px', alignItems:'center', display:'flex', padding:'8px 0', boxSizing:'border-box'}}>
                        <div style={{marginRight:'12px', height:'40px', alignItems:'center', justifyContent:'center', display:'flex', width:'40px', padding:'6px', borderRadius:'40px'}}>
                            <PhoneIcon />
                        </div>
                        <div style={{flexGrow: 1}}>
                            {user?.phone && user?.phone !== ''  ? (
                                <h2 style={{fontWeight:'bold', lineHeight:'23.8px', fontSize:'18px'}}>
                                    {user?.phone}
                                </h2>
                            ) : (
                                <h2 style={{fontWeight:'bold', color: 'rgb(29, 155, 240)', lineHeight:'23.8px', fontSize:'18px'}}>
                                    Add Phone Number
                                </h2>
                            )}
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{fontSize:'32px', marginLeft:'32px'}}>
                                <ChevronRightIcon />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout_form_toggle no_bottom_margin no_hover'>
                    <div style={{minWidth:'100%', minHeight:'48px', alignItems:'center', display:'flex', padding:'8px 0 0', boxSizing:'border-box'}}>
                        <div style={{marginRight:'12px', height:'40px', alignItems:'center', justifyContent:'center', display:'flex', width:'40px', padding:'6px', borderRadius:'40px'}}>
                            <CreditCardIcon />
                        </div>
                        <div style={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
                            <h2 style={{fontWeight:'bold', lineHeight:'23.8px', fontSize:'18px', marginRight: '6px'}}>
                                Card
                            </h2>
                            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                <img 
                                    src="https://aimg.kwcdn.com/upload_aimg/temu/da7f463a-916f-4d91-bcbb-047317a1c35e.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                    style={{width: '39px', height: '26px', marginRight: '6px'}}
                                />
                                <img 
                                    src="https://aimg.kwcdn.com/upload_aimg/temu/b79a2dc3-b089-4cf8-a907-015a25ca12f2.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                    style={{width: '39px', height: '26px', marginRight: '6px'}}
                                />
                                <img 
                                    src="https://aimg.kwcdn.com/upload_aimg/temu/936bf9dc-9bb2-4935-9c5a-a70b800d4cf1.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                    style={{width: '39px', height: '26px', marginRight: '6px'}}
                                />
                                <img 
                                    src="https://aimg.kwcdn.com/upload_aimg/temu/3f39097d-e751-4891-af08-41b63ebc876e.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                    style={{width: '39px', height: '26px', marginRight: '6px'}}
                                />
                                <img 
                                    src="https://aimg.kwcdn.com/upload_aimg/temu/fb599a1d-6d42-49f2-ba7a-64b16d01b226.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                    style={{width: '39px', height: '26px', marginRight: '6px'}}
                                />
                                <img 
                                    src="https://aimg.kwcdn.com/upload_aimg/temu/c6962c14-ad79-4856-89e4-32205f96a7de.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                    style={{width: '39px', height: '26px', marginRight: '6px'}}
                                />
                                <img 
                                    src="https://aimg.kwcdn.com/upload_aimg/temu/c3e5eb19-1b60-4c2b-87e1-4528fb390cbf.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                                    style={{width: '39px', height: '26px', marginRight: '6px'}}
                                />
                            </div>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{fontSize:'32px', marginLeft:'32px'}}>
                                <ExpandMoreIcon />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginLeft: '24px'}}>
                    <div style={{marginTop: '8px', padding: '0 16px'}}>
                        <div style={{marginBottom: '16px'}} className='cart_safety_msg no_margin green small_icon'> 
                            <LockIcon />
                            <span>
                                All transactions are secure and encrypted
                            </span>
                        </div>
                    </div>
                    <div style={{padding: '0 0 16px'}}>
                        <form>
                            <div className='payment_card_input_container' style={{marginBottom: '16px'}}>
                                {/* <CardElement options={cardElementOptions} /> */}
                                <div id="card-number">
                                </div>
                                <div style={{display: 'flex', gap: '10px', marginTop: '10px'}}>
                                    <div style={{flex: '0.6'}} id="card-expiry">
                                    </div>
                                    <div style={{flex: '0.4'}} id="card-cvc">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div style={{margin: '0 16px', minWidth: '40px', minHeight: '40px', paddingBottom: '6px 0', display: 'flex', alignItems: 'center'}}>
                    <p style={{fontSize: '14px', fontWeight: 'bold', textDecoration: 'underline'}}>
                        Add instructions for delivery partner:
                    </p>
                    <span style={{fontSize: '13px', marginLeft: '5px'}}>(70 chars)</span>
                </div>
                <div style={{width: '100%', boxSizing:'border-box', padding:'0px 10px 10px 10px', display:'flex'}}>
                    
                    {/* Phone Input */}
                    <textarea
                        style={{width:'100%', padding:'10px', minHeight:'100px', resize: 'none'}}
                        type="text"
                        placeholder="ex. &quot;Place on chair next to front door.&quot;"
                        className="input"
                        name="deliveryNotes"
                        maxLength="70"
                        onChange={e => onChange(e)}
                        value={deliveryNotes}
                    />
                </div>
                <div style={{margin: '0 16px 16px', flex: 1}}>
                    <p style={{margin: '16px 0 16px', fontSize: '14px', fontWeight: 'bold'}}>
                        Delivery Partner Tip
                    </p>
                    <div style={{background: 'rgba(236,238,233,1.00)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', borderRadius: '32px'}}>
                        <div onClick={() => handleDriverTipOption(0)} className={`checkout_driverTip_option ${driverTipOption === 0 && 'active'}`}>
                            <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold', overflow: 'hidden'}}>
                                $2.49
                            </div>
                        </div>
                        <div onClick={() => handleDriverTipOption(1)} className={`checkout_driverTip_option ${driverTipOption === 1 && 'active'}`}>
                            <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold', overflow: 'hidden'}}>
                                $3.49
                            </div>
                        </div>
                        <div onClick={() => handleDriverTipOption(2)} className={`checkout_driverTip_option ${driverTipOption === 2 && 'active'}`}>
                            <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold', overflow: 'hidden'}}>
                                $4.49
                            </div>
                        </div>
                        <div onClick={() => handleDriverTipOption(3)} className={`checkout_driverTip_option ${driverTipOption === 3 && 'active'}`}>
                            <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold', overflow: 'hidden'}}>
                                $0.00
                            </div>
                        </div>
                    </div>
                    <p style={{margin: '8px 0', color: 'rgb(91, 92, 90)', fontSize: '13px'}}>
                        * 100% of your tip goes to the delivery partner
                    </p>
                </div>

                <Checkout_Totals />

                {/* {isTablet && (
                    <Checkout_Totals />
                )} */}

                <div  style={{width: '100%', marginTop: '24px', minWidth: '300px'}}>
                    {!processingOrder ? (
                        <Button 
                            variant="outlined" 
                            className={`sidebar__tweet black`} 
                            fullWidth
                            onClick={handleSubmitOrder}
                        >
                            Place Order
                        </Button>
                    )
                    : (
                        <Button 
                            variant="outlined" 
                            className={`sidebar__tweet black`} 
                            fullWidth
                            disabled={true}
                        >
                            processing...
                        </Button>
                    )}
                    
                </div>

                <div style={{marginTop: '20px'}} className='cart_safety_msg green'>
                                
                    <VerifiedUserIcon />
                    <span style={{fontWeight: 'bold'}}>
                        Miitoken protects your card information
                    </span>
                </div>

                <div style={{alignItems: 'flex-start'}} className='cart_safety_msg'>
            
                    
                    <CheckIcon />
                    <span>
                        Miitoken follows the Payment Card Industry Data Security Standard (PCI DSS) when handling card data
                    </span>
                </div>

                <div style={{alignItems: 'flex-start'}} className='cart_safety_msg'>
            
                    
                    <CheckIcon />
                    <span>
                        Card information is secure and uncompromised
                    </span>
                </div>

                <div style={{marginTop: '8px'}}>
                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <img 
                            src="https://aimg.kwcdn.com/upload_aimg/temu/da7f463a-916f-4d91-bcbb-047317a1c35e.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                            style={{width: '39px', height: '26px', marginRight: '6px'}}
                        />
                        <img 
                            src="https://aimg.kwcdn.com/upload_aimg/temu/b79a2dc3-b089-4cf8-a907-015a25ca12f2.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                            style={{width: '39px', height: '26px', marginRight: '6px'}}
                        />
                        <img 
                            src="https://aimg.kwcdn.com/upload_aimg/temu/936bf9dc-9bb2-4935-9c5a-a70b800d4cf1.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                            style={{width: '39px', height: '26px', marginRight: '6px'}}
                        />
                        <img 
                            src="https://aimg.kwcdn.com/upload_aimg/temu/3f39097d-e751-4891-af08-41b63ebc876e.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                            style={{width: '39px', height: '26px', marginRight: '6px'}}
                        />
                        <img 
                            src="https://aimg.kwcdn.com/upload_aimg/temu/fb599a1d-6d42-49f2-ba7a-64b16d01b226.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                            style={{width: '39px', height: '26px', marginRight: '6px'}}
                        />
                        <img 
                            src="https://aimg.kwcdn.com/upload_aimg/temu/c6962c14-ad79-4856-89e4-32205f96a7de.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                            style={{width: '39px', height: '26px', marginRight: '6px'}}
                        />
                        <img 
                            src="https://aimg.kwcdn.com/upload_aimg/temu/c3e5eb19-1b60-4c2b-87e1-4528fb390cbf.png.slim.png?imageView2/2/w/800/q/70/format/webp" 
                            style={{width: '39px', height: '26px', marginRight: '6px'}}
                        />
                    </div>
                </div>

                <div style={{marginTop: '20px', alignItems: 'flex-start'}} className='cart_safety_msg'>
                                
                    <LockIcon />
                    <span style={{fontWeight: 'bold'}}>
                        <span style={{color: '#0a8800'}}>
                            Secure Privacy: 
                        </span>
                        <span style={{marginLeft: '3px', fontWeight: 500}}>
                            Protecting your privacy is important to us! Please be assured that your information will be kept secured and uncompromised.
                        </span>
                    </span>
                </div>
                <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = '/settings/privacy'} className='cart_safety_msg'>
                    Learn more 
                    <ChevronRightIcon />
                </div>
           

                <div style={{height: '100px'}}></div>
            </div>
            )}
        </CartLayout>
    )
}

CheckoutPage.propTypes = {
    locationState: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    zone: PropTypes.object.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    toggleLocationModal: PropTypes.func.isRequired,
    toggleModal_ProcessingOrder: PropTypes.func.isRequired,
    fetchGuestPhone: PropTypes.func.isRequired,
    updateDriverTip: PropTypes.func.isRequired,
    createOrder: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    locationState: state.location,
    cart: state.cart,
    auth: state.auth,
    nav: state.nav,
    discount: state.discount,
    zone: state.zone
})

export default connect(mapStateToProps, {
    setPage,
    add_To_Recent_History,
    toggleLocationModal,
    toggleModal_ProcessingOrder,
    fetchGuestPhone,
    updateDriverTip,
    createOrder,
    setAlert
})(CheckoutPage);