import axios from 'axios';

import { setAlert  } from './alertActions';

import { db, auth } from '../utils/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, orderBy, serverTimestamp, startAfter, limit } from 'firebase/firestore';

import { 
    GET_ORDERS, 
    GET_ORDER, 
    ORDERS_LOADING, 
    CLEAR_ORDER, 
    CLEAR_ORDERS,
    ORDER_ERROR,
    // Driver Action Types
    GET_DRIVER_DELIVERIES
} from './types';
import { addRefund } from './refundActions';

// Reference to the "orders" collection in Firestore
const ordersCollectionRef = collection(db, "orders");
const refundCollectionRef = collection(db, "refunds");
const emailCollectionRef = collection(db, "mail");

// Get Uer Orders by ID
export const getOrdersByUserID = (userID) => async dispatch => {

    dispatch(clearOrders());
    console.log('GETTING USER ORDERS');

    try {
        console.log('QUERYING USER ORDERS');

        // Create a query to retrieve orders where the user ID matches the provided ID, ordered by creation date in descending order
        const q = query(ordersCollectionRef, where("user._id", "==", userID), orderBy('createdAt', 'desc'));

        console.log('QUERY USER RESULTS');
        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each order
        const userOrders = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('USER ORDERS DATA');
        console.log(userOrders);

        // Dispatch an action of type GET_ORDERS with the user orders as the payload
        dispatch({
            type: GET_ORDERS,
            payload: userOrders
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type GET_POSTS with an empty array as the payload in case of an error
        dispatch({
            type: GET_ORDERS,
            payload: []
        })
    }
};

// Get Orders
export const getOrderById = (orderId) => async dispatch => {

    console.log('GETTING ORDER BY ID');
    dispatch(setOrdersLoading());

    try {

        // Create a reference to the specified order document in the "orders" collection
        const docRef = doc(db, 'orders', orderId)

        // Retrieve the document with the specified ID from the "orders" collection
        const orderDoc = await getDoc(docRef);

        console.log('GOT ORDER BY ID');
        console.log(orderDoc.data())
  
        if(orderDoc.data()) {
            // Dispatch an action of type GET_ORDER with the order data and ID as the payload
            dispatch({
                type: GET_ORDER,
                payload: {
                    ...orderDoc.data(),
                    _id: orderDoc.id
                }
            });
        } else {
            dispatch({
                type: GET_ORDER,
                payload: null
            });

            // dispatch(setAlert("Sorry, that order doesn't exist.", "okay"));
        }
    } catch (err) {

        console.log('ERROR!!')
        console.log(err);

        // Dispatch an action of type ORDER_ERROR with an error message and status code as the payload
        dispatch({
            type: ORDER_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        
        // Log the error to the console
        console.log('ERROR!!!')
        console.log(err);
    }
};

// Update the location_details, formatted address, and placeId of order (delivery address)
export const change_address_of_order = (orderID, location_object) => async dispatch => {
    
    console.log('CHANGING ADDRESS 4 ORDER');

    // Create a reference to the specified user document in the "users" collection
    const orderRef = doc(db, 'orders', orderID)

    try {
        console.log('UPDATING ORDER')
    
        // Update order address to this address object
        await updateDoc(orderRef, {
            placeId: location_object.placeId,
            formatted_address: location_object.formatted_address,
            location_details: location_object
        })

        console.log('SUCCESS UPDATING LOCATIONS')

        // Redirect back to order Detail page and trigger alert with 'updated' param = address_changed
        window.location = `/orders/${orderID}?updated=address_changed`;
    } catch (error) {
        console.log('ERROR!!!')
        console.log(error);
        dispatch(setAlert('Error changing address', 'danger'));
    }
};

// Get All Orders
export const getAllOrders = () => async dispatch => {

    dispatch(clearOrders());
    console.log('GETTING ALL ORDERS');

    try {
        console.log('QUERYING ALL ORDERS');

        // Create a query to retrieve all orders ordered by creation date in descending order
        const q = query(ordersCollectionRef, orderBy('createdAt', 'desc'));

        console.log('QUERY ORDERS RESULTS');
        console.log(q);

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each order
        const allOrders = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        console.log('ALL ORDERS DATA');
        console.log(allOrders);

        // Dispatch an action of type GET_ORDERS with all the orders as the payload
        dispatch({
            type: GET_ORDERS,
            payload: allOrders
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type GET_POSTS with an empty array as the payload in case of an error
        dispatch({
            type: GET_ORDERS,
            payload: []
        })
    }
};

// Clear all orders from state
export const clearOrders = () => dispatch => {
    dispatch(setOrdersLoading());

    dispatch({
        type: CLEAR_ORDERS
    });

}

// Orders loading
export const setOrdersLoading = () => {
    return {
        type: ORDERS_LOADING
    }
}

// ---------------------
// -- Driver Actions to update order

// Get orders user is assigned to as a driver
export const getDriverDeliveries = (userId) => async dispatch => {
    
    console.log('GETTING DRIVER DELIVERIES');
    try {
        console.log('QUERYING  ORDERS');

        let q;

        if(userId) {
            q = query(ordersCollectionRef, where("driver_id_list", "array-contains", userId));

            console.log('QUERYING  RESULT');
            console.log(q);

            // Execute the query and retrieve the query snapshot
            const querySnapshot = await getDocs(q);

            // Map through the document snapshots in the query snapshot and extract the data and ID for each order
            const orders_to_deliver = querySnapshot.docs.map((doc) => ({_id: doc.id}));

            console.log('ORDERS TO DELIVER DATA');
            console.log(orders_to_deliver);

            // Dispatch an action of type GET_DRIVER_DELIVERIES with the orders_to_deliver as the payload
            dispatch({
                type: GET_DRIVER_DELIVERIES,
                payload: orders_to_deliver
            });
        }


    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type GET_DRIVER_DELIVERIES with an empty array as the payload in case of an error
        dispatch({
            type: GET_DRIVER_DELIVERIES,
            payload: []
        })
    }
};

// Add Driver to order
export const addDriverToOrder = (campus_name, orderId, userData, orderData) => async dispatch => {
    
    // Get the current driver(s) from the order data
    let driverList = []; 
    
    if(orderData.driver) {
        driverList = [...orderData.driver];
    };
    
    try {
        console.log('ADDING DRIVER TO ORDER!!!!!')
        console.log(orderId);

        // Get Firebase Orders collection ref
        const orderRef = doc(db, 'orders', orderId)


        console.log('DRIVER DATA:');
        console.log(userData);

        console.log('DRIVER LIST DATA:');
        console.log(driverList);
        
        // Create the new driver object
        const newDriver = {
            user: {
                _id: userData._id,
                username: userData.username,
                first_name: userData.first_name,
                last_name: userData.last_name,
                img: userData.img,
                phone: userData.phone,
            }
        };

        // Check if driver has already been assigned to the order
        if(!(driverList.filter(driverObj => driverObj.user._id.toString() === userData._id).length > 0)) {

            // Add the new driver to the driverList
            driverList.push(newDriver);

            console.log('NEW Driver DATA:');
            console.log(driverList);

            // Create array for the list of driver ID's
            const id_array = [];

            driverList.map((driverObj) => {
                id_array.push(driverObj.user._id);
            })

            // Update the order document with the updated driverObj data and driver_id_list
            await updateDoc(orderRef, {
                driver: driverList,
                driver_id_list: id_array,
                status: "collecting"
            })

            console.log('SUCCESSFULLY ADDED DRIVER TO ORDER');
            

            // Dispatch an alert to indicate that the driver has been added
            // to the order
            dispatch(setAlert('Starting Delivery...', 'okay'));

            // --- Send Email ----

            console.log('SENDING EMAIL FOR DRIVER ADDED TO ORDER!')

            const emailFields = {};
            
            emailFields.date = Date.now();
            emailFields.createdAt = serverTimestamp();

            // Retrieve the to_user document using the userRef
            const to_user = orderData.user;

            // Render Cart List
            const order_items = orderData.cart.items;
            
            emailFields.to = to_user.email;

            emailFields.message = {
                subject: `Delivery Offer Accepted!`,
                html: `
                    <div>
                        <h1 style="color:#333;font-size:20px">
                            A student is collecting your items.
                        </h1> 
                        <div>
                            <div style="color:#333333;margin-top:12px">
                                Your order is now in motion! A delivery partner has accepted your delivery offer and is on their way to collect your items.
                            </div>

                            <div style="color:#333333;margin-top:12px">
                                When they arrive, please give them <b>YOUR CODE:</b> #${orderId.substring(0, 4).toLowerCase()} 
                            </div>

                            <div style="color:#333333;margin-top:12px">
                                Your convenience is our priority, and our delivery partner is committed to ensuring a swift and reliable delivery experience. If you have any specific delivery instructions or questions, please don't hesitate to call or text the delivery partner.
                            </div>

                            <div style="margin-top:12px">
                                <a href="https://www.miitoken.com/orders/${orderId}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">
                                    View Order Details
                                </a>
                            </div>
                            
                            <div style="margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0">
                                ${order_items.map(order_item => (
                                    `<div style="display:flex;width:100%;">
                                        <div style="display:flex;justify-content:flex-start;align-items:center;height:100px;width:60%;">
                                            <div style="display:flex;align-items:center;justify-content:center;">
                                                <div style="font-size:1rem;color:#cecece;margin:10px;padding:2px;width:40px;height:40px;display:flex;justify-content:center;align-items:center;">
                                                    <img style="height:100%" src="${order_item.imageURL}" alt="img" />
                                                </div>
                                                <div style="width:100%;padding-left:10px;">
                                                    <p style="height:18px;overflow:hidden;font-weight:500;font-size:13px;display:-webkit-box;-webkit-line-clamp: 1;-webkit-box-orient:vertical;">${order_item.item.name}</p>
                                                    <p style="font-size:12px;color:rgb(83, 100, 113);">
                                                        <span><b>Qty:</b> ${order_item.qty}</span>
                                                        <span><b>Total:</b> ${'$'+order_item.price}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>`
                                ))}
                            </div>
                            <div style="padding:16px 0; width:100%">
                                <h3>Order Summary</h3>
                                <div style="width:100%;display:flex;justify-content:space-between;align-items:center;margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0;">
                                    <div>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Subtotal:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Driver Tip:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Estimated Tax:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Completed Total:</p>
                                    </div>
                                    <div>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderData.cart.subtotal}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderData.cart.tip}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderData.cart.tax}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderData.cart.total}</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div style="color:#aaaaaa;margin-top:12px">
                            Need Help With Something?
                            <div style="margin-top:12px">
                                Please visit <a href="https://www.miitoken.com/settings/help" target="_blank">our help page</a> for details on how to contact us.
                            </div>
                        </div> 
                        <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888">
                            <div>
                                <a href="https://www.miitoken.com" style="color:inherit" target="_blank">
                                    <span class="il">
                                        MiiToken
                                    </span>.com
                                </a>
                                , the simplest way to buy groceries.
                            </div>
                        </div>
                    </div>`
            };


            console.log('EMAIL FIELDS')
            console.log(emailFields);

            // Add the new email document to the mail collection
            const email_response = await addDoc(emailCollectionRef, emailFields);
            
            console.log('SENT EMAIL FOR REFUND CONFIRMATION', email_response)

            // --- END Send Email ---

            window.location = `/delivery/${orderId}`;
        }

      
    } catch (err) {
        console.log('ERROR ADDING DRIVER TO ORDER')
        console.log(err);

        // Dispatch an alert to indicate that the driver has been added
        // to the order
        dispatch(setAlert('Something went wrong...', 'okay'));
    }
};

// Add Driver to order
export const remove_Driver_From_Order = (campus_name, orderId, userData, orderData) => async dispatch => {
    
    // Get the current driver(s) from the order data
    let driverList = []; 
    
    if(orderData.driver) {
        driverList = [...orderData.driver];
    };
    
    try {
        console.log('REMOVING DRIVER FROM ORDER!!!!!')
        console.log(orderId);

        // Get Firebase Orders collection ref
        const orderRef = doc(db, 'orders', orderId)


        console.log('DRIVER DATA:');
        console.log(userData);

        console.log('DRIVER LIST DATA:');
        console.log(driverList);

        // Check if driver has already been assigned to the order
        if(driverList.filter(driverObj => driverObj.user._id.toString() === userData._id).length > 0) {
            // Get the index of the driverObj to remove
            const removeIndex = driverList.map(driverObj => driverObj.user._id.toString()).indexOf(userData._id);

            // Remove the driverObj from the order
            driverList.splice(removeIndex, 1);

            console.log('Removed Driiver From Order:');
            console.log(driverList);

            // Create array for the list of driver ID's
            const id_array = [];

            driverList.map((driverObj) => {
                id_array.push(driverObj.user._id);
            })

            // Update the order document with the updated driverObj data and driver_id_list
            await updateDoc(orderRef, {
                driver: driverList,
                driver_id_list: id_array,
                items_picked: [],
                items_picked_id_list: [],
                status: "waiting",
            })

            console.log('SUCCESSFULLY REMOVED DRIVER FROM ORDER');

            // Dispatch an alert to indicate that the driver has been REMOVED FROM THE ORDER
            dispatch(setAlert('Offer Unassigned!', 'okay'));

            // --- Send Email ----

            console.log('SENDING EMAIL FOR DRIVER REMOVED FROM ORDER!')

            const emailFields = {};
            
            emailFields.date = Date.now();
            emailFields.createdAt = serverTimestamp();

            // Retrieve the to_user document using the userRef
            const to_user = orderData.user;

            // Render Cart List
            const order_items = orderData.cart.items;
            
            emailFields.to = to_user.email;

            emailFields.message = {
                subject: `Update on Your Order!`,
                html: `
                    <div>
                        <h1 style="color:#333;font-size:20px">
                            Delivery Partner Unassigned.
                        </h1> 
                        <div>
                            <div style="color:#333333;margin-top:12px">
                                Unfortunately, the delivery partner initially assigned to your delivery offer has unassigned themselves from the task.
                            </div>

                            <div style="color:#333333;margin-top:12px">
                                There is still time before your order expires, and we are actively working to find another delivery partner to complete your delivery.
                            </div>

                            <div style="color:#333333;margin-top:12px">
                                We appreciate your patience and understanding during this process. Your order is important to us, and we are committed to ensuring a successful delivery.
                            </div>

                            <div style="color:#333333;margin-top:12px">
                                If you have any specific concerns or questions about your order, please feel free to <a href="https://www.miitoken.com/settings/help" target="_blank">contact us</a>, and we will be more than happy to assist you.
                            </div>

                            <div style="margin-top:12px">
                                <a href="https://www.miitoken.com/orders/${orderId}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">
                                    View Order Details
                                </a>
                            </div>
                            
                            <div style="margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0">
                                ${order_items.map(order_item => (
                                    `<div style="display:flex;width:100%;">
                                        <div style="display:flex;justify-content:flex-start;align-items:center;height:100px;width:60%;">
                                            <div style="display:flex;align-items:center;justify-content:center;">
                                                <div style="font-size:1rem;color:#cecece;margin:10px;padding:2px;width:40px;height:40px;display:flex;justify-content:center;align-items:center;">
                                                    <img style="height:100%" src="${order_item.imageURL}" alt="img" />
                                                </div>
                                                <div style="width:100%;padding-left:10px;">
                                                    <p style="height:18px;overflow:hidden;font-weight:500;font-size:13px;display:-webkit-box;-webkit-line-clamp: 1;-webkit-box-orient:vertical;">${order_item.item.name}</p>
                                                    <p style="font-size:12px;color:rgb(83, 100, 113);">
                                                        <span><b>Qty:</b> ${order_item.qty}</span>
                                                        <span><b>Total:</b> ${'$'+order_item.price}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>`
                                ))}
                            </div>
                            <div style="padding:16px 0; width:100%">
                                <h3>Order Summary</h3>
                                <div style="width:100%;display:flex;justify-content:space-between;align-items:center;margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0;">
                                    <div>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Subtotal:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Driver Tip:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Estimated Tax:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Completed Total:</p>
                                    </div>
                                    <div>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderData.cart.subtotal}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderData.cart.tip}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderData.cart.tax}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderData.cart.total}</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div style="color:#aaaaaa;margin-top:12px">
                            Need Help With Something?
                            <div style="margin-top:12px">
                                Please visit <a href="https://www.miitoken.com/settings/help" target="_blank">our help page</a> for details on how to contact us.
                            </div>
                        </div> 
                        <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888">
                            <div>
                                <a href="https://www.miitoken.com" style="color:inherit" target="_blank">
                                    <span class="il">
                                        MiiToken
                                    </span>.com
                                </a>
                                , the simplest way to buy groceries.
                            </div>
                        </div>
                    </div>`
            };


            console.log('EMAIL FIELDS')
            console.log(emailFields);

            // Add the new email document to the mail collection
            const email_response = await addDoc(emailCollectionRef, emailFields);
            
            console.log('SENT EMAIL FOR REFUND CONFIRMATION', email_response)

            // --- END Send Email ---

            window.location = '/offers';
        }

      
    } catch (err) {
      console.log(err)
    }
};

// Add Item to Picked List
export const markItemPicked = (orderId, orderData, itemId) => async dispatch => {
    
    console.log('MARKING ITEM PICKED!!!!!');

    // Get the current list of items picked from the order data
    let items_Picked_List = []; 
    
    if(orderData.items_picked) {
        items_Picked_List = [...orderData.items_picked];
    };
    
    try {
        console.log('ADDING ITEM!!!!!')
        console.log(itemId);

        // Get Firebase Post & Likes of post collection ref
        const orderRef = doc(db, 'orders', orderId)

        console.log('Like List DATA:');
        console.log(items_Picked_List);
    

        // Check if post already liked by same user
        if(items_Picked_List.filter(item_picked => item_picked._id.toString() === itemId).length > 0) {
            // Get the index of the like to remove
            const removeIndex = items_Picked_List.map(item_picked => item_picked._id.toString()).indexOf(itemId);

            // Remove the like from the post
            items_Picked_List.splice(removeIndex, 1);

            console.log('Removed Like Data:');
            console.log(items_Picked_List);

            // Create array for the list of like ID's
            const id_array = [];

            items_Picked_List.map((item_picked) => {
                id_array.push(item_picked._id);
            })

            console.log('Removed Like ID LIST Data:');
            console.log(id_array);

            // Update the post document with the updated likes and like_id_list
            await updateDoc(orderRef, {
                items_picked: items_Picked_List,
                items_picked_id_list: id_array
            })
        } else {
            // const likeData = await addDoc(colRef, newLike);

            // Add the new like to the likeList
            items_Picked_List.push({
                _id: itemId,
                timestamp: Date.now()
            });

            console.log('NEW Like DATA:');
            console.log(items_Picked_List);

            // Create array for the list of like ID's
            const id_array = [];

            items_Picked_List.map((item_picked) => {
                id_array.push(item_picked._id);
            })

            console.log('Removed Like ID LIST Data:');
            console.log(id_array);

            // Update the post document with the updated likes and like_id_list
            await updateDoc(orderRef, {
                items_picked: items_Picked_List,
                items_picked_id_list: id_array,
            })

            console.log('ITEM SUCCESSFULLY PICKED');
            

            // Dispatch an alert to indicate that the post was saved
            dispatch(setAlert('Item confirmed.', 'okay'));
        }
  
    //   dispatch({
    //     type: UPDATE_POST_LIKES,
    //     payload: { id, likes: res.data }
    //   });

      
    } catch (err) {
      console.log(err)
    }
};

// Update order status
export const updateOrderStatus = (orderObj, status) => async dispatch => {
    
    console.log('UPDATING ORDER STATUS!!!!!');

    // Reference to the user document in the Firestore database
    const orderDoc = doc(db, "orders", orderObj._id);

    try {
        console.log('UPDATING ORDER!!!!!')
        console.log(orderObj._id);

        // Update the order's info
        if(status === "delivered") {

            // Remove the Driver's ID from the order's driver_id_list (but not Driver, b/c the id_list is used to redirect user if delivery is not complete)
            let driverList = []; 
    
            if(orderObj.driver_id_list) {
                driverList = [...orderObj.driver_id_list];
            };

            // Check if driver has been assigned to the order
            if(driverList.filter(driverID => driverID.toString() === auth.currentUser.uid).length > 0) {
                // Get the index of the driverID to remove
                const removeIndex = driverList.map(driverID => driverID.toString()).indexOf(auth.currentUser.uid);

                // Remove the driverID from the order driver_id_list
                driverList.splice(removeIndex, 1);

                console.log('Removed Driver ID From Order:');
                console.log(driverList);

            }

            await updateDoc(orderDoc, {
                status,
                lastModified: serverTimestamp(),
                final_complete_time: serverTimestamp(),
                driver_id_list: driverList
            })
            
        } else {

            // Update Order Status, This is the NOT DELIVERED SECTION
            await updateDoc(orderDoc, {
                status,
                lastModified: serverTimestamp(),
            })
        }

        // Get updated Order data for display
        const updatedOrderDoc = await getDoc(orderDoc);

        console.log('GOT UPDATED ORDER BY ID');
        console.log(updatedOrderDoc.data())
  
        // Dispatch an action to update the current order's data with the new updated data
        dispatch({
            type: GET_ORDER,
            payload: {
                ...updatedOrderDoc.data(),
                _id: updatedOrderDoc.id
            }
        });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Delivery Status Updated.', 'success'));

        // --- Send Email ----

        console.log('SENDING EMAIL FOR STATUS UPDATE TO ORDER!')

        const emailFields = {};
        
        emailFields.date = Date.now();
        emailFields.createdAt = serverTimestamp();

        // Retrieve the to_user document using the userRef
        const to_user = orderObj.user;

        // Render Cart List
        const order_items = orderObj.cart.items;
        
        emailFields.to = to_user.email;

        if(status === "in transit") {

            emailFields.message = {
                subject: `Order On The Way!`,
                html: `
                    <div>
                        <h1 style="color:#333;font-size:20px">
                            Your delivery will be there shortly.
                        </h1> 
                        <div>
                            <div style="color:#333333;margin-top:12px">
                                To ensure a smooth handover, please don't forget to give the delivery partner your code!
                            </div>

                            <div style="color:#333333;margin-top:12px">
                                <b>YOUR CODE:</b> #${orderObj._id.substring(0, 4).toLowerCase()}
                            </div>


                            <div style="color:#333333;margin-top:12px">
                                If you have any specific concerns or questions about your order, please feel free to <a href="https://www.miitoken.com/settings/help" target="_blank">contact us</a>, and we will be more than happy to assist you.
                            </div>

                            <div style="margin-top:12px">
                                <a href="https://www.miitoken.com/orders/${orderObj._id}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">
                                    View Order Details
                                </a>
                            </div>
                            
                            <div style="margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0">
                                ${order_items.map(order_item => (
                                    `<div style="display:flex;width:100%;">
                                        <div style="display:flex;justify-content:flex-start;align-items:center;height:100px;width:60%;">
                                            <div style="display:flex;align-items:center;justify-content:center;">
                                                <div style="font-size:1rem;color:#cecece;margin:10px;padding:2px;width:40px;height:40px;display:flex;justify-content:center;align-items:center;">
                                                    <img style="height:100%" src="${order_item.imageURL}" alt="img" />
                                                </div>
                                                <div style="width:100%;padding-left:10px;">
                                                    <p style="height:18px;overflow:hidden;font-weight:500;font-size:13px;display:-webkit-box;-webkit-line-clamp: 1;-webkit-box-orient:vertical;">${order_item.item.name}</p>
                                                    <p style="font-size:12px;color:rgb(83, 100, 113);">
                                                        <span><b>Qty:</b> ${order_item.qty}</span>
                                                        <span><b>Total:</b> ${'$'+order_item.price}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>`
                                ))}
                            </div>
                            <div style="padding:16px 0; width:100%">
                                <h3>Order Summary</h3>
                                <div style="width:100%;display:flex;justify-content:space-between;align-items:center;margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0;">
                                    <div>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Subtotal:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Driver Tip:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Estimated Tax:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Completed Total:</p>
                                    </div>
                                    <div>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.subtotal}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.tip}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.tax}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.total}</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div style="color:#aaaaaa;margin-top:12px">
                            Need Help With Something?
                            <div style="margin-top:12px">
                                Please visit <a href="https://www.miitoken.com/settings/help" target="_blank">our help page</a> for details on how to contact us.
                            </div>
                        </div> 
                        <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888">
                            <div>
                                <a href="https://www.miitoken.com" style="color:inherit" target="_blank">
                                    <span class="il">
                                        MiiToken
                                    </span>.com
                                </a>
                                , the simplest way to buy groceries.
                            </div>
                        </div>
                    </div>`
            };
            
        } else if(status === "delivered") {
            
            emailFields.message = {
                subject: `Order Complete!`,
                html: `
                    <div>
                        <h1 style="color:#333;font-size:20px">
                            Your order was delivered.
                        </h1> 
                        <div>
                            <div style="color:#333333;margin-top:12px">
                                All items have reached their final destination(s) and are now in the correct hands.
                            </div>

                            <div style="color:#333333;margin-top:12px">
                                We hope you enjoyed the service! Your satisfaction is very importance to us, and we appreciate your trust in our delivery partners.
                            </div>


                            <div style="color:#333333;margin-top:12px">
                                If you have any feedback, comments, or questions regarding your order or delivery experience, please feel free to <a href="https://www.miitoken.com/settings/help" target="_blank">contact us</a>.
                            </div>

                            <div style="margin-top:12px">
                                <a href="https://www.miitoken.com/orders/${orderObj._id}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">
                                    View Order Details
                                </a>
                            </div>
                            
                            <div style="margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0">
                                ${order_items.map(order_item => (
                                    `<div style="display:flex;width:100%;">
                                        <div style="display:flex;justify-content:flex-start;align-items:center;height:100px;width:60%;">
                                            <div style="display:flex;align-items:center;justify-content:center;">
                                                <div style="font-size:1rem;color:#cecece;margin:10px;padding:2px;width:40px;height:40px;display:flex;justify-content:center;align-items:center;">
                                                    <img style="height:100%" src="${order_item.imageURL}" alt="img" />
                                                </div>
                                                <div style="width:100%;padding-left:10px;">
                                                    <p style="height:18px;overflow:hidden;font-weight:500;font-size:13px;display:-webkit-box;-webkit-line-clamp: 1;-webkit-box-orient:vertical;">${order_item.item.name}</p>
                                                    <p style="font-size:12px;color:rgb(83, 100, 113);">
                                                        <span><b>Qty:</b> ${order_item.qty}</span>
                                                        <span><b>Total:</b> ${'$'+order_item.price}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>`
                                ))}
                            </div>
                            <div style="padding:16px 0; width:100%">
                                <h3>Order Summary</h3>
                                <div style="width:100%;display:flex;justify-content:space-between;align-items:center;margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0;">
                                    <div>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Subtotal:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Driver Tip:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Estimated Tax:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Completed Total:</p>
                                    </div>
                                    <div>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.subtotal}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.tip}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.tax}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.total}</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div style="color:#aaaaaa;margin-top:12px">
                            Need Help With Something?
                            <div style="margin-top:12px">
                                Please visit <a href="https://www.miitoken.com/settings/help" target="_blank">our help page</a> for details on how to contact us.
                            </div>
                        </div> 
                        <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888">
                            <div>
                                <a href="https://www.miitoken.com" style="color:inherit" target="_blank">
                                    <span class="il">
                                        MiiToken
                                    </span>.com
                                </a>
                                , the simplest way to buy groceries.
                            </div>
                        </div>
                    </div>`
            };
        }

        console.log('EMAIL FIELDS')
        console.log(emailFields);

        // Add the new email document to the mail collection
        const email_response = await addDoc(emailCollectionRef, emailFields);
        
        console.log('SENT EMAIL FOR REFUND CONFIRMATION', email_response)

        // --- END Send Email ---

        if(status === "in transit") {           
            window.location = `/delivery/${orderObj._id}?status=transit`
        } else if(status === "arrived") {
            window.location = `/delivery/${orderObj._id}?status=${status}`
        } else if(status === "delivered") {
            window.location = `/delivery/${orderObj._id}?status=${status}`
        }

        console.log('ORDER DELVIRED HERES ORDER OBJ: ', orderObj)
      
    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        if(err.response) {
            const errors = err.response.data.errors;

            // Dispatch alerts for each error received from the server
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Display a generic error message if no specific error response is received
            setAlert('Something went wrong', 'danger')
        }
    }
};

// Initiate Stripe Transfer to Driver
export const transferPayout = (orderObj, stripe_account_id) => async dispatch => {

    console.log('STARTING PAYOUT TRANSFER!!!!!');

    // Reference to the user document in the Firestore database
    const orderDoc = doc(db, "orders", orderObj._id);

    try {
        console.log('INITIATING!!!!!')
        console.log(orderObj._id);

        const { data: paymentTransfer_obj } = await axios.post("https://intense-beyond-56709-63aadc62ac0b.herokuapp.com/transfer_to_driver", {
            driver_tip: orderObj.driver_tip * 100,
            stripe_account_id,
            paymentId: orderObj.paymentId,
            transfer_group: orderObj.transfer_group
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('TRANSFER SENT!!');
        console.log(paymentTransfer_obj);

        let transfers_array = [];

        if(orderObj.stripe_transfers?.length > 0) {
            transfers_array = [paymentTransfer_obj, ...orderObj.stripe_transfers]
        } else {
            transfers_array.push(paymentTransfer_obj)
        }


        // Update Order Status, This is the NOT DELIVERED SECTION
        await updateDoc(orderDoc, {
            stripe_transfers: transfers_array,
            driver_paid: true
        })

        // Get updated Order data for display
        const updatedOrderDoc = await getDoc(orderDoc);

        console.log('GOT UPDATED ORDER BY ID');
        console.log(updatedOrderDoc.data())
  
        // Dispatch an action to update the current order's data with the new updated data
        dispatch({
            type: GET_ORDER,
            payload: updatedOrderDoc.data()
        });

        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('Payout Initiated!', 'success'));
      
    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        dispatch(setAlert('Payout Error!', 'danger'));
    }
};

// Initiate Stripe Transfer to Driver
export const cancel_And_Refund_Order = (campus_name, orderObj, driver_obj_to_remove) => async dispatch => {

    console.log('STARTING REFUND AND CANCEL ORDER !!!!!');

    // Reference to the order document in the Firestore database
    const orderDoc = doc(db, "orders", orderObj._id);

    try {
        console.log('REFUNDING!!!!!')
        console.log(orderObj._id);

        const { data: refund_obj } = await axios.post("https://intense-beyond-56709-63aadc62ac0b.herokuapp.com/refund_order", {
            payment_intent: orderObj.paymentId,
            reason: 'requested_by_customer',
            refund_application_fee: true
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('REFUND SUCCEEDED!!');
        console.log(refund_obj);

        // ADD THE NEW REFUND OBJECT to DB indicating that the order has successfully been cancelled
        await addDoc(refundCollectionRef, {
            order_id: orderObj._id,
            refund_id: refund_obj.id,
            user_id: auth.currentUser.uid,
            ...refund_obj,
            date: Date.now(),
            createdAt: serverTimestamp()
        });

        // Update Order Status
        if(driver_obj_to_remove) {

            // Get the current driver from the order object
            let driverList = []; 

            if(orderObj.driver) {
                driverList = [...orderObj.driver];
            };

            console.log('REMOVING DRIVER FROM ORDER!!!!!')

            console.log('DRIVER DATA TO REMOVE:');
            console.log(driver_obj_to_remove);

            console.log('ORDER DRIVER LIST DATA:');
            console.log(driverList);
            
            // Check if driver has been assigned to this order, so it can be removed
            if(driverList.filter(driverObj => driverObj.user._id.toString() === driver_obj_to_remove._id).length > 0) {
                // Get the index of the driverObj to remove
                const removeIndex = driverList.map(driverObj => driverObj.user._id.toString()).indexOf(driver_obj_to_remove._id);

                // Remove the driverObj from the order
                driverList.splice(removeIndex, 1);

                console.log('Success, Removed Driiver From Order:');
                console.log(driverList);

                // Create array for the new list of driver ID's
                const id_array = [];

                driverList.map((driverObj) => {
                    id_array.push(driverObj.user._id);
                })

                // Update the order document with the updated driverObj data and driver_id_list, AND refund information

                await updateDoc(orderDoc, {
                    driver: driverList,
                    driver_id_list: id_array,
                    items_picked: [],
                    items_picked_id_list: [],
                    status: "driver cancelled",
                    order_refunded: true,
                    refund_id: refund_obj.id
                })

                console.log('SUCCESSFULLY REMOVED DRIVER FROM ORDER');

                // Dispatch an alert to indicate that the driver has been REMOVED FROM THE ORDER
                dispatch(setAlert('Offer Unassigned!', 'okay'));

                 // --- Send Email ----

                console.log('SENDING EMAIL FOR REFUND CONFIRMATION')

                const emailFields = {};
                
                emailFields.date = Date.now();
                emailFields.createdAt = serverTimestamp();

                // Retrieve the to_user document using the userRef
                const to_user = orderObj.user;

                // Render Cart List
                const order_items = orderObj.cart.items;
                
                emailFields.to = to_user.email;

                emailFields.message = {
                    subject: `Your Order Was Cancelled!`,
                    html: `
                        <div>
                            <h1 style="color:#333;font-size:20px">
                                We sent you a refund.
                            </h1> 
                            <div>
                                <div style="color:#333333;margin-top:12px">
                                    Unfortunately your delivery partner flaked on your recent Grocery order. We sincerely apologize for any inconvenience this may have caused.
                                </div>

                                <div style="color:#333333;margin-top:12px">
                                    Don't worry, we processed a full refund for your order. Please allow around 5-10 business days for the refund to reflect in your account. For any refund-related questions, please go to <a href="https://www.miitoken.com/settings/help" target="_blank">our help page</a>.
                                </div>

                                <div style="color:#333333;margin-top:12px">
                                    We encourage you to try again and reorder your items. Give another student a chance to deliver your order!
                                </div>

                                <div style="margin-top:12px">
                                    <a href="https://www.miitoken.com/" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">
                                        Try Again | Reorder Items
                                    </a>
                                </div>
                                
                                <div style="margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0">
                                    ${order_items.map(order_item => (
                                        `<div style="display:flex;width:100%;">
                                            <div style="display:flex;justify-content:flex-start;align-items:center;height:100px;width:60%;">
                                                <div style="display:flex;align-items:center;justify-content:center;">
                                                    <div style="font-size:1rem;color:#cecece;margin:10px;padding:2px;width:40px;height:40px;display:flex;justify-content:center;align-items:center;">
                                                        <img style="height:100%" src="${order_item.imageURL}" alt="img" />
                                                    </div>
                                                    <div style="width:100%;padding-left:10px;">
                                                        <p style="height:18px;overflow:hidden;font-weight:500;font-size:13px;display:-webkit-box;-webkit-line-clamp: 1;-webkit-box-orient:vertical;">${order_item.item.name}</p>
                                                        <p style="font-size:12px;color:rgb(83, 100, 113);">
                                                            <span><b>Qty:</b> ${order_item.qty}</span>
                                                            <span><b>Total:</b> ${'$'+order_item.price}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>`
                                    ))}
                                </div>
                                <div style="padding:16px 0; width:100%">
                                    <h3>Amount Refunded</h3>
                                    <div style="width:100%;display:flex;justify-content:space-between;align-items:center;margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0;">
                                        <div>
                                            <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Subtotal:</p>
                                            <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Driver Tip:</p>
                                            <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Estimated Tax:</p>
                                            <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Refund Total:</p>
                                        </div>
                                        <div>
                                            <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.subtotal}</p>
                                            <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.tip}</p>
                                            <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.tax}</p>
                                            <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.total}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div style="color:#aaaaaa;margin-top:12px">
                                Need Help With Something?
                                <div style="margin-top:12px">
                                    Please visit <a href="https://www.miitoken.com/settings/help" target="_blank">our help page</a> for details on how to contact us.
                                </div>
                            </div> 
                            <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888">
                                <div>
                                    <a href="https://www.miitoken.com" style="color:inherit" target="_blank">
                                        <span class="il">
                                            MiiToken
                                        </span>.com
                                    </a>
                                    , the simplest way to buy groceries.
                                </div>
                            </div>
                        </div>`
                };


                console.log('EMAIL FIELDS')
                console.log(emailFields);

                // Add the new email document to the mail collection
                const email_response = await addDoc(emailCollectionRef, emailFields);
                
                console.log('SENT EMAIL FOR REFUND CONFIRMATION', email_response)

                // --- END Send Email ---

                window.location = '/offers';
            }
        } else {
    
            await updateDoc(orderDoc, {
                status: "customer cancelled",
                order_refunded: true,
                refund_id: refund_obj.id
            })

            // Get updated Order data for display
            const updatedOrderDoc = await getDoc(orderDoc);

            console.log('GOT UPDATED ORDER BY ID');
            console.log(updatedOrderDoc.data())
    
            // Dispatch an action to update the current order's data with the new updated data
            dispatch({
                type: GET_ORDER,
                payload: {
                    _id: updatedOrderDoc.id,
                    ...updatedOrderDoc.data()
                }
            });

            // Dispatch a success alert indicating that the profile has been updated
            dispatch(setAlert('Order Cancelled!', 'success'));

             // --- Send Email ----

            console.log('SENDING EMAIL FOR REFUND CONFIRMATION')

            const emailFields = {};
            
            emailFields.date = Date.now();
            emailFields.createdAt = serverTimestamp();

            // Retrieve the to_user document using the userRef
            const to_user = orderObj.user;

            // Render Cart List
            const order_items = orderObj.cart.items;
            
            emailFields.to = to_user.email;

            emailFields.message = {
                subject: `Your Order Was Cancelled!`,
                html: `
                    <div>
                        <h1 style="color:#333;font-size:20px">
                            We sent you a refund.
                        </h1> 
                        <div>
                            <div style="color:#333333;margin-top:12px">
                                We're sorry it didn't work out this time.
                            </div>

                            <div style="color:#333333;margin-top:12px">
                                We processed a full refund for your order. Please allow around 5-10 business days for the refund to reflect in your account. For any refund-related questions, please go to <a href="https://www.miitoken.com/settings/help" target="_blank">our help page</a>.
                            </div>

                            <div style="color:#333333;margin-top:12px">
                                We encourage you to order again whenever next you are hungry or need to re-stock your shelves. Give a student a chance to deliver your next order!
                            </div>

                            <div style="margin-top:12px">
                                <a href="https://www.miitoken.com/" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">
                                    Browse Grocery Items
                                </a>
                            </div>
                            
                            <div style="margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0">
                                ${order_items.map(order_item => (
                                    `<div style="display:flex;width:100%;">
                                        <div style="display:flex;justify-content:flex-start;align-items:center;height:100px;width:60%;">
                                            <div style="display:flex;align-items:center;justify-content:center;">
                                                <div style="font-size:1rem;color:#cecece;margin:10px;padding:2px;width:40px;height:40px;display:flex;justify-content:center;align-items:center;">
                                                    <img style="height:100%" src="${order_item.imageURL}" alt="img" />
                                                </div>
                                                <div style="width:100%;padding-left:10px;">
                                                    <p style="height:18px;overflow:hidden;font-weight:500;font-size:13px;display:-webkit-box;-webkit-line-clamp: 1;-webkit-box-orient:vertical;">${order_item.item.name}</p>
                                                    <p style="font-size:12px;color:rgb(83, 100, 113);">
                                                        <span><b>Qty:</b> ${order_item.qty}</span>
                                                        <span><b>Total:</b> ${'$'+order_item.price}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>`
                                ))}
                            </div>
                            <div style="padding:16px 0; width:100%">
                                <h3>Amount Refunded</h3>
                                <div style="width:100%;display:flex;justify-content:space-between;align-items:center;margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0;">
                                    <div>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Subtotal:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Driver Tip:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Estimated Tax:</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Refund Total:</p>
                                    </div>
                                    <div>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.subtotal}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.tip}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.tax}</p>
                                        <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+orderObj.cart.total}</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div style="color:#aaaaaa;margin-top:12px">
                            Need Help With Something?
                            <div style="margin-top:12px">
                                Please visit <a href="https://www.miitoken.com/settings/help" target="_blank">our help page</a> for details on how to contact us.
                            </div>
                        </div> 
                        <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888">
                            <div>
                                <a href="https://www.miitoken.com" style="color:inherit" target="_blank">
                                    <span class="il">
                                        MiiToken
                                    </span>.com
                                </a>
                                , the simplest way to buy groceries.
                            </div>
                        </div>
                    </div>`
            };


            console.log('EMAIL FIELDS')
            console.log(emailFields);

            // Add the new email document to the mail collection
            const email_response = await addDoc(emailCollectionRef, emailFields);
            
            console.log('SENT EMAIL FOR REFUND CONFIRMATION', email_response)

            // --- END Send Email ---

            if(updatedOrderDoc) {
                window.location = '/settings/refunds';
            }
        }


      
    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        dispatch(setAlert('Error Cancelling Order!', 'danger'));
    }
};

export const test_add_doc = (orderObj) => async dispatch => {

    console.log('STARTING TEST ADD DOC FROM HEROKU BACKEND !!!!!');

    try {
        console.log('PROCESSING!!!!!')
        console.log(orderObj._id);

        const { data: test_response_obj } = await axios.post("https://intense-beyond-56709-63aadc62ac0b.herokuapp.com/test_add_doc", {
            payment_intent: orderObj.paymentId,
            reason: 'requested_by_customer',
            est_complete_time_ISO: orderObj.est_complete_time_ISO
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('RESPONSE SUCCEEDED!!');
        console.log(test_response_obj);
  
        // Dispatch a success alert indicating that the profile has been updated
        dispatch(setAlert('TEST COMPLETE!', 'success'));
      
    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        dispatch(setAlert('Error running test!', 'danger'));
    }
};
