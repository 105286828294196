import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Components
import Spinner from '../../../components/common/Spinner';
import Discount_List_Item from './Discount_List_Item';
import CurrencyFormat from '../../common/CurrencyFormat';

const Discount_List = ({
    cart: {
        cart,
        loading
    },
    discount: {
        discounts
    }
}) => {

    const [discountList, setDiscountList] = useState([]);
    const [gotDiscountList, setGotDiscountList] = useState(false);

    useEffect(() => {

        renderDiscountList();
    }, [discounts]);

    const renderDiscountList = () => {
        setDiscountList([]);

        console.log(discounts)

        if(discounts === null) {
            setDiscountList([(
                <Spinner />
            )])
        }
        else { 
            if(discounts?.length > 0) {

                discounts.map(discount => {
                    setDiscountList(discountList => [...discountList, (
                        <Discount_List_Item discount={discount} />
                    )])
                });

                setGotDiscountList(true);
            } else {
                setDiscountList([(
                    <div>
                        <h1>No Discounts</h1>
                    </div>
                )])
            }
        } 
    }

    return (
        <Fragment>
            <div style={{minHeight: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                <div style={{flex: 1}}>
                    <span style={{lineHeight: '23.8px', fontWeight: 400, fontSize:'14px', marginLeft: '5px', color:'rgb(92, 92, 92)'}}>
                        {cart.qty} items
                    </span>    
                </div>

                <div style={{fontSize: '14px'}}>
                    <s style={{color: 'rgb(92, 92, 92)'}}><CurrencyFormat value={cart.subtotal} /></s> <CurrencyFormat value={cart.discountPrice} />
                </div>
            </div>
            {/* <div style={{minHeight: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                <div style={{fontSize: '14px', flex: 1}}>
                    <div style={{maxWidth: '80%'}}>
                        Save $3.00 on any TWO (2) Breyers, Reese's or Popsicle Frozen Dessert Products
                    </div>
                </div>
                <div style={{fontSize: '14px', color: 'rgb(0, 153, 89)'}}>
                    -$3
                </div>
            </div> */}
            {discountList}
        </Fragment>
    )
}

Discount_List.propTypes = {
    cart: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    cart: state.cart,
    discount: state.discount
})

export default connect(mapStateToProps, null)(Discount_List);