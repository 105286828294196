import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { removeDiscount } from '../../../actions/discountActions';

// Firebase
import { db, auth, storage } from "../../../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

// Components
import CurrencyFormat from '../../common/CurrencyFormat';

// Icons
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const Discount_List_Item = ({ 
    discount,
    removeDiscount
}) => {

    const {
        discount_value,
        type_of_value,
        discount_type
    } = discount;

    return (

        <Fragment>
            {(discount_type === 'amount off order' && type_of_value === 'fixed amount') && (
                <div style={{minHeight: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                    <div style={{fontSize: '14px', flex: 1}}>
                        <div style={{maxWidth: '80%'}}>
                            ${discount_value} discount applied
                        </div>
                    </div>
                    <div style={{fontSize: '14px', color: 'rgb(0, 153, 89)'}}>
                        -<CurrencyFormat value={discount_value} />
                    </div>
                </div>
            )}
        </Fragment>
    )
}

Discount_List_Item.propTypes = {
    removeDiscount: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { 
    removeDiscount 
})(Discount_List_Item);