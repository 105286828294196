import React from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// components - material UI
import { Button } from '@material-ui/core';


const CartInfo = ({ 
    cart: {
        cart: {
            subtotal,
            total,
            tax
        }
    },
    nav: {
        page
    }
 }) => {

    return (
        <div className="orderPage">
            {/**Header */}
            <div className="feed__header">
                <h2>Order Summary</h2>
            </div>
            <div className="orderPage__body">
                <div className="orderPage__section totals">
                    <div>
                        <p>Subtotal:</p>
                        <p>Shipping & Handling:</p>
                        <p>Estimated Tax:</p>
                        <p>Completed Total:</p>
                    </div>
                    <div>
                        <p>${subtotal}</p>
                        <p>$0.00</p>
                        <p>${tax}</p>
                        <p>${total}</p>
                    </div>
                </div>
                
                {page === 'cart' && (
                    <Button onClick={() => window.location = "/checkout"} variant="outlined" className="sidebar__tweet" fullWidth>Checkout</Button>
                )}

                {page === 'checkout' && (
                    <Button variant="outlined" className="sidebar__tweet" fullWidth>Place Order</Button>
                )}
            </div>

        </div>
    )
}

CartInfo.propTypes = {
    cart: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    cart: state.cart,
    nav: state.nav
})

export default connect(mapStateToProps, null)(CartInfo);
