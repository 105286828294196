import React, { Fragment } from 'react';

// Components
import { Button } from '@material-ui/core';

import logo from '../../utils/imgs/miitoken_logo.png';

const Default = (props) => {
  return (
    <div className="no-post">

      {/* Logo image */}
      <img onClick={() => window.location = '/home'} className="modal__twitterIcon" src={logo} style={{maxHeight: '80px', cursor:'pointer'}} alt="logo" />
      
      <h1>This page doesn’t exist. </h1>
      <p>Try searching for something else.</p>

      <Button  
          onClick={() => window.location ='/'}
          className="noChats__btn"
      >
          Find what you need
      </Button>
    </div>
  );
};

export default Default;