import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Firebase


// Redux
import { connect } from 'react-redux';

// Actions

// Components
import Spinner from '../../../../components/common/Spinner';

// Icons
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RefreshIcon from '@material-ui/icons/Refresh';

const Empty_Card = ({
    loadMore,
    setCanLoadMore,
    canLoadMore,
    page,
    product: {
        last_page_doc,
        show_bottom_spinner
    }

}) => {

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    const handleLoadMore = () => {
        if(canLoadMore) {
            console.log('CLICK 1')
            loadMore(last_page_doc);
        }

        console.log('canLoadMore', canLoadMore)

        console.log('last_page_doc', last_page_doc)
    }

    return (
        <div className='icon_36' onClick={handleLoadMore} style={{width: `${(isMobile && page === 'category_main') ? '30%' : '176px'}`, minWidth: `${(isMobile && page === 'category_main') ? 'auto' : `${page === 'category' ? '126px' : '166px'}`}`, marginLeft: '4px', height: `176px`, background: '#e6ecf0', maxHeight: `${isMobile ? '176px' : 'auto'}`, padding: '4px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', border: '1px solid #e6ecf0', cursor: 'pointer', textAlign: 'center', fontWeight: 'bold', color: 'rgb(83, 100, 113)', fontSize: '13px'}}>
            {canLoadMore ? (
                <Fragment>
                    <RefreshIcon />
                    Click To Load More..
                </Fragment>  
            ) : 'Loading'}
                        
        </div>   
    )
}

Empty_Card.propTypes = {
    product: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    product: state.product
})

export default connect(mapStateToProps, { })(Empty_Card);