import React, { useEffect, useState } from 'react';

// Date formatting
import { format, lightFormat } from 'date-fns';

// Components
import Item_Img from '../../Drivers/components/Offers/Item_Img';

// Icons
import { Avatar, Button } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import RepeatIcon from '@material-ui/icons/Repeat';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PublishIcon from '@material-ui/icons/Publish';

const Order = ({
        detailOrder
}) => {

    // Order Item Imgs List
    const [cartList, setCartList] = useState([]);
    const [gotCartList, setGotCartList] = useState(false);

    useEffect(() => {

        renderCartList();
    }, []);

    const renderCartList = () => {
        setCartList([]);

        if(detailOrder.cart.items === null) {
            setCartList([(
                <></>
            )])
        }
        else { 
            if(detailOrder.cart.items.length > 0) {

                detailOrder.cart.items.map(order_item => {
                    setCartList(cartList => [...cartList, (
                        <Item_Img key={order_item.item._id} orderItem={order_item} />
                    )])
                });

                setGotCartList(true);
            } else {
                setCartList([(
                    <></>
                )])
            }
        } 
    }

    const combineItemNames = (order) => {
        let combinedNames = [];
    
        for (const cartItem of order.cart.items) {
            combinedNames.push(cartItem.item.name);
        }
    
        return combinedNames.join(', ');
    }

    let orderTitle = combineItemNames(detailOrder);

    return (
        <div className='order' key={detailOrder._id}>

            {/* Avatar of the notification sender */}
            <div style={{display: 'flex', alignItems: 'center', width: '100%', overflowX: 'scroll'}}>
                {!cartList.length > 0 || !gotCartList ? <></> : cartList}
            </div>

            <div className='order__headerText'>
                <h3 className='line-clamp' style={{marginBottom: '10px', overflow:'hidden' , fontSize:'16px', fontWeight:'600', color:'#000', maxWidth:'100%'}}>
                    {orderTitle && orderTitle}
                </h3>
            </div>
            <div className='order__headerDescription'>
                <span style={{fontWeight: 'bold'}}>{detailOrder.status}</span>
                <span style={{margin: '5px 0'}}>{format(detailOrder.date, "h:mm a")}{' '}{lightFormat(detailOrder.date, 'MM-dd-yyyy')}</span>
            </div>

            {detailOrder?.status !== 'delivered' ? (
                <div style={{width: '100%', display: 'flex'}}>

                    <div style={{flex: 1, padding: '10px 5px 0px 0px', boxSizing: 'border-box'}}>
                        <Button onClick={() => window.location = `/orders/${detailOrder._id}`} variant="outlined" className="sidebar__tweet ghost noMargin" fullWidth>
                            View Details
                        </Button>
                    </div>

                    {/* <div style={{flex: 1, padding: '10px 0px 0px 5px', boxSizing: 'border-box'}}>
                        <Button onClick={() => window.location = `/orders/${detailOrder._id}`} variant="outlined" className="sidebar__tweet heart noMargin" fullWidth>
                            Rate Order
                        </Button>
                    </div> */}
            
                </div>
            ) : (
                <div style={{width: '100%', display: 'flex'}}>

                    <div style={{flex: 1, padding: '10px 5px 0px 0px', boxSizing: 'border-box'}}>
                        <Button onClick={() => window.location = `/orders/${detailOrder._id}`} variant="outlined" className="sidebar__tweet ghost noMargin" fullWidth>
                            View Details
                        </Button>
                    </div>
            
                </div>
            )}
        </div>
    )
}

export default Order;
