import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

// Components - imported
import { Button } from '@material-ui/core';
import { toggleAuthModal, toggleProfileModal } from '../../actions/navActions';


// Displays if the user is logged in AND has set up profile
const HowItWorks = ({ 
    auth: {
        isAuthenticated,
        user
    },
    toggleAuthModal,
    toggleProfileModal
}) => {

    const handleCreateDriverProfile = () => {
        if(isAuthenticated) {
            toggleProfileModal();
        } else {
            toggleAuthModal('driver register')
        }
    }

    // Render the component
    return (
        <div style={{padding: '16px', borderBottom: '1px solid #e6ecf0'}}>
            <div style={{color:'rgb(15, 20, 25)', marginBottom: '1.5rem', textAlign:'center', fontWeight:'700', fontSize:'24px', lineHeight:'24px', overflowWrap:'break-word'}}>
                <span style={{fontSize: '1.125rem'}}>Student Delivery &mdash; Earn Money!</span>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                    <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://cdn-icons-png.flaticon.com/512/1532/1532672.png" />
                </div>
                <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                        Get paid to deliver to students
                    </h5>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                        Pick up offers that are convenient for you
                    </h5>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                    <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '100%', height: 'auto'}} src="https://st3.depositphotos.com/1041273/17304/v/450/depositphotos_173043374-stock-illustration-delivery-man-riding-red-motor.jpg" />
                </div>
                <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                        Make your deliveries
                    </h5>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                        Go to the store, purchase the assigned items, and complete the delivery
                    </h5>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                    <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '100%', height: 'auto'}} src="https://assets.materialup.com/uploads/bcf6dd06-7117-424f-9a6e-4bb795c8fb4d/preview.png" />
                </div>
                <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                        Get paid
                    </h5>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                        Once the delivery is complete, you're done! We'll pay you back the full amount + tips!
                    </h5>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                    <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://images.vexels.com/media/users/3/207309/isolated/preview/9d2f0639c1ba2538699d51524c26f497-scroll-message-colorful-icon-stroke.png" />
                </div>
                <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                        Terms & Conditions
                    </h5>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                        Important: Please review this agreement carefully. <a href='/settings/terms' style={{color: 'rgb(29, 155, 240)'}}>Read more.</a>
                    </h5>
                </div>
            </div>

            <div>
                {isAuthenticated && user?.profile_set_up ? (
                    <Fragment>
                        {user.stripe_onboarding_complete ? (
                            <Fragment>
                                {user?.phone?.length > 0 ? (
                                    <></>
                                ) : (
                                    <Button 
                                        variant="outlined" 
                                        className="sidebar__tweet danger" 
                                        fullWidth
                                        onClick={() => window.location = '/settings/account/phone'}
                                    >
                                        Missing Item(s) - Add Phone Number
                                    </Button>
                                )}
                            </Fragment>
                        ) : (
                            <Button 
                                variant="outlined" 
                                className="sidebar__tweet ghost" 
                                fullWidth
                                onClick={() => window.location = '/settings/payments'}
                            >
                                Set up payments
                            </Button>
                        )}
                    </Fragment>
                ) : (
                    <Button 
                        variant="outlined" 
                        className="sidebar__tweet ghost" 
                        fullWidth
                        onClick={handleCreateDriverProfile}
                    >
                        Set up profile
                    </Button>
                )}
                {/* <Button 
                    variant="outlined" 
                    className="sidebar__tweet ghost" 
                    fullWidth
                >
                    Skip
                </Button> */}
            </div>
        </div>
    )
}

// Prop types for the component
HowItWorks.propTypes = {

    // Prop type validation for function to toggle the Authentication modal
    auth: PropTypes.object.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    toggleProfileModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { 

    // Connecting actions from Redux to the component
    toggleAuthModal,
    toggleProfileModal
})(HowItWorks);
