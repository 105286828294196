import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';

// Google Maps API
import { useLoadScript, DistanceMatrixService } from '@react-google-maps/api';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleLocationModal, setIsMarketplace, toggleModal_StoreHours, goBack } from '../../actions/navActions';
import { getLocation, setDeliveryTime } from '../../actions/locationActions';
import { addSearchQuery_Marketplace, getPopularSearches_Marketplace } from '../../actions/searchActions';
import { setProducts } from '../../actions/productActions';
import { getUnreadCount } from '../../actions/notificationActions';
import { getUnreadChats } from '../../actions/chatActions';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch/lite';

// Components - imported
import Widgets from '../Widgets/Widgets';
import { Avatar, colors } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import Delivery_Widget from '../../Marketplace/common/Delivery_Widget';

// Icons - material UI
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';

const ShopLayout = ({ 
    locationState: {
        detailLocation,
        deliveryTime,
        loading
    },
    notification,
    auth,
    chat,
    nav: { 
        sideNav,
        campus_name
    },
    cart: {
        cart
    },
    zone: {
        isOpen,
        detailZone,
        store_hours
    },
    toggleLocationModal, 
    toggleModal_StoreHours,
    goBack,
    getLocation,
    setDeliveryTime,
    children, 
    addSearchQuery_Marketplace,
    getPopularSearches_Marketplace,
    search: {
        popular_searches
    },
    setProducts,
    page,
    lastPageDoc,
    setLastPageDoc,
    setShowBottomSpinner,
    handleScroll,
    getUnreadCount,
    getUnreadChats,
    setIsMarketplace,
    autoFocus
}) => {

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [badgeValue, setBadgeValue] = useState(0);

    // ---- Search stuff

    const [inputClicked, setInputClicked] = useState(false);

    const [searchText, setSearchText] = useState('');

    const [searchRecommendations, setSearchRecommendations] = useState([]);

    const [dropdownOptionIndex, setDropdownOptionIndex] = useState(-1);

    const [searchDropdownArray, setSearchDropdownArray] = useState([]);

    // Delete Recent Searches Modal toggle
    const [deleteRecentSearchModal, setDeleteRecentSearchModal] = useState(false);

    // ---- END Search stuff

    // -- Toggle Dropdown ---
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [authDropdown, setAuthDropdown] = useState(false);

    // -- END: Toggle Dropdown ---

    // Google Maps API
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"]
    });

    // ---- Search stuff

    const searchClient = algoliasearch('H8YQ3RB3ZI', '5ac154251c4dc66618a5d9031842c478'); // change the secret key (2nd) to specific api key
    const searchRecommendationClient = algoliasearch('H8YQ3RB3ZI', 'c1e65045b5fecf8d1739a36673616f0c'); // change the secret key (2nd) to specific api key

    const searchIndex = searchClient.initIndex('products');
    const recommendationIndex = searchRecommendationClient.initIndex('searches_marketplace');

    // ---- END: Search stuff

    // Get the current URL and extract the "filter" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("q");

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        setIsMarketplace(true)
        getPopularSearches_Marketplace();

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // --- Badge Value -----

    // Fetch unread notification count and unread chat count when the authenticated user changes
    useEffect(() => {
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [auth.user]);

    // Update the badge value based on the counts of unread notifications and unread chats
    useEffect(() => {
        setBadgeValue(notification.num_unread + chat.num_unread_chats);
    }, [notification.num_unread, chat.num_unread_chats])

    // --- END: Badge Value -----

    useEffect(() => {

        getLocation();

    }, []);

    // -- Get the Delivery Time estimate
    useEffect(() => {

        // Create a callback function to handle Distance Matrix Service response
        const callback = (response) => {
            console.log('DRIVING DISTANCE MATRIX');
            console.log(response);
            console.log('MATRIX TIME: ', response.rows[0].elements[0].duration.text);
            console.log('MATRIX DISTANCE: ', response.rows[0].elements[0].distance.text);
            setDeliveryTime(response.rows[0].elements[0].duration.text)
        };

        if(isLoaded && detailLocation && detailZone?.location_details?.coordinates) {
        
            // Create a new DistanceMatrixService instance
            const distanceMatrixService = new window.google.maps.DistanceMatrixService();
        
            // Call the Distance Matrix API
            distanceMatrixService.getDistanceMatrix(
            {
                destinations: [detailLocation.coordinates],
                // Measure delivery time based on the time it takes to get from the customer to the Delivery Zone
                origins: [{lng: detailZone.location_details.coordinates.lng, lat: detailZone.location_details.coordinates.lat}], 
                travelMode: "DRIVING",
            },
                callback
            );
        }
    }, [isLoaded, detailLocation, detailZone]);

    // Update the array for counting the search dropdown index for when up/down arrows are clicked
    useEffect(() => {

        if(searchDropdownArray.length === 0) {
            setSearchDropdownArray([])
        } else {
            setSearchDropdownArray([...searchRecommendations])
        }

    }, [searchRecommendations]);

    // Search stuff: Reset the dropdown index for key up/down
    useEffect(() => {

        if(!inputClicked) {
            setDropdownOptionIndex(-1);
        }

    }, [inputClicked]);

    // Search stuff: Logic to detect when the search input is clicked
    useEffect(() => {

        // 👇 Get the Input element by Id
      const searchInput = document.getElementById('search-input');
      
      if (!searchInput) return;
  
      searchInput.addEventListener("focusin", (event) => {
        console.log('FOCUS IN')
        setInputClicked(true);
      });

      if(autoFocus) {
        setInputClicked(true);
      }

      return () => {
        searchInput.removeEventListener("focusin", (event) => {
            console.log('FOCUS IN')
            setInputClicked(true);
        });
      };

    }, []);

    // Apply CSS classes to the feed header based on the value of "sideNav"
    useEffect(() => {
        if(!isMobile) {
            if(sideNav) {
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                    return "feed__header";
                }
            } else {
                if(document.getElementById('feed-header') !== null) {
                    setTimeout(() => {
                        document.getElementById('feed-header').classList.add("active");
                    }, 700)
                }
            }
        } else {
            if(document.getElementById('feed-header') !== null) {
                document.getElementById('feed-header').classList.remove("active");
                return "feed__header";
            }
        }
    }, [sideNav, isMobile])

    // Search stuff: Handle search page filter
    useEffect(() => {

        if(page === 'search') {
            if(filter === null) return;
            setSearchText(filter);
            handleSearch(filter);
            handleRecommendations(filter);
            setLastPageDoc(0);

            addSearchQuery_Marketplace(filter);
        }

    }, [filter])

    // Search stuff: Prefill search recommendations when input is empty
    useEffect(() => {

        if(page === 'search') {
            if(searchText.length === 0) {
                setSearchRecommendations([]);
                
                if(popular_searches.length > 4) {
                    setSearchDropdownArray([...popular_searches.slice(0,4)]);
                } else {
                    setSearchDropdownArray([...popular_searches]);
                }
            }
        }

    }, [searchText])

    // Redirect the user to the previous page or a specific URL when the "goBack" function is called
    const history = useHistory();
    // const goBack = () => {

    //     history.goBack();
    // }

    // Function to handle input change
    const onChange = e => setSearchText(e.target.value);

    const onDownArrow = () => {

        console.log('SEARCH ARRAY')
        console.log(searchDropdownArray.length)

        console.log('SEARCH INDEX')
        console.log(dropdownOptionIndex + 1)

        console.log('REQ LENGTH INDEX')
        console.log(searchRecommendations.length)


        let nextIndex = dropdownOptionIndex + 1;

        if(nextIndex > searchDropdownArray.length - 1) {
            nextIndex = 0;
        }

        setDropdownOptionIndex(nextIndex);
    }

    const onUpArrow = () => {

        let nextIndex = dropdownOptionIndex - 1;

        if(nextIndex === -1 ) {
            nextIndex = searchDropdownArray.length - 1;
        }

        setDropdownOptionIndex(nextIndex);
    }

    const handleSearch = async (queryText) => {
        try {

            if(queryText === '') return;

            const results = await searchIndex.search(queryText, {
                page: lastPageDoc,
                hitsPerPage: 5
            });

            console.log('SEARCH RESULT');
            console.log(results);

            // Fetch initial products
     
            console.log('UPDATING PRODUCTS...');
            const tempProductList = results.hits.map((doc) => ({...doc, _id: doc.objectID}));

            setProducts(tempProductList);

            // Get the last visible document for the next load
            setLastPageDoc(lastPageDoc + 1);
            
            setShowBottomSpinner(false);

        } catch (error) {
            console.log(error);
            setProducts([]);
        }
    }

    const handleSubmit = async (queryText) => {
        if(dropdownOptionIndex > -1) {
            window.location.href = `/marketplace/search?q=${searchDropdownArray[dropdownOptionIndex].text ? searchDropdownArray[dropdownOptionIndex].text : searchDropdownArray[dropdownOptionIndex].search_text}`;

            console.log('SEARCH THIS WORD')
            console.log(searchDropdownArray[dropdownOptionIndex]);
        } else {
            if(queryText === '') return;

            window.location.href = `/marketplace/search?q=${queryText}`;
        }
    }

    const handleRecommendations = async (queryText) => {

        if(searchText.length - 1 > 0) {
            setDropdownOptionIndex(-1); // Reset search dropdown index

            try {

                if(queryText === '') return;

                const results = await recommendationIndex.search(queryText);

                console.log('SEARCH RESULT');
                console.log(results);

                setSearchRecommendations(results.hits.map((doc) => ({...doc, _id: doc.objectID})));

                setSearchDropdownArray([...results.hits.map((doc) => ({...doc, _id: doc.objectID})), ...searchDropdownArray]);

            } catch (error) {
                console.log(error);
                setSearchRecommendations([]);
            }
        }
    }

    let recommendations_list = [];

    if(searchRecommendations.length > 0) {

        // Iterate through the posts array and render each post component   
        for (let i = 0; i < searchRecommendations.length; i++) {
            const recommendation = searchRecommendations[i];
            
            if (recommendation) {
                recommendations_list.push(
                <div key={recommendation._id} onClick={() => window.location = `/marketplace/search?q=${recommendation.text}`} className={dropdownOptionIndex === i ? "search-dropdown-item active" : "search-dropdown-item"}>
                    <div className='search_dropdown_item_icon'>
                    <SearchIcon />
                    </div>
                    <div className='search_dropdown_item_option'>
                    <div style={{fontWeight:700}}>
                        {recommendation.text}
                    </div>
                    </div>
                </div>
                );
            }
        }
    }

    let popularSearch_list = [];

    for (let i = 0; i < popular_searches.length; i++) {

        const popular_search = popular_searches[i];
        
        if (popular_search) {
            popularSearch_list.push(
                <div onClick={() => window.location = `/marketplace/search?q=${popular_search.text}`} className={dropdownOptionIndex === i ? "search-dropdown-item active" : "search-dropdown-item"} key={popular_search._id}>
                    <div className='search_dropdown_item_icon'>
                        <SearchIcon />
                    </div>
                    <div className='search_dropdown_item_option'>
                        <div className='generated_result'>
                            <span>
                                
                            </span>
                            <span>
                                {popular_search.text}
                            </span>
                        </div>
                    </div>
                </div>
            );
        }
    }

    let user_name;

    if(!auth.loading && auth.isAuthenticated) {
        user_name = `${auth.user.first_name} ${auth.user.last_name && auth.user.last_name}`
    }

    return (
        <div className={page === 'search' ? `feed__container` : `shop__container`}>
            <div onScroll={handleScroll} className="shopFeed">
                {/* {(page === 'search' && campus_name === 'OU') && (
                    <div className="feed_headerTabs">
                        <Link to="/search">
                            <div>
                                <h3>Resale</h3>
                                <div className="block__underline"></div>
                            </div>
                        </Link>
                        <Link to="/marketplace/search">
                            <div className="active">
                                <h3>Grocery</h3>
                                <div className="block__underline"></div>
                            </div>
                        </Link>
                    </div>
                )} */}

                {/**Header */}
                <div className="feed__header" id="feed-header">
                    <div style={{padding: '0 10px 0 0'}} className="shopFeed__search">

                        {/* Left arrow Icon */}
                        <div onClick={goBack} style={{marginRight: `${isTablet ? '10px' : ''}`}} className="shopFeed__backBtn">
                            <div>
                                <ArrowBackIcon />
                            </div>
                        </div>

                        {/* Alerts Badge: displayed if it's a mobile device and badgeValue is greater than 0 */}
                        {isMobile && badgeValue > 0 ? (
                            <span className="feed-header-badge defaultBadge">
                                {badgeValue}
                            </span>
                        ) : null}

                        {page !== 'search' ? (
                            <Fragment>
                                {/* Search Input */}
                                {!isTablet && (
                                    <Fragment>
                                        <div style={{marginRight: '16px'}} className={`widgets__input marketplace ${inputClicked ? 'active' : ''}`}>
                                            <SearchIcon className="widgets__searchIcon" />
                                            <input 
                                                id="search-input" 
                                                placeholder="Search MiiToken"
                                                type="text" 
                                                autocomplete="off" 
                                                value={searchText}
                                                onChange={e => onChange(e)}
                                                onKeyDown={(e) => {
                                                    if(e.key === 'Enter') {
                                                        handleSubmit(searchText);
                                                    } else if(e.keyCode == '40') { // down arrow
                                                        onDownArrow();
                                                    } else if(e.keyCode == '38') { // up arrow
                                                        onUpArrow();
                                                    } else {
                                                        handleRecommendations(searchText);
                                                    }
                                                }}
                                            />
                                        </div>

                                        {/* Dropdown Menu */}
                                        <div className={inputClicked ? "shop-search-dropdown active" : "shop-search-dropdown"}>
                                                
                                            <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />

                                            {recommendations_list}

                                            {!recommendations_list?.length > 0 ? (
                                                <Fragment>
                                                    {(popular_searches.length > 0) && (
                                                        <Fragment>
                                                            <div className='menu-title' style={{display: 'flex', cursor:'default', justifyContent:'space-between', alignItems: 'center', padding: '12px 16px'}}>
                                                                <div style={{lineHeight:'24px', fontSize:'20px', fontWeight:700, wordWrap:'break-word', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                                                    <span>Popular searches</span>
                                                                </div>
                                                            </div>

                                                            {popularSearch_list.slice(0, 4)}

                                                        </Fragment>
                                                    )}
                                                </Fragment>
                                            ) : null}

                                            {!recommendations_list?.length > 0 && !popular_searches?.length > 0 ? (
                                                <div className='menu-title' style={{display: 'flex', width:'100%', color:'rgb(83, 100, 113)', fontSize:'15px', cursor:'default', justifyContent:'center', alignItems: 'center', paddingTop: '12px'}}>Try searching for keywords</div>
                                            ) : null}
                                        </div>

                                        {/* Hidden Overlay for dropdown */}
                                        <div
                                            className={`hidden-overlay ${inputClicked ? "show" : ""}`}
                                            onClick={() => setInputClicked(!inputClicked)}
                                        />
                                    </Fragment>
                                )}

                                <Delivery_Widget />

                            </Fragment>
                        ) : (
                            <Fragment>
                                <div style={{flex: 1}}>

                                    {/* Search Input */}
       
                                    <div style={{marginRight: '16px'}} className={`widgets__input marketplace ${inputClicked ? 'active' : ''}`}>
                                        <SearchIcon className="widgets__searchIcon" />
                                        <input 
                                            id="search-input" 
                                            placeholder="Search MiiToken"
                                            type="text" 
                                            autocomplete="off" 
                                            autoFocus={autoFocus ? autoFocus : false}
                                            value={searchText}
                                            onChange={e => onChange(e)}
                                            onKeyDown={(e) => {
                                                if(e.key === 'Enter') {
                                                    handleSubmit(searchText);
                                                } else if(e.keyCode == '40') { // down arrow
                                                    onDownArrow();
                                                } else if(e.keyCode == '38') { // up arrow
                                                    onUpArrow();
                                                } else {
                                                    handleRecommendations(searchText);
                                                }
                                            }}
                                        />
                                    </div>

                                    {/* Dropdown Menu */}
                                    <div className={inputClicked ? "search-dropdown active" : "search-dropdown"}>
                                            
                                        <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />

                                        {recommendations_list}

                                        {!recommendations_list?.length > 0 ? (
                                            <Fragment>
                                                {(popular_searches.length > 0) && (
                                                    <Fragment>
                                                        <div className='menu-title' style={{display: 'flex', cursor:'default', justifyContent:'space-between', alignItems: 'center', padding: '12px 16px'}}>
                                                            <div style={{lineHeight:'24px', fontSize:'20px', fontWeight:700, wordWrap:'break-word', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                                                <span>Popular searches</span>
                                                            </div>
                                                        </div>

                                                        {popularSearch_list.slice(0, 4)}

                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        ) : null}

                                        {!recommendations_list?.length > 0 && !popular_searches?.length > 0 ? (
                                            <div className='menu-title' style={{display: 'flex', width:'100%', color:'rgb(83, 100, 113)', fontSize:'15px', cursor:'default', justifyContent:'center', alignItems: 'center', paddingTop: '12px'}}>Try searching for keywords</div>
                                        ) : null}
                                    </div>

                                    {/* Hidden Overlay for dropdown */}
                                    <div
                                        className={`hidden-overlay ${inputClicked ? "show" : ""}`}
                                        onClick={() => setInputClicked(!inputClicked)}
                                    />
                                </div>
                            </Fragment>
                        
                        )}

                        {/* Shopping Cart Icon */}
                        {!isTablet && (
                            <div onClick={() => {window.location = '/cart'}} style={{height: '44px', boxSizing: 'border-box', cursor: 'pointer', background:'#000', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '64px', overflow:'hidden', paddingLeft: '8px'}}>
                                <div style={{height: '40px', marginRight: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', borderRadius: '64px', position: 'relative'}}>
                                    <div style={{height: '40px', display: 'flex', alignItems: 'center', width: '40px', justifyContent: 'center', padding: '6px', borderRadius: '40px'}}>
                                        <div style={{fontSize: '24px', color: '#fff'}}>
                                            <LocalMallOutlinedIcon />
                                        </div>
                                    </div>
                                    {cart.qty > 0 && (
                                        <div style={{position: 'absolute', minWidth: '18px', background: 'rgba(250,216,105,1.00)', left: '21px', height: '18px', top: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1px', borderRadius: '24px'}}>
                                            <div style={{lineHeight: '15.8px', fontWeight: 'bold', fontSize: '12px'}}>
                                                {cart.qty}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div style={{textAlign: 'center', marginRight:'40px', paddingRight: '24px', flex: 1}}>
                                    <div style={{color: '#fff', fontWeight: 'bold', lineHeight: '23.8px', fontSize: '18px'}}>
                                        My Bag
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Avatar Icon */}
                        {isTablet && page !== 'search' && (
                            <div onClick={() => window.location = '/cart'} className="shopFeed__cog">
                                <ShoppingCartOutlinedIcon />
                            
                                {cart.qty > 0 && (
                                    <span className="notification-badge badge_for_top_cart">
                                        {cart.qty}
                                    </span>
                                )}
                            </div>
                        )}
                      
                    </div>
                    
                </div>

                {/* Render the children */}
                {children}

                {/* Add space to the bottom of the screen to provide room for the cart btn */}
                <div style={{height: '100px'}}></div>
            </div>
        </div>
    )
}

ShopLayout.propTypes = {
    locationState: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    zone: PropTypes.object.isRequired,
    toggleLocationModal: PropTypes.func.isRequired,
    toggleModal_StoreHours: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getLocation: PropTypes.func.isRequired,
    setDeliveryTime: PropTypes.func.isRequired,
    addSearchQuery_Marketplace: PropTypes.func.isRequired,
    getPopularSearches_Marketplace: PropTypes.func.isRequired,
    setProducts: PropTypes.func.isRequired,
    getUnreadCount: PropTypes.func.isRequired,
    getUnreadChats: PropTypes.func.isRequired,
    setIsMarketplace: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    locationState: state.location,
    search: state.search,
    auth: state.auth,
    notification: state.notification,
    chat: state.chat,
    nav: state.nav,
    cart: state.cart,
    zone: state.zone
})

export default connect(mapStateToProps, {
    
    // Connecting actions to the component
    toggleLocationModal,
    toggleModal_StoreHours,
    goBack,
    getLocation,
    setDeliveryTime,
    addSearchQuery_Marketplace,
    getPopularSearches_Marketplace,
    setProducts,
    getUnreadCount,
    getUnreadChats,
    setIsMarketplace
})(ShopLayout);
