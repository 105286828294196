import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { deleteLocation } from '../../../actions/locationActions';

// Icons - material UI
import CheckIcon from '@material-ui/icons/Check';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import CloseIcon from '@material-ui/icons/Close';

const LocationList_Item = ({ 
    detailLocation, 
    setLocationActive, 
    deleteLocation,
    locations,
    page,
    handleChangeAddress
}) => {

    const handleDelete = () => {
        deleteLocation(locations, detailLocation._id)
    }

    const handle_Click = () => {

        if(page === 'change address') {
            handleChangeAddress(detailLocation)
        } else {
            setLocationActive(detailLocation._id, locations)
        }
    }

    return (
        <div>
            <div className='location_list_item'>
                <div onClick={handle_Click} style={{flex: 1, display: 'flex', alignItems: 'center', height: '100%'}}>
                    <div style={{height:'36px', alignItems:'center', width: '36px', justifyContent:'center', display:'flex', padding:'0px 6px', display:'flex', borderRadius:'50%', boxSizing:'border-box'}}>
                        <div style={{fontSize:'24px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <RoomOutlinedIcon />
                        </div>
                    </div>
                    <div style={{flex: 1, boxSizing: 'border-box', marginLeft: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <div style={{fontSize: '20px', fontWeight: 'bold'}}>
                            {detailLocation.street_number}{' '}{detailLocation.street_name}
                        </div>
                        {detailLocation.room_number && (
                            <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px', overflow: 'hidden'}}>
                                Room: {detailLocation.room_number}
                            </div>
                        )}
                        <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px', overflow: 'hidden'}}>
                            {detailLocation.city}{', '}{detailLocation.state}
                        </div>
                    </div>
                </div>
                
                {detailLocation.active ? (
                    <div className='location_list_item_iconBtn'>
                        <CheckIcon />
                    </div>
                ) : (
                    <div onClick={handleDelete} className='location_list_item_iconBtn danger'>
                        <CloseIcon />
                    </div>
                )}
            </div>
            
        </div>
    )
}

LocationList_Item.propTypes = {
    deleteLocation: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { 
    
    // Connecting actions to the component
    deleteLocation
})(LocationList_Item);