
import {
    GET_CART,
    ADD_TO_CART,
    APPLY_DISCOUNT,
    ADD_TOTALS,
    DECREMENT_ITEM,
    CLEAR_CART, 
    CART_ERROR, 
    CART_LOADING,
    UPDATE_DRIVER_TIP
} from '../actions/types';

    const initialState = {
        cart: {
            items: [],
            discounts: [],
            subtotal: 0,
            discountPrice: 0,
            tax: 0,
            tip: 0,
            total: 0,
            qty: 0
        },
        loading: true,
        error: {}
    };

    function formatAsDollarValue(number) {
        console.log('NUMBER TO CONVERT: ', number)
        if (typeof number !== 'number') {
            return number
        }

        const dollars = Math.floor(number);
        const cents = Math.round((number - dollars) * 100).toString().padStart(2, '0');

        console.log('DOLLARS: ', dollars);
        console.log('CENTS: ', cents);
        console.log(Number(dollars + '.' + cents).toFixed(2));
        return Number(dollars + '.' + cents).toFixed(2);
    }

    export default function(state = initialState, action) {
        const { type, payload } = action;

        switch (type) {
            case GET_CART:

                const arr = [];

                for (const id in action.payload.items) {
                    arr.push({
                        ...action.payload.items[id],
                        price: parseFloat(action.payload.items[id].price.toFixed(2))
                    });
                }


                let _subTotal = action.payload.totalPrice;
                let _discountPrice = action.payload.discountPrice;
                let _driverTip = action.payload.driverTip;
                const _tempTax = _subTotal * 0.1;
                const _tax = parseFloat(_tempTax.toFixed(2));
                let _total = _discountPrice + _tax + _driverTip;
                _total = parseFloat(_total.toFixed(2));
                const _totalQty = action.payload.totalQty;

                console.log('REDUCER CART')
                console.log(_discountPrice);
                console.log(_total);

                return {
                    ...state,
                    cart: {
                        items: arr,
                        discounts: action.payload.discounts,
                        subtotal: _subTotal,
                        discountPrice: _discountPrice,
                        tip: _driverTip,
                        tax: _tax,
                        total: formatAsDollarValue(_total),
                        qty: _totalQty
                    },
                    loading: false
                }
            case ADD_TO_CART: {

                const arr = [];

                for (const id in action.payload.items) {
                    arr.push({
                        ...action.payload.items[id],
                        price: parseFloat(action.payload.items[id].price.toFixed(2))
                    });
                }

                let subTotal = action.payload.totalPrice;
                let discountPrice = action.payload.discountPrice;
                let driverTip = action.payload.driverTip;
                const tempTax = subTotal * 0.1;
                const tax = parseFloat(tempTax.toFixed(2));
                let total = discountPrice + tax + driverTip;
                total = parseFloat(total.toFixed(2));
                const totalQty = action.payload.totalQty;
                return {
                    ...state,
                    cart: {
                        items: arr,
                        discounts: action.payload.discounts,
                        subtotal: subTotal,
                        discountPrice: discountPrice,
                        tax,
                        tip: driverTip,
                        total: formatAsDollarValue(total),
                        qty: totalQty
                    }
                }
            }
            case APPLY_DISCOUNT: {

                const arr = [];

                for (const id in action.payload.items) {
                    arr.push({
                        ...action.payload.items[id],
                        price: parseFloat(action.payload.items[id].price.toFixed(2))
                    });
                }

                let _subTotal_ = action.payload.totalPrice;
                let _discountPrice_ = action.payload.discountPrice;
                let _driverTip_ = action.payload.driverTip;
                const _tempTax_ = _subTotal_ * 0.1;
                const _tax_ = parseFloat(_tempTax_.toFixed(2));
                let _total_ = _discountPrice_ + _tax_ + _driverTip_;
                _total_ = parseFloat(_total_.toFixed(2));
                const _totalQty_ = action.payload.totalQty;
                return {
                    ...state,
                    cart: {
                        items: arr,
                        discounts: action.payload.discounts,
                        subtotal: _subTotal_,
                        discountPrice: _discountPrice_,
                        tax: _tax_,
                        tip: _driverTip_,
                        total: formatAsDollarValue(_total_),
                        qty: _totalQty_
                    }
                }
            }
            case ADD_TOTALS: 

                let subTotal_ = 0;

                state.cart.items.map(item => (subTotal_ += item.price));

                let discountTotal_ = subTotal_;

                // extract an array of all key values from object then map()
                Object.values(state.cart.discounts).map(discount => {
                    if (discount.discount_type === 'amount off order' && discount.type_of_value === 'fixed amount') {
                        discountTotal_ -= discount.discount_value;
                    }
                });

                if(discountTotal_ >= 0) {
                    discountTotal_ = parseFloat(discountTotal_.toFixed(2));
                } else {
                    discountTotal_ = 0;
                }

                const tempTax_ = subTotal_ * 0.1;
                const tax_ = parseFloat(tempTax_.toFixed(2));
                let total_ = discountTotal_ + tax_;
                total_ = parseFloat(total_.toFixed(2));

                console.log('REDUCER CART ADD TOTALS')
                console.log('discountTotal_' + discountTotal_);
                console.log('subTotal_' + subTotal_);
                console.log('total_' + total_);

                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        subtotal: subTotal_,
                        discountPrice: discountTotal_,
                        tax: tax_,
                        total: formatAsDollarValue(total_),
                    }
                }
            case UPDATE_DRIVER_TIP: 

                let subTotal__ = 0;

                state.cart.items.map(item => (subTotal__ += item.price));

                let discountTotal__ = subTotal__;

                console.log('REDUCER CART ADD TOTALS')
                console.log(state.cart);

                // extract an array of all key values from object then map()
                Object.values(state.cart.discounts).map(discount => {
                    if (discount.discount_type === 'amount off order' && discount.type_of_value === 'fixed amount') {
                        discountTotal__ -= discount.discount_value;
                    }
                });
         

                if(discountTotal__ >= 0) {
                    discountTotal__ = parseFloat(discountTotal__.toFixed(2));
                } else {
                    discountTotal__ = 0;
                }

                const tempTax__ = subTotal__ * 0.1;
                const tax__ = parseFloat(tempTax__.toFixed(2));

                let total__ = discountTotal__ +  payload + tax__;
                total__ = parseFloat(total__.toFixed(2));

                console.log('REDUCER CART ADD TOTALS')
                console.log(state.cart);
                console.log('discountTotal_' + discountTotal__);
                console.log('subTotal_' + subTotal__);
                console.log('total_' + total__);

                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        tip: payload,
                        total: formatAsDollarValue(total__),
                    }
                }
            case CLEAR_CART: 

                return {
                    ...state,
                    cart: {
                        items: [],
                        discounts: [],
                        subtotal: 0,
                        discountPrice: 0,
                        tax: 0,
                        tip: 0,
                        total: 0,
                        qty: 0
                    }
                }
            case CART_LOADING:

                // Set the loading state to true
                return {
                    ...state,
                    loading: true
                };
            case CART_ERROR:
                return {
                    ...state,
                    error: payload,
                    loading: false
                };
            default:
                return state;
        }
    }