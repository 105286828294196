import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { setPage, toggleLocationModal, togglePostModal } from '../../actions/navActions';

// Components - imported
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';

// Icons -imported
import TwitterIcon from '@material-ui/icons/Twitter';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PersonIcon from '@material-ui/icons/Person';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import FastfoodOutlinedIcon from '@material-ui/icons/FastfoodOutlined';
import FastfoodIcon from '@material-ui/icons/Fastfood';

import SidebarOption from './SidebarOption';

import miitoken_logo from '../../utils/imgs/miitoken_logo.png';

const Sidebar = ({
    setPage,
    togglePostModal,
    toggleLocationModal,
    auth: { 
        user, 
        modalUser,
        isAuthenticated, 
        loading 
    }, 
    nav: { 
        page,
        isMarketplace,
        // - Campus Logic -
        campus_name
        // - END: Campus Logic -
    },
    isMobile,
    isTablet
}) => {

    // State to determine highlighted sidebar option
    const [navHighlight, setNavHighlight] = useState(null);

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);

    // --- Set the navigation highlight based on the current page
    useEffect(() => {
        if(page === 'search' || page === 'marketplace search') {
            setNavHighlight('search');
        }
        if(page === 'notifications') {
            setNavHighlight('notifications');
        }

        if(page === 'messages') {
            setNavHighlight('messages');
        }

        if(page === 'saved') {
            setNavHighlight('saved');
        }

        if(page === 'profile') {
            setNavHighlight('profile');
        }

        if(page === 'settings') {
            setNavHighlight('settings');
        }

        // - Marketplace ---
        
        if(page === 'marketplace' || page === 'grocery landing' || page === 'product detail' || page === 'cart' || page === 'checkout' || page === 'collection') {
            setNavHighlight('grocery');
        }

    }, [page]);

    // Handle navigation to a specific page
    const todo = (page) => {
        setNavHighlight(page);
        setPage(page);
    }

    // Get User info
    let username = null;

    // Check if the user is logged in and set the username
    if(user) {
        username = user.username;
    }

    let isMyProfile = false;

    // Check if the current highlighted navigation is 'profile'
    if(navHighlight === "profile") {
        if(user && modalUser && user._id !== modalUser._id) {
            isMyProfile = false;
        } else {
            isMyProfile = true;
        }
    }

    let user_name;

    if(!loading && isAuthenticated) {
        user_name = `${user.first_name} ${user.last_name && user.last_name}`
    }

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(user && user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    return (
        <div className="sidebar" style={{display:'flex', position:'relative', flexDirection: 'column'}}>
            {/* <TwitterIcon className="sidebar__twitterIcon" /> */}
            <div onClick={() => window.location = '/'} style={{cursor: 'pointer'}}>
                 <img className="sidebar__twitterIcon" src={miitoken_logo} style={{height: '150px', width: '150px'}} alt="logo" />
            </div>

            {/* --- Sidebar options */}

            {/* The active option is determined by comparing the current navHighlight with the option */}
            <SidebarOption active={navHighlight === "grocery"} Icon={navHighlight === "grocery landing" ? FastfoodIcon : FastfoodOutlinedIcon} text="Home" link="/" />
            <SidebarOption active={navHighlight === "search"} Icon={SearchOutlinedIcon} text="Search" link="/search" />
            
            {/* Conditional rendering for authenticated users */}
            {!loading && isAuthenticated ? (
                <Fragment>
                    <SidebarOption active={navHighlight === "notifications"} Icon={navHighlight === "notifications" ? NotificationsIcon : NotificationsNoneIcon} text="Alerts" link="/notifications" />
                    <SidebarOption active={navHighlight === "messages"} Icon={navHighlight === "messages" ? MailIcon : MailOutlineIcon} text="Inbox" link="/messages" />
                </Fragment>
            ) : null}

            {/* If the user is authenticated, show "More" as the text, otherwise show "Settings" */}
            <SidebarOption active={navHighlight === "settings"} Icon={MoreHorizIcon} text="More" link="/settings" />

        </div>
    )
}

Sidebar.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
    nav: PropTypes.object.isRequired, // Prop: Object representing navigation state
    setPage: PropTypes.func.isRequired, // Function prop to set the current page
    togglePostModal: PropTypes.func.isRequired, // Function prop to toggle the post modal
    toggleLocationModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
})

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage, 
    togglePostModal,
    toggleLocationModal
})(Sidebar);
