import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// Routing
import Routes from './components/routes/Routes';
import PrivateRoute from './components/routes/PrivateRoute';
import Body from './Body';

// Stripe API
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// CSS - controlled by /scss
import './css/main.css';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Actions
import { loadUser } from './actions/authActions';
import { getCart } from './actions/cartActions';
import { getDeliveryZone } from './actions/zoneActions';

// Components - imported
import Sidebar from './components/sidebar/Sidebar';
import AlertContainer from './components/common/Alerts/AlertContainer';

// Pages - imported
import SetUpProfile from './components/Profile/SetUpProfile/SetUpProfile';

// Marketplace Pages
import CartPage from './Marketplace/pages/CartPage';

// Auth Pages
import AuthPage from './pages/auth/AuthPage';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import VerifyPage from './pages/auth/register/Verification';
import Logout from './pages/auth/Logout';
import ClaimAccount from './pages/auth/ClaimAccount/ClaimAccount';
import CreateNewMessage from './pages/create/CreateNewMessage';
// Marketplace Pages
import GuestAddressPage from './Marketplace/pages/GuestAddressPage';
import GuestPhoneNumberPage from './Marketplace/pages/GuestPhoneNumberPage';
import Flyer_1 from './Marketplace/common/Flyer_1';
import ComingSoon from './Marketplace/common/ComingSoon';
// Driver Pages
import DeliveryPage from './Drivers/pages/DeliveryPage';
import DeliveryHowTo from './Drivers/pages/DeliveryHowTo';
import DeliverySupport from './Drivers/pages/DeliverySupport';

// Call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_stripeKey);

const App = () => {
  // Initializing analytics
  const initializeAnalytics = () => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
      debug: true
    });
  }

  // Window Width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Slide menu
  const [slideMenu, setSlideMenu] = useState(true);

  useEffect(() => {
    const hostname = window.location.hostname;

    console.log('HOSTNAME HERE: ', hostname)

    // Dispatching the action to load the user
    store.dispatch(loadUser());
    // Dispatching the action to GET the Cart
    store.dispatch(getCart());
    // Dispatching the action to GET the Delivery Zone
    store.dispatch(getDeliveryZone());

    if (process.env.NODE_ENV === 'production') {
      // Initializing analytics in production environment
      initializeAnalytics();
    }

    // Handling window resize event
    window.addEventListener('resize', handleWindowSizeChange);

    // Cleaning up the event listener on component unmount
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const handleWindowSizeChange = () => {
    // Updating the window width state on window resize
    setWindowWidth(window.innerWidth);
  };

  const handleSlideMenu = () => {
    // Toggling the slide menu state
    setSlideMenu(!slideMenu);
  }

  // Determine window size breakpoints
  const isMobile = windowWidth <= 769;
  const isTablet = windowWidth <= 1000;

  const searchClient = algoliasearch('H8YQ3RB3ZI', 'b797e6858550b93ae143de5b4941f4e9');

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/x" component={Flyer_1} />
            <Route exact path="/coming" component={ComingSoon} />

            
            <Route exact path="/user_registration" component={AuthPage} />

            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />

            <Route exact path="/verify" component={VerifyPage} />
            <Route exact path="/addresses" component={GuestAddressPage} />
            <Route exact path="/contact-phone" component={GuestPhoneNumberPage} />
            <PrivateRoute exact path="/logout" component={Logout} />
            <Route exact path="/login/sent" component={ClaimAccount} />
            {/* <PrivateRoute exact path="/profile/set-up" component={SetUpProfile} /> */}
            {/* <PrivateRoute exact path="/messages/new" component={UserSearchPage} /> */}
            {/* <PrivateRoute exact path="/create/message" component={CreateNewMessage} /> */}

            {/* Driver Pages */}
            <Route exact path="/delivery/howto" component={DeliveryHowTo} />
            <Route exact path="/delivery/support" component={DeliverySupport} />

            {/* Driver Pages */}
            <Route exact path="/delivery/:orderID" component={DeliveryPage} />

            <Body />
          </Switch>

          <AlertContainer />
        </Router>
      </Provider>
    </Elements>
  );
}

export default App;
