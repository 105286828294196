import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav } from '../../actions/navActions';

// Components - imported
import { Avatar } from '@material-ui/core';

// Icons -imported
import TwitterIcon from '@material-ui/icons/Twitter';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PersonIcon from '@material-ui/icons/Person';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FastfoodOutlinedIcon from '@material-ui/icons/FastfoodOutlined';
import FastfoodIcon from '@material-ui/icons/Fastfood';

import SidebarOption from './SidebarOption';

// Icons - material UI
import CloseIcon from '@material-ui/icons/Close';

import miitoken_logo from '../../utils/imgs/miitoken_logo.png';

const SidebarMobile = ({ 
    slideMenu, 
    handleSlideMenu, 
    auth: { 
        user,
        loading,
        isAuthenticated
    },
    nav: { 
        page,
        // - Campus Logic -
        campus_name
        // - END: Campus Logic -
    }
}) => {

    // State to determine highlighted sidebar option
    const [navHighlight, setNavHighlight] = useState(null);

    // --- Update the navHighlight based on the current page
    useEffect(() => {
        if(page === 'home') {
            setNavHighlight('home');
        }
        // if(page === 'howitworks') {
        //     setNavHighlight('howitworks');
        // }
        if(page === 'notifications') {
            setNavHighlight('notifications');
        }

        if(page === 'messages') {
            setNavHighlight('messages');
        }

        if(page === 'saved') {
            setNavHighlight('saved');
        }

        if(page === 'profile') {
            setNavHighlight('profile');
        }

        if(page === 'settings') {
            setNavHighlight('settings');
        }

        // - Marketplace ---
        
        if(page === 'marketplace' || page === 'product detail' || page === 'cart' || page === 'checkout' || page === 'collection') {
            setNavHighlight('grocery');
        }
    }, [page]);

    // Get User info
    let username = null;

    // Check if the user is logged in and set the username
    if(user) {
        username = user.username;
    }

    let user_name;

    if(!loading && isAuthenticated) {
        user_name = `${user.first_name} ${user.last_name && user.last_name}`
    }

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(user && user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    return (
        /* --- Stucture --- */

            /* Twitter logo or icon */
            /* Sidebar options */
            /* Display additional options if user is authenticated */
            /* Sidebar options */
            
            /* Close button */

        /* --- */
        
        <div className={slideMenu ? "nav open": "nav"}>
            <div className="sidebar-mobile" style={{display:'flex', height:'100vh', flexDirection:'column'}}>
                {/* <TwitterIcon className="sidebar__twitterIcon" /> */}
                <div onClick={() => window.location = '/'} style={{marginLeft: '20px', cursor: 'pointer'}}>
                    <img className="sidebar__twitterIcon" src={miitoken_logo} style={{height: '150px', width: '150px'}} alt="logo" />
                </div>

                {/* --- Sidebar options */}

                <SidebarOption toggle active={navHighlight === "home"} Icon={navHighlight === "home" ? FastfoodIcon : FastfoodOutlinedIcon} text="Home" link="/" handleSlideMenu={handleSlideMenu} />
                <SidebarOption toggle active={navHighlight === "search"} Icon={SearchOutlinedIcon} text="Search" link="/search" handleSlideMenu={handleSlideMenu} />

                {/* Display additional options if user is authenticated */}
                {!loading && isAuthenticated ? (
                    <Fragment>
                        {/* <SidebarOption toggle Icon={StorefrontIcon} text="Marketplace" link="/marketplace" handleSlideMenu={handleSlideMenu} /> */}
                        <SidebarOption toggle active={navHighlight === "notifications"} Icon={navHighlight === "notifications" ? NotificationsIcon : NotificationsNoneIcon} text="Alerts" link="/notifications" handleSlideMenu={handleSlideMenu} />
                        <SidebarOption toggle active={navHighlight === "messages"} Icon={navHighlight === "messages" ? MailIcon : MailOutlineIcon} text="Inbox" link="/messages" handleSlideMenu={handleSlideMenu} />
                        {/* <SidebarOption toggle active={navHighlight === "saved"} Icon={navHighlight === "saved" ? FavoriteIcon : FavoriteBorderIcon} text="Saved" link={`/${user.username}?show=likes`} handleSlideMenu={handleSlideMenu} /> */}
                        {/* <SidebarOption toggle Icon={ListAltIcon} text="My Orders" link="/orders" handleSlideMenu={handleSlideMenu} /> */}
                        {/* <SidebarOption toggle active={navHighlight === "profile"} Icon={navHighlight === "profile" ? PersonIcon : PersonOutlineIcon} text="Profile" link={username ? `/${username}` : '/xyz'} handleSlideMenu={handleSlideMenu} /> */}
                       
                    </Fragment>
                ) : null}
                
                    <SidebarOption toggle active={navHighlight === "settings"} Icon={SettingsOutlinedIcon} text="Settings" link="/settings" handleSlideMenu={handleSlideMenu} />

            </div>
            <a href="#" className="close" onClick={handleSlideMenu}>
                <CloseIcon />
            </a>
        </div>
    )
}

SidebarMobile.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
    nav: PropTypes.object.isRequired, // Prop: Object representing navigation state
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
})

// Connect the SidebarMobile component to the Redux store and export it
export default connect(mapStateToProps, null)(SidebarMobile);
