import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux';

// Actions
import { toggleLocationModal, toggleModal_StoreHours } from '../../actions/navActions';

// Icons - material UI
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const Delivery_Widget = ({
    zone: {
        isOpen,
        detailZone,
        store_hours
    },
    locationState: {
        detailLocation,
        deliveryTime,
        loading
    },
    nav: {
        page
    },
    toggleLocationModal,
    toggleModal_StoreHours,
    is
}) => {

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

  return (
        <div style={{flex: 1}}>
            {isOpen ? (
                <div onClick={() => toggleLocationModal()} className={` delivery_widget ${isTablet ? 'isTablet' : ''} ${page === 'cart' ? 'inCart' : ''} `}>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>

                        {detailLocation ? (
                            <div className='delivery_widget_icon_container'>
                                <div style={{fontSize:'24px'}}>
                                    <DirectionsCarIcon />
                                </div>
                            </div>
                        ) : (
                            <div className='delivery_widget_icon_container add_address'>
                                <AddCircleOutlineIcon />
                            </div>
                        )}

                        <div style={{height: '36px', flex: 1, paddingBottom: '4px', boxSizing: 'border-box', marginLeft: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            {detailLocation ? (
                                <Fragment>
                                    <div style={{display: 'flex', maxWidth: '100%'}}>
                                        <div style={{color:'#333', fontWeight:'bold', maxWidth:'100%', whiteSpace:'nowrap', textOverflow:'ellipsis', fontSize:'16px', overflow:'hidden'}}>
                                            {deliveryTime ? `Delivery in ${deliveryTime} mins` : "Calculating the delivery time..."}
                                        </div>
                                    </div>
                                    <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '12px', overflow: 'hidden'}}>
                                        {detailLocation.street_number}{' '}{detailLocation.street_name}{detailLocation.room_number ? ' '+detailLocation.room_number : ''}
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div style={{display: 'flex', maxWidth: '100%'}}>
                                        <div style={{fontSize:'12px', color:'#333'}}>
                                            {/* <OfflineBoltOutlinedIcon /> */}
                                        </div>
                                        <div style={{color:'#333', fontWeight:'bold', maxWidth:'100%', whiteSpace:'nowrap', textOverflow:'ellipsis', fontSize:'16px', overflow:'hidden'}}>
                                            Add an Address
                                        </div>
                                    </div>
                                    <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '12px', overflow: 'hidden'}}>
                                        Add an address to see delivery time
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>

                    {page === 'cart' && (
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{fontSize:'30px', color: 'rgb(249, 24, 128)'}}>
                                <EditOutlinedIcon />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Fragment>
                    {detailZone ? (
                        <div onClick={toggleModal_StoreHours} className={` delivery_widget ${isTablet ? 'isTablet' : ''} ${page === 'cart' ? 'inCart' : ''} `}>
                            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>

                                <div className='delivery_widget_icon_container closed'>
                                    <div style={{fontSize:'24px'}}>
                                        <ScheduleIcon />
                                    </div>
                                </div>

                                <div style={{height: '36px', flex: 1, paddingBottom: '4px', boxSizing: 'border-box', marginLeft: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <div style={{display: 'flex', maxWidth: '100%'}}>
                                        <div style={{color: 'red', fontWeight:'bold', maxWidth:'100%', whiteSpace:'nowrap', textOverflow:'ellipsis', fontSize:'16px', overflow:'hidden'}}>
                                            WE'RE CLOSED
                                        </div>
                                    </div>
                                    {store_hours && (
                                        <div style={{maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '12px', overflow: 'hidden'}}>
                                            Hours: {store_hours.open_time} - {store_hours.close_time}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {page === 'cart' && (
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <div style={{fontSize:'30px', color: 'rgb(249, 24, 128)'}}>
                                        <EditOutlinedIcon />
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div onClick={() => window.location = '/select-city'} className={` delivery_widget ${isTablet ? 'isTablet' : ''} ${page === 'cart' ? 'inCart' : ''} `}>
                            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>

                                <div className='delivery_widget_icon_container add_address'>
                                    <div style={{fontSize:'24px'}}>
                                        <AddCircleOutlineIcon />
                                    </div>
                                </div>

                                <div style={{height: '36px', flex: 1, paddingBottom: '4px', boxSizing: 'border-box', marginLeft: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <div style={{display: 'flex', maxWidth: '100%'}}>
                                        <div style={{color: 'red', fontWeight:'bold', maxWidth:'100%', whiteSpace:'nowrap', textOverflow:'ellipsis', fontSize:'16px', overflow:'hidden'}}>
                                            Select Your Campus
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {page === 'cart' && (
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <div style={{fontSize:'30px', color: 'rgb(249, 24, 128)'}}>
                                        <EditOutlinedIcon />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Fragment>
                
            )}
        </div> 
  )
}

// Prop types for the component
Delivery_Widget.propTypes = {

    // Prop type validation for function to toggle the Authentication modal
    zone: PropTypes.object.isRequired,
    locationState: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    toggleLocationModal: PropTypes.func.isRequired,
    toggleModal_StoreHours: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    zone: state.zone,
    locationState: state.location,
    nav: state.nav
})

export default connect(mapStateToProps, { 

    // Connecting actions from Redux to the component
    toggleLocationModal,
    toggleModal_StoreHours
})(Delivery_Widget);