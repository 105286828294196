import {
    GET_LOCATIONS,
    LOCATION_ERROR,
    DELETE_LOCATION,
    ADD_LOCATION,
    EDIT_LOCATION,
    GET_LOCATION,
    SET_DELIVERY_TIME,
    CLEAR_LOCATIONS,
    SET_LOCATION_ACTIVE
  } from '../actions/types';
  
  const initialState = {
    locations: [],
    detailLocation: null,
    deliveryTime: null,
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
        case GET_LOCATIONS:
            return {
                ...state,
                locations: payload,
                loading: false
            };
        case GET_LOCATION:
            return {
                ...state,
                detailLocation: payload,
                loading: false
            };
        case SET_DELIVERY_TIME: 
            return {
                ...state,
                // deliveryTime: 10
                deliveryTime: payload + 25 // this will add 30 minutes to whatever the driving time is from neighborhood walmart to customer address
            }
        case ADD_LOCATION:
            return {
                ...state,
                locations: [payload, ...state.locations],
                detailLocation: payload,
                loading: false
            };
        case SET_LOCATION_ACTIVE:
            return {
                ...state,
                locations: [...payload],
                detailLocation: payload.map(address => {
                    if(address.active) {
                        console.log('address');
                        console.log(address)
                        return {...address};
                    }
                }),
                loading: false
            };
        case EDIT_LOCATION:
            return {
                ...state,
                detailLocation: payload,
                loading: false
            };
        case DELETE_LOCATION:
            return {
                ...state,
                locations: state.locations.filter(location => location._id !== payload),
                loading: false
            };
        case LOCATION_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        case CLEAR_LOCATIONS:
            return {
                ...state,
                locations:[],
                loading: false
            }
        default:
            return state;
    }
  }