import axios from 'axios';

// Firebase
import { db, auth, storage } from "../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

import { setAlert } from './alertActions';
import { setDiscounts, clearDiscounts } from './discountActions';
import Cart from './Cart';

import { 
    GET_CART, 
    ADD_TO_CART,
    ADD_TOTALS,
    DECREMENT_ITEM,
    REMOVE_ITEM,
    CLEAR_CART, 
    CART_ERROR, 
    CART_LOADING,
    UPDATE_DRIVER_TIP 
} from './types';

// Reference to the collections in Firestore
const productsCollectionRef = collection(db, "products");
const ordersCollectionRef = collection(db, "orders");
const emailCollectionRef = collection(db, "mail");

// Get Current Cart
export const getCart = (dont_reset_discounts) => dispatch => {

    let cart = new Cart(localStorage.cart_bs_1 ? JSON.parse(localStorage.cart_bs_1) : {});

    if(!localStorage.cart_bs_1) {
        // Store the cart in local storage
        localStorage.setItem("cart_bs_1", JSON.stringify(cart));
    } else {

        cart.addTotals();

        localStorage.setItem("cart_bs_1", JSON.stringify(cart));
    }

    console.log('GOT CART:');
    console.log(cart);

    dispatch({
        type: GET_CART,
        payload: cart
    })

    if(!dont_reset_discounts) {
        console.log('FETCH DISCOUNTS:');
        console.log(Object.values(cart.discounts)); // extract an array of all key values from object

        dispatch(clearDiscounts());
        dispatch(setDiscounts(Object.values(cart.discounts)));
    }

};

// Add item to cart
export const addToCart = (itemID, imageURL, locationID) => async dispatch => {

    let cart = new Cart(localStorage.cart_bs_1 ? JSON.parse(localStorage.cart_bs_1) : {});

    try {

        // Create a reference to the specified product document in the "products" collection
        const docRef = doc(db, 'products', itemID)

        // Retrieve the document with the specified ID from the "products" collection
        const productDoc = await getDoc(docRef);

        const product = { ...productDoc.data(), _id: productDoc.id };

        console.log('GOT PRODUCT BY ID');
        console.log(product)

        // Add item to cart
        console.log('ADDING TO CART')
        cart.add(product, itemID, imageURL, locationID);

        cart.addTotals();

        // Store the cart in local storage
        localStorage.setItem("cart_bs_1", JSON.stringify(cart));

        // Dispatch an action of type ADD_TO_CART with the cart data as the payload
        dispatch({
            type: ADD_TO_CART,
            payload: cart
        });

        // dispatch(setAlert("Added To Bag", "okay"));

    } catch (err) {

        // Dispatch an action of type CART_ERROR with an error message and status code as the payload
        dispatch({
            type: CART_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });

        // Log the error to the console
        console.log('ERROR!!!')
        console.log(err);
    }
}

// Decrease item qty in cart by 1
export const decrement = (itemID) => dispatch => {

    let cart = new Cart(localStorage.cart_bs_1 ? JSON.parse(localStorage.cart_bs_1) : {});

    cart.reduceByOne(itemID);

    // Store the cart in local storage
    localStorage.setItem("cart_bs_1", JSON.stringify(cart));

    dispatch(getCart());
}

// Remove item from cart
export const removeItem = (itemID) => dispatch => {

    let cart = new Cart(localStorage.cart_bs_1 ? JSON.parse(localStorage.cart_bs_1) : {});

    cart.removeItem(itemID);

    // Store the cart in local storage
    localStorage.setItem("cart_bs_1", JSON.stringify(cart));

    dispatch(getCart());
}

// Remove all items from cart
export const clearCart = () => async dispatch => {

    localStorage.removeItem("cart_bs_1");

    dispatch({
        type: CLEAR_CART,
    });
}

// Add Totals
export const addTotals = () => {
    return {
        type: ADD_TOTALS
    }
}

export const updateDriverTip = (tipAmount) => dispatch  => {

    dispatch({
        type: UPDATE_DRIVER_TIP,
        payload: tipAmount
    });

    // let cart = new Cart(localStorage.cart_bs_1 ? JSON.parse(localStorage.cart_bs_1) : {});

    // cart.addTip(tipAmount);

    // Store the cart in local storage
    // localStorage.setItem("cart_bs_1", JSON.stringify(cart));

    // dispatch(getCart());
}

// Create Order
export const createOrder = (newOrderObj, campus_name) => async dispatch => {

    console.log('CREATING NEW ORDER')

    try {

        console.log('FRONTEND ORDER OBJ');
        console.log(newOrderObj);

        // --- Prepare the fields for the new order document

        newOrderObj.date = Date.now();
        newOrderObj.createdAt = serverTimestamp();

        // Add the new order document to the orders collection
        const result = await addDoc(ordersCollectionRef, newOrderObj);


        // --- Initiate Order Set_To_Expire timer ---

        console.log('INITIATING TIMER!!')
        console.log(result.id);

        // Get updated Order data for display
        const updatedOrderDoc = await getDoc(result);

        console.log('GOT UPDATED ORDER BY ID');
        console.log(updatedOrderDoc.id)

        const { data: response_obj } = await axios.post("https://intense-beyond-56709-63aadc62ac0b.herokuapp.com/initiate_order_timer", {
            order_id: updatedOrderDoc.id,
            payment_intent: updatedOrderDoc.data().paymentId,
            est_complete_time_ISO: updatedOrderDoc.data().est_complete_time_ISO,
            user_id: auth.currentUser.uid,
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('RESPONSE SUCCEEDED!!');
        console.log(response_obj);

        // --- END: Initiate Order Set_To_Expire timer ---

        console.log('SUCCESS CREATING ORDER')

        dispatch(clearCart());

        // --- Send Email ----

        console.log('SENDING EMAIL FOR ORDER CONFIRMATION')

        const emailFields = {};

        emailFields.date = Date.now();
        emailFields.createdAt = serverTimestamp();

        // Create a reference to the to_user document using the provided id
        const userRef = doc(db, 'users', auth.currentUser.uid)

        // Retrieve the to_user document using the userRef
        const to_user = await getDoc(userRef);

        // Render Cart List
        const order_items = newOrderObj.cart.items;

        emailFields.to = to_user.data().email;

        emailFields.message = {
            subject: `We Got Your Order!`,
            html: `
                <div>
                    <img style="width:100%" src="https://as2.ftcdn.net/v2/jpg/03/43/48/39/1000_F_343483917_eO7ZgfPoT709RFWl8cFYD7k3R5GHEyyi.jpg" alt="img" />
                    <h1 style="color:#333;font-size:20px">
                        Order Recieved.
                    </h1> 
                    <div>
                        <div style="color:#333333;margin-top:12px">
                            Now waiting for a delivery partner to accept your order. We understand the importance of timely deliveries, and your satisfaction is our top priority.
                        </div>

                        <div style="color:#333333;margin-top:12px">
                            <b>Your Code:</b> #${updatedOrderDoc.id.substring(0, 4).toLowerCase()}
                        </div>

                        <div style="color:#333333;margin-top:12px">
                            If we are unable to secure a delivery partner for your order within the next 30 minutes, your order will expire. In this event, we will initiate a full refund, returning the full amount to your original payment method.
                        </div>

                        <div style="color:#333333;margin-top:12px">
                            We'll keep you in the loop with any updates, but you can always check <a href="https://www.miitoken.com" target="_blank">your account</a> for the most up-to-date order information.
                        </div>

                        <div style="margin-top:12px">
                            <a href="https://www.miitoken.com/orders/${updatedOrderDoc.id}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">
                                View Order Details
                            </a>
                        </div>

                        <div style="margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0">
                            ${order_items.map(order_item => (
                                `<div style="display:flex;width:100%;">
                                    <div style="display:flex;justify-content:flex-start;align-items:center;height:100px;width:60%;">
                                        <div style="display:flex;align-items:center;justify-content:center;">
                                            <div style="font-size:1rem;color:#cecece;margin:10px;padding:2px;width:40px;height:40px;display:flex;justify-content:center;align-items:center;">
                                                <img style="height:100%" src="${order_item.imageURL}" alt="img" />
                                            </div>
                                            <div style="width:100%;padding-left:10px;">
                                                <p style="height:18px;overflow:hidden;font-weight:500;font-size:13px;display:-webkit-box;-webkit-line-clamp: 1;-webkit-box-orient:vertical;">${order_item.item.name}</p>
                                                <p style="font-size:12px;color:rgb(83, 100, 113);">
                                                    <span><b>Qty:</b> ${order_item.qty}</span>
                                                    <span><b>Total:</b> ${'$'+order_item.price}</span>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>`
                            ))}
                        </div>
                        <div style="padding:16px 0; width:100%">
                            <h3>Order Summary</h3>
                            <div style="width:100%;display:flex;justify-content:space-between;align-items:center;margin:0;padding:10px 1vw;border-bottom:1px solid #e6ecf0;">
                                <div>
                                    <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Subtotal:</p>
                                    <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Driver Tip:</p>
                                    <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Estimated Tax:</p>
                                    <p style="color:rgb(83, 100, 113);margin-bottom:4px;">Completed Total:</p>
                                </div>
                                <div>
                                    <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+newOrderObj.cart.subtotal}</p>
                                    <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+newOrderObj.cart.tip}</p>
                                    <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+newOrderObj.cart.tax}</p>
                                    <p style="color:rgb(83, 100, 113);margin-bottom:4px;">${'$'+newOrderObj.cart.total}</p>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div style="color:#aaaaaa;margin-top:12px">
                        Need Help With Your Order?
                        <div style="margin-top:12px">
                            Please visit <a href="https://www.miitoken.com/settings/help" target="_blank">our help page</a> for details on how to contact us.
                        </div>
                    </div> 
                    <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888">
                        <div>
                            <a href="https://www.miitoken.com" style="color:inherit" target="_blank">
                                <span class="il">
                                    MiiToken
                                </span>.com
                            </a>
                            , the simplest way to buy groceries.
                        </div>
                    </div>
                </div>`
        };


        console.log('EMAIL FIELDS')
        console.log(emailFields);

        // Add the new email document to the mail collection
        await addDoc(emailCollectionRef, emailFields);

        console.log('SENT EMAIL FOR ORDER CONFIRMATION')

        // --- END Send Email ---

        // --- Send Driver Alert Email ----

        console.log('SENDING EMAIL FOR DRIVER ALERT')

        const emailAlert_Fields = {};

        emailAlert_Fields.date = Date.now();
        emailAlert_Fields.createdAt = serverTimestamp();


        emailAlert_Fields.to = 'tundeadepitan@ou.edu';

        emailAlert_Fields.message = {
            subject: `New Delivery Opportunity!`,
            html: `
                <div>
                    <img style="width:100%" src="https://as2.ftcdn.net/v2/jpg/03/43/48/39/1000_F_343483917_eO7ZgfPoT709RFWl8cFYD7k3R5GHEyyi.jpg" alt="img" />
                    <h1 style="color:#333;font-size:20px">
                        Get Paid <span style="color:rgb(29, 155, 240)">${'$'+updatedOrderDoc.data().cart.tip}</span> To Deliver To Students.
                    </h1> 
                    <div>
                        <div style="color:#333333;margin-top:12px">
                            1. <b>Do This Delivery:</b> First, click 'Do This Delivery!' when you find an offer that suits your schedule and preferences.
                        </div>

                        <div style="color:#333333;margin-top:12px">
                            2. <b>Purchase Assigned Items:</b> Ensure you have enough money to purchase the assigned items. Then, head to the designated pick-up spot. We'll provide you with directions to make it easy.
                        </div>

                        <div style="color:#333333;margin-top:12px">
                            3. <b>Scan & Purchase:</b> At the store, scan and purchase the assigned items. Don't worry; we've got you covered! We'll reimburse you for the full amount of the purchase, and you'll even receive tips from happy customers.
                        </div>

                        <div style="color:#333333;margin-top:12px">
                            4. <b>Complete the Delivery:</b> Finally, head to the customer's location and hand over the items. But remember, there's one crucial step: <b>Collect the Customer's Code</b> during the handover. Without this code, we won't be able to pay you for your hard work.
                        </div>

                        <div style="color:#333333;margin-top:12px">
                            Ready to start earning? Visit <a href="https://www.miitoken.com/settings/help" target="_blank">our website</a> and explore the available delivery opportunities!
                        </div>

                        <div style="margin-top:12px">
                            <a href="https://www.miitoken.com/offers/${updatedOrderDoc.id}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">
                                Do This Delivery!
                            </a>
                        </div>

                    </div> 
                    <div style="color:#aaaaaa;margin-top:12px">
                        Need Help With Your Order?
                        <div style="margin-top:12px">
                            Please visit <a href="https://www.miitoken.com/settings/help" target="_blank">our help page</a> for details on how to contact us.
                        </div>
                    </div> 
                    <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888">
                        <div>
                            <a href="https://www.miitoken.com" style="color:inherit" target="_blank">
                                <span class="il">
                                    MiiToken
                                </span>.com
                            </a>
                            , the simplest way to buy groceries.
                        </div>
                    </div>
                </div>`
        };


        console.log('EMAIL FIELDS')
        console.log(emailAlert_Fields);

        // Add the new email document to the mail collection
        await addDoc(emailCollectionRef, emailAlert_Fields);

        console.log('SENT EMAIL FOR ORDER CONFIRMATION')

        // --- END Send Email ---

        window.location = `/orders/${result.id}`

    } catch (err) {
        console.log('ERROR CREATING ORDER!!!');
        console.log(err);

        // Handle the error if creating the notification fails
        //   dispatch({
        //     type: NOTIFICATION_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        //   });
    }
};