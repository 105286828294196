import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

import { setAlert } from '../../actions/alertActions';

import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';

import { setEditorText, clearEditorText, setEditorImgFile, clearEditorImgFile, setChatImgUrl, removeChatImgUrl } from '../../actions/chatActions';

import TextEditor from '../common/TextEditor';
import New_ImageBox from '../common/New_ImageBox';
import New_ImageChat from './Messages/Message/New_ImageChat';

// Icons = material UI
import CloseIcon from '@material-ui/icons/Close';

const Input = ({ 
    message, 
    setMessage, 
    sendMessage, 
    setEditorText,
    clearEditorText,
    setEditorImgFile, 
    clearEditorImgFile, 
    setChatImgUrl, 
    removeChatImgUrl,
    chat: { 
        editorText,
        editorImgFiles,
        img_url
    }, 
    setAlert
}) => {

    // Text Editor State - Description
    // State for resetting the text editor
    const [resetEditor, setResetEditor] = useState(false);

    // State for storing file dimensions
    const [widthHeights, setWidthHeights] = useState([]);

    const [inputCharacterLength, setInputCharacterLength] = useState(0);

    const [didAlertForCharLimit, setDidAlertForCharLimit] = useState(false);

    // Get page URL
    var _URL = window.URL || window.webkitURL;

    useEffect(() => {

        let characterCount = 0;

        for (let i = 0; i < JSON.parse(editorText)?.blocks.length; i++) {
            characterCount += JSON.parse(editorText)?.blocks[i].text.length

            if(i !== 0) { // For all line breaks add 2 characters
                characterCount += 2
            }
        }

        setInputCharacterLength(characterCount)

    }, [editorText]);

    const handleSubmit = e => {
        e.preventDefault();

        if(JSON.parse(editorText)?.blocks[0].text || editorImgFiles.length > 0) {
            if(inputCharacterLength > 500) {
                setAlert("Error: Too many characters!", 'danger')
            } else {
                sendMessage(e, widthHeights);

                clearEditorText();
                setResetEditor(true);

                // Remove temporary URL in the img_url state
                removeChatImgUrl();

                // Empty the dimensions state
                setWidthHeights([]);

                // Remove all files from the file input tag
                document.getElementById('file').value = '';
            }
        }
    }

    // Trigger the file upload process
    const fileUploadButton = () => {
        console.log(editorImgFiles);
        let fileList = [];

        let fileObj, img;

        // Open file selection dialog when file input is clicked
        document.getElementById('file').click();

        // Process selected files
        document.getElementById('file').onchange = (e) =>{   

            if(!document.getElementById('file').files[0]) return;

            const fileName = document.getElementById('file').files[0].name;

            // --- File extension

            const fileExt = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length)
            console.log('File ext: ', fileExt);

            if(!(fileExt.includes('jpeg') || 
                fileExt.toLowerCase().includes('jpg') || 
                fileExt.includes('png') || 
                fileExt.includes('gif') ||
                // Add all video extensions as well
                fileExt.includes('mp4') ||
                fileExt.includes('mov') ||  
                fileExt.includes('avi')
                )) {
                setAlert('Only photos are allowed!', 'danger');
                return;
            }

            // --- END File extension

            fileList.push(document.getElementById('file').files[0])


            // Gettting file dimensions
            if((
                fileExt.includes('mp4') ||
                fileExt.includes('mov') ||  
                fileExt.includes('avi')
            )) {
                // Getting video dimensions
                console.log('---- VIDEO DIMENSIONS HERE: -----');
                const video = document.createElement('video');
                const fileObj = document.getElementById('file').files[0];

                video.onloadedmetadata = function() {
                    console.log('Video Width: ' + this.videoWidth + ', Height: ' + this.videoHeight);
                    console.log('File Name: ' + fileName);

                    // Assuming you have a function to update state, like setWidthHeights
                    setWidthHeights(currentState => [
                        {
                            fileName,
                            width: this.videoWidth,
                            height: this.videoHeight
                        }
                    ]);
                };

                video.onerror = function() {
                    console.log('Not a valid video file: ' + fileObj.type);
                };

                video.src = window.URL.createObjectURL(fileObj);
                // End video dimensions
            } else {
                 // Getting file dimensions
                console.log('---- FILE SIZE HERE: -----')
                img = new Image();
                fileObj = document.getElementById('file').files[0]

                img.onload = function() {
                    console.log(this.width + " " + this.height);
                    console.log(fileName)
                    setWidthHeights(currentState => [
                        {
                            fileName,
                            width: this.width,
                            height: this.height
                        }
                    ]);
                };
                img.onerror = function() {
                    console.log( "not a valid file: " + fileObj.type);
                };
                img.src = _URL.createObjectURL(fileObj);
                // End file dimensions
            }
           
            
            console.log('FILE LIST');
            console.log(fileList);

            setEditorImgFile(fileList);

            // console.log('FILE URLS')
            // console.log(fileUrls);
            // onSubmitImage(fileList);

            removeChatImgUrl();
            onSubmitImage(fileList);

        }

        // Track Mixpanel event for files uploaded (in production)
        if (process.env.NODE_ENV === 'production') {
            mixpanel.track("Img Uploaded - ChatBox", {
                "# of Imgs": `${fileList.length}`
            });
        }

    }


    const onSubmitImage = async (fileList) => {

        setChatImgUrl(_URL.createObjectURL(fileList[0]));
        // Iterate through each file in the fileList
        // let reader = new FileReader()
        // reader.readAsDataURL(fileList[0])
        // reader.onload = () => {
        //     // console.log('FILE PATH')
        //     // console.log(reader.result);

        //     // Set temporary URLs for the uploaded images
        //     setChatImgUrl(reader.result);
        // };
        // reader.onerror = function (error) {
        //     console.log('Error: ', error);
        // }
    
        console.log('IMG FILES');
        console.log(fileList);
    };

    const removeImg = () => {
        console.log('OLD IMG FILES HERE')
        console.log(editorImgFiles);

        
        // Clear the editorImgFiles state
        clearEditorImgFile();

        // Remove temporary URL in the img_url state
        removeChatImgUrl();

        // Empty the dimensions state
        setWidthHeights([]);

        // Remove all files from the file input tag
        document.getElementById('file').value = '';
    }

    const setAlertAction = () => {

        if(!didAlertForCharLimit) {
            // Display an alert message to notify the user that the post was sent
            setAlert("Error: Too many characters!", 'danger');
            setDidAlertForCharLimit(true);

            // Set timer to not show alert for EVERY time user types a character
            setTimeout(() => {
                
                setDidAlertForCharLimit(false);
            }, 3000 );
        }
    }

    return (
        /* ChatBox Input field */
        <form 
            className="chatBox__form"
            onKeyDown={(e) => {
                if(e.key === 'Enter') {
                    handleSubmit(e);
                }
            }}
        >

            <div className='chatBox__input__container'>
                <input
                    type="file"
                    name="file"
                    id="file"
                    accept="image/png, image/gif, image/jpeg, image/jpg, video/*"
                    hidden 
                    multiple={false}
                />

                {/* Option buttons */}
                <div className='chatBox__actions'>
                    {/* Icon */}
                    <AddPhotoAlternateOutlinedIcon onClick={fileUploadButton} fontSize="small" />
                </div>

                <div className="chatBox_input_box">


                    {(img_url[0] && widthHeights[0]) ? (
                        <Fragment>
                            {widthHeights[0]?.fileName?.includes('mov') || widthHeights[0]?.fileName?.includes('mp4') ? 
                                <New_ImageChat 
                                    index={0}
                                    img_obj={{
                                        img_name: widthHeights[0].fileName,
                                        img_path: img_url[0],
                                        width: widthHeights[0].width,
                                        height: widthHeights[0].height
                                    }}
                                    admin={true}
                                    chatInput={true}
                                    removeImg={removeImg}
                                />
                            : 
                                <New_ImageBox
                                    index={0}
                                    img_obj={{
                                        img_name: widthHeights[0].fileName,
                                        img_path: img_url[0],
                                        width: widthHeights[0].width,
                                        height: widthHeights[0].height
                                    }}
                                    admin={true}
                                    removeImg={removeImg}
                                    chatInput={true}
                                />
                            }
                        </Fragment>
                    ) : (
                        <div style={{display:'none'}}>
                        </div>
                    )}
               

                    {/* Input field */}
                    <TextEditor maxLength={500} inputCharacterLength={inputCharacterLength} setAlertAction={setAlertAction} isChatBox descriptionObj={editorText} setDescriptionObj={setEditorText} resetEditor={resetEditor} setResetEditor={setResetEditor} />

                </div>
                {/* <input 
                    className="chatBox__input"
                    type="text"
                    placeholder="Start a new message..."
                    value={message} 
                    onChange={(e) => setMessage(e.target.value)} 
                    onKeyPress={(e) => e.key === 'Enter' ? sendMessage(e) : null}
                /> */}
                
                {/* Send button */}
                <button className="sendButton" onClick={handleSubmit}>Send</button>
            </div>
        </form>
    )
}

Input.propTypes = {
    setEditorText: PropTypes.func.isRequired,  // Prop: Function to set editor text
    clearEditorText: PropTypes.func.isRequired,  // Prop: Function to clear editor text
    setEditorImgFile: PropTypes.func.isRequired, 
    clearEditorImgFile: PropTypes.func.isRequired, 
    setChatImgUrl: PropTypes.func.isRequired, 
    removeChatImgUrl: PropTypes.func.isRequired,
    chat: PropTypes.object.isRequired,  // Prop: Object representing chat state
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    chat: state.chat,  // Connect: Map chat state from redux store to chat prop
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setEditorText,
    clearEditorText,
    setEditorImgFile, 
    clearEditorImgFile, 
    setChatImgUrl, 
    removeChatImgUrl,
    setAlert
})(Input);