import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Routing
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

// Redux
import { connect } from 'react-redux';

// Pages
import Default from './Default';
import Notifications from '../../pages/Notifications/Notifications';
import Messages from '../../pages/Messages';
import ChatNav from '../../pages/ChatNav';
import LikePage from '../../pages/LikePage';
import Profile from '../../pages/Profile';
import EditProfile from '../../pages/EditProfile';
import MyOrders from '../../pages/MyOrders';
import MyOrdersList from '../../pages/MyOrdersList';
import Settings from '../../pages/Settings';
import SettingsNav from '../../pages/SettingsNav';

// Marketplace Pages
import Landing_1 from '../../Marketplace/pages/Landing_1';
import HomePage from '../../Marketplace/pages/HomePage';
import GroceryAboutPage from '../../Marketplace/pages/AboutPage';
import CategoriesPage from '../../Marketplace/pages/CategoriesPage';
import Marketplace from '../../Marketplace/pages/ExplorePage';
import CollectionPage from '../../Marketplace/pages/CollectionPage';
import CartPage from '../../Marketplace/pages/CartPage';
import CheckoutPage from '../../Marketplace/pages/CheckoutPage';
import ProductPage from '../../Marketplace/pages/ProductPage';
import SearchPage_Marketplace from '../../Marketplace/pages/SearchPage';
import Add_DiscountForm from '../../pages/Add_DiscountForm';
import Select_City_Page from '../../pages/Select_City_Page';

// Driver Pages
import OffersPage from '../../Drivers/pages/OffersPage';
import OfferPage from '../../Drivers/pages/OfferPage';


const Routes = ({ auth: { user, isAuthenticated, loading }}) => {

  // Screen Width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {

    // Add event listener for window resize
    window.addEventListener('resize', () => handleWindowSizeChange());
      
    // Clean up by removing event listener on component unmount
    return () => window.removeEventListener('resize', () => handleWindowSizeChange());
  }, [window.location]);

  // Handle window size change
  const handleWindowSizeChange = () => {
      setWindowWidth(window.innerWidth);
  };

  // Determine if the current screen width is mobile or tablet
  const isMobile = windowWidth <= 769;
  const isTablet = windowWidth <= 1000;

  return (
    <Fragment>
        <Switch>

          {/* <Route exact path="/x" component={Landing_1} /> */}
            
            <Route exact path="/search" component={SearchPage_Marketplace} />
            {/* <Route exact path="/howitworks" component={HowItWorksPage} /> */}

            {/* Driver Pages */}
            <Route exact path="/offers" component={OffersPage} />
            <Route exact path="/offer/:orderID" component={OfferPage} />

            {/* Shopping Page */}
            <Route exact path="/" component={HomePage} />
            <Route exact path="/marketplace" component={HomePage} />
            <Route exact path="/home" component={HomePage} />
            <Route exact path="/marketplace/about" component={GroceryAboutPage} />
            <Route exact path="/marketplace/categories" component={CategoriesPage} />
            <Route exact path="/marketplace/explore" component={Marketplace} />
            <Route exact path="/cart" component={CartPage} />
            <Route exact path="/add-code" component={Add_DiscountForm} />
            <Route exact path="/select-city" component={Select_City_Page} />
            <Route exact path="/marketplace/search" component={SearchPage_Marketplace} />
            {/*<PrivateRoute exact path="/checkout" component={CheckoutPage} /> */}

            {/* Notifications Page */}
            <PrivateRoute exact path="/notifications" component={Notifications} />
            
            {/* Messages */}
            <PrivateRoute exact path="/messages" component={!isTablet ? Messages : ChatNav} />
            {/* <PrivateRoute exact path="/messages" component={Messages} /> */}

            {/* <PrivateRoute exact path="/saved" component={LikePage} /> */}
            <PrivateRoute exact path="/orders" component={!isTablet ? MyOrders : MyOrdersList} />
            {/* <PrivateRoute exact path="/orders" component={MyOrders} /> */}
            {/* <PrivateRoute exact path="/profile" component={Profile} /> */}
            <PrivateRoute exact path="/profile/edit" component={EditProfile} />

            {/* Settings */}
            <Route exact path="/settings" component={SettingsNav}>

              {/* Redirect based on authentication and loading state */}
              {!isTablet && (
                !loading && isAuthenticated ? (
                  <Redirect to="/settings/account" /> 
                ) : (
                  <Redirect to="/settings/howitworks" />
                )
              )}
            </Route>

            {/* Setting Account Pages */}
            <PrivateRoute exact path="/settings/account/screen_name" component={Settings} />
            <PrivateRoute exact path="/settings/account/password" component={Settings} />
            <PrivateRoute exact path="/settings/account/email" component={Settings} />
            <PrivateRoute exact path="/settings/account/phone" component={Settings} />
            <PrivateRoute exact path="/settings/account/gender" component={Settings} />
            <PrivateRoute exact path="/settings/account/birth_date" component={Settings} />
            {/* End */}
            
            {/* More Settings Pages */}
            <PrivateRoute exact path="/settings/payments" component={Settings} />
            <Route exact path="/settings/coupons" component={Settings} />
            <PrivateRoute exact path="/settings/addresses" component={Settings} />
            <PrivateRoute exact path="/settings/account" component={Settings} />
            <Route exact path="/settings/refunds" component={Settings} />
            <Route exact path="/settings/howitworks" component={Settings} />
            <Route exact path="/settings/help" component={Settings} />
            <Route exact path="/settings/feedback" component={Settings} />
            <Route exact path="/settings/terms" component={Settings} />
            <Route exact path="/settings/delivery_partner_terms" component={Settings} />
            <Route exact path="/settings/privacy" component={Settings} />
            <Route exact path="/settings/cookies" component={Settings} />

            {/* Payment Pages */}
            <Route exact path="/checkout" component={CheckoutPage} />
            {/* <Route exact path="/makeoffer" component={OfferPage} /> */}


            {/* Detail Post pages */}
            {/* <Route exact path="/post/:id" component={PostPage} />
            <Route exact path="/post/:id/likes" component={LikedBy} />
            <Route exact path="/media/:id" component={Detail_MediaPage} /> */}

            {/* ChatBox Page */}
            <PrivateRoute exact path="/messages/:id" component={Messages} />

            {/* Marketplace Orders */}
            <Route exact path="/orders/:orderID" component={MyOrders} />
            
            {/* Shopping Pages */}
            <Route exact path="/details/:productID" component={ProductPage} />
            <Route exact path="/:handle/:menuID" component={CollectionPage} />

            {/* Profile Page */}
            {/* <Route exact path="/:username" component={Profile} /> */}

            {/* Page not found */}
            <Route component={Default} />
        </Switch>
    </Fragment>
  );
};

Routes.propTypes = {
  auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
}

const mapStateToProps = state => ({

  // Mapping the states from the Redux store to the below props
  auth: state.auth
})

// Connect the SidebarMobile component to the Redux store and export it
export default connect(mapStateToProps, null)(Routes);
