
// Firebase
import { db, auth, storage } from "../utils/firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit } from 'firebase/firestore';

import { setAlert } from './alertActions';

import {
  GET_MENUS,
  SET_MODAL_MENU,
  MENU_ERROR,
  DELETE_MENU,
  UPDATE_MENU_ITEMS,
  ADD_MENU,
  GET_MENU,
  EDIT_MENU,
  MENUS_LOADING,
  CLEAR_MENUS
} from './types';

// Reference to the "menus" collection in Firestore
const menuCollectionRef = collection(db, "menu");

// Get menus
export const getMenus = (lastPageDoc) => async dispatch => {
  
  console.log('ENTER GET COLLECTIONS');

  try {

    console.log('GETTING COLLECTIONS')

    // Construct a new query starting at the last visible document and fetch the next 3 posts.
    const getQuery = query(menuCollectionRef, orderBy('lastModified', 'desc'), limit(15));

    // Retrieve all documents from the "categories" collection
    const data = await getDocs(getQuery);

    // Map the document data to an array of categories with the document ID included
    const categoryList = data.docs.map((doc) => ({...doc.data(), _id: doc.id}));

    console.log('SHOW CATEGORY LIST')
    console.log(categoryList);

    // Dispatch an action of type GET_CATEGORIES with the categoryList as the payload
    dispatch({
        type: GET_MENUS,
        payload: categoryList
    });

  } catch (err) {
    
    console.log('ERROR!!!')
    console.log(err);

    // dispatch({
    //   type: COLLECTION_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status }
    // });
  }
};

// Get menu page by id
export const getMenuPage = id => async dispatch => {
  dispatch(setMenusLoading());

    try {

        // Create a reference to the specified category document in the "categories" collection
        const docRef = doc(db, 'menu', id)

        // Retrieve the document with the specified ID from the "categories" collection
        const menuPageDoc = await getDoc(docRef);

        console.log('GOT COLLECTION BY ID');
        console.log(menuPageDoc.data())
  
        if(menuPageDoc.data()) {
            // Dispatch an action of type GET_MENU with the category data and ID as the payload
            dispatch({
                type: GET_MENU,
                payload: {
                    ...menuPageDoc.data(),
                    _id: menuPageDoc.id
                }
            });
        } else {
            dispatch({
                type: GET_MENU,
                payload: null
            });

            dispatch(setAlert("Sorry, that Menu doesn't exist.", "okay"));
        }
    } catch (err) {

        // Dispatch an action of type MENU_ERROR with an error message and status code as the payload
        dispatch({
            type: MENU_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        
        // Log the error to the console
        console.log('ERROR!!!')
        console.log(err);
    }
}

// Get sub-menus by main menu id
export const getMenuItems = (menu_ID, parentID) => async dispatch => {
    dispatch(setMenusLoading());

        let temp_parentID = null;

        if(parentID) {
            temp_parentID = parentID;
        }
  
      try {

            console.log('GETTING MENU ITEMS')
            console.log('menu id: ', menu_ID)

            // Create a query to retrieve comments where the post_commented_on field matches the provided post ID, ordered by creation date in descending order
            const menu_Query = query(menuCollectionRef, where("menu_page", "==", menu_ID), orderBy('order_num', 'desc'), where("parent_menuID", "==", temp_parentID), orderBy('date', 'desc'));

            // Execute the query and retrieve the query snapshot
            const menu_Snapshot = await getDocs(menu_Query);
            
            // Map through the document snapshots in the query snapshot and extract the data and ID for each comment
            const menu_List = menu_Snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
  
            // Recursively fetch comments of comments
            const fetch_MenuList_Of_Menu = async (menu_list) => {
                for (let i = 0; i < menu_list.length; i++) {
                const menu_item = menu_list[i];
                
                // Create a query to retrieve comments where the parent_menuID field matches the comment ID, ordered by creation date in descending order
                const menuList_Of_Menu_Query = query(menuCollectionRef, where("parent_menuID", "==", menu_item._id), orderBy('order_num', 'desc'));
                
                // Execute the query and retrieve the query snapshot
                const menuList_Of_Menu_Snapshot = await getDocs(menuList_Of_Menu_Query);
                
                // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                const menuList_Of_Menu = menuList_Of_Menu_Snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                
                // Append the comments of comments to the current comment
                menu_item.menuList_Of_Menu = menuList_Of_Menu;
                
                // Recursively fetch comments of comments for the current comment
                await fetch_MenuList_Of_Menu(menuList_Of_Menu);
                }
            };
            
            // Fetch comments of comments for the initial set of comments
            await fetch_MenuList_Of_Menu(menu_List);
    

              // Dispatch an action of type GET_MENUS with the menu_List data
              dispatch({
                  type: GET_MENUS,
                  payload: menu_List
              });

      } catch (err) {
  
          // Dispatch an action of type MENU_ERROR with an error message and status code as the payload
          dispatch({
              type: MENU_ERROR,
              payload: { msg: "something went wrong", status: 500 }
          });
          
          // Log the error to the console
          console.log('ERROR!!!')
          console.log(err);
      }
  }

// Add Menu
export const createMenuPage = (formData, selectedArray) => async dispatch => {
    
    console.log('CREATING MENU PAGE');

    try {

      console.log('FRONTEND FORM DATA');
      console.log(formData);

      const {
            name,
            handle
        } = formData;

        // Create a new document in the "categories" collection with the category data
        const result = await addDoc(menuCollectionRef, {
            name,
            handle,
            type: 'menu-page',
            date: Date.now(),
            createdAt: serverTimestamp(),
            lastModified: serverTimestamp()
        });

        console.log('SENT TO FIREBASE')
        console.log(result.id);

        // --- Add Selected Menu Items ----0-----0-----0-----


        if (selectedArray?.length > 0) {
            // Iterate over the imgData array and upload each image to the storage
            selectedArray.map(async (selected_item) => {
                    
                console.log('PLUS SUB_MENU !!!')
    
                // Create a new document in the "posts" collection with the post data
                await addDoc(menuCollectionRef, {
                    name: selected_item.name,
                    parent_menuID: selected_item.parent_id,
                    handle: selected_item.handle,
                    collection_id: selected_item.collection_id,
                    order_num: selected_item.orderNum,
                    date: Date.now(),
                    createdAt: serverTimestamp(),
                    menu_page: result.id
                });
    
                console.log('ADDED MENU ITEM')
    
            });
        }
   

        // --- END: Add Selected Menu Items ----0-----0-----0-----

  
        dispatch(setAlert('Menu Page Created', 'success'));
    } catch (err) {

      console.log('ERROR!!!')
      console.log(err)
    }
};

// Add-Remove selected Collections from the selection array
export const submit_Selected_Items = (collection_Obj_List, menu_ID, menu_page_id) => async dispatch => {

    console.log('SUBMITTING COLLECTION LIST');
  
      try {
            console.log('FRONTEND OBJ LIST');
            console.log(collection_Obj_List); 

            let tempt_MenuID = null;
            if (menu_ID) {
                tempt_MenuID = menu_ID;
            }
  
            // Create a query to retrieve comments where the post_commented_on field matches the provided post ID, ordered by creation date in descending order
            const subMenu_Query = query(menuCollectionRef, where("parent_menuID", "==", tempt_MenuID), orderBy('order_num', 'desc'));
            
            // Iterate over the imgData array and upload each image to the storage
            collection_Obj_List.map(async (collection_Obj) => {
                
                console.log('PLUS SUB_MENU !!!')
                
                // Execute the query and retrieve the query snapshot
                const subMenu_Snapshot = await getDocs(subMenu_Query);
                
                // Map through the document snapshots in the query snapshot and extract the data and ID for each comment
                const subMenu_List = subMenu_Snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
    
                let orderNum = 1;
                
                // If existing gallery data has documents increment to orderNum to the sum + 1
                if(subMenu_List && subMenu_List.length > 0) {
                    orderNum = subMenu_List.length + 1
                }
    
                // Create a new document in the "posts" collection with the post data
                const result = await addDoc(menuCollectionRef, {
                    name: collection_Obj.name,
                    parent_menuID: tempt_MenuID,
                    handle: collection_Obj.handle,
                    collection_id: collection_Obj.collection_id,
                    order_num: orderNum,
                    date: Date.now(),
                    createdAt: serverTimestamp(),
                    menu_page: menu_page_id
                });
    
                console.log('SENT TO FIREBASE')
                console.log(result.id);
    
            });
  
          // history.push(`/admin/product/${storeId}/${res.data._id}?show=detail`);
          dispatch(setAlert('Sub-Menus Added', 'success'));
      } catch (err) {
          
          console.log('ERROR!!!')
          console.log(err);
          // dispatch({
          //   type: PRODUCT_ERROR,
          //   payload: { msg: err.response.statusText, status: err.response.status }
          // });
      }
}

export const setModalMenu = (menuObj) => dispatch => {

    dispatch({
        type: SET_MODAL_MENU,
        payload: menuObj
    })
}

// Edit Menu
export const editMenu = (formData, collectionId, storeId, history) => async dispatch => {
//   try {
//     const config = {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//     };
//     const res = await axios.post(`/api/categories/admin/edit/${collectionId}/${storeId}`, formData, config);

//     const storeProducts = await axios.get(`/api/products/store/${storeId}`);

//     // const storeProducts = await axios.get('/api/products/store');

//     const categoryTags = [...res.data.tags];
    
//     for(var i = 0; i < categoryTags.length; i++) {
//       storeProducts.data.map(async product => {
//         try {
//           if(product.tags.includes(categoryTags[i])) {
//             let data = new FormData();
//             data.append('id', product._id);

//             await axios.post(`/api/products/edit_collection/${product._id}/${res.data._id}`);
//             await axios.put(`/api/categories/product/${res.data._id}/${product._id}`, config);
//             console.log('Added ' + product._id + ' TO COLLECTION: ' + res.data.name);
//           }
//         } catch (err) {
//           console.log(err);
//         }
//       })
//     }

//     dispatch({
//       type: EDIT_COLLECTION,
//       payload: res.data
//     });

//     history.push(`/admin/collection/${storeId}/${res.data._id}?show=detail`);
    
//     dispatch(setAlert('Collection Updated', 'success'));
//   } catch (err) {
//     dispatch({
//       type: COLLECTION_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
};

// Delete menu
export const deleteMenu = id => async dispatch => {
//   try {
//     await axios.delete(`/api/categories/${id}`);

//     dispatch({
//       type: DELETE_COLLECTION,
//       payload: id
//     });

//     // dispatch(setAlert('Collection Removed', 'success'));
//   } catch (err) {
//     dispatch({
//       type: COLLECTION_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
};

// add a collection to menu
export const addToMenu = (itemList, id) => async dispatch => {
//   const config = {
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   };

//   console.log('ITEM LIST ACTION');
//   console.log(itemList);

//   itemList.map(async item => {
//     try {
//       await axios.post(`/api/products/edit_collection/${item}/${id}`);

//       const collection = await axios.put(`/api/categories/product/${id}/${item}`, config);
  
//       dispatch({
//         type: UPDATE_COLLECTION_ITEMS,
//         payload: { id, items: collection.data}
//       });
  
//       // dispatch(setAlert('Collection Created', 'success'));
//     } catch (err) {
//       dispatch({
//         type: COLLECTION_ERROR,
//         payload: { msg: err.response.statusText, status: err.response.status }
//       });
//     }
//   })
};

// Menus loading
export const setMenusLoading = () => {
  return {
      type: MENUS_LOADING
  }
} 

// Remove all menus
export const clearMenus = () => dispatch => {
  dispatch({
      type: CLEAR_MENUS
  });
}