import React, { useEffect, useState } from 'react';

// Date formatting
import { format, lightFormat, formatISO } from 'date-fns';

// Components
import Item_Img from '../../Drivers/components/Offers/Item_Img';

// Icons
import { Button } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';

const Refund_Block = ({
        refund_obj
}) => {

    return (
        <div className='refund' key={refund_obj._id}>

            <div className='order__headerText' style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <h3 className='line-clamp' style={{marginBottom: '10px', overflow:'hidden' , fontSize:'16px', fontWeight:'600', color:'#000', maxWidth:'100%'}}>
                    {refund_obj.date?.seconds ? formatISO(refund_obj.date, "h:mm a") : format(refund_obj.date, "h:mm a")}{' '}{refund_obj.date?.seconds ? formatISO(refund_obj.date, "MM-dd-yyyy") : lightFormat(refund_obj.date, 'MM-dd-yyyy')}
                </h3>
                <h2>+${refund_obj.amount / 100}</h2>
            </div>
            <div className='order__headerDescription'>
                <div style={{background: '#CDFFCD', color: '#65A765', fontWeight: 'bold', width: 'fit-content', fontSize: '13px', borderRadius: '5px', border: '1px solid #e6ecf0', padding: '4px 5px'}}>
                    {refund_obj.status}
                </div>
                <span style={{margin: '5px 0'}}>Order ID: {refund_obj.order_id}</span>
            </div>

            <div className='order__headerText' style={{display: 'flex', margin: '20px 0 10px', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
                <VerifiedUserOutlinedIcon />
                <h3 className='line-clamp' style={{marginLeft: '5px', overflow:'hidden' , fontSize:'16px', fontWeight:'600', color:'#000', maxWidth:'100%'}}>
                    MiiToken initiated your refund
                </h3>
            </div>

            <div style={{width: '100%', display: 'flex'}}>

                <div style={{flex: 1, padding: '10px 5px 0px 0px', boxSizing: 'border-box'}}>
                    <Button onClick={() => window.location = `/orders/${refund_obj.order_id}`} variant="outlined" className="sidebar__tweet ghost noMargin" >
                        View Order Details
                    </Button>
                </div>
        
            </div>
        </div>
    )
}

export default Refund_Block;
