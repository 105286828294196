import { db, auth, storage } from '../utils/firebase-config';
import { collection, query, where, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';


// Reference to the "users" collection in Firestore
const emailCollectionRef = collection(db, "mail");

// Function to capitalize the first letter of a string
const capitalizeFirstLetter =(string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// Create New Notification
export const sendEmail = ( formData, campus_name ) => async dispatch => {

    const {
        type,  // String
        to_userId,  // objectID
        from_user, // User Object
        postId, // objectID
        chatId, //objectID
        message  // String
    } = formData;

    try {
        
        // Create a reference to the to_user document using the provided id
        const docRef = doc(db, 'users', to_userId)

        // Retrieve the to_user document using the docRef
        const to_user = await getDoc(docRef);

        console.log('GOT TO_USER BY ID');
        console.log(to_user.data())

         const fullName = capitalizeFirstLetter(from_user.first_name.toLowerCase()) + ' ' + capitalizeFirstLetter(from_user.last_name.charAt(0).toLowerCase()) + '.';
         const firstName = capitalizeFirstLetter(from_user.first_name.toLowerCase());

         const imgContent = [{}, {}, {}, {}]

        // --- Prepare the fields for the new email document

        const docFields = {};

        if(type === 'message') {
            docFields.to = to_user.data().email;
            docFields.message = {
                subject: `${fullName} has sent you a Direct Message on MiiToken!`,
                html: `<div><h1 style="color:#333;font-size:20px">You have 1 unread message on MiiToken</h1> <div><div style="color:#333333;margin-top:24px">~ ${fullName} wrote:</div> <div style="padding:16px 24px;margin-top:12px;margin-bottom:24px;background-color:#f5f8fa;border-radius:5px;font-size:18px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#8899a6;font-weight:400;line-height:24px;text-align:left;word-break:break-word">${message}</div> <a href="https://www.miitoken.com/messages/${chatId ? chatId : ''}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">Reply</a></div> <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888"><div><a href="https://www.miitoken.com" style="color:inherit" target="_blank"><span class="il">MiiToken</span>.com</a>, the simplest way to buy groceries.</div></div></div>`,
            };
        }

        if(type === 'comment') {
            docFields.to = to_user.data().email;
            docFields.message = {
                subject: `${fullName} commented on your post!`,
                html: `
                <div>
                    <h1 style="color:#333;font-size:20px">You have 1 new comment on MiiToken</h1> 
                    <div>
                        <div style="color:#333333;margin-top:24px">~ ${fullName} commented:</div> 
                        <div style="padding:16px 24px;margin-top:12px;margin-bottom:24px;background-color:#f5f8fa;border-radius:5px;font-size:18px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#8899a6;font-weight:400;line-height:24px;text-align:left;word-break:break-word">${message}</div>
                        <a href="https://www.miitoken.com/post/${postId ? postId : ''}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">Reply</a>
                    </div> 
                    <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888">
                        <div>
                            <a href="https://www.miitoken.com" style="color:inherit" target="_blank">
                                <span class="il">MiiToken</span>
                                .com
                            </a>
                            , the simplest way to buy groceries.
                        </div>
                    </div>
                </div>`,
            };
        }

        if(type === 'comment reply') {
            docFields.to = to_user.data().email;
            docFields.message = {
                subject: `${fullName} replied to your comment!`,
                html: `<div><h1 style="color:#333;font-size:20px">You have 1 new comment on MiiToken</h1> <div><div style="color:#333333;margin-top:24px">~ ${fullName} replied to your comment:</div> <div style="padding:16px 24px;margin-top:12px;margin-bottom:24px;background-color:#f5f8fa;border-radius:5px;font-size:18px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#8899a6;font-weight:400;line-height:24px;text-align:left;word-break:break-word">${message}</div> <a href="https://www.miitoken.com/post/${postId ? postId : ''}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">Reply</a></div> <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888"><div><a href="https://www.miitoken.com" style="color:inherit" target="_blank"><span class="il">MiiToken</span>.com</a>, the simplest way to buy groceries.</div></div></div>`,
            };
        }

        // if(type === 'comment interaction') {
        //     docFields.to = to_user.data().email;
        //     docFields.message = {
        //         subject: `${fullName} interacted with your comment!`,
        //         html: `<div><h1 style="color:#333;font-size:20px">You have 1 new comment on MiiToken</h1> <div><div style="color:#333333;margin-top:24px">~ ${fullName} interacted with your comment:</div> <div style="padding:16px 24px;margin-top:12px;margin-bottom:24px;background-color:#f5f8fa;border-radius:5px;font-size:18px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#8899a6;font-weight:400;line-height:24px;text-align:left;word-break:break-word">${message}</div> <a href="https://www.miitoken.com/post/${postId ? postId : ''}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">Reply</a></div> <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888"><div><a href="https://www.miitoken.com" style="color:inherit" target="_blank"><span class="il">MiiToken</span>.com</a>, the simplest way to buy groceries.</div></div></div>`,
        //     };
        // }

        // if(type === 'post interaction') {
        //     docFields.to = to_user.data().email;
        //     docFields.message = {
        //         subject: `${fullName} replied to a comment on your post!`,
        //         html: `<div><h1 style="color:#333;font-size:20px">You have 1 new comment on MiiToken</h1> <div><div style="color:#333333;margin-top:24px">~ ${fullName} replied to a comment on your post:</div> <div style="padding:16px 24px;margin-top:12px;margin-bottom:24px;background-color:#f5f8fa;border-radius:5px;font-size:18px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#8899a6;font-weight:400;line-height:24px;text-align:left;word-break:break-word">${message}</div> <a href="https://www.miitoken.com/post/${postId ? postId : ''}" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#ffffff;border-radius:4px;padding:11px 30px;border:1px solid #55acee;display:inline-block;font-weight:bold;background-color:#55acee" target="_blank">Reply</a></div> <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888"><div><a href="https://www.miitoken.com" style="color:inherit" target="_blank"><span class="il">MiiToken</span>.com</a>, the simplest way to buy groceries.</div></div></div>`,
        //     };
        // }

        if(type === 'like') {
            docFields.to = to_user.data().email;
            docFields.message = {
                subject: `${fullName} likes your post!`,
                html: `<div><h1 style="color:#333;font-size:20px">You have 1 new like on MiiToken</h1> <div style="color:#333333;margin-top:24px">~ ${fullName} likes <a href="https://www.miitoken.com/post/${postId ? postId : ''}">your post:</a></div> <div style="padding:16px 24px;margin-top:12px;margin-bottom:24px;background-color:#f5f8fa;border-radius:5px;font-size:18px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#8899a6;font-weight:400;line-height:24px;text-align:left;word-break:break-word">${message}</div> <a href="https://www.miitoken.com/notifications" style="text-decoration:none;font-size:16px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;color:#0f1419;border-radius:4px;padding:11px 30px;border:2px solid #e6ecf0;display:inline-block;font-weight:bold;background-color:initial" target="_blank">Go to notifications</a></div> <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888"><div><a href="https://www.miitoken.com" style="color:inherit" target="_blank"><span class="il">MiiToken</span>.com</a>, the simplest way to buy groceries.</div></div>`,
            };
        }
        
        // if(avatar) docFields.from_avatar = avatar;
        docFields.date = Date.now();
        docFields.createdAt = serverTimestamp();

        console.log('Email docFields Data:')
        console.log(docFields)

        // Add the new email document to the mail collection
        await addDoc(emailCollectionRef, docFields);

        console.log('QUEUED EMAIL FOR DELIVERY!')

    } catch (err) {
        console.log('ERROR SENDING EMAIL!!!');
        console.log(err);

        // Handle the error if creating the notification fails
        //   dispatch({
        //     type: NOTIFICATION_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        //   });
    }
};