import {
    GET_MENUS,
    SET_MODAL_MENU,
    MENU_ERROR,
    DELETE_MENU,
    ADD_MENU,
    GET_MENU,
    EDIT_MENU,
    CLEAR_MENUS,
    MENUS_LOADING,
  } from '../actions/types';
  
  const initialState = {
    menus: [],
    detailMenu: null,
    modal_menus: [],
    modal_detailMenu: null,
    selected_menu: null,
    loading: true,
    modal_loading: true,
    menuUploading: false,
    modalOpen: false,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case MENUS_LOADING:

            // Set the loading state to true
            return {
                ...state,
                loading: true
            };
      case GET_MENUS:
        return {
          ...state,
          menus: payload,
          loading: false
        };
      case GET_MENU:
        return {
          ...state,
          detailMenu: payload,
          loading: false
        };
      case SET_MODAL_MENU:

        return {
          ...state,
          modal_detailMenu: payload,
        };
      case ADD_MENU:
        return {
          ...state,
          menus: [payload, ...state.menus],
          detailMenu: payload,
          loading: false
        };
      case EDIT_MENU:
        return {
            ...state,
            detailMenu: payload,
            loading: false
        };
      case DELETE_MENU:
        return {
          ...state,
          menus: state.menus.filter(menu => menu._id !== payload),
          loading: false
        };
      case MENU_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
      case CLEAR_MENUS:
        return {
            ...state,
            menus: [],
            loading: false
        }
      default:
        return state;
    }
  }